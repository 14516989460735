@import "@sitestyles";

.search_cross_banner_item_wrap {
  position: relative;
  background-color: $accentRightColor;
  box-shadow: 3px 3px 5px $backgroundSearchItemShadowColor;
  border-radius: 10px;
}

.search_cross_banner_item_photo_wrap {
  position: relative;
  width: 100%;
  min-height: 274px;
  padding-top: 166%;
  cursor: pointer;
  overflow: hidden;
  border-radius: 10px;
}

.search_cross_banner_item_photo {
  position: absolute;
  left: -35%;
  top: -31%;
  width: 225%;
  height: auto;
  border-radius: 10px;
  transform: rotate(16deg);

  @media screen and (max-width: 699px) {
    left: -50%;
    top: -22%;
    width: 190%;
  }
}

.search_cross_banner_item_bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  align-items: center;
  padding: 16px;

  &:before {
    content: '';
    width: 100%;
    height: 260%;
    position: absolute;
    bottom: 0;
    left: 0;
    background: linear-gradient(180deg, rgba(114, 0, 102, 0.00) 0.05%, $accentRightColor 59.56%);
    border-radius: 0 0 10px 10px;
    pointer-events: none;
    transition: .3s;

    @media screen and (max-width: 499px) {
      background: linear-gradient(180deg, rgba(114, 0, 102, 0.00) 0.14%, $accentRightColor 44.81%);
      height: 180%;
    }
  }

  @media screen and (max-width: 699px) {
    padding: 8px;
    gap: 4px;
  }
}

.online_now_cross_banner_bottom_title {
  font-size: 16px;
  font-weight: 600;
  color: $whiteColor;
  line-height: 120%;
  z-index: 2;
  text-align: center;

  span {
    text-transform: capitalize;
  }

  @media screen and (max-width: 699px){
    font-size: 14px;
  }
}

.online_now_cross_banner_bottom_text {
  color: $whiteColor;
  font-size: 14px;
  font-weight: 400;
  line-height: 120%;
  z-index: 2;
  text-align: center;

  @media screen and (max-width: 699px){
    font-size: 12px;
  }
}

.search_cross_banner_item_chat_btn {
  background: $whiteColor;
  color: $accentLeftColor;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  border-radius: 4px;
  transition: .3s;
  position: relative;
  z-index: 2;
  width: 100%;
  height: 40px;
  margin-top: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  text-transform: capitalize;

  &:hover,
  &:focus {
    box-shadow: $searchItemBtnShadowHover;
  }

  @media screen and (max-width: 699px) {
    margin-top: 12px;
    height: 32px;
    font-size: 12px;
  }
}