@import "@sitestyles";

$mobileBreakpoint: 599px;

.delete_modal_wrap{
  background-color: #fff;
  border-radius: 10px;
  padding: 52px 69px;
  max-width: 100%;
  width: 603px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  @media (max-width: $mobileBreakpoint){
    padding: 40px 20px;
  }
}

.delete_modal_title{
  font-size: 26px;
  letter-spacing: 0.06em;
  color: #474F5C;
  margin-bottom: 12px;
  text-align: center;
  font-weight: bold;

  @media (max-width: $mobileBreakpoint){
    font-size: 22px;
    line-height: 1.3;
  }

  span{
    color: #EB5757;
  }
}

.delete_modal_desc{
  font-size: 14px;
  line-height: 1.5;
  text-align: center;
  color: rgba(51, 51, 51, 0.8);

  a{
    color: #333;

    &:hover{
      text-decoration: underline;
    }
  }

  @media (max-width: $mobileBreakpoint){
    br{
      display: none;
    }
  }

  & + .delete_modal_choose_btn{
    margin-top: 30px;
  }
}

.delete_modal_form{
  width: 402px;
  max-width: 100%;
  margin-top: 44px;
  margin-bottom: 48px;

  @media (max-width: $mobileBreakpoint){
    margin-top: 30px;
  }
}

.delete_modal_form_item{
  display: flex;
  align-items: flex-start;

  & + .delete_modal_form_item{
    margin-top: 29px;
  }
}

.delete_modal_form_item_input{
  margin-right: 16px;

  input{
    display: none;

    &:checked + label{
      border-color: #7474B2;
      background-color: #7474B2;
      background-image: url(../../../../img/popup-checked.svg);
      background-size: 11px;
      background-repeat: no-repeat;
      background-position: center;
    }
  }

  label{
    width: 20px;
    height: 20px;
    display: block;
    margin-top: 2px;
    border-radius: 4px;
    border: 1.5px solid #9AAFD0;
    box-shadow: 0 7px 64px rgba(0, 0, 0, 0.07);
    cursor: pointer;
  }
}

.delete_modal_form_item_desc{


  .title{
    font-weight: bold;
    font-size: 16px;
    color: #333;
    margin-bottom: 7px;
    
    &.permanent{
      color: #EB5757;
    }
  }

  .text{
    color: rgba(51, 51, 51, 0.8);

    a{
      color: #333;
      
      &:hover{
        text-decoration: underline;
      }
    }
  }
}

.delete_modal_choose_btn{
  width: 240px;
  height: 44px;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $buttonBackgroundColor;
  border-radius: 4px;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0.01em;
  color: #FFFFFF;
  cursor: pointer;
  margin: 0 auto;
  line-height: 1;

  img {
    width: 20px;
    margin-right: 12px;
  }

  svg {
    width: 22px;
    height: 22px;
    fill: #FFFFFF;
    vertical-align: middle;
    margin-right: 12px;
  }

  &.disabled{
    opacity: .5;
    pointer-events: none;
  }
}

.delete_modal_close{
  position: absolute;
  right: 10px;
  top: 10px;
  width: 25px;
  height: 25px;
  background-image: url(../../../../img/cross-darkblue.svg);
  background-size: 17px;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
}