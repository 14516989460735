$accentColor: #6766DC;

.modal_wrap {
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 10px;
  width: 550px;
  overflow: hidden;

  @media screen and (max-width: 600px) {
    margin: auto;
    max-width: 91%;
  }
}

.modal_header {
  height: 72px;
  background: #F2F2FE;
  position: relative;
  overflow: hidden;

  &:before {
    content: '';
    width: 100px;
    height: 100px;
    background: url("../../../../../img/letter_alert_decoration.svg") no-repeat center;
    background-size: cover;
    position: absolute;
    top: -60%;
    left: 0;
  }

  &:after {
    content: '';
    width: 190px;
    height: 190px;
    background: url("../../../../../img/letter_alert_decoration.svg") no-repeat center;
    background-size: cover;
    position: absolute;
    transform: rotate(32deg);
    top: -77px;
    right: 0;
  }
}

.modal_header.header_gift {
  &:before {
    background: url("../../../../../img/gift_alert_decoration.svg") no-repeat center;
    background-size: cover;
  }

  &:after {
    background: url("../../../../../img/gift_alert_decoration.svg") no-repeat center;
    background-size: cover;
  }

}

.modal_body {
  padding: 40px;
  background: #fff;
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: relative;

  @media screen and (max-width: 600px) {
    padding: 16px;
  }
}

.modal_title {
  font-size: 20px;
  font-weight: 600;
  line-height: 130%;
  letter-spacing: -0.24px;
  color: #2F2D48;
  text-align: center;

  @media screen and (max-width: 600px) {
    font-size: 18px;
  }

  &:before {
    content: '';
    display: block;
    width: 72px;
    height: 72px;
    background: #fff;
    position: absolute;
    top: -32px;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 50%;

    @media screen and (max-width: 600px) {
      width: 40px;
      height: 40px;
      top: -20px;
    }
  }

  &:after {
    content: '';
    display: block;
    width: 26px;
    height: 26px;
    mask: url("../../../../../img/unlock_icon.svg");
    mask-size: contain;
    mask-repeat: no-repeat;
    mask-position: center;
    background: #000000;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: -13px;

    @media screen and (max-width: 600px) {
      width: 14px;
      height: 14px;
      top: -7px;
    }
  }
}

.modal_body h3 {
  font-size: 18px;
  font-weight: 500;
  line-height: 130%;
  letter-spacing: -0.24px;
  color: #2F2D48;
  text-align: center;

  @media screen and (max-width: 600px) {
    font-size: 16px;
  }
}

.modal_body p {
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  color: #7E7D8B;
  text-align: center;

  @media screen and (max-width: 600px) {
    font-size: 14px;
  }
}

.modal_body ol {
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 8px;
  counter-reset: item;
}

.modal_body ol li {
  counter-increment: item;
  list-style-type: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  color: #19191C;
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;


  &:before {
    content: counter(item);
    min-width: 32px;
    min-height: 32px;
    width: 32px;
    height: 32px;
    background: #F5F4F6;
    border-radius: 50%;
    color: #19191C;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    line-height: 150%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media screen and (max-width: 600px) {
    font-size: 14px;
  }
}


.modal_button {
  border-radius: 5px;
  display: flex;
  height: 44px;
  padding: 12px;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  background: transparent;
  outline: 0;
  cursor: pointer;
  width: 100%;
  transition: all .3s ease-in-out;
  box-sizing: border-box;
  border: 1px solid transparent;
  background: $accentColor;
  color: #fff;
  gap: 16px;
  margin-top: 8px;

  span {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 1px solid #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    transition-duration: .3s;

    svg {
      width: 12px;
      height: 12px;
      fill: #fff;
      transition-duration: .3s;
    }
  }

  &:disabled {
    background: rgba(25, 25, 25, 0.15);
    cursor: default;
  }

  &:hover:not(:disabled),
  &:hover:not(:disabled) {
    background: #E1E0F8;
    color: $accentColor;

    span {
      border-color: $accentColor;

      svg {
        fill: $accentColor;
      }
    }
  }

  @media screen and (max-width: 600px) {
    font-size: 14px;
  }
}

.modal_close {
  position: absolute;
  top: 16px;
  right: 16px;
  width: 18px;
  height: 18px;
  cursor: pointer;
  border: none;
  background: transparent;
  outline: none;

  svg {
    fill: #9E9E9E;
    width: 100%;
    height: 100%;
    transition-duration: .3s;
  }

  &:hover,
  &:focus {
    svg {
      fill: $accentColor;
    }
  }
}
