@import "@sitestyles";

.popup_imbra {
  position: relative;
  width: 1040px;
  color: #446077;
  font-weight: 500;
  background-color: $whiteColor;
  padding: 16px 27px 19px 16px;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  text-align: left;
}

.popup_imbra_title,
.popup_imbra_verification_title {
  font-weight: bold;
  font-size: 28px;
  text-align: center;
  color: $accentLeftColor;
  margin-top: 16px;
  margin-bottom: 16px;
}

.popup_imbra_text {
  p {
    margin-bottom: 10px;
  }
}

.popup_imbra_verification {

}

.popup_imbra_verification_title {
  font-size: 20px;
}

.popup_imbra_verification_steps {
  display: flex;
  justify-content: space-around;
  margin-bottom: 10px;
}

.popup_imbra_verification_steps_col {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  input {
    display: none;
  }
}

.popup_imbra_verification_img {
  position: relative;
  margin-bottom: 16px;
  width: 130px;
  height: 130px;

  img {
    width: 130px;
  }

  svg {
    width: 100px;
    height: 100px;
    position: absolute;
    top: 13%;
    left: 13%;
  }

  &::before {
    content: '';
    height: 130px;
    width: 130px;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.popup_imbra_verification_img.status_verification {
  svg {
    fill: #FFFFFF;
  }
  &::before {
    background: linear-gradient(to bottom, #edf1ff, #9db4f2);
  }
}

.popup_imbra_verification_img.imbra_download {
  svg {
    fill: #5fcf65;
  }
  &::before {
    background: linear-gradient(to bottom, #e8ffe8, #79d97d);
  }
}

.popup_imbra_verification_img.imbra_upload {
  svg {
    fill: #5ad1e0;
  }
  &::before {
    background: linear-gradient(to bottom, #dbfbff, #81d3de);
  }
}

.popup_imbra_verification_img.status_canceled {
  svg {
    fill: #d02745;
  }
  &::before {
    background: linear-gradient(to bottom, #ffcfd7, #ff4f7e);
  }
}

.popup_imbra_verification_img.status_verified {
  svg {
    fill: #5fc763;
  }
  &::before {
    background: linear-gradient(to bottom, #e6ffe6, #79d97e);
  }
}

.popup_imbra_verification_img.status_waiting {
  svg {
    fill: #91aadb;
  }
  &::before {
    background: linear-gradient(to bottom, #edf1ff, #9db4f2);
  }
}

.popup_imbra_btn {
  width: 310px;
  height: 45px;
  border: 2px solid #828282;
  box-sizing: border-box;
  border-radius: 5px;
  color: #828282;
  font-size: 13px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  img {
    width: 20px;
    margin-right: 8px;
  }

  svg {
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }

  &.active {
    color: $whiteColor;
    background: $backgroundButtonColor;
    border: none;

    &:hover {
      box-shadow: $smallBtnShadowHover;
    }
  }
}

.popup_imbra_btn.upload_successful {
  svg {
    fill: #828282;
  }
}

.popup_imbra_verification_status {
  font-size: 15px;
  font-weight: 600;
  height: 45px;
  display: flex;
  align-items: center;

  &.verified {
    color: #62C065;
  }
  &.canceled {
    color: #D02745;
  }
}

.popup_imbra_close {
  cursor: pointer;
  position: absolute;
  right: 16px;
  top: 16px;
  padding: 5px;
  background: #C2C2C2;
  width: 24px;
  height: 24px;
  -webkit-mask: url("../../../../../img/cross_white.svg") center no-repeat;
  mask: url("../../../../../img/cross_white.svg") center no-repeat;
  -webkit-mask-size: contain;
  mask-size: contain;

  &:hover {
    background: $attachTouchText;
  }

  @media screen and (max-width: 600px) {
    right: 14px;
    top: 14px;
    width: 20px;
    height: 20px;
  }
}

//Media styles ******************************

@media screen and (max-width: 1249px) {
  .popup_imbra {
    width: 683px;
    padding: 12px;
    overflow-y: scroll;
  }

  .popup_imbra_verification_steps {
    flex-direction: column;
  }

  .popup_imbra_verification_steps_col {
    margin-bottom: 16px;
  }

  .popup_imbra_verification_img {
    margin-bottom: 10px;
    width: 90px;
    height: 90px;

    &::before {
      width: 90px;
      height: 90px;
    }

    img {
      width: 90px;
    }

    svg {
      width: 60px;
      height: 60px;
      top: 0;
      left: 0;
      transform: translate(25%, 25%);
    }
  }
}

@media screen and (max-width: 720px) {
  .popup_imbra {
    width: 100%;
    height: 100%;
    padding: 10px;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
  }

  .popup_imbra_close {
    img {
      width: 17px;
    }
  }
}

@media screen and (max-height: 719px){
  .popup_imbra {
    height: 100%;
    overflow-y: auto;
  }
}

@media screen  and (max-width: 599px){
  .popup_imbra_title {
    font-size: 22px;
  }

  .popup_imbra_text {
    font-size: 13px;
  }

  .popup_imbra_verification_title {
    font-size: 18px;
  }

  .popup_imbra_verification_steps_col {
    label {
      width: 100%;
      display: flex;
      justify-content: center;
    }

    a {
      width: 100%;
      max-width: 310px;
    }
  }

  .popup_imbra_btn {
    width: 100%;
    max-width: 310px;
  }
}

@media screen  and (max-width: 899px) and (max-height: 450px){
  .popup_imbra {
    height: 100%;
    overflow-y: auto;
  }
}