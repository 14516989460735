@import "@sitestyles";

* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  outline: none;
}

html {
  background: $whiteColor;
}

html.is-ios {
  width: 100%;
  overflow: hidden;
  position: fixed;
  bottom: 0;
  left: 0;
  -webkit-font-smoothing: antialiased;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
}

body {
  font-family: $mainFont;
  font-size: 14px;
  background-color: $whiteColor;

  & * {
    font-family: $mainFont;
  }
}

body::-webkit-scrollbar {
  width: 7px;
  background: $littleGreyLittleBlueColor;
  border-radius: 20px;
}

body::-webkit-scrollbar-thumb {
  background: $accentLeftColor;
  border-radius: 20px;
}

button,
input {
  border: none;
  background-color: transparent;
  outline: none;
}

//body iframe{
//  display: none!important;
//}

a {
  color: inherit;
  text-decoration: none;
}

.overlay {
  display: none;
  width: 100%;
  height: 100vh;
  background-color: rgba(235, 237, 250, .5);
  z-index: 6;
  position: fixed;
  left: 0;
  top: 0;
  backdrop-filter: blur(4px);
}

.overlay.dark {
  background-color: $fadeColor;
}

.overlay.active {
  display: block;
}

.blur {
  -webkit-filter: blur(2px);
  filter: blur(2px);
}

.blur_unpaid {
  -webkit-filter: blur(30px);
  filter: blur(30px);
}

.chat_wrap {
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
}

.column-1 {
  width: 204px;
  position: relative;
  -webkit-transition: .5s;
  -o-transition: .5s;
  transition: .5s;
  -webkit-box-shadow: 6px 0px 12px rgba(47, 43, 99, 0.16);
  box-shadow: 6px 0px 12px rgba(47, 43, 99, 0.16);
  z-index: 2;
  grid-area: column1;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.block-block-list {
  overflow-y: auto;
  display: flex;
  flex: 1;
}

.column-1.small {
  width: 130px;
}

.column-2 {
  position: relative;
  width: 340px;
  min-height: 100%;
  background-color: $background1Color;
  grid-area: column2;
}

.column-2.c2_fx {
  display: flex;
  flex-direction: column;
}

.column-3 {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  background-color: $whiteColor;
  position: relative;
  grid-area: column3;
  /*height: calc(100vh - 39vh);
*/
  /*height: calc(100% - 12vh);
*/
  /*height: calc(100% - 12vh);
*/
}

.column_3_letters_page {
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
}

.column-3.empty {
  background-color: $whiteColor;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;
}

.column-3.empty:after {

}

.column-3.profile_man {
  background-color: $bleachColor;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.column-3.credits_page_column {
  overflow: hidden;
}

.ReactModal__Content.ReactModal__Content--after-open .column-3.profile_man {
  background-color: transparent;
  max-width: 1316px;
  padding-top: 100px;

}

.column-3.c3_fx {
  display: flex;
  flex-direction: column;
}

.column-4 {
  width: 340px;
  background-color: $background1Color;
  box-shadow: $notifyMainShadow;
  position: relative;
  grid-area: column4;
  display: flex;
  flex-direction: column;
  height: 100%;
  z-index: 100;
}

@mixin holiday-notify-top($name, $image-path) {
  .column-4.#{$name} {
    background-image: url(#{$image-path});
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: top;

    @media screen and (max-width: 1199px) {
      background-image: none;
    }
  }
}

@include holiday-notify-top('halloween', '../../img/holiday/halloween_notify_top.svg');
@include holiday-notify-top('lunar_new_year', '../../img/holiday/lunar_new_year_notify_top.svg');
@include holiday-notify-top('thanksgiving_day', '../../img/holiday/thanksgiving_day_notify_top.svg');
@include holiday-notify-top('christmas', '../../img/holiday/christmas_notify_top.svg');
@include holiday-notify-top('woman_day', '../../img/holiday/woman_day_notify_top.svg');
@include holiday-notify-top('valentine_day', '../../img/holiday/valentine_day_notify_top.svg');
@include holiday-notify-top('independence_day', '../../img/holiday/independence_day_notify_top.svg');
@include holiday-notify-top('easter', '../../img/holiday/easter_notify_top.svg');


.column-2.likeher {
  position: relative;
}

.column-2.hidden {
  display: none;
}

.column-3.likeher {
  background-color: $bleachColor;
}

.column-2.letters {
  width: 290px;
  height: 100%;
  overflow: hidden;
}

.mob_head {
  display: none;
  padding: 10px 15px;
  background: -o-linear-gradient(358.57deg, $accentLeftColor 0%, $accentRightColor 100%);
  background: linear-gradient(91.43deg, $accentLeftColor 0%, $accentRightColor 100%);
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/*.chat_wrap.need_confirm_email_4{
	display: grid;
	grid-template-columns: max-content max-content 1fr max-content;
	grid-template-areas: "column1 confirm_email confirm_email column4"
						  "column1 column2 column3 column4";
}*/

@media screen and (max-width: 767px) {
  .c3_woman_profile_second_block.middle {
    padding-bottom: 0 !important;
  }
}

.c3_woman_profile_second_block.middle .c3_woman_profile_second_block_row {
  margin-bottom: 0;
}

.c3_woman_profile_second_block.middle .c3_woman_profile_main_button_wrap {
  display: flex;
  justify-content: center;
  margin-bottom: 5px;
}

.c3_woman_profile_second_block.middle .c3_woman_profile_main_button_wrap .c3_woman_profile_main_button {
  cursor: pointer;
  background: $buttonBackgroundColor;
  width: 80%;
  height: 42px;
  border-radius: 4px;
  color: $whiteColor;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.01em;
}

.chat_wrap_confirm_email_wrap {
  grid-area: confirm_email;
  background-color: $background4Color;
  justify-content: space-between;
  display: none;
}

.chat_wrap_confirm_email_wrap.vip_banner {
  background-color: #fff;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.05);
}

.chat_wrap_confirm_email_wrap.recurring {
  background-color: #fff;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.05);
}

.need_confirm_email .chat_wrap_confirm_email_wrap {
  display: flex;
  flex: none;
}

.column_3_letters_page .chat_wrap_confirm_email_wrap {
  margin-bottom: unset;
}

.column-3.empty .chat_wrap_confirm_email_wrap {
  width: 100%;
}

.chat_wrap_confirm_email_item {
  width: 100%;
  display: flex;
  align-items: center;
  height: 80px;
  padding: 0 25px;
  position: relative;
  text-align: left;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.05);
}

.chat_wrap_confirm_email_item_img {
  margin-right: 12px;
}

.chat_wrap_confirm_email_item_title {
  font-weight: 500;
  letter-spacing: -0.03em;
  color: $accentLeftColor;
  margin-bottom: 2px;
}

.chat_wrap_confirm_email_item_title span {
  font-weight: 600;
}

.chat_wrap_confirm_email_item_text {
  font-size: 12px;
  letter-spacing: -0.03em;
  color: $defaultTextColorAlpha7;
}

.chat_wrap_confirm_email_item_confirm_btn {
  width: 146px;
  height: 42px;
  margin-left: 100px;
  border-radius: 4px;
  border: 1px solid $primaryColor;
  background-color: $whiteColor;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  letter-spacing: 0.01em;
  color: $primaryColor;
  cursor: pointer;
}

.chat_wrap_confirm_email_item_confirm_btn img {
  margin-right: 9px;
}

.chat_wrap_confirm_email_item_close {
  position: absolute;
  right: 25px;
  top: 50%;
  transform: translateY(-50%);
  width: 22px;
  height: 22px;
  background-image: url(../../img/gifts/cross-darkblue.svg);
  background-size: contain;
  background-position: center;
  cursor: pointer;
  background-repeat: no-repeat;
}

.chat_wrap_confirm_email_item_confirm_btn svg {
  margin-right: 9px;
  fill: $primaryColor;
  width: 20px;
  height: 20px;
}

.vip_banner .chat_wrap_confirm_email_item_img {
  margin-left: 25px;
  margin-right: 21px;
}

.vip_banner .button_get_credit {
  position: absolute;
  right: 82px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  background: $buttonBackgroundColor;
  width: 253px;
  height: 42px;
  border-radius: 4px;
  //border: 1px solid $primaryColor;
  color: $whiteColor;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.01em;
}

.head_logo img:nth-child(2) {
  width: 60px;
  display: block;
  margin-left: 6px;
  -webkit-transition: .5s;
  -o-transition: .5s;
  transition: .5s;
}

.c4_top_mob_pause {
  display: none;
}

.c4_top {
  height: 77px;
  font-weight: 600;
  font-size: 16px;
  line-height: 80px;
  text-align: center;
  text-transform: uppercase;
  color: $profileButtonActiveColor;
}

.chat_tutorial_wrap {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
  background-color: $fadeColor;
}

.chat_tutorial_steps_wrap {
  position: absolute;
  width: max-content;
  max-width: calc(100% - 15px);
}

.chat_tutorial_step_1 .chat_tutorial_steps_wrap {
  left: 4px;
  top: 50%;
}

.chat_tutorial_step_2 .chat_tutorial_steps_wrap {
  left: 6px;
  top: 19%;
}

.chat_tutorial_step_3_search .chat_tutorial_steps_wrap {
  left: -106px;
  top: 100%;
  max-width: unset;
  width: min-content;
}

.chat_tutorial_step_4 .chat_tutorial_steps_wrap {
  max-width: 715px;
  width: 490px;
  left: 210px;
  top: 0px;
}

.chat_tutorial_step_3_likeher .chat_tutorial_steps_wrap {
  display: flex;
  left: calc(50% + 200px);
  bottom: 18px;
  transform: translateX(-50%);
}

.chat_tutorial_steps_btn {
  width: 166px;
  height: 56px;
  line-height: 56px;
  background-color: $whiteColor;
  border: 2px solid $primaryColor;
  border-radius: 8px;
  font-weight: 600;
  font-size: 24px;
  color: $primaryColor;
  text-align: center;
  cursor: pointer;
  margin-left: auto;
  margin-top: 24px;
}

.chat_tutorial_step_3_likeher .chat_tutorial_steps_btn {
  margin-top: 0;
  margin-left: 24px;
}

.chat_tutorial_steps_content {
  background-color: $whiteColor;
  box-shadow: 0px 0px 25px rgba(14, 4, 143, 0.34), 0px 0px 10px rgba(61, 101, 153, 0.12);
  border-radius: 20px;
  display: flex;
  align-items: center;
}

.chat_tutorial_step_1 .chat_tutorial_steps_content {
  padding: 10px;
  padding-right: 45px;
}

.chat_tutorial_step_2 .chat_tutorial_steps_content {
  padding: 22px 42px 22px 10px;
}

.chat_tutorial_step_3_search .chat_tutorial_steps_content {
  flex-direction: column;
  padding: 30px;
  margin-top: -50px;
  position: relative;
  z-index: 2;
  width: 500px;
}

.chat_tutorial_step_4 .chat_tutorial_steps_content {
  flex-direction: row;
  margin-top: 0;
  width: 100%;
}

.chat_tutorial_step_3_likeher .chat_tutorial_steps_content {
  padding: 25px;
  display: block;
}

.chat_tutorial_step_1_buttons {
  padding-right: 10px;
  border-right: 2px solid $background3Color;
}

.chat_tutorial_step_4 .chat_tutorial_step_1_buttons {
  padding-right: 40px;
  padding-left: 14px;
}

.chat_tutorial_step_1_buttons_item {
  width: 246px;
  height: 75px;
  line-height: 75px;
  padding-left: 70px;
  background-repeat: no-repeat;
  font-weight: 500;
  font-size: 24px;
  color: $primaryColor;
  border-radius: 8px;
}

.chat_tutorial_step_4 .chat_tutorial_step_1_buttons_item {
  width: 38px;
  height: 36px;
  margin-bottom: 30px;
  padding-left: 0;
}

.chat_tutorial_step_4 .chat_tutorial_step_1_buttons_item:last-child {
  margin-bottom: 0;
}

.chat_tutorial_step_1_buttons_item.active {
  background-color: $primaryColor;
  color: $whiteColor;
}

.chat_tutorial_step_1_buttons_item.search {
  background-image: url(../../img/search-blue.svg);
  background-position: 22px center;
}

.chat_tutorial_step_1_buttons_item.search.active {
  background-image: url(../../img/search-white.svg);
}

.chat_tutorial_step_1_buttons_item.likeher {
  background-image: url(../../img/likeher-blue.svg);
  background-position: 19px center;
}

.chat_tutorial_step_1_buttons_item.likeher.active {
  background-image: url(../../img/likeher-white.svg);
}

.chat_tutorial_step_1_buttons_item.videoshow {
  background-image: url(../../img/videoshow-blue.svg);
  background-position: 21px center;
}

.chat_tutorial_step_1_buttons_item.allchats {
  background-image: url(../../img/allchats-blue.svg);
  background-position: 22px center;
}

.chat_tutorial_step_1_buttons_item.letters {
  background-image: url(../../img/letters-blue.svg);
  background-position: 19px center;
}

.chat_tutorial_step_1_buttons_item.letters_top {
  background-image: url(../../img/letters-darkblue.svg);
  background-position: center;
}

.chat_tutorial_step_1_buttons_item.gifts {
  background-image: url(../../img/gifts-blue.svg);
  background-position: center;
}

.chat_tutorial_step_1_buttons_item.like {
  background-image: url(../../img/like-blue.svg);
  background-position: center;
}


.chat_tutorial_steps_content_right {
  width: 100%;
}

.chat_tutorial_step_1 .chat_tutorial_steps_content_right {
  max-width: 306px;
  margin-left: 65px;
}

.chat_tutorial_step_2 .chat_tutorial_steps_content_right {
  max-width: 687px;
  margin-left: 35px;
}

.chat_tutorial_step_3_search .chat_tutorial_steps_content_right {
}

.chat_tutorial_step_4 .chat_tutorial_steps_content_right {
  max-width: 306px;
  margin-left: 40px;
}

.chat_tutorial_step_3_likeher .chat_tutorial_steps_content_right {
  max-width: 753px;
}

.chat_tutorial_steps_content_title {
  font-weight: 600;
  font-size: 24px;
  line-height: 140%;
  color: $accentLeftColor;
  margin-bottom: 12px;
}

.chat_tutorial_steps_content_text {
  font-weight: 500;
  font-size: 18px;
  line-height: 140%;
  color: $defaultTextColorAlpha8;
}

.chat_tutorial_skip_btn {
  width: 230px;
  height: 56px;
  line-height: 56px;
  position: absolute;
  right: 30px;
  bottom: 30px;
  background-color: $darkerIndigoTemplateColor;
  border-radius: 8px;
  padding-left: 20px;
  padding-right: 50px;
  background-image: url(../../img/skip-tutorial.svg);
  background-repeat: no-repeat;
  background-position: right 18px center;
  font-weight: bold;
  font-size: 18px;
  color: $whiteColor;
  text-shadow: 0px 1px 5px rgba(5, 4, 30, 0.66);
  cursor: pointer;
}

.chat_tutorial_step_3_buttons_top {
  display: flex;
  align-items: center;
  width: 100%;
  padding-bottom: 14px;
  border-bottom: 2px solid $background3Color;
  margin-bottom: 24px;
}

.chat_tutorial_step_3_btn_top {
  width: 31px;
  height: 30px;
  background-repeat: no-repeat;
  background-position: center;
  margin-right: 32px;
}

.chat_tutorial_step_3_btn_top.profile {
  background-image: url(../../img/c3lh-item-profile.svg);
  background-size: 27px;
}

.chat_tutorial_step_3_btn_top.photo {
  background-image: url(../../img/c3lh-item-pic.svg);
  background-size: 30px;
}

.chat_tutorial_step_3_btn_top.videos {
  background-image: url(../../img/c3lh-item-video.svg);
  background-size: 30px;
}

.chat_tutorial_step_3_buttons_top .c3lh_your_likes_item_back_chat_btn {
  width: 130px;
  height: 39px;
  line-height: 39px;
  font-size: 18px;
  font-weight: 600;
  margin-left: auto;
  pointer-events: none;
}

.chat_tutorial_step_3_buttons_top .c3lh_your_likes_item_back_chat_btn img {
  width: 23px;
  margin-right: 12px;
}

.chat_tutorial_step_3_search .c3lh_your_likes_item {
  width: 290px;
  margin: 0 auto;
  position: absolute;
  left: 30.7%;
  top: 70px;
}

.chat_tutorial_step_3_search .c3lh_your_likes_item_back {
  pointer-events: none;
}

.chat_tutorial_step_3_search .c3lh_your_likes_item_main {
  pointer-events: none;
}

.chat_tutorial_step_4 .c3lh_your_likes_item {
  position: absolute;
  left: 30.8%;
  top: 70px;
}

.chat_tutorial_step_3_buttons_bottom {
  display: flex;
  justify-content: space-between;
  padding-top: 17px;
  border-top: 2px solid $background3Color;
  margin-top: 11px;
}

.chat_tutorial_step_3_buttons_bottom .likeher_bottom_btn br {
  display: none;
}

.chat_tutorial_step_3_buttons_bottom .likeher_bottom_btn {
  width: 190px;
  height: 40px;
  pointer-events: none;
}

.chat_tutorial_step_1_mob_buttons {
  display: none;
}

.mob_notif_fixed svg {
  width: 30px;
  height: 30px;
  fill: $primaryColor;
}

.column-3.profile_man .girls_slider_wrap_global {
  margin-bottom: 11px;
}

.sf_page_wrap {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  &.hide_for_new_mobile_chat {
    //position: unset;
    //width: auto;
    height: calc(var(--vh, 1vh) * 100);
  }

  //@media screen and (min-width: 1140px){
  //	overflow-x: hidden;
  //	overflow-y: auto;
  //}
}

@media screen and (min-width: 1921px) {
  .column-4 {
    flex: none;
    overflow: hidden;
  }

  .carousel_container {
    max-width: 2897px;
    width: 100%;
  }

  .chat_tutorial_step_1 .chat_tutorial_steps_wrap {
    top: 520px;
  }

  .chat_tutorial_step_2 .chat_tutorial_steps_wrap {
    top: 230px;
  }

  .chat_tutorial_step_4 .c3lh_your_likes_item {
    width: 453px;
    left: 29.3%;
  }

  .chat_tutorial_step_3_search .c3lh_your_likes_item {
    width: 453px;
    left: 29.3%;
  }

  .chat_tutorial_step_3_search.chat_tutorial_step_3 .chat_tutorial_steps_wrap {
    left: -34px;
    top: calc(100% - 30px);
  }

  .chat_tutorial_step_4 .chat_tutorial_steps_wrap {
    left: 360px;
  }

  .chat_tutorial_step_3_search .chat_tutorial_steps_content {
    width: 520px;
  }

  .chat_tutorial_step_4 .chat_tutorial_steps_btn {
    margin-right: -30px;
  }
}

@media screen and (min-width: 3200px) {
  .chat_tutorial_step_4 .c3lh_your_likes_item {
    width: 577px;
    left: 24.6%;
  }

  .chat_tutorial_step_3_search .c3lh_your_likes_item {
    width: 577px;
    left: 24.6%;
  }

  .chat_tutorial_step_3_search .chat_tutorial_steps_content {
    width: 560px;
  }

  .chat_tutorial_step_3_search.chat_tutorial_step_3 .chat_tutorial_steps_wrap {
    left: 35px;
  }

  .chat_tutorial_step_4 .chat_tutorial_steps_wrap {
    left: 473px;
  }

  .chat_tutorial_step_4 .chat_tutorial_steps_btn {
    margin-right: -70px;
  }
}

@media screen and (min-width: 1200px) {
  .column_4_overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-color: rgba(255, 255, 255, .5);
    pointer-events: none;
    opacity: 0;
    transition: opacity .5s;
  }

  .c4_list_wrap_2:hover ~ .column_4_overlay {
    opacity: 1;
  }

  .overlay.active ~ .chat_wrap .column_4_overlay {
    opacity: 0 !important;
  }
}

@media screen and (max-width: 1849px) {
  .chat_tutorial_step_4 .c3lh_your_likes_item {
    left: calc(50% - 68px);
    transform: translateX(-50%);
  }

  .chat_tutorial_step_4 .chat_tutorial_steps_wrap {
    /*left: 54.5%;
*/
  }

  .chat_tutorial_step_3_search .c3lh_your_likes_item {
    left: calc(50% - 68px);
    transform: translateX(-50%);
  }
}

@media screen and (max-width: 1649px) {
  .column-2,
  .column-2.letters {
    width: 266px;
  }

  .column-2-wide {
    width: 340px;
    min-width: 340px;
  }

  .column-4 {
    width: 255px;
  }

  .chat_tutorial_step_4 .c3lh_your_likes_item {
    left: calc(50% - 27px);
  }

  .chat_tutorial_step_3_search .c3lh_your_likes_item {
    left: calc(50% - 27px);
  }

  .chat_tutorial_step_4 .chat_tutorial_steps_wrap {
    /*left: 58.5%;
*/
  }
}

@media screen and (max-width: 1599px) {
  .chat_tutorial_step_1_buttons_item {
    height: 68px;
    line-height: 68px;
    padding-left: 64px;
    font-size: 20px;
    width: 225px;
  }

  .chat_tutorial_step_1_buttons_item.search {
    background-size: 24px;
  }
  .chat_tutorial_step_1_buttons_item.likeher {
    background-size: 24px;
    background-position: 22px center;
  }
  .chat_tutorial_step_1_buttons_item.videoshow {
    background-size: 24px;
    background-position: 22px center;
  }
  .chat_tutorial_step_1_buttons_item.allchats {
    background-size: 24px;
    background-position: 22px center;
  }
  .chat_tutorial_step_1_buttons_item.letters {
    background-size: 24px;
    background-position: 22px center;
  }

  .chat_tutorial_steps_content_title {
    font-size: 20px;
  }

  .chat_tutorial_steps_content_text {
    font-size: 16px;
  }

  .chat_tutorial_steps_btn {
    height: 50px;
    line-height: 48px;
    font-size: 20px;
  }

  .chat_tutorial_step_1 .chat_tutorial_steps_wrap {
    top: unset;
    bottom: 10px;
  }

  .chat_tutorial_skip_btn {
    width: 180px;
    height: 50px;
    line-height: 50px;
    padding-left: 20px;
    background-size: 24px;
    font-size: 16px;
    right: 20px;
    bottom: 20px;
  }

  .chat_tutorial_step_3_search .chat_tutorial_steps_content_right {
  }

  .chat_tutorial_step_1 .chat_tutorial_steps_content_right {
    margin-left: 50px;
  }

  .chat_tutorial_step_2 .chat_tutorial_steps_content_right {
    margin-left: 42px;
  }

  .chat_tutorial_step_1_buttons_item.letters_top {
    background-size: 28px;
  }
  .chat_tutorial_step_1_buttons_item.gifts {
    background-size: 25px;
  }
  .chat_tutorial_step_1_buttons_item.like {
    background-size: 28px;
  }

  .chat_tutorial_step_4 .chat_tutorial_step_1_buttons {
    padding-right: 24px;
    padding-left: 4px;
  }

  .chat_tutorial_step_4 .chat_tutorial_step_1_buttons_item {
    margin-bottom: 20px;
  }

  .chat_tutorial_step_3_buttons_bottom .likeher_bottom_btn {
    width: 164px;
  }

  .chat_tutorial_step_3_likeher .chat_tutorial_steps_content {
    max-width: 690px;
  }

  .chat_tutorial_step_3_likeher .chat_tutorial_steps_wrap {
    flex-direction: column-reverse;
    left: unset;
    right: 325px;
    transform: unset;
  }

  .chat_tutorial_step_3_likeher .chat_tutorial_steps_btn {
    margin-left: auto;
    margin-bottom: 24px;
  }
}

@media screen and (max-width: 1499px) {
  .column-1 {
    width: 130px;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 4;
    background-color: $whiteColor;
  }

  .column-1:hover {
    width: 204px;
  }

  .chat_wrap {
    //padding-left: 130px;
  }

  .chat_wrap_confirm_email_text {
    font-size: 12px;
  }

  .chat_tutorial_step_4 .c3lh_your_likes_item {
    left: calc(50% - 64px);
  }

  .chat_tutorial_step_3_search .c3lh_your_likes_item {
    left: calc(50% - 64px);
  }

  .chat_tutorial_step_4 .chat_tutorial_steps_wrap {
    /*left: 56%;
*/
  }

  .chat_wrap_confirm_email_item {
    height: 80px;
  }

  .chat_wrap_confirm_email_item {
    padding: 0 15px;
  }

  .chat_wrap_confirm_email_item_img {
    width: 45px;
    margin-right: 8px;
  }

  .chat_wrap_confirm_email_item_title {
    font-size: 14px;
  }

  .chat_wrap_confirm_email_item_text {
    font-size: 11px;
  }

  .chat_wrap_confirm_email_item_confirm_btn {
    width: 119px;
    height: 35px;
    font-size: 12px;
    margin-left: 32px;
  }

  .chat_wrap_confirm_email_item_confirm_btn img {
    width: 15px;
    margin-right: 7px;
  }

  .chat_wrap_confirm_email_item_close {
    width: 16px;
    height: 16px;
    right: 15px;
  }

  .ReactModal__Content.ReactModal__Content--after-open .column-3.profile_man {
    padding-top: 82px;
    max-width: 95% !important;
    margin: 0 auto;
  }
}

@media screen and (max-width: 1399px) {
  .chat_tutorial_step_4 .c3lh_your_likes_item {
    width: 258px;
  }

  .chat_tutorial_step_3_search .c3lh_your_likes_item {
    width: 258px;
  }

  .chat_tutorial_step_4 .chat_tutorial_steps_wrap {
    left: 178px;
  }

  .chat_tutorial_step_3_likeher .chat_tutorial_steps_wrap {
    right: 270px;
  }
}

@media screen and (max-width: 1349px) {
  .chat_wrap_confirm_email_item_text {
    font-size: 12px;
  }

  /*.chat_wrap_confirm_email_item{
      padding: 0 8px;
  }*/

  .chat_wrap_confirm_email_item_btn {
    width: 84px;
    height: 25px;
    line-height: 25px;
    font-size: 12px;
  }

  .chat_wrap_confirm_email_item + .chat_wrap_confirm_email_item {
    margin-left: 11px;
  }

  .chat_wrap_confirm_email_item.item_email {
    -webkit-background-size: auto 60%;
    background-size: auto 60%;
    background-position: right 80px center;
  }

  .chat_wrap_confirm_email_item.item_fill_profile {
    background-position: right 110px center;
    background-size: auto 50%;
  }

  .chat_tutorial_step_3_likeher .chat_tutorial_steps_wrap {
    right: 230px;
  }

  .vip_banner .chat_wrap_confirm_email_item_img {
    margin: 0 10px;
  }

  .vip_banner .button_get_credit {
    right: 45px;
    width: 220px;
    font-size: 13px;
  }
}

@media screen and (max-width: 1299px) {
  .chat_tutorial_step_4 .c3lh_your_likes_item {
    left: calc(50% - 63px);
  }

  .chat_tutorial_step_3_search .c3lh_your_likes_item {
    left: calc(50% - 63px);
  }

  .chat_tutorial_step_3_search.chat_tutorial_step_3 .chat_tutorial_steps_wrap {
    display: flex;
  }

  .chat_tutorial_step_3_search.chat_tutorial_step_3 .chat_tutorial_steps_btn {
    margin-top: -50px;
    margin-left: 24px;
  }

  .chat_tutorial_step_4 .chat_tutorial_step_1_buttons {
    padding-right: 24px;
    padding-left: 4px;
  }

  .chat_tutorial_step_4 .chat_tutorial_steps_wrap {
    left: 189px;
  }

  .chat_wrap_confirm_email_item_title {
    font-size: 12px;
  }

  .chat_wrap_confirm_email_item_text {
    font-size: 10px;
  }
}

@media screen and (max-width: 1299px) and (min-height: 1000px) {
  .chat_tutorial_step_1 .chat_tutorial_steps_wrap {
    bottom: unset;
    top: 500px;
  }

  .chat_tutorial_step_3_likeher .chat_tutorial_steps_wrap {
    bottom: 100px;
    right: 230px;
  }
}

@media screen and (max-width: 1249px) {
  .chat_wrap_confirm_email_item_text {
    width: 270px;
  }
}

@media (hover: none) and (pointer: coarse) {
  @media screen and (min-width: 1140px) {
    #root {
      //position: fixed;
      //width: 100vw;
    }

    .chat_wrap {
      height: calc(100% - 127px);
      width: 100%;
      padding-left: 0;
    }

    .mob_head {
      display: flex;
      height: 64px;
      padding: 0;
    }

    .column-1 {
      display: none;
    }

    .mob_head_left {
      display: none;
    }

    .head_logo img:first-child {
      height: 32px;
    }

    //.head_logo img:nth-child(2) {
    //  width: 50px;
    //}

    .column-2.c2_fx {
      display: flex;
      flex-direction: column;
    }

    .column-2 {
      position: absolute;
      left: -340px;
      z-index: 12;
      transition: left .5s;
      min-height: unset;
      height: calc(100% - 127px);
      top: 64px;
      border-radius: 0 8px 0 0;
    }

    .column-2.active {
      left: 0;
    }

    .block-block-list .column-2 {
      height: 100%;
      top: 0;
    }

    .column-2.likeher {
      position: absolute;
    }

    .column-3.c3_fx {
      display: flex;
      flex-direction: column;
      height: 100%;
    }

    .overlay {
      height: calc(100vh - 127px);
      height: calc(calc(var(--vh, 1vh) * 100) - 127px);
      top: 64px;
    }

    .new_mob_menu + .column-4 {
      display: none;
    }
  }
}

@media screen and (max-width: 1199px) {
  .column-4 {
    position: absolute;
    right: -340px;
    transition: .3s;
    z-index: 999;
    top: 0;
    width: 340px;
    box-shadow: unset;
    height: 100%;
  }

  .c4_minichat .c3_head {
    padding-right: 30px;
  }

  .column-4.active {
    right: 0;
  }

  .chat_tutorial_step_4 .c3lh_your_likes_item {
    left: calc(35% - 49px);
  }

  .chat_tutorial_step_3_search .c3lh_your_likes_item {
    left: calc(35% - 56px);
  }

  .chat_tutorial_step_4 .chat_tutorial_steps_wrap {
    /*left: 43%;
*/
  }

  .chat_tutorial_step_3_likeher .chat_tutorial_steps_wrap {
    bottom: 100px;
    right: 20px;
  }

  .chat_wrap_confirm_email_item_close {
    position: static;
    transform: unset;
    margin-left: auto;
  }

  .chat_wrap_confirm_email_item_text {
    width: auto;
  }

  .chat_wrap_confirm_email_item_confirm_btn {
    margin: 0 12px 0 24px;
  }

  .vip_banner .button_get_credit {
    right: 82px;
  }
}

@media screen and (max-width: 1139px) {
  #root {
    //position: fixed;
    //width: 100vw;
  }

  .chat_wrap {
    height: calc(100% - 127px);
    width: 100%;
    padding-left: 0;
  }

  .mob_head {
    display: flex;
    height: 64px;
    padding: 0;
  }

  .mob_notif_fixed.active {
    width: 90px;
    height: 64px;
    top: 0;
    border-radius: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $background1Color;
  }

  .mob_notif_fixed.active:after {
    content: "";
    display: block;
    width: 15px;
    height: 15px;
    position: absolute;
    left: -14px;
    bottom: -1px;
    background-color: $background1Color;
    -webkit-mask: url(../../img/mh-bg-shape.svg) left bottom no-repeat;
    transform: scale(-1, 1);
  }

  .column-1 {
    display: none;
  }

  .mob_head_left {
    display: none;
  }

  .head_logo img:first-child {
    height: 32px;
  }

  //.head_logo img:nth-child(2) {
  //  width: 50px;
  //}

  .column-2.c2_fx {
    display: flex;
    flex-direction: column;
  }

  .overlay {
    height: calc(100vh - 127px);
    height: calc(calc(var(--vh, 1vh) * 100) - 127px);
    top: 64px;
  }

  .column-2.likeher {
    position: absolute;
    z-index: 7;
    left: -100%;
  }

  .column-2.likeher.active {
    left: 0;
  }

  .need_confirm_email .chat_wrap_confirm_email_wrap {
    //display: none;
  }

  .column-4 {
    top: 64px;
    height: calc(100% - 127px);
    border-radius: 8px 0 0 0;
  }

  .c4_top_mob_pause {
    display: block;
    width: 90px;
    height: 30px;
    line-height: 30px;
    background-color: $whiteColor;
    border: 1px solid $primaryColor;
    box-shadow: 0px 2px 5px rgba(0, 49, 123, 0.1);
    border-radius: 5px;
    font-weight: 600;
    font-size: 10px;
    letter-spacing: -0.02em;
    color: $primaryColor;
    text-transform: none;
    cursor: pointer;
  }

  .c4_top {
    height: 50px;
    line-height: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 14px;
    margin: 0 16px;
    border-bottom: 2px solid #fff;
    padding-top: 3px;
    position: relative;
    flex: none;
  }

  .c4_top:after {
    content: '';
    display: block;
    width: 8px;
    height: 14px;
    position: absolute;
    right: 3px;
    top: 50%;
    transform: translateY(-50%);
    transition: transform .3s;
    mask: url(../../img/notif-arr-violet.svg);
    mask-size: contain;
    mask-position: center;
    mask-repeat: no-repeat;
    background: $profileButtonActiveColor;
  }

  .c4_top.active:after {
    transform: translateY(-50%) rotate(90deg);
  }

  .c4_top.active {
    border-bottom: none;
  }

  .ReactModal__Content.ReactModal__Content--after-open .column-3.profile_man {
    padding-top: 102px;

  }

  .column-3.profile_man .girls_slider_wrap_global {
    margin-bottom: 5px;
    padding-left: 0;
    padding-right: 0;
  }

  .chat_tutorial_step_1_mob_buttons {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 0 140px;
  }

  .chat_tutorial_step_1_mob_btn_empty {
    width: 50px;
  }

  .chat_tutorial_step_1_mob_btn {
    width: 70px;
    height: 70px;
    background-color: $whiteColor;
    box-shadow: 0px 0px 25px rgba(14, 4, 143, 0.34), 0px 0px 10px rgba(61, 101, 153, 0.12);
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: none;
    margin: 0 30px;
  }

  .chat_tutorial_step_1_mob_btn_txt {
    font-size: 10px;
    letter-spacing: -0.03em;
    color: $stepMobButtonTextColor;
  }

  .chat_tutorial_step_1_mob_btn_img {
    width: 30px;
    height: 30px;
    background-position: center;
    background-repeat: no-repeat;
    margin-bottom: 4px;
  }

  .chat_tutorial_step_1_mob_btn.search .chat_tutorial_step_1_mob_btn_img {
    background: $stepMobButtonTextColor;
    mask: url(../../img/search-darkblue.svg) center no-repeat;
    mask-size: 29px;
    -webkit-mask: url(../../img/search-darkblue.svg) center no-repeat;
    -webkit-mask-size: 29px;
  }

  .chat_tutorial_step_1_mob_btn.likeher .chat_tutorial_step_1_mob_btn_img {
    background: $stepMobButtonTextColor;
    mask: url(../../img/likeher-darkblue.svg) center no-repeat;
    mask-size: 27px;
    -webkit-mask: url(../../img/likeher-darkblue.svg) center no-repeat;
    -webkit-mask-size: 27px;
  }

  .chat_tutorial_step_1_mob_btn.letters .chat_tutorial_step_1_mob_btn_img {
    background: $stepMobButtonTextColor;
    mask: url(../../img/letters-darkblue-2.svg) center no-repeat;
    mask-size: 29px;
    -webkit-mask: url(../../img/letters-darkblue-2.svg) center no-repeat;
    -webkit-mask-size: 29px;
  }

  .chat_tutorial_step_1_mob_btn.chat .chat_tutorial_step_1_mob_btn_img {
    background: $stepMobButtonTextColor;
    mask: url(../../img/chat-darkblue.svg) center no-repeat;
    mask-size: 29px;
    -webkit-mask: url(../../img/chat-darkblue.svg) center no-repeat;
    -webkit-mask-size: 29px;
  }

  .tutorial_step_2_bottom_nav_global .chat_tutorial_step_1_mob_buttons {
    //justify-content: space-around;
  }

  .tutorial_step_2_bottom_nav_global .chat_tutorial_step_1_mob_btn {
    //margin: 0 70px;
  }

  .chat_tutorial_step_1_mob_btn_empty {
    margin: 0 25px;
  }


}

@media (hover: none) and (pointer: coarse) {
  @media screen and (min-width: 1140px) {
    #root {
      //position: fixed;
      //width: 100vw;
    }

    .chat_wrap {
      height: calc(100% - 127px);
      width: 100%;
      padding-left: 0;
    }

    .mob_head {
      display: flex;
      height: 64px;
      padding: 0;
    }

    .column-1 {
      display: none;
    }

    .mob_head_left {
      display: none;
    }

    .head_logo img:first-child {
      height: 32px;
    }

    //.head_logo img:nth-child(2) {
    //  width: 50px;
    //}

    .column-2.c2_fx {
      display: flex;
      flex-direction: column;
    }

    .column-2 {
      position: absolute;
      left: -340px;
      z-index: 12;
      transition: left .5s;
      min-height: unset;
      height: calc(100% - 139px);
      top: 64px;
      border-radius: 0 8px 0 0;
    }

    .column-2.active {
      left: 0;
    }

    .block-block-list .column-2 {
      height: 100%;
      top: 0;
    }

    .column-2.likeher {
      position: absolute;
    }

    .column-3.c3_fx {
      display: flex;
      flex-direction: column;
      height: 100%;
    }

    .overlay {
      height: calc(100vh - 139px);
      height: calc(calc(var(--vh, 1vh) * 100) - 139px);
      top: 64px;
    }

    .new_mob_menu + .column-4 {
      display: none;
    }
  }
}

@media screen and (max-width: 999px) {

  .chat_wrap {
    padding-left: 0;
  }
  .column-1 {
    opacity: 0;
    background-color: $whiteColor;
    position: absolute;
    top: 0;
    left: -204px;
    width: 204px;
    z-index: 10;
    transition: .5s;
  }

  .column-1.active {
    top: 0;
    left: 0;
    opacity: 1;
    z-index: 999;
  }

  .column-1.active .c1_close {
    display: block;
    visibility: visible;
  }

  .column-2 {
    position: absolute;
    left: -340px;
    z-index: 4;
    transition: left .5s;
    min-height: unset;
    height: calc(100% - 127px);
    top: 64px;
  }

  .column-2.active {
    left: 0;
  }


  .mob_head {
    padding: 0;
    height: 64px;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
  }

  .mob_head_left {
    display: none;
  }

  .need_confirm_email .column-3.empty {
    padding-top: 0;
  }

  .block-block-list .column-2 {
    display: flex;
    flex-direction: column;
  }

  .block-block-list .column-3 {
    display: flex;
    flex-direction: column;
  }

  .block-block-list {
    display: flex;
    height: 100%;
  }

  .chat_tutorial_step_1_buttons {
    display: none;
  }

  .chat_tutorial_step_1 .chat_tutorial_steps_content_right {
    margin-left: 0;
    max-width: 245px;
  }

  .chat_tutorial_steps_content {
    padding: 0;
    box-shadow: none;
  }

  .chat_tutorial_steps_content_title {
    font-size: 15px;
    margin-bottom: 5px;
  }

  .chat_tutorial_steps_content_text {
    font-size: 12px;
  }

  .chat_tutorial_steps_wrap {
    background-color: $whiteColor;
    padding: 16px;
    box-shadow: 0px 0px 25px rgba(14, 4, 143, 0.34), 0px 0px 10px rgba(61, 101, 153, 0.12);
    border-radius: 16px;
  }

  .chat_tutorial_steps_btn {
    width: 90px;
    height: 30px;
    line-height: 28px;
    font-size: 14px;
    border-width: 1px;
    margin-top: 12px;
    position: relative;
    z-index: 2;
  }

  .chat_tutorial_steps_content {
    position: relative;
    z-index: 2;
  }

  .chat_tutorial_skip_btn {
    width: 140px;
    height: 35px;
    line-height: 35px;
    right: 20px;
    top: 20px;
    font-size: 12px;
    background-size: 16px;
    background-position: right 10px center;
    padding-right: 0;
    padding-left: 14px;
  }

  .chat_tutorial_step_1 .chat_tutorial_steps_wrap {
    top: unset;
    left: 50%;
    transform: translateX(-50%);
    bottom: 112px;
  }

  .chat_tutorial_steps_wrap:after {
    content: "";
    display: block;
    width: 84px;
    height: 72px;
    position: absolute;
    bottom: -25px;
    left: 50%;
    border-radius: 5px;
    background: linear-gradient(45deg, $transparentColor 50%, rgba(255, 255, 255, 1) 50%);
    transform: translateX(-90%);
  }

  .chat_tutorial_step_2 .chat_tutorial_steps_wrap {
    top: unset;
    bottom: 119px;
    left: 50%;
    transform: translateX(-50%);
  }

  .chat_tutorial_step_2 .chat_tutorial_steps_content_right {
    margin-left: 0;
    max-width: 260px;
  }

  .chat_tutorial_step_2 .chat_tutorial_steps_wrap {
    background-color: $whiteColor;
    padding: 16px;
    box-shadow: 0px 0px 25px rgba(14, 4, 143, 0.34), 0px 0px 10px rgba(61, 101, 153, 0.12);
    border-radius: 16px;
  }

  .chat_tutorial_step_2 .chat_tutorial_steps_content {
    padding: 0;
    box-shadow: none;
  }

  .chat_tutorial_step_2 .chat_tutorial_steps_wrap:after {
    background: linear-gradient(-45deg, $transparentColor 50%, rgba(255, 255, 255, 1) 50%);
  }

  .chat_tutorial_step_2 .chat_tutorial_step_1_mob_buttons {
    justify-content: space-around;
  }

  .chat_tutorial_step_1_mob_btn_empty {
    //width: 70px;
    //height: 70px;
  }

  .chat_tutorial_step_4 .c3lh_your_likes_item {
    top: 134px;
    width: 258px;
    left: 50.5%;
    transform: unset;
  }

  .chat_tutorial_step_4 .chat_tutorial_steps_wrap {
    display: block;
    top: calc(100% + 25px);
    width: 288px;
    left: 50%;
    transform: translateX(-50%);
  }

  .chat_tutorial_step_4 .chat_tutorial_steps_content {
    margin-left: 0;
    padding: 0;
  }

  .chat_tutorial_step_4 .chat_tutorial_steps_content_right {
    margin-left: 0;
    max-width: 251px;
  }

  .chat_tutorial_step_4 .chat_tutorial_steps_wrap:after {
    bottom: unset;
    top: -25px;
    background: linear-gradient(135deg, $transparentColor 50%, rgba(255, 255, 255, 1) 50%);
    transform: translateX(-30%);
  }

  .chat_tutorial_step_3.chat_tutorial_step_3_search {
    display: none;
  }

  .chat_tutorial_step_3.chat_tutorial_step_3_likeher {
    display: none;
  }

  .column-2.letters {
    width: 320px;
    background-color: transparent;
    height: calc(100svh - 127px);
    z-index: 6;
  }


}

@media screen and (max-width: 899px) {
  .column-2.active .c2_close {
    display: block;
    visibility: visible;
  }
}

@media screen and (max-width: 767px) {
  .chat_wrap {
    height: calc(100% - 114px);
  }

  .column-2.letters {
    height: calc(100% - 114px);
  }

  .column-2 {
    height: calc(100% - 114px);
  }

  .overlay {
    height: calc(100vh - 114px);
    height: calc(calc(var(--vh, 1vh) * 100) - 114px);
  }

  .column-4 {
    height: calc(100% - 114px);
  }

  .ReactModal__Content.ReactModal__Content--after-open .column-3.profile_man {
    padding-top: 52px;
  }

  .chat_tutorial_step_1_mob_buttons {
    justify-content: space-evenly;
    padding: 0;
  }

  .chat_tutorial_step_1_mob_btn_empty {
    display: none;
  }

  .tutorial_step_2_bottom_nav_global .chat_tutorial_step_1_mob_btn_empty {
    display: block;
  }
}

@media screen and (max-width: 699px) {
  .vip_banner .chat_wrap_confirm_email_item {
    padding: 0 10px 0 0;
    height: 50px;
  }

  .vip_banner .chat_wrap_confirm_email_item_img {
    margin-right: 10px;
    margin-left: 10px;
  }

  .vip_banner .button_get_credit {
    background: linear-gradient(90.32deg, #FEB930 0.31%, #FE8730 115.03%), #0965B1;
    width: 98px;
    height: 28px;
    font-size: 10px;
    line-height: 12px;
    text-align: center;
    right: 37px;
  }

  .vip_banner .chat_wrap_confirm_email_item_title,
  .vip_banner .chat_wrap_confirm_email_item_text {
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: -0.03em;
    color: #00317B;
  }

  .vip_banner .chat_wrap_confirm_email_item_title span {
    color: #FEB930;
  }

  .chat_tutorial_step_4 .chat_tutorial_steps_wrap {
    /*top: 605px;
*/
  }

  .chat_tutorial_step_4 .c3lh_your_likes_item {
    top: 173px;
    left: 50.8%;
  }
}

@media screen and (max-width: 599px) {
  .column-3 {
    width: 100%;
  }

  .column-4 {
    right: -264px;
    width: 264px;
    height: calc(100% - 100px);
    top: 50px;

    &.full {
      //height: 100%;
      height: calc(100% - 50px);

      //top: unset;
      //bottom: 0;
      position: fixed;
    }

    &.hide_for_new_mobile_chat {
      display: none;
      height: calc(100% - 50px);
      position: fixed;

      &.active {
        display: flex;
        //grid-template-rows: 1fr;
        flex: 1;
      }
    }
  }


  .chat_wrap {
    height: calc(100% - 100px);
    overflow: hidden;

    &.chat_wrap_mobile {
      height: 100%;
      //min-height: calc(var(--vh, 1vh) * 100);
      //position: relative;
      //display: flex;
      //align-items: stretch;
      //display: grid;
      //grid-template-columns: 100vw 100vw 100vw;
      //overflow: hidden;
    }
  }

  .mob_head {
    height: 50px;
  }

  .column-3.empty:after {
    -webkit-background-size: 100%;
    background-size: 100%;
  }

  .column-2.letters {
    height: calc(100% - 100px);
    z-index: 6;
  }

  .column-2 {
    top: 50px;
    height: calc(100% - 100px);
  }

  .overlay {
    height: calc(100vh - 100px);
    height: calc(calc(var(--vh, 1vh) * 100) - 100px);
    top: 50px;

    &.overlay_new {
      top: 56px;
    }

    &.full {
      height: calc(100vh - 50px);
    }
  }

  .mob_notif_fixed.active {
    width: 64px;
    height: 50px;
  }

  .column-1 {
    top: 50px;
    height: calc(100vh - 95px);
    height: calc(calc(var(--vh, 1vh) * 100) - 95px);
  }

  .column-1.active {
    top: 50px;
    height: calc(100vh - 95px);
    height: calc(calc(var(--vh, 1vh) * 100) - 95px);
  }

  .head_logo img:nth-child(1) {
    width: auto;
    height: 30px;
  }

  //.head_logo img:nth-child(2) {
  //  width: 32px;
  //}

  .chat_tutorial_step_1_mob_buttons {
    padding: 0 5px;
  }

  .chat_tutorial_step_4 .chat_tutorial_steps_wrap {
    right: 0;
    left: unset;
    transform: unset;
    max-width: calc(100vw - 20px);
    top: calc(100% + 35px);
  }

  .chat_tutorial_step_4 .c3lh_your_likes_item {
    width: calc(50vw - 15px);
    top: 60px;
    left: 50.5%;
  }

  .chat_tutorial_step_4_item_empty {
    width: calc(50vw - 15px);
    padding-top: 156%;
    visibility: hidden;
    display: none;
  }

  .chat_tutorial_step_1 .chat_tutorial_steps_content {
    padding: 0;
  }

  .tutorial_step_2_bottom_nav_global .chat_tutorial_step_1_mob_btn {
    margin: 0;
  }

  .chat_tutorial_step_1_mob_btn_txt {
    font-weight: 600;
    letter-spacing: 0.05em;
  }

  .chat_tutorial_skip_btn {
    right: 15px;
    top: 15px;
  }

  .chat_tutorial_step_2 .chat_tutorial_steps_wrap {
    padding: 24px;
  }

  .chat_tutorial_step_1 .chat_tutorial_steps_wrap {
    padding: 24px;
  }

  .ReactModal__Content.ReactModal__Content--after-open .column-3.profile_man {
    padding-top: 52px;
    top: 0;
    height: 85vh;
    max-height: 85vh;
    left: 50%;
    transform: translateX(-50%);
    position: fixed;
    border-radius: 10px;
    overflow: hidden;
  }

  .column-3.profile_man .girls_slider_wrap_global {
  }

  .chat_wrap_confirm_email_item_close {
    flex: none;
  }

  .chat_wrap_confirm_email_item {
    padding: 0 8px;
    height: 50px;
  }

  .chat_wrap_confirm_email_item_img {
    width: 35px;
  }
}

@media screen and (max-width: 899px) and (max-height: 500px) and (min-width: 420px) {
  .chat_wrap {
    height: 100%;
    width: calc(100% - 95px);
    margin-left: 50px;
  }

  .chat_wrap.chat_wrap_mobile {
    height: 100%;
    width: 100%;
    margin-left: 0;
  }

  .column-3.empty {
    flex-direction: row;
  }

  .mob_notif_fixed img {
    height: unset;
    width: 24px;
  }

  .overlay {
    top: 0;
    height: 100%;
    width: calc(100% - 95px);
    left: 50px;
    z-index: 998;
  }

  .column-2 {
    top: 0;
    height: 100%;
  }

  .column-2.active {
    height: 100%;
    left: 50px;
    z-index: 999;
  }

  .mob_head {
    position: absolute;
    left: 0;
    top: 0;
    width: 50px;
    height: 100%;
    padding: 0;
    display: flex;
    flex-direction: column-reverse;
  }

  .head_logo img:nth-child(2) {
    display: none;
  }

  .head_logo img:nth-child(1) {
    width: 32px;
  }

  .head_empty {
    width: 100%;
    height: 60px;
  }

  .left_menu_chat_list {
    /*display: none;
*/
    margin-left: 0;
    margin-top: 10px;
  }

  .left_menu_chat_list img {
    margin: auto;
  }

  .mob_head_left {
  }

  .mob_notif_fixed {
    width: 45px;
    height: 34px;
    left: 0;
    top: 11px;
    border-radius: 0 5px 5px 0;
  }

  .column-4 {
    right: unset;
    left: -300px;
    top: 0;
    border-radius: 0 8px 0 0;
    height: 100%;
    width: 239px;
    transition: .5s;
  }

  .column-4.active {
    left: 50px;
  }

  .mob_notif_fixed.active {
    width: 50px;
    height: 60px;
  }

  .mob_notif_fixed.active:after {
    bottom: unset;
    top: 100%;
    left: unset;
    right: -1px;
    transform: scale(-1);
  }
}

@media (hover: none) and (pointer: coarse) {
  @media screen and (min-width: 1140px) {
    .column-2 {
      position: static;
      height: 100%;
      border-radius: 0;
    }
  }
}

@media (orientation: landscape) and (max-height: 200px) and (min-width: 420px) {
  .need_confirm_email .chat_wrap_confirm_email_wrap {
    display: none;
  }
}


/* global styles */
.c3lh_disabled {
  pointer-events: none;
  opacity: 0.4;
}

/* global styles */
.c3lh_disabled_like {
  opacity: 0.4;
}

.disabled_events {
  pointer-events: none;
  cursor: pointer;
}

/*

.c2_chat_categories{
	display: none;
}

.mob_bottom_nav{
	display: none;
}

.mob_head_nav{
	display: none;
}

.c4_top_mob_pause{
	display: none;
}

*/


//ios15

@media screen and (max-width: 599px) {
  html, body {
    overflow: hidden;
    //height: calc(1vh * 100);
    //height: calc(var(--vh, 1vh) * 100);

    height: 100vh;
    min-height: 100vh;
  }

  .main_page_content {
    height: auto;
    overflow-y: auto;
  }
}

.unsubscribe-page {
  padding: 16px;
  background: #f4f4f4;
  flex: 1 1;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 700px) {
    padding: 8px;
  }
}

.unsubscribe-page-wrap {
  background: #FFFFFF;
  box-shadow: 0 6px 17px rgba(47, 43, 99, 0.16);
  border-radius: 10px;
  padding: 24px 0 70px;
  overflow-y: scroll;
  overflow-x: hidden;

  @media screen and (max-width: 700px) {
    padding: 8px 0;
  }

  &::-webkit-scrollbar {
    width: 3px;
    background: #F7F8FD;
    border-radius: 5px;
    margin-right: 3px;
  }

  &::-webkit-scrollbar-thumb {
    background: $scrollbarThumbColor;
    border-radius: 7px;
  }
}

.unsubscribe-container {
  padding: 0 48px;
  @media screen and (max-width: 5700px) {
    padding: 0 12px;
  }
}

.unsubscribe-page-title {
  font-weight: 700;
  font-size: 32px;
  line-height: 44px;
  text-align: center;
  color: $unsubscribeTitleColor;
  margin-bottom: 16px;
  @media screen and (max-width: 700px) {
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 8px;
  }
}

.unsubscribe-page-text {
  font-weight: 400;
  font-size: 20px;
  line-height: 150%;
  text-align: center;
  color: $unsubscribeTextColor;
  margin-bottom: 64px;
  @media screen and (max-width: 700px) {
    font-size: 14px;
    line-height: 150%;
    margin-bottom: 36px;
  }
}


@media screen and (max-width: 999px) {
  .column-4 {

    &.full {
      height: calc(100% - 50px);
      top: 50px;
      position: fixed;
    }

    &.hide_for_new_mobile_chat {
      display: none;
      height: calc(100% - 50px);
      position: fixed;

      &.active {
        display: flex;
        flex: 1;
      }
    }
  }


  .chat_wrap {
    //height: calc(100% - 100px);
    //overflow: hidden;

    &.chat_wrap_mobile {
      height: 100%;
    }
  }

  .overlay {
    height: calc(100vh - 100px);
    height: calc(calc(var(--vh, 1vh) * 100) - 100px);


    &.full {
      height: calc(100vh - 50px);
      top: 50px;
      z-index: 5;

      &.overlay_new {
        top: 56px;
      }
    }
  }

  .chat_wrap_mobile .c3_chat_scroll {
    height: 100% !important;
  }

  .column_3_letters_page #mobile-chat .c3l_letters_list_box_wrap {
    height: 100%;
    margin-right: 0;
  }
}

#notify_new.active {
  left: 0;
  opacity: 1;
  visibility: visible;
}