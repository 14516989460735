.video_error_wrap{
  width: 692px;
  height: 389px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #FAFAFF;
  border: 5px solid rgba(68, 96, 119, 0.1);
  border-radius: 10px;
}

.video_error_img{
  display: block;
}

.video_error_text{
  font-weight: 600;
  font-size: 18px;
  letter-spacing: 0.06em;
  color: #00317B;
  margin-top: 43px;
}

@media screen and (max-width: 1139px){
  .video_error_wrap{
    width: 474px;
    height: 266px;
  }

  .video_error_img{
    height: 90px;
  }

  .video_error_text{
    margin-top: 33px;
  }
}

@media screen and (max-width: 599px){
  .video_error_wrap{
    width: 300px;
    height: 168px;
  }

  .video_error_img{
    height: 70px;
  }

  .video_error_text{
    margin-top: 25px;
    font-size: 14px;
  }
}