@import "@sitestyles";

.topics_col{
  padding: 0 24px;
  counter-reset: topics;

  @media screen and (max-width: 1139px){
    padding: 0 15px;
  }

  @media screen and (max-width: 767px){
    width: 100%;
    padding: 0;

    &.mob_opened{
      display: none;
    }
  }
}

.topic_item{
  width: 291px;
  display: flex;
  align-items: center;
  padding: 12px;
  background-color: #fff;

  box-shadow: 0 6px 12px rgba(47, 43, 99, 0.16);
  border-radius: 10px;
  cursor: pointer;

  @media screen and (max-width: 1499px), screen and (max-height: 699px){
    width: 250px;
    padding: 8px;
  }

  @media screen and (max-width: 767px){
    width: 100%;
    max-width: 340px;
    margin-left: auto;
    margin-right: auto;
  }

  &.active{
    @media screen and (min-width: 768px){
      background-color: #E3ECFC;

      .number{
        background-color: #D4DFF1;
      }
    }
  }

  & + .topic_item{
    margin-top: 16px;

    @media screen and (max-width: 767px){
      margin-top: 8px;
    }
  }

  .number{
    display: block;
    width: 44px;
    height: 44px;
    margin-right: 24px;
    border-radius: 50%;
    background-color: #F4F4F4;
    position: relative;
    flex: none;

    @media screen and (max-width: 1499px), screen and (max-height: 699px){
      width: 36px;
      height: 36px;
    }

    &:before{
      counter-increment: topics;
      content: counter(topics);
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      font-weight: 600;
      font-size: 18px;
      letter-spacing: 0.01em;
      color: #474F5C;

      @media screen and (max-width: 1499px), screen and (max-height: 699px){
        font-size: 16px;
      }
    }
  }

  .title{
    font-weight: 600;
    font-size: 16px;
    letter-spacing: 0.01em;
    color: $accentLeftRightColor;
    //word-break: break-all;

    @media screen and (max-width: 1499px), screen and (max-height: 699px){
      font-size: 14px;
    }
  }
}