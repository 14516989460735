@import "@sitestyles";


.unsubscribe_girls_block_small {
  background: $unsubscribeInputBackground;
  border-radius: 10px;
  padding: 48px 48px 24px;
  display: flex;
  align-items: center;
  margin-bottom: 100px;
  justify-content: flex-start;
  flex-wrap: wrap;

  @media screen and (max-width: 1712px) {
    justify-content: center;
    flex-direction: column;
    padding: 24px 16px;
  }

  @media screen and (max-width: 860px) {
    margin-bottom: 40px;
  }

  @media screen and (max-width: 350px) {
    padding-top: 16px;
  }
}


.unsubscribe_girls_list_small {
  display: flex;
  margin-right: 60px;
  flex-wrap: wrap;


  @media screen and (max-width: 1712px) {
    margin-right: 0;
    justify-content: center;
  }

  @media screen and (max-width: 860px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto;

  }
}

.unsubscribe_girl_card_small {
  position: relative;
  list-style-type: none;
  display: block;
  max-width: 150px;
  margin-bottom: 24px;

  &:not(:last-child) {
    margin-right: 12px;
    @media screen and (max-width: 860px) {
      margin-right: auto;
    }
  }

  @media screen and (max-width: 860px) {
    max-width: 248px;
  }
}

.girl_card_small_photo {
  width: 150px;
  height: 150px;
  object-fit: cover;
  object-position: top;
  border-radius: 5px;
  display: block;
  background: $background1Color;
  margin-bottom: 12px;
  cursor: pointer;

  @media screen and (max-width: 860px) {
    width: 248px;
    height: 248px;
  }
}


.girl_card_small_status {
  min-width: 64px;
  padding: 0 12px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 4px;
  right: -4px;
  background: $likeSliderOnlineBg;
  border-radius: 3px;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: $onlineTitleColor;
  @media screen and (max-width: 599px) {
    font-size: 12px;
    line-height: 16px;
  }

}

.girl_card_btn {
  width: 100%;
  height: 40px;
  border-radius: 5px;
  font-family: $mainFont;
  font-weight: 600;
  font-size: 16px;
  line-height: 140%;
  letter-spacing: 0.03em;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: $primarySearchButtonBackground2;
  border: 1px solid $primarySearchButton2;
  color: $primarySearchButton2;
  position: relative;

  @media screen and (max-width: 599px) {
    height: 40px;
  }

  &:hover {
    background: $primarySearchButton2;
    color: $primarySearchButtonBackground2;

    &:before {
      background: $primarySearchButtonBackground2;
    }
  }
}

.girl_card_btn_chat:before {
  content: '';
  display: block;
  width: 24px;
  height: 24px;
  min-width: 24px;
  background: $primarySearchButton2;
  mask: url('../../../img/c3lh-item-chat.svg') center no-repeat;
  mask-size: contain;
  -webkit-mask: url('../../../img/c3lh-item-chat.svg') center no-repeat;
  -webkit-mask-size: contain;
  margin-right: 12px;
}


.unsubscribe_girls_small_content {
  flex: 1;
  min-width: 248px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 1712px) {
    max-width: 448px;
    margin: 0 auto;
  }
  @media screen and (max-width: 860px) {
    justify-content: flex-start;
  }
}

.unsubscribe_girls_small_text {
  font-weight: 400;
  font-size: 20px;
  line-height: 150%;
  color: $unsubscribeTextColor;
  @media screen and (max-width: 1712px) {
    text-align: center;
  }
  @media screen and (max-width: 700px) {
    font-size: 14px;
    width: 100%;
    margin: 0 auto;
  }
}