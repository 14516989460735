@import "@sitestyles";

.profile_photos{
  padding-top: 20px;
}

.profile_about_icon svg {
  width: 35px;
  height: 35px;
}

.profile_div_head_add_photo{
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 15px;
  letter-spacing: 0.06em;
  color: $notificationFavouriteBg;
  padding: 0 15px;
  margin-right: -15px;
  cursor: pointer;
  transition: background-color .3s;
  border-radius: 10px;
}

.profile_div_head_add_photo:hover{
  background-color: $background1Color;
}

.profile_div_head_add_photo svg {
  width: 21px;
  height: 21px;
  margin-right: 12px;
  fill: $iconAddPhotoFillColor;
}

.profile_photos_scroll_wrap{
  position: relative;
}

.profile_photos_scroll{
  width: 100%;
  overflow-x: auto;
  padding-bottom: 20px;
  position: relative;
}

.profile_photos_scroll::-webkit-scrollbar{
  height: 5px;
  background: #E0E2EB;
  border-radius: 3px;
}

.profile_photos_scroll::-webkit-scrollbar-thumb{
  background: $scrollbarThumbColor;
  border-radius: 3px;
}

.profile_photos_list{
  display: flex;
  width: max-content;
  list-style: none;
}

.profile_photos_item{
  position: relative;
}

.profile_photos_item + .profile_photos_item{
  margin-left: 20px;
}

.profile_photos_item_delete{
  width: 85px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 10px;
  right: 10px;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 5px;
  font-size: 12px;
  color: #00317B;
  cursor: pointer;
  transition: background-color .3s;
}

.profile_photos_item_delete:hover{
  background-color: #fff;
}

.profile_photos_item_delete svg {
  width: 18px;
  height: 18px;
  margin-right: 8px;
}

.profile_photos_item_img{
  height: 286px;
  width: auto;
  border-radius: 15px;
  display: block;
}

.hidden_upload{
  opacity: 0;
  display: none;
  position: absolute;
  top:0;
  bottom: 0;
  left:0;
  right: 0;
}

.profile_photos_arrow{
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 30px;
  height: 30px;
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer;
}

.profile_photos_arrow.left_arrow{
  background-image: url(../../../../../img/c3/arrow-btn.svg);
  left: 10px;
}

.profile_photos_arrow.right_arrow{
  background-image: url(../../../../../img/c3/btn-right.svg);
  right: 10px;
}

@media screen and (max-width: 1599px){
  .profile_photos_item_img{
    height: 157px;
  }
}

@media screen and (max-width: 599px){
  .profile_photos_item_img{
    height: 114px;
    min-width: 100px;
    object-fit: cover;
  }

  .profile_photos_item_delete{
    right: 4px;
    top: 4px;
  }

  .profile_photos_item + .profile_photos_item{
    margin-left: 8px;
  }

  .profile_photos_scroll{
    padding-bottom: 10px;
  }

  .profile_about_icon svg {
    width: 22px;
    height: 22px;
  }

  .profile_div_head_add_photo{
    font-size: 12px;
    height: 30px;
    padding: 0 10px;
    margin-right: -10px;
    border-radius: 3px;
  }

  .profile_div_head_add_photo svg{
    width: 14px;
    height: 14px;
  }
}