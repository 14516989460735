@import "@sitestyles";

.wrapper {
  background-image: url(../../../../img/confirm/bg-modal.svg);
  width: 750px;
  height: 700px;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 134px;
  padding-right: 134px;
  position: relative;
  text-align: center;

  @media screen and (max-width: 767px) {
    width: 95%;
    margin: auto;
  }

  @media screen and (max-width: 625px) {
    padding-left: 10px;
    padding-right: 10px;
  }

  @media screen and (max-width: 599px) {
    width: 304px;
    height: 273px;
  }

  .cross {
    cursor: pointer;
    position: absolute;
    right: 15px;
    top: 15px;
    width: 16px;
    height: 16px;
    background-image: url(../../../../img/confirm/cros-modal.svg);
  }

  .logo {
    background-image: url(../../../../img/confirm/logo-modal.svg);
    background-repeat: no-repeat;
    width: 200px;
    height: 200px;

    @media screen and (max-width: 599px) {
      background-image: url(../../../../img/confirm/logo-modal-mob.svg);
      width: 80px;
      height: 80px;
    }
  }

  .title {
    margin-top: 40px;
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 700;
    font-size: 48px;
    line-height: 65px;
    letter-spacing: -0.06em;
    color: #00317B;

    @media screen and (max-width: 599px) {
      margin-top: 24px;
      font-size: 16px;
      line-height: 22px;
    }
  }

  .sub {
    margin-top: 8px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 33px;
    text-align: center;
    letter-spacing: -0.03em;
    color: #898989;

    @media screen and (max-width: 599px) {
      margin-top: 4px;
      font-size: 12px;
      line-height: 16px;
    }
  }

  .button {
    cursor: pointer;
    width: 235px;
    height: 57px;
    margin-top: 56px;
    background: linear-gradient(91.49deg, #4A92FF 0%, #8EBBFF 29.48%, #0050C9 100%);
    box-shadow: 0px 0px 43px rgba(255, 255, 255, 0.72);
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 599px) {
      margin-top: 16px;
      width: 169px;
      height: 43px;
    }

    &_title {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 25px;
      letter-spacing: -0.03em;
      color: #FFFFFF;

      @media screen and (max-width: 599px) {
        font-size: 14px;
        line-height: 19px;
      }
    }
  }
}