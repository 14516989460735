@import "@sitestyles";

.tutorial_step_illustration {
  width: 100%;
  height: 262px;
  margin-bottom: 56px;
  background: $tutorialSecondaryBackground;
  border: 1px solid $tutorialPrimaryBorderColor;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  overflow: hidden;

  @media screen and (max-width: 540px) {
    height: 190px;
  }
}

.step_filter_candidates_card.mobile {
  @media screen and (max-width: 540px) {
    display: none;
  }
}

.step_filter {
  width: 100%;
  height: 100%;
  position: relative;
  gap: 10px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  display: flex;

  &:before {
    content: '';
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;
    background: $tutorialPrimaryIllustrationBackground;
  }

  @media screen and (max-width: 540px) {
    &:before {
      display: none;
    }
  }
}

.step_filter_footer {
  background: $tutorialPrimaryIllustrationBackground;
  backdrop-filter: blur(15px);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;

  @media screen and (max-width: 540px) {
    height: 33px;
  }
}

.step_filter_footer_text {
  color: $tutorialLightTextColor;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  line-height: 140%;

  @media screen and (max-width: 540px) {
    font-size: 12px;
  }
}

.step_filter_header {
  width: 100%;
  height: 31px;
  border: 0 solid $tutorialHeaderBorderColor;
  border-bottom-width: 0.5px;
  justify-content: space-between;
  align-items: center;
  display: flex;
  padding: 9px;

  @media screen and (max-width: 540px) {
    display: none;
  }
}

.step_filter_age_range {
  gap: 10px;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.step_filter_age_text {
  color: $tutorialStepSecondaryColor;
  font-size: 6px;
  font-weight: 600;
  line-height: 140%;
}

.step_filter_inputs {
  gap: 4px;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}

.step_filter_item {
  width: 31px;
  height: 14px;
  gap: 4px;
  background-color: $tutorialFilterItemBackground;
  border: 0.4px solid $tutorialFilterItemBorder;
  border-radius: 2px;
  justify-content: space-between;
  align-items: center;
  padding: 3px 4px;
  display: flex;
  box-shadow: 0 1.4px 1.4px rgba(0, 0, 0, .05);
}

.step_filter_item_text {
  color: $tutorialStepTextColor;
  font-size: 6px;
  font-weight: 400;
  line-height: 140%;
}

.step_filter_item_icon {
  width: 4px;
  height: 2px;
  object-fit: cover;
  justify-content: center;
  align-items: center;
  display: flex;

  svg {
    width: 4px;
    height: 2px;
    fill: #8894a6;
  }
}

.step_filter_item_detailed {
  height: 18px;
  gap: 4px;
  background-color: $tutorialPopupBackground;
  border: 0.5px solid $tutorialStepSecondaryColor;
  border-radius: 2px;
  justify-content: space-between;
  align-items: center;
  padding: 4px 5px;
  display: flex;
  box-shadow: 0 1.4px 1.4px rgba(0, 0, 0, .05);
  position: relative;
  z-index: 3;
}

.step_filter_item_detailed_wrap {
  width: 67px;
  gap: 4px;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.step_filter_item_detailed_icon {

  svg {
  width: 10px;
  height: 10px;
  fill: $tutorialStepSecondaryColor;

  path{
    fill: $tutorialStepSecondaryColor;
  }
}
}

.step_filter_item_detailed_text {
  color: $tutorialStepSecondaryColor;
  font-size: 7px;
  font-weight: 600;
  line-height: 140%;
}

.step_filter_online {
  gap: 4px;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.step_filter_online_text {
  color: $tutorialStepSecondaryColor;
  letter-spacing: -.02em;
  text-transform: uppercase;
  font-size: 8px;
  font-weight: 400;
}

.step_filter_online_icon {
  svg {
   width: 18px;
   height: 16px;
   padding-top: 6px;
 }
}

.step_filter_search {
  gap: 4px;
  background-color: $tutorialButtonLightBackground;
  border: 0.5px solid $tutorialFilterItemBorder;
  border-radius: 2px;
  justify-content: flex-start;
  align-items: center;
  padding: 3px 4px;
  display: flex;
  height: 17px;
}

.step_filter_text {
  color: $tutorialStepTextColor;
  letter-spacing: -.02em;
  font-size: 6px;
  font-weight: 400;
  line-height: 100%;
}

.step_filter_search_icon {
  svg {
    width: 10px;
    height: 10px;
    fill: $iconCrossSearchColor;
  }
}

.step_filter_mob_header {
  display: none;

  @media screen and (max-width: 540px) {
    display: flex;
    padding: 7px 0;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    background: $tutorialButtonBackground;
  }
}

.step_filter_item_mob {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 3px;
  width: 55px;
  height: 36px;
}

.step_filter_item_mob_text {
  color: $tutorialLightTextColor;
  font-size: 8.72px;
  font-weight: 600;
}


.step_filter_item_mob_icon {
  height: 18px;

  svg {
    width: 18px;
    height: 18px;
    fill: #FFFFFF;
  }
}

.step_filter_mob_bell {
  border-radius: 7px 0 0 7px;
  background: $tutorialPopupBackground;
  width: 55px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.step_filter_mob_logo_wrap {
  width: 55px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.step_filter_mob_logo_icon {
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.step_filter_mob_logo_large{
  width: 110px;
  height: 26px;
}

.step_filter_mob_bell_icon {
  height: 28px;
  svg {
    width: 28px;
    height: 28px;
  }
}

.step_filter_candidates {
  width: 100%;
  gap: 8px;
  padding: 0 9px;
  justify-content: space-around;
  align-items: flex-start;
  display: flex;
}

.step_filter_candidates_card {
  width: 100%;
  height: 211px;
  gap: 5px;
  border-radius: 5px;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
  position: relative;
  box-shadow: 1.4px 1.4px 2.3px rgba(197, 196, 208, .64);
}

.step_filter_candidates_profile {
  width: 100%;
  height: 211px;
  background-color: $tutorialPopupBackground;
  border-radius: 5px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 1.5px;
  display: flex;
  position: relative;
  overflow: hidden;
  box-shadow: 1.4px 1.4px 2.3px rgba(197, 196, 208, .64);

  &:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 25%;
    background: $tutorialLightGradient;
  }
}

.step_filter_candidates_photo {
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-image: $tutorialImageBackground;
  border-radius: 2.3px;
}

.step_filter_candidates_more_photos {
  height: 13px;
  gap: 4px;
  background-color: $tutorialMorePhotoBackground;
  border-radius: 2px;
  justify-content: center;
  align-items: center;
  padding-left: 2px;
  padding-right: 2px;
  display: flex;
  position: absolute;
  top: 4px;
  right: 4px
}

.step_filter_candidates_more_photos_icon {
  object-fit: cover;
  justify-content: center;
  align-items: center;
  display: flex;

  svg {
    width: 9px;
    height: 9px;
    fill: $accentLeftColor;
  }
}

.step_filter_candidates_more_photos_text {
  color: $tutorialStepSecondaryColor;
  letter-spacing: -.01em;
  font-size: 7px;
  font-weight: 400;
}

.step_filter_candidates_online {
  width: 76px;
  height: 13px;
  gap: 5px;
  background-image: $tutorialOnlineBackground;
  border-radius: 2px;
  justify-content: center;
  align-items: center;
  padding: 2px 5px;
  display: flex;
  position: absolute;
  top: 4px;
  left: -4px;

  @media screen and (max-width: 350px) {
    width: 65px;
  }
}

.step_filter_candidates_online_text {
  color: $tutorialOnlineTextColor;
  font-size: 7px;
  font-weight: 600;
}

.step_filter_candidates_info {
  width: 147px;
  height: 51px;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 6px 2px;
  display: flex;
  position: absolute;
  top: 182px;
  left: 1px;
}

.step_filter_candidates_info_wrap {
  width: 100%;
  gap: 4px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.step_filter_candidates_info_text {
  color: $tutorialStepTextSecondaryColor;
  text-align: center;
  font-size: 12px;
  font-weight: 600;
}

.step_filter_candidates_info_buttons {
  width: 100%;
  gap: 4px;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}

.step_filter_candidates_info_button_chat {
  width: 70px;
  height: 20px;
  grid-column-gap: 4px;
  grid-row-gap: 4px;
  background-image: $tutorialButtonBackground;
  border-radius: 2px;
  justify-content: center;
  align-items: center;
  padding: 4px 10px;
  text-decoration: none;
  display: flex;
}

.step_filter_candidates_info_button_icon {
  width: 12px;
  height: 12px;
  object-fit: cover;
  justify-content: center;
  align-items: center;
  display: flex;

  svg {
    width: 11px;
    height: 11px;
    fill: #FFFFFF;
  }
}

.step_filter_candidates_info_button_chat_text {
  color: $tutorialButtonLightColor;
  text-align: center;
  letter-spacing: .01em;
  font-size: 7px;
  font-weight: 600;
}

.step_filter_candidates_info_button_like {
  width: 70px;
  height: 20px;
  gap: 5px;
  background-color: $tutorialButtonLightBackground;
  border: 0.5px solid $tutorialStepSecondaryColor;
  border-radius: 2px;
  justify-content: center;
  align-items: center;
  padding: 4px 10px;
  text-decoration: none;
  display: flex;
}

.step_filter_candidates_info_button_like .step_filter_candidates_info_button_icon {
  width: 12px;
  height: 12px;
  object-fit: cover;
  justify-content: center;
  align-items: center;
  display: flex;

  svg {
    width: 11px;
    height: 11px;
    fill: $accentLeftColor;
  }
}

.step_filter_candidates_info_button_like_text {
  color: $tutorialStepSecondaryColor;
  text-align: center;
  letter-spacing: .01em;
  font-size: 6px;
  font-weight: 600;
}

.tutorial_body {
  display: grid;
  grid-row-gap: 16px;
  margin-bottom: 48px;
}

.tutorial_step_title {
  color: $accentDarkColor;
  text-align: center;
  font-size: 20px;
  font-weight: 700;
}

.tutorial_step_text {
  color: $tutorialStepTextColor;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  font-family: $mainFont;
}