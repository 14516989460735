@import "@sitestyles";

.verify_phone_modal {
  width: 527px;
  max-width: 100%;
  background-color: #fff;
  padding: 58px 70px;
  position: relative;

  @media screen and (max-width: 599px) {
    padding: 50px 15px;
  }
}

.verify_phone_title {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 26px;
  font-weight: 600;
  font-size: 26px;
  letter-spacing: 0.06em;
  color: #6F0267;

  @media screen and (max-width: 599px) {
    font-size: 18px;
  }

  img {
    margin-right: 16px;

    @media screen and (max-width: 599px) {
      width: 28px;
      margin-right: 8px;
    }
  }

  svg {
    margin-right: 16px;
    width: 44px;
    height: 44px;
    fill: #6F0267;

    @media screen and (max-width: 599px) {
      width: 34px;
      height: 34px;
      margin-right: 8px;
    }
  }
}

.verify_phone_success_title {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #27AE60;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.06em;
  margin-bottom: 26px;

  @media screen and (max-width: 599px) {
    font-size: 14px;
  }

  img {
    margin-right: 8px;

    @media screen and (max-width: 599px) {
      width: 16px;
    }
  }

  svg {
    margin-right: 8px;
    width: 26px;
    height: 26px;
    fill: #27AE60;

    @media screen and (max-width: 599px) {
      width: 16px;
      height: 16px;
    }
  }
}

.verify_phone_text {
  margin-bottom: 24px;
  font-size: 18px;
  text-align: center;
  color: rgba(51, 51, 51, 0.8);

  @media screen and (max-width: 599px) {
    font-size: 14px;
  }
}

.verify_phone_diff_text {
  margin-top: 24px;
  font-size: 18px;
  text-align: center;
  color: rgba(51, 51, 51, 0.6);

  a {
    color: rgba(51, 51, 51, 0.8);
    font-weight: bold;
  }

  @media screen and (max-width: 599px) {
    font-size: 14px;
  }
}

.verify_phone_label {
  margin-bottom: 18px;
  text-align: center;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  color: #474F5C;

  @media screen and (max-width: 599px) {
    font-size: 14px;
  }
}

.verify_phone_inputs_row {
  display: flex;
  align-items: center;
  justify-content: center;

  input {
    display: block;
    width: 43px;
    height: 54px;
    background: #FFFFFF;
    border: 1px solid #9AAFD0;
    border-radius: 4px;
    text-align: center;
    font-weight: 600;
    font-size: 18px;
    letter-spacing: 0.06em;
    text-transform: uppercase;
    color: $attachTouchText;

    & + input {
      margin-left: 8px;
    }
  }
}

.verify_phone_button {
  width: max-content;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  margin: 32px auto 0;
  text-align: center;
  padding: 20px 12px;
  min-width: 196px;
  background: $buttonBackgroundColor;
  font-weight: 600;
  font-size: 18px;
  color: #FFFFFF;
  cursor: pointer;

  @media screen and (max-width: 599px) {
    font-size: 16px;
    padding: 16px 12px;
    margin-top: 22px;
  }
}

.verify_phone_receive_row {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 24px;

  @media screen and (max-width: 599px) {
    flex-wrap: wrap;
  }

  span {
    font-size: 18px;
    color: rgba(51, 51, 51, 0.6);
    margin-right: 16px;

    @media screen and (max-width: 599px) {
      width: 100%;
      margin-right: 0;
      margin-bottom: 5px;
      text-align: center;
      font-size: 14px;
    }
  }

  div {
    font-size: 18px;
    color: $attachTouchText;
    cursor: pointer;

    @media screen and (max-width: 599px) {
      font-size: 14px;
    }

    &:hover {
      text-decoration: underline;
    }
  }
}

.verify_phone_data_saved {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
  font-size: 18px;
  text-align: center;
  color: rgba(51, 51, 51, 0.6);

  @media screen and (max-width: 599px) {
    font-size: 14px;
  }

  img {
    margin-right: 8px;

    @media screen and (max-width: 599px) {
      width: 16px;
    }
  }


  svg {
    margin-right: 8px;
    width: 20px;
    height: 20px;
    fill: #858585;

    @media screen and (max-width: 599px) {
      width: 16px;
      height: 16px;
    }
  }
}

.verify_phone_close {
  position: absolute;
  right: 15px;
  top: 15px;
  padding: 5px;
  cursor: pointer;

  img {
    display: block;
    width: 19px;
  }

  svg {
    width: 24px;
    height: 24px;
    fill: #c4c4c4;
    stroke: #c4c4c4;
    stroke-width: 0.5;
  }
}