.video_thumbnail_container {
  position: relative;
}

.video_thumbnail_lock_screen {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  flex-direction: column;
}