@import "@sitestyles";

.column-3.online_now_page{
  background-color: $bleachColor;
  overflow-x: hidden;
}

.online_now_filters{
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 61px;
  border-bottom: 1px solid rgba(51, 51, 51, 0.1);
  margin: 0 46px 12px;
}

.online_now_filters .c3lh_search_id_wrap input{
  width: 174px;
}

.online_now_filters .c3lh_search_range_title{
  text-transform: none;
  font-size: 14px;
}

.online_now_filters .c3lh_search_range_title span{
  display: inline;
}

.column-3.online_now_page .c3_woman_profile_empty_video{
  margin-bottom: 12px;
  width: 308px;
  height: 186px;
}

@media screen and (hover: none) and (min-width: 1140px) and (pointer: coarse){
  .online_now_filters .c3lh_search_id_wrap{
    display: block;
    width: max-content;
    margin: 0;
  }

  .online_now_filters .c3lh_search_range_wrap{
    margin-bottom: 0;
    width: max-content;
  }

  .online_now_filters .c3lh_search_id_wrap input{
    height: 30px;
  }

  .online_now_filters .c3lh_search_id_wrap button{
    width: 28px;
    height: 28px;
  }
}

@media screen and (max-width: 1699px){
  .online_now_filters{
    margin: 0 24px 12px;
  }

  .column-3.online_now_page .c3_woman_profile_empty_video{
    width: 289px;
    height: 158px;
  }
}

@media screen and (max-width: 1399px){
  .column-3.online_now_page .c3_woman_profile_empty_video{
    width: 229px;
    height: 138px;
  }

  .column-3.online_now_page .c3_woman_profile_empty_video_title{
    font-size: 12px;
  }

  .column-3.online_now_page .c3_woman_profile_empty_video_title img{
    width: 26px;
    margin-right: 10px;
  }
}

@media screen and (max-width: 1249px) and (min-width: 1200px), screen and (max-width: 899px){
  .column-3.online_now_page .c3_woman_profile_empty_video{
    width: 162px;
    height: 98px;
  }

  .column-3.online_now_page .c3_woman_profile_empty_video_title{
    padding: 0 15px;
  }
}

@media screen and (max-width: 1139px){
  .online_now_filters .c3lh_search_id_wrap{
    display: block;
    width: auto;
    margin-right: 0;
    margin-bottom: 0;
  }

  .online_now_filters .c3lh_search_id_wrap input{
    height: 30px;
  }

  .online_now_filters .c3lh_search_id_wrap button{
    width: 28px;
    height: 28px;
  }

  .online_now_filters .c3lh_search_range_wrap{
    width: max-content;
    margin-bottom: 0;
  }
}

@media screen and (max-width: 699px){
  .column-3.online_now_page .c3_woman_profile_empty_video{
    display: none;
  }

  .online_now_filters{
    margin-left: 10px;
    margin-right: 10px;
  }
}

@media screen and (max-width: 599px){
  .online_now_filters{
    height: auto;
    padding: 10px 0;
  }

  .online_now_filters  .cl2h_tab_wide_filter_item_wrap{
    height: 24px;
  }

  .online_now_filters .c3lh_search_range_title{
    margin-bottom: 0;
    margin-right: 10px;
    text-transform: capitalize;
    width: auto;
    font-size: 12px;
  }

  .online_now_filters .c3lh_search_range_title span{
    display: none;
  }

  .online_now_filters .c3lh_search_range_wrap{
    display: flex;
  }

  .online_now_filters .c3lh_search_id_wrap input{
    width: 107px;
  }
}

@media screen and (max-width: 340px){
  .online_now_filters .c3lh_search_range_wrap{
    display: block;
  }

  .online_now_filters .c3lh_search_range_title{
    display: block;
    margin-right: 0;
    margin-bottom: 6px;
    font-size: 12px;
  }

  .online_now_filters .c3lh_search_id_wrap input{
    width: 160px;
  }
}