@import "@sitestyles";

.tutorial_popup {
  width: 520px;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 10px;
  background: $tutorialPopupBackground;
  position: fixed;
  padding: 24px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 25px 50px 0 rgba(25, 39, 60, 0.10);
  transition: .7s;
  z-index: 999999;

  @media screen and (max-width: 540px) {
    width: 100%;
    padding-top: 16px;
    border-radius: 0;
    //height: 100%;
  }
}

.tutorial_title {
  color: $accentDarkColor;
  text-align: center;
  font-size: 18px;
  font-weight: 700;
  line-height: 140%;
  margin: 0 auto 24px;

  @media screen and (max-width: 540px) {
    font-size: 16px;
    margin-bottom: 16px;
  }
}

.tutorial_step_illustration {
  width: 100%;
  height: 262px;
  margin-bottom: 56px;
  background: $tutorialPrimaryIllustrationBackground;
  border: 1px solid $tutorialPrimaryBorderColor;
  border-radius: 5px;

  @media screen and (max-width: 540px) {
    height: 190px;
  }
}

.tutorial_body {
  display: grid;
  grid-row-gap: 16px;
  margin-bottom: 48px;
}

.tutorial_step_title {
  color: $accentDarkColor;
  text-align: center;
  font-size: 20px;
  font-weight: 700;
}

.tutorial_step_text {
  color: $tutorialStepTextColor;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  font-family: $mainFont;
}

.tutorial_footer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 16px;

  @media screen and (max-width: 540px) {
    display: flex;
    flex-direction: column-reverse;
    gap: 12px;
  }
}

.tutorial_footer.step_6 {
  grid-template-columns: 1fr;
  width: 100%;
  margin: auto;
}

.tutorial_btn_continue {
  border-radius: 5px;
  background: $accentLightColor;
  box-shadow: inset 127px 0 65px -44px $accentDarkColor;
  border: 0 solid transparent;
  display: flex;
  padding: 0 16px;
  justify-content: center;
  align-items: center;
  outline: 0;
  box-sizing: border-box;
  color: $tutorialButtonLightColor;
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  line-height: 140%;
  transition: all .6s ease-in-out;
  height: 48px;
  font-family: $mainFont;
  cursor: pointer;

  @media screen and (max-width: 540px) {
    box-shadow: inset 177px 0 80px -44px $accentDarkColor;
  }

  &:hover {
    background: $accentDarkColor;
    box-shadow: inset 127px 0 65px -44px $accentDarkColor;
    border: 2px solid $accentDarkColor;
    color: $tutorialButtonLightColor;
  }

  &:focus {
    background: $tutorialButtonLightBackground;
    box-shadow: inset 127px 0 65px -44px $tutorialButtonLightBackground;
    border: 2px solid $accentDarkColor;
    color: $accentDarkColor;
  }
}

.tutorial_btn_find_match{
  box-shadow: inset 371px 0 89px -85px $accentDarkColor;

  @media screen and (max-width: 546px) {
    box-shadow: inset 177px 0 80px -44px $accentDarkColor;
  }
}

.tutorial_btn_skip {
  border-radius: 5px;
  border: 2px solid $tutorialButtonBorderColor;
  display: flex;
  height: 48px;
  padding: 0 16px;
  background: transparent;
  justify-content: center;
  align-items: center;
  outline: 0;
  box-sizing: border-box;
  color: $tutorialButtonGreyColor;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 140%;
  transition: all .6s ease-in-out;
  cursor: pointer;
  font-family: $mainFont;

  &:hover {
    border-color: $tutorialPrimaryBorderColor;
    background: $tutorialSecondaryBackground;
  }

  &:focus {
    border-color: $tutorialPrimaryBorderColor;
    background: $tutorialSecondaryBackground;
  }
}

.tutorial_btn_close {
  width: 24px;
  min-width: 24px;
  height: 24px;
  background: $tutorialCloseButtonGreyColor;
  position: absolute;
  top: 24px;
  right: 24px;
  mask: url('../../../../../img/c3-media-close-tab.svg') center no-repeat;
  mask-size: 16px;
  -webkit-mask: url('../../../../../img/c3-media-close-tab.svg') center no-repeat;
  -webkit-mask-size: 16px;
  outline: 0;
  border: 0;
  transition: all .6s ease-in-out;
  cursor: pointer;
  display: flex;

  @media screen and (max-width: 540px) {
    top: 8px;
    right: 8px;
  }

  &:hover {
    background: $accentDarkColor;
  }
}

.tutorial_hello_box {
  border-radius: 5px;
  border: 1px solid $tutorialSecondaryBorderColor;
  background: $tutorialPrimaryBackground;
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  color: $accentDarkColor;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
  gap: 16px;
  margin-bottom: 24px;

  @media screen and (max-width: 540px) {
    padding: 16px;
  }
}

.tutorial_hello_box_btns {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 16px;
  width: 100%;
}

.tutorial_btn_hello {
  border-radius: 5px;
  background: $tutorialButtonBackground;
  display: flex;
  height: 40px;
  padding: 0;
  justify-content: center;
  align-items: center;
  border: 0;
  outline: 0;
  color: $tutorialButtonLightColor;
  font-size: 14px;
  font-weight: 600;
  line-height: 140%;
  letter-spacing: 0.42px;
  box-sizing: border-box;

  @media screen and (max-width: 350px) {
    font-size: 12px;
  }

  &:before {
    content: '';
    display: block;
    margin-right: 8px;
    width: 24px;
    min-width: 24px;
    height: 24px;
    background: url('../../../../../img/say-hello-yellow-emoji.svg') center no-repeat;
    background-size: contain;

    @media screen and (max-width: 540px) {
      width: 18px;
      min-width: 18px;
      height: 18px;
    }
    @media screen and (max-width: 350px) {
      width: 16px;
      min-width: 16px;
      height: 16px;
    }
  }
}

.tutorial_btn_like {
  border-radius: 5px;
  background: $tutorialButtonLightBackground;
  border: 1px solid $tutorialStepSecondaryColor;
  display: flex;
  height: 40px;
  padding: 0;
  justify-content: center;
  align-items: center;
  outline: 0;
  color: $tutorialStepSecondaryColor;
  box-sizing: border-box;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  letter-spacing: 0.42px;

  @media screen and (max-width: 350px) {
    font-size: 12px;
  }

  &:before {
    content: '';
    display: block;
    margin-right: 8px;
    width: 24px;
    min-width: 24px;
    height: 24px;
    background: $tutorialStepSecondaryColor;
    mask: url('../../../../../img/c3lh-item-like.svg') center no-repeat;
    mask-size: contain;

    @media screen and (max-width: 540px) {
      width: 18px;
      min-width: 18px;
      height: 18px;
    }
    @media screen and (max-width: 350px) {
      width: 16px;
      min-width: 16px;
      height: 16px;
    }
  }
}

.tutorial_pagination {
  position: absolute;
  top: 364px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  @media screen and (max-width: 540px) {
    top: 277px;
  }
}

.tutorial_pagination.step_5 {
  top: 330px;

  @media screen and (max-width: 540px) {
    top: 166px;
  }
}

.tutorial_pagination_item {
  width: 6px;
  height: 6px;
  background: $tutorialPaginationItemColor;
  display: block;
  border-radius: 50%;
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.tutorial_pagination_item.active {
  background: $accentDarkColor;
  width: 24px;
  border-radius: 10px;
}