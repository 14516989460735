@import "../../Menu/NewMenuMain/breakpoints";

:global(.new_mob_menu) .c1_bottom_wrap{
  margin-top: 20px;
}

.c1_bottom_wrap{
  margin-top: 20px;

  @media (max-width: $BP_middle), (max-height: $BP_middle_height){
    margin-top: 10px;
  }
}

.c1_bottom_policy_page_btn{
  height: 56px;
  display: flex;
  align-items: center;
  padding-left: 15px;
  padding-right: 8px;
  box-shadow: 6px 0 12px rgba(47, 43, 99, 0.16);

  font-weight: 500;
  font-size: 20px;
  color: #6F0267;
  cursor: pointer;

  &.active{
    background-color: #446077;
    color: #fff;
  }

  .ico{
    width: 30px;
    height: 30px;
    display: flex;
    margin-right: 9px;

    svg{
      margin: auto;
      stroke: #6F0267;
      max-width: 100%;
      max-height: 100%;
    }
  }
}

.c1_policy_wrap{
  margin-bottom: 8px;

  @media (max-width: $BP_middle), (max-height: $BP_middle_height){
    margin-bottom: 6px;
  }
}

.c1_policy_open_btn{
  width: max-content;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  border-bottom: 1px solid #6F0267;
  font-size: 11px;
  color: #6F0267;
  transition: .3s;
  cursor: pointer;

  svg{
    stroke: #6F0267;
    width: 12px;
    height: 7px;
    margin-left: 8px;
    transition: .3s;
  }
}

.c1_policy_links_list{
  height: 0;
  overflow: hidden;
  transition: .3s;

  .links_item{
    width: max-content;
    margin-left: auto;
    margin-right: auto;
    font-size: 9px;
    color: #6F0267;
    text-decoration: underline;
    text-align: center;
    cursor: pointer;
    
    &:hover{
      text-decoration: none;
    }

    &:not(:last-child){
      margin-bottom: 6px;
    }
  }
}

.c1_policy_wrap.opened{

  .c1_policy_open_btn{
    font-size: 12px;
    color: #474F5C;
    border-color: transparent;
    margin-bottom: 8px;

    svg{
      transform: rotate(180deg);
      stroke: #474F5C;
    }
  }

  .c1_policy_links_list{
    height: 109px;
  }
}

.c1_copyright{
  font-size: 10px;
  color: #4F4F4F;
  margin-bottom: 11px;
  text-align: center;

  @media (max-width: $BP_middle), (max-height: $BP_middle_height){
    margin-bottom: 8px;
  }
}

.c1_support_mail{
  display: block;
  font-size: 10px;
  color: #7474B2;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  
  &:hover{
    text-decoration: underline;
  }
}