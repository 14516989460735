@import "@sitestyles";

.c3_woman_profile_page_wrap {
  padding: 0 10px 0 0;
  flex: 1;
  overflow: hidden;
}

.c3_woman_profile_page_wrap2 {
  /*height: calc(100vh - 86px);
  height: calc(calc(var(--vh, 1vh) * 100) - 86px);
*/
  height: 100%;
  overflow-y: auto;
  padding: 0 14px 24px 24px;
}

.ReactModal__Content.ReactModal__Content--after-open .c3_woman_profile_page_wrap2 {
  padding: 0;
  overflow: visible;
}

.c3_woman_profile_page_wrap2::-webkit-scrollbar {
  width: 3px;
  background: $whiteColor;
  border-radius: 22px;
}

.c3_woman_profile_page_wrap2::-webkit-scrollbar-thumb {
  background: $scrollbarThumbColor;
  border-radius: 9px;
}

.c3_woman_profile_main_block {
  background-color: $whiteColor;
  box-shadow: 0 6px 12px rgba(47, 43, 99, 0.16);
  border-radius: 10px;
  padding: 25px;
  position: relative;
}

.c3_woman_profile_online_status {
  width: 198px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 14px;
  right: -7px;
  background: $likeOnlineBg;
  border-radius: 3px;
  font-weight: 600;
  font-size: 18px;
  color: $likeOnlineColor;
}

.c3_woman_profile_main_block_say_hello_btn_mob {
  width: 110px;
  height: 28px;
  display: none;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0;
  top: 40px;
  background-color: #fff;
  border: 1px solid $accentLeftRightColor;
  font-size: 10px;
  border-radius: 3px 0 0 3px;
  cursor: pointer;
  transition: .3s;
  font-weight: 600;
  border-right: none;
  color: $accentLeftRightColor;
}

.c3_woman_profile_main_block_say_hello_btn_mob img {
  width: 14px;
  margin-right: 6px;
}

.c3_woman_profile_main_block_say_hello_btn_mob svg {
  width: 16px;
  height: 16px;
  margin-right: 6px;
  fill: #ffba30;
  vertical-align: bottom;
}

.c3_woman_profile_main_block_info_btn.say_hello svg {
  width: 31px;
  height: 33px;
  margin-right: 10px;
  fill: #ffba30;
}

.c3_woman_profile_main_block_info_btn.like_her_btn svg {
  width: 34px;
  height: 30px;
  margin-right: 10px;
  fill: $iconLikeWomanProfileFillColor;
}

.c3_woman_profile_main_block_top {
  display: flex;
  align-items: stretch;
}

.c3_woman_profile_main_block_photo {
  width: 38%;
}

.c3_woman_profile_main_block_photo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
  display: block;
}

.c3_woman_profile_main_block_info {
  width: calc(62% - 24px);
  margin-left: 24px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.c3_woman_profile_main_block_info_wrap {
  width: 100%;
}

.c3_woman_profile_main_block_info_top {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 25px;
  border-bottom: 1px solid $familySizeCondensedSoupColor;
  margin-bottom: 20px;
  width: 100%;
}

.c3_woman_profile_main_block_info_top_left {
  flex: 1;
}

.c3_woman_profile_main_block_name {
  font-weight: 600;
  font-size: 36px;
  line-height: 140%;
  color: $accentLeftColor;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.c3_woman_profile_main_block_info_top_left_bottom {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.c3_woman_profile_main_block_id {
  font-size: 18px;
  letter-spacing: 0.06em;
  color: $defaultTextColor;
  white-space: nowrap;
}

.c3_woman_profile_main_block_place {
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.06em;
  text-transform: capitalize;
  color: $primaryColor;
  margin-left: 22px;
}

.c3_woman_profile_main_block_place img {
  height: 18px;
  vertical-align: middle;
  margin-bottom: 2px;
  margin-right: 5px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}

.c3_woman_profile_main_block_list {
  margin-bottom: 65px;
  width: 100%;
}

.c3_woman_profile_main_block_list:last-child {
  margin-bottom: 0;
}

.c3_woman_profile_main_block_dob {
  width: 100%;
  font-weight: 600;
  font-size: 18px;
  letter-spacing: 0.06em;
  color: #446077;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.c3_woman_profile_main_block_dob span {
  font-weight: normal;
  display: none;
}

.c3_woman_profile_main_block_info_top_right {
  display: flex;
  align-items: flex-start;
  min-height: 40px;
  position: relative;
}

.c3_wp_hello_btn_wrap {
  position: relative;
  z-index: 2;
}

.c3_wp_hello_list_wrap_mob {
  display: none;
}

.c3_wp_hello_list_wrap {
  width: 284px;
  position: absolute;
  left: 0;
  top: calc(100% - 1px);
  background-color: #EBEDFA;
  padding: 10px 10px 0 10px;
  border-radius: 0 5px 5px 5px;
  //box-shadow: 0 4px 8px rgba(14,4,143,.24);
  border: 1px solid #DFE3FF;
}

.popup_likeher_search_photos_big_photo_hello_btn_wrap {
  @media screen and (min-height: 801px) and (min-width: 600px) {
    width: 232px;
  }
}
.popup_likeher_search_photos_big_photo_hello_btn_wrap .c3_wp_hello_list_wrap {
  width: 450px;
  bottom: calc(100% - 1px);
  right: 0;
  top: unset;
  left: unset;
  border-radius: 5px 5px 0 5px;
}

.c3_wp_hello_list_title {
  font-size: 14px;
  color: $accentLeftRightColor;
  font-weight: 500;
  margin-bottom: 17px;
  text-align: center;
  line-height: normal;
}

.c3_wp_hello_list {
  padding-right: 5px;
  max-height: 200px;
  overflow-y: auto;
  padding-bottom: 10px;
  list-style: none;
}

.c3_wp_hello_list::-webkit-scrollbar {
  width: 2px;
  background: #fff;
  border-radius: 3px;
}

.c3_wp_hello_list::-webkit-scrollbar-thumb {
  background: $accentLeftColor;
  border-radius: 10px;
}

.c3_wp_hello_list_item {
  border: 1px solid #eee;
  border-radius: 5px;
  padding: 10px 15px;
  color: $accentLeftRightColor;
  letter-spacing: -0.02em;
  cursor: pointer;
  transition: .3s;
  background-color: #fff;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.05);
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
}

.c3_wp_hello_list_item:hover {
  box-shadow: 0 4px 8px rgba(14, 4, 143, .14);
}

.c3_wp_hello_list_item + .c3_wp_hello_list_item {
  margin-top: 10px;
}

.c3_woman_profile_main_block_info_btn {
  width: 188px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border: 1px solid #00317B;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color .3s, box-shadow .3s, color .3s, height .3s;
  font-weight: 600;
}

.c3_woman_profile_main_block_info_btn span {
  background: $backgroundButtonColor;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.c3_woman_profile_main_block_info_btn.say_hello.active {
  height: 40px;
  border-radius: 5px 5px 0 0;
  border-color: #DFE3FF;
  border-bottom-color: transparent;
  z-index: 5;
  position: relative;
  background-color: #EBEDFA;
}

.c3_woman_profile_main_block_info_btn.like.active {
  background-color: #EB5757;
  border-color: #EB5757;
  color: #fff;
  box-shadow: none;
  cursor: default;
}

.c3_woman_profile_main_block_info_btn.like.active span {
  background: transparent;
  -webkit-background-clip: unset;
  -webkit-text-fill-color: unset;
  color: #fff;
}

.c3_woman_profile_main_block_info_btn span {
  background: $backgroundButtonColor;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.c3_woman_profile_main_block_info_btn.like.active {
  background-color: #446077;
  border-color: #446077;
  color: #fff;
  box-shadow: none;
  cursor: default;
}

.c3_woman_profile_main_block_info_btn.like.active svg {
  width: 34px;
  height: 30px;
  margin-right: 10px;
  fill: #FFFFFF;
}

.c3_woman_profile_main_block_info_btn.like.active span {
  background: transparent;
  -webkit-background-clip: unset;
  -webkit-text-fill-color: unset;
  color: #fff;
}

.c3_woman_profile_main_block_info_btn:not(.active):hover {
  box-shadow: $smallBtnShadowHover;
}

.c3_wp_hello_btn_wrap + .c3_woman_profile_main_block_info_btn {
  margin-left: 16px;
}

.c3_woman_profile_main_block_info_btn img {
  margin-right: 12px;
}

.c3_woman_profile_main_block_info_btn svg {
  margin-right: 12px;
}

.c3_woman_profile_main_block_list_title {
  font-weight: 600;
  font-size: 22px;
  line-height: 20px;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  color: $primaryColor;
  margin-bottom: 12px;
}

.c3_woman_profile_main_block_list_item {
  font-size: 14px;
  letter-spacing: 0.06em;
  color: $accentLeftColor;
  margin-bottom: 5px;
  padding-left: 20px;
  text-transform: capitalize;
  line-height: 1.8;
}

.c3_woman_profile_main_block_list_item:last-child {
  margin-bottom: 0;
}

.c3_woman_profile_main_block_list_item span {
  font-weight: 600;
  color: $accentLeftColor;
}

.c3_woman_profile_main_block_list_item span.not_specs {
  color: $counterAndErrorColor;
}

.c3_woman_profile_main_block_buttons {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  -ms-align-self: flex-end;
  align-self: flex-end;
}

.c3_woman_profile_main_block_btn {
  width: 190px;
  height: 44px;
  line-height: 44px;
  background-color: $accentLeftColor;
  border-radius: 5px;
  color: $whiteColor;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 0.06em;
  text-align: center;
  margin-left: 22px;
  cursor: pointer;
}

.c3_woman_profile_main_block_btn img {
  vertical-align: middle;
  margin-right: 12px;
}

.c3_woman_profile_second_block {
  background-color: $whiteColor;
  box-shadow: 0px 6px 12px rgba(47, 43, 99, 0.16);
  border-radius: 10px;
  margin-top: 16px;
  padding: 28px 44px 28px 28px;
}

.c3_woman_profile_second_block_row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 60px;
}

.c3_woman_profile_main_block_list_flex_wrap {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  flex: 1;
  margin-right: 20px;
}

.c3_woman_profile_main_block_list {
  flex: 1;
}

.c3_woman_profile_main_block_list:first-child {
  //flex: .7;
}

.c3_woman_profile_main_block_list:nth-child(2) {
  //margin-right: 30px;
}

.c3_woman_profile_tabs_wrap {
  margin-bottom: 0;
  flex: 1;

}

.c3_woman_profile_tabs_links_wrap {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: center;
  margin-bottom: 28px;
}

.c3_woman_profile_tabs_link {
  width: 314px;
  font-weight: 600;
  font-size: 18px;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  color: $stormGreyColor;
  text-align: center;
  border-bottom: 1px solid $familySizeCondensedSoupColor;
  padding-bottom: 10px;
  cursor: pointer;
}

.c3_woman_profile_tabs_link.active {
  color: $accentLeftColor;
  font-weight: bold;
  border-bottom: 3px solid $accentLeftColor;
  padding-bottom: 8px;
}

.c3_woman_profile_tab_block {
  display: none;
}

.c3_woman_profile_tab_block.active {
  display: block;
}

.c3_woman_profile_tab_about {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.c3_woman_profile_tab_about_item {
  //width: calc(50% - 28px);
}

.c3_woman_profile_tab_about_item + .c3_woman_profile_tab_about_item {
  margin-top: 65px;
}

.c3_woman_profile_tab_about_item_title {
  font-weight: 600;
  font-size: 22px;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  color: $primaryColor;
  margin-bottom: 10px;
}

.c3_woman_profile_tab_about_item_text {
  font-size: 16px;
  line-height: 150%;
  text-align: justify;
  color: $stormGreyColor;
}

.c3_woman_profile_tab_about_item_more_btn {
  width: 175px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #446077;
  border-radius: 5px;
  color: #446077;
  font-weight: 550;
  font-size: 16px;
  margin-top: 20px;
  cursor: pointer;
  transition: .3s;
  display: none;
}

.c3_woman_profile_tab_about_item_more_btn:hover {
  box-shadow: $smallBtnShadowHover;
}

.c3_woman_profile_tab_blog {
  padding-top: 10px;
}

.c3_woman_profile_tab_blog_item {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  margin-bottom: 30px;
}

.c3_woman_profile_tab_blog_item_photos {
  width: 218px;
  margin-right: 32px;
}

.c3_woman_profile_tab_blog_item_big_photo img {
  width: 218px;
  height: 364px;
  object-fit: cover;
  display: block;
  border-radius: 5px;
}

.c3_woman_profile_tab_blog_item_small_photos {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
}

.c3_woman_profile_tab_blog_item_small_photos img {
  width: 65px;
  height: 108px;
  object-fit: cover;
  display: block;
  border-radius: 5px;
}

.c3_woman_profile_tab_blog_item_content_title {
  font-weight: 600;
  font-size: 24px;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  color: $primaryColor;
  margin-bottom: 13px;
}

.c3_woman_profile_tab_blog_item_content_date {

  font-size: 12px;
  color: $defaultTextColorAlpha6;
  margin-bottom: 32px;
}

.c3_woman_profile_tab_blog_item_content_text p {

  font-size: 16px;
  line-height: 150%;
  color: $defaultTextColor;
  margin-bottom: 30px;
}

.c3_woman_profile_tab_blog_pagination {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  width: 100%;
  justify-content: center;
  -ms-align-items: center;
  align-items: center;
}

.c3_woman_profile_tab_blog_pagination_nums {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  margin: 0 10px;
}

.c3_woman_profile_tab_blog_pagination_current {

  width: 28px;
  height: 28px;
  line-height: 30px;
  text-align: center;
  border-radius: 100%;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  background-color: $whiteColor;
  color: $accentRightColor;
  margin-right: 8px;
  outline: none;
}

.c3_woman_profile_tab_blog_pagination_all {

  color: $oldGrayColor;
}

.c3_woman_profile_tab_blog_pagination_buttons {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
}

.c3_woman_profile_tab_blog_pagination_btn {
  width: 27px;
  height: 13px;
  border-radius: 5px;
  background-image: url(../img/c3wp-pagin-disable.svg);
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
}

.c3_woman_profile_tab_blog_pagination_btn:last-child {
  transform: rotate(180deg);
  margin-left: 5px;
}

.c3_woman_profile_tab_blog_pagination_btn.active {
  background-color: $accentRightColor;
  background-image: url(../img/c3wp-pagin-active.svg);
}

.c3_woman_profile_tab_blog_pagination_btn.active:first-child {
  transform: rotate(180deg);
}

.c3_woman_profile_tab_blog_pagination_btn.active:last-child {
  transform: unset;
}

.c3_woman_profile_page_title {
  font-weight: 600;
  font-size: 22px;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  color: $primaryColor;
  margin-bottom: 24px;
}

.c3_woman_profile_gallery_video_wrap {
  margin-top: 50px;
}

.c3_woman_profile_gallery_photo_wrap {
  width: 100%;
}

.c3_woman_profile_gallery_video_list_wrap {
  overflow-y: auto;
  padding-bottom: 23px;
  position: relative;
}

.c3_woman_profile_gallery_video_list_wrap::-webkit-scrollbar {
  height: 5px;
  background: $familySizeCondensedSoupColor;
  border-radius: 22px;
}

.c3_woman_profile_gallery_video_list_wrap::-webkit-scrollbar-thumb {
  background: $accentLeftColor;
  border-radius: 9px;
}

.c3_woman_profile_gallery_video_list {
  /*width: 3000px;
*/
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
}

.c3_woman_profile_empty_video {
  width: 348px;
  height: 201px;
  background-image: url(../img/profile-empty-video.svg);
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);
}

.c3_woman_profile_empty_video.disabled_ask_video {
  width: 348px;
  height: 201px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);
}

.c3_woman_profile_empty_video_title {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  color: #FFFFFF;
  text-shadow: 0 1px 2px rgba(5, 0, 57, 0.33);
  margin-bottom: 14px;
}

.c3_woman_profile_empty_video_title img,
.c3_woman_profile_empty_video_title svg {
  width: 31px;
  margin-right: 14px;
  margin-top: 2px;
}

.c3_woman_profile_empty_video_title svg {
  width: 31px;
  height: 30px;
  fill: #FFFFFF;
  margin-right: 14px;
  margin-top: 2px;
}

.c3_woman_profile_empty_video_btn {
  width: 134px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid $bluePinkColor;
  border-radius: 5px;
  background-color: #fff;
  font-weight: 600;
  font-size: 12px;
  letter-spacing: -0.03em;
  color: $bluePinkColor;
  cursor: pointer;
}

.c3_woman_profile_empty_video_btn img,
.c3_woman_profile_empty_video_btn svg {
  margin-right: 9px;
  fill: $iconVideosFillColor;
}

.c3_woman_profile_empty_video_btn svg {
  width: 24px;
  height: 24px;
  margin-right: 9px;
  fill: $bluePinkColor;
}

.c3_woman_profile_gallery_video_list_item {
  width: 348px;
  background-color: $whiteColor;
  border-radius: 10px;
  position: relative;
  cursor: pointer;
  margin-right: 23px;
}

.c3_woman_profile_gallery_video_list_item .c3mp_video_list_item {
  margin: 0;
  width: 100%;
}

.c3_woman_profile_gallery_video_list_item .c3mp_video_list_item_img {
  border-radius: 10px;
  height: 201px;
}

.c3_woman_profile_gallery_video_list_item_img_wrap {
  position: relative;
  cursor: pointer;
}

.c3_woman_profile_gallery_video_list_item_img {
  width: 100%;
  height: 357px;
  border-radius: 10px;
  object-fit: cover;
  display: block;
}

.c3_woman_profile_gallery_video_list_item_play {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.c3_woman_profile_gallery_video_list_item_play img {
  width: 100px;
}

.c3_woman_profile_gallery_video_list_item_bottom {
  height: 84px;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  font-weight: 500;
  font-size: 24px;
  color: $accentLeftColor;
  padding: 0 19px;
}

.c3_woman_profile_gallery_photo_top_big_list_wrap {
  /*width: calc(100vw - 652px);*/
  overflow: hidden;
  position: relative;
}

.ReactModal__Content.ReactModal__Content--after-open .c3_woman_profile_gallery_photo_top_big_list_wrap {
  width: auto;
}

.ReactModal__Content.ReactModal__Content--after-open .c3_woman_profile_gallery_photo_top_small_list_wrap {
  /*width: auto;*/
}

.c3_woman_profile_gallery_photo_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.7);
  transition: background-color .3s;
}

.c3_woman_profile_gallery_photo_btn:hover {
  background-color: #fff;
}

.c3_woman_profile_gallery_photo_btn img {
  width: 18px;
}
.c3_woman_profile_gallery_photo_btn svg {
  width: 18px;
  height: 28px;
  stroke: #515457;
  stroke-width: 0.5;
  fill: #515457;
}

.c3_woman_profile_page_title_cost svg {
  width: 16px;
  height: 16px;
  fill: #5c5c5c;
}

.c3_woman_profile_gallery_photo_btn.btn_prev {
  left: 0;
  border-radius: 0 40px 40px 0;
  padding-right: 3px;
}

.c3_woman_profile_gallery_photo_btn.btn_next {
  right: 0;
  border-radius: 40px 0 0 40px;
  padding-left: 3px;
}


.c3_woman_profile_gallery_photo_top_big_list {
  width: max-content;
  display: flex;
}

.c3_woman_profile_gallery_photo_top_big_item {
  border-radius: 10px;
  overflow: hidden;
  margin-right: 16px;
  flex: none;
  cursor: pointer;
}

.c3_woman_profile_gallery_photo_top_big_item img {
  height: 480px;
  display: block;
  object-fit: cover;
}

.c3_woman_profile_gallery_photo_top_big_item.unpaid img {
  filter: blur(40px);
}

.c3_woman_profile_gallery_photo_top_small_list_wrap {
  padding-bottom: 9px;
  overflow-x: auto;
  margin-top: 20px;
}

.c3_woman_profile_gallery_photo_top_small_list_wrap::-webkit-scrollbar {
  height: 3px;
  background: $familySizeCondensedSoupColor;
  border-radius: 22px;
}

.c3_woman_profile_gallery_photo_top_small_list_wrap::-webkit-scrollbar-thumb {
  background: $accentLeftColor;
  border-radius: 9px;
}

.c3_woman_profile_gallery_photo_top_small_list {
  width: max-content;
  display: flex;
  list-style: none;
}

.c3_woman_profile_gallery_photo_top_small_list_item {
  border-radius: 10px;
  overflow: hidden;
  margin-right: 18px;
  flex: none;
  cursor: pointer;
}

.c3_woman_profile_gallery_photo_top_small_list_item:last-child {
  margin-right: 0;
}

.c3_woman_profile_gallery_photo_top_small_list_item img {
  height: 135px;
  width: 135px;
  display: block;
  object-fit: cover;
}

.c3_woman_profile_gallery_photo_top_small_list_item.unpaid img {
  filter: blur(30px);
}

.c3_woman_profile_head {
  height: 80px;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  justify-content: center;
  padding: 0 15px;
  flex: none;
}

.ReactModal__Content.ReactModal__Content--after-open .c3_woman_profile_head {
  /*display: none;*/
  position: fixed;
  top: 16px;
  left: 50%;
  width: 100%;
  max-width: 1316px;
  transform: translateX(-50%);
  z-index: 9;
}

.ReactModal__Content.ReactModal__Content--after-open .c3_woman_profile_page_wrap {
  overflow-y: auto;
}

.ReactModal__Content.ReactModal__Content--after-open .c3_woman_profile_page_wrap::-webkit-scrollbar {
  width: 3px;
  background: #fff;
  border-radius: 3px;
}

.ReactModal__Content.ReactModal__Content--after-open .c3_woman_profile_page_wrap::-webkit-scrollbar-thumb {
  background: #00317B;
  border-radius: 3px;
}

.c3_woman_profile_head_item {
  background-color: $whiteColor;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 62px;
  margin-right: 20px;
  box-shadow: 0 6px 12px rgba(47, 43, 99, 0.16);
  padding: 0 15px 0 15px;
  border-radius: 10px;
  cursor: pointer;
  position: relative;
  transition-duration: .3s;

  &:disabled {
    background: #EEE;

    .c3_woman_profile_head_item_text {
      color: #9F9F9F;
      -webkit-text-fill-color: unset;
      -webkit-background-clip: unset;
      background: unset;
    }

    svg {
      fill: #9F9F9F;
    }

    &:hover,
    &:focus {
      .c3_woman_profile_head_item_text {
        color: #333333;
      }

      svg {
        fill: #333333;
      }

      .c3_woman_profile_gift_btn_tip {
        visibility: visible;
        opacity: 1;
      }
    }
  }

  svg {
    width: 22px;
    height: 22px;
    min-height: 22px;
    min-width: 22px;
    margin-right: 10px;
    fill: $accentLeftColor;
  }
}

.c3_woman_profile_gift_btn_tip {
  visibility: hidden;
  opacity: 0;
  padding: 12px;
  transition-duration: .3s;
  position: absolute;
  top: 115%;
  left: 0;
  border-radius: 5px;
  background: $whiteColor;
  color: #4F4F4F;
  font-size: 12px;
  font-weight: 400;
  width: 256px;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.15);
  z-index: 10;
  text-align: center;

  strong {
    color: #333;
    display: block;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 4px;
  }

  &:before {
    content: '';
    width: 14px;
    height: 14px;
    background: $whiteColor;
    position: absolute;
    top: -4px;
    left: 16px;
    transform: rotate(45deg);
  }

  @media screen and (max-width: 767px) {
    font-size: 10px;
    padding: 8px;
    left: unset;
    right: 0;
    text-align: left;
    top: 120%;
    width: 190px;

    strong {
      font-size: 12px;
    }

    &:before {
      left: unset;
      right: 16px;
    }
  }
}

.c3_woman_profile_head_item:last-child {
  margin-right: 0;
}

.c3_woman_profile_head_item.disabled {
  background-color: $whiteColor;
  -webkit-box-shadow: none;
  box-shadow: none;
  cursor: default;
}

.c3_woman_profile_head_item.c3wphi_chat {
  background: $backgroundButtonColor;
  box-shadow: 0 6px 12px rgba(47, 43, 99, 0.16);
  flex: 1;
  max-width: 374px;

  svg {
    fill: $whiteColor;
  }
}

.c3_woman_profile_head_item_text {
  flex: none;
  font-weight: 600;
  font-size: 20px;
  color: $accentLeftColor;
  background: $backgroundButtonColor;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.c3_woman_profile_head_item.c3wphi_chat .c3_woman_profile_head_item_text {
  //color: #fff;
  background: #FFFFFF;
  webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  font-weight: 700;
  //-webkit-background-clip: unset;
  //-webkit-text-fill-color: unset;
}

.disabled .c3_woman_profile_head_item_text {
  background: none;
  color: $arsenicColor;
  -webkit-background-clip: unset;
  -webkit-text-fill-color: unset;
}

.c3_woman_profile_head_item_img {
  width: 38px;
  height: 38px;
  background-repeat: no-repeat;
  background-position: center;
  margin-right: 15px;
  flex: none;
  background-size: 30px;
}

.c3wphi_letter .c3_woman_profile_head_item_img {
  background-image: $profileLetter;
}

.c3wphi_chat .c3_woman_profile_head_item_img {
  background-image: url(../img/chat-white.svg);
}

.c3wphi_gift .c3_woman_profile_head_item_img {
  background-image: $profileGiftTop;
  background-size: 26px;
}

.c3wphi_gift.disabled .c3_woman_profile_head_item_img {
  background-image: url(../img/c3wpt_gift_dis.svg);
}

.c3wphi_favs .c3_woman_profile_head_item_img {
  background-image: $profileFavs;
}

.c3wphi_favs.active .c3_woman_profile_head_item_img {
  background-image: url(../img/c3wpt_favs_active.svg);
}

.c3wphi_webcam .c3_woman_profile_head_item_img {
  background-image: url(../img/c3wpt_webcam.svg);
}

.c3wphi_webcam.disabled .c3_woman_profile_head_item_img {
  background-image: url(../img/c3wpt_webcam_dis.svg);
}

.c3wphi_compl .c3_woman_profile_head_item_img {
  background-image: $profileComplain;
}

.c3_woman_profile_gallery_photo_top_big_item {
  position: relative;
}

.c3_woman_profile_gallery_photo_top_big_item.unpaid .popup_likeher_photo_unpaid_warning {
  display: block;
}

.display_online_on_profile_page {
  text-align: right;
  font-size: 19px;
  width: auto;
  float: right;
}

.display_inline_block {
  display: inline-block;
}

.c3_woman_profile_head.c3_woman_profile_head_mob {
  display: none;
}

.profile_flex {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.popup_profile_close {
  position: fixed;
  top: 13px;
  right: 13px;
  padding: 5px;
  cursor: pointer;
  z-index: 10;
}

.popup_profile_close img {
  width: 39px;
  display: block;
}

.c3_woman_profile_main_block .popup_likeher_photo_unpaid_warning {
  padding: 20px 10px;
}

.c3_woman_profile_mobile_buttons {
  display: none;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 17px;
}

.c3_woman_profile_mob_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(50% - 4px);
  height: 36px;
  border: 1px solid #446077;
  border-radius: 5px;
  font-weight: 600;
  font-size: 12px;
  letter-spacing: -0.03em;
  color: #446077;
  position: relative;

  svg {
    width: 18px;
    height: 18px;
    min-height: 18px;
    min-width: 18px;
    margin-right: 8px;
    fill: #446077;
  }

  &:disabled {
    background: #EEE;
    border-color: #EEE;

    span {
      color: #9F9F9F;
    }

    svg {
      fill: #9F9F9F;
    }

    &:hover,
    &:focus {
      span {
        color: #333333;
      }

      svg {
        fill: #333333;
      }

      .c3_woman_profile_gift_btn_tip {
        visibility: visible;
        opacity: 1;
      }
    }
  }
}

.c3_woman_profile_mob_btn.active {
  justify-content: center;
  padding-left: 10px;
}

.c3_woman_profile_mob_btn:nth-child(1),
.c3_woman_profile_mob_btn:nth-child(2),
.c3_woman_profile_mob_btn:nth-child(3),
.c3_woman_profile_mob_btn:nth-child(4) {
  margin-bottom: 8px;
}

.c3_woman_profile_mob_btn img {
  margin-right: 9px;
}

.c3_woman_profile_mob_btn.complain {
  display: flex;
  align-items: center;
  justify-content: center;
  //border: 1px solid #EB5757;
  //color: #EB5757;

  svg {
    fill: #446077;
  }
}

.c3_woman_profile_mob_btn.write_letter {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: $mainFont;
  color: #00317B;
  background: #E8EEF8;
  border: 1px solid #A3B5D3;

  svg {
    fill: #00317B;
  }
}

.c3_woman_profile_mob_btn.contact {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: $mainFont;

  &:before {
    content: '';
    display: block;
    width: 18px;
    height: 18px;
    min-width: 18px;
    margin-right: 6px;
    background: #446077;
    mask: url('../img/c3ph-contact.svg') center no-repeat;
    mask-size: contain;
  }
}

.c3_woman_profile_mob_btn.meeting {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: $mainFont;

  &:before {
    content: '';
    display: block;
    width: 18px;
    height: 18px;
    min-width: 18px;
    margin-right: 6px;
    background: #446077;
    mask: url('../img/c3ph-meet.svg') center no-repeat;
    mask-size: contain;
  }
}


.c3_woman_profile_mob_nav {
  display: none;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  bottom: 50px;
  left: 0;
  width: 100%;
  padding: 0 35px 14px 30px;
  z-index: 10;
}

.c3_woman_profile_mob_nav:before {
  content: '';
  width: 100%;
  height: 92px;
  position: absolute;
  left: 0;
  bottom: 0;
  pointer-events: none;
  background: linear-gradient(0deg, #EBEDFA 0%, rgba(255, 255, 255, 0) 100%);
}

.c3_woman_profile_mob_nav > button {
  width: calc(50% - 3px);
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  font-weight: 600;
  font-size: 12px;
  letter-spacing: -0.03em;
  position: relative;
}

.c3_woman_profile_mob_nav > button svg,
.c3_woman_profile_mob_nav > button img {
  width: 17px;
  height: 17px;
  margin-right: 9px;
}

.c3_woman_profile_mob_nav_chat_btn {
  background: $buttonBackgroundColor;
  color: #fff;

  svg {
    fill: #fff;
  }
}

.c3_woman_profile_mob_nav_like_btn {
  background-color: #fff;
  color: #446077;
  border: 1px solid #446077;
}

.c3_woman_profile_mob_nav_like_btn svg {
  width: 17px;
  height: 17px;
  margin-right: 10px;
  fill: $iconLikeWomanProfileFillColor;
}

.c3_woman_profile_mob_nav_like_btn.active svg {
  width: 17px;
  height: 17px;
  margin-right: 10px;
  fill: #FFFFFF;
}

.c3_woman_profile_mob_nav_like_btn.active {
  background-color: #446077;
  color: #fff;
  border-color: #446077;
}

.ReactModal__Content.ReactModal__Content--after-open .c3_woman_profile_page_wrap .girls_slider_wrap_global {
  padding: 0;
  margin: 30px 0 0 0;
}

.ReactModal__Content.ReactModal__Content--after-open .c3_woman_profile_page_wrap .girls_slider_list_wrap_global:after {
  background: linear-gradient(to left, #fff 0%, rgba(255, 255, 255, 0) 60%);
}

@media screen and (max-width: 1899px) {
  .c3_woman_profile_page_wrap2 {
    /*height: calc(100vh - 80px);
*/
  }

  .c3_woman_profile_page_wrap {
    padding-top: 0;
  }

  /*.c3_woman_profile_head{
      justify-content: center;
      height: 70px;
      margin-bottom: 10px;
  }

  .c3_woman_profile_head_item{
      flex-wrap: wrap;
      width: 116px;
      text-align: center;
      padding: 0;
      align-items: flex-end;
      margin-right: 8px;
      height: 62px;
  }

  .c3_woman_profile_head_item.c3wphi_webcam{
      width: 145px;
  }

  .c3_woman_profile_head_item_text{
      font-size: 14px;
      width: 100%;
      margin-bottom: 6px;
  }

  .c3_woman_profile_head_item_img{
      width: 29px;
      height: 29px;
      background-size: contain;
      margin: 5px auto 0;
  }

  .c3_woman_profile_head_item_text br{
      display: none;
  }*/
}

@media screen and (max-width: 1849px) {
  .c3_woman_profile_main_block_info_btn {
    width: 134px;
    height: 34px;
    font-size: 12px;
  }

  .c3_woman_profile_main_block_info_btn img {
    width: 20px;
    margin-right: 10px;
  }

  .c3_woman_profile_main_block_info_btn svg {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }

  .c3_woman_profile_main_block_info_btn.say_hello svg {
    width: 22px;
    height: 22px;
  }

  .c3_woman_profile_main_block_info_btn.like_her_btn svg {
    width: 20px;
    height: 17px;
  }

  .c3_woman_profile_main_block_info_btn.like.active svg {
    width: 20px;
    height: 17px;
  }
}

@media screen and (max-width: 1699px) {
  .c3_woman_profile_head_item {
    padding: 0 15px;
    height: 48px;
  }

  .c3_woman_profile_head_item_text {
    font-size: 12px;
  }

  .c3_woman_profile_head_item_img {
    width: 22px;
    height: 22px;
    background-size: contain;
    background-position: center;
    margin-right: 13px;
  }

  .c3wphi_gift .c3_woman_profile_head_item_img {
    background-size: contain;
  }

  .c3_woman_profile_head_item.c3wphi_chat {
    max-width: 308px;
  }
}

@media screen and (max-width: 1649px) {

  .c3_woman_profile_main_block_list_title {
    font-size: 16px;
    margin-bottom: 5px;
  }

  .c3_woman_profile_main_block_list_item {
    font-size: 14px;
    font-weight: 400;
  }

  .c3_woman_profile_main_block_list {
    //margin-bottom: 10px;
  }

  .c3_woman_profile_main_block_name {
    font-size: 30px;
  }

  .c3_woman_profile_main_block_info_top {
    padding-bottom: 16px;
    margin-bottom: 16px;
  }

  .ReactModal__Content.ReactModal__Content--after-open .c3_woman_profile_head_item {
    padding: 0 25px;
  }

  .ReactModal__Content.ReactModal__Content--after-open .c3_woman_profile_head_item_text {
    font-size: 16px;
  }
}

@media screen and (max-width: 1599px) {
  .c3_woman_profile_main_block {
    padding: 14px;
  }

  .c3_woman_profile_main_block_name {
    font-size: 28px;
  }

  .c3_woman_profile_main_block_dob {
    font-size: 12px;
  }

  .c3_woman_profile_main_block_info_top_left_bottom {
    margin-top: 14px;
  }

  .c3_woman_profile_main_block_id {
    font-size: 12px;
  }

  .c3_woman_profile_main_block_place {
    font-size: 12px;
    margin-left: 20px;
    line-height: 1.5;
  }

  .c3_woman_profile_main_block_place img {
    height: 12px;
  }

  .c3_woman_profile_online_status {
    width: 130px;
    height: 25px;
    font-size: 12px;
    top: 6px;
    right: -4px;
  }

  .c3_wp_hello_btn_wrap + .c3_woman_profile_main_block_info_btn {
    margin-left: 5px;
  }

  .c3_woman_profile_gallery_photo_top_big_item img {
    height: 271px;
  }

  .c3_woman_profile_gallery_photo_top_small_list_item {
    margin-right: 5px;
  }

  .c3_woman_profile_gallery_photo_top_small_list_item img {
    height: 95px;
    width: 95px;
  }

  .c3_woman_profile_page_title {
    font-size: 16px;
  }

  .c3_woman_profile_gallery_photo_btn {
    width: 31px;
    height: 31px;
  }

  .c3_woman_profile_gallery_photo_btn img {
    width: 12px;
  }

  .c3_woman_profile_gallery_photo_btn svg {
    width: 12px;
    height: 19px;
  }

  .c3_woman_profile_main_block_photo {
    width: 34%;
  }

  .c3_woman_profile_main_block_info {
    width: calc(66% - 20px);
    margin-left: 20px;
  }

  .c3_woman_profile_gallery_photo_top_big_item {
    margin-right: 5px;
  }

  .c3_woman_profile_gallery_photo_top_small_list_wrap {
    margin-top: 8px;
  }

  .ReactModal__Content.ReactModal__Content--after-open .c3_woman_profile_gallery_photo_top_big_item img {
    height: 420px;
  }

  .ReactModal__Content.ReactModal__Content--after-open .c3_woman_profile_gallery_photo_top_small_list_item img {
    width: 120px;
    height: 120px;
  }

  .c3_wp_hello_list_wrap {
    width: 273px;
  }
}

@media screen and (max-width: 1549px) {
  .c3_woman_profile_main_block_list_flex_wrap {
    display: flex;
  }

  .c3_woman_profile_tab_about_item_title {
    font-size: 16px;
    margin-bottom: 16px;
  }

  .c3_woman_profile_tab_about_item_text {
    font-size: 14px;
  }

  .display_online_on_profile_page {
    font-size: 16px;
  }

  .c3_woman_profile_tab_about_item_more_btn {
    width: 130px;
    height: 36px;
    font-size: 14px;

  }
}

@media screen and (max-width: 1499px) {

  .c3_woman_profile_gallery_video_list_item .c3mp_video_list_item_img {
    height: 201px;
  }

  .c3_woman_profile_second_block {
    margin-top: 16px;
  }

  .c3_woman_profile_head_item {
    margin-right: 16px;
  }

  .c3_woman_profile_head {
    height: 67px;
  }
}

@media screen and (max-width: 1449px) {
  .c3_woman_profile_page_wrap {
    padding-top: 0;
  }

  .c3_woman_profile_main_block_info_top {
    padding-bottom: 15px;
    margin-bottom: 10px;
  }

  .c3_woman_profile_main_block_list_item {
    font-size: 12px;
  }

  .c3_woman_profile_main_block_btn {
    width: 149px;
    height: 32px;
    line-height: 32px;
    font-size: 14px;
  }

  .c3_woman_profile_main_block_btn img {
    margin-right: 8px;
    margin-bottom: 2px;
  }

  .c3_woman_profile_main_block_btn:first-child img {
    width: 19px;
  }

  .c3_woman_profile_main_block_btn:last-child img {
    width: 21px;
  }

  .c3_woman_profile_gallery_video_list_item {
    width: 348px;
  }

  .c3_woman_profile_gallery_video_list_item_img {
    height: 201px;
  }

  .c3_woman_profile_gallery_video_list_item_bottom {
    height: 38px;
    font-size: 14px;
  }

  .c3_woman_profile_gallery_video_list_item_play img {
    width: 50px;
  }

  .display_online_on_profile_page {
    font-size: 14px;
  }
}

@media screen and (max-width: 1399px) {
  .c3_woman_profile_head_item_img {
    background-size: 26px;
    margin-right: 8px;
    width: 30px;
    height: 30px;
  }

  .c3wphi_gift .c3_woman_profile_head_item_img {
    background-size: 22px;
  }

  .c3_woman_profile_head_item_text {
    font-size: 14px;
  }

  .c3_woman_profile_head_item {
    margin-right: 8px;
    padding: 0 16px;
  }
}

@media screen and (max-width: 1199px) {
}

@media screen and (max-width: 1139px) {
  .c3_woman_profile_gallery_photo_top_small_list_wrap {
    padding-bottom: 0;
  }

  .c3_woman_profile_gallery_photo_top_small_list_wrap::-webkit-scrollbar {
    opacity: 0;
    background-color: transparent;
  }

  .c3_woman_profile_gallery_photo_top_small_list_wrap::-webkit-scrollbar-thumb {
    background-color: transparent;
  }

  .c3_woman_profile_main_block_info_top_right {
    flex-direction: column;
    align-items: flex-end;
    margin-top: 30px;
  }

  .c3_wp_hello_btn_wrap + .c3_woman_profile_main_block_info_btn {
    margin-left: 0;
    margin-top: 5px;
  }

  .c3_woman_profile_main_block_photo {
    width: 40%;
  }

  .c3_woman_profile_main_block_info {
    width: calc(60% - 20px);
  }

  .c3_woman_profile_main_block_name {
    font-size: 24px;
  }

  .c3_woman_profile_tab_about_item {
    //width: calc(50% - 14px);
  }

  .c3_woman_profile_second_block {
    margin-top: 8px;
  }

  .ReactModal__Content.ReactModal__Content--after-open .c3_woman_profile_head {
    top: 32px;
  }

  .ReactModal__Content.ReactModal__Content--after-open .c3_woman_profile_head_item {
    height: 42px;
  }

  .ReactModal__Content.ReactModal__Content--after-open .c3_woman_profile_head_item_text {
    font-size: 14px;
  }

  .ReactModal__Content.ReactModal__Content--after-open .c3_woman_profile_gallery_photo_top_big_item img {
    height: 271px;
  }

  .ReactModal__Content.ReactModal__Content--after-open .c3_woman_profile_gallery_photo_top_small_list_item img {
    width: 91px;
    height: 91px;
  }

  .c3_wp_hello_list_wrap {
    top: 110%;
    right: 0%;
    left: unset;
  }

  .c3_woman_profile_main_block_info_btn.say_hello.active {
    height: 34px;
    width: 154px;
    border-bottom-color: #EBEDFA;
    border-left-color: transparent;
    border-radius: 0 5px 5px 0;
  }

  .c3_wp_hello_list_wrap {
    border-radius: 5px 0 5px 5px;
  }

  .popup_likeher_search_photos_big_photo_hello_btn_wrap .c3_wp_hello_list_wrap {
    width: 450px;
  }
}

@media screen and (max-width: 1099px) {
  /*.c3_woman_profile_head{
      justify-content: flex-start;
      padding-left: 24px;
  }*/
}

@media screen and (max-width: 999px) {
  .c3_woman_profile_page_wrap2 {
    /*height: calc(100vh - 189px);
    height: calc(calc(var(--vh, 1vh) * 100) - 189px);
*/
  }

  .c3_woman_profile_gallery_video_list_item .c3mp_video_list_item_img {
    height: 167px;
  }

  .c3_woman_profile_gallery_video_list_item {
    width: 275px;
  }

  .c3_woman_profile_gallery_video_list_item_img {
    height: 167px;
  }

  .c3_woman_profile_gallery_video_list_item_play img {
    width: 42px;
  }

  .c3_woman_profile_gallery_photo_top_big_item {
    margin-right: 10px;
  }

  .c3_woman_profile_empty_video {
    width: 275px;
    max-width: 100%;
    height: 167px;
  }

  .c3_woman_profile_empty_video_title {
    font-size: 14px;
  }

  .c3_woman_profile_empty_video_title img {
    width: 24px;
    margin-right: 9px;
    margin-top: 2px;
  }

  .c3_woman_profile_empty_video_title svg {
    width: 24px;
    height: 24px;
    margin-right: 9px;
    margin-top: 2px;
  }

  .c3_woman_profile_second_block {
    padding: 14px;
  }

  .c3_woman_profile_second_block_row {
    flex-direction: column;
  }

  .c3_woman_profile_main_block_list_flex_wrap {
    flex-direction: row;
    margin-bottom: 40px;
    margin-right: 0;
  }

  .c3_woman_profile_main_block_list {
    margin-bottom: 0;
  }

  .c3_woman_profile_main_block_list:nth-child(1) {
    flex: .8;
  }
  .c3_woman_profile_main_block_list:nth-child(2) {
    flex: 1.4;
    margin: 0 10px;
  }
  .c3_woman_profile_main_block_list:nth-child(3) {
    flex: .8;
  }
  .c3_woman_profile_tab_about_item + .c3_woman_profile_tab_about_item {
    margin-top: 40px;
  }

}

@media screen and (max-width: 899px) {
  .c3_woman_profile_head {
    margin-bottom: 0;
    height: 60px;
  }

  .c3_woman_profile_head_item {
    width: auto;
    height: auto;
    padding: 10px 12px;
    align-items: center;
  }

  .c3_woman_profile_head_item_text {
  }

  .c3_woman_profile_head_item_img {
    width: 24px;
    height: 24px;
    background-size: 20px;
  }

  .c3wphi_gift .c3_woman_profile_head_item_img {
    background-size: 18px;
  }

  .c3_woman_profile_page_wrap {
    padding-top: 0px;
  }
  .c3_woman_profile_page_wrap2 {
    /*height: calc(100vh - 169px);
    height: calc(calc(var(--vh, 1vh) * 100) - 169px);
*/
  }
}

@media screen and (max-width: 849px) {
  .c3_woman_profile_main_block_buttons {
    justify-content: center;
    margin-top: 16px;
  }

  .c3_woman_profile_main_block_btn {
    /*margin: 0 auto 10px;
*/
  }

  .c3_woman_profile_main_block_btn:last-child {
    margin-bottom: 0;
  }

  .c3_woman_profile_main_block {
  }

  .c3_woman_profile_main_block_list_flex_wrap {
    display: flex;
  }
}

@media screen and (max-width: 789px) {
  .popup_likeher_search_photos_big_photo_hello_btn_wrap .c3_wp_hello_list_wrap {
    width: 450px;
  }
}

@media screen and (max-width: 767px) {
  .c3_woman_profile_main_block {
    display: block;
  }

  .c3_woman_profile_main_block_buttons {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
  }

  .c3_woman_profile_main_block_btn {
    margin: 0 0 10px 24px;
  }

  .c3_woman_profile_tabs_link {
    width: 50%;
  }

  .c3_woman_profile_head_item_text {
    display: none;
  }

  .c3_woman_profile_head_item_img {
    margin: 0;
  }

  .c3_woman_profile_head {
    display: none;
  }

  .c3_woman_profile_main_block_info_top_right {
    /*display: none;*/
    flex-direction: row;
  }

  .c3_wp_hello_list_wrap {
    width: 240px;
  }

  .c3_wp_hello_btn_wrap + .c3_woman_profile_main_block_info_btn {
    margin-top: 0;
    margin-left: 5px;
  }

  .c3_woman_profile_main_block_photo {
    display: none;
  }

  .c3_woman_profile_main_block_info {
    width: 100%;
    margin-left: 0;
  }

  .c3_woman_profile_gallery_photo {
    max-width: 100%;
    width: 500px;
    display: flex;
    align-items: stretch;
    margin-left: auto;
    margin-right: auto;
  }

  .c3_woman_profile_gallery_photo_top_small_list_wrap {
    height: 500px;
    width: 70px;
    flex: none;
    margin-top: 0;
    margin-left: 10px;
  }

  .c3_woman_profile_gallery_photo_top_small_list {
    flex-direction: column;
    width: 100%;
  }

  .c3_woman_profile_gallery_photo_top_small_list_item {
    margin-right: 0;
    margin-bottom: 5px;
  }

  .c3_woman_profile_gallery_photo_top_small_list_item img {
    width: 100%;
    height: 52px;
  }

  .c3_woman_profile_gallery_photo_top_big_list_wrap {
    width: calc(100% - 75px);
    height: 500px;
  }

  .c3_woman_profile_gallery_photo_top_big_list {
    width: 100%;
    overflow: hidden;
    height: 100%;
  }

  .c3_woman_profile_gallery_photo_top_big_list > div {
    display: none;
    width: 100%;
  }

  .c3_woman_profile_gallery_photo_top_big_list > div:first-child {
    display: block;
  }

  .c3_woman_profile_gallery_photo_top_big_item {
    margin-right: 0;
    width: 100%;
    height: 100%;
  }

  .c3_woman_profile_gallery_photo_top_big_item img {
    width: 100%;
    height: 100%;
    object-position: top;
  }

  .c3_woman_profile_mobile_buttons {
    display: flex;
  }

  .c3_woman_profile_mob_nav {
    display: flex;
  }

  .ReactModal__Content.ReactModal__Content--after-open .c3_woman_profile_second_block {
    padding-bottom: 50px;
  }

  .ReactModal__Content.ReactModal__Content--after-open .c3_woman_profile_mob_nav {
    width: 100%;
    left: 0;
    bottom: 0;
  }

  .ReactModal__Content.ReactModal__Content--after-open .c3_woman_profile_mob_nav:before {
    border-radius: 0 0 10px 10px;
  }
}

@media screen and (max-height: 800px) {
  .popup_likeher_search_photos_big_photo_hello_btn_wrap .c3_wp_hello_list_wrap {
    max-width: calc(100vw - 40px);
    width: 300px;
    position: absolute;
    padding: 10px 10px 0 10px;
  }
}

@media screen and (max-width: 789px) and (max-height: 450px) {
  .popup_likeher_search_photos_big_photo_hello_btn_wrap .c3_wp_hello_list_wrap {
    width: 250px;
    position: absolute;
    padding: 10px 10px 0 10px;
  }
}

@media screen and (max-width: 599px) {
  .c3_woman_profile_page_wrap2 {
    /*height: calc(100vh - 100px);
    height: calc(calc(var(--vh, 1vh) * 100) - 100px);
*/
    padding: 5px;
    position: relative;
  }

  .c3_woman_profile_main_block_list_flex_wrap {
    display: block;
    margin-bottom: 42px;
  }

  .ReactModal__Content.ReactModal__Content--after-open .c3_woman_profile_page_wrap .girls_slider_wrap_global {
    display: block;
  }

  .ReactModal__Content.ReactModal__Content--after-open .c3_woman_profile_page_wrap .girls_slider_wrap_global > div {
    width: 100%;
  }

  .ReactModal__Content.ReactModal__Content--after-open .girls_slider_list_wrap_global {
    margin-top: 10px;
  }

  .c3_woman_profile_tab_about {
    display: block;
  }

  .c3_woman_profile_tab_about_item {
    width: 100%;
    margin-bottom: 40px;
  }

  .c3_woman_profile_tab_about_item:last-child {
    margin-bottom: 0;
  }

  .c3_woman_profile_tab_blog_item {
    display: block;
    margin-bottom: 60px;
    border-bottom: 1px solid $silverColor;
  }

  .c3_woman_profile_tab_blog_item_photos {
    margin: 0 auto 24px;
  }

  .c3_woman_profile_tab_blog_item_content_text p {
    margin-bottom: 16px;
  }

  .c3_woman_profile_tabs_link {
    font-size: 14px;
  }

  .c3_woman_profile_tab_about_item_title {
    font-size: 16px;
    margin-bottom: 8px;
  }

  .c3_woman_profile_page_wrap {
    padding-right: 0px;
  }

  .display_online_on_profile_page {
    position: absolute;
    bottom: 22px;
    left: 19px;
    font-size: 14px;
    z-index: 2;
  }

  .ReactModal__Content.ReactModal__Content--after-open .c3_woman_profile_main_block_photo .display_online_on_profile_page {
    width: auto;
  }

  .ReactModal__Content.ReactModal__Content--after-open .c3lh_your_likes_item_profile_online.display_inline.display_online_on_profile_page:before {
    width: 8px;
    height: 8px;
  }

  .c3lh_your_likes_item_profile_online.display_inline.display_online_on_profile_page:before {
    width: 13px;
    height: 13px;
  }

  .c3_woman_profile_main_block_dob span {
    display: block;
    margin-top: 3px;
  }

  .c3_woman_profile_main_block_buttons {
    padding-bottom: 16px;
  }

  .c3_woman_profile_main_block_btn {
    margin: 8px auto 0;
  }

  .c3_woman_profile_second_block {
    padding: 16px;
  }

  .c3_woman_profile_tab_about_item_text {
    font-size: 12px;
  }

  .c3_woman_profile_head {
    display: none;
    padding: 0;
    height: auto;
    align-self: center;
  }

  .c3_woman_profile_head.c3_woman_profile_head_mob {
    display: block;
  }

  .c3_woman_profile_head_mob {
    display: block;
  }

  .c3_woman_profile_head_item {
    margin-right: 0;
    margin-bottom: 16px;
    width: 50px;
    height: 44px;
    box-shadow: none;
    border: 1px solid $primaryColor;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
  }

  .c3_woman_profile_mob_nav .c3_woman_profile_head_item {
    margin: 0;
  }

  .c3_woman_profile_head_item:last-child {
    margin-bottom: 0;
  }

  .display_online_on_profile_page {
    font-size: 14px;
  }

  .c3_woman_profile_head_item_img {
    background-size: contain;
  }

  .c3wphi_letter .c3_woman_profile_head_item_img {
    background-image: url(../img/c3wpt_mail-m.svg);
  }

  .c3wphi_chat .c3_woman_profile_head_item_img {
    background-image: url(../img/c3wpt_chat-m.svg);
  }

  .c3wphi_gift .c3_woman_profile_head_item_img {
    background-image: url(../img/c3wpt_gift-m.svg);
    background-size: contain;
  }

  .c3wphi_favs .c3_woman_profile_head_item_img {
    background-image: url(../img/c3wpt_favs-m.svg);
  }

  .c3wphi_compl .c3_woman_profile_head_item_img {
    background-image: url(../img/c3wpt_compl-m.svg);
  }

  .c3_woman_profile_mob_nav {
    padding: 14px 12px;
    background: $whiteColor;
  }

  .c3_woman_profile_main_block_info_top_right {
    display: none;
  }

  .c3_woman_profile_gallery_photo_top_small_list_wrap {
    overflow-x: visible;
    width: 64px;
    height: 369px;
    margin-left: 7px;
  }

  .c3_woman_profile_gallery_photo_top_big_list_wrap {
    width: calc(100% - 71px);
    height: 369px;
  }

  .c3_woman_profile_gallery_photo_top_small_list_item img {
    height: 64px;
  }

  .c3_woman_profile_gallery_photo_top_small_list {
    display: block;
    height: 100%;
    overflow-y: auto;
  }

  .c3_woman_profile_main_block_name {
    font-size: 22px;
  }

  .c3_woman_profile_online_status {
    width: 111px;
    height: 27px;
    top: 8px;
    right: -3px;
  }

  .c3_woman_profile_main_block_say_hello_btn_mob {
    display: flex;
  }

  .c3_woman_profile_main_block_dob {
    margin-top: 10px;
    display: block;
  }

  .c3_woman_profile_main_block_info_top_left_bottom {
    margin-top: 7px;
  }

  .c3_woman_profile_main_block_list + .c3_woman_profile_main_block_list {
    margin-top: 42px;
  }

  .c3_woman_profile_tabs_wrap {
    margin-bottom: 20px;
  }

  .c3_woman_profile_gallery_photo_btn {
    width: 40px;
    height: 40px;
  }

  .c3_woman_profile_page_title {
    margin-bottom: 18px;
  }

  .c3_woman_profile_page_title_cost {
    padding-right: 10px;
  }

  .ReactModal__Content.ReactModal__Content--after-open .c3_woman_profile_gallery_photo_top_small_list_item img {
    width: 64px;
    height: 64px;
  }

  .ReactModal__Content.ReactModal__Content--after-open .c3_woman_profile_gallery_photo_top_big_item img {
    height: 100%;
  }

  .ReactModal__Content.ReactModal__Content--after-open .c3_woman_profile_second_block {
    padding-bottom: 70px;
  }

  .c3_woman_profile_main_block_list:nth-child(2) {
    margin-left: 0;
    margin-right: 0;
    flex: 1;
  }

  .c3_wp_hello_list_wrap_mob {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-end;
    background-color: rgba(55, 55, 55, 0.82);
    z-index: 99999;
    border-radius: 10px;
  }

  .c3_wp_hello_list_wrap {
    position: relative;
    left: unset;
    right: unset;
    top: unset;
    bottom: unset;
    width: 100%;
    border-radius: 5px 5px 0 0;
    border: none;
    padding: 40px 20px 15vh 20px;
  }

  .c3_wp_hello_list_wrap_close {
    display: block;
    width: 20px;
    height: 20px;
    background-image: url(../img/c3lh-item-big-close-blue.svg);
    position: absolute;
    right: 10px;
    top: 10px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    cursor: pointer;
  }

  .c3_wp_hello_list_to_chat_btn {
    max-width: 100%;
    width: 220px;
    height: 40px;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px auto 0;
    background: $buttonBackgroundColor;
    color: #fff;
    border-radius: 5px;
    cursor: pointer;
    font-weight: 600;
  }

  .c3_wp_hello_list {
    max-height: 190px;
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;
    padding-right: 0;
  }

  .c3_wp_hello_list_title {
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;
    font-size: 18px;
  }

  .popup_likeher_search_photos_big_photo_hello_btn_wrap .c3_wp_hello_list_wrap {
    max-width: calc(100vw - 40px);
    width: 300px;
    position: absolute;
    padding: 10px 10px 0 10px;
  }

  .popup_likeher_search_photos_big_photo_hello_btn_wrap .c3_wp_hello_list {
    max-width: unset;
    padding-right: 5px;
  }
}

@media screen and (max-width: 329px) {
  .c3_woman_profile_main_block_buttons {
    padding-bottom: 7px;
  }
  .c3_woman_profile_main_block_btn {
    width: 138px;
  }

  .ReactModal__Content.ReactModal__Content--after-open .c3_woman_profile_main_block_btn {
    width: 129px;
    font-size: 12px;
  }

  .c3_woman_profile_gallery_photo_top_big_list_wrap {
    height: 320px;
  }

  .c3_woman_profile_gallery_photo_top_small_list_wrap {
    height: 320px;
  }
}

@media screen and (max-width: 899px) and (max-height: 450px) and (min-width: 420px) {
  .c3_woman_profile_head {
    display: none;
  }

  .c3_woman_profile_head.c3_woman_profile_head_mob {
    display: flex;
    justify-content: space-evenly;
    position: absolute;
    right: 13px;
    top: 15px;
    width: calc(100% - 197px);
  }

  .c3_woman_profile_page_wrap {
    padding: 0;
    padding-right: 5px;
  }

  .c3_woman_profile_page_wrap2 {
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    padding: 5px;
  }

  .c3_woman_profile_main_block {
    position: relative;
    display: flex;
  }

  .c3_woman_profile_tabs_link {
    width: 50%;
  }

  .c3_woman_profile_main_block_list_flex_wrap {
    display: block;
  }

  .display_online_on_profile_page {
    position: absolute;
    left: 20px;
    top: 275px;
    font-size: 12px;
  }

  .c3_woman_profile_main_block_list_title {
    font-size: 15px;
  }

  .c3_woman_profile_main_block_list_item {
    line-height: 1.3;
  }

  .c3_woman_profile_head_item {
    margin-right: 0;
    margin-bottom: 16px;
    width: 50px;
    height: 44px;
    box-shadow: none;
    border: 1px solid $primaryColor;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
  }

  .c3wphi_letter .c3_woman_profile_head_item_img {
    background-image: url(../img/c3wpt_mail-m.svg);
  }

  .c3wphi_chat .c3_woman_profile_head_item_img {
    background-image: url(../img/c3wpt_chat-m.svg);
  }

  .c3wphi_gift .c3_woman_profile_head_item_img {
    background-image: url(../img/c3wpt_gift-m.svg);
    background-size: contain;
  }

  .c3wphi_favs .c3_woman_profile_head_item_img {
    background-image: url(../img/c3wpt_favs-m.svg);
  }

  .c3wphi_compl .c3_woman_profile_head_item_img {
    background-image: url(../img/c3wpt_compl-m.svg);
  }

  .c3_woman_profile_tab_about_item_title {
    font-size: 16px;
    margin-bottom: 18px;
  }

  .c3_woman_profile_tab_about_item_text {
    font-size: 13px;
  }

  .c3_woman_profile_second_block {
    padding: 20px;
  }

  .c3_woman_profile_head_item_text {
    display: none;
  }

  .c3_woman_profile_head_item_img {
    margin-right: 0;
  }

  .c3_woman_profile_main_block {
    flex-wrap: wrap;
  }

  .c3_woman_profile_main_block_top {
    width: 100%;
  }

  .ReactModal__Content.ReactModal__Content--after-open .c3_woman_profile_gallery_photo_top_big_item img {
    height: 100%;
  }

  .c3_woman_profile_mobile_buttons {
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (max-width: 699px) and (max-height: 450px) and (min-width: 420px) {
  .c3_woman_profile_main_block {
    display: flex;
  }

  .c3_woman_profile_main_block_buttons {
    width: max-content;
    justify-content: space-between;
    display: none;
  }

  .c3_woman_profile_main_block_btn {
    margin-left: 0;
  }

  .c3_woman_profile_main_block_btn:last-child {
    margin-left: 16px;
  }

  .c3_woman_profile_tab_about_item_title {
    font-size: 14px;
    margin-bottom: 16px;
  }

  .c3_woman_profile_tab_about_item_text {
    font-size: 14px;
  }

  .c3_woman_profile_second_block {
    padding: 16px;
  }
}

@media screen and (max-width: 599px) and (max-height: 450px) and (min-width: 420px) {
  .c3_woman_profile_page_wrap {
    padding-right: 0;
  }

  .c3_woman_profile_head.c3_woman_profile_head_mob {
    right: 0px;
    justify-content: space-between;
  }

  .c3_woman_profile_main_block {
    background-color: $bleachColor;
    box-shadow: none;
  }

  .c3_woman_profile_second_block {
    background-color: $bleachColor;
    box-shadow: none;
  }

  .c3_woman_profile_main_block_info > .c3_woman_profile_main_block_list {
    width: calc(100% + 185px);
  }

  .c3_woman_profile_head_item {
    margin: 0;
  }
}

.c3_woman_profile_custom {
  @media screen and (max-width: 767px) {
    padding: 16px 16px 60px 16px;
  }
}

.c3_woman_profile_custom .c3_woman_profile_second_block_row {
  @media screen and (max-width: 767px) {
    margin-bottom: 40px;
  }

  @media screen and (max-width: 599px) {
    margin-bottom: 0;
  }
}