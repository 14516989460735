// immutable
$blackColor: #000000;
$whiteColor: #FFFFFF;
$onlineColor: #27AE60;
$springSunsetFlowerColor: #fadddd; // error, letter status unasnwered
$bookmarkIconColor: #feb930; // bookmark
$freeContentColor: #CFF5DD; //= free content, letter status green (answered epta)
$fadeColor: rgba(55,78,108,0.32);
$overlayColor: rgba(235, 237, 250, 0.5);
$transparentColor: rgba(255,255,255,0);
$whiteColorAlpha2: rgba(255, 255, 255, 0.2);
$whiteColorAlpha5: rgba(255, 255, 255, 0.5);
$whiteColorAlpha8: rgba(255, 255, 255, 0.8);
$submenuColor: #8694AB; // submenu and text
$mainFirstGradientAlpha6: rgba(0,49,123,.63);
$mainFirstGradientAlpha0: rgba(0,49,123,0);
// GREY
$stormGreyColor: #5c5c5c; //= grey
$arsenicColor: #828282; // grey vodu
$oldGrayColor: #999999; //+ grey
$olderGrayColor: #b2b2b2; // grey
$silverColor: #c0c0c0; //+ grey
$liteGreyColor: #cdd3da; //= grey
$familySizeCondensedSoupColor: #e0e0e0; // grey
$trueGreyColor: #eeeeee; //=
$littleGreyLittleBlueColor: #f7f8fd; //= lite blue lite grey

// TEXT
$defaultTextColor: #333333;
$defaultTextColorAlpha1: rgba(51, 51, 51, 0.1);
$defaultTextColorAlpha2: rgba(51, 51, 51, 0.2);
$defaultTextColorAlpha3: rgba(51, 51, 51, 0.3);
$defaultTextColorAlpha4: rgba(51, 51, 51, 0.4);
$defaultTextColorAlpha5: rgba(51, 51, 51, 0.5);
$defaultTextColorAlpha6: rgba(51, 51, 51, 0.6);
$defaultTextColorAlpha7: rgba(51, 51, 51, 0.7);
$defaultTextColorAlpha8: rgba(51, 51, 51, 0.8);
$defaultTextColorAlpha9: rgba(51, 51, 51, 0.9);

// BORDERS
$greyColorBorderAlpha4: rgba(0,0,0,.4);
$greyColorBorderAlpha2: rgba(0,0,0,.2);

// TEMPLATES
$michiganSkyColor: #798F8C; // templates
$unknownSenSmBLPaAColor: #BE527F; //templ
$unknownpJRWRjVWGFColor: #C6E2E2; // temple
$theRetroOrangeColor: #E57825; // temple
$unknownlWOqwKKMlVColor: #e9f0f1; // temple
$yourPaleColor: #FFEEE4; // temple
$pikachuHashColor: #79A8AD;
$unknownaLHRtdBfNfColor: #26574f;
$greenSwampTemplateColor: rgba(49, 76, 71, 0.8);
$darkerIndigoTemplateColor: rgb(55, 78, 108);
