@import "@sitestyles";

.notify_girls_title_mob_dot {
  display: block;
  width: 11px;
  height: 11px;
  border-radius: 100%;
  background-color: #EB5757;
  margin-left: 10px;

  @media screen and (min-width: 1139px) {
    display: none;
  }
}

.notify_title {
  font-weight: 600;
  font-size: 16px;
  text-align: center;
  text-transform: uppercase;
  color: $profileButtonActiveColor;
  cursor: pointer;

  @media screen and (max-width: 1499px) {
    font-size: 15px;
  }

  @media screen and (max-width: 1139px) {
    font-size: 14px;
    height: 55px;
    display: flex;
    align-items: center;
  }
}




