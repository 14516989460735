@import "@sitestyles";

.wrapper {
  max-width: 600px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
}

.step {
  color: #757575;
  font-family: $mainFont;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0 0 16px;

  @media (max-width: 600px) {
    font-size: 12px;
  }
}

.title {
  color: #333;
  font-family: $mainFont;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  margin: 0 0 8px;

  @media (max-width: 600px) {
    font-size: 18px;
    text-align: left;
  }
}

.subtitle {
  color: #757575;
  font-family: $mainFont;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  margin: 0 0 48px;

  @media (max-width: 600px) {
    font-size: 14px;
    margin: 0 0 24px;
  }
}

.text {
  color: #333;
  font-family: $mainFont;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  margin: 0 0 72px;

  @media (max-width: 600px) {
    font-size: 14px;
  }
}

.button_row {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 72px;

  @media (max-width: 600px) {
    flex-direction: column;
    margin-top: 96px;
  }

  @media (max-width: 360px) {
    margin-top: 48px;
  }
}

.button {
  height: 48px;
  width: 100%;
  max-width: 200px;
  box-sizing: border-box;
  padding: 12px 16px;
  border-radius: 8px;
  background: #212125;
  color: #FFF;
  text-align: center;
  font-family: $mainFont;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  cursor: pointer;
  transition: all .3s ease;

  &:hover {
    background: #363644;
    transition: all .3s ease;
  }

  &.skip {
    border: 1px solid #BFBFBF;
    color: #BFBFBF;
    background: transparent;

    &:hover {
      border: 1px solid #363644;
      color: #363644;
      background: transparent;
      transition: all .3s ease;
    }
  }

  @media (max-width: 600px) {
    max-width: 100%;
    margin: 0 auto;

    &:last-child {
      margin-top: 16px;
    }
  }
}

.disabled {
  background: #9A9A9A;
  transition: all .3s ease;
  cursor: not-allowed;

  &:hover {
    background: #9A9A9A;
    transition: all .3s ease;
  }
}

.list {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 24px;
  width: 100%;
  flex-direction: column;
}