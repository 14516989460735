@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&display=swap');
@import "@sitestyles";

.new_main_container {
  width: 100%;
  padding: 0 8%;

  @media screen and (max-width: 1400px) {
    padding: 0 32px;
  }
  @media screen and (max-width: 536px) {
    padding: 0 16px;
  }
  @media screen and (max-width: 370px) {
    padding: 0 8px;
  }
}

.main_page_wrap {
  background-color: $MainHeaderBg;
}

.main_title_h2 {
  font-weight: 600;
  font-size: 48px;
  line-height: 65px;
  text-align: center;
  color: $attachTouchText;
  @media screen and (max-width: 1140px) {
    font-size: 32px;
    line-height: 38px;
  }
  @media screen and (max-width: 536px) {
    font-size: 18px;
    line-height: 25px;
  }
}

.main_title_h3 {
  font-size: 18px;
  line-height: 150%;
  color: $greyColor;
  text-align: center;
  margin: 16px auto 40px;
  width: 798px;
  @media screen and (max-width: 1140px) {
    width: 80%;
  }

  @media screen and (max-width: 600px) {
    font-size: 14px;
    margin-bottom: 24px;
    width: 100%;
  }
}

.popup_main_login {
  width: 520px;
  max-width: 100%;
  background-color: #fff;
  border-radius: 8px;
  position: relative;
}

.popup_main_login_top {
  padding: 60px 60px 35px;
}

.popup_main_login_btn {
  display: block;
  width: 148px;
  height: 42px;
  line-height: 42px;
  background: linear-gradient(90.44deg, $accentLeftColor 0%, $accentRightColor 100%);
  border-radius: 4px;
  font-weight: 600;
  font-size: 18px;
  text-align: center;
  letter-spacing: .01em;
  color: #fff;
  margin: 32px auto 9px;
  cursor: pointer;
}

.popup_main_login_btn_forget {
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  color: #eb5757;
  cursor: pointer;
}

.popup_main_login_bottom {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  border-top: 1px solid rgba(0, 0, 0, .2);
  height: 77px;
}

.popup_main_login_close {
  position: absolute;
  top: 5px;
  right: 5px;
  padding: 5px;
  cursor: pointer;
}


.main_view_more_btn {
  width: 230px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $whiteColor;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  border-radius: 5px;
  position: relative;
  margin: 0 auto;
  cursor: pointer;
  font-family: $mainFont;
  background-clip: padding-box, border-box;
  background-origin: border-box;
  border: double 1px transparent;
  background-image: linear-gradient(white, white), linear-gradient(to right, $accentLeftColor, $accentRightColor);

  @media screen and (max-width: 600px) {
    width: 200px;
    font-size: 16px;
    line-height: 22px;
  }

  &:hover {
    background-image: $buttonBackgroundColor, linear-gradient(to right, $accentLeftColor, $accentRightColor);

    .main_view_more_btn_text {
      background: #FFFFFF;
      -webkit-background-clip: text;
      -webkit-text-fill-color: #FFFFFF;
      background-clip: text;
      text-fill-color: #FFFFFF;
      position: relative;
      z-index: 1;
    }
  }
}

.main_view_more_btn_text {
  background: $buttonBackgroundColor;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  font-weight: 700;
}


@media screen and (max-width: 1199px) {
  .main_page_wrap {
    height: calc(var(--vh, 1vh) * 100);
    overflow-y: auto;
  }

  .main_page_wrap::-webkit-scrollbar {
    width: 7px;
    background: #F7F8FD;
    border-radius: 20px;
  }

  .main_page_wrap::-webkit-scrollbar-thumb {
    background: #798F8C;
    border-radius: 20px;
  }
}