@import "@sitestyles";

.mob_bottom_nav {
  display: none;
  height: 63px;
  justify-content: center;
  align-items: center;
  border-top: 1px solid $trueGreyColor;
  background-color: $whiteColor;
  position: relative;
  z-index: 99;
  list-style: none;

  @media screen and (max-width: 1139px) {
    display: flex;
    padding: 0 140px;
  }

  @media screen and (min-width: 1140px) {
    display: flex;
    justify-content: center;
  }

  @media screen and (max-width: 899px) {
    justify-content: space-around;
  }

  @media screen and (max-width: 767px) {
    padding: 0;
    height: 50px;
  }

  @media screen and (max-width: 899px) and (max-height: 500px) and (min-width: 420px) {
    position: absolute;
    right: 0;
    top: 0;
    width: 45px;
    height: 100%;
    flex-direction: column;
    border-top: none;
    border-left: 1px solid $trueGreyColor;
    padding: 0;
  }
}

.mob_bottom_nav_button {
  width: 50px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 4px;
  height: 100%;
  align-items: center;
  cursor: pointer;
  position: relative;
  font-size: 12px;
  font-family: inherit;
  color: $defaultTextColorAlpha6;
  border: none;
  background: transparent;
  transition: .3s;

  @media (hover: none) and (pointer: coarse) and (min-width: 1140px) {
    margin: 0 30px;
  }

  @media screen and (max-width: 767px) {
    font-size: 10px;
  }

  @media screen and (max-width: 899px) {
    margin: 0;
  }

  @media screen and (max-width: 899px) and (max-height: 500px) and (min-width: 420px) {
    font-size: 10px;
  }

  svg {
    width: 24px;
    height: 24px;
    margin-bottom: 6px;
    fill: $defaultTextColorAlpha6;


    @media screen and (max-width: 767px) {
      width: 21px;
      height: 21px;
      margin-bottom: 2px;
    }

    @media screen and (max-width: 899px) and (max-height: 500px) and (min-width: 420px) {
      width: 21px;
      height: 21px;
      margin-bottom: 2px;
    }
  }

  &:hover,
  &:focus {
    color: $smallActiveButtonColor;

    svg{
      fill: $smallActiveButtonColor;
    }
  }
}

.mob_bottom_nav_item {
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 899px) and (max-height: 500px) and (min-width: 420px) {
    height: fit-content;
  }
}

.mob_bottom_nav_item_notify {
  background: #EB5757;
  box-shadow: 0 1px 2px rgba(157, 0, 0, 0.16);
  border-radius: 4px;
  font-weight: 600;
  font-size: 10px;
  line-height: 10px;
  letter-spacing: -0.02em;
  color: #FFFFFF;
  padding: 0 4px 4px;
  min-width: 19px;
  height: 17px;
  min-height: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 26px;
  top: 2px;
  z-index: 2;

  @media screen and (max-width: 899px) and (max-height: 500px) and (min-width: 420px) {
    top: -9px;
  }
}


.mob_bottom_nav_button.active {
  color: $smallActiveButtonColor;
  font-weight: 600;

  svg {
    fill: $smallActiveButtonColor;
  }
}


