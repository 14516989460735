@import "@sitestyles";

/*!*HISTORY-MAIN*!*/


/**{*/
/*	margin: 0;
*/
/*	padding: 0;
*/
/*	-webkit-box-sizing: border-box;
*/
/*	-moz-box-sizing: border-box;
*/
/*	box-sizing: border-box;
*/
/*}*/
   
/*body{*/
/*	font-family: 'Montserrat', sans-serif;
*/
/*	!*font-family: 'Roboto', sans-serif;
*!*/
/*	font-size: 14px;
*/
/*	background: $bleachColor;
*/
/*	overflow-x: hidden;
*/
/*}*/

/*button,*/
/*input{*/
/*	font-family: 'Montserrat', sans-serif;
*/
/*	border: none;
*/
/*	background-color: transparent;
*/
/*}*/

/*a{*/
/*	color: inherit;
*/
/*	text-decoration: none;
*/
/*}*/

/*.overlay{*/
/*	display: none;
*/
/*	width: 100%;
*/
/*	height: 100vh;
*/
/*	height: calc(var(--vh, 1vh) * 100);
*/
/*	background-color: rgba(235,237,250,.5);
*/
/*	z-index: 90;
*/
/*	position: fixed;
*/
/*	left: 0;
*/
/*	filter: blur(4px);
*/
/*	top: 0;
*/
/*}*/

/*.overlay.dark{*/
/*	background-color: rgba(0,0,0,.5);
*/
/*}*/

/*.overlay.active{*/
/*	display: block;
*/
/*}*/

/*.blur{*/
/*	filter: blur(2px);
*/
/*}*/

/*.chat_wrap{*/
/*	display: -webkit-flex;
*/
/*	display: -moz-flex;
*/
/*	display: -ms-flex;
*/
/*	display: -o-flex;
*/
/*	display: flex;
*/
/*	height: 100vh;
*/
/*}*/

/*.column-1{*/
/*	width: 204px;
*/
/*	position: relative;
*/
/*	transition: .5s;
*/
/*	box-shadow: 6px 0px 12px rgba(47, 43, 99, 0.16);
*/
/*	position: fixed;
*/
/*	top: 0;
*/
/*	left: 0;
*/
/*	z-index: 55;
*/
/*}*/

/*.column-1.small{*/
/*	width: 130px;
*/
/*}*/

/*.column-2{*/
/*	width: 340px;
*/
/*	background-color: $background1Color;
*/
/*}*/

/*.column-3{*/
/*	flex: 1;
*/
/*	position: relative;
*/
/*	margin-left: 200px !important;
*/
/*	margin-right: 340px !important;
*/
/*	!*padding-top: 110px;
*!*/
/*}*/
/*.column-3 .pagesOpen {*/
/*	display: none;
*/
/*}*/
/*.column-3 .head-links{*/
/*	display: flex;
*/
/*	align-content: center;
*/
/*	justify-content: space-between;
*/
/*	position: fixed;
*/
/*	flex-wrap: wrap;
*/
/*	margin-bottom: 30px;
*/
/*	padding-top: 12px;
*/
/*	z-index: 50;
*/
/*	width: 100%;
*/
/*	top: 0;
*/
/*	background: $bleachColor;
*/
/*	padding-bottom: 10px;
*/
/*	padding-right: 120px;
*/
/*	left: 0;
*/
/*	right: 0;
*/
/*	margin: auto;
*/
/*	max-width: 1440px;
*/
/*}*/
/*.column-3 .head-links a{*/
/*	max-width: 170px;
*/
/*	width: 100%;
*/
/*	display: flex;
*/
/*	align-items: center;
*/
/*	background: $whiteColor;
*/
/*	box-shadow: 0px 6px 12px rgba(47, 43, 99, 0.16);
*/
/*	border-radius: 10px;
*/
/*	min-height: 76px;
*/
/*	padding-left: 20px;
*/
/*	margin-bottom: 10px;
*/
/*	margin-right: 2px;
*/
/*}*/
/*.column-3 .head-links img{*/
/*	margin-right: 10px;
*/
/*}*/
/*.column-3 .head-links h5{*/
/*	background: linear-gradient(90.23deg, $accentLeftColor 0%, $accentRightColor 100%);
*/
/*  -webkit-background-clip: text;
*/
/*  -webkit-text-fill-color: transparent;
*/
/*	font-weight: 600;
*/
/*	font-size: 18px;
*/
/*	line-height: 140%;
*/
/*}*/
/*.column-3 .head-links .active{*/
/*	background: $background5Color;
*/
/*}*/
/*.column-3 .head-links .active h5{*/
/*	color: $accentLeftColor;
*/
/*	background: linear-gradient(90.23deg, $accentLeftColor 0%, $accentLeftColor 100%);
*/
/*	-webkit-background-clip: text;
*/
/*  -webkit-text-fill-color: transparent;
*/
/*}*/
/*.column-3.empty{*/
/*	background-color: $unknownyYnGaQFijfColor;
*/
/*}*/
/*.column-3 .all-blocks{*/
/*	padding-top: 33px;
*/
/*	margin: 0 16px;
*/
/*}*/
/*.block{*/
/*	display: none;
*/
/*}*/
/*.column-3 .first-link-show .first-link{*/
/*	display: block;
*/
/*}*/
/*.column-3 .third-link-show .third-link{*/
/*	display: block;
*/
/*}*/
/*.column-4{*/
/*	width: 340px;
*/
/*	background-color: $background1Color;
*/
/*	position: fixed;
*/
/*	top: 0;
*/
/*	right: 0;
*/
/*	z-index: 55;
*/
/*}*/

/*.c1_top{*/
/*	background-color: $background1Color;
*/
/*	padding-bottom: 4px;
*/
/*}*/

/*.c1_logo{*/
/*	display: -webkit-flex;
*/
/*	display: -moz-flex;
*/
/*	display: -ms-flex;
*/
/*	display: -o-flex;
*/
/*	display: flex;
*/
/*	justify-content: center;
*/
/*	-ms-align-items: center;
*/
/*	align-items: center;
*/
/*	height: 80px;
*/
/*}*/

/*.c1_logo img:nth-child(2){*/
/*	width: 60px;
*/
/*	display: block;
*/
/*	margin-left: 6px;
*/
/*	transition: .5s;
*/
/*}*/

/*.column-1.small .c1_logo img:nth-child(2){*/
/*	width: 0px;
*/
/*}*/

/*.c1_profile_wrap{*/
/*	background-color: $whiteColor;
*/
/*	padding: 16px 12px 12px;
*/
/*	display: -webkit-flex;
*/
/*	display: -moz-flex;
*/
/*	display: -ms-flex;
*/
/*	display: -o-flex;
*/
/*	display: flex;
*/
/*	-ms-align-items: center;
*/
/*	align-items: center;
*/
/*	box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.05);
*/
/*	border-radius: 5px;
*/
/*}*/

/*.c1_profile_photo{*/
/*	margin-right: 12px;
*/
/*}*/

/*.c1_profile_photo img{*/
/*	width: 56px;
*/
/*	height: 56px;
*/
/*	object-fit: cover;
*/
/*	border-radius: 100%;
*/
/*}*/

/*.c1_profile_right a{*/
/*	display: block;
*/
/*	width: 112px;
*/
/*	height: 24px;
*/
/*	border-width: 1px;
*/
/*	border-style: solid;
*/
/*	border-radius: 4px;
*/
/*	padding-top: 3px;
*/
/*	font-weight: 500;
*/
/*	line-height: 17px;
*/
/*	transition: .5s;
*/
/*	overflow: hidden;
*/
/*}*/

/*.column-1.small .c1_profile_right a{*/
/*	width: 30px;
*/
/*}*/

/*.c1_profile_right a span{*/
/*	width: 80px;
*/
/*	transition: .5s;
*/
/*	display: block;
*/
/*	white-space: nowrap;
*/
/*}*/

/*.column-1.small .c1_profile_right a span{*/
/*	width: 0px;
*/
/*	overflow: hidden;
*/
/*}*/

/*.c1_profile_link{*/
/*	display: block;
*/
/*	padding-left: 30px;
*/
/*	color: $primaryColor;
*/
/*	border-color: $primaryColor;
*/
/*	background-image: url(/img/profile_menu/profile_ico.svg);
*/
/*	background-repeat: no-repeat;
*/
/*	background-position: 10px 4px;
*/
/*	margin-bottom: 8px;
*/
/*}*/

/*.c1_add_credits{*/
/*	display: block;
*/
/*	padding-left: 27px;
*/
/*	color: $whiteColor;
*/
/*	background-color: $counterAndErrorColor;
*/
/*	border-color: $counterAndErrorColor;
*/
/*	background-image: url(/img/profile_menu/get_credit_ico.svg);
*/
/*	background-repeat: no-repeat;
*/
/*	background-position: 6px 3px;
*/
/*}*/

/*.c1_wrap2{*/
/*	padding: 4px 4px 4px 0;
*/
/*	height: calc(100vh - 172px);
*/
/*}*/

/*.c1_wrap{*/
/*	height: 100%;
*/
/*	overflow-y: auto;
*/
/*	padding-bottom: 100px;
*/
/*	background-color: $whiteColor;
*/
/*}*/

/*.c1_wrap::-webkit-scrollbar{*/
/*	width: 3px;
*/
/*	background: $littleGreyLittleBlueColor;
*/
/*	border-radius: 5px;
*/
/*	margin-right: 3px;
*/
/*}*/

/*.c1_wrap::-webkit-scrollbar-thumb {*/
/*  background: $scrollbarThumbColor;
*/
/*  border-radius: 7px;
*/
/*}*/

/*.c1_menu{*/
/*	margin-top: -4px;
*/
/*}*/

/*.c1_menu_item{*/
/*	color: $primaryColor;
*/
/*	font-size: 20px;
*/
/*	font-weight: 500;
*/
/*	display: -webkit-flex;
*/
/*	display: -moz-flex;
*/
/*	display: -ms-flex;
*/
/*	display: -o-flex;
*/
/*	display: flex;
*/
/*	-ms-align-items: center;
*/
/*	align-items: center;
*/
/*	height: 56px;
*/
/*	border-left: 4px solid transparent;
*/
/*	padding-left: 10px;
*/
/*	padding-right: 12px;
*/
/*	cursor: pointer;
*/
/*}*/

/*.c1_menu_item.active{*/
/*	color: $accentLeftColor;
*/
/*}*/

/*.c1_menu_item span{*/
/*	max-width: 150px;
*/
/*	transition: .5s;
*/
/*	white-space: nowrap;
*/
/*}*/

/*.column-1.small .c1_menu_item span{*/
/*	max-width: 0px;
*/
/*	overflow: hidden;
*/
/*	visibility: hidden;
*/
/*}*/

/*.column-1.small .c1_menu_item{*/
/*	justify-content: center;
*/
/*	padding-left: 16px;
*/
/*}*/

/*.c1_menu_item_left{*/
/*	display: -webkit-flex;
*/
/*	display: -moz-flex;
*/
/*	display: -ms-flex;
*/
/*	display: -o-flex;
*/
/*	display: flex;
*/
/*	-ms-align-items: center;
*/
/*	align-items: center;
*/
/*}*/

/*.c1_menu_item svg{*/
/*	margin-right: 11px;
*/
/*	transition: .5s;
*/
/*	fill: $menuIconColor;
*/
/*}*/

/*.c1_menu_item.active svg{*/
/*	fill: $accentLeftColor;
*/
/*}*/

/*.c1_menu_more_btn.active svg{*/
/*	transform: rotate(180deg);
*/
/*}*/

/*.c1_submenu_item{*/
/*	height: 60px;
*/
/*	padding: 10px 12px 8px 18px;
*/
/*	display: -webkit-flex;
*/
/*	display: -moz-flex;
*/
/*	display: -ms-flex;
*/
/*	display: -o-flex;
*/
/*	display: flex;
*/
/*	justify-content: space-between;
*/
/*	-ms-align-items: center;
*/
/*	align-items: center;
*/
/*	transition: .5s;
*/
/*	cursor: pointer;
*/
/*}*/

/*.c1_submenu_item.active{*/
/*	background-color: $primaryColor;
*/
/*	color: $whiteColor;
*/
/*}*/

/*.c1_submenu_item_left{*/
/*	color: $defaultTextColorAlpha6;
*/
/*	font-size: 16px;
*/
/*	font-weight: 600;
*/
/*}*/

/*.column-1.small .c1_submenu_item_left{*/
/*	padding-left: 10px;
*/
/*}*/

/*.c1_submenu_item_left span{*/
/*	max-width: 100px;
*/
/*	max-height: 20px;
*/
/*	display: block;
*/
/*	transition: .5s;
*/
/*	white-space: nowrap;
*/
/*}*/

/*.column-1.small .c1_submenu_item_left span{*/
/*	max-width: 0px;
*/
/*	max-height: 0px;
*/
/*	overflow: hidden;
*/
/*}*/

/*.c1_submenu_item.active .c1_submenu_item_left{*/
/*	color: $whiteColor;
*/
/*}*/

/*.c1_submenu_item_left svg{*/
/*	display: block;
*/
/*	margin-bottom: 9px;
*/
/*	position: relative;
*/
/*	top: 3px;
*/
/*	fill: $menuIconColor;
*/
/*	stroke: $menuIconColor;
*/
/*	opacity: .32;
*/
/*}*/

/*.c1_submenu_item.active .c1_submenu_item_left svg{*/
/*	fill: $whiteColor;
*/
/*	stroke: $whiteColor;
*/
/*	opacity: 1;
*/
/*}*/

/*.c1_submenu_item_right{*/
/*	font-size: 12px;
*/
/*	font-weight: bold;
*/
/*	color: $submenuColor;
*/
/*	line-height: 1;
*/
/*	letter-spacing: -0.02em;
*/
/*	text-align: center;
*/
/*	width: 50px;
*/
/*	height: 39px;
*/
/*	background-color: $background1Color;
*/
/*	border-radius: 4px;
*/
/*	padding: 1px 4px 0;
*/
/*}*/

/*.c1_submenu_item_right_top{*/
/*	border-bottom: 1px solid $whiteColor;
*/
/*	padding-bottom: 3px;
*/
/*	margin-bottom: 1px;
*/
/*}*/

/*.c1_submenu_item_right_top span:before{*/
/*	content: '';
*/
/*	display: inline-block;
*/
/*	width: 4px;
*/
/*	height: 4px;
*/
/*	border-radius: 100%;
*/
/*	background-color: $onlineColor;
*/
/*	vertical-align: middle;
*/
/*	margin-right: 4px;
*/
/*}*/

/*.c1_menu_item_count{*/
/*	text-align: center;
*/
/*	width: 50px;
*/
/*	height: 20px;
*/
/*	background-color: $background1Color;
*/
/*	border-radius: 4px;
*/
/*	font-size: 12px;
*/
/*	color: $defaultTextColorAlpha6;
*/
/*	letter-spacing: -0.02em;
*/
/*	line-height: 12px;
*/
/*	font-weight: bold;
*/
/*	margin-left: auto;
*/
/*}*/

/*.c1_menu_more_btn{*/
/*	justify-content: center;
*/
/*	color: $accentRightColor;
*/
/*	cursor: pointer;
*/
/*}*/

/*.c1_menu_more_btn span{*/
/*	width: 52px;
*/
/*}*/

/*.c1_menu_item.c1_menu_more_btn.active{*/
/*	color: $primaryColor;
*/
/*}*/

/*.c1_menu_more_btn img{*/
/*	transition: .3s;
*/
/*}*/

/*.c1_menu_more_btn.active img{*/
/*	transform: rotate(180deg);
*/
/*}*/

/*.c1_menu_more{*/
/*	display: none;
*/
/*}*/

/*.c1_menu_hide_wrap{*/
/*	width: calc(100% - 8px);
*/
/*	height: 100px;
*/
/*	position: absolute;
*/
/*	left: 0;
*/
/*	bottom: 0;
*/
/*	display: -webkit-flex;
*/
/*	display: -moz-flex;
*/
/*	display: -ms-flex;
*/
/*	display: -o-flex;
*/
/*	display: flex;
*/
/*	justify-content: center;
*/
/*	-ms-align-items: center;
*/
/*	align-items: center;
*/
/*	background-color: $whiteColor;
*/
/*}*/

/*.c1_menu_hide{*/
/*	display: -webkit-flex;
*/
/*	display: -moz-flex;
*/
/*	display: -ms-flex;
*/
/*	display: -o-flex;
*/
/*	display: flex;
*/
/*	-ms-align-items: center;
*/
/*	align-items: center;
*/
/*	justify-content: center;
*/
/*	border: 2px solid $accentRightColor;
*/
/*	border-radius: 37px;
*/
/*	width: 44px;
*/
/*	height: 37px;
*/
/*}*/

/*.c1_menu_hide img{*/
/*	transition: .5s;
*/
/*}*/

/*.c1_menu_hide.small img{*/
/*	transform: rotateY(180deg);
*/
/*}*/

/*.c2_top{*/
/*	display: -webkit-flex;
*/
/*	display: -moz-flex;
*/
/*	display: -ms-flex;
*/
/*	display: -o-flex;
*/
/*	display: flex;
*/
/*	-ms-align-items: center;
*/
/*	align-items: center;
*/
/*	justify-content: space-between;
*/
/*	padding: 25px 16px;
*/
/*}*/

/*.c2_top_online_text{*/
/*	font-weight: 500;
*/
/*	font-size: 16px;
*/
/*	line-height: 20px;
*/
/*	letter-spacing: -0.02em;
*/
/*	text-transform: uppercase;
*/
/*	color: $primaryColor;
*/
/*}*/

/*.c2_top_tumbler input{*/
/*	display: none;
*/
/*}*/

/*.c2_top_tumbler label{*/
/*	display: block;
*/
/*	position: relative;
*/
/*	width: 36px;
*/
/*	height: 18px;
*/
/*	background-color: $liteGreyColor;
*/
/*	border-radius: 19px;
*/
/*}*/

/*.c2_top_tumbler label:before{*/
/*	content: '';
*/
/*	display: block;
*/
/*	width: 14px;
*/
/*	height: 14px;
*/
/*	border-radius: 100%;
*/
/*	background-color: $whiteColor;
*/
/*	position: absolute;
*/
/*	left: 2px;
*/
/*	top: 2px;
*/
/*	transition: left .3s;
*/
/*}*/

/*.c2_top_tumbler input:checked + label{*/
/*	background-color: $whiteColor;
*/
/*}*/

/*.c2_top_tumbler input:checked + label:before{*/
/*	left: 20px;
*/
/*	background-color: $onlineColor;
*/
/*}*/

/*.c2_top_search{*/
/*	position: relative;
*/
/*}*/

/*.c2_top_search input{*/
/*	width: 174px;
*/
/*	height: 30px;
*/
/*	background-color: $whiteColor;
*/
/*	border-radius: 5px;
*/
/*	font-weight: 500;
*/
/*	font-size: 12px;
*/
/*	line-height: 100%;
*/
/*	letter-spacing: -0.02em;
*/
/*	padding-left: 12px;
*/
/*	padding-right: 30px;
*/
/*	color: $primaryColor;
*/
/*}*/

/*.c2_top_search input::placeholder{*/
/*	font-weight: 500;
*/
/*	color: $defaultTextColorAlpha3;
*/
/*}*/

/*.c2_top_search button{*/
/*	height: 100%;
*/
/*	width: 30px;
*/
/*	position: absolute;
*/
/*	right: 0;
*/
/*	top: 0;
*/
/*	line-height: 30px;
*/
/*	cursor: pointer;
*/
/*}*/

/*.c2_top_search button img{*/
/*	vertical-align: middle;
*/
/*	margin-top: -2px;
*/
/*}*/

/*.c2_list_wrap_2{*/
/*	padding-right: 4px;
*/
/*}*/

/*.c2_list_wrap{*/
/*	height: calc(100vh - 80px);
*/
/*	overflow-y: auto;
*/
/*}*/

/*.c2_list_wrap::-webkit-scrollbar{*/
/*	width: 3px;
*/
/*	background: $littleGreyLittleBlueColor;
*/
/*	border-radius: 5px;
*/
/*	margin-right: 3px;
*/
/*}*/

/*.c2_list_wrap::-webkit-scrollbar-thumb {*/
/*  background: $scrollbarThumbColor;
*/
/*  border-radius: 7px;
*/
/*}*/

/*.c2_list{*/
/*	padding: 0 4px 0 4px;
*/
/*}*/

/*.c2_item{*/
/*	background-color: $whiteColor;
*/
/*	border-radius: 8px;
*/
/*	padding: 8px 0;
*/
/*	margin-bottom: 8px;
*/
/*	box-shadow: $searchItemLikeBtnShadowHover;
*/
/*}*/

/*.c2_item_top{*/
/*	padding: 0 10px 0 24px;
*/
/*	display: -webkit-flex;
*/
/*	display: -moz-flex;
*/
/*	display: -ms-flex;
*/
/*	display: -o-flex;
*/
/*	display: flex;
*/
/*	justify-content: space-between;
*/
/*	-ms-align-items: center;
*/
/*	align-items: center;
*/
/*}*/

/*.c2_item_photo_wrap{*/
/*	position: relative;
*/
/*	margin-right: 16px;
*/
/*}*/

/*.c2_item_photo{*/
/*	width: 56px;
*/
/*	height: 56px;
*/
/*	border-radius: 100%;
*/
/*	object-fit: cover;
*/
/*	display: block;
*/
/*}*/

/*.c2_item_photo_delete{*/
/*	position: absolute;
*/
/*	left: -12px;
*/
/*	top: -5px;
*/
/*	cursor: pointer;
*/
/*}*/

/*.c2_item_photo_counter{*/
/*	display: block;
*/
/*	width: 28px;
*/
/*	height: 28px;
*/
/*	position: absolute;
*/
/*	left: -14px;
*/
/*	top: -10px;
*/
/*	background-color: $counterAndErrorColor;
*/
/*	border-radius: 4px;
*/
/*	font-weight: 600;
*/
/*	font-size: 16px;
*/
/*	line-height: 30px;
*/
/*	text-align: center;
*/
/*	letter-spacing: -0.02em;
*/
/*	color: $whiteColor;
*/
/*}*/

/*.c2_item_name{*/
/*	font-weight: 600;
*/
/*	font-size: 18px;
*/
/*	line-height: 22px;
*/
/*	color: $stormGreyColor;
*/
/*	margin-bottom: 8px;
*/
/*}*/

/*.c2_item_text{*/
/*	font-size: 13px;
*/
/*	line-height: 150%;
*/
/*	color: $stormGreyColor;
*/
/*	font-family: "Roboto", sans-serif;
*/
/*}*/

/*.c2_item_text.from_you{*/
/*	position: relative;
*/
/*	padding-left: 30px;
*/
/*}*/

/*.c2_item_text.from_you:before{*/
/*	content: 'You:';
*/
/*	position: absolute;
*/
/*	left: 0;
*/
/*}*/

/*.c2_item_bottom{*/
/*	display: -webkit-flex;
*/
/*	display: -moz-flex;
*/
/*	display: -ms-flex;
*/
/*	display: -o-flex;
*/
/*	display: flex;
*/
/*	justify-content: space-between;
*/
/*	-ms-align-items: center;
*/
/*	align-items: center;
*/
/*	padding: 0 9px 0 26px;
*/
/*	margin-top: 4px;
*/
/*	font-family: "Roboto", sans-serif;
*/
/*}*/

/*.c2_item_id{*/
/*	font-size: 10px;
*/
/*	line-height: 12px;
*/
/*	color: $silverColor;
*/
/*}*/

/*.c2_item_time{*/
/*	font-size: 12px;
*/
/*	line-height: 14px;
*/
/*	letter-spacing: -0.03em;
*/
/*	text-transform: uppercase;
*/
/*	color: $silverColor;
*/
/*}*/

/*.c2_item_photo_fav{*/
/*	display: none;
*/
/*	position: absolute;
*/
/*	right: -6px;
*/
/*	top: -4px;
*/
/*}*/

/*.c2_item_photo_fav svg{*/
/*	stroke: white;
*/
/*	fill: $bookmarkIconColor;
*/
/*}*/

/*.c2_item.current .c2_item_photo_fav svg{*/
/*	stroke: $primaryColor;
*/
/*}*/

/*.c2_item_photo_wrap:after{*/
/*	content: '';
*/
/*	display: block;
*/
/*	width: 12px;
*/
/*	height: 12px;
*/
/*	background-color: $familySizeCondensedSoupColor;
*/
/*	border-radius: 100%;
*/
/*	border: 2px solid $whiteColor;
*/
/*	position: absolute;
*/
/*	right: 0;
*/
/*	bottom: 0px;
*/
/*}*/

/*.c2_item.current .c2_item_photo_wrap:after{*/
/*	border-color: $primaryColor;
*/
/*}*/

/*.c2_item.online .c2_item_photo_wrap:after{*/
/*	background-color: $onlineColor;
*/
/*}*/

/*.c2_item.favorites .c2_item_photo_fav{*/
/*	display: block;
*/
/*}*/

/*.c2_item.current{*/
/*	background-color: $primaryColor;
*/
/*	-webkit-box-shadow: none;
*/
/*	box-shadow: none;
*/
/*}*/

/*.c2_item.current .c2_item_name,*/
/*.c2_item.current .c2_item_text{*/
/*	color: $whiteColor;
*/
/*}*/

/*.column-3.empty:after{*/
/*	content: '';
*/
/*	display: block;
*/
/*	width: 100%;
*/
/*	height: 100%;
*/
/*	background-image: url(/img/profile_menu/c3_empty.png);
*/
/*	background-repeat: no-repeat;
*/
/*	background-position: center;
*/
/*	position: absolute;
*/
/*	left: 0;
*/
/*	top: 0;
*/
/*	opacity: .56;
*/
/*}*/

/*.c4_top{*/
/*	height: 80px;
*/
/*	font-weight: 600;
*/
/*	font-size: 16px;
*/
/*	line-height: 80px;
*/
/*	text-align: center;
*/
/*	text-transform: uppercase;
*/
/*	color: $accentRightColor;
*/
/*}*/

/*.c4_top.blur{*/
/*	filter: blur(2px);
*/
/*}*/

/*.c4_list_wrap_2{*/
/*	padding-right: 7px;
*/
/*}*/

/*.c4_list_wrap{*/
/*	height: calc(100vh - 80px);
*/
/*	overflow-y: auto;
*/
/*}*/

/*.c4_list_wrap::-webkit-scrollbar{*/
/*	width: 3px;
*/
/*	background: $littleGreyLittleBlueColor;
*/
/*	border-radius: 5px;
*/
/*	margin-right: 3px;
*/
/*}*/

/*.c4_list_wrap::-webkit-scrollbar-thumb {*/
/*  background: $scrollbarThumbColor;
*/
/*  border-radius: 7px;
*/
/*}*/

/*.c4_list{*/
/*	padding: 0 6px 60px 4px;
*/
/*}*/

/*.c4_item{*/
/*	background-color: $whiteColor;
*/
/*	border-radius: 8px;
*/
/*	box-shadow: $searchItemLikeBtnShadowHover;
*/
/*	display: -webkit-flex;
*/
/*	display: -moz-flex;
*/
/*	display: -ms-flex;
*/
/*	display: -o-flex;
*/
/*	display: flex;
*/
/*	position: relative;
*/
/*	margin-bottom: 8px;
*/
/*	-ms-align-items: flex-start;
*/
/*	align-items: flex-start;
*/
/*}*/

/*.c4_item.clicked{*/
/*	position: relative;
*/
/*	z-index: 3;
*/
/*	border: 8px solid $whiteColor;
*/
/*	border-radius: 0 8px 8px 0;
*/
/*}*/

/*.c4_item.clicked:before{*/
/*	content: '';
*/
/*	display: block;
*/
/*	height: calc(100% + 45px);
*/
/*	width: 19px;
*/
/*	position: absolute;
*/
/*	top: -22px;
*/
/*	left: -8px;
*/
/*	background: url(/img/profile_menu/c4-item-clicked-top.svg) left top no-repeat, url(/img/profile_menu/c4-item-clicked-bottom.svg) left bottom no-repeat;
*/
/*}*/

/*!*.c4_item.clicked:after{*/
/*	content: '';
*/
/*	display: block;
*/
/*	width: 120%;
*/
/*	height: calc(100% + 16px);
*/
/*	background-color: $whiteColor;
*/
/*	position: absolute;
*/
/*	right: -8px;
*/
/*	top: -8px;
*/
/*	z-index: 0;
*/
/*}*!*/

/*.c4_item.liked{*/
/*	padding: 10px 40px 10px 24px;
*/
/*}*/

/*.c4_item.viewed{*/
/*	padding: 10px 40px 10px 24px;
*/
/*}*/

/*.c4_item.message{*/
/*	padding: 19px 40px 15px 24px;
*/
/*	background-color: $primary2Color;
*/
/*}*/

/*.c4_item.message.favorites{*/
/*	background-color: $accentRightColor;
*/
/*}*/

/*.c4_item.blur{*/
/*	filter: blur(2px);
*/
/*}*/

/*.c4_item_photo_wrap{*/
/*	position: relative;
*/
/*	margin-right: 17px;
*/
/*}*/

/*.c4_item .c2_item_photo_fav{*/
/*	display: block;
*/
/*}*/

/*.c4_item .c2_item_photo_fav svg{*/
/*	stroke: $whiteColor;
*/
/*}*/

/*.c4_item.favorites .c2_item_photo_fav svg{*/
/*	stroke: $accentRightColor;
*/
/*}*/

/*.c4_item_photo_wrap:after{*/
/*	content: '';
*/
/*	display: block;
*/
/*	width: 12px;
*/
/*	height: 12px;
*/
/*	background-color: $familySizeCondensedSoupColor;
*/
/*	border-radius: 100%;
*/
/*	border: 2px solid $whiteColor;
*/
/*	position: absolute;
*/
/*	right: 0;
*/
/*	bottom: 0px;
*/
/*}*/

/*.online .c4_item_photo_wrap:after{*/
/*	background-color: $onlineColor;
*/
/*}*/

/*.message .c4_item_photo_wrap:after{*/
/*	border-color: rgba(18, 18, 109, 0.4);
*/
/*}*/

/*.c4_item_photo{*/
/*	width: 56px;
*/
/*	height: 56px;
*/
/*	border-radius: 100%;
*/
/*	object-fit: cover;
*/
/*	display: block;
*/
/*}*/

/*.c4_item_big_photo{*/
/*	display: none;
*/
/*	position: fixed;
*/
/*	width: 320px;
*/
/*	height: 100%;
*/
/*	padding: 16px 18px;
*/
/*	background-color: $primary2Color;
*/
/*	border-radius: 8px 0px 0px 8px;
*/
/*	box-shadow: $searchItemLikeBtnShadowHover;
*/
/*}*/

/*.message.favorites .c4_item_big_photo{*/
/*	background-color: $accentRightColor;
*/
/*}*/

/*.c4_item_big_photo img{*/
/*	width: 100%;
*/
/*	height: 100%;
*/
/*	object-fit: cover;
*/
/*}*/

/*.c4_item_name{*/
/*	font-weight: 600;
*/
/*	font-size: 18px;
*/
/*	line-height: 22px;
*/
/*	color: $stormGreyColor;
*/
/*}*/

/*.message .c4_item_name{*/
/*	color: $whiteColor;
*/
/*}*/

/*.c4_item_text{*/
/*	font-family: "Roboto", sans-serif;
*/
/*	font-weight: 500;
*/
/*	font-size: 12px;
*/
/*	line-height: 150%;
*/
/*	color: $defaultTextColor;
*/
/*	margin-top: 10px;
*/
/*}*/

/*.message .c4_item_text{*/
/*	color: $whiteColor;
*/
/*	-webkit-line-clamp: 2;
*/
/*	overflow: hidden;
*/
/*	text-overflow: ellipsis;
*/
/*	height: 38px;
*/
/*	display: -webkit-box;
*/
/*	width: 170px;
*/
/*	font-weight: normal;
*/
/*}*/

/*.liked .c4_item_text{*/
/*	padding-left: 18px;
*/
/*	position: relative;
*/
/*}*/

/*.liked .c4_item_text:before{*/
/*	content: '';
*/
/*	display: block;
*/
/*	width: 12px;
*/
/*	height: 11px;
*/
/*	background-image: url(/img/profile_menu/c4-like.svg);
*/
/*	background-repeat: no-repeat;
*/
/*	position: absolute;
*/
/*	left: 0;
*/
/*	top: 3px;
*/
/*}*/

/*.viewed .c4_item_text{*/
/*	padding-left: 20px;
*/
/*	position: relative;
*/
/*}*/

/*.viewed .c4_item_text:before{*/
/*	content: '';
*/
/*	display: block;
*/
/*	width: 14px;
*/
/*	height: 10px;
*/
/*	background-image: url(/img/profile_menu/c4-viwed.svg);
*/
/*	background-repeat: no-repeat;
*/
/*	position: absolute;
*/
/*	left: 0;
*/
/*	top: 4px;
*/
/*}*/

/*.c4_item_clear{*/
/*	position: absolute;
*/
/*	top: 7px;
*/
/*	right: 7px;
*/
/*	cursor: pointer;
*/
/*	padding: 5px;
*/
/*}*/

/*.c4_item_clear img{*/
/*	display: block;
*/
/*}*/

/*.c4_item_time{*/
/*	position: absolute;
*/
/*	right: 11px;
*/
/*	bottom: 8px;
*/
/*	font-family: "Roboto", sans-serif;
*/
/*	font-size: 12px;
*/
/*	line-height: 14px;
*/
/*	letter-spacing: -0.03em;
*/
/*	text-transform: uppercase;
*/
/*	color: $defaultTextColorAlpha6;
*/
/*}*/

/*.message .c4_item_time{*/
/*	color: $whiteColor;
*/
/*}*/

/*.c4_id_small{*/
/*	display: none;
*/
/*	font-size: 10px;
*/
/*	line-height: 12px;
*/
/*	color: $silverColor;
*/
/*	margin-top: 10px;
*/
/*}*/

/*.c4_item_reply{*/
/*	display: none;
*/
/*	position: absolute;
*/
/*	right: 20px;
*/
/*	bottom: 12px;
*/
/*	cursor: pointer;
*/
/*}*/

/*.c4_item.message:hover{*/
/*	display: block;
*/
/*	padding-bottom: 56px;
*/
/*}*/

/*.c4_item.message:hover .c4_id_small{*/
/*	display: block;
*/
/*}*/

/*.c4_item.message:hover .c4_item_text{*/
/*	height: auto;
*/
/*	width: 100%;
*/
/*	margin-top: 24px;
*/
/*	font-size: 13px;
*/
/*}*/

/*.c4_item.message:hover .c4_item_photo_wrap{*/
/*	float: left;
*/
/*}*/

/*.c4_item.message:hover .c4_item_time{*/
/*	top: 10px;
*/
/*	right: 33px;
*/
/*	height: 16px;
*/
/*}*/

/*.c4_item.message:hover .c4_item_reply{*/
/*	display: block;
*/
/*}*/

/*.c4_item_photo_wrap:hover + .c4_item_big_photo{*/
/*	display: block;
*/
/*}*/

/*.c4_support{*/
/*	position: absolute;
*/
/*	bottom: 8px;
*/
/*	right: -84px;
*/
/*	background-color: $littleGreyLittleBlueColor;
*/
/*	width: 140px;
*/
/*	height: 52px;
*/
/*	box-shadow: $searchItemLikeBtnShadowHover;
*/
/*	border-radius: 5px;
*/
/*	display: -webkit-flex;
*/
/*	display: -moz-flex;
*/
/*	display: -ms-flex;
*/
/*	display: -o-flex;
*/
/*	display: flex;
*/
/*	-ms-align-items: center;
*/
/*	align-items: center;
*/
/*	transition: .3s;
*/
/*	cursor: pointer;
*/
/*	display: none;
*/
/*}*/

/*.c4_support:hover{*/
/*	right: 0;
*/
/*}*/

/*.c4_support img{*/
/*	margin: 0 16px;
*/
/*}*/

/*.c4_support span{*/
/*	font-weight: bold;
*/
/*	font-size: 16px;
*/
/*	line-height: 20px;
*/
/*	color: $accentRightColor;
*/
/*}*/

/*.c3_head{*/
/*	height: 80px;
*/
/*	background-color: $primaryColor;
*/
/*	display: -webkit-flex;
*/
/*	display: -moz-flex;
*/
/*	display: -ms-flex;
*/
/*	display: -o-flex;
*/
/*	display: flex;
*/
/*	justify-content: space-between;
*/
/*	-ms-align-items: center;
*/
/*	align-items: center;
*/
/*	padding: 10px 26px 15px 60px;
*/
/*}*/

/*.c3_head_left{*/
/*	display: -webkit-flex;
*/
/*	display: -moz-flex;
*/
/*	display: -ms-flex;
*/
/*	display: -o-flex;
*/
/*	display: flex;
*/
/*	-ms-align-items: center;
*/
/*	align-items: center;
*/
/*}*/

/*.c3_head_photo_wrap{*/
/*	margin-right: 11px;
*/
/*	display: block;
*/
/*	height: 65px;
*/
/*	width: 75px;
*/
/*	padding-top: 10px;
*/
/*}*/

/*.c3_head_photo{*/
/*	position: relative;
*/
/*}*/

/*.c3_head_photo:hover{*/
/*	background-color: $whiteColor;
*/
/*	position: relative;
*/
/*	padding-bottom: 10px;
*/
/*	margin-top: -10px;
*/
/*	padding-top: 10px;
*/
/*	box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.05);
*/
/*	border-radius: 5px;
*/
/*	z-index: 1;
*/
/*}*/

/*.c3_head_photo_bottom{*/
/*	display: none;
*/
/*	font-weight: 500;
*/
/*	font-size: 11px;
*/
/*	line-height: 118%;
*/
/*	color: $accentRightColor;
*/
/*	-ms-align-items: center;
*/
/*	align-items: center;
*/
/*	justify-content: center;
*/
/*	margin-top: 12px;
*/
/*}*/

/*.c3_head_photo:hover .c3_head_photo_bottom{*/
/*	display: -webkit-flex;
*/
/*	display: -moz-flex;
*/
/*	display: -ms-flex;
*/
/*	display: -o-flex;
*/
/*	display: flex;
*/
/*}*/

/*.c3_head_photo_bottom img{*/
/*	width: 16px;
*/
/*	margin-right: 6px;
*/
/*}*/

/*.c3_head_photo > img{*/
/*	width: 56px;
*/
/*	height: 56px;
*/
/*	display: block;
*/
/*	border-radius: 100%;
*/
/*	object-fit: cover;
*/
/*	margin: auto;
*/
/*}*/

/*.c3_head_photo:after{*/
/*	content: '';
*/
/*	display: block;
*/
/*	width: 12px;
*/
/*	height: 12px;
*/
/*	background-color: $familySizeCondensedSoupColor;
*/
/*	border-radius: 100%;
*/
/*	border: 2px solid $primaryColor;
*/
/*	position: absolute;
*/
/*	right: 7px;
*/
/*	top: 36px;
*/
/*}*/

/*.c3_head_photo:hover:after{*/
/*	top: 46px;
*/
/*	border-color: $whiteColor;
*/
/*}*/

/*.c3_head_photo_wrap.online .c3_head_photo:after{*/
/*	background-color: $onlineColor;
*/
/*}*/

/*.c3_head_name{*/
/*	font-weight: 600;
*/
/*	font-size: 16px;
*/
/*	line-height: 20px;
*/
/*	letter-spacing: 0.01em;
*/
/*	color: $whiteColor;
*/
/*	margin-bottom: 4px;
*/
/*}*/

/*.liked .c3_head_name:after{*/
/*	content: '';
*/
/*	display: inline-block;
*/
/*	width: 10px;
*/
/*	height: 9px;
*/
/*	background-image: url(/img/profile_menu/c3-like.svg);
*/
/*	margin-left: 11px;
*/
/*	position: relative;
*/
/*	top: -2px;
*/
/*}*/

/*.c3_head_name_bottom{*/
/*	display: -webkit-flex;
*/
/*	display: -moz-flex;
*/
/*	display: -ms-flex;
*/
/*	display: -o-flex;
*/
/*	display: flex;
*/
/*	-ms-align-items: center;
*/
/*	align-items: center;
*/
/*}*/

/*.c3_head_id{*/
/*	font-family: "Roboto", sans-serif;
*/
/*	font-size: 10px;
*/
/*	line-height: 12px;
*/
/*	color: $silverColor;
*/
/*}*/

/*.c3_head_place{*/
/*	display: -webkit-flex;
*/
/*	display: -moz-flex;
*/
/*	display: -ms-flex;
*/
/*	display: -o-flex;
*/
/*	display: flex;
*/
/*	-ms-align-items: center;
*/
/*	align-items: center;
*/
/*	margin-left: 11px;
*/
/*}*/

/*.c3_head_place img{*/
/*	height: 12px;
*/
/*	display: block;
*/
/*	margin-right: 4px;
*/
/*}*/

/*.c3_head_place span{*/
/*	line-height: 1;
*/
/*	font-size: 12px;
*/
/*	letter-spacing: -0.03em;
*/
/*	color: $silverColor;
*/
/*}*/

/*.c3_head_right{*/
/*	display: -webkit-flex;
*/
/*	display: -moz-flex;
*/
/*	display: -ms-flex;
*/
/*	display: -o-flex;
*/
/*	display: flex;
*/
/*	-ms-align-items: center;
*/
/*	align-items: center;
*/
/*}*/

/*.c3_head_btn{*/
/*	cursor: pointer;
*/
/*	margin-left: 36px;
*/
/*}*/

/*.c3_head_btn.ring_not{*/
/*	padding: 7px;
*/
/*	background-color: $whiteColor;
*/
/*	border-radius: 8px;
*/
/*	position: relative;
*/
/*	display: none;
*/
/*	box-shadow: 6px 0px 12px rgba(47, 43, 99, 0.16);
*/
/*}*/

/*.c3_head_btn.ring_not img{*/
/*	width: 30px;
*/
/*}*/

/*.c3_head_btn img{*/
/*	display: block;
*/
/*}*/

/*.c3_head_btn.like{*/
/*	border: 1px solid $liteGreyColor;
*/
/*	border-radius: 5px;
*/
/*	width: 27px;
*/
/*	height: 27px;
*/
/*	text-align: center;
*/
/*	line-height: 32px;
*/
/*	display: -webkit-flex;
*/
/*	display: -moz-flex;
*/
/*	display: -ms-flex;
*/
/*	display: -o-flex;
*/
/*	display: flex;
*/
/*	-ms-align-items: center;
*/
/*	align-items: center;
*/
/*	justify-content: center;
*/
/*	margin-right: 6px;
*/
/*}*/

/*.c3_head_btn.wink{*/
/*	border: 1px solid $liteGreyColor;
*/
/*	border-radius: 5px;
*/
/*	width: 27px;
*/
/*	height: 27px;
*/
/*	text-align: center;
*/
/*	line-height: 32px;
*/
/*	display: -webkit-flex;
*/
/*	display: -moz-flex;
*/
/*	display: -ms-flex;
*/
/*	display: -o-flex;
*/
/*	display: flex;
*/
/*	-ms-align-items: center;
*/
/*	align-items: center;
*/
/*	justify-content: center;
*/
/*	margin-right: 6px;
*/
/*}*/

/*.c3_options{*/
/*	height: 56px;
*/
/*	background-color: $whiteColor;
*/
/*	box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.1);
*/
/*	display: -webkit-flex;
*/
/*	display: -moz-flex;
*/
/*	display: -ms-flex;
*/
/*	display: -o-flex;
*/
/*	display: flex;
*/
/*	-ms-align-items: center;
*/
/*	align-items: center;
*/
/*	justify-content: space-between;
*/
/*	padding: 0 60px;
*/
/*	position: relative;
*/
/*}*/

/*.c3_options_item{*/
/*	line-height: 1;
*/
/*	font-weight: 500;
*/
/*	font-size: 16px;
*/
/*	display: flex;
*/
/*	align-items: center;
*/
/*	letter-spacing: 0.01em;
*/
/*	color: $stormGreyColor;
*/
/*	display: -webkit-flex;
*/
/*	display: -moz-flex;
*/
/*	display: -ms-flex;
*/
/*	display: -o-flex;
*/
/*	display: flex;
*/
/*	-ms-align-items: center;
*/
/*	align-items: center;
*/
/*	cursor: pointer;
*/
/*}*/

/*.c3_options_item img{*/
/*	display: block;
*/
/*	margin-right: 18px;
*/
/*}*/

/*.c3_options_item.webacm{*/
/*	position: relative;
*/
/*	padding-right: 34px;
*/
/*}*/

/*.c3_options_item.webacm:after{*/
/*	content: '';
*/
/*	display: block;
*/
/*	width: 12px;
*/
/*	height: 8px;
*/
/*	position: absolute;
*/
/*	right: 0;
*/
/*	top: calc(50% - 4px);
*/
/*	background-image: url(/img/profile_menu/bottom-grey.svg);
*/
/*	-webkit-background-size: contain;
*/
/*	background-size: contain;
*/
/*	background-repeat: no-repeat;
*/
/*}*/

/*.c3_chat_wrap{*/
/*	height: calc(100vh - 136px);
*/
/*	display: -webkit-flex;
*/
/*	display: -moz-flex;
*/
/*	display: -ms-flex;
*/
/*	display: -o-flex;
*/
/*	display: flex;
*/
/*	-webkit-flex-direction: column;
*/
/*	-moz-flex-direction: column;
*/
/*	-ms-flex-direction: column;
*/
/*	-o-flex-direction: column;
*/
/*	flex-direction: column;
*/
/*}*/

/*.c3_chat_scroll{*/
/*	height: calc(100vh - 306px);
*/
/*	overflow-y: auto;
*/
/*}*/

/*.c3_chat_wrap .c3_chat_scroll::-webkit-scrollbar{*/
/*	width: 3px;
*/
/*	background: $bleachColor;
*/
/*	border-radius: 5px;
*/
/*	margin-right: 3px;
*/
/*}*/

/*.c3_chat_wrap .c3_chat_scroll::-webkit-scrollbar-thumb {*/
/*  background: $scrollbarThumbColor;
*/
/*  border-radius: 7px;
*/
/*}*/

/*.c3_chat{*/
/*	min-height: 100%;
*/
/*	padding: 20px 26px;
*/
/*}*/

/*.chat_message_wrap{*/
/*	display: -webkit-flex;
*/
/*	display: -moz-flex;
*/
/*	display: -ms-flex;
*/
/*	display: -o-flex;
*/
/*	display: flex;
*/
/*	margin-bottom: 20px;
*/
/*}*/

/*.chat_message_wrap.right{*/
/*	justify-content: flex-end;
*/
/*}*/

/*.chat_message{*/
/*	font-family: "Roboto", sans-serif;
*/
/*	display: -webkit-flex;
*/
/*	display: -moz-flex;
*/
/*	display: -ms-flex;
*/
/*	display: -o-flex;
*/
/*	display: flex;
*/
/*	-ms-align-items: flex-end;
*/
/*	align-items: flex-end;
*/
/*}*/

/*.message_avatar{*/
/*	margin-bottom: 15px;
*/
/*}*/

/*.message_avatar img{*/
/*	width: 40px;
*/
/*	height: 40px;
*/
/*	border-radius: 100%;
*/
/*	object-fit: cover;
*/
/*}*/

/*.message_text{*/
/*	font-size: 16px;
*/
/*	line-height: 140%;
*/
/*	color: $defaultTextColor;
*/
/*	padding: 16px 24px;
*/
/*	border-radius: 8px;
*/
/*	margin-bottom: 5px;
*/
/*}*/

/*.message_bottom{*/
/*	display: -webkit-flex;
*/
/*	display: -moz-flex;
*/
/*	display: -ms-flex;
*/
/*	display: -o-flex;
*/
/*	display: flex;
*/
/*	justify-content: space-between;
*/
/*}*/

/*.message_date{*/
/*	font-size: 12px;
*/
/*	line-height: 14px;
*/
/*	letter-spacing: -0.03em;
*/
/*	text-transform: uppercase;
*/
/*	color: $silverColor;
*/
/*}*/

/*.chat_message_wrap.left .message_content{*/
/*	margin-left: 16px;
*/
/*	margin-right: 56px;
*/
/*}*/

/*.chat_message_wrap.left .message_text{*/
/*	background-color: $bleachColor;
*/
/*	border-radius: 8px 8px 8px 0;
*/
/*}*/

/*.chat_message_wrap.right .message_content{*/
/*	margin-left: 56px;
*/
/*	margin-right: 16px;
*/
/*}*/

/*.chat_message_wrap.right .message_text{*/
/*	background-color: $background4Color;
*/
/*	border-radius: 8px 8px 0 8px;
*/
/*}*/

/*.chat_message_wrap.sticker .message_text{*/
/*	background-color: transparent;
*/
/*	padding: 0;
*/
/*}*/

/*.chat_message_wrap.sticker .message_text img{*/
/*	width: 350px;
*/
/*	height: 350px;
*/
/*	object-fit: contain;
*/
/*}*/

/*.chat_message_wrap.wink .message_text{*/
/*	background-color: transparent;
*/
/*	padding: 0;
*/
/*}*/

/*.chat_message_wrap.wink .message_text img{*/
/*}*/

/*.message_status{*/
/*	width: 19px;
*/
/*	height: 12px;
*/
/*	-webkit-background-size: contain;
*/
/*	background-size: contain;
*/
/*	background-repeat: no-repeat;
*/
/*}*/

/*.message_status.send{*/
/*	background-image: url(/img/profile_menu/send.svg);
*/
/*}*/

/*.message_status.readed{*/
/*	background-image: url(/img/profile_menu/readed.svg);
*/
/*}*/

/*.message_img{*/
/*	display: block;
*/
/*	width: 200px;
*/
/*	height: 200px;
*/
/*	object-fit: cover;
*/
/*	margin-top: 16px;
*/
/*	border-radius: 8px;
*/
/*}*/

/*.message_reply_text{*/
/*	font-style: italic;
*/
/*	font-weight: normal;
*/
/*	font-size: 16px;
*/
/*	line-height: 26px;
*/
/*	padding-left: 8px;
*/
/*	border-left: 2px solid $submenuColor;
*/
/*	width: 200px;
*/
/*	min-width: 100%;
*/
/*	height: 26px;
*/
/*	overflow: hidden;
*/
/*	-ms-text-overflow: ellipsis;
*/
/*	text-overflow: ellipsis;
*/
/*	white-space: nowrap;
*/
/*	margin-bottom: 10px;
*/
/*}*/

/*.message_video_wrap span.need_pay{*/
/*	font-size: 11px;
*/
/*	line-height: 130%;
*/
/*	color: $defaultTextColorAlpha3;
*/
/*	margin-bottom: 7px;
*/
/*	display: block;
*/

/*}*/

/*.message_video{*/
/*	width: 200px;
*/
/*	height: 113px;
*/
/*	position: relative;
*/
/*	cursor: pointer;
*/
/*}*/

/*.message_video img{*/
/*	width: 100%;
*/
/*	height: 100%;
*/
/*	margin-top: 0;
*/
/*	object-fit: cover;
*/
/*}*/

/*.message_video:after{*/
/*	content: '';
*/
/*	display: block;
*/
/*	width: 40px;
*/
/*	height: 40px;
*/
/*	position: absolute;
*/
/*	top: calc(50% - 20px);
*/
/*	left: calc(50% - 20px);
*/
/*	background-image: url(/img/profile_menu/play-btn.svg);
*/
/*}*/

/*.message_video_status{*/
/*	margin-top: 6px;
*/
/*}*/

/*.message_video_status.paid{*/
/*	font-weight: 500;
*/
/*	font-size: 11px;
*/
/*	line-height: 130%;
*/
/*	color: $primaryColor;
*/
/*	text-align: right;
*/
/*}*/

/*.message_video_status.viewed{*/
/*	text-align: right;
*/
/*	font-size: 11px;
*/
/*	line-height: 130%;
*/
/*	color: $defaultTextColorAlpha6;
*/
/*	position: relative;
*/
/*}*/

/*.message_video_status.viewed span:before{*/
/*	content: '';
*/
/*	display: inline-block;
*/
/*	width: 14px;
*/
/*	height: 9px;
*/
/*	background-image: url(/img/profile_menu/video-viewed.svg);
*/
/*	background-repeat: no-repeat;
*/
/*	margin-right: 8px;
*/
/*	margin-bottom: -1px;
*/
/*}*/

/*.chat_new_line_wrap{*/
/*	display: -webkit-flex;
*/
/*	display: -moz-flex;
*/
/*	display: -ms-flex;
*/
/*	display: -o-flex;
*/
/*	display: flex;
*/
/*	justify-content: space-between;
*/
/*	-ms-align-items: center;
*/
/*	align-items: center;
*/
/*}*/

/*.chat_new_line{*/
/*	height: 1px;
*/
/*	background-color: $submenuColor;
*/
/*	flex: 1;
*/
/*}*/

/*.chat_new_text{*/
/*	font-weight: 500;
*/
/*	font-size: 16px;
*/
/*	letter-spacing: -0.02em;
*/
/*	text-transform: uppercase;
*/
/*	color: $counterAndErrorColor;
*/
/*	margin: 0 16px;
*/
/*}*/

/*.c3_write_msg{*/
/*	height: 170px;
*/
/*	background-color: $whiteColor;
*/
/*	display: -webkit-flex;
*/
/*	display: -moz-flex;
*/
/*	display: -ms-flex;
*/
/*	display: -o-flex;
*/
/*	display: flex;
*/
/*	justify-content: space-between;
*/
/*	-ms-align-items: flex-start;
*/
/*	align-items: flex-start;
*/
/*	padding: 24px 24px 0 27px;
*/
/*	border-top: 2px solid $bleachColor;
*/
/*}*/

/*.c3_textaera_wrap{*/
/*	-webkit-flex: 1px;
*/
/*	-moz-flex: 1px;
*/
/*	-ms-flex: 1px;
*/
/*	-o-flex: 1px;
*/
/*	flex: 1px;
*/
/*}*/

/*.c3_textaera_wrap{*/
/*	display: block;
*/
/*	resize: none;
*/
/*	width: 100%;
*/
/*	height: 104px;
*/
/*	background: $littleGreyLittleBlueColor;
*/
/*	border-radius: 8px;
*/
/*	border: none;
*/
/*	padding: 16px 16px 16px 18px;
*/
/*	font-size: 16px;
*/
/*	line-height: 150%;
*/
/*	color: $stormGreyColor;
*/
/*	font-family: "Roboto", sans-serif;
*/
/*}*/

/*.c3_attach{*/
/*	margin-right: 20px;
*/
/*}*/

/*.c3_smile_btn_wrap{*/
/*	margin-left: 30px;
*/
/*	width: 58px;
*/
/*	height: 62px;
*/
/*	position: relative;
*/
/*}*/

/*.c3_smile_btn_wrap2{*/
/*	display: -webkit-flex;
*/
/*	display: -moz-flex;
*/
/*	display: -ms-flex;
*/
/*	display: -o-flex;
*/
/*	display: flex;
*/
/*	-webkit-flex-direction: column;
*/
/*	-moz-flex-direction: column;
*/
/*	-ms-flex-direction: column;
*/
/*	-o-flex-direction: column;
*/
/*	flex-direction: column;
*/
/*	position: absolute;
*/
/*	background-color: $whiteColor;
*/
/*	border-radius: 4px;
*/
/*	top: 0;
*/
/*	left: 0;
*/
/*}*/

/*.c3_smile_btn_wrap.active .c3_smile_btn_wrap2{*/
/*	bottom: 0;
*/
/*	left: 0;
*/
/*	top: auto;
*/
/*	box-shadow: 0px 0px 8px rgba(221, 220, 236, 0.64);
*/
/*}*/

/*.c3_smile_btn_wrap.sticker .c3_smile_btn_wrap2{*/
/*	-webkit-flex-direction: column-reverse;
*/
/*	-moz-flex-direction: column-reverse;
*/
/*	-ms-flex-direction: column-reverse;
*/
/*	-o-flex-direction: column-reverse;
*/
/*	flex-direction: column-reverse;
*/
/*}*/

/*.c3_sticker_btn{*/
/*	display: none;
*/
/*	padding: 15px;
*/
/*}*/

/*.c3_sticker_btn svg{*/
/*	fill: $fadeColor;
*/
/*}*/

/*.c3_sticker_btn.active{*/
/*	background-color: $bleachColor;
*/
/*	padding: 10px 15px;
*/
/*}*/

/*.c3_sticker_btn.active svg{*/
/*	fill: $accentRightColor;
*/
/*}*/

/*.sticker .c3_smile_btn svg{*/
/*	fill: $fadeColor;
*/
/*}*/

/*.c3_smile_btn svg{*/
/*	fill: $accentRightColor;
*/
/*}*/

/*.c3_smile_btn_wrap.active .c3_sticker_btn{*/
/*	display: block;
*/
/*}*/

/*.c3_smile_btn{*/
/*	padding: 15px;
*/
/*	padding-top: 0;
*/
/*}*/

/*.c3_smile_btn_wrap.active .c3_smile_btn{*/
/*	padding-top: 15px;
*/
/*}*/

/*.c3_smile_btn.active{*/
/*	background-color: $bleachColor;
*/
/*	padding: 10px 15px;
*/
/*}*/

/*.c3_sticker_btn.active{*/
/*	background-color: $bleachColor;
*/
/*	padding: 10px 15px;
*/
/*}*/

/*.c3_smile_window_wrap{*/
/*	display: none;
*/
/*	background-color: $bleachColor;
*/
/*	padding: 10px 13px;
*/
/*	position: absolute;
*/
/*	right: 100%;
*/
/*	bottom: 56px;
*/
/*	border-radius: 4px 4px 0px 4px;
*/
/*}*/

/*.smile .c3_smile_window_wrap{*/
/*	display: block;
*/
/*}*/

/*.c3_sticker_window_wrap{*/
/*	display: none;
*/
/*	background-color: $bleachColor;
*/
/*	padding: 10px 13px;
*/
/*	position: absolute;
*/
/*	right: 100%;
*/
/*	bottom: 56px;
*/
/*	border-radius: 4px 4px 0px 4px;
*/
/*}*/

/*.stiker .c3_sticker_window_wrap{*/
/*	display: block;
*/
/*}*/

/*.c3_sticker_window_wrap img{*/
/*	width: 345px;
*/
/*}*/












/*.c3_send_btn{*/
/*	width: 96px;
*/
/*	height: 42px;
*/
/*	display: -webkit-flex;
*/
/*	display: -moz-flex;
*/
/*	display: -ms-flex;
*/
/*	display: -o-flex;
*/
/*	display: flex;
*/
/*	-ms-align-items: center;
*/
/*	align-items: center;
*/
/*	justify-content: center;
*/
/*	font-weight: 600;
*/
/*	font-size: 13px;
*/
/*	text-align: center;
*/
/*	letter-spacing: 0.01em;
*/
/*	color: $whiteColor;
*/
/*	background: linear-gradient(90.29deg, $accentLeftColor 0%, $accentRightColor 100%);
*/
/*	border-radius: 4px;
*/
/*	margin-left: 30px;
*/
/*}*/

/*.c3_send_btn img{*/
/*	display: block;
*/
/*	margin-right: 15px;
*/
/*}*/

/*.c4_minichat{*/
/*	position: absolute;
*/
/*	top: 0;
*/
/*	right: 100%;
*/
/*	height: 100vh;
*/
/*	width: 440px;
*/
/*	background-color: $whiteColor;
*/
/*	z-index: 3;
*/
/*	display: none;
*/
/*	box-shadow: -5px 0px 5px rgba(0, 0, 0, 0.1);
*/
/*}*/

/*.c4_minichat .c3_chat_wrap{*/
/*	height: calc(100vh - 80px);
*/
/*	padding-right: 4px;
*/
/*}*/

/*.c4_minichat .c3_chat_scroll{*/
/*	height: calc(100vh - 297px);
*/
/*}*/

/*.c4_minichat .c3_chat_scroll::-webkit-scrollbar{*/
/*	width: 3px;
*/
/*	background: $bleachColor;
*/
/*	border-radius: 5px;
*/
/*	margin-right: 3px;
*/
/*}*/

/*.c4_minichat .c3_chat_scroll::-webkit-scrollbar-thumb {*/
/*  background: $scrollbarThumbColor;
*/
/*  border-radius: 7px;
*/
/*}*/

/*.close_minichat{*/
/*	width: 16px;
*/
/*	height: 16px;
*/
/*	cursor: pointer;
*/
/*}*/

/*.c4_minichat .c3_head{*/
/*	padding-left: 30px;
*/
/*}*/

/*.c4_minichat .chat_message_wrap.sticker .message_text img{*/
/*	width: 285px;
*/
/*	height: 285px;
*/
/*}*/

/*.c4_minichat .c3_write_msg{*/
/*	-webkit-flex-wrap: wrap;
*/
/*	-moz-flex-wrap: wrap;
*/
/*	-ms-flex-wrap: wrap;
*/
/*	-o-flex-wrap: wrap;
*/
/*	flex-wrap: wrap;
*/
/*	-ms-align-items: center;
*/
/*	align-items: center;
*/
/*	padding: 24px 20px 21px;
*/
/*	height: auto;
*/
/*}*/

/*.c4_minichat .c3_textaera_wrap{*/
/*	width: 100%;
*/
/*	flex: auto;
*/
/*	margin-bottom: 24px;
*/
/*}*/

/*.c4_minichat .c3_smile_btn{*/
/*	margin-left: 0;
*/
/*	margin-right: 24px;
*/
/*}*/

/*.c3_write_msg_left{*/
/*	display: -webkit-flex;
*/
/*	display: -moz-flex;
*/
/*	display: -ms-flex;
*/
/*	display: -o-flex;
*/
/*	display: flex;
*/
/*	-ms-align-items: center;
*/
/*	align-items: center;
*/
/*}*/

/*.fancybox-content{*/
/*	padding: 24px;
*/
/*}*/

/*.upload_popup_wrap{*/
/*	display: none;
*/
/*	width: 680px;
*/
/*	background-color: $whiteColor;
*/
/*	box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.1);
*/
/*	border-radius: 8px;
*/
/*}*/

/*.upload_popup_tabs_ul{*/
/*	display: -webkit-flex;
*/
/*	display: -moz-flex;
*/
/*	display: -ms-flex;
*/
/*	display: -o-flex;
*/
/*	display: flex;
*/
/*	border-bottom: 1px solid $submenuColor;
*/
/*}*/

/*.upload_popup_tabs_ul li{*/
/*	flex: 1;
*/
/*	text-align: center;
*/
/*	outline: none;
*/
/*}*/

/*.upload_popup_tabs_ul li a{*/
/*	display: block;
*/
/*	height: 35px;
*/
/*	font-weight: 500;
*/
/*	font-size: 16px;
*/
/*	line-height: 25px;
*/
/*	text-align: center;
*/
/*	letter-spacing: 0.06em;
*/
/*	text-transform: uppercase;
*/
/*	color: $defaultTextColorAlpha6;
*/
/*	font-family: "Roboto", sans-serif;
*/
/*	outline: none;
*/
/*	border-bottom: 2px solid transparent;
*/
/*}*/

/*.upload_popup_tabs_ul li.ui-tabs-active a{*/
/*	border-color: $accentRightColor;
*/
/*	color: $accentRightColor;
*/
/*}*/

/*.upload_popup_tabs_content_top{*/
/*	display: -webkit-flex;
*/
/*	display: -moz-flex;
*/
/*	display: -ms-flex;
*/
/*	display: -o-flex;
*/
/*	display: flex;
*/
/*	justify-content: space-between;
*/
/*	-ms-align-items: center;
*/
/*	align-items: center;
*/
/*	padding-top: 18px;
*/
/*	padding-bottom: 18px;
*/
/*}*/

/*.upload_popup_tabs_content_top .cost{*/
/*	font-size: 11px;
*/
/*	color: $defaultTextColorAlpha3;
*/
/*}*/

/*.upload_popup_tabs_content_top .sort{*/
/*	font-weight: bold;
*/
/*	font-size: 13px;
*/
/*	line-height: 16px;
*/
/*	letter-spacing: 0.01em;
*/
/*	color: $accentLeftColor;
*/
/*}*/

/*.upload_popup_tabs_content_top .sort img{*/
/*	vertical-align: middle;
*/
/*	margin-right: 9px;
*/
/*}*/

/*.upload_popup_tabs_content_middle{*/
/*	margin-bottom: 16px;
*/
/*}*/

/*.upload_popup_tabs_content_middle.photo,*/
/*.upload_popup_tabs_content_middle.video{*/
/*	display: -webkit-flex;
*/
/*	display: -moz-flex;
*/
/*	display: -ms-flex;
*/
/*	display: -o-flex;
*/
/*	display: flex;
*/
/*	-webkit-flex-wrap: wrap;
*/
/*	-moz-flex-wrap: wrap;
*/
/*	-ms-flex-wrap: wrap;
*/
/*	-o-flex-wrap: wrap;
*/
/*	flex-wrap: wrap;
*/
/*}*/


/*.upload_popup_tabs_content_item{*/
/*	border-radius: 4px;
*/
/*	background-color: $bleachColor;
*/
/*	margin: 2px 2px 8px;
*/
/*}*/

/*.upload_popup_tabs_content_audio_item{*/
/*	background-color: $bleachColor;
*/
/*	border-radius: 8px;
*/
/*	height: 114px;
*/
/*	padding: 16px;
*/
/*	margin-bottom: 8px;
*/
/*}*/

/*.upload_popup_tabs_content_item_top input{*/
/*	display: none;
*/
/*}*/

/*.upload_popup_tabs_content_item_top label{*/
/*	position: relative;
*/
/*	display: block;
*/
/*	cursor: pointer;
*/
/*}*/

/*.upload_popup_tabs_content_item_top label:after{*/
/*	content: '';
*/
/*	display: block;
*/
/*	width: 16px;
*/
/*	height: 16px;
*/
/*	border-radius: 4px;
*/
/*	position: absolute;
*/
/*	top: 4px;
*/
/*	right: 4px;
*/
/*	background-color: $whiteColor;
*/
/*	border: 1px solid $liteGreyColor;
*/
/*}*/

/*.upload_popup_tabs_content_item_top input:checked + label:after{*/
/*	background-image: url(/img/profile_menu/popup-checked.svg);
*/
/*	background-repeat: no-repeat;
*/
/*	background-position: center;
*/
/*	background-color: $primaryLiteColor;
*/
/*	border-color: $primaryLiteColor;
*/
/*}*/

/*.upload_popup_tabs_content_item_top label img{*/
/*	border-radius: 4px;
*/
/*	display: block;
*/
/*	object-fit: cover;
*/
/*}*/

/*.photo .upload_popup_tabs_content_item_top label img{*/
/*	width: 120px;
*/
/*	height: 120px;
*/
/*}*/

/*.video .upload_popup_tabs_content_item_top label img{*/
/*	width: 202px;
*/
/*	height: 80px;
*/
/*}*/

/*.upload_popup_tabs_content_item_bottom{*/
/*	display: -webkit-flex;
*/
/*	display: -moz-flex;
*/
/*	display: -ms-flex;
*/
/*	display: -o-flex;
*/
/*	display: flex;
*/
/*	justify-content: space-between;
*/
/*	-ms-align-items: center;
*/
/*	align-items: center;
*/
/*	padding: 8px 10px;
*/
/*}*/

/*.upload_popup_tabs_content_item_bottom img{*/
/*	display: block;
*/
/*	cursor: pointer;
*/
/*}*/

/*.upload_popup_tabs_content_bottom{*/
/*	display: -webkit-flex;
*/
/*	display: -moz-flex;
*/
/*	display: -ms-flex;
*/
/*	display: -o-flex;
*/
/*	display: flex;
*/
/*	justify-content: space-between;
*/
/*}*/

/*.popup_bottom_upload_btn{*/
/*	width: 144px;
*/
/*	height: 42px;
*/
/*	font-weight: 600;
*/
/*	font-size: 14px;
*/
/*	letter-spacing: 0.01em;
*/
/*	color: $primaryColor;
*/
/*	border: 1px solid $primaryColor;
*/
/*	box-sizing: border-box;
*/
/*	border-radius: 4px;
*/
/*	text-align: center;
*/
/*	line-height: 40px;
*/
/*	cursor: pointer;
*/
/*}*/

/*.popup_bottom_upload_btn img{*/
/*	vertical-align: middle;
*/
/*	display: inline-block;
*/
/*	margin-top: -5px;
*/
/*	margin-right: 13px;
*/
/*}*/

/*.popup_bottom_send_btn{*/
/*	width: 147px;
*/
/*	height: 42px;
*/
/*	background: linear-gradient(90.44deg, $accentLeftColor 0%, $accentRightColor 100%);
*/
/*	box-shadow: $searchItemLikeBtnShadowHover;
*/
/*	border-radius: 4px;
*/
/*	color: $whiteColor;
*/
/*	line-height: 42px;
*/
/*	text-align: center;
*/
/*	cursor: pointer;
*/
/*}*/

/*.popup_bottom_send_btn img{*/
/*	display: inline-block;
*/
/*	margin-right: 14px;
*/
/*	margin-bottom: -3px;
*/
/*}*/

/*.popup_time{*/
/*	font-weight: 500;
*/
/*	font-size: 11px;
*/
/*	text-align: center;
*/
/*	color: $submenuColor;
*/
/*}*/

/*.popup_audio_item_top{*/
/*	display: -webkit-flex;
*/
/*	display: -moz-flex;
*/
/*	display: -ms-flex;
*/
/*	display: -o-flex;
*/
/*	display: flex;
*/
/*	margin-bottom: 12px;
*/
/*}*/

/*.popup_audio_item_play_btn{*/
/*	margin-right: 16px;
*/
/*	cursor: pointer;
*/
/*}*/

/*.popup_audio_item_info{*/
/*	display: -webkit-flex;
*/
/*	display: -moz-flex;
*/
/*	display: -ms-flex;
*/
/*	display: -o-flex;
*/
/*	display: flex;
*/
/*	justify-content: space-between;
*/
/*}*/

/*.popup_audio_item_date{*/
/*	font-size: 12px;
*/
/*	letter-spacing: -0.03em;
*/
/*	color: $fadeColor;
*/
/*}*/

/*.popup_audio_item_time{*/
/*	font-weight: bold;
*/
/*	font-size: 13px;
*/
/*	line-height: 16px;
*/
/*	text-align: center;
*/
/*	letter-spacing: -0.02em;
*/
/*	color: $submenuColor;
*/
/*}*/

/*.popup_audio_item_name{*/
/*	font-weight: 600;
*/
/*	font-size: 16px;
*/
/*	letter-spacing: 0.01em;
*/
/*	color: $defaultTextColorAlpha6;
*/
/*}*/

/*.popup_audio_item_graph img{*/
/*	width: 100%;
*/
/*}*/

/*.popup_audio_item_bottom{*/
/*	display: -webkit-flex;
*/
/*	display: -moz-flex;
*/
/*	display: -ms-flex;
*/
/*	display: -o-flex;
*/
/*	display: flex;
*/
/*	justify-content: space-between;
*/
/*	-ms-align-items: center;
*/
/*	align-items: center;
*/
/*}*/

/*.popup_audio_item_check input{*/
/*	display: none;
*/
/*}*/

/*.popup_audio_item_check label{*/
/*	display: block;
*/
/*	width: 16px;
*/
/*	height: 16px;
*/
/*	background-color: $whiteColor;
*/
/*	border: 1px solid $liteGreyColor;
*/
/*	border-radius: 4px;
*/
/*	cursor: pointer;
*/
/*}*/

/*.popup_audio_item_check input:checked + label{*/
/*	background-image: url(/img/profile_menu/popup-checked.svg);
*/
/*	background-repeat: no-repeat;
*/
/*	background-position: center;
*/
/*	background-color: $primaryLiteColor;
*/
/*	border-color: $primaryLiteColor;
*/
/*}*/

/*.playing.upload_popup_tabs_content_audio_item{*/
/*	background-color: $background3Color;
*/
/*}*/

/*.playing .popup_audio_item_date{*/
/*	color: $whiteColor;
*/
/*}*/

/*.playing .popup_audio_item_name{*/
/*	color: $stormGreyColor;
*/
/*}*/

/*.playing .popup_audio_item_time{*/
/*	color: $whiteColor;
*/
/*}*/

/*.fancybox-is-open .fancybox-bg{*/
/*	background: $fadeColor;
*/
/*}*/

/*.add_file_popup_wrap.fancybox-content{*/
/*	padding: 0;
*/
/*	padding-bottom: 16px;
*/
/*}*/

/*.popup_video.fancybox-content{*/
/*	padding: 0;
*/
/*}*/

/*.add_file_popup_wrap{*/
/*	width: 343px;
*/
/*	border-radius: 8px;
*/
/*	display: none;
*/
/*}*/

/*.popup_add_file_head{*/
/*	height: 44px;
*/
/*	font-weight: bold;
*/
/*	font-size: 16px;
*/
/*	text-align: center;
*/
/*	color: $whiteColor;
*/
/*	background-color: $accentLeftColor;
*/
/*	line-height: 43px;
*/
/*	margin-bottom: 20px;
*/
/*}*/

/*.popup_add_file_row{*/
/*	margin: 0 20px;
*/
/*	min-height: 42px;
*/
/*	margin-bottom: 9px;
*/
/*}*/

/*.popup_add_file_row span{*/
/*	font-weight: bold;
*/
/*	font-size: 14px;
*/
/*	text-align: center;
*/
/*	color: $accentLeftColor;
*/
/*}*/

/*.popup_add_file_row input[type="text"]{*/
/*	display: block;
*/
/*	width: 100%;
*/
/*	border: 0.5px solid $greyColorBorderAlpha4;
*/
/*	height: 42px;
*/
/*	outline: none;
*/
/*	padding: 0 11px;
*/
/*	margin-top: 6px;
*/
/*	font-size: 12px;
*/
/*}*/

/*.popup_add_file_row input[type="text"]::placeholder{*/
/*	font-size: 12px;
*/
/*	color: $defaultTextColorAlpha5;
*/
/*}*/

/*.popup_add_file_row.oversize{*/
/*	font-weight: 600;
*/
/*	font-size: 12px;
*/
/*	color: $defaultTextColor;
*/
/*	text-align: center;
*/
/*	background-color: $counterAndErrorColor;
*/
/*	line-height: 42px;
*/
/*}*/

/*.popup_add_file_row.oversize span{*/
/*	font-weight: normal;
*/
/*	color: $defaultTextColor;
*/
/*}*/

/*.popup_add_file_row .popup_bottom_upload_btn{*/
/*	margin: 0 auto;
*/
/*	margin-top: 20px;
*/
/*	display: block;
*/
/*}*/

/*.upload_btn input[type=file]{*/
/*	display: none;
*/
/*}*/

/*.popup_add_file_row.uploaded{*/
/*	display: -webkit-flex;
*/
/*	display: -moz-flex;
*/
/*	display: -ms-flex;
*/
/*	display: -o-flex;
*/
/*	display: flex;
*/
/*	-ms-align-items: center;
*/
/*	align-items: center;
*/
/*}*/

/*.popup_uploaded_info{*/
/*	margin-left: 25px;
*/
/*	font-weight: 600;
*/
/*	font-size: 14px;
*/
/*	color: $accentRightColor;
*/
/*}*/

/*.popup_uploaded_info span{*/
/*	color: $defaultTextColorAlpha6;
*/
/*	font-weight: normal;
*/
/*	font-size: 12px;
*/
/*}*/

/*.popup_add_file_upload_btn{*/
/*	margin: 0 auto;
*/
/*	margin-top: 20px;
*/
/*	background: linear-gradient(90.35deg, $accentLeftColor 0%, $accentRightColor 100%);
*/
/*	border-radius: 4px;
*/
/*	height: 42px;
*/
/*	width: 116px;
*/
/*	font-weight: bold;
*/
/*	font-size: 14px;
*/
/*	text-align: center;
*/
/*	color: $whiteColor;
*/
/*	line-height: 42px;
*/
/*	cursor: pointer;
*/
/*}*/

/* .fancybox-close-small svg{*/
/*	fill: $whiteColor;
*/
/*	stroke: $whiteColor;
*/
/*	stroke-width: $whiteColor;
*/
/*	color: $whiteColor;
*/
/* }*/

/* .popup_video{*/
/* 	display: none;
*/
/* 	max-width: 100%;
*/
/* 	background-color: transparent;
*/
/* }*/

/* .popup_video video{*/
/* 	display: block;
*/
/* 	margin: auto;
*/
/* 	max-width: 100%;
*/
/* }*/

/* .fancybox-bg{*/
/* 	background-color: transparent;
*/
/* }*/

/* .popup_complaint{*/
/* 	width: 343px;
*/
/* 	padding: 0;
*/
/* 	border-radius: 8px;
*/
/* 	display: inline-block;
*/
/* }*/

/* .reason_label{*/
/* 	display: block;
*/
/* 	font-size: 14px;
*/
/*	color: $accentLeftColor;
*/
/*	margin-top: 6px;
*/
/*	margin-bottom: 6px;
*/
/* }*/

/*.reason_textarea{*/
/* 	display: block;
*/
/* 	width: 100%;
*/
/* 	height: 188px;
*/
/* 	border: 0.5px solid $greyColorBorderAlpha4;
*/
/*	border-radius: 4px;
*/
/*	resize: none;
*/
/*	padding: 10px;
*/
/*	font-family: "Roboto", sans-serif;
*/
/*}*/

/*.complain_file_inp{*/
/*	display: none;
*/
/*}*/

/*.complain_file_label{*/
/* 	display: block;
*/
/* 	width: 100%;
*/
/* 	height: 42px;
*/
/* 	font-size: 14px;
*/
/* 	line-height: 42px;
*/
/*	text-align: center;
*/
/*	color: $accentLeftColor;
*/
/*	border: 0.5px dashed $greyColorBorderAlpha4;
*/
/*	border-radius: 4px;
*/
/*	cursor: pointer;
*/
/*	margin-top: 9px;
*/
/*}*/

/*.complain_file_label img{*/
/*	vertical-align: middle;
*/
/*	margin-right: 5px;
*/
/*}*/

/*.popup_complaint .popup_add_file_row{*/
/*	margin-bottom: 20px;
*/
/*}*/

/*.send_complaint_btn{*/
/*	width: 116px;
*/
/*	height: 42px;
*/
/*	font-weight: bold;
*/
/*	font-size: 14px;
*/
/*	line-height: 42px;
*/
/*	text-align: center;
*/
/*	color: $whiteColor;
*/
/*	background: linear-gradient(90deg, $accentLeftColor 0%, $accentRightColor 100%);
*/
/*	border-radius: 4px;
*/
/*	margin-left: auto;
*/
/*	cursor: pointer;
*/
/*}*/

/*.c4_top_close{*/
/*	display: none;
*/
/*	width: 30px;
*/
/*	height: 30px;
*/
/*	background-image: url(/img/profile_menu/cross.svg);
*/
/*	-webkit-background-size: 26px 26px;
*/
/*	background-size: 20px 20px;
*/
/*	background-position: center;
*/
/*	position: absolute;
*/
/*	right: 10px;
*/
/*	top: 10px;
*/
/*	background-repeat: no-repeat;
*/
/*	cursor: pointer;
*/
/*}*/

/*.mob_head{*/
/*	display: none;
*/
/*	padding: 10px 15px;
*/
/*	background: linear-gradient(91.43deg, $accentLeftColor 0%, $accentRightColor 100%);
*/
/*	justify-content: space-between;
*/
/*	-ms-align-items: center;
*/
/*	align-items: center;
*/
/*}*/

/*.head_logo{*/
/*	display: -webkit-flex;
*/
/*	display: -moz-flex;
*/
/*	display: -ms-flex;
*/
/*	display: -o-flex;
*/
/*	display: flex;
*/
/*}*/

/*.head_logo img:nth-child(2) {*/
/*	width: 60px;
*/
/*	display: block;
*/
/*	margin-left: 6px;
*/
/*	transition: .5s;
*/
/*}*/

/*.left_menu_btn span{*/
/*	display: block;
*/
/*	background-color: $whiteColor;
*/
/*	width: 40px;
*/
/*	height: 6px;
*/
/*	margin-bottom: 4px;
*/
/*	border-radius: 5px;
*/
/*}*/

/*.left_menu_btn span:last-child{*/
/*	margin-bottom: 0;
*/
/*}*/

/*.head_empty{*/
/*	width: 44px;
*/
/*	height: 44px;
*/
/*	display: flex;
*/
/*	justify-content: flex-end;
*/
/*}*/

/*.c1_close{*/
/*	display: none;
*/
/*	width: 30px;
*/
/*	height: 30px;
*/
/*	background-image: url(/img/profile_menu/cross_white.svg);
*/
/*	-webkit-background-size: 26px 26px;
*/
/*	background-size: 20px 20px;
*/
/*	background-position: center;
*/
/*	position: absolute;
*/
/*	right: -40px;
*/
/*	top: 10px;
*/
/*	background-repeat: no-repeat;
*/
/*	cursor: pointer;
*/
/*	visibility: hidden;
*/
/*}*/
/*.c1_close1{*/
/*	display: none;
*/
/*	width: 30px;
*/
/*	height: 30px;
*/
/*	background-image: url(/img/profile_menu/cross_white.svg);
*/
/*	-webkit-background-size: 26px 26px;
*/
/*	background-size: 20px 20px;
*/
/*	background-position: center;
*/
/*	position: absolute;
*/
/*	right: -40px;
*/
/*	top: 10px;
*/
/*	background-repeat: no-repeat;
*/
/*	cursor: pointer;
*/
/*	visibility: hidden;
*/
/*}*/

/*.c2_close{*/
/*	display: none;
*/
/*	width: 30px;
*/
/*	height: 30px;
*/
/*	background-image: url(/img/profile_menu/cross_white.svg);
*/
/*	-webkit-background-size: 26px 26px;
*/
/*	background-size: 20px 20px;
*/
/*	background-position: center;
*/
/*	position: absolute;
*/
/*	right: -40px;
*/
/*	top: 10px;
*/
/*	background-repeat: no-repeat;
*/
/*	cursor: pointer;
*/
/*	visibility: hidden;
*/
/*}*/
