@import "@sitestyles";

.tutorial_step_illustration {
  width: 100%;
  height: 262px;
  margin-bottom: 56px;
  background: $tutorialSecondaryBackground;
  border: 1px solid $tutorialPrimaryBorderColor;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  overflow: hidden;

  @media screen and (max-width: 540px) {
    height: 190px;
  }
}

.step_select {
  width: 100%;
  height: 100%;
  position: relative;
  gap: 6px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
  top: 12px;
  left: 19px;
  overflow: hidden;

  @media screen and (max-width: 540px) {
    top: 16px;
    left: 0;
    width: 248px;
    margin: auto;
    padding: 12px;
    background-color: $tutorialPopupBackground;
    border-radius: 7px;
  }
}

.step_select_buttons {
  width: 100%;
  gap: 5px;
  justify-content: center;
  align-items: center;
  display: flex;

  @media screen and (max-width: 540px) {
    display: none;
  }
}

.step_select_button {
  width: 83px;
  height: 20px;
  gap: 5px;
  background-color: $tutorialButtonLightBackground;
  border-radius: 3px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.step_select_button_accent {
  width: 106px;
  height: 20px;
  gap: 4px;
  background-image: $tutorialButtonBackground;
  border-radius: 3px;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;
  display: flex;
}

.step_select_button_icon {
  svg {
    width: 11px;
    height: 11px;
    fill: #FFFFFF;
  }
}

.step_select_button .step_select_button_icon svg {
  fill: $accentDarkColor;
}

.step_select_button_text {
  -webkit-text-fill-color: transparent;
  background-image: $tutorialButtonBackground;
  -webkit-background-clip: text;
  background-clip: text;
  font-size: 8px;
  font-weight: 600;
  line-height: 140%;
}

.step_select_button_accent_text {
  color: $tutorialLightTextColor;
  font-size: 8px;
  font-weight: 600;
  line-height: 140%;
}

.step_select_candidate {
  height: 100%;
  background-color: $tutorialPopupBackground;
  border-radius: 3px;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 4px 5px;
  display: flex;
  gap: 7px;
  box-shadow: 0 25px 50px rgba(25, 39, 60, .1);

  @media screen and (max-width: 540px) {
    box-shadow: none;
    padding: 0;
    gap: 3px;
  }
}

.step_select_main_candidate_photo {
  object-fit: cover;
  background-color: $tutorialImageBackground;
  border-radius: 2px;
  height: 246px;
  width: 147px;

  @media screen and (max-width: 540px) {
    margin-top: 73px;
    width: 179px;
    height: 258px;
  }
}

.step_select_candidate_info {
  gap: 5px;
  width: 271px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
  position: relative;
  overflow: hidden;

  @media screen and (max-width: 540px) {
    position: static;
  }
}

.step_select_candidate_header {
  width: 100%;
  gap: 8px;
  border: 0 solid $tutorialHeaderBorderColor;
  border-bottom-width: 0.5px;
  justify-content: space-between;
  align-items: flex-start;
  display: flex;

  @media screen and (max-width: 540px) {
    position: absolute;
    top: 12px;
    left: 12px;
    width: 230px;
    margin: auto;
  }
}

.step_select_candidate_details {
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-bottom: 8px;
  display: flex;
}

.step_select_candidate_name {
  color: $accentDarkColor;
  font-size: 12px;
  font-weight: 600;
  line-height: 140%;
  padding-bottom: 6px;

  @media screen and (max-width: 540px) {
    font-size: 16px;
    padding-bottom: 8px;
  }
}

.step_select_candidate_birth {
  color: $tutorialStepTextSecondaryColor;
  letter-spacing: .06em;
  font-size: 8px;
  font-weight: 600;
  padding-bottom: 3px;

  @media screen and (max-width: 540px) {
    padding-bottom: 5px;
  }
}

.step_select_candidate_details_bottom {
  display: flex;
  flex-direction: row;
  gap: 8px;

  @media screen and (max-width: 540px) {
    gap: 20px;
  }
}

.step_select_candidate_country {
  color: $tutorialStepTextSecondaryColor;
  font-size: 8px;
  font-weight: 400;
  letter-spacing: 0.2px;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  white-space: nowrap;

  @media screen and (max-width: 540px) {
    font-size: 8px;
  }

  &:before {
    content: '';
    display: block;
    width: 11px;
    min-width: 11px;
    height: 9px;
    margin-right: 4px;

    @media screen and (max-width: 540px) {
      margin-right: 4px;
    }
  }
}

.step_select_candidate_country.flag_united_states:before {
  background: url('../../../../../../img/tutorial/united-states.svg') center no-repeat;
  background-size: cover;
}

.step_select_candidate_country.flag_argentina:before {
  background: url('../../../../../../img/tutorial/argentina.svg') center no-repeat;
  background-size: cover;
}

.step_select_candidate_country.flag_philippines:before {
  background: url('../../../../../../img/tutorial/philipines.svg') center no-repeat;
  background-size: cover;
}

.step_select_candidate_country.flag_brazil:before {
  background: url('../../../../../../img/tutorial/brazil.svg') center no-repeat;
  background-size: cover;
}

.step_select_candidate_country.flag_ukraine:before {
  background: url('../../../../../../img/tutorial/ukraine.svg') center no-repeat;
  background-size: cover;
}

.step_select_candidate_country.flag_australia:before {
  background: url('../../../../../../img/tutorial/australia.svg') center no-repeat;
  background-size: cover;
}

.step_select_candidate_country.flag_france:before {
  background: url('../../../../../../img/tutorial/france.svg') center no-repeat;
  background-size: cover;
}

.step_select_candidate_country.flag_bahamas:before {
  background: url('../../../../../../img/tutorial/bahamas.svg') center no-repeat;
  background-size: cover;
}

.step_select_candidate_country.flag_china:before {
  background: url('../../../../../../img/tutorial/china.svg') center no-repeat;
  background-size: cover;
}

.step_select_candidate_id {
  color: $tutorialStepTextSecondaryColor;
  font-size: 8px;
  font-weight: 400;
  letter-spacing: 0.2px;
  text-transform: uppercase;
  white-space: nowrap;

  @media screen and (max-width: 540px) {
    font-size: 8px;
  }
}

.step_select_candidate_buttons {
  width: 132px;
  gap: 2px;
  justify-content: flex-end;
  align-items: center;
  display: flex;
  padding-top: 21px;
  position: absolute;
  right: 0;

  @media screen and (max-width: 540px) {
    position: absolute;
    right: 0;
  }
}

.step_select_candidate_button {
  width: 49px;
  gap: 3px;
  border: 0.5px solid $accentDarkColor;
  border-radius: 2px;
  justify-content: center;
  align-items: center;
  padding-top: 3px;
  padding-bottom: 3px;
  display: flex;

  &:before {
    content: '';
    display: block;
    width: 8px;
    height: 8px;

    @media screen and (max-width: 540px) {
      width: 10px;
      height: 10px;
    }
  }

  @media screen and (max-width: 540px) {
    width: 80px;
    height: 19px;
  }
}

.step_select_candidate_button_hello {

  &:before {
    background: url('../../../../../../img/say-hello-yellow-emoji.svg') center no-repeat;
    background-size: contain;
  }

  @media screen and (max-width: 540px) {
    position: absolute;
    right: -15px;
    top: 27px;
  }
}

.step_select_candidate_button_like {
  &:before {
    background: url('../../../../../../img/tutorial/tut_step_4_like_icon.svg') center no-repeat;
    background-size: contain;
  }

  @media screen and (max-width: 540px) {
    display: none;
  }
}


.step_select_candidate_button_icon {
  width: 7px;
  height: 7px;
  object-fit: cover;
  justify-content: center;
  align-items: center;
  display: flex;
}

.step_select_candidate_button_text {
  letter-spacing: -.03em;
  -webkit-text-fill-color: transparent;
  background-image: $tutorialButtonBackground;
  -webkit-background-clip: text;
  background-clip: text;
  font-size: 6px;
  font-weight: 600;
  line-height: 7px;
  white-space: nowrap;

  @media screen and (max-width: 540px) {
    font-size: 8px;
    line-height: 10px;
  }
}

.step_select_gallery {
  height: 100%;
  gap: 5px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;

  @media screen and (max-width: 540px) {
    margin-top: 73px;
    overflow: hidden;
  }
}

.step_select_gallery_big {
  height: 142px;
  width: 271px;
  gap: 5px;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;

  @media screen and (max-width: 540px) {
    flex-direction: column;
    gap: 3px;
    position: relative;
    top: -2px;
  }
}

.step_select_gallery_big_item {
  height: 141px;
  width: 90px;
  object-fit: cover;
  border-radius: 2px;

  @media screen and (max-width: 540px) {
    height: 45px;
    width: 45px;
  }
}

.step_select_gallery_small {
  width: 271px;
  gap: 5px;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
  position: relative;

  &:after {
    content: '';
    backdrop-filter: blur(1px);
    position: absolute;
    border-radius: 3px;
    bottom: 0;
    right: 0;
    height: 41px;
    width: 190px;
    z-index: 3;
  }

  @media screen and (max-width: 540px) {
    flex-direction: column;
    gap: 3px;
  }
}

.step_select_gallery_small_item {
  object-fit: cover;
  border-radius: 3px;
  width: 39px;
  height: 39px;

  @media screen and (max-width: 540px) {
    height: 45px;
    width: 45px;
  }
}

.step_select_online_status {
  width: 55px;
  height: 14px;
  gap: 2px;
  background-image: $tutorialOnlineBackground;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
  justify-content: center;
  align-items: center;
  padding: 1px 3px;
  display: flex;
  position: absolute;
  top: 31px;
  right: 35px;

  @media screen and (max-width: 540px) {
    right: -9px;
    top: 15px;
    width: 80px;
    height: 19px;
  }
}

.step_select_online_status_text {
  color: $tutorialOnlineTextColor;
  font-size: 6px;
  font-weight: 600;

  @media screen and (max-width: 540px) {
    font-size: 8px;
  }
}

.tutorial_body {
  display: grid;
  grid-row-gap: 16px;
  margin-bottom: 48px;
}

.tutorial_step_title {
  color: $accentDarkColor;
  text-align: center;
  font-size: 20px;
  font-weight: 700;
}

.tutorial_step_text {
  color: $tutorialStepTextColor;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  font-family: $mainFont;
}












