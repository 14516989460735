@import "@sitestyles";

.notify_list_wrap {
  height: 100%;
  overflow-y: auto;
}

.notify_list_wrap::-webkit-scrollbar {
  width: 3px;
  background: $notificationScrollbarBg;
  border-radius: 5px;
  margin-right: 3px;
}

.notify_list_wrap::-webkit-scrollbar-thumb {
  background: $scrollbarThumbColor;
  border-radius: 7px;
}

.notify_list {
  padding: 0 6px 19px 8px;
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 8px;

  @media screen and (max-width: 1649px) {
    padding-left: 10px;
    gap: 5px;
  }
}

.notify_item {
  background-color: $whiteColor;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(95, 95, 95, 0.24);
  display: flex;
  position: relative;
  padding: 10px 10px 16px;
  cursor: pointer;
  gap: 17px;
  min-height: 30px;
  overflow: hidden;

  @media screen and (min-width: 1200px) and (max-width: 1649px), screen and (max-width: 599px) {
    gap: 10px;
  }
}

.notify_item:hover {
  z-index: 99;
}

.notify_item.liked {
  align-items: center;
}

.liked .notify_item_clear_mask svg{
  fill: $notificationLikedTitle;
}

.liked .notify_item_name{
  color: $notificationLikedTitle;
}

.notify_item_photo_large {
  opacity: 0;
  display: none;
  transition-duration: .3s;
}

.notify_image_scale:not(.liked):hover {
  .notify_item_photo_large,
  .notify_item_photo_wrap{
    width: 110px;
    height: 178px;
    border-radius: 4px;
    opacity: 1;
    display: block;
  }
  .notify_item_photo {
    opacity: 0;
    display: none;
  }
}

.notify_item:not(.liked):hover {

  .notify_item_photo_wrap:after {
    bottom: 0;
    right: 0;
  }

  .favorite svg {
    right: 0;
  }

  @media screen and (max-width: 599px) {
    //min-height: 200px;

    .notify_item_photo_large {
      opacity: 0;
      display: none;
    }
    .notify_item_photo,
    .notify_item_photo_wrap {
      width: 72px;
      height: 72px;
      border-radius: 100%;
      opacity: 1;
      display: block;
    }

    .notify_item_photo_wrap:after {
      right: 2px;
      bottom: 2px;
    }

    .favorite svg {
      right: 2px;
      top: 2px;
    }

    .notify_item_name {
      margin-top: 25px;
    }

    .notify_item_text,
    .notify_item_letter_content {
      position: relative;
      top: 0;
      left: -78px;
      width: 224px;
    }

    .notify_item_text{
      margin-top: 16px;
    }

    .notify_item_letter_content {
      margin-top: 40px;
    }

    .notify_item_time{
      top: 11px;
      right: 30px;
    }
  }
}

.notify_content {
  display: block;
}

.notify_attachment {
  white-space: nowrap;
}

.notify_item_photo,
.notify_item_photo_wrap {
  width: 100px;
  height: 100px;
  border-radius: 100%;
  object-fit: cover;
  display: block;
  transition-duration: .3s;
  opacity: 1;

  @media screen and (max-width: 599px) {
    width: 72px;
    height: 72px;
  }
}

.notify_item_letter_content {
  position: relative;
}

.notify_item_name {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: $notificationMessageText;
  margin-bottom: 10px;

  @media screen and (max-width: 1649px) {
    font-size: 14px;
  }
}

.notify_item_text {
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  color: $notificationDefaultText;
  margin-top: 10px;

  @media screen and (max-width: 1649px) {
    margin-top: 5px;
  }
}

.notify_item.letter {
  align-items: flex-start;
}

.notify_item.message {
  background-color: $notificationMessageBg;
}

.notify_item.message.favorites {
  background-color: $notificationFavouriteBg;
}

.notify_item.blur {
  filter: blur(2px);
}

.notify_item_photo_wrap {
  position: relative;
}

.notify_item_photo_wrap:after {
  content: '';
  display: block;
  width: 11px;
  height: 11px;
  background-color: $notificationOfflineColor;
  border-radius: 100%;
  border: 2px solid $notificationMessageBg;
  position: absolute;
  right: 6px;
  bottom: 6px;
  transition-duration: .3s;

  @media screen and (max-width: 599px) {
    right: 2px;
    bottom: 2px;
    width: 10px;
    height: 10px;
  }
}

.favorite svg {
  position: absolute;
  content: '';
  display: block;
  width: 20px;
  height: 20px;
  right: 6px;
  top: 0;
  fill: $notificationFavouriteIcon;
  transition-duration: .3s;

  @media screen and (max-width: 599px) {
    right: 2px;
    width: 14px;
    height: 14px;
  }
}

.message .favorite svg {
  stroke: $notificationFavouriteBg;
}

.read .favorite svg {
  stroke: $whiteColor;
}

.liked .favorite svg {
  stroke: $notificationLikedBg;
}

.online .notify_item_photo_wrap:after {
  background-color: $onlineColor;
}

.favorites .notify_item_photo_wrap:after {
  border-color: $notificationFavouriteBg;
}

.liked .notify_item_photo_wrap:after {
  border-color: $notificationLikedBg;
}

.read .notify_item_photo_wrap:after {
  border-color: $notificationLetterBg;
}

.unread {
  display: block;
  border-radius: 4px;
  background: $notifyCounterBg;
  padding: 2px 4px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: fit-content;
  height: fit-content;
  position: absolute;
  top: 0;
  color: $notifyCounterColor;
  text-align: center;
  font-size: 12px;
  font-weight: 600;
  min-width: 21px;

  @media screen and (max-width: 599px) {
    padding: 0 4px;
  }
}

.notify_item_content {
  height: 100%;

}

.favorites.message .notify_item_name {
  color: $notificationFavouriteText;
}

.favorites .notify_item_clear_mask svg {
  fill: $notificationFavouriteText;
}

.favorites.message .notify_item_text {
  color: $notificationFavouriteText;
}

.favorites .notify_item_place {
  color: $notificationFavouriteText;
}

.favorites.message .notify_item_time {
  color: $notificationFavouriteText;
}

.favorites.message.read .notify_item_name {
  color: $notificationFavouriteText;
}

.favorites.read .notify_item_clear_mask svg {
  fill: $notificationFavouriteText;
}

.favorites.message.read .notify_item_text {
  color: $notificationFavouriteText;
}

.favorites.read .notify_item_place {
  color: $notificationFavouriteText;
}

.favorites.message.read .notify_item_time {
  color: $notificationFavouriteText;
}

.message .notify_item_text {
  color: $notificationMessageText;
  width: 170px;
  font-weight: normal;

  @media screen and (max-width: 1649px) {
    width: auto;
  }
}

.liked .notify_item_text {
  padding-left: 18px;
  position: relative;
}

.liked .notify_item_text:before {
  content: '';
  display: block;
  width: 12px;
  height: 11px;
  background-image: url(../../../../img/c4-like.svg);
  background-repeat: no-repeat;
  position: absolute;
  left: 0;
  top: 3px;
}

.viewed .notify_item_text {
  padding-left: 20px;
  position: relative;
}

.viewed .notify_item_text:before {
  content: '';
  display: block;
  width: 14px;
  height: 10px;
  background-image: url(../../../../img/c4-viwed.svg);
  background-repeat: no-repeat;
  position: absolute;
  left: 0;
  top: 4px;
}

.notify_item_letter_title {
  position: relative;
  font-weight: 500;
  font-size: 12px;
  color: $notificationMessageText;
  padding-left: 20px;
  display: flex;
  align-items: center;
}

.notify_item_letter_title:before {
  content: '';
  display: block;
  width: 15px;
  height: 12px;
  position: absolute;
  left: 0;
  top: 3px;
  mask: url(../../../../img/c4-letter.svg);
  mask-size: 15px 12px;
  background-color: $notificationMessageText;
}

.notify_item_letter_text {
  font-size: 12px;
  line-height: 1.5;
  color: $notificationMessageText;
  max-width: 153px;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 38px;
  margin-top: 4px;
}

.notify_item:hover .notify_item_letter_text {
  height: auto;
  overflow: visible;
  max-width: unset;
}

.notify_item_clear {
  position: absolute;
  top: 7px;
  right: 7px;
  cursor: pointer;
  padding: 5px;

  @media screen and (max-width: 1649px) {
    right: 2px;
    top: 2px;
  }

  @media screen and (max-width: 599px) {
    top: 3px;
    right: 3px;
  }
}

.notify_item_clear img {
  display: block;
}

.notify_item_clear_mask {
  position: absolute;
  top: 7px;
  right: 7px;
  cursor: pointer;
  width: 16px;
  height: 16px;
  border: none;

  svg {
    fill: $notificationMessageText;
    width: 100%;
    height: 100%;
  }
}

.notify_item_time {
  position: absolute;
  right: 10px;
  bottom: 10px;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: -0.03em;
  color: rgba(51, 51, 51, 0.6);

  @media screen and (max-width: 1649px) {
    right: 8px;
    bottom: 2px;
    font-size: 10px;
  }

  @media screen and (max-width: 599px) {
    bottom: 3px;
  }
}

.message .notify_item_time {
  color: $notificationMessageText;
  opacity: .8;
}

.notify_item.message.read {
  background: $notificationLetterBg;
}

.notify_item_place {
  display: none;
  font-size: 12px;
  letter-spacing: -0.03em;
  color: $notificationMessageText;
  opacity: .8;
  margin-top: 10px;
}


.notify_item_place img {
  vertical-align: middle;
  height: 12px;
  margin-bottom: 2px;
  margin-right: 5px;
}

.notify_item.message:hover .notify_item_place {
  display: block;
}

.notify_verification_title {
  color: $accentLeftColor;
  font-size: 14px;
  font-weight: 700;
}

.notify_verification_text {
  color: #4F4F4F;
  font-size: 12px;
  font-weight: 500;
}

.notify_verification_content {
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;

  @media screen and (max-width: 599px) {
    gap: 2px;
  }
}

.notify_verification {
  padding: 24px;

  @media screen and (max-width: 599px) {
    padding: 20px 10px;
  }
}

.notify_verification svg {
  height: 72px;
  width: 72px;
}


.notify_verification_progress svg,
.notify_verification_pass svg, {
  path {
    stroke: $accentLeftColor;
  }

  path:last-child {
    stroke: none;
    fill: $accentLeftColor;
  }
}

.notify_verification_complete svg, {
  path {
    stroke: $accentLeftColor;
  }
  g path {
    stroke: none;
    fill: $accentLeftColor;
  }
}

.close_btn {
  width: 15px;
  height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 12px;
  right: 16px;
  cursor: pointer;

  @media screen and (max-width: 600px) {
    top: 10px;
  }

  svg {
    fill: #818181;
    transition-duration: .3s;
  }

  &:hover,
  &:focus {
    outline: none;

    svg {
      fill: #333;
    }
  }
}



