@import "@sitestyles";

.likeher_cross_banner_photos_wrapper {
  display: flex;
  align-items: flex-end;
  height: calc(100% - 172px);
  background-color: #fff;
  padding: 33px 110px 0;
  border-radius: 16px 16px 0 0;
  position: relative;

  @media screen and (max-width: 1749px) {
    padding: 33px 70px 0;
  }

  @media screen and (max-width: 1499px) {
    height: calc(100% - 140px);
  }

  @media screen and (max-width: 1139px) {
    border-radius: 0;
    padding: 23px 27px 0;
    justify-content: center;
  }

  @media (hover: none) and (pointer: coarse) {
    border-radius: 0;
  }

  @media (hover: none) and (pointer: coarse) {
    @media screen and (max-height: 450px) and (max-width: 899px) and (min-width: 420px) {
      position: static;
      height: 100%;
      padding: 5px;
      background-color: transparent;
      z-index: 1;
      box-shadow: none;
      width: max-content;
      margin: 0 auto;
      min-width: 50%;}

    @media screen and (orientation: landscape) {
      padding: 62px 250px 0;
    }
  }


  @media screen and (max-width: 999px) {
    padding: 23px 80px 0;
    height: calc(100% - 165px);
  }

  @media screen and (max-width: 767px) {
    padding: 23px 30px 0;
  }

  @media screen and (max-width: 599px) {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    padding: 5px 5px 0;
    background-color: transparent;
    z-index: 1;
    box-shadow: none;
  }
}

.likeher_cross_banner_photos {
  display: flex;
  gap: 90px;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  margin: auto;

  @media screen and (max-width: 1139px) {
    margin: 30px 0 auto 0;
  }

  @media screen and (max-width: 1139px) and (max-height: 810px){
    width: 70%;
  }

  @media screen and (max-width: 599px) {
    margin: 0;
    width: 100%;
    height: 100%;
  }

  @media screen and (hover: none) and (pointer: coarse) and (orientation: landscape) {
    margin: auto;

    @media screen and (min-width: 1440px) {
      width: 80%;
    }
    @media screen and (min-width: 1520px) {
      width: 70%;
    }
    @media screen and (max-width: 1139px) and (max-height: 810px) {
      width: 80%;
    }
    @media screen and (max-width: 999px) {
      margin: 30px 0 auto 0;
    }
  }

}

.likeher_cross_banner_photo_item {
  width: 100%;
  height: 100%;
}

.likeher_cross_banner_photo {
  width: 100%;
  height: auto;
  border-radius: 10px;
  display: block;

  @media screen and (max-width: 599px) {
    height: 100%;
    max-width: initial;
    object-fit: cover;
    object-position: top;
    border-radius: 8px;
  }
}

.likeher_cross_banner_photo_item:first-child,
.likeher_cross_banner_photo_item:last-child {
  width: 42%;
  height: 100%;
  position: relative;
  transition: 0.3s;

  &::after {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    bottom: 0;
    left: 0;
    border-radius: 10px;
    background: linear-gradient(180deg, rgba(115, 1, 103, 0.00) 33.24%, $accentRightColor 100%);
    box-shadow: inset 0 0 30px rgb(236 236 240 / 40%);
  }

  .likeher_cross_banner_photo {
    filter: blur(1.5px);
  }

  @media screen and (max-width: 1139px) {
    max-width: 180px;
  }

  @media screen and (max-width: 599px) {
    display: none;
  }
}

.likeher_cross_banner_photo_item:nth-child(2) {
  width: 52%;
  height: auto;
  position: absolute;
  top: -48px;
  bottom: -20px;
  z-index: 2;

  &::after {
    position: absolute;
    content: '';
    width: 100%;
    height: 60%;
    bottom: -40px;
    left: 0;
    border-radius: 10px;
    background: linear-gradient(180deg, rgba(115, 1, 103, 0.00) 0%, $accentRightColor 69.12%);

    @media screen and (max-width: 1440px) {
      bottom: -26px;
    }

    @media screen and (max-width: 599px) {
      bottom: 0;
      background: linear-gradient(180deg, rgba(115, 1, 103, 0.00) 0%, $accentRightColor 110.12%);
    }
  }

  .likeher_cross_banner_content {
    position: absolute;
    width: 100%;
    height: auto;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 0 15px;

    @media screen and (max-width: 599px) {
      top: auto;
      margin-bottom: 80px;
    }
  }

  @media screen and (max-width: 1139px) {
    max-width: 240px;
    top: -42px;
  }

  @media screen and (max-width: 599px) {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    max-width: none;
  }

  @media screen and (hover: none) and (pointer: coarse) and (orientation: landscape) {
    @media screen and (min-width: 1920px) {
      width: 51%;
    }
  }
}

.likeher_cross_banner_title {
  font-size: 16px;
  font-weight: 600;
  color: $whiteColor;
  line-height: 120%;
  z-index: 2;
  text-align: center;

  span {
    text-transform: capitalize;
  }
}

.likeher_cross_banner_text {
  color: $whiteColor;
  font-size: 14px;
  font-weight: 400;
  line-height: 120%;
  z-index: 2;
  text-align: center;
}
