@import "@sitestyles";
@import "./breakpoints";

@mixin links_button {
  width: 108px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  font-weight: 500;
  font-size: 12px;
  //letter-spacing: -0.02em;
  line-height: 1;
  cursor: pointer;

  @media (max-width: $BP_middle), (max-height: $BP_middle_height) {
    width: 76px;
    height: 28px;
  }
}

.column_1 {
  width: 200px;
  background-color: #fff;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-shadow: 6px 0 12px rgba(47, 43, 99, 0.16);
  position: relative;
  z-index: 2;

  @media (max-width: $BP_middle), (max-height: $BP_middle_height) {
    width: 150px;
  }

  @media (max-width: $BP_hidden) {
    display: none;
  }
}

.c1_top {
  background-color: $background1Color;
  padding-bottom: 8px;
}

.c1_logo_wrap {
  width: 100%;
  height: 80px;
  display: flex;

  @media (max-width: $BP_middle), (max-height: $BP_middle_height) {
    height: 56px;
  }

  img {
    display: block;
    margin: auto;
    max-width: 85%;
    object-fit: contain;
    max-height: 46px;
  }
}

.c1_profile_wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  padding: 12px 0;
  box-shadow: 0 0 10px 0 #0000001A;
  border-radius: 5px;

  @media (max-width: $BP_middle), (max-height: $BP_middle_height) {
    padding: 10px 0;
  }
}

.c1_profile_photo {
  display: block;
  margin-right: 9px;
  position: relative;

  img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    object-fit: cover;
    object-position: top;

    @media (max-width: $BP_middle), (max-height: $BP_middle_height) {
      width: 50px;
      height: 50px;
    }
  }

  &.user_vip {
    img:not(.c1_profile_photo_user_vip_icon) {
      border: 2px solid #FEB930;
      box-sizing: content-box;
      padding: 2px;
    }

    .c1_profile_photo_crown_vip {
      background: #fff;
      padding: 0 2px;
      border-radius: 50%;
      position: absolute;
      left: calc(50% - 11px);
      top: -10px;

      svg {
        width: 18px;
        height: 16px;
        fill: #ffba30;
      }
    }
  }
}

.c1_profile_photo_user_vip {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  background: linear-gradient(90.32deg, #FEB930 0.31%, #FE8730 115.03%);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  &:before {
    content: '';
    display: block;
    position: absolute;
    width: calc(100% + 6px);
    height: calc(100% + 6px);
    border: 2px solid #FEB930;
    border-radius: 50%;
  }

  svg {
    width: 32px;
    height: 32px;
    fill: #FFFFFF;
  }
}

.c1_profile_links {
  display: flex;
  flex-direction: column;
  gap: 4px;

  .profile {
    @include links_button;

    & {
      border: 1px solid $buttonBorderColor;
      color: $buttonTitleTopMenu;
      background-color: $backgroundTopMenuButtonColor;
      transition-duration: .3s;
    }

    &:hover,
    &:focus {
      color: $backgroundTopMenuButtonColor;
      background-color: $buttonTitleTopMenu;

      svg {
        fill: $backgroundTopMenuButtonColor;
      }
    }

    b {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 16px;
      height: 16px;
      background-color: #eb5757;
      font-weight: 500;
      font-size: 12px;
      line-height: 1;
      color: #fff;
      border-radius: 100%;
      margin-left: 7px;
    }

    svg {
      margin-right: 9px;
      width: 16px;
      height: 16px;
      fill: $buttonTitleTopMenu;
      transition-duration: .3s;

      @media (max-width: $BP_middle), (max-height: $BP_middle_height) {
        display: none;
      }
    }
  }

  .credits {
    @include links_button;

    & {
      border: 1px solid $buttonBorder2Color;
      background-color: $background2TopMenuButtonColor;
      color: $buttonTitle2TopMenu;
      transition-duration: .3s;
    }

    svg {
      margin-right: 9px;
      width: 16px;
      height: 16px;
      fill: $buttonTitle2TopMenu;
      transition-duration: .3s;

      @media (max-width: $BP_middle), (max-height: $BP_middle_height) {
        display: none;
      }
    }

    &:hover,
    &:focus {
      color: $background2TopMenuButtonColor;
      background-color: $buttonTitle2TopMenu;

      svg {
        fill: $background2TopMenuButtonColor;
      }
    }
  }
}

.add_credits_btn {
  height: 51px;
  background: $backgroundButtonColor;
  border: 1px solid transparent;
  box-shadow: 0 3px 3px rgba(0, 0, 0, .05);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: 600;
  font-size: 18px;
  letter-spacing: -.03em;
  cursor: pointer;
  margin-top: 8px;
  width: 100%;
  transition-duration: .3s;
  position: relative;

  @media (max-width: $BP_middle), (max-height: $BP_middle_height) {
    height: 44px;
    font-size: 15px;
    font-weight: 500;
  }

  svg {
    margin-right: 12px;
    max-height: 28px;
    max-width: 28px;
    fill: #fff;
    transition-duration: .3s;

    @media (max-width: $BP_middle), (max-height: $BP_middle_height) {
      margin-right: 10px;
      max-height: 22px;
      max-width: 22px;
    }
  }

  &:hover,
  &:focus {
    color: $addCreditsHoverColor;
    background-image: none;
    background-color: $addCreditsBackgroundColor;
    border: 1px solid $addCreditsHoverColor;

    &:after {
      background: $addCreditsHoverColor;
      opacity: 0.6;
    }

    svg {
      fill: $addCreditsHoverColor;
    }
  }
}

@mixin holiday-add-credits-btn($name, $image-path) {
  .add_credits_btn.#{$name} {
    &::before {
      content: '';
      display: block;
      height: 100%;
      width: 100%;
      background-image: url(#{$image-path});
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}

@include holiday-add-credits-btn('halloween', '../../../img/holiday/halloween_add_credit_button.svg');
@include holiday-add-credits-btn('lunar_new_year', '../../../img/holiday/lunar_new_year_add_credit_button.svg');
@include holiday-add-credits-btn('thanksgiving_day', '../../../img/holiday/thanksgiving_day_add_credit_button.svg');
@include holiday-add-credits-btn('christmas', '../../../img/holiday/christmas_add_credit_button.svg');
@include holiday-add-credits-btn('woman_day', '../../../img/holiday/woman_day_add_credit_button.svg');
@include holiday-add-credits-btn('valentine_day', '../../../img/holiday/valentine_day_add_credit_button.svg');
@include holiday-add-credits-btn('independence_day', '../../../img/holiday/independence_day_add_credit_button.svg');
@include holiday-add-credits-btn('easter', '../../../img/holiday/easter_add_credit_button.svg');