@import '@sitestyles';

:global(.column-3) .quiz_notify_wrap{
  width: calc(100% - 22px);
  bottom: 10px;
  z-index: 4;
}

.quiz_notify_wrap{
  position: absolute;
  bottom: 75px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #FFFFFF;
  border: 1px solid #CCD8E7;
  border-radius: 8px 8px 0 8px;
  padding: 16px;
  width: calc(100% - 12px);
  z-index: 99;

  @media screen and (max-width: 599px){
    bottom: 100px;
  }

  .title{
    font-weight: bold;
    font-size: 14px;
    color: #474F5C;
    margin-bottom: 4px;
  }

  .text{
    font-size: 12px;
    color: #474F5C;
    padding-right: 20px;
    margin-bottom: 12px;
  }

  .button{
    width: 100%;
    height: 37px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 22px;
    background-color: $primary2Color;
    border-radius: 4px;

    font-weight: 500;
    font-size: 12px;
    letter-spacing: -0.02em;
    color: #FFFFFF;
    cursor: pointer;

    img{
      width: 20px;
      margin-left: 10px;
    }

    svg {
      width: 20px;
      height: 15px;
      margin-left: 10px;
      fill: #FFFFFF;
      fill-opacity: 1;
    }
  }

  .close{
    width: 18px;
    height: 18px;
    position: absolute;
    right: 5px;
    top: 5px;
    background-image: url(../../../../img/c3lh-item-big-close-blue.svg);
    background-repeat: no-repeat;
    background-size: 13px;
    background-position: center;
    cursor: pointer;
  }
}