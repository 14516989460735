@import "@sitestyles";

.media_list_wrap_slide{
  width: 100%;
  position: absolute;
  top: 100%;
  left: 0;
  max-height: 0;
  transition: 1s;
  overflow: hidden;
  z-index: 6;
}

.media_list_wrap_slide.open{
  max-height: 800px;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.1);
  border-radius: 0 0 8px 8px;
}

.media_list_wrap{
  padding: 0 24px 14px 29px;
  background-color: #fff;
}

.media_list_head{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 12px;
  border-top: 1px solid #C2CFE0;
  margin-bottom: 15px;
}

.media_list_head_categories{
  display: flex;
  align-items: center;
}

.media_list_head_category_btn{
  height: 28px;
  padding: 0 12px;
  border-radius: 5px;
  margin-right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #5C5C5C;
  cursor: pointer;
  transition: .3s;
}

.media_list_head_category_btn:last-child{
  margin-right: 0;
}

.media_list_head_category_btn.active{
  background-color: $mediaListTabActiveBg;
  box-shadow: 0 3px 5px rgba(14, 4, 143, 0.15);
  font-weight: 500;
  color: $mediaListTabActiveColor;
  cursor: default;
}

.media_list_head_type{
  display: flex;
  align-items: center;
  border: 1px solid #E0EAF5;
  border-radius: 4px;
  position: relative;
}

.media_list_head_type:before{
  content: '';
  display: block;
  width: 46px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: $mediaListSwitcherActiveBg;
  border-radius: 4px;
  transition: .3s;
}

.media_list_head_type.paid:before{
  width: 56px;
  left: 47px;
}

.media_list_head_type_btn{
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  cursor: pointer;
  transition: .3s;
  position: relative;
  font-size: 12px;
  color: rgba(51, 51, 51, 0.8);
}

.media_list_head_type_btn:first-child{
  width: 46px;
}

.media_list_head_type:not(.paid) .media_list_head_type_btn:first-child{
  font-weight: 500;
  color: #333;
}

.media_list_head_type_btn:last-child{
  width: 56px;
}

.media_list_head_type.paid .media_list_head_type_btn:last-child{
  font-weight: 500;
  color: #333;
}

.media_list_scroll{
  max-height: 326px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-top: 10px;
  margin-right: -13px;
  padding-right: 10px;
  display: flex;
  flex-wrap: wrap;
}

.media_list_scroll::-webkit-scrollbar {
  width: 3px;
  background: #F4F4F4;
  border-radius: 3px;
}

.media_list_scroll::-webkit-scrollbar-thumb {
  background: $scrollbarThumbColor;
  border-radius: 3px;
}

.media_list{
  display: flex;
  flex-wrap: wrap;
}

.media_list_month{
  width: 100%;
  font-weight: 600;
  font-size: 12px;
  color: #446077;
  margin-bottom: 11px;
  clear: both;
}

.media_list_item + .media_list_month{
  margin-top: 30px;
}

.media_list_item{
  float: left;
  height: 120px;
  margin-right: 10px;
  cursor: pointer;
  position: relative;
  margin-bottom: 10px;
}

.media_list_item video{
  height: 100%;
  border-radius: 8px;
  min-width: 120px;
  object-fit: cover;
  object-position: top;
}

.media_list_item.video > img{
  height: 100%;
  border-radius: 8px;
  min-width: 120px;
  object-fit: cover;
  object-position: top;
}

.media_list_item.video > img.media_list_item_video_play,
.media_list_item.video > .media_list_item_video_play {
  all: initial;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}

.media_list_item.video > div.media_list_item_video_play svg {
  width: 51px;
  height: 51px;
  fill: #FFFFFF;
  filter: drop-shadow(0px 0 3px rgba(0, 0, 0, 0.4));
}

.media_list_head_mob_title svg {
  width: 10px;
  height: 16px;
  vertical-align: middle;
  fill: #bdcbdb;
  stroke: #bdcbdb;
  stroke-width: 0.8;
  margin-left: 4px;
}

.media_list_item.audio{
  width: 296px;
  background-color: $mediaListTabActiveBg;
  border: 1px solid rgba(0, 49, 123, 0.1);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 21px 18px 18px 18px;
}

.media_list_item_unpaid_wrap + .media_list_item_audio_content{
  //margin-top: auto;
}

.media_list_item_audio_text{
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-bottom: auto;

}

.media_list_item_img{
  height: 100%;
  width: auto;
  border-radius: 8px;
  min-width: 120px;
  object-fit: cover;
  object-position: top;
}

.media_list_item.unpaid .media_list_item_img{
  min-width: 99px;
  object-fit: cover;
  object-position: top;
}

.media_list_item_unpaid_wrap{
  position: absolute;
  right: 6px;
  top: 6px;
  z-index: 3;
}

.media_list_item_unpaid_main{
  width: 87px;
  height: 26px;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: rgba(51, 51, 51, 0.8);
}

.media_list_item_unpaid_main img{
  margin-right: 7px;
}

.media_list_item_unpaid_main svg{
  margin-right: 7px;
  width: 14px;
  height: 16px;
  fill: #5b5b5c;
}

.media_list_item_unpaid_tip{
  width: 186px;
  height: 67px;
  position: absolute;
  top: calc(50%);
  left: calc(100% + 8px);
  transform: translateY(-50%);
  background: rgba(255,255,255,.95);
  border: 1px solid #E9EBEE;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  line-height: 150%;
  color: rgba(51, 51, 51, 0.8);
  opacity: 0;
  transition: .3s;
  z-index: 2;
  pointer-events: none;
}

.media_list_item_unpaid_tip.left{
  left: unset;
  right: calc(100% + 8px);
}

.media_list_item_unpaid_tip:before{
  content: '';
  display: block;
  width: 13px;
  height: 13px;
  background: linear-gradient(45deg, #E9EBEE 50%, transparent 50%);
  position: absolute;
  left: 0;
  top: 50%;
  border-radius: 0 2px 0 2px;
  transform: translate(-50%, -50%) rotate(45deg);
}

.media_list_item_unpaid_tip.left:before{
  left: unset;
  right: 0;
  background: linear-gradient(45deg, transparent 50%, #E9EBEE 50%);
  transform: translate(50%, -50%) rotate(45deg);
}

.media_list_item_unpaid_tip:after{
  content: '';
  display: block;
  width: 11px;
  height: 11px;
  background: linear-gradient(45deg, #fff 50%, transparent 50%);
  position: absolute;
  left: 0;
  top: 50%;
  border-radius: 0 2px 0 1px;
  transform: translate(-50%, -50%) rotate(45deg);
}

.media_list_item_unpaid_tip.left:after{
  left: unset;
  right: 0;
  background: linear-gradient(45deg, transparent 50%, #fff 50%);
  transform: translate(50%, -50%) rotate(45deg);
}

.media_list_item_unpaid_tip span{
  font-weight: bold;
  display: block;
}

.media_list_head_mob_title{
  display: none;
}

.mob_media_paid_info{
  border-top: 1px solid rgba(194, 207, 224, 0.6);
  display: flex;
  align-items: center;
  padding-top: 14px;
  margin-top: 5px;
}

.mob_media_paid_info svg {
  margin-right: 6px;
  width: 14px;
  height: 16px;
  fill: #446178;
}

.mob_media_paid_info_img{
  margin-right: 6px;
}

.mob_media_paid_info_title{
  font-weight: 500;
  font-size: 12px;
  color: #446077;
  margin-right: 4px;
}

.mob_media_paid_info_text{
  font-size: 12px;
  color: rgba(51, 51, 51, 0.8);
}

.mob_media_paid_info_text span:not(.mob_media_paid_info_text_mob){
  font-weight: bold;
}

@media screen and (max-width: 1499px){
  .media_list_wrap{
    padding: 0 21px 11px;
  }

  .mob_media_paid_info{
    padding-top: 11px;
  }

  .media_list_scroll{
    max-height: 260px;
  }

  .media_list_item{
    height: 90px;
    margin-right: 6px;
    margin-bottom: 6px;
  }

  .media_list_item_unpaid_main img{
    width: 12px;
  }

  .media_list_item_unpaid_main svg{
    width: 12px;
    height: 14px;
  }

  .media_list_item_unpaid_tip{
    width: 160px;
    height: 56px;
    font-size: 12px;
  }

  .media_list_item.audio{
    width: 234px;
    padding: 12px 12px 12px 12px;
  }

  .media_list_item_audio_text{
    font-size: 12px;
  }

  .media_list_head_category_btn{
    font-size: 12px;
  }

  .media_list_head_type_btn{
    height: 28px;
    font-size: 12px;
  }

  .media_list_month{
    margin-bottom: 9px;
  }

  .media_list_item + .media_list_month{
    margin-top: 25px;
  }
}

@media screen and (max-height: 600px){
  .media_list_scroll{
    max-height: 220px;
  }
}

@media screen and (max-height: 560px){
  .media_list_scroll{
    max-height: 120px;
  }
}

@media (hover: none) and (pointer: coarse){
  @media screen and (min-height: 800px){
    .media_list_scroll{
      max-height: 560px;
    }
  }
}

@media screen and (max-width: 1199px){
  .media_list_wrap{
    padding-bottom: 11px;
  }
}

@media screen and (max-width: 599px){
  .media_list_wrap{
    padding: 0 11px 11px;
  }

  .media_list_scroll{
    max-height: calc(100vh - 350px);
    max-height: calc(calc(var(--vh, 1vh) * 100) - 350px);
    margin-right: -5px;
    padding-right: 2px;
    display: block;
  }

  .media_list_item + .media_list_month{
    margin-top: 0;
    padding-top: 25px;
  }

  .media_list_item{
    width: calc((100% / 3) - 4px);
    height: 101px;
    margin-right: 4px;
  }

  .media_list_item video,
  .media_list_item.video > img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    min-width: unset;
  }

  .media_list_item.audio{
    padding: 33px 5px 5px 5px;
    width: calc((100% / 3) - 4px);
    margin-right: 4px;
  }

  .media_list_item_img{
    width: 100%;
    height: 100%;
    min-width: unset;
  }

  .media_list_item_audio_content > img{
    height: 37px;
    object-fit: none;
  }

  .media_list_head_category_btn{
    padding: 0 10px;
    margin-right: 0;
  }

  .media_list_head{
    margin-bottom: 5px;
  }

  .media_list_item_unpaid_wrap{
    right: 2px;
    top: 4px;
  }

  .mob_media_paid_info{
    margin-top: 4px;
  }
}

@media screen and (max-width: 360px){
  .media_list_head.open ~ .media_list_scroll{
    min-height: 100px;
  }

  .media_list_head_mob_title{
    display: flex;
    align-items: center;
    width: 114px;
    height: 28px;
    padding: 0 11px;
    position: relative;
    background-color: #EFF5FE;
    box-shadow: 0px 3px 5px rgba(14, 4, 143, 0.15);
    border-radius: 5px;
    font-weight: 500;
    font-size: 12px;
    color: #093A83;
  }

  .media_list_head_mob_title.open{
    border-radius: 5px 5px 0 0;
  }

  .media_list_head_mob_title_arrow{
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    display: block;
    transition: .3s;
  }

  .media_list_head_mob_title.open .media_list_head_mob_title_arrow{
    transform: translateY(-50%) rotate(180deg);
  }

  .media_list_head{
    position: relative;
  }

  .media_list_head_categories{
    width: 114px;
    display: none;
    position: absolute;
    left: 0;
    top: calc(100% - 1px);
    background-color: #EFF5FE;
    z-index: 9;
    border-radius: 0 0 5px 5px;
    box-shadow: 0px 3px 5px rgba(14, 4, 143, 0.15);
    padding: 5px 0;
  }

  .media_list_head_mob_title.open + .media_list_head_categories{
    display: block;
  }

  .media_list_head_category_btn{
    justify-content: flex-start;
    height: 32px;
  }

  .media_list_head_category_btn.active{
    background-color: transparent;
    font-weight: normal;
    box-shadow: none;
    color: #5c5c5c;
  }

  .media_list_item{
    height: 86px;
  }

  .media_list_item.audio{
    padding-top: 10px;
  }

  .media_list + .media_list_month{
    margin-top: 24px;
  }

  .mob_media_paid_info_text_mob{
    display: none;
  }
}

@media (hover: none) and (pointer: coarse){
  @media screen and (max-height: 400px){
    .media_list_scroll{
      max-height: calc(100vh - 180px);
      max-height: calc(calc(var(--vh, 1vh) * 100) - 180px);
    }
  }
}
