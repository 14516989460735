.user_avatar {
    display: inline-block;
    width: 40px;
    height: 40px;
    padding-top: 8px;
    border-radius: 60px;
    text-align: center;
    color: white;
    font-size: 20px;
    margin-left: 10px;
}