@import '@sitestyles';

.forbidden_page {
  width: 100%;
  height: 100svh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  background: $forbiddenPageBg;
  padding: 24px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;

  @media screen and (max-width: 1400px) {
    overflow-y: auto;
  }

  @media screen and (max-width: 767px) {
    padding: 16px;
  }
}

.forbidden_logo {
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }

  @media screen and (max-width: 767px) {
    height: 40px;
  }
}

.forbidden_content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  max-width: 442px;
  text-align: center;
  position: relative;


  &:before {
    content: '';
    display: block;
    width: 186px;
    height: 222px;
    mask: url("../../img/forbidden_map.svg") center no-repeat;
    mask-size: contain;
    background: $forbiddenSVGMapColor;
    z-index: 3;
    position: absolute;
    top: -4px;
    left: 50%;
    transform: translateX(-50%);

    @media screen and (max-width: 767px) {
      width: 123px;
      height: 180px;
      top: -19px;
    }
  }

  &:after {
    content: '';
    display: block;
    width: 264px;
    height: 248px;
    mask: url("../../img/forbidden_shadow.svg") center no-repeat;
    mask-size: contain;
    background: $forbiddenSVGShadowColor;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 26px;

    @media screen and (max-width: 767px) {
      width: 170px;
      height: 160px;
    }
  }

  @media screen and (max-width: 420px) {
    max-width: 90%;
  }
}

.forbidden_map_bg {
  display: block;
  width: 186px;
  height: 213px;
  mask: url("../../img/forbidden_map_bg.svg") center no-repeat;
  mask-size: contain;
  background: $forbiddenSVGMapBackground;
  margin: 0 auto 50px;
  z-index: 2;

  @media screen and (max-width: 767px) {
    width: 118px;
    height: 142px;
    margin-bottom: 32px;
  }
}

.forbidden_text {
  font-size: 20px;
  line-height: 28px;
  font-weight: 500;
  text-align: center;
  color: $forbiddenTextColor;

  span {
    text-transform: capitalize;
  }

  @media screen and (max-width: 767px) {
    font-size: 16px;
    line-height: 22px;
  }
}

.forbidden_title {
  font-size: 26px;
  line-height: 36px;
  font-weight: 600;
  color: $forbiddenTextColor;
  text-align: center;
  position: relative;

  @media screen and (max-width: 767px) {
    font-size: 20px;
    line-height: 28px;
    max-width: 300px;
  }
}

.forbidden_links {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.forbidden_links li {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  color: $forbiddenTextColor;
  text-transform: uppercase;
  cursor: pointer;
  transition-duration: .3s;

  &:hover,
  &:focus {
    opacity: .8;
  }

  &:after {
    content: '/';
    margin-left: 8px;
  }

  @media screen and (max-width: 767px) {
    font-size: 12px;
    line-height: 16px;
  }
}

.forbidden_links li:last-child:after {
  display: none;
}