.custom_gallery .image-gallery-content .image-gallery-slide .image-gallery-image {
    max-height: 80vh;
    border-radius: 10px;

    @media screen and (max-height: 789px) and (min-width: 789px)   {
        max-height: 70vh;
        border-radius: 10px;
    }

    @media screen and (max-width: 789px) {
        max-height: 70vh;
        width: auto;
        max-width: calc(100vw - 38px);
        border-radius: 10px;
    }
}

.custom_gallery .image-gallery-left-nav, .custom_gallery .image-gallery-right-nav {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background: rgba(0, 0, 0, 0.15);
    padding: 0;
    transition-duration: .3s;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
        display: none;
    }

    &:before{
        content: '';
        display: block;
        width: 24px;
        height: 24px;
        mask-size: contain;
        mask-repeat: no-repeat;
        mask-position: center;
        background: #fff;
        transition-duration: .3s;
    }

    &:hover {
        background: #fff;
        box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.15);

        &:before {
            background: #6766DC;
        }
    }
}

.custom_gallery .image-gallery-right-nav {
    right: 20px;

    &:before{
        mask: url("../../../../img/icon_arrow_right.svg");
    }
}

.custom_gallery .image-gallery-left-nav {
    left: 20px;

    &:before{
        mask: url("../../../../img/icon_arrow_left.svg");
    }
}