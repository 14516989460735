$modalBackgroundColor: #fff;

$inputColor: #1C2677;
$inputBackgroundColor: #F4F4F4;

$violetBlueColor: #2B5A64;

$buttonColor: #fff;
$buttonBackgroundColor: linear-gradient(90.44deg, #288DA4 0%, #5ECCBC 100%);
$buttonModalBackgroundColor: linear-gradient(90.44deg, #288DA4 0%, #5ECCBC 100%);
$buttonBackgroundColorHover: #fff;
$buttonBackgroundColorDisabled: linear-gradient(0deg, #19191926 0%, #19191926 100%); ;
$buttonTextColorDisabled: linear-gradient(0deg, #333333 0%, #333333 100%);

$profileButtonColor: linear-gradient(90.44deg, #47605C 0%, #47605C 100%);
$profileButtonActiveColor: #47605C;

$boxShadowGrey: rgba(197, 196, 208, 0.64);
$boxShadowPurple: rgba(84, 0, 114, 0.24);

$iconCrossSearchColor: #299191;
$iconResetPasswordBackgroundColor: #ffe2cc;
$iconResetPasswordFillColor: #d45c00;
$iconCoinsBalanceGradientColor: linear-gradient(90.5deg, #288DA4 0%, #5ECCBC 100%);
$iconCoinsBalanceDefaultColor: #299191;
$iconLikeWomanProfileFillColor: #EB5757;
$iconAddPlusFillColor: #299191;
$iconAddPhotoFillColor: #299191;
$iconVideosFillColor: #299191;
$iconHandWaveFillColor: #299191;
$iconLetterFillColor: #299191;
$iconFilterFillColor: #446077;
$iconLikeVideoComponentFillColor: #299191;
$iconLetterVideoComponentFillColor: #299191;
$iconMoveFillColor: #299191;
$iconUploadAttachmentFillColor: #5ECCBC;
$iconArrowLeftFillColor: #5ECCBC;
$iconsMoreInfoChatHeaderFillColor: #279191;
$iconProfileFillColor: #279191;
$iconCrossCloseFillColor: #279191;
$iconReplyFillColor: #279191;

$supportFeedbackModalHeaderBgColor: rgba(40, 141, 164, 0.1);
$supportFeedbackRadioLabelColor: #289292;
$supportFeedbackRadioLabelColorChecked: #289292;
$supportFeedbackRadioLabelColorHover: #2892924D;
$supportFeedbackRatingTextColor: #446077;
$supportFeedbackTitleColor: #289292;
$supportFeedbackTextColor: #333333;
$supportFeedbackButtonBorderHover: double 1px transparent;

$mainFont: "Open Sans", sans-serif;
$greenColor: #1C9D53;
$whiteColor: #fff;
$greyColor: #4F4F4F;
$greyBlueColor: #426076;

$inputTextColor: #334B48;

$inputBorderNormal: #288DA4;
$inputBorderHover: #288DA4;
$inputBorderActive: #288DA4;

$selectBackgroundColor: #E0F4F3;
$selectBorderColor: #288DA4;
$selectBorderHoverColor: #288DA4;

$textareaBackgroundColor: #FFFFFF;
$textareaBackgroundHoverColor: #F5F7FA;
$textareaBackgroundActiveColor: #FFFFFF;
$textareaBorderColor: #288DA4;
$textareaBorderActiveColor: #5ECCBC;
$textareaOutlineColor: #5ECCBC1a;

$messageRightBackground: #F8F8F8;
$messageLeftBackground: #E0F4F3;

$chatTextareaBg: #EBF2F7;

$attachTouchText: #288DA4;

$bluePinkColor: #288DA4;

$uploadTabsColor: #334B48;
$uploadTabsActiveColor: #288DA4;

$uploadTabsItemBg: #E7EEF4;

$uploadTabsButton: #5ECCBC;

$mediaListTabActiveBg: #E0F4F3;
$mediaListTabActiveColor: #333;

$mediaListSwitcherActiveBg: #E0F4F3;

$searchBottomBtn: #288DA4;

$buttonHoverBg: #F8F8F8;
$buttonHoverBg2: #E0F4F3;
$buttonHoverBg3: #E0F4F3;

$lettersMenuIconColor: #47605C;

$videoButtonsColor: #288DA4;

$paymentBorderColor: rgba(68, 96, 119, 0.3);
$paymentBorderActiveColor: #798F8C;

$unreadLetter: #E8F1FA;

  //LOGIN MODAL
$colorLoginForgetButton: #EB5757;
$colorLoginBottom: rgba(0,0,0,.2);
$colorLoginBottomSpan: #474F5C;

//ERROR MODAL
$colorErrorTitle: #00317B;
$colorErrorTitleHref: #1952ab;

//MAIN PAGE
$buttonArrowBackground: rgba(240, 249, 255, 0.9);

//ICONS

$c1ProfileIco: url(../../img/c1-profile_btn.svg);

$c3StickerViolet: url(../../img/c3-msg-sticker-violet.svg);
$c3StickerBlue: url(../../img/c3-msg-sticker-blue.svg);

$chatHello: url(../../img/chat-hello.svg);

$profileEdit: url(../../img/c3ph-edit.svg);
$profileEditH: url(../../img/c3ph-edit-h.svg);

$profileCredits: url(../../img/c3ph-purchase.svg);
$profileCreditsH: url(../../img/c3ph-purchase-h.svg);

$profileGifts: url(../../img/c3ph-gift.svg);
$profileGiftsH: url(../../img/c3ph-gift-h.svg);

$profileContact: url(../../img/c3ph-contact.svg);
$profileContactH: url(../../img/c3ph-contact-h.svg);

$profileMeeting: url(../../img/c3ph-meet.svg);
$profileMeetingH: url(../../img/c3ph-meet-h.svg);

$profileBlock: url(../../img/block-list-p.svg);
$profileBlockH: url(../../img/block-list-p-h.svg);

$profileLogout: url(../../img/logout-p.svg);

$editProfileBtn: url(../../img/edit-profile-btn.svg);

$profileLetter: url(../img/c3wpt_mail.svg);
$profileFavs: url(../img/c3wpt_favs.svg);
$profileComplain: url(../img/c3wpt_compl.svg);
$profileGiftTop: url(../img/c3wpt_gift.svg);

$messageStatusRead: url(../../img/readed.svg);
$messageStatusSend: url(../../img/send.svg);

$paymentSelectedCheck: url(../../../../img/check-circle-full-violet.svg);

$npsRadioLabelColor: #289292;
$npsRadioLabelColorChecked: #289292;
$npsRadioLabelColorHover: #2892924D;
$npsButtonBorderHover: double 1px transparent;
$ratingTextColor: #333333;
$npsInputBorderColor: #288DA4;
$npsCheckboxColor: #289292;
$npsContactsInputColor: #333333;
$npsTextAreaBackgroundActiveColor: #F5F7FA;
$npsModalTitleColor: #288DA4;

//POLICY
$policyTitleColor: #5ECCBC;
$policyTextColor: #289292;
$policyButtonBg: #F0FFFD;
$policyLinksColor: #0000FF;
$policyLinksHoverColor: #0101D6;
$policyScrollColor: #A8B7B5;

@import 'theme';
