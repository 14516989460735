@import "@sitestyles";
$mobileBreakpoint: 599px;

.lnd_reg_popup{
  background-color: #fff;
  border: 1px solid #DADDE1;
  border-radius: 10px;
  width: 600px;
  max-width: 100%;

  @media (max-width: $mobileBreakpoint){
    width: 300px;
  }
}

.lnd_reg_popup_title{
  height: 85px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #DADDE1;
  border-radius: 10px 10px 0 0;

  font-weight: 500;
  font-size: 28px;
  color: #306CE3;
  text-align: center;

  @media (max-width: $mobileBreakpoint){
    height: 55px;
    font-size: 16px;
  }
}

.lnd_reg_popup_body{
  padding: 40px 20px;
  text-align: center;


  @media (max-width: $mobileBreakpoint){
    padding: 35px 30px 30px;
  }
}

.lnd_reg_popup_item{

  & + .lnd_reg_popup_item{
    margin-top: 36px;

    @media (max-width: $mobileBreakpoint){
      margin-top: 26px;
    }
  }

  .title{
    font-size: 18px;
    color: #3D4147;
    margin-bottom: 8px;

    @media (max-width: $mobileBreakpoint){
      font-size: 14px;
    }
  }

  .text{
    font-weight: bold;
    font-size: 24px;
    color: #30508B;
    overflow-x: auto;

    &::-webkit-scrollbar {
      width: 3px;
      background: #fff;
      border-radius: 3px;
      height: 3px;
    }

    &::-webkit-scrollbar-thumb {
      background: $scrollbarThumbColor;
      border-radius: 3px;
      height: 3px;
    }

    @media (max-width: $mobileBreakpoint){
      font-size: 18px;
    }
  }

  .text_input{
    text-align: center;
    border: 1px solid #9aafd0;
    padding: 7px 5px;
    border-radius: 8px;

    @media (max-width: $mobileBreakpoint){
      width: 100%;
    }
  }
}

.lnd_reg_popup_ok_btn{
  width: 340px;
  height: 58px;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: #4267B2;
  border: 1px solid #2A497E;
  border-radius: 4px;
  margin: 28px auto 0;

  font-size: 26px;
  text-transform: uppercase;
  color: #FFFFFF;
  text-shadow: 0 2px 1px rgba(41, 72, 125, 0.25);
  cursor: pointer;
}

.lnd_reg_popup_copy{
  margin-top: 12px;
  font-size: 12px;
  color: rgba(51, 51, 51, 0.8);

  @media (max-width: $mobileBreakpoint){
    padding: 0 30px;
  }
}