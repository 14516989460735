@import "@sitestyles";


.popup_manprof_change_password{
	width: 791px;
	max-width: calc(100vw - 30px);
	height: 378px;
	max-height: calc(100vh - 30px);
	max-height: calc(calc(var(--vh, 1vh) * 100) - 30px);
	position: relative;
	background-color: $whiteColor;
	border-radius: 10px;
	padding: 70px 40px 37px 40px;
	overflow-y: auto;
	margin: auto;
	text-align: center;
}

.popup_manprof_change_password_title{
	font-weight: 600;
	font-size: 26px;
	text-align: center;
	letter-spacing: 0.06em;
	color: $accentRightColor;
	margin-bottom: 50px;
}

.popup_manprof_change_password_row{
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	-webkit-flex-wrap: wrap;
	-moz-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	-o-flex-wrap: wrap;
	flex-wrap: wrap;
	justify-content: space-between;
	-ms-align-items: flex-start;
	align-items: flex-start;
}

.popup_manprof_change_password_item{
	width: calc(50% - 12px);
	position: relative;
}

.popup_manprof_change_password_label{
	display: block;
	letter-spacing: 0.06em;
	text-transform: uppercase;
	color: $stormGreyColor;
	margin-bottom: 3px;
}
.popup_manprof_change_email_item{
	width: 100%;
}
.popup_manprof_change_password_input_wrap{
	position: relative;
}

.popup_manprof_change_password_input{
	display: block;
	width: 100%;
	height: 42px;
	background: #eff3f8;
	border-radius: 4px;
	padding-right: 56px;
	padding-left: 17px;
	font-size: 14px;
	font-weight: 600;
	color: $accentLeftColor;
	border: 1px solid #9AAFD0;
	outline: none;
}

input.popup_manprof_change_password_input:-webkit-autofill,
input.popup_manprof_change_password_input:-webkit-autofill:focus,
input.popup_manprof_change_password_input:-webkit-autofill:hover,
input.popup_manprof_change_password_input:-internal-autofill-selected{
	background: #EFF3F8 !important;
	color: #00317B !important;
}

.popup_manprof_change_password_input:hover{
	border-color: #5577AD;
	box-shadow: 0px 3px 6px rgba(28, 38, 119, 0.08);
}

.popup_manprof_change_password_input::placeholder {
	font-weight: 400;
	color: #C2C2C2;
}

.popup_manprof_change_password_input:focus{
	border-color: #00317B;
	box-shadow: 0px 3px 6px rgba(28, 38, 119, 0.08);
}

.popup_manprof_change_password_input[type=text]{
	font-size: 14px;
}

.popup_manprof_change_password_visible_btn{
	display: block;
	width: 42px;
	height: 42px;
	position: absolute;
	right: 5px;
	top: 0;
	background-image: url(../../img/c3mp-pass-hidden.svg);
	background-repeat: no-repeat;
	background-position: center;
	cursor: pointer;
	outline: none;
}

.popup_manprof_change_password_visible_btn.active{
	background-image: url(../../img/c3mp-pass-visible.svg);
}

.popup_manprof_change_password_save_btn{
	width: 290px;
	max-width: 100%;
	height: 44px;
	line-height: 44px;
	margin: 34px auto 0;
	text-align: center;
	color: $whiteColor;
	background: linear-gradient(90.83deg, $accentLeftColor 0%, $accentRightColor 100%);
	border-radius: 4px;
	cursor: pointer;
}

.popup_manprof_change_password_save_btn:not(.disable):hover{
	box-shadow: $searchItemBtnShadowHover;
}

.popup_manprof_change_password_save_btn.disable{
	background: linear-gradient(90.83deg, rgba(0, 49, 123, 0.6) 0%, rgba(114, 0, 102, 0.6) 100%);
	cursor: default;
}

.popup_manprof_change_password_save_btn img{
	vertical-align: middle;
	margin-bottom: 2px;
	margin-right: 10px;
}

.popup_manprof_change_password_save_btn svg {
	vertical-align: middle;
	margin-bottom: 2px;
	margin-right: 10px;
	width: 22px;
	height: 22px;
	fill: #FFFFFF;
}

.popup_manprof_change_password_info_text{
	text-align: center;
	font-size: 16px;
	letter-spacing: 0.01em;
	color: $defaultTextColorAlpha9;
	margin-top: 13px;
}

.popup_manprof_change_password_input_status{
	position: absolute;
	bottom: -20px;
	right: 0;
	font-size: 11px;
	font-weight: 500;
	margin-top: 8px;
	text-align: right;
	height: 14px;
}

.popup_manprof_change_password_input_status span{
	display: inline-block;
	padding-left: 20px;
	background-repeat: no-repeat;
	background-position: 0 center;
}

.popup_manprof_change_password_input_status.wrong{
	color: $counterAndErrorColor;
}

.popup_manprof_change_password_input_status.right{
	color: $onlineColor;
}

.popup_manprof_change_password_input_status.right span{
	background-image: url(../../img/check-circle-green.svg);
}

.popup_manprof_change_password_close{
	position: absolute;
	right: 15px;
	top: 15px;
	padding: 5px;
	cursor: pointer;
}

.popup_manprof_change_password_close svg {
	width: 28px;
	height: 28px;
	fill: #ccd2d9;
	stroke: #ccd2d9;
	stroke-width: 0.5;
}

@media screen and (max-height: 400px){
	.popup_manprof_change_password{
		padding-top: 20px;
		padding-bottom: 20px;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	.popup_manprof_change_password_title{
		margin-bottom: 30px;
	}
}

@media screen and (max-width: 599px){
	.popup_manprof_change_password{
		padding: 50px 20px 30px;
		height: auto;
		overflow-y: auto;
	}

	.popup_manprof_change_password_title{
		font-size: 18px;
		margin-bottom: 30px;
	}



	.popup_manprof_change_password_item{
		width: 100%;
		margin-bottom: 24px;
	}

	.popup_manprof_change_password_item:last-child{
		margin-bottom: 0;
	}

	.popup_manprof_change_password_info_text{
		font-size: 14px;
	}
}
