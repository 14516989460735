@import "@sitestyles";

.user_birthday_modal_wrap{
  width: 100%;
  max-width: 738px;
  min-width: 280px;
  padding: 48px 78px;
  background-color: #fff;
  border-radius: 10px;
  text-align: center;
  position: relative;

  @media screen and (max-width: 1099px){
    padding: 36px 50px;
  }

  @media screen and (max-width: 599px){
    padding: 36px 20px 30px;
  }

  @media screen and (max-width: 399px){
    padding-left: 10px;
    padding-right: 10px;
  }
}

.modal_title{
  font-weight: bold;
  font-size: 24px;
  color: $accentLeftRightColor;

  & + .modal_text{
    margin-top: 32px;
    padding: 0;

    @media screen and (max-width: 1099px){
      margin-top: 20px;
    }
  }

  @media screen and (max-width: 1099px){
    font-size: 20px;
  }

  @media screen and (max-width: 599px){
    font-size: 18px;
  }
}

.user_birthday_modal_img{
  display: block;
  width: 360px;
  max-width: 100%;
  margin: 0 auto 32px;

  @media screen and (max-width: 1139px){
    width: 260px;
    margin-bottom: 24px;
  }

  @media screen and (max-width: 599px){
    width: 80%;
  }
}

.modal_text{
  padding: 0 30px;

  p{
    font-weight: 600;
    font-size: 18px;
    line-height: 170%;
    color: #446077;

    & + p{
      margin-top: 32px;

      @media screen and (max-width: 1099px){
        margin-top: 20px;
      }
    }

    @media screen and (max-width: 1099px){
      font-size: 16px;
    }

    @media screen and (max-width: 599px){
      font-size: 14px;
    }
  }

  @media screen and (max-width: 599px){
    padding: 0;
  }
}

.modal_buttons{
  margin-top: 48px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 1099px){
    margin-top: 30px;
  }
}

.modal_btn{
  width: 256px;
  height: 63px;
  display: flex;
  align-items: center;
  justify-content: center;

  font-weight: 600;
  font-size: 24px;
  letter-spacing: 0.01em;
  border-radius: 10px;
  cursor: pointer;

  @media screen and (max-width: 1099px){
    width: 200px;
    height: 52px;
    font-size: 20px;
  }

  @media screen and (max-width: 599px){
    font-size: 16px;
    height: 46px;
  }

  &.continue {
    width: max-content;
    padding: 0 15px;
  }
}

.color_btn{
  width: 316px;
  background: $buttonBackgroundColor;
  color: #fff;

  @media screen and (max-width: 1099px){
    width: 200px;
  }
}

.grey_btn{
  border: 2px solid #A5A5A5;
  color: #A5A5A5;

  & + .color_btn{
    width: 256px;
    margin-left: 15px;

    @media screen and (max-width: 1099px){
      width: 200px;
    }
  }
}

.close_modal{
  position: absolute;
  right: 5px;
  top: 5px;
  cursor: pointer;
  padding: 10px;

  img{
    width: 24px;
    display: block;

    @media screen and (max-width: 599px){
      width: 16px;
    }
  }

  svg {
    width: 30px;
    height: 30px;
    fill: #c4c4c4;
    stroke: #c4c4c4;
    stroke-width: 0.5;

    @media screen and (max-width: 599px){
      width: 20px;
      height: 20px;
    }
  }

  @media screen and (max-width: 599px){
    right: 0;
    top: 0;
  }
}