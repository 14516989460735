.search_result_wrap {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 16px;
  justify-content: center;
  margin-bottom: 14px;
  list-style: none;

  @media screen and (min-width: 2200px) {
    grid-template-columns: repeat(auto-fill, 330px);
  }

  @media screen and (max-width: 1749px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media screen and (max-width: 1299px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (max-width: 1199px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media screen and (max-width: 999px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (max-width: 599px) {
    padding-left: 10px;
    grid-gap: 8px;
  }
}