@import "@sitestyles";

.unsubscribe_girls_list {
  display: block;
  margin: 0 -16px 64px 0;
  position: relative;
  overflow-x: hidden;

  @media screen and (max-width: 1140px) {
    margin: 0 -16px 0 0;
  }

  @media screen and (max-width: 800px) {
    margin: 0 -16px 0;
  }

  &:after {
    content: '';
    display: block;
    width: 160px;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    background: linear-gradient(to left, #FFFFFF 10%, rgba(255, 255, 255, 0) 100%);
    z-index: 2;
    pointer-events: none;
    @media screen and (max-width: 1140px) {
      display: none;
    }
  }
}

.active_cursor {
  cursor: pointer;
}

.unsubscribe_girls_list_item {
  position: relative;
  padding: 8px;
  background-color: #fff;
  box-shadow: 0 3px 10px rgba(197, 196, 208, 0.64);
  border-radius: 15px;
  list-style-type: none;
  box-sizing: border-box;
  width: 286px;
  min-width: 286px;
  margin: 0 8px;
  z-index: 10;

  @media screen and (max-width: 1880px) {
    padding: 6px;
  }
  @media screen and (max-width: 1140px) {
    margin: 0;
    min-width: 200px;
    width: 200px;
  }
}

.girl_card {
  position: relative;
}

.girl_card_photo_wrap {
  position: relative;

  background: #EBEDFA;
  border-radius: 10px;
  height: 450px;
  @media screen and (max-width: 1140px) {
    height: 280px;
  }
}

.girl_card_photo {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
  border-radius: 10px 10px 12px 12px;
}

.girl_card_photo_all_btn {
  max-width: 123px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 5px;
  font-weight: 500;
  padding: 0 12px;
  letter-spacing: -0.01em;
  color: $unsubscribeTitleColor;
  cursor: pointer;
  transition: all .3s ease-in-out;
  font-family: $mainFont;
  font-size: 12px;
  line-height: 16px;
  height: 24px;
  top: 4px;
  right: 4px;

  @media screen and (max-width: 8000px) {
    font-size: 10px;
    line-height: 14px;
    padding: 0 8px;
  }

  &:before {
    content: '';
    display: block;
    background: $unsubscribeTitleColor;
    width: 20px;
    height: 20px;
    min-width: 20px;
    margin-right: 8px;
    mask: url('../../../img/c2-photo-ico.svg') center no-repeat;
    mask-size: contain;
    -webkit-mask: url('../../../img/c2-photo-ico.svg') center no-repeat;
    -webkit-mask-size: contain;
    @media screen and (max-width: 1880px) {
      width: 16px;
      height: 16px;
      min-width: 16px;
    }
  }
}

.girl_card_photo_wrap:hover .girl_card_photo_all_btn {
  background-color: #fff;
}

.girl_card_online_status {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  background: $backgroundOnlineColor;
  border-radius: 5px;
  font-weight: 600;
  color: $onlineTitleColor;
  font-family: $mainFont;
  padding: 0 16px;

  width: auto;
  font-size: 12px;
  line-height: 16px;
  height: 24px;
  top: 4px;
  left: -12px;

  @media screen and (max-width: 8000px) {
    font-size: 10px;
    line-height: 14px;
  }
}

.girl_card_bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 0 8px 8px;
  border-radius: 10px 10px 0 0;
  z-index: 10;
}

.girl_card:before {
  content: '';
  display: block;
  width: 100%;
  height: 200px;
  position: absolute;
  bottom: 0;
  left: 0;
  background: $backgroundSearchColor;
  border-radius: 0 0 10px 10px;
  z-index: 1;
  border: 0;
  @media screen and (max-width: 599px) {
    height: 110px;
  }
}


.girl_card_name {
  font-family: $mainFont;
  text-align: center;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: #333333;
  margin-bottom: 28px;
  text-shadow: 0 1px 1px rgba(255, 255, 255, 0.32);
  word-break: break-word;

  @media screen and (max-width: 599px) {
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 12px;
  }

}

.girl_card_buttons {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 8px;
}

.girl_card_btn {
  height: 48px;
  border-radius: 5px;
  font-family: $mainFont;
  font-weight: 600;
  font-size: 16px;
  line-height: 140%;
  letter-spacing: 0.03em;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: $primarySearchButtonBackground2;
  border: 1px solid $primarySearchButton2;
  color: $primarySearchButton2;
  position: relative;
  max-width: 100%;

  @media screen and (max-width: 1140px) {
    height: 32px;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.01em;
  }

  &:hover {
    background: $primarySearchButton2;
    color: $primarySearchButtonBackground2;

    &:before {
      background: $primarySearchButtonBackground2;
    }
  }
}

.girl_card_btn_chat:before {
  content: '';
  display: block;
  width: 24px;
  height: 24px;
  min-width: 24px;
  background: $primarySearchButton2;
  mask: url('../../../img/c3lh-item-chat.svg') center no-repeat;
  mask-size: contain;
  -webkit-mask: url('../../../img/c3lh-item-chat.svg') center no-repeat;
  -webkit-mask-size: contain;
  margin-right: 12px;
  @media screen and (max-width: 1140px) {
    width: 16px;
    height: 16px;
    min-width: 16px;
    margin-right: 8px;
  }
}

.girl_card_btn_label {
  background: #EB5757;
  border-radius: 4px;
  height: 22px;
  min-width: 50px;
  padding: 0 6px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: -0.02em;
  color: #FFFFFF;
  font-weight: 600;
  font-size: 12px;
  font-family: $mainFont;
  line-height: 100%;
  position: absolute;
  top: -4px;
  right: -4px;
  @media screen and (max-width: 1140px) {
    font-size: 10px;
    height: 17px;
  }
}


.girl_card_btn_letter:before {
  content: '';
  display: block;
  width: 24px;
  height: 24px;
  min-width: 24px;
  background: $primarySearchButton2;
  mask: url('../../../img/c3lh-item-mail.svg') center no-repeat;
  mask-size: contain;
  -webkit-mask: url('../../../img/c3lh-item-mail.svg') center no-repeat;
  -webkit-mask-size: contain;
  margin-right: 12px;
  @media screen and (max-width: 1140px) {
    width: 16px;
    height: 16px;
    min-width: 16px;
    margin-right: 8px;
  }
}


.girls_slider_btn_buttons {
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  z-index: 12;
  width: 90px;
  justify-content: center;

  @media screen and (max-width: 1140px) {
    display: none;
  }
}

.girls_slider_btn {
  z-index: 2;
  width: 40px;
  height: 40px;
  background-color: rgba(255, 255, 255, 0.8);
  border: 1px solid #ECECEC;
  border-radius: 100%;
  background-repeat: no-repeat;
  cursor: pointer;
  transition: background-color .3s;
}

.girls_slider_btn:hover {
  background-color: #fff;
}

.girls_slider_prev_btn {
  margin-right: 8px;
  background-image: url(../../../img/girls-slider-prev.svg);
  background-position: calc(50% - 2px) center;
}

.girls_slider_next_btn {
  background-image: url(../../../img/girls-slider-next.svg);
  background-position: calc(50% + 1px) center;
}

.unsubscribe_girls_list_item_wrapper {
  padding: 10px 0 10px 16px;
}