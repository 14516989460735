@import "@sitestyles";

.vip_onb_wrapper {
  display: flex;
  justify-content: center;
  height: calc(100% - 70px);
  margin: 15px;
}

.vip_onb_item {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 3.42%;
  margin-bottom: 16px;
  box-shadow: 3px 3px 5px rgba(197, 196, 208, 0.64);
  border-radius: 10px;
  background-color: #fff;
  position: relative;
  width: 100%;
  max-width: 1012px;
  height: 100%;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 5px;
    background: $bleachColor;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: $accentLeftColor;
    border-radius: 7px;
  }
}

.vip_onb_title {
  margin-top: 28px;
  margin-bottom: 24px;
  font-weight: bold;
  font-size: 36px;
  line-height: 44px;
  color: #00317B;
  text-align: center;

  span {
    color: #FEB930;
  }
}

.vip_onb_container {
  display: flex;
}

.vip_onb_container_txt {
  display: flex;
  align-items: center;
  margin-top: 12px;
  text-align: left;
}
.vip_onb_container_txt_sub {
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-left: 20px;
  text-align: left;
}

.vip_onb_dot {
  width: 16px;
  height: 16px;
}

.vip_onb_txt {
  margin-left: 8px;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #00317B;
}

.vip_onb_pict {
  width: 268px;
  margin-right: 48px;
}

.vip_onb_btn {
  margin-top: 40px;
  margin-bottom: 28px;
  cursor: pointer;
  background: linear-gradient(90.32deg, #FEB930 0.31%, #FE8730 115.03%), #0965B1;
  width: 381px;
  padding: 12px;
  border-radius: 4px;
  color: $whiteColor;
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
}

.vip_onb_cross {
  position: absolute;
  right: 14px;
  top: 30px;
  transform: translateY(-50%);
  cursor: pointer;
}

.vip_onb_cross svg {
  width: 34px;
  height: 34px;
  fill: #c4c4c4;
}

@media screen and (max-width: 1449px) {
  .vip_onb_item {
    padding: 20px 25px;
  }
}

@media screen and (max-width: 999px) {
  .vip_onb_title {
    font-size: 20px;
  }

  .vip_onb_txt {
    font-size: 14px;
  }

  .vip_onb_btn {
    font-size: 16px;
    width: 360px;
    height: 50px;
  }

  .vip_onb_container {
    flex-direction: column;
  }

  .vip_onb_pict {
    margin: 10px 0 0 0;
  }
}

@media screen and (max-width: 699px) {
  .vip_onb_item {
    display: block;
    padding: 16px;
  }

  .vip_onb_btn {
    margin: 20px auto 10px;
  }
}

@media screen and (max-width: 650px) {
  .vip_onb_item__video_show {
    max-width: 350px;
  }

  .vip_onb_btn {
    font-size: 14px;
    width: 100%;
    height: 40px;
    background: $buttonBackgroundColor;
  }
}

@media screen and (max-width: 599px) {
  .vip_onb_wrapper {
    height: 100%;
    //padding: 30px 15px 50px 15px;
  }

  .vip_onb_item {
    height: 100%;
    overflow-y: auto;
  }

  .vip_onb_title {
    font-size: 17px;
    line-height: 30px;
    margin: 10px 0;
  }

  .vip_onb_cross {
    right: 7px;
    top: 20px;
    width: 28px;
    height: 28px;
  }

  .vip_onb_cross svg {
    width: 28px;
    height: 28px;
  }
}

@media screen and (max-width: 1349px) and (min-width: 1200px) {
  .vip_onb_item__video_show {
    max-width: 780px;
  }
}

@media screen and (max-width: 2496px) and (min-width: 1900px) {
  .vip_onb_item__video_show {
    max-width: 1270px;
  }
}

@media screen and (max-width: 3138px) and (min-width: 2497px) {
  .vip_onb_item__video_show {
    max-width: 1911px;
  }
}

@media screen and (max-width: 3199px) and (min-width: 3139px) {
  .vip_onb_item__video_show {
    max-width: 2554px;
  }
}

@media screen and (max-width: 3666px)  and (min-width: 3200px) {
  .vip_onb_item__video_show {
    max-width: 2050px;
  }
}

@media screen and (min-width: 3667px) {
  .vip_onb_item__video_show {
    max-width: 3080px;
  }
}



