.ls_content_bg {
  background: #FFFFFF;
}

.ls_loader {
  display: block;
  position: relative;
  width: 80px;
  height: 80px;
  margin: 0 auto;
}
.ls_loader div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #383B5A;
  border-radius: 50%;
  animation: ls_loader 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #383B5A transparent transparent transparent;
}
.ls_loader div:nth-child(1) {
  animation-delay: -0.45s;
}
.ls_loader div:nth-child(2) {
  animation-delay: -0.3s;
}
.ls_loader div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes ls_loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 599px) {
  body.love_stories_page {
    overflow-y: auto;
  }
}