@import "@sitestyles";

.attach_new_temp{
	width: 100%;
	height: 100vh;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	justify-content: center;
	-ms-align-items: center;
	align-items: center;
	background-color: $oldGrayColor;
}

.attach_new_popup_wrap{
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	width: 848px;
	max-width: 100%;
	position: relative;
}

.attach_new_popup_left{
	width: 168px;
	margin: 10px 0;
	background-color: $bleachColor;
	box-shadow: -5px 6px 12px rgba(47, 43, 99, 0.16);
	border-radius: 10px 0px 0px 10px;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	-webkit-flex-direction: column;
	-moz-flex-direction: column;
	-ms-flex-direction: column;
	-o-flex-direction: column;
	flex-direction: column;
	justify-content: space-between;
	padding-bottom: 32px;
	flex: none;
}

.attach_new_popup_ul li{
	list-style: none;
	height: 57px;
	cursor: pointer;
}

.attach_new_popup_ul li.active{
	color: $accentRightColor;
	position: relative;
	background: url(../../img/attach-li-bottom.svg) right 71px, url(../../img/attach-li-top.svg) right -20px;
}

.attach_new_popup_ul li.active:first-child{
	margin-top: -6px;
	margin-bottom: 6px;
}

.attach_new_popup_ul li.active:before{
	content: '';
	display: block;
	width: calc(100% + 9px);
	height: calc(100% + 8px);
	position: absolute;
	left: -9px;
	top: -4px;
	background-color: $whiteColor;
	box-shadow: -6px 6px 12px rgba(47, 43, 99, 0.16);
	border-radius: 10px 0px 0px 10px;
}

.attach_new_popup_ul li.active:after{
	content: '';
	display: block;
	width: 12px;
	height: calc(100% + 8px);
	position: absolute;
	right: -10px;
	top: -4px;
	background-color: $whiteColor;
	pointer-events: none;
}

.attach_new_popup_li_wrap{
	display: flex;
	width: 100%;
	height: 100%;
	align-items: center;
	padding-left: 32px;
	font-weight: 500;
	font-size: 18px;
	letter-spacing: 0.06em;
	text-transform: uppercase;
	color: $uploadTabsColor;
	position: relative;
}

li.active .attach_new_popup_li_wrap{
	color: $uploadTabsActiveColor;
}

li.active .attach_new_popup_li_wrap:after{
	content: '';
	width: 21px;
	height: calc(100% + 48px);
	position: absolute;
	right: -1px;
	top: -24px;
	background: url(../../img/attach-li-top.svg) right top, url(../../img/attach-li-bottom.svg) right bottom;
	background-repeat: no-repeat;
	pointer-events: none;
}

li.active:first-child .attach_new_popup_li_wrap:after{
	background: url(../../img/attach-li-bottom.svg) right bottom;
	background-repeat: no-repeat;
}

.attach_new_popup_ul li span{
	position: relative;
}

.attach_new_popup_li_wrap div{
	background-repeat: no-repeat;
	background-position: center;
	margin-right: 12px;
	position: relative;
	width: 26px;
}

.attach_new_popup_li_photo{
	width: 25px;
	height: 25px;
	background-image: url(../../img/attach-photo-ico.svg);
}

li.active .attach_new_popup_li_photo{
	background-image: url(../../img/attach-photo-ico-a.svg);
}

.attach_new_popup_li_video{
	width: 26px;
	height: 16px;
	background-image: url(../../img/attach-video-ico.svg);
}

li.active .attach_new_popup_li_video{
	background-image: url(../../img/attach-video-ico-a.svg);
}

.attach_new_popup_li_audio{
	width: 24px;
	height: 24px;
	background-image: url(../../img/attach-audio-ico.svg);
}

li.active .attach_new_popup_li_audio{
	background-image: url(../../img/attach-audio-ico-a.svg);
}

.attach_new_popup_drag_drop{
	width: 132px;
	height: 123px;
	border: 2px dashed $submenuColor;
	border-radius: 5px;
	margin: auto;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}
.attach_new_popup_drag_drop.accept{
	border: 2px dashed $primaryColor;
}

.attach_new_popup_drag_drop.reject{
	border: 2px dashed red;
}

 .attach_new_popup_drag_drop div{
	 text-align: center;
	 margin-bottom: 10px;
	 color: $primaryColor;
	 font-size: 12px;
 }

 .attach_new_popup_drag_drop svg {
	 width: 30px;
	 height: 38px;
	 fill: #a3b3c7;
 }

.attach_new_popup_drag_drop.accept svg{
	fill: #415B71;
}

 .attach_new_popup_right{
	width: 680px;
	background: $whiteColor;
	box-shadow: 0 6px 12px rgba(47, 43, 99, 0.16);
	border-radius: 10px 8px 8px 10px;

	 @media screen and (max-width: 880px) {
		 width: 100%;
	 }
}

.attach_new_popup_info_text{
	text-align: center;
	font-size: 12px;
	line-height: 17px;
	letter-spacing: 0.01em;
	color: $primaryColor;
}

.attach_new_popup_tab_wrap{
	height: 100%;
}

.attach_new_popup_tab_content{
	height: 100%;
	padding: 23px 10px 23px 23px;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	-webkit-flex-direction: column;
	-moz-flex-direction: column;
	-ms-flex-direction: column;
	-o-flex-direction: column;
	flex-direction: column;
}

.attach_new_popup_tab_content_top{
	margin-bottom: 16px;
	display: flex;
	justify-content: space-between;
	padding-right: 14px;
	align-items: center;
}

.attach_new_popup_tab_content_top_text{
	font-size: 11px;
	color: $defaultTextColorAlpha3;
	flex: none;
}

.attach_new_popup_tab_content_top_sort{
	font-size: 13px;
	letter-spacing: 0.01em;
	color: $accentLeftColor;
	display: flex;
	align-items: center;
}

.attach_new_popup_tab_content_top_sort svg{
	width: 20px;
	height: 20px;
	margin-right: 7px;
	fill: $iconMoveFillColor;
}

.attach_new_popup_tab_content_middle_empty{
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}

.attach_new_popup_tab_content_middle_empty_img{
	margin-bottom: 5px;
}

.attach_new_popup_tab_content_middle_empty_title{
	font-weight: 600;
	font-size: 16px;
	color: #426076;
	margin-bottom: 6px;
}

.attach_new_popup_tab_content_middle_empty_text{
	font-weight: 500;
	font-size: 12px;
	line-height: 1.5;
	color: #8A9BA9;
	max-width: 150px;
	text-align: center;
}

.attach_new_popup_tab_content_middle_empty_text svg {
	vertical-align: top;
	fill: #8a9cab;
}

.attach_new_popup_tab_content_midddle{
	flex: 1px;
	overflow-y: auto;
	padding-right: 8px;
}

.attach_new_popup_tab_content_midddle::-webkit-scrollbar{
	width: 3px;
	background: $bleachColor;
	border-radius: 5px;
}

.attach_new_popup_tab_content_midddle::-webkit-scrollbar-thumb {
  background: $accentLeftColor;
  border-radius: 7px;
}

.attach_new_popup_tab_content_bottom{
	margin-top: 20px;
	flex: none;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	justify-content: space-between;
	-ms-align-items: center;
	align-items: center;
	padding-right: 15px;
}

.attach_new_popup_upload_btn{
	width: 144px;
	height: 42px;
	line-height: 40px;
	background: linear-gradient(0deg, $whiteColor, $whiteColor);
	border: 1px solid $uploadTabsButton;
	border-radius: 4px;
	text-align: center;
	font-weight: 600;
	letter-spacing: 0.01em;
	color: $uploadTabsButton;
	cursor: pointer;
}

.attach_new_popup_upload_btn:hover{
	box-shadow: $searchItemLikeBtnShadowHover;
}

.attach_new_popup_upload_btn svg {
	width: 20px;
	height: 20px;
	fill: $iconUploadAttachmentFillColor;
	vertical-align: middle;
	margin-bottom: 4px;
	margin-right: 13px;
}

.attach_new_popup_send_btn{
	width: 203px;
	height: 42px;
	line-height: 42px;
	background: $backgroundButtonColor;
	box-shadow: $searchItemLikeBtnShadowHover;
	border-radius: 4px;
	text-align: center;
	color: $whiteColor;
	font-weight: 600;
	letter-spacing: 0.01em;
	cursor: pointer;
}

.attach_new_popup_send_btn:hover{
	box-shadow: $searchItemBtnShadowHover;
}

.attach_new_popup_send_btn svg {
	width: 21px;
	height: 21px;
	fill: #FFFFFF;
	vertical-align: middle;
	margin-bottom: 6px;
	margin-right: 14px;
}

.attach_new_popup_close{
	position: absolute;
	right: 2px;
	top: 2px;
	padding: 3px;
	cursor: pointer;
}

.attach_new_popup_close svg{
	display: block;
	width: 20px;
	height: 20px;
	fill: #bdcbdb;
}

.attach_loader_gif{
	width: 60px;
}

@media screen and (max-width: 599px){
	.attach_new_popup_wrap{
		width: 281px;
	}

	.attach_new_popup_left{
		width: 64px;
	}

	.attach_new_popup_li_wrap{
		padding-left: 0;
		justify-content: center;
	}

	.attach_new_popup_li_wrap span{
		display: none;
	}

	.attach_new_popup_li_wrap div{
		margin-right: 0;
	}

	.attach_new_popup_drag_drop{
		display: none;
	}

	.attach_new_popup_info_text{
		display: none;
	}

	.attach_new_popup_upload_btn{
		width: 51px;
		display: -webkit-flex;
		display: -moz-flex;
		display: -ms-flex;
		display: -o-flex;
		display: flex;
		-ms-align-items: center;
		align-items: center;
		justify-content: center;
	}

	.attach_new_popup_upload_btn span{
		display: none;
	}

	.attach_new_popup_upload_btn svg{
		margin-right: 0;
		margin-bottom: 0;
	}

	.attach_new_popup_send_btn{
		width: 103px;
	}

	.attach_new_popup_send_btn span span{
		display: none;
	}

	.attach_new_popup_tab_content_top_text{
		width: 100px;
	}

	.attach_new_popup_tab_content_top{
		margin-bottom: 11px;
	}

	.attach_new_popup_tab_content{
		padding-top: 11px;
		padding-bottom: 17px;
	}

	.attach_new_popup_tab_content_bottom{
		margin-top: 11px;
	}
}

@media (orientation: landscape) and (max-width: 900px) and (max-height: 500px) and (min-width: 420px){
	.attach_new_popup_drag_drop{
		display: none;
	}
}