@import "@sitestyles";

@import "@sitestyles";

.popup_main_login{
  width: 520px;
  max-width: calc(100vw - 30px);
  background-color: $modalBackgroundColor;
  border-radius: 8px;
  position: relative;
}
.popup_main_login_top{
  padding: 60px 60px 35px;
}
.popup_main_login_btn{
  display: block;
  width: 148px;
  height: 42px;
  line-height: 42px;
	background: $buttonBackgroundColor;
  border-radius: 4px;
  font-weight: 600;
  font-size: 18px;
  text-align: center;
  letter-spacing: 0.01em;
  color: $buttonColor;
  margin: 0 auto 9px;
  cursor: pointer;
}
.popup_main_login_forget_btn{
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  color: $colorLoginForgetButton;
  cursor: pointer;
}

.login_forget_btn__alert_margin {
  margin-top: 9px;
}

.popup_main_login_bottom{
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  justify-content: center;
  border-top: 1px solid $colorLoginBottom;
  height: 129px;
}
.popup_main_login_bottom span{
  font-weight: 600;
  font-size: 24px;
  color: $colorLoginBottomSpan;
  margin-right: 30px;
}
.popup_main_login_bottom div:last-child{
  margin-left: 21px;
}
.popup_main_login_bottom div{
  cursor: pointer;
}
.popup_main_login_close{
  position: absolute;
  top: 5px;
  right: 5px;
  padding: 5px;
  cursor: pointer;
}

.popup_main_login_close svg {
  width: 22px;
  height: 22px;
  fill: #c1ccde;
}

.popup_main_login_input{
  display: block;
  width: 100%;
  height: 42px;
  border-radius: 4px;
  background-color: $inputBackgroundColor;
  border: 1px solid $inputBorderNormal;
  padding: 0 16px;
  margin-bottom: 24px;
  color: $inputColor;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.01em;
}
.popup_main_login_input::placeholder{
  font-weight: 500;
  color: $defaultTextColor;
}
.popup_main_login_input:hover{
  border-color: #5577ad;
}
.popup_main_login_input:focus{
  border-color: #5577ad;
}
.popup_error_title{
  font-weight: 600;
  font-size: 20px;
  color: $colorErrorTitle;
  margin: 20px;
}
.popup_error_title a[href^="mailto:"]{
  color: $colorErrorTitleHref;
  font-size: 21px;
}
.main_registration_form_bottom{
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  justify-content: space-evenly;
  border-top: 1px solid $greyColorBorderAlpha2;
  height: 77px;
}

.main_registration_form_bottom_mob_txt{
  display: none;
}

@media screen and (max-width: 599px){
  .main_registration_form_bottom_mob_txt{
    display: block;
    color: $defaultTextColorAlpha8;
  }

  .main_registration_form_bottom{
    justify-content: center;
  }

}
@media screen and (max-width: 499px){
  .popup_error_title{
    text-align: center;
  }
  .popup_main_login_top {
    padding: 40px 10px 20px;
  }
  .popup_main_login_bottom{
    height: 79px;
  }
  .popup_main_login_bottom span{
    font-size: 18px;
    margin-right: 22px;
  }
  .popup_main_login_bottom img{
    width: 37px;
  }
}
