.profile_about_icon svg {
  width: 35px;
  height: 35px;
}

@media screen and (max-width: 1799px){
  .profile_about_icon svg {
    width: 24px;
    height: 28px;
  }
}