@import "@sitestyles";

.girls_slider_wrap {
  //display: flex;
  align-items: center;
  padding: 12px 0 0 24px;
  width: 100%;
  flex: none;

  display: grid;
  grid-template-columns: 160px 1fr;
  grid-column-gap: 16px;
  box-sizing: border-box;
  max-width: 100%;
  overflow: hidden;
  position: relative;
  //display: none !important;
}

//.girls_slider_wrap:after {
//  content: '';
//  display: block;
//  width: 160px;
//  height: 100%;
//  position: absolute;
//  right: 0;
//  top: 0;
//  background: linear-gradient(to left, #F4F4F4 0%, rgba(255, 255, 255, 0) 60%);
//  z-index: 2;
//  pointer-events: none;
//}


.girls_slider_info {
  width: 160px;
  //margin-right: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
}

.girls_slider_title {
  font-weight: 600;
  font-size: 18px;
  letter-spacing: 0.06em;
  color: $accentLeftColor;
}

.girls_slider_text {
  font-size: 12px;
  line-height: 140.4%;
  letter-spacing: 0.06em;
  color: rgba(51, 51, 51, 0.8);
  padding-right: 10px;
  margin-top: 4px;
}

.girls_slider_like_prev {
  width: 153px;
  height: 34px;
  border: 1px solid $primaryOrangeColor;
  border-radius: 4px;
  background-color: #fff;
  font-weight: 600;
  font-size: 12px;
  letter-spacing: 0.06em;
  color: $primaryOrangeColor;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-top: 19px;
  transition: .3s;
}

.girls_slider_like_prev:hover {
  box-shadow: $searchItemLikeBtnShadowHover;
}

.girls_slider_like_prev img {
  margin-right: 8px;
}

.girls_slider_like_prev svg {
  margin-right: 8px;
  width: 16px;
  height: 12px;
  fill-opacity: 1;
  fill: $iconArrowLeftFillColor;
}

.girls_slider_list_wrap {
  flex: 1;
  overflow: hidden;
  position: relative;
}

.girls_slider_list_wrap:after {
  content: '';
  display: block;
  width: 5px;
  height: 100%;
  position: absolute;
  right: -5px;
  top: 0;
  //background: linear-gradient(to left, #F4F4F4 0%, rgba(255, 255, 255, 0) 60%);
  z-index: 2;
  pointer-events: none;

  box-shadow: 2px 2px 50px 50px #F4F4F4;

}

.girls_slider_list_wrap:before {
  content: '';
  display: block;
  //width: 135px;
  width: 5px;
  height: 100%;
  position: absolute;
  //left: 0;
  left: -5px;
  top: 0;
  //background: linear-gradient(to right, #F4F4F4 0%, rgba(255, 255, 255, 0) 60%);
  z-index: 2;
  pointer-events: none;

  box-shadow: 2px 2px 50px 50px #F4F4F4;

  @media screen and (max-width: 599px) {
    display: none;
  }
}

.girls_slider_list {
  width: 100%;
}

.girls_slider_item_wrap {
  height: 160px;
  display: flex;
  align-items: center;
  margin-right: 10px;

}

.girls_slider_item {
  position: relative;
}

.girls_slider_item_status {
  width: 49px;
  height: 18px;
  background: $likeSliderOnlineBg;
  border-radius: 3px;
  position: absolute;
  top: 3px;
  right: -3px;
  color: $likeSliderOnlineColor;
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.girls_slider_item_photo {
  width: 120px;
  height: 120px;
  object-fit: cover;
  object-position: top;
  border-radius: 10px;
  display: block;
  transition: .3s;
  cursor: pointer;
}

.girls_slider_item:hover .girls_slider_item_photo {
  width: 160px;
  height: 160px;
}

.girls_slider_item_bg {
  background: linear-gradient(0deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
  width: 100%;
  height: 84px;
  position: absolute;
  left: 0;
  bottom: 0;
  border-radius: 0 0 9px 9px;
  opacity: 0;
  transition: opacity .3s;
  pointer-events: none;
}

.girls_slider_item:hover .girls_slider_item_bg {
  opacity: 1;
}

.girls_slider_item_buttons {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  bottom: 5px;
  padding: 0 5px;
  opacity: 0;
  transition: opacity .3s;
}

.girls_slider_item:hover .girls_slider_item_buttons {
  opacity: 1;
}

.girls_slider_item_buttons > div {
  width: calc(50% - 2px);
  height: 33px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.03em;
  transition: .3s;
  cursor: pointer;
}

.girls_slider_item_chat_btn {
  background: $buttonBackgroundColor;
  color: #fff;
}

.girls_slider_item_chat_btn:hover {
  box-shadow: $searchItemBtnShadowHover;
}

.girls_slider_item_like_btn {
  background-color: #fff;
  border: 1px solid $primaryOrangeColor;
  color: $primaryOrangeColor;
}

.girls_slider_item_like_btn:hover {
  box-shadow: $searchItemLikeBtnShadowHover;
}

.girls_slider_item_like_btn.active {
  background-color: $bluePinkColor;
  border: 1px solid $bluePinkColor;
  color: #fff;
  cursor: default;
}

.girls_slider_item_like_btn.active:hover {
  box-shadow: none;
}

//.girls_slider_btn_buttons {
//  position: relative;
//  //left: 5px;
//  //top: 50%;
//  //transform: translateY(-50%);
//  display: flex;
//  align-items: center;
//  z-index: 3;
//  width: calc(100% - 10px);
//  justify-content: space-between;
//}

.girls_slider_btn {
  z-index: 3;
  width: 40px;
  height: 40px;
  background-color: rgba(255, 255, 255, 0.8);
  border: 1px solid #ECECEC;
  border-radius: 100%;
  background-repeat: no-repeat;
  cursor: pointer;
  transition: background-color .3s;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);

  @media screen and (max-width: 1139px) {
    top: 57.5%;
  }
  @media screen and (max-width: 599px) {
    display: none;
  }
}

.girls_slider_btn:hover {
  background-color: #fff;
}

.girls_slider_prev_btn {
  background-image: url(../../../img/girls-slider-prev.svg);
  background-position: calc(50% - 2px) center;
  left: 180px;

  @media screen and (max-width: 1139px) {
    left: 8px;
  }

}

.girls_slider_next_btn {
  background-image: url(../../../img/girls-slider-next.svg);
  background-position: calc(50% + 1px) center;
  right: 8px;
}

.girls_slider_item_mob_click {
  display: none;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.girls_slider_item_placeholder {
  animation-duration: 1.8s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: linear-gradient(90deg, rgba(189, 195, 199, .5) 0%, rgba(44, 62, 80, .3) 51.04%, rgba(189, 195, 199, .5) 100%);
  background-size: 1000px 640px;
  position: relative;
  border-radius: 10px;
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0
  }
  100% {
    background-position: 468px 0
  }
}

@media screen and (max-width: 1599px) {
  .girls_slider_wrap {
    padding-top: 10px;
  }

  .girls_slider_title {
    font-size: 16px;
  }

  .girls_slider_like_prev {
    width: 142px;
  }

  .girls_slider_like_prev img,
  .girls_slider_like_prev svg {
    width: 16px;
  }

  .girls_slider_item_wrap {
    height: 122px;
    margin-right: 6px;
  }

  .girls_slider_item_photo {
    width: 92px;
    height: 92px;
  }

  .girls_slider_item:hover .girls_slider_item_photo {
    width: 122px;
    height: 122px;
  }

  .girls_slider_item_buttons > div {
    width: calc(50% - 1px);
    height: 25px;
    font-size: 12px;
  }

  .girls_slider_item_buttons {
    padding: 0 4px;
    bottom: 4px;
  }

  .girls_slider_info {
    width: 155px;
    margin-right: 13px;
  }

  //.girls_slider_list_wrap:after {
  //  width: 78px;
  //  background: linear-gradient(to left, #F4F4F4 0%, rgba(255, 255, 255, 0) 100%);
  //}

  .girls_slider_btn_buttons {
    right: 5px;
  }
}

@media screen and (max-width: 1139px) {
  .girls_slider_wrap {
    display: block;
  }

  .girls_slider_item_buttons > div {
    height: 32px;
  }

  .girls_slider_item_status {
    top: unset;
    bottom: 3px;
    transition: bottom, top .3s;
  }

  .girls_slider_item:hover .girls_slider_item_status {
    bottom: unset;
    top: 3px;
  }

  .girls_slider_info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .girls_slider_text {
    margin: 0;
    padding: 0;
  }

  .girls_slider_info.lh .girls_slider_text {
    display: none;
  }

  .girls_slider_info.lh {
    flex-direction: row-reverse;
  }

  .girls_slider_like_prev {
    width: unset;
    height: unset;
    margin: 0;
    border: none;
    background-color: unset;
  }

  .girls_slider_like_prev:hover {
    box-shadow: none;
  }
}

@media screen and (max-width: 599px) {
  .girls_slider_wrap {
    padding: 12px 9px 0 9px;
    display: block;
  }

  .girls_slider_info {
    margin-bottom: 6px;
  }

  .girls_slider_title {
    font-size: 12px;
  }

  .girls_slider_text {
    font-size: 10px;
    margin-bottom: -1px;
  }

  .girls_slider_item_wrap {
    height: max-content;
  }

  .girls_slider_item_photo {
    width: 64px;
    height: 64px;
  }

  .girls_slider_item:hover .girls_slider_item_photo {
    width: 64px;
    height: 64px;
  }

  .girls_slider_btn_buttons {
    display: none;
  }

  //.girls_slider_list_wrap:after {
  //  width: 70px;
  //  pointer-events: none;
  //}

  .girls_slider_item_buttons,
  .girls_slider_item_bg {
    display: none;
  }

  .girls_slider_item:hover .girls_slider_item_status {
    bottom: 3px;
    top: unset;
  }

  .girls_slider_item_mob_click {
    display: block;
  }
}

@media (orientation: landscape) and (max-width: 900px) and (max-height: 500px) and (min-width: 420px) {
  .girls_slider_wrap {
    display: none;
  }
}