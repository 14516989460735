@import "@sitestyles";

.wrapper {
  background: #fff;
  width: 600px;
  height: 450px;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 80px;
  padding-right: 80px;
  position: relative;
  margin: auto;
  max-width: 95%;
  text-align: center;

  @media screen and (max-width: 600px) {
    padding-left: 15px;
    padding-right: 15px;
  }

  @media screen and (max-width: 420px) {
    width: calc(100% - 20px);
    height: 300px;
  }

  .cross {
    cursor: pointer;
    position: absolute;
    right: 15px;
    top: 15px;
    width: 16px;
    height: 16px;
    background-image: url(../../../../../img/confirm/cros-modal.svg);
  }

  .logo {
    width: auto;
    max-height: 60px;

    @media screen and (max-width: 600px) {
      width: auto;
      height: 40px;
    }
  }

  .title {
    margin-top: 30px;
    font-weight: 700;
    font-size: 34px;
    line-height: 40px;
    color: $accentLeftColor;

    @media screen and (max-width: 420px) {
      margin-top: 20px;
      font-size: 16px;
      line-height: 22px;
    }
  }

  .policy_row {
    display: flex;
    align-items: center;
    margin-top: 26px;
    text-align: left;

    input {
      display: none;
    }

    label {
      display: block;
      width: 24px;
      height: 24px;
      min-width: 24px;
      border: 1.5px solid $InputSelectedTextColor;
      border-radius: 4px;
      flex: none;
      margin-right: 16px;
      position: relative;
      cursor: pointer;

      @media screen and (max-width: 600px) {
        width: 16px;
        height: 16px;
        min-width: 16px;
        border: 1px solid #9F9F9F;
        border-radius: 4px;
      }
    }

    & input:checked + label:after {
      content: '';
      display: block;
      width: 12px;
      min-width: 12px;
      height: 12px;
      border-radius: 100%;
      background: $InputSelectedTextColor;
      mask: url('../../../../../img/arrow-checked.svg') center no-repeat;
      mask-size: 12px 8px;
      -webkit-mask: url('../../../../../img/arrow-checked.svg') center no-repeat;
      -webkit-mask-size: 12px 8px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);

      @media screen and (max-width: 600px) {
        width: 8px;
        height: 8px;
        min-width: 8px;
        background-size: contain;
      }
    }

    @media screen and (max-width: 600px) {
      margin-top: 16px;
    }
  }

  .policy_text {
    font-size: 13px;
    line-height: 150%;
    font-weight: 400;
    background: $buttonBackgroundColor;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    div {
      display: inline;
      cursor: pointer;
      font-weight: 600;
      padding-right: 2px;
      border-bottom: 1px solid transparent;

      &:hover {
        border-bottom: 1px solid $InputSelectedTextColor;
        background: $InputSelectedTextColor;
        -webkit-background-clip: text;
        -webkit-text-fill-color: $InputSelectedTextColor;
        font-weight: 600;
      }

      @media screen and (max-width: 600px) {
        font-size: 11px;
        line-height: 16px;
      }
    }

    span {
      background: $greyColor;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    @media screen and (max-width: 600px) {
      font-size: 11px;
      line-height: 15px;
    }
  }

  .error_text {
    margin-top: 20px;
    color: #eb5757;
    font-weight: 600;
    font-size: 15px;

    @media screen and (max-width: 600px){
      margin-top: 10px;
      font-size: 13px;
    }
  }

  .button {
    width: 270px;
    height: 48px;
    margin-top: 30px;
    background: $backgroundButtonColor;
    box-shadow: 0px 4px 8px rgba(14, 4, 143, 0.24);
    border-radius: 4px;
    text-align: center;
    color: $whiteColor;
    font-weight: 600;
    letter-spacing: 0.02em;
    cursor: pointer;
    font-size: 18px;

    &:hover {
      box-shadow: 0 6px 12px rgba(84, 0, 114, 0.24);
    }

    & img {
      vertical-align: middle;
      margin-bottom: 6px;
      margin-right: 14px;
    }

    @media screen and (max-width: 600px){
      font-size: 16px;
      width: 250px;
      height: 40px;
      margin-top: 20px;
    }
  }
}
