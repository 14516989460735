@import "@sitestyles";

//TODO: вывести цвет в палитру - #9F9F9F
@mixin custom-scrollbar() {


  &::-webkit-scrollbar {
    width: 3px;
    background: #EEE;
    border-radius: 99px;
  }

  &::-webkit-scrollbar-thumb {
    background: #858585;
    border-radius: 99px;
    width: 3px;
  }
}


.popup_support_wrap {
  max-width: 476px;
  width: 476px;
  border-radius: 8px;
  background-color: $whiteColor;
  padding: 32px 40px;
  position: relative;

  @media screen and (max-width: 599px) {
    max-width: calc(100vw - 32px);
    width: calc(100vw - 32px);
    margin: 0 auto;
    padding: 20px;
  }
}

.popup_title {
  font-weight: 500;
  font-size: 20px;
  line-height: 130%;
  text-align: center;
  color: transparent;
  clip-path: inset(1px);
  outline: 0;
  background: $buttonBackgroundColor;
  -webkit-background-clip: text;
  -webkit-box-decoration-break: clone;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  border: 0;
  margin-bottom: 8px;
  letter-spacing: -0.2px;

  @media screen and (max-width: 599px) {
    font-size: 16px;
    letter-spacing: -0.16px;
  }
}

.popup_text {
  color: #9F9F9F;
  text-align: center;
  font-size: 16px;
  line-height: 150%;
  margin-bottom: 16px;

  @media screen and (max-width: 600px) {
    font-size: 14px;
    margin-bottom: 24px;
  }
}

.popup_fields {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.popup_support_input_text {
  display: block;
  width: 100%;
  border: 1px solid transparent;
  border-radius: 5px;
  padding: 13px 16px;
  outline: none;
  background: #F8F8F8;
  height: 48px;
  font-family: $mainFont;
  font-size: 16px;
  line-height: 120%;
  font-weight: 600;
  color: $InputSelectedTextColor;
  transition: all .6s ease-in-out;

  &::placeholder {
    font-family: $mainFont;
    font-size: 16px;
    line-height: 120%;
    color: #9F9F9F;
    font-weight: 400;

    @media screen and (max-width: 600px) {
      font-size: 14px;
    }
  }

  &.invalid {
    background-color: $springSunsetFlowerColor;
  }

  &:hover {
    border-color: #858585;
  }

  &:focus {
    color: #4F4F4F;
    background: #F4F4F4;
    border-color: #858585;
  }
}

.textarea_container {
  position: relative;
  border: 1px solid transparent;
  background: #F8F8F8;
  border-radius: 6px;
  color: #6766DC;
  transition: all .3s ease-in-out;

  &:hover {
    border-color: #858585;
  }

  &:focus {
    background: #F4F4F4;
    border-color: #858585;

    & + .textarea_counter {
      background: #F4F4F4;
    }
  }

  &.invalid {
    background-color: #FADDDD;
  }
}

.textarea_counter {
  font-size: 14px;
  font-weight: 400;
  line-height: 120%;
  box-sizing: border-box;
  background: transparent;
  height: 41px;
  color: #9F9F9F;
  border-radius: 0 0 8px 8px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 13px 16px;
  width: calc(100% - 20px);
  transition: all .6s ease-in-out;

  &.invalid {
    color: #F46060;
  }
}

.popup_support_textarea {
  display: block;
  width: 100%;
  resize: none;
  outline: none;
  border: 1px solid transparent;
  border-radius: 5px;
  padding: 12px 16px;
  background: transparent;
  height: 160px;
  font-family: $mainFont;
  font-size: 16px;
  line-height: 120%;
  font-weight: 600;
  color: $accentRightColor;
  transition: all .6s ease-in-out;
  @include custom-scrollbar();

  &::placeholder {
    font-family: $mainFont;
    font-size: 16px;
    line-height: 120%;
    color: #9F9F9F;
    font-weight: 400;

    @media screen and (max-width: 600px) {
      font-size: 14px;
    }
  }

  &:focus {
    color: #4F4F4F;
  }

  @media screen and (max-width: 600px) {
    font-size: 14px;
    line-height: 20px;
    height: 120px;
  }
}

.popup_support_file_input {
  display: none;
}

.popup_support_file_input_label {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  font-size: 16px;
  line-height: 120%;
  padding: 0 24px;
  width: 100%;
  border: 1px solid #9F9F9F;
  color: #4F4F4F;
  background: #FFF;
  border-radius: 4px;
  text-align: center;
  cursor: pointer;
  gap: 8px;

  &:hover,
  &:focus {
    background: $accentRightColor;
    color: #FFFFFF;
    border-color: $accentRightColor;

    svg {
      fill: #FFFFFF;
    }
  }

  svg {
    fill: #4F4F4F;
    width: 24px;
    height: 24px;
  }

  @media screen and (max-width: 600px) {
    font-size: 14px;
  }
}

.file_uploaded {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  align-items: center;
  margin-top: 4px;
}

.file_uploaded_name {
  color: $InputSelectedTextColor;
  font-weight: 600;
  margin-right: 8px;
  font-size: 14px;
  line-height: 120%;
  display: flex;
  align-items: center;

  &:hover .file_uploaded_name_text {
    opacity: 0.8;
    max-width: 150px;
  }
}

.file_uploaded_name_text {
  max-width: 82px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
  transition: all .6s ease-in-out;
}

.file_uploaded_size {
  font-size: 12px;
  color: $defaultTextColorAlpha6;
  margin-right: 8px;
}

.file_uploaded_text {
  color: #4F4F4F;
  margin-right: 8px;
  display: flex;
  font-size: 14px;
  line-height: 120%;
  font-weight: 600;
  align-items: center;
  justify-content: flex-start;

  @media screen and (max-width: 420px) {
    width: 100%;
  }

  &:before {
    content: '';
    display: block;
    background: url('../../../../../img/file_uploaded.svg') center no-repeat;
    background-size: contain;
    margin-right: 12px;
    width: 16px;
    height: 16px;
  }

  @media screen and (max-width: 600px) {

  }
}


.file_uploaded_button_remove {
  background: #858585;
  mask: url('../../../../../img/file_uploaded_delete.svg') center no-repeat;
  mask-size: contain;
  width: 16px;
  height: 16px;
  min-width: 16px;
  cursor: pointer;
  margin-left: auto;
  border: 0;
  outline: 0;
  transition: all .6s ease-in-out;

  &:hover,
  &:focus {
    background: #4F4F4F;
  }
}


.popup_support_send_info_text {
  font-size: 12px;
  line-height: 1.5;
  text-align: center;
  color: $defaultTextColorAlpha6;

  a {
    color: $accentRightColor;
  }
}

.popup_button_send {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 48px;
  margin: 4px 0 0;
  background-clip: padding-box, border-box;
  background-origin: border-box;
  font-family: $mainFont;
  border: double 1px transparent;
  border-radius: 4px;
  color: $whiteColor;
  text-align: center;
  font-weight: 700;
  font-size: 16px;
  line-height: 120%;
  padding: 0 24px;
  background-image: $buttonBackgroundColor, linear-gradient(to right, $accentLeftColor, $accentRightColor);

  &:hover {
    background-image: linear-gradient(white, white), linear-gradient(to right, $accentLeftColor, $accentRightColor);


    .popup_button_send_text {
      background: $buttonBackgroundColor;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }
  }
}

.popup_button_send_text {
  background: #FFFFFF;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  clip-path: inset(1px);
}

.popup_button_close {
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
  background: transparent;
  width: 40px;
  height: 40px;
  border: 0;
  outline: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all .6s ease-in-out;
  border-radius: 8px;

  svg {
    width: 20px;
    height: 20px;
    fill: $tutorialCloseButtonGreyColor;
    transition: all .6s ease-in-out;
  }

  &:hover,
  &:focus {
    svg {
      fill: $tutorialButtonGreyColor;
    }
  }
}