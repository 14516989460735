@import "@sitestyles";

.contacts_wrap {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  row-gap: 24px;

  @media screen and (max-width: 599px){
    row-gap: 20px;
  }
}

.contacts_wrapper {
  display: flex;
  column-gap: 36px;

  @media screen and (max-width: 899px){
    flex-direction: column;
    row-gap: 32px;
  }
}

.contacts_info,
.contacts_text {
  width: 50%;
  display: flex;
  flex-direction: column;
  row-gap: 24px;

  @media screen and (max-width: 899px){
    width: 100%;
    row-gap: 12px;
  }
}

.contacts_title_wrapper {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 20px;
  letter-spacing: 0.06em;
  color: $primaryColor;
  column-gap: 30px;

  svg {
    transition: 0.3s;
    fill: $primaryColor;
    position: relative;
    z-index: 1;
    width: 32px;
    height: 32px;
    min-width: 32px;
    min-height: 32px;

    @media screen and (max-width: 599px){
      width: 24px;
      height: 24px;
      min-width: 24px;
      min-height: 24px;
    }
  }

  @media screen and (max-width: 1599px) {
    column-gap: 16px;
  }
  @media screen and (max-width: 699px) {
    column-gap: 12px;
  }
}

.contacts_title_image {
  position: relative;
  margin-left: 4px;
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;

  svg {
    position: relative;
    z-index: 1;
    transition: 0.3s;
    fill: $accentLeftColor;
    width: 100%;
    height: 100%;
  }

  &::after {
    content: "";
    width: 40px;
    height: 40px;
    border-radius: 50%;
    position: absolute;
    left: -33%;
    right: 0;
    bottom: 0;
    top: -33%;
    background-color: $background1Color;
  }
}

.contacts_title {
  font-weight: 600;
  font-size: 20px;
  letter-spacing: 0.06em;
  color: $primaryColor;

  @media screen and (max-width: 1599px) {
    font-size: 16px;
  }
}

.contacts_title {
  text-transform: uppercase;
}

.contacts_info_title {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.06em;
  color: $accentLeftColor;

  @media screen and (max-width: 1599px) {
    font-size: 14px;
  }
}

.contacts_info_row {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  row-gap: 16px;

  @media screen and (max-width: 1499px) {
    flex-wrap: wrap;
  }

  @media screen and (max-width: 699px) {
    flex-direction: column;
  }
}

.contacts_info_col {
  &:last-child {
    .contacts_info_value {
      border-bottom: none;
    }
  }

  @media screen and (max-width: 1499px) {
    display: flex;
    flex-direction: column;
  }

  @media screen and (max-width: 699px) {
    width: 100%;
  }
}

.contacts_info_value_wrap {
  display: flex;
  height: 48px;
  position: relative;
  margin-top: 24px;

  @media screen and (max-width: 699px) {
    width: 100%;
  }
}

.contacts_info_value_name {
  position: absolute;
  text-transform: uppercase;
  font-size: 14px;
  color: #494F5B;
  font-weight: 500;
  top: -20px;
  left: 0;

  @media screen and (max-width: 1299px) {
    font-size: 12px;
  }
}

.contacts_info_value {
  width: 100%;
  font-size: 16px;
  color: #494F5B;
  font-weight: 500;
  padding: 12px 0;
  border-bottom: 1px solid #CECECE;

  @media screen and (max-width: 1799px) {
    width: 100%;
  }

  @media screen and (max-width: 1699px) {
    width: 100%;
  }

  @media screen and (max-width: 1299px) {
    width: 100%;
    font-size: 14px;
  }
}

.contacts_info_value_btn {
  width: 100px;
  height: 40px;
  background-color: transparent;
  box-sizing: border-box;
  border-radius: 5px;
  color: $accentLeftColor;
  font-size: 16px;
  font-weight: 600;
  display: flex;
  column-gap: 8px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.3s;
  position: absolute;
  top: 0;
  left: 100%;
  right: 0;
  bottom: 0;
  transform: translate(-100%);

  svg {
    transition: 0.3s;
    stroke: $accentLeftColor;
    width: 24px;
    height: 24px;
  }

  &:hover, &:focus {
    background-color: #F3F3F3;
  }

  @media screen and (max-width: 1299px) {
    font-size: 14px;
  }
}

.contacts_benefit {
  margin-bottom: 16px;
}

.contacts_benefit_title {
  font-weight: bold;
  font-size: 18px;
  color: $accentLeftColor;
  margin-top: 20px;
  margin-bottom: 20px;

  @media screen and (max-width: 1599px) {
    font-size: 14px;
  }
}

.contacts_benefit_row {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.contacts_benefit_icon {
  width: 48px;
  height: 52px;
  min-width: 48px;
  margin-right: 10px;
  position: relative;
  z-index: 0;

  &::before {
    content: '';
    width: 48px;
    height: 48px;
    border-radius: 50%;
    z-index: -1;
    background-color: #EBEDFA;
    position: absolute;
  }

  @media screen and (max-width: 1299px) {
    svg {
      width: 26px;
      height: 26px;
    }
  }
}

.contacts_benefit_icon svg {
  width: 40px;
  height: 40px;
  margin-right: 10px;
  fill: $accentLeftColor;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  transform: translate(4px, 4px);
}

.contacts_text,
.contacts_benefit_text {
  font-size: 16px;
  color: $accentLeftColor;
  font-weight: 600;

  @media screen and (max-width: 1599px) {
    font-size: 14px;
  }

  @media screen and (max-width: 1299px) {
    font-size: 12px;
  }
}

.contacts_text_list {
  list-style: none;
  display: flex;
  flex-direction: column;
  row-gap: 16px;

  li:first-child {
    margin-top: 8px;
  }

  @media screen and (max-width: 599px) {
    row-gap: 8px;
  }
}

.contacts_text_list_item {
  display: flex;
  min-height: 54px;
  align-items: center;
  padding: 8px 0;
  gap: 30px;

  @media screen and (max-width: 1799px) {
    gap: 24px;
  }
}

.contacts_text_list_item_text {
  font-weight: 600;
  font-size: 16px;
  color: #494F5B;

  @media screen and (max-width: 1299px) {
    font-size: 14px;
  }
}

.contacts_verify_status {
}

.contacts_verify_status_title {
  color: $accentLeftColor;
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 16px;

  @media screen and (max-width: 1599px) {
    font-size: 14px;
  }
}

.contacts_verify_status_row {
  background-color: #EBEDFA;
  display: flex;
  padding: 16px 0;
  margin-bottom: 20px;
  border-radius: 5px;

  @media screen and (max-width: 799px) {
    flex-wrap: wrap;
  }

  @media screen and (max-width: 599px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.contacts_verify_status_col {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-right: 2px solid #CACBD4;
  box-sizing: border-box;

  &:nth-last-of-type(1) {
    border-right: none;
  }

  @media screen and (max-width: 799px) {
    flex: none;
    border-right: none;
    width: 50%;
    &:nth-last-of-type(1) {
      width: 100%;
      margin-top: 16px;
    }
  }

  @media screen and (max-width: 599px) {
    width: 100%;
    margin-bottom: 12px;

    &:nth-last-of-type(1) {
      margin-top: 0;
    }
  }
}

.contacts_verify_status_name {
  font-size: 18px;
  font-weight: 600;
  color: $accentLeftColor;
  display: flex;
  align-items: center;
  margin-bottom: 16px;

  svg {
    margin-right: 10px;
  }

  &.verified {
    color: #828282;

    path {
      fill: #828282;
    }
  }

  &.shield_name.verified {
    path:nth-last-of-type(1) {
      stroke: #828282;
    }
  }

  @media screen and (max-width: 1599px) {
    font-size: 14px;
  }

  @media screen and (max-width: 799px) {
    margin-bottom: 8px;
  }

  @media screen and (max-width: 599px) {
    align-self: start;
    margin-left: 10px;
  }
}

.contacts_verify_status_btn {
  width: 200px;
  height: 45px;
  border: 2px solid #828282;
  box-sizing: border-box;
  border-radius: 5px;
  color: #828282;
  font-size: 16px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-transform: uppercase;

  svg {
    width: 20px;
    height: 20px;
    margin-right: 8px;
    fill: #828282;
  }

  &.active {
    color: $whiteColor;
    text-transform: none;
    background: $backgroundButtonColor;
    border: none;

    &:hover {
      box-shadow: $smallBtnShadowHover;
    }
  }

  @media screen and (max-width: 1599px) {
    font-size: 14px;
  }

  @media screen and (max-width: 599px) {
    width: calc(100% - 24px);
  }
}

.contacts_main_btn {
  width: 100%;
  padding: 12px 14px;
  border: 2px solid #828282;
  box-sizing: border-box;
  border-radius: 5px;
  text-align: center;
  color: #828282;
  font-size: 16px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: default;

  &.active {
    color: $whiteColor;
    text-transform: none;
    background: $backgroundButtonColor;
    border: none;
    cursor: pointer;

    &:hover {
      box-shadow: $smallBtnShadowHover;
    }
  }

  @media screen and (max-width: 1599px) {
    font-size: 14px;
  }

  @media screen and (max-width: 599px) {
    font-size: 12px;
    padding: 10px;
  }
}

.shield_phone_svg svg,
.shield_email_svg svg,
.shield_name_svg svg {
  width: 28px;
  height: 28px;
  vertical-align: middle;
  fill: $accentLeftColor;
  stroke-width: 0;
}