@import "@sitestyles";

.popup_verify_status {
  position: relative;
  width: 800px;
  color: #446077;
  font-weight: 500;
  background-color: $whiteColor;
  padding: 16px 27px 19px 16px;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  text-align: left;
  margin: auto;
}

.popup_verify_status_title,
.popup_verify_status_subtitle,
.popup_verify_status_benefit_title,
.popup_verify_status_condition_title {
  font-weight: bold;
  font-size: 28px;
  text-align: center;
  color: $accentLeftColor;
  margin-top: 20px;
  margin-bottom: 20px;
}

.popup_verify_status_subtitle {
  font-size: 22px;
  margin: 20px auto;
}

.popup_verify_status_benefit {

}

.popup_verify_status_benefit_title {
  font-size: 18px;
  text-align: start;
}

.popup_verify_status_benefit_row {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  gap: 10px;
}

.popup_verify_status_benefit_icon {
  position: relative;
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;

  svg {
    position: absolute;
    z-index: 1;
    transition: 0.3s;
    fill: $accentLeftColor;
    width: 35px;
    height: 35px;
    top: 6%;
    left: 6%;
  }

  &::after {
    content: "";
    width: 40px;
    height: 40px;
    border-radius: 50%;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background-color: $background1Color;
  }
}

.popup_verify_status_benefit_text {
  font-size: 16px;
  color: $accentLeftColor;
  font-weight: 600;
}

.popup_verify_status_condition {
}

.popup_verify_status_condition_title {
  font-size: 18px;
  text-transform: uppercase;
}

.popup_verify_status_condition_row {
  background-color: #EBEDFA;
  display: flex;
  padding: 16px 0;
  margin-bottom: 20px;
  border-radius: 5px;
}

.popup_verify_status_condition_col {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-right: 2px solid #CACBD4;
  box-sizing: border-box;

  &:nth-last-of-type(1) {
    border-right: none;
  }
}

.popup_verify_status_condition_name {
  font-size: 18px;
  font-weight: 600;
  color: $accentLeftColor;
  display: flex;
  align-items: center;
  margin-bottom: 16px;

  svg {
    margin-right: 10px;
    width: 28px;
    height: 28px;
    vertical-align: middle;
    fill: $accentLeftColor;
    stroke-width: 0;
  }

  &.verified {
    color: #828282;

    path {
      fill: #828282;
    }
  }

  &.shield_name.verified {
    path:nth-last-of-type(1) {
      stroke: #828282;
    }
  }
}

.popup_verify_status_condition_btn {
  width: 200px;
  height: 45px;
  border: 2px solid #828282;
  box-sizing: border-box;
  border-radius: 5px;
  color: #828282;
  font-size: 16px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-transform: uppercase;

  img {
    width: 20px;
    margin-right: 8px;
  }

  svg {
    width: 20px;
    height: 20px;
    fill: #828282;
    margin-right: 8px;
  }

  &.active {
    color: $whiteColor;
    text-transform: none;
    background: $backgroundButtonColor;
    border: none;

    &:hover {
      box-shadow: $smallBtnShadowHover;
    }
  }
}

.popup_verify_status_condition_value_row {
  color: #828282;
  font-weight: 600;
  font-size: 15px;
  margin-bottom: 25px;
  display: flex;
  justify-content: space-between;
}

.popup_verify_status_condition_value_col {
  width: calc(33.33% - 10px);
}

.popup_verify_status_condition_value_text {
  font-size: 14px;
  font-weight: bold;
  background-color: #EBEDFA;
  padding: 14px 12px;
  border-radius: 4px;
  margin-top: 5px;
  height: 47px;
}

.popup_verify_status_main_btn {
  width: 100%;
  padding: 12px 14px;
  border: 2px solid #828282;
  box-sizing: border-box;
  border-radius: 5px;
  text-align: center;
  color: #828282;
  font-size: 16px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &.active {
    color: $whiteColor;
    text-transform: none;
    background: $backgroundButtonColor;
    border: none;

    &:hover {
      box-shadow: $smallBtnShadowHover;
    }
  }
}

.popup_verify_status_close {
  position: absolute;
  right: 9px;
  top: 9px;
  padding: 5px;
  cursor: pointer;

  img {
    display: block;
    width: 21px;
  }

  svg {
    width: 28px;
    height: 28px;
    fill: #ccd2d9;
    stroke: #ccd2d9;
    stroke-width: 0.8;
  }
}

//Media styles ******************************

@media screen and (max-width: 1249px) {
  .popup_verify_status_title {
    font-size: 24px;
    margin: 10px 0;
  }

  .popup_verify_status {
    width: 600px;
    padding: 12px;
    overflow-y: scroll;
  }

  .popup_verify_status_condition_row {
    flex-wrap: wrap;
  }

  .popup_verify_status_condition_col {
    width: 50%;
    flex: none;
    border-right: none;

    &:nth-last-of-type(1) {
      margin-top: 20px;
      width: 100%;
    }
  }

  .popup_verify_status_condition_name {
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 720px) {
  .popup_verify_status {
    width: 100%;
    height: 100%;
    padding: 10px;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
  }

  .popup_verify_status_close {
    img {
      width: 17px;
    }

    svg {
      width: 24px;
      height: 24px;
    }
  }
}

@media screen and (max-height: 719px) {
  .popup_verify_status {
    height: 100%;
    overflow-y: auto;
  }
}

@media screen and (max-width: 599px) {
  .popup_verify_status_title {
    font-size: 20px;
    margin: 24px 0;
  }

  .popup_verify_status_subtitle,
  .popup_verify_status_benefit_title,
  .popup_verify_status_condition_title {
    font-size: 16px;
    width: 100%;
    margin: 10px 0;
  }

  .popup_verify_status_benefit_text {
    font-size: 14px;
  }

  .popup_verify_status_condition_row {
    padding: 10px 14px;
  }

  .popup_verify_status_condition_col {
    width: 100%;
    margin-bottom: 16px;

    &:nth-last-of-type(1) {
      margin-top: 0;
    }
  }

  .popup_verify_status_condition_name {
    align-self: start;
    font-size: 14px;

    svg {
      margin-right: 5px;
    }
  }

  .popup_verify_status_condition_btn {
    width: 100%;
    font-size: 14px;
  }

  .popup_verify_status_condition_value_row {
    flex-direction: column;
  }

  .popup_verify_status_condition_value_col {
    width: 100%;
  }

  .popup_verify_status_main_btn {
    font-size: 14px;
  }
}

@media screen and (max-width: 899px) and (max-height: 450px) {
  .popup_verify_status {
    height: 100%;
    overflow-y: auto;
  }
}


.shield_email_svg {
  fill: $accentLeftColor;
}

.shield_phone_svg {
  fill: $accentLeftColor;
}

.shield_name_svg {
  fill: $accentLeftColor;
}