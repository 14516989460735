@import "@sitestyles";

@media (hover: none) and (pointer: coarse) {
  .c2_item,
  .c2_new_item_big {
    margin-bottom: 5px;
  }

  @media screen and (min-width: 1000px) {

  }

  @media screen and (min-width: 1140px) {
    .c2_chat_categories {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      padding-top: 10px;
    }
  }

  @media screen and (min-width: 600px) {
    .c2_chat_categories_btn {
      height: 35px;
      font-size: 14px;
      line-height: 36px;
      padding: 0 12px;
    }
  }


}

@media screen and (min-width: 600px) and (max-width: 999px) {
  .c2_item_text {
    width: 100%;
    padding-right: 50px;
    //height: auto;
    max-height: 38px;
  }

  .c2_item_name {
    font-size: 18px;
  }

  .c2_item_new_message {
    width: 70px;
    height: 25px;
    font-size: 12px;
  }
}

@media screen and (max-width: 1139px) {
  .c2_chat_categories {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding-top: 10px;
  }
}

@media screen and (max-width: 1139px) {
  .overlay.active ~ .chat_wrap .column-2.column_2_chat_page.active {
    z-index: 3;
  }

  .column-2.column_2_chat_page {
    width: 100%;
    left: -100%;
    transition: .5s;
  }

  .column-2.column_2_chat_page.hide_for_new_mobile_chat {
    left: -100%;
    z-index: 4;
    display: none;
  }

  .column-2.column_2_chat_page.active {
    left: 0;
  }

  .column-3.column_3_chat_page {
    position: absolute;
    right: 0;
    top: 64px;
    height: calc(100svh - 64px - 64px);
    transition: .5s;
    width: 100%;
  }

  .column-3.column_3_chat_page.send_chat {
    top: 56px;
    height: calc(100vh - 112px);
  }

  .column-2.column_2_chat_page.active ~ .column-3.column_3_chat_page {
    right: -100%;
  }

  .column-2.column_2_chat_page.active .c2_close {
    display: none;
  }

  .c2_item_top {
    align-items: center;
  }

  .c2_top_search {
    margin-left: 28px;
  }

  .c2_new_item_big {
    /*background-color: #fff;*/
    color: white;
    padding: 17px 0 16px;
    align-items: center;
    box-shadow: 0px 3px 5px rgba(14, 4, 143, 0.15);
  }

  .c2_new_item_big_img_wrap {
    margin-left: 16px;
    margin-right: 13px;
    border-radius: 50%;
  }

  .c2_new_item_big_bottom {
    display: none;
  }

  .c2_new_item_big_img {
    width: 72px;
    height: 72px;
    border-radius: 100%;
    object-position: top;
    object-fit: cover;
  }

  .c2_new_item_big_middle {
    padding-left: 0;
  }

  .c2_new_item_big_rigth {
    flex-direction: column-reverse;
  }

  .c2_new_item_big_img_wrap:after {
    content: '';
    display: block;
    width: 9px;
    height: 9px;
    background-color: #E0E0E0;
    border-radius: 100%;
    border: 2px solid #446077;
    position: absolute;
    right: 2px;
    bottom: 2px;
  }

  .c2_new_item_big_text {
    width: 100%;
    max-width: unset;
    /*color: #4F4F4F;*/
    padding-right: 50px;
    line-height: 1.5;
  }

  .c2_new_item_big_name {
    font-size: 18px;
    /*color: #474F5C;*/
    font-weight: 500;
  }

  .c2_new_item_big_time {
    position: absolute;
    right: 8px;
    bottom: 8px;
  }

  .c2_top_tumbler {
    margin-right: auto;
    margin-left: 10px;
  }

  .c2_chat_mob_top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 4px;
    padding-right: 12px;
    height: 60px;
    border-bottom: 1px solid #fff;
    margin-bottom: 17px;
  }

  .c2_chat_categories {
    padding-top: 0;
  }

  .c2_top {
    padding: 0;
  }

  .c2_new_item_big_gallery_btn {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .column-3.column_3_chat_page {
    height: calc(100% - 114px);
  }
}

@media screen and (max-width: 699px) {
  .c2_chat_mob_top {
    display: block;
    height: auto;
    border-bottom: none;
    margin-bottom: 10px;
    padding: 0;
    position: relative;
  }

  .c2_chat_categories_wrap {
    overflow-x: auto;
    margin-bottom: 10px;
    padding-left: 12px;
    padding-right: 12px;
    position: relative;
  }

  .c2_chat_mob_top:after {
    content: '';
    display: none;
    width: 71px;
    height: 51px;
    position: absolute;
    right: 0;
    top: 0;
    background: linear-gradient(90deg, rgba(235, 237, 250, 0) 0%, #EBEDFA 83.1%, #EBEDFA 100%);
    pointer-events: none;
  }

  .c2_chat_categories {
    width: max-content;
    min-width: 100%;
    height: 52px;
    border-bottom: 1px solid #fff;
    align-items: center;
    position: relative;
    //padding-right: 40px;
  }

  .c2_top {
    padding: 0 12px;
  }
}

@media screen and (max-width: 599px) {
  .column-3.column_3_chat_page {
    top: 50px;
    height: calc(100% - 100px);
  }

  .c2_new_item_big_name {
    font-size: 15px;
    margin-bottom: 0;
    font-weight: 600;
  }

  .c2_item,
  .c2_new_item_big {
    padding: 12px;
    margin-bottom: 5px;
  }

  .c2_item_top {
    padding: 0;
  }

  .c2_new_item_big_img_wrap {
    margin-left: 0;
  }

  .c2_item_photo,
  .c2_new_item_big_img {
    width: 52px;
    height: 52px;
  }

  .c2_item_photo_wrap:after {
    width: 10px;
    height: 10px;
    right: 0;
    bottom: 0;
  }

  .c2_item_name {
    font-weight: 600;
    font-size: 15px;
    margin-bottom: 0;
  }

  .c2_item_text,
  .c2_new_item_big_text {
    line-height: 1.4;
    width: 100%;
    padding-right: 55px;
    overflow: hidden;
    word-break: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    height: auto;
  }

  .c2_item .c2_item_photo_delete {
    top: -7px;
    left: -7px;
  }

  .c2_item_photo_delete {
    left: 4px;
    top: 4px;
  }

  .c2_item_bottom {
    padding: 0;
  }

  .c2_list {
    padding-left: 12px;
    padding-right: 8px;
  }
  .c2_list_wrap_2 {
    padding-right: 6px;
  }

  .c2_item_time {
    position: absolute;
    right: 8px;
    bottom: 8px;
  }

  .c2_new_item_big_time {
    margin: 0;
  }

  .c2_item_id {
    display: none;
  }

  .c2_item_bottom {
    margin: 0;
  }
}

@media (orientation: landscape) and (max-width: 900px) and (max-height: 500px) and (min-width: 420px) {
  .column-2.column_2_chat_page {
    width: calc(100% - 95px);
  }

  .column-3.column_3_chat_page {
    width: calc(100% - 95px);
    height: 100%;
    bottom: unset;
    top: 0;
    right: 45px;
    transition: .3s;
  }

  .column-2.column_2_chat_page.active {
    left: 50px;
  }

  .c2_chat_mob_top {
    display: block;
    height: auto;
    border-bottom: none;
    margin-bottom: 12px;
    padding: 0;
  }

  .c2_chat_categories_wrap {
    overflow-x: auto;
    margin-bottom: 10px;
    padding-left: 12px;
    padding-right: 12px;
  }

  .c2_chat_categories {
    width: max-content;
    min-width: 100%;
    height: 52px;
    border-bottom: 1px solid #fff;
    align-items: center;
    justify-content: center;
    padding-right: 0;
  }

  .c2_top {
    padding: 0 12px;
  }

  .c2_top_search input {
    width: 169px;
  }

  .c2_new_item_big_name {
    margin-bottom: 0;
  }

  .c2_chat_mob_top {
    position: relative;
  }

  .c2_chat_mob_top:after {
    display: none;
  }

  .c2_item,
  .c2_new_item_big {
    padding: 12px;
    margin-bottom: 5px;
  }
}

@media screen and (max-width: 1399px) {
  .c3_touch_stickers_wrap.active {
    display: block;
  }

  .c3_touch_smiles_wrap.active {
    display: block;
  }

  .c3_touch_stickers_wrap {
    height: 210px;
    padding-left: 27px;
    width: 100%;
    overflow-y: auto;
    padding-bottom: 10px;
  }

  .c3_touch_stickers_row + .c3_touch_stickers_row {
    margin-top: 14px;
  }

  .c3_touch_stickers_head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 27px;
    margin-bottom: 7px;
  }

  .c3_touch_stickers_head_title {
    font-weight: 600;
    font-size: 16px;
    color: #446077;
  }

  .c3_touch_stickers_head_price {
    font-size: 9px;
    text-align: right;
    color: rgba(51, 51, 51, 0.3);
  }

  .c3_touch_stickers_list_wrap {
    width: 100%;
    overflow-x: auto;

    &.mobile {
      overflow-x: hidden;
    }
  }

  .c3_touch_stickers_list {
    width: max-content;
    display: flex;
    padding-bottom: 3px;

    &.mobile {
      padding-bottom: 0;
      width: 100%;
      max-width: 100%;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-column-gap: 8px;
      grid-row-gap: 8px;
    }
  }

  .c3_touch_stickers_list_item {
    width: 110px;
    height: 110px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 6px;
  }

  .c3_touch_stickers_list_item img {
    width: 110px;
  }

  .c3_touch_smiles_wrap {
    margin-top: 5px;
    padding: 0 15px;
  }

  .c3_touch_smiles_list {
    height: 134px;
    display: flex;
    flex-wrap: wrap;
    overflow-y: auto;
  }

  .c3_touch_smiles_item {
    width: 55px;
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .c3_touch_smiles_item img {
    width: 35px;
  }

  .c3_write_msg.smiles_open .c3_sticker_wrap {
    opacity: .5;
    filter: brightness(0);
  }

  .c3_write_msg.smiles_open .c3_attach {
    opacity: .5;
    filter: brightness(0);
  }

  .c3_write_msg.stickers_open .c3_smile_btn_wrap2 {
    opacity: .5;
    filter: brightness(0);
  }

  .c3_write_msg.stickers_open .c3_attach {
    opacity: .5;
    filter: brightness(0);
  }

  .c3_touch_stickers_head_price.mob {
    display: none;
  }
}

@media screen and (max-width: 799px) {

}

@media screen and (max-width: 599px) {
  .c3_touch_stickers_wrap {
    height: 142px;
    padding-left: 15px;
  }

  .c3_touch_stickers_head_title {
    font-size: 14px;
    white-space: nowrap;
  }

  .c3_touch_stickers_list_item {
    width: 80px;
    height: 80px;
    margin-right: 4px;
  }

  .c3_touch_stickers_list_item img {
    width: 80px;
    height: 80px;
    object-fit: contain;
  }

  .c3_touch_stickers_head_price {
    display: none;
  }

  .c3_touch_stickers_head_price.mob {
    display: block;
  }

  .c3_touch_stickers_head {
    padding-right: 12px;
  }

  .c3_touch_smiles_item img {
    width: 21px;
  }

  .c3_touch_smiles_item {
    width: 33px;
    height: 33px;
  }

  .c3_touch_smiles_list {
    padding-left: 10px;
    padding-right: 10px;
  }

  .c3_write_msg.smiles_open .c3_write_msg_wink {
    opacity: .5;
    filter: brightness(0);
  }

  .c3_write_msg.smiles_open .c3_write_msg_like {
    opacity: .5;
    filter: brightness(0);
  }

  .c3_write_msg.stickers_open .c3_write_msg_wink {
    opacity: .5;
    filter: brightness(0);
  }

  .c3_write_msg.stickers_open .c3_write_msg_like {
    opacity: .5;
    filter: brightness(0);
  }

  .c3_touch_smiles_wrap {
    padding: 0;
  }

}

@media screen and (max-width: 370px) {
  .c3_touch_stickers_wrap {
    height: 122px;
  }

  .c3_touch_stickers_list_item {
    width: 65px;
    height: 65px;
  }

  .c3_touch_stickers_list_item img {
    width: 65px;
    height: 65px;
  }
}

@media screen and (max-height: 300px) {
  .column-3.column_3_chat_page {
    top: 50px;
    height: calc(100% - 50px);
    z-index: 100;
  }

  .mob-hide {
    display: none;
  }
}

@media screen and (max-height: 300px) and (min-width: 400px) {
  .column-3.column_3_chat_page {
    top: 0;
    height: 100%;
    z-index: 100;
  }
}

@media screen and (max-width: 599px) {
  .c3_touch_stickers_list.mobile .c3_stickers_list_item {
    width: 100%;
    height: 100%;
    margin-right: 0;
  }
  .c3_touch_stickers_list.mobile .c3_stickers_list_item img {
    width: 100%;
    height: 100%;
  }
  .c3_touch_stickers_wrap.mobile {
    padding: 0 4px;
    margin-top: 9px;
  }
}