@import "@sitestyles";

.online_now_filter_v3_wrap {
  padding-left: 30px;
  padding-right: 12px;
  display: flex;
  flex-direction: column;
}

.online_now_list_wrap{
  flex: 1;
  overflow-y: auto;
  margin-right: 20px;
  padding-right: 23px;
  padding-left: 46px;
}

.online_now_list_wrap::-webkit-scrollbar {
  width: 5px;
  background: #fff;
  border-radius: 3px;
}

.online_now_list_wrap::-webkit-scrollbar-thumb {
  background: $scrollbarThumbColor;
  border-radius: 3px;
}

.online_now_list{
  display: block;
}

.online_now_item{
  padding: 30px 3.42%;
  margin-bottom: 16px;
  box-shadow: 3px 3px 5px rgba(197, 196, 208, 0.64);
  border-radius: 10px;
  background-color: #fff;
  position: relative;
  display: flex;
  align-items: center;
}

.online_now_item_status{
  position: absolute;
  left: -5px;
  top: 12px;
  height: 22px;
  padding: 0 15px;
  background: $likeOnlineBg;
  border-radius: 3px;
  font-weight: 600;
  font-size: 12px;
  color: $likeOnlineColor;
  display: flex;
  align-items: center;
  z-index: 2;
}

.online_now_item_profile{

}

.online_now_item_profile_photo_wrap{
  margin-bottom: 19px;
  position: relative;
  cursor: pointer;
}

.online_now_item_profile_photo{
  display: block;
  width: 216px;
  height: 216px;
  border-radius: 100%;
  object-fit: cover;
  object-position: top;
  margin: 0 auto;
}

.online_now_item_profile_photo_text{
  opacity: 0;
  width: 100%;
  height: 46px;
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255,255,255,.7);
  font-weight: 600;
  font-size: 12px;
  letter-spacing: 0.03em;
  color: #1C2677;
  transition: opacity .3s;
}

.online_now_item_profile_photo_wrap:hover .online_now_item_profile_photo_text{
  opacity: 1;
}

.online_now_item_profile_photo_text svg {
  width: 17px;
  height: 17px;
  fill: #1d2778;
  margin-right: 11px;
}

.online_now_item_profile_info{

}

.online_now_item_profile_name{
  font-weight: 600;
  font-size: 18px;
  color: #3C3C3C;
  text-shadow: 0 1px 1px rgba(255, 255, 255, 0.32);
  text-align: center;
  margin-bottom: 19px;
  cursor: pointer;
}

.online_now_item_profile_buttons{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.online_now_item_profile_buttons > div{
  width: 120px;
  height: 47px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 0.03em;
  cursor: pointer;
  transition: box-shadow .3s;
}

.online_now_item_profile_chat_btn{
  background: $buttonBackgroundColor;
  color: #fff;
  margin-right: 5px;
}

.online_now_item_profile_chat_btn:hover{
  box-shadow: $smallBtnShadowHover;
}

.online_now_item_profile_chat_btn svg{
  width: 24px;
  height: 24px;
  fill: #f5f5fa;
  display: none;
  margin-right: 10px;
}

.online_now_item_profile_like_btn{
  border: 1px solid $accentLeftColor;
}

.online_now_item_profile_like_btn.active{
  background-color: $primaryColor;
  border-color: $primaryColor;
  color: #fff;
  cursor: default;
}

.online_now_item_profile_like_btn.active svg{
  display: none;
  margin-right: 6px;
  width: 25px;
  height: 25px;
  fill: #ffffff;
}

.online_now_item_profile_like_btn.active:hover{
  box-shadow: none;
}

.online_now_item_profile_like_btn:hover{
  box-shadow: $smallBtnShadowHover;
}

.online_now_item_profile_like_btn svg{
  display: none;
  margin-right: 6px;
  width: 25px;
  height: 25px;
  fill: #eb5757;
}

.online_now_item_gallery{
  margin: 0 4%;
  position: relative;
  flex: 1;
  overflow: hidden;
}

.online_now_item_gallery_list_wrap{

}

.online_now_item_gallery_list{
  display: flex;
  align-items: center;
  position: relative;
  padding-right: 22px;
}

.online_now_item_gallery_list:before{
  content: '';
  width: calc(100% - 22px);
  max-width: 523px;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-color: #fff;
  z-index: 3;
}

.online_now_item_gallery_list img{
  width: 163px;
  height: 262px;
  border-radius: 10px;
  position: relative;
  z-index: 3;
  cursor: pointer;
  object-fit: cover;
}

.online_now_item_gallery_list img + img,
.online_now_item_gallery_list > div + div{
  margin-left: 20px;
}

.online_now_item_gallery_list > div{
  overflow: hidden;
  border-radius: 10px;
  flex: none;
}

.online_now_item_gallery_list img:nth-child(4){
  width: 142px;
  height: 230px;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
}

.online_now_item_gallery_list img:nth-child(5){
  width: 128px;
  height: 194px;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}

.online_now_item_gallery_list img:nth-child(5) ~ img{
  display: none;
}

.online_now_item_gallery_btn{
  width: 38px;
  overflow: hidden;
  padding-left: 16px;
  height: 37px;
  display: flex;
  align-items: center;
  background: rgba(255, 255, 255, 0.8);
  border: 1px solid #ECECEC;
  box-sizing: border-box;
  backdrop-filter: blur(4px);
  border-radius: 40px 0 0 40px;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 4;
  white-space: nowrap;
  font-weight: 600;
  font-size: 12px;
  color: #00317B;
  cursor: pointer;
  transition: .5s;
}

.online_now_item_gallery_btn:hover{
  width: 146px;
  padding-left: 20px;
}

.online_now_item_gallery_btn svg{
  min-width: 8px;
  width: 8px;
  height: 15px;
  fill: #01327a;
  margin-right: 12px;
}

.online_now_item_gallery_btn:hover svg{
  margin-right: 10px;
}

.online_now_item_video_wrap{

}

.online_now_item_video{
  position: relative;
  cursor: pointer;
  margin-bottom: 12px;
}

.online_now_item_video_photo{
  //width: 308px;
  width: 100%;
  height: 186px;
  border-radius: 10px;
  object-fit: cover;
  display: block;
}

.online_now_item_video_play_ico {
  width: 86px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: block;
}

.online_now_item_video_play_ico svg{
  width: 86px;
  height: 86px;
  fill: #FFFFFF;
}

.online_now_item_video_buttons{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}

.online_now_item_video_wrap{
  width: 308px;
}

.online_now_item_video_buttons.dis_ask_video{
  //width: 289px;
  display: block;
}

.online_now_item_video_buttons.dis_ask_video > div + div{
  margin-left: 0;
  margin-top: 10px;
}

.online_now_item_video_hello_btn_wrap{
  position: relative;
  flex: 1;
  z-index: 4;
}

.c3_wp_hello_list_wrap{
  width: 308px;
  position: absolute;
  left: 0;
  top: calc(100% - 1px);
  background-color: #EBEDFA;
  padding: 10px 10px 0 10px;
  border-radius: 0 5px 5px 5px;
  border: 1px solid #DFE3FF;
}



.c3_wp_hello_list_title{
  font-size: 14px;
  line-height: normal;
  color: #00317B;
  font-weight: 500;
  margin-bottom: 17px;
  text-align: center;
}

.c3_wp_hello_list{
  padding-right: 5px;
  max-height: 200px;
  overflow-y: auto;
  padding-bottom: 10px;
}

.c3_wp_hello_list::-webkit-scrollbar{
  width: 2px;
  background: #fff;
  border-radius: 3px;
}

.c3_wp_hello_list::-webkit-scrollbar-thumb{
  background: $scrollbarThumbColor;
  border-radius: 10px;
}

.c3_wp_hello_list_item{
  border: 1px solid #eee;
  border-radius: 5px;
  padding: 10px 15px;
  color: #00317b;
  letter-spacing: -0.02em;
  cursor: pointer;
  transition: .3s;
  background-color: #fff;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.05);
  font-size: 14px;
  font-weight: 500;
}

.c3_wp_hello_list_item:hover{
  box-shadow: 0 4px 8px rgba(14,4,143,.14);
}

.c3_wp_hello_list_item + .c3_wp_hello_list_item{
  margin-top: 10px;
}

.online_now_item_video_hello_btn,
.online_now_item_video_letter_btn,
.online_now_item_video_her_video_btn{
  flex: 1;
  height: 42px;
  border: 1px solid $bluePinkColor;
  border-radius: 4px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.03em;
  color: $bluePinkColor;
  cursor: pointer;
  transition: .3s;
}

.online_now_item_video_hello_btn svg {
  width: 16px;
  height: 16px;
  margin-right: 7px;
  fill: $iconHandWaveFillColor;
  stroke-width: 0.7;
  stroke: $iconHandWaveFillColor;
}

.online_now_item_video_letter_btn svg{
  width: 18px;
  height: 18px;
  margin-right: 7px;
  fill: $iconLetterFillColor;
}

.online_now_item_video_her_video_btn svg{
  width: 20px;
  height: 20px;
  margin-right: 7px;
  fill: $iconVideosFillColor;
}

.online_now_item_video_letter_btn.mob{
  display: none;
}

.online_now_item_video_hello_btn.active{
  border-radius: 5px 5px 0 0;
  border-color: #DFE3FF;
  border-bottom-color: transparent;
  z-index: 5;
  position: relative;
  background-color: #EBEDFA;
}

.online_now_item_video_buttons > div + div{
  margin-left: 10px;
}

.online_now_item_video_letter_btn:hover{
  box-shadow: $smallBtnShadowHover;
}

.online_now_item_video_hello_btn:not(.active):hover{
  box-shadow: $smallBtnShadowHover;
}

.online_now_item_video_buttons > div img{
  margin-right: 11px;
}

.online_now_item_video_letter_btn img{
  width: 20px;
}

.online_now_item_video_buttons > div.online_now_item_video_her_video_btn{
  display: none;
}

//@media screen and (max-width: 1899px) and (min-width: 1700px){
//  .online_now_item_gallery_list img:nth-child(4){
//    display: none;
//  }
//
//  .online_now_item_gallery_list img:nth-child(5){
//    display: none;
//  }
//}

@media screen and (max-width: 1799px){
  .online_now_item_gallery_list img{
    width: 128px;
    height: 207px;
  }
}

@media screen and (max-width: 1699px){
  .online_now_list_wrap{
    padding-left: 24px;
    margin-right: 9px;
    padding-right: 12px;
  }

  .online_now_item_video_photo{
    height: 158px;
  }

  .online_now_item_video_wrap{
    width: 289px;
  }

  .c3_wp_hello_list_wrap{
    width: 289px;
  }

  .online_now_item_video_hello_btn,
  .online_now_item_video_letter_btn,
  .online_now_item_video_her_video_btn{
    height: 36px;
    font-size: 13px;
  }

  .online_now_item_video_hello_btn svg {
    width: 16px;
    height: 16px;
  }

  .online_now_item_video_letter_btn svg {
    width: 18px;
    height: 18px;
  }

  .online_now_item_video_her_video_btn svg{
    width: 20px;
    height: 20px;
  }

  .online_now_item_video_buttons > div img{
    width: 16px;
    margin-right: 7px;
  }

  .online_now_item_video_play_ico,
  .online_now_item_video_play_ico svg{
    width: 65px;
    height: 86px;
  }

  .online_now_item_gallery_list img:nth-child(4){
    width: 112px;
    height: 182px;
  }

  .online_now_item_gallery_list img:nth-child(5){
    width: 101px;
    height: 153px;
  }

  .online_now_item_gallery{
    margin: 0 30px;
  }

  .online_now_item_gallery_list{
    justify-content: space-between;
  }
}

@media screen and (max-width: 1449px){
  .online_now_item_status{
    height: 20px;
    padding: 0 9px;
    font-size: 10px;
  }

  .online_now_item{
    padding: 20px 25px;
  }

  .online_now_item_profile_photo_wrap{
    margin-bottom: 14px;
  }

  .online_now_item_profile_photo{
    width: 160px;
    height: 160px;
  }

  .online_now_item_profile_photo_text svg {
    width: 14px;
    height: 14px;
    margin-right: 8px;
  }

  .online_now_item_profile_name{
    font-size: 14px;
    margin-bottom: 14px;
  }

  .online_now_item_profile_buttons > div{
    width: 77px;
    height: 35px;
    font-size: 12px;
  }

  .online_now_item_profile_chat_btn svg,
  .online_now_item_profile_like_btn svg,
  .online_now_item_profile_like_btn.active svg{
      width: 18px;
  }

  .online_now_item_gallery_btn{
    width: 28px;
    height: 30px;
    padding-left: 13px;
    font-size: 10px;
  }

  .online_now_item_gallery_btn:hover{
    width: 125px;
    padding-left: 13px;
  }

  .online_now_item_gallery_btn svg{
    width: 7px;
  }

  .online_now_item_gallery_list img + img{
    margin-left: 15px;
  }
}

@media screen and (max-width: 1399px){
  .online_now_item_video_photo{
    height: 138px;
  }

  .online_now_item_video_wrap{
    width: 229px;
  }

  .c3_wp_hello_list_wrap{
    width: 229px;
  }

  .online_now_item_video_hello_btn,
  .online_now_item_video_letter_btn,
  .online_now_item_video_her_video_btn{
    height: 32px;
    font-size: 11px;
  }

  .online_now_item_video_hello_btn svg {
    width: 14px;
    height: 14px;
  }

  .online_now_item_video_letter_btn svg {
    width: 16px;
    height: 16px;
  }

  .online_now_item_video_her_video_btn svg{
    width: 18px;
    height: 18px;
  }

  .online_now_item_video_buttons > div img{
    width: 14px;
    margin-right: 7px;
  }

  .online_now_item_video_play_ico,
  .online_now_item_video_play_ico svg {
    width: 65px;
    height: 65px;
  }
}

@media screen and (max-width: 1249px) and (min-width: 1200px), screen and (max-width: 899px){

  .online_now_item_video_photo{
    height: 98px;
  }

  .online_now_item_video_wrap{
    width: 162px;
  }

  .online_now_item_video_play_ico,
  .online_now_item_video_play_ico svg {
    width: 50px;
    height: 50px;
  }

  .online_now_item_video_buttons{
    display: block;
  }

  .c3_wp_hello_list_wrap{
    width: 162px;
  }

  .online_now_item_video_hello_btn,
  .online_now_item_video_letter_btn,
  .online_now_item_video_her_video_btn{
    height: 36px;
  }

  .online_now_item_video_buttons > div + div{
    margin-left: 0;
  }

  .online_now_item_video_hello_btn{
    margin-bottom: 6px;
  }
}

@media screen and (max-width: 799px) and (min-width: 700px){
  .online_now_item_gallery_list img:nth-child(3){
    display: none;
  }
}

@media screen and (max-width: 699px){

  .online_now_list_wrap{
    padding-left: 10px;
    padding-right: 10px;
    margin-right: 0;
  }

  .online_now_item{
    display: block;
    padding: 16px;
  }

  .online_now_item_status{
    left: unset;
    right: -3px;
    top: 5px;
  }

  .online_now_item_profile{
    display: flex;
    align-items: center;
    margin-bottom: 13px;
  }

  .online_now_item_profile_photo_wrap{
    margin-right: 11px;
    margin-bottom: 0;
  }

  .online_now_item_profile_photo{
    width: 95px;
    height: 95px;
  }

  .online_now_item_profile_photo_text{
    font-size: 10px;
    height: 30px;
    opacity: 1;
  }

  .online_now_item_profile_photo_text svg {
    width: 10px;
    height: 10px;
    margin-right: 5px;
  }

  .online_now_item_profile_name{
    margin-bottom: 9px;
    text-align: left;
    font-size: 16px;
  }

  .online_now_item_profile_chat_btn{
    margin-right: 6px;
  }

  .online_now_item_profile_info{
    flex: 1;
  }

  .online_now_item_profile_buttons{
    justify-content: flex-start;
  }

  .online_now_item_profile_buttons > div{
    max-width: 120px;
    flex: 1;
    white-space: nowrap;
  }

  .c3_wp_hello_list_wrap{
    width: calc(200% + 12px);
  }

  .online_now_item_video_hello_btn,
  .online_now_item_video_letter_btn,
  .online_now_item_video_her_video_btn{
    height: 36px;
    font-size: 13px;
  }

  .online_now_item_profile_chat_btn svg,
  .online_now_item_profile_like_btn svg,
  .online_now_item_profile_like_btn.active svg,
  .online_now_item_video_her_video_btn svg{
    display: block;
  }

  .online_now_item_gallery{
    margin: 0 0 13px;
    overflow: visible;
    overflow-x: auto;
  }

  .online_now_item_gallery_list_wrap{
    width: max-content;
  }

  .online_now_item_gallery_list{
    justify-content: flex-start;
    width: max-content;
    padding-right: 0;
  }

  .online_now_item_gallery_list:before{
    display: none;
  }

  .online_now_item_gallery_list img{
    width: 77px;
    height: 126px;
  }

  .online_now_item_gallery_list img + img{
    margin-left: 8px;
  }

  .online_now_item_gallery_list img:nth-child(4),
  .online_now_item_gallery_list img:nth-child(5){
    position: static;
    width: 77px;
    height: 126px;
    transform: unset;
    right: unset;
    top: unset;
  }

  .online_now_item_gallery_list img:nth-child(5) ~ img{
    display: block;
  }

  .online_now_item_gallery_btn{
    display: none;
  }

  .online_now_item_video_wrap{
    width: 100%;
  }

  .online_now_item_video_buttons.dis_ask_video{
    display: flex;
  }

  .online_now_item_video_buttons.dis_ask_video > div + div{
    margin-top: 0;
    margin-left: 10px;
  }
  //.online_now_item_video_buttons.dis_ask_video > div.online_now_item_video_hello_btn_wrap + .online_now_item_video_letter_btn{
  //  display: flex;
  //}

  .online_now_item_video_buttons.dis_ask_video .online_now_item_video_her_video_btn{
    display: none;
  }

  .online_now_item_video{
    display: none;
  }

  .online_now_item_video_buttons{
    display: flex;
    justify-content: center;
  }

  .online_now_item_video_buttons > div{
    flex: none;
    width: calc(50% - 4px);
  }

  .online_now_item_video_buttons > div + div{
    margin-left: 10px;
  }

  .online_now_item_video_hello_btn{
    margin-bottom: 0;
  }

  .online_now_item_video_buttons > div.online_now_item_video_her_video_btn{
    display: flex;
  }

  .online_now_item_video_buttons > div.online_now_item_video_her_video_btn img{
    width: 15px;
  }

  .online_now_item_video_buttons > div.online_now_item_video_hello_btn_wrap + .online_now_item_video_letter_btn{
    display: none;
  }

  .online_now_item_video_buttons > div.online_now_item_video_hello_btn_wrap ~ .online_now_item_video_letter_btn.mob{
    display: flex;
  }
}

@media screen and (max-width: 399px){
  .online_now_item_profile_chat_btn svg,
  .online_now_item_profile_like_btn svg,
  .online_now_item_profile_like_btn.active svg{
    display: none;
  }

  .online_now_item_profile_buttons > div.online_now_item_profile_like_btn{
     max-width: 60px;
  }
}