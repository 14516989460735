p img.faq_answer_img_pc{
  display: block;
  cursor: zoom-in;
}

p img.faq_answer_img_mob{
  display: none;
}

@media screen and (max-width: 767px){
  p img.faq_answer_img_pc{
    display: none;
  }

  p img.faq_answer_img_mob{
    display: block;
  }
}