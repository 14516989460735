@import '@sitestyles';

.support_feedback_modal {
    width: 600px;
    height: auto;
    border-radius: 10px;
    background: $tutorialPopupBackground;
    position: fixed;
    box-sizing: border-box;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: 0.7s;
    z-index: 999999;

    &.success {
        width: 480px;
        align-items: center;

        .support_feedback_modal_title {
            margin: 24px auto 8px;
        }

        .support_feedback_modal_text {
            margin: 0 0 32px;

            @media screen and (max-width: 620px) {
                font-size: 14px;
                margin: 0 0 24px;
            }
        }

        .support_feedback_modal_header {
            display: none;
        }

        .support_feedback_content {
            align-items: center;
            padding: 40px;

            &:after {
                display: none;
            }

            @media screen and (max-width: 620px) {
                padding: 24px;
            }
        }

        .support_feedback_content::before {
            content: '';
            background-image: url(../../../../../img/success_icon.svg);
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            position: relative;
            width: 64px;
            min-width: 64px;
            min-height: 64px;
            height: 64px;
            top: 0;
            left: 0;
            transform: none;
            border-radius: initial;
            background-color: transparent;

            @media screen and (max-width: 620px) {
                width: 40px;
                min-width: 40px;
                min-height: 40px;
                height: 40px;
            }
        }

        .support_feedback_modal_btn_close {
            top: 0;
        }

        @media screen and (max-width: 520px) {
            width: calc(100% - 32px);
        }
    }

    @media screen and (max-width: 620px) {
        width: 343px;
        padding: 0;
    }

    @media screen and (max-width: 350px) {
        width: calc(100% - 32px);
    }
}

.support_feedback_modal_header {
    height: 72px;
    max-height: 72px;
    border-radius: 10px 10px 0 0;
    background-color: $supportFeedbackModalHeaderBgColor;
    position: relative;

    &:before {
        content: '';
        background-image: url(../../../../../img/support-feedback/support-feedback-modal-header.png);
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;

        @media screen and (max-width: 620px) {
            background-image: url(../../../../../img/support-feedback/support-feedback-modal-header-mobile.png);
            height: 84px;
            max-height: 84px;
        }
    }

    @media screen and (max-width: 620px) {
        height: 84px;
        max-height: 84px;
    }
}

.support_feedback_content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px 40px 40px 40px;
    position: relative;

    &:before {
        content: '';
        background-color: #ffffff;
        border-radius: 50%;
        position: absolute;
        width: 72px;
        height: 72px;
        top: -7%;
        left: 50%;
        transform: translate(-50%, 0%);

        @media screen and (max-width: 620px) {
            top: -40px;
            width: 40px;
            height: 40px;
            transform: translate(-50%, 50%);
        }
    }

    &:after {
        content: '';
        display: block;
        width: 32px;
        height: 32px;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, -50%);
        mask: url(../../../../../img/support-feedback/support-feedback-icon.svg) no-repeat center;
        mask-size: contain;
        mask-repeat: no-repeat;
        mask-position: center;
        background-color: $supportFeedbackTitleColor;

        @media screen and (max-width: 620px) {
            width: 18px;
            height: 18px;
        }
    }

    @media screen and (max-width: 620px) {
        padding: 0 24px 24px 24px;
    }
}

.support_feedback_modal_title {
    color: $supportFeedbackTitleColor;
    font-size: 20px;
    font-weight: 700;
    line-height: normal;
    margin: 26px auto 24px;

    @media screen and (max-width: 620px) {
        font-size: 18px;
        margin-bottom: 16px;
    }
}

.support_feedback_modal_text {
    color: $supportFeedbackTextColor;
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
    margin: 0 0 16px;
}

.support_feedback_modal.success .support_feedback_modal_text {
    text-align: center;
    font-weight: 400;
    color: rgba(51, 51, 51, 0.7);
}

.rating_text_row {
    color: $supportFeedbackRatingTextColor;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    margin: 0 0 24px;

    @media screen and (max-width: 620px) {
        justify-content: center;
        margin: 0 0 16px;
    }
}

.rating_textarea_wrapper {
    max-height: 135px;
    height: 135px;
    margin-bottom: 32px;
    position: relative;

    @media screen and (max-width: 620px) {
        margin-bottom: 24px;
    }
}

.rating_textarea {
    border-radius: 4px;
    outline: 0;
    border: 1px solid $textareaBorderColor;
    height: 135px;
    min-height: 135px;
    padding: 12px 12px 32px;
    box-sizing: border-box;
    width: 100%;
    min-width: 100%;
    background-color: $textareaBackgroundColor;
    color: #333333;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    font-family: $mainFont;
    max-height: 300px;
    resize: none;

    &:hover {
        background-color: $textareaBackgroundHoverColor;

        + .rating_textarea_counter {
            background-color: $textareaBackgroundHoverColor;
        }
    }

    &:active,
    &:focus {
        background-color: $textareaBackgroundActiveColor;
        border: 2px solid $textareaBorderActiveColor;
        outline: 4px solid $textareaOutlineColor;

        + .rating_textarea_counter {
            background-color: $textareaBackgroundActiveColor;
        }
    }

    &::placeholder {
        font-family: $mainFont;
        color: #8a9ba9;
        font-size: 14px;
        font-weight: 400;
        line-height: normal;
    }

    &::-webkit-scrollbar {
        width: 3px;
        background: #f7f8fd;
        border-radius: 20px;
    }

    &::-webkit-scrollbar-thumb {
        background: #adbaff;
        border-radius: 20px;
    }
}

.rating_textarea_counter {
    color: #8a9ba9;
    min-height: 31px;
    max-height: 31px;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    position: absolute;
    bottom: 2px;
    padding: 5px 0 7px 10px;
    left: 2px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 98%;
    border-radius: 0 0 4px 4px;
    z-index: 10;
    background-color: $textareaBackgroundColor;
}

.rating_text {
    color: $supportFeedbackRatingTextColor;
    font-size: 14px;
    font-weight: 400;
    line-height: normal;

    @media screen and (max-width: 620px) {
        display: none;
    }
}

.rating_inputs_row {
    display: flex;
    gap: 24px;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    list-style: none;

    @media screen and (max-width: 620px) {
        gap: 22px;
    }
}

.rating_radio {
    display: none;
}

.rating_radio:checked + .rating_radio_label {
    color: $supportFeedbackRadioLabelColorChecked;
    .rating_radio_label_circle_background {
        background: $supportFeedbackRadioLabelColorChecked;
        border: none;
    }
}

.rating_radio_label {
    width: 40px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    row-gap: 4px;
    border-radius: 6px;
    box-sizing: border-box;
    transition: all 0.3s ease-in-out;
    color: #00000080;
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;
    outline: 0;
    margin-bottom: 5px;

    &:hover,
    &:focus {
        color: $supportFeedbackRadioLabelColorChecked;
        .rating_radio_label_circle_background {
            background: $supportFeedbackRadioLabelColorHover;
            border: none;
        }
    }

    @media screen and (max-width: 620px) {
        font-size: 14px;
    }
}

.rating_radio_label_circle_background {
    width: 40px;
    height: 40px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    transition: all 0.3s ease-in-out;
}

.rating_radio_label_emoji {
    width: 32px;
    height: 32px;
    background-size: cover;
}

.rating_radio_label_emoji[data-index='rate_1'], {
    background-image: url(../../../../../img/emoji_unsatisfied.svg);
}

.rating_radio_label_emoji[data-index='rate_2'], {
    background-image: url(../../../../../img/emoji_medium_unsatisfied.svg);
}

.rating_radio_label_emoji[data-index='rate_3'] {
    background-image: url(../../../../../img/emoji_neutral.svg);
}

.rating_radio_label_emoji[data-index='rate_4'] {
    background-image: url(../../../../../img/emoji_medium_satisfied.svg);
    margin-right: 2px;
}

.rating_radio_label_emoji[data-index='rate_5'] {
    background-image: url(../../../../../img/emoji_satisfied.svg);
}

.support_feedback_button_continue {
    width: 100%;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $whiteColor;
    font-weight: 700;
    font-size: 16px;
    line-height: normal;
    border-radius: 8px;
    position: relative;
    margin: 0 auto;
    padding: 0 16px;
    box-sizing: border-box;
    cursor: pointer;
    font-family: $mainFont;
    background-clip: padding-box, border-box;
    background-origin: border-box;
    border: $supportFeedbackButtonBorderHover;
    background-image: $buttonModalBackgroundColor,
        linear-gradient(to right, $accentLeftColor, $accentRightColor);
    transition: all 0.3s ease-in-out;

    @media screen and (max-width: 620px) {
        font-size: 16px;
        height: 40px;
    }

    &:hover {
        background-image: linear-gradient($buttonBackgroundColorHover, $buttonBackgroundColorHover),
            linear-gradient(to right, $accentLeftColor, $accentRightColor);

        .support_feedback_button_continue_text {
            background: $buttonModalBackgroundColor;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;
            position: relative;
            z-index: 1;
        }
    }
}

.support_feedback_button_continue_text {
    background: $buttonColor;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    font-weight: 600;
    font-size: 16px;

    @media screen and (max-width: 620px) {
        font-size: 14px;
    }
}

.support_feedback_button_success {
    width: 100%;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $whiteColor;
    font-weight: 700;
    font-size: 16px;
    line-height: normal;
    border-radius: 8px;
    position: relative;
    margin: 0 auto;
    padding: 0 16px;
    box-sizing: border-box;
    cursor: pointer;
    font-family: $mainFont;
    background-clip: padding-box, border-box;
    background-origin: border-box;
    border: $supportFeedbackButtonBorderHover;
    background-image: $buttonModalBackgroundColor,
        linear-gradient(to right, $accentLeftColor, $accentRightColor);

    @media screen and (max-width: 620px) {
        font-size: 16px;
        height: 40px;
    }

    &:before {
        background: $whiteColor;
        content: '';
        display: block;
        width: 24px;
        min-width: 24px;
        height: 24px;
        margin-right: 12px;
        mask: url('../../../../../img/arrow-checked-circle.svg') center no-repeat;
        mask-size: contain;
    }

    &:hover {
        background-image: linear-gradient($buttonBackgroundColorHover, $buttonBackgroundColorHover),
            linear-gradient(to right, $accentLeftColor, $accentRightColor);

        .support_feedback_button_success_text {
            background: $buttonModalBackgroundColor;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;
            position: relative;
            z-index: 1;
        }

        &:before {
            background: $buttonModalBackgroundColor;
        }
    }
}

.support_feedback_button_success_text {
    background: $whiteColor;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    font-weight: 600;
}

.support_feedback_modal_btn_close {
    width: 40px;
    min-width: 40px;
    height: 40px;
    background: #7e7d8b;
    position: absolute;
    top: -65px;
    right: 8px;
    mask: url('../../../../../img/c3-media-close-tab.svg') center no-repeat;
    mask-size: 12px;
    -webkit-mask: url('../../../../../img/c3-media-close-tab.svg') center no-repeat;
    -webkit-mask-size: 12px;
    outline: 0;
    border: 0;
    cursor: pointer;
    display: flex;

    @media screen and (max-width: 620px) {
        top: -85px;
        right: 0;
    }

    &:hover {
        background: $accentDarkColor;
        transition: all 0.6s ease-in-out;
    }
}

.textarea_error_message {
    color: #f15656;
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    margin: 4px 0 4px 0;
}

.disabled {
    background-image: $buttonBackgroundColorDisabled;
    color: $whiteColor;
    cursor: default;

    .support_feedback_button_continue_text {
        background: $buttonTextColorDisabled;
        background-clip: text;
    }

    &:hover {
        background-image: $buttonBackgroundColorDisabled;
        color: $buttonColor;
        border: $supportFeedbackButtonBorderHover;

        .support_feedback_button_continue_text {
            background: $buttonTextColorDisabled;
            background-clip: text;
        }
    }
}
