.chat_wrap .top_banner {
  position: absolute;
}

.top_banner {
  height: 80px;
  background-color: #49abf9;
  background-image: url(../../../img/confirm/confirm-email-v2-ico.svg);
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.banner_confirm_email {
  cursor: pointer;
}

.top_title_wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  margin-left: 24px;
  font-style: italic;
  font-weight: 700;
  font-size: 34px;
  line-height: 37px;
  letter-spacing: -0.06em;
  color: #FFFFFF;
  text-shadow: 0px 2px 4px rgba(0, 56, 164, 0.49);
  position: relative;
  width: 100%;

  span.sub_desktop {
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 38px;
    letter-spacing: -0.06em;
    color: #FFFFFF;
    text-shadow: 0px 2px 4px rgba(0, 56, 164, 0.49);
  }

  span.sub_mob {
    display: none;
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 38px;
    letter-spacing: -0.06em;
    color: #FFFFFF;
    text-shadow: 0px 2px 4px rgba(0, 56, 164, 0.49);
  }
}

.top_btn_wrap {
  display: flex;
  align-items: center;
}

.top_btn {
  font-size: 16px;
  font-weight: bold;
  padding: 10px 15px;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0 2px 4px rgba(9, 53, 28, 0.42);
  cursor: pointer;
  width: 203px;
  height: 57px;
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
    letter-spacing: -0.03em;
    background: linear-gradient(91.49deg, #4A92FF 0%, #8EBBFF 29.48%, #0050C9 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }
}

.top_close {
  width: 24px;
  height: 24px;
  transform: translateY(-50%);
  background-image: url(../../../img/confirm/cros.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  margin: 51px 25px 25px;
}

@media screen and (max-width: 1930px) {
  .top_title_wrap {
    font-size: 26px;

    span.sub_desktop {
      display: none;
    }

    span.sub_mob {
      font-size: 22px;
      display: flex;
    }
  }
}

@media screen and (max-width: 1439px) {
  .top_title_wrap {
    flex-direction: column;
    align-items: flex-start;
    gap: 0;
    line-height: 22px;
  }
}

@media screen and (max-width: 790px) {
  .top_btn {
    width: 116px;
    height: 34px;

    span {
      font-style: normal;
      font-weight: 700;
      font-size: 10px;
      line-height: 12px;
      text-align: center;
    }
  }

  .top_title_wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0;
    font-style: italic;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.06em;
    color: #FFFFFF;
    text-shadow: 0px 2px 4px rgba(0, 56, 164, 0.49);

    span.sub_desktop {
      display: none;
    }

    span.sub_mob {
      display: flex;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 19px;
      letter-spacing: -0.06em;
      color: #FFFFFF;
      text-shadow: 0px 2px 4px rgba(0, 56, 164, 0.49);
    }
  }
}

@media screen and (max-width: 699px) {
  .top_banner {
    height: 50px;
  }
}

@media screen and (max-width: 400px) {
  .top_btn {
    span {
      font-size: 10px;
    }
  }

  .top_close {
    width: 10px;
    height: 10px;
    margin: 10px 9px 0px;
  }

  .top_title_wrap {
    margin-left: 4px;
    font-size: 16px;
    line-height: 22px;

    span.sub_desktop {
      display: none;
    }

    span.sub_mob {
      display: flex;
      font-size: 14px;
    }
  }
}
