@import "@sitestyles";

.upload_popup_tabs_content_audio_item .rhap_progress-container{
	margin: 0;
}

.upload_popup_tabs_content_audio_item .popup_audio_item_play_btn{
	width: 100%;
	margin-right: 0;
}
.upload_popup_tabs_content_audio_item .popup_audio_item_move_icon svg{
	width: 20px;
	height: 20px;
	fill: $iconMoveFillColor;
}
.upload_popup_tabs_content_audio_item .rhap_container{
	box-shadow: none;
	background-color: unset;
}
.upload_popup_tabs_content_audio_item .rhap_volume-controls{
	display: none;
}
.upload_popup_tabs_content_audio_item .popup_audio_item_top{
	margin-bottom: 0;
}
.upload_popup_tabs_content_audio_item .rhap_current-time{
	position: absolute;
	top: 37px;
	left: 0;
	font-size: 13px;
	line-height: 15px;
	color: $defaultTextColorAlpha6;
}
.upload_popup_tabs_content_audio_item .rhap_total-time{
	position: absolute;
	top: 37px;
	right: 0;
	font-size: 13px;
	line-height: 15px;
	color: $defaultTextColorAlpha6;
}
.upload_popup_tabs_content_audio_item .rhap_progress-section {
	width: calc(100% - 50px);
	left: 50px;
}
.upload_popup_tabs_content_audio_item.playing .rhap_progress-indicator{
	color: $primaryLiteColor;
	display: inline-block;
}
.upload_popup_tabs_content_audio_item.playing .rhap_time{
	color: white;
}

@media screen and (max-width: 599px){
	.upload_popup_tabs_content_audio_item .rhap_play-pause-button{
		width: 26px;
		height: 26px;
	}

	.upload_popup_tabs_content_audio_item .rhap_play-pause-button img{
		width: 100%;
	}

	.upload_popup_tabs_content_audio_item .rhap_progress-section{
		width: calc(100% - 36px);
		left: 36px;
	}
}
