.wrapper {
  background: white;
  width: 100vw;
  height: 100vh;
  @media (max-width: 600px) {
    overflow-y: auto;
  }
}

.content {
  width: 100vw;
  height: calc(100vh - 64px - 4px);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
  padding: 16px 0;
  box-sizing: border-box;

  @media (max-width: 600px) {
    padding: 16px;
    height: fit-content;
  }
}