@import "@sitestyles";

.popup_welcome {
  width: 576px;
  max-width: 100%;
  background-color: #fff;
  box-shadow: 0 25px 50px rgba(25, 39, 60, 0.1);
  border-radius: 10px;
  padding: 40px;
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 16px;

  @media screen and (max-width: 600px) {
    width: 304px;
    padding: 16px;
    grid-row-gap: 12px;
  }
}

.popup_welcome_logo {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 600px) {
    height: 32px;
  }
}

.popup_welcome_logo img {
  width: auto;
  height: 100%;
  display: block;
  max-height: 100%;
}

.popup_welcome_title {
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  color: $modalWelcomeTitleColor;
  margin-bottom: 8px;
  text-align: center;

  @media screen and (max-width: 600px) {
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 4px;
  }
}

.popup_welcome_desc {
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: $modalWelcomeTextColor;
  margin-block-start: 0;
  margin-block-end: 0;

  @media screen and (max-width: 600px) {
    font-size: 12px;
    line-height: 16px;
  }
}

.popup_welcome_new_photo {
  display: block;
  width: 100%;
  height: 227px;
  object-fit: contain;
  object-position: top center;

  @media screen and (max-width: 600px) {
    height: 96px;
  }
}

.popup_welcome_text {
  font-size: 14px;
  line-height: 19px;
  color: $modalWelcomeTextColor;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  @media screen and (max-width: 600px) {
    font-size: 12px;
    line-height: 16px;
  }
}

.popup_welcome_text span {
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: $modalWelcomeTitleColor;
  margin-right: 24px;
  white-space: nowrap;

  @media screen and (max-width: 600px) {
    font-size: 12px;
    line-height: 16px;
    margin-right: 12px;
  }

}

.popup_welcome_free_tips {
  background: $modalWelcomeTipsBackground;
  border-radius: 5px;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  text-transform: capitalize;
  color: $modalWelcomeTipsColor;
  padding: 12px 32px;

  @media screen and (max-width: 600px) {
    padding: 8px;
    font-size: 12px;
    line-height: 16px;
    text-align: left;
    justify-content: flex-start;
  }

  &:before {
    content: '';
    display: block;
    background: $modalWelcomeTipsColor;
    width: 24px;
    height: 24px;
    min-width: 24px;
    mask: url('../../img/welcome-alert/heart.svg') center no-repeat;
    mask-size: contain;
    margin-right: 14px;

    @media screen and (max-width: 600px) {
      margin-right: 8px;
    }
  }
}

.popup_welcome_buttons {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 12px;
  margin-top: 8px;

  @media screen and (max-width: 600px) {
    grid-template-columns: 1fr;
    grid-row-gap: 8px;
    grid-column-gap: unset;
    margin-top: 12px;
  }
}

.popup_welcome_start_btn {
  width: 100%;
  height: 54px;
  box-sizing: border-box;
  padding: 16px 32px;
  font-family: $mainFont;
  border-radius: 5px;
  color: $whiteColor;
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-clip: padding-box, border-box;
  background-origin: border-box;
  border: double 2px transparent;
  background-image: $buttonBackgroundColor, $buttonBackgroundColor;

  @media screen and (max-width: 600px) {
    font-size: 16px;
    line-height: 22px;
    padding: 16px;
    height: 44px;
  }

  &:hover {
    background-image: linear-gradient(white, white), $buttonBackgroundColor;

    .popup_welcome_start_btn_text {
      background: $buttonBackgroundColor;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }
  }
}

.popup_welcome_start_btn_text {
  background: #FFFFFF;
  -webkit-background-clip: text;
  -webkit-text-fill-color: #FFFFFF;
  background-clip: text;
  text-fill-color: #FFFFFF;
  font-weight: 700;
}

.popup_welcome_tutorial_btn {
  width: 100%;
  height: 54px;
  box-sizing: border-box;
  padding: 16px 32px;
  font-family: $mainFont;
  border-radius: 5px;
  color: $whiteColor;
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background-clip: padding-box, border-box;
  background-origin: border-box;
  border: double 2px transparent;
  background-image: linear-gradient(white, white), $buttonBackgroundColor;

  @media screen and (max-width: 600px) {
    font-size: 16px;
    line-height: 22px;
    padding: 16px;
    height: 44px;
  }

  &:hover {
    background-image: $buttonBackgroundColor, $buttonBackgroundColor;

    .popup_welcome_tutorial_btn_text {
      background: #FFFFFF;
      -webkit-background-clip: text;
      -webkit-text-fill-color: #FFFFFF;
      background-clip: text;
      text-fill-color: #FFFFFF;
      font-weight: 700;
    }
  }
}

.popup_welcome_tutorial_btn_text {
  background: $buttonBackgroundColor;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  font-weight: 700;
}

.popup_welcome_close {
  position: absolute;
  right: 12px;
  top: 12px;
  width: 24px;
  height: 24px;
  cursor: pointer;

  @media screen and (max-width: 600px) {
    right: 8px;
    top: 8px;
  }

  &:before {
    content: '';
    display: block;
    background: #C2C2C2;
    mask: url('../../img/welcome-alert/close.svg') center no-repeat;
    mask-size: contain;
    width: 24px;
    height: 24px;
    min-width: 24px;
  }

  &:hover {
    &:before {
      background: $buttonBackgroundColor;
    }
  }
}