.wrapper {
  max-width: 416px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
}

.step {
  color: #757575;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0 0 16px;

  @media (max-width: 600px) {
    font-size: 12px;
  }
}

.title {
  color: #333;
  font-family: "Open Sans", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  margin: 0 0 8px;
  text-align: left;

  @media (max-width: 600px) {
    font-size: 18px;
    text-align: left;
  }
}

.subtitle {
  color: #757575;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  margin: 0 0 48px;

  @media (max-width: 600px) {
    font-size: 14px;
    margin: 0 0 24px;
  }
}

.text {
  color: #333;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  margin: 0 0 72px;

  @media (max-width: 600px) {
    font-size: 14px;
  }
}

.button_row {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 72px;

  @media (max-width: 600px) {
    flex-direction: column;
    margin-top: 48px;
  }
}

.button {
  height: 48px;
  width: 100%;
  max-width: 200px;
  box-sizing: border-box;
  padding: 12px 16px;
  border-radius: 8px;
  background: #212125;
  color: #FFF;
  text-align: center;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  cursor: pointer;
  transition: all .3s ease;

  &:hover {
    background: #363644;
    transition: all .3s ease;
  }

  &.skip {
    border: 1px solid #BFBFBF;
    color: #BFBFBF;
    background: transparent;

    &:hover {
      border: 1px solid #363644;
      color: #363644;
      background: transparent;
      transition: all .3s ease;
    }
  }

  @media (max-width: 600px) {
    max-width: 100%;
    margin: 0 auto;

    &:last-child {
      margin-top: 16px;
    }
  }
}

.onboardingInput {
  width: 100%;

  p {
    color: #333;
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    text-align: left;
    margin: 0 0 8px;
  }
}

.input_wrapper {
  width: 100%;
  position: relative;
  display: flex;
  height: 240px;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 16px;
  max-width: 406px;
  margin: 0 auto;
  overflow-y: auto;
  max-height: 240px;

  &::-webkit-scrollbar-track {
    border-radius: 0;
    margin-left: 15px;
  }

  &::-webkit-scrollbar {
    width: 2px;
    height: 2px;
    background: #EFEFEF;
    margin-right: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: #757575;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
  }
}

.input {
  border-radius: 8px;
  border: 1px solid #E4E4E4;
  background: #F8F8F8;
  width: 100%;
  padding: 12px 16px;
  color: #333333;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  transition: all .3s ease;
  cursor: pointer;
  resize: none;
  height: 120px;

  &::placeholder {
    color: #404752;
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }

  &:hover {
    border: 1px solid #D8D8D8;
    background: #EFEFEF;
    transition: all .3s ease;
  }

  &:focus {
    border: 1px solid #6D6AE5;
    background: #FFF;
    transition: all .3s ease;
  }
}

.list_item {
  width: 104px;
  margin-bottom: 50px;
}

.list_item_image {
  width: 104px;
  height: 104px;
  border-radius: 20px;
  position: relative;
}

.list_item_image_photo {
  object-fit: cover;
  object-position: top;
  width: 104px;
  height: 104px;
  border-radius: 20px;
  cursor: pointer;
}

.list_item_like {
  width: 44px;
  height: 44px;
  position: absolute;
  left: 50%;
  right: 50%;
  transform: translateX(-50%);
  bottom: -21px;
  //background: #EFEFEF;
  border-radius: 15px;
  //border: 3px solid var(--white-100, #FFF);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-image: url("../../../img/onboarding/heart_default.svg");
  transition: all .3s ease;

  &:hover {
    background-image: url("../../../img/onboarding/heart_hover.svg");
    transition: all .3s ease;
  }

  &.liked {
    background-image: url("../../../img/onboarding/heart_active.svg");
    transition: all .3s ease;
  }
}

.list_item_name {
  color: var(--text-title, #333);
  text-align: center;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 20px auto 0;
}