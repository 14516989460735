.profile_about_icon svg {
  width: 36px;
  height: 36px;
}

@media screen and (max-width: 1799px){
  .profile_about_icon svg {
    width: 29px;
    height: 29px;
  }
}
