@import "@sitestyles";

.faq_column_3{
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background-color: #F4F4F4;
  padding-bottom: 16px;

  @media screen and (max-width: 1499px), screen and (max-height: 699px){
    padding-bottom: 12px;
  }

  @media screen and (max-width: 767px){
    padding-bottom: 0;
  }
}

.faq_header{
  height: 80px;
  display: flex;
  align-items: center;
  padding: 0 24px;
  margin-bottom: 16px;
  flex: none;

  background-color: #fff;
  box-shadow: 0 6px 12px rgba(47, 43, 99, 0.16);

  font-size: 16px;
  color: $accentLeftRightColor;

  & > div{
    flex: 1;
  }

  @media screen and (max-width: 1499px), screen and (max-height: 699px){
    height: 60px;
    font-size: 14px;
  }

  @media screen and (max-width: 767px){
    display: none;
  }
}

.header_middle{
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;

  svg{
    margin-right: 16px;

    @media screen and (max-width: 1499px), screen and (max-height: 699px){
      width: 20px;
      height: 20px;
      margin-right: 12px;
    }
  }
}

.header_right{
  display: flex;
  justify-content: flex-end;

  .text{
    font-weight: 600;
    cursor: pointer;
  }
}

.faq_content_row{
  flex: 1;
  display: flex;
  align-items: flex-start;
  overflow-y: auto;
  margin-right: 10px;
  padding-right: 12px;
  padding-bottom: 15px;

  @media screen and (max-width: 1139px){
    margin-right: 5px;
    padding-right: 7px;
  }

  @media screen and (max-width: 767px){
    padding-left: 15px;
    margin-top: 8px;
  }
}

.faq_content_row::-webkit-scrollbar{
  width: 3px;
  height: 3px;
  background: $littleGreyLittleBlueColor;
  border-radius: 5px;
}

.faq_content_row::-webkit-scrollbar-thumb {
  background: $scrollbarThumbColor;
  border-radius: 7px;
}

.faq_bottom_text{
  text-align: center;
  font-weight: bold;
  font-size: 16px;
  color: #42678C;
  margin-top: 15px;

  @media screen and (max-width: 1499px), screen and (max-height: 699px){
    font-size: 14px;
    margin-top: 12px;
  }

  @media screen and (max-width: 1139px){
    font-size: 12px;
  }

  @media screen and (max-width: 767px){
    padding: 5px 10px;
    margin: 0;
    font-size: 10px;
  }
  
  span{
    color: #9B83FB;
    cursor: pointer;
  }
}