@import "@sitestyles";

.email{
  width: 791px;
  max-width: calc(100vw - 30px);
  height: auto;
  max-height: 100%;
  position: relative;
  background-color: $whiteColor;
  border-radius: 10px;
  padding: 58px 40px 58px 40px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.email_title{
  font-weight: 600;
  font-size: 26px;
  text-align: center;
  letter-spacing: 0.06em;
  color: $accentRightColor;
  margin-bottom: 30px;
}

.email_textinfo{
  margin-bottom: 20px;
  font-size: 14px;
}

.email_textinfo span{
  display: block;
  color: #00317B;
  font-weight: 500;
}

.email_row{
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -o-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: space-between;
  -ms-align-items: flex-start;
  align-items: flex-start;
}

.email_item{
  width: calc(50% - 12px);
  width: 100%;
}

.email_label{
  display: block;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  color: $stormGreyColor;
  margin-bottom: 3px;
}

.email_input_wrap{
  position: relative;
}

.email_input{
  display: block;
  width: 100%;
  max-width: 500px;
  height: 42px;
  background: #eff3f8;
  border-radius: 4px;
  padding-right: 17px;
  padding-left: 17px;
  font-size: 14px;
  font-weight: 600;
  color: $accentLeftColor;
  outline: none;
  text-align: center;
  margin: 0 auto;
  border: 1px solid #9AAFD0;
}

.email_input:hover{
  border-color: #5577AD;
  box-shadow: 0px 3px 6px rgba(28, 38, 119, 0.08);
}

.email_input::placeholder{
  font-weight: 400;
  color: #C2C2C2;
}

.email_input:focus{
  border-color: #00317B;
  box-shadow: 0px 3px 6px rgba(28, 38, 119, 0.08);
}

.email_input[type=text]{
  font-size: 14px;
}

.email_save_btn{
  width: 290px;
  max-width: 100%;
  height: 44px;
  line-height: 44px;
  margin: 23px auto 0;
  text-align: center;
  color: $whiteColor;
  background: $backgroundButtonColor;
  border-radius: 4px;
  cursor: pointer;
}

.email_save_btn:not(.disable):hover{
  box-shadow: $searchItemBtnShadowHover;
}

.email_save_btn.disable{
  background: linear-gradient(90.83deg, rgba(0, 49, 123, 0.6) 0%, rgba(114, 0, 102, 0.6) 100%);
  cursor: default;
}

.email_save_btn img{
  vertical-align: middle;
  margin-bottom: 2px;
  margin-right: 10px;
}

.email_save_btn svg {
  vertical-align: middle;
  margin-bottom: 2px;
  margin-right: 10px;
  width: 22px;
  height: 22px;
  fill: #FFFFFF;
}

.email_close{
  position: absolute;
  right: 15px;
  top: 15px;
  padding: 5px;
  cursor: pointer;
}

.email_close svg {
  width: 28px;
  height: 28px;
  fill: #ccd2d9;
  stroke: #ccd2d9;
  stroke-width: 0.5;
}

@media screen and (max-height: 400px){
  .email{
    padding: 20px 30px;
    overflow-y: auto;
  }

  .email_title{
    font-size: 20px;
    margin-bottom: 20px;
  }

  .email_textinfo{
    font-size: 10px;
  }
}

@media screen and (max-width: 599px){
  .email{
    padding: 50px 20px 30px;
    height: auto;
    overflow-y: auto;
  }

  .email_title{
    font-size: 18px;
    margin-bottom: 30px;
  }

  .email_item{
    width: 100%;
    margin-bottom: 24px;
  }

  .email_item:last-child{
    margin-bottom: 0;
  }
}
