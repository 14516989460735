@import '@sitestyles';
@import '../breakpoints';

.menu_wrap {
    flex: 1;
    overflow: hidden;
    margin: 0 0 auto;
}

.menu_scroll {
    display: block;
    height: 100%;
    overflow-y: auto;
}

.menu_scroll::-webkit-scrollbar {
    width: 3px;
    background: #fff;
    border-radius: 3px;
}

.menu_scroll::-webkit-scrollbar-thumb {
    background: $scrollbarThumbColor;
    border-radius: 3px;
}

.menu_list {
    display: block;
}

.menu_list_item {
    padding: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
}

.item_with_submenu {
    display: flex;
    flex-direction: column;
}

.menu_list_button {
    background: transparent;
    width: 100%;
    padding: 0 15px 0 8px;
    border: none;
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: flex-start;
    font-weight: 500;
    font-size: 20px;
    color: $buttonTitleColor;
    line-height: 1;
    transition: 0.3s;
    font-family: inherit;
    cursor: pointer;
    min-height: 54px;
    position: relative;

    @media (max-width: $BP_middle), (max-height: $BP_middle_height) {
        min-height: 40px;
        font-size: 14px;
    }

    svg {
        max-width: 24px;
        max-height: 24px;
        transition: 0.3s;
        fill: $buttonTitleColor;

        @media (max-width: $BP_middle), (max-height: $BP_middle_height) {
            max-width: 18px;
            max-height: 18px;
        }
    }

    &:hover,
    &:focus {
        color: $buttonTitleHoverColor;
        background-color: $backgroundButtonHoverColor;

        svg {
            fill: $buttonTitleHoverColor;
        }
    }

    &.active {
        color: $buttonTitleActiveColor;
        background-color: $backgroundButtonActiveColor;
        position: relative;
        overflow: hidden;

        //&:before {
        //  content: '';
        //  display: block;
        //  position: absolute;
        //  bottom: -4px;
        //  right: -4px;
        //  background: url('../../../../img/valentines-day/active-menu-decor.svg') center no-repeat;
        //  background-size: contain;
        //  width: 50px;
        //  height: 50px;
        //  transform: rotate(-5deg);
        //}

        svg {
            fill: $buttonTitleActiveColor;
        }
    }
}

.submenu_list {
    list-style: none;
    width: 100%;
}

.submenu_list_item {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    cursor: pointer;
    padding: 0;
    transition: 0.3s;
}

.submenu_list_button {
    background: transparent;
    border: none;
    display: flex;
    flex-direction: column;
    gap: 4px;
    align-items: flex-start;
    justify-content: center;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 0.01em;
    color: $submenuButtonColor;
    line-height: 1;
    transition: 0.3s;
    cursor: pointer;
    height: 56px;
    width: 100%;
    padding: 0 15px 0 8px;
    position: relative;

    @media (max-width: $BP_middle), (max-height: $BP_middle_height) {
        font-size: 12px;
        height: 42px;
    }

    svg {
        max-width: 20px;
        max-height: 20px;
        fill: $submenuButtonColor;
        transition: 0.3s;
        opacity: 0.7;

        @media (max-width: $BP_middle), (max-height: $BP_middle_height) {
            max-width: 16px;
            max-height: 16px;
        }
    }

    &:hover,
    &:focus {
        color: $buttonTitleHoverColor;
        background-color: $backgroundButtonHoverColor;

        svg {
            fill: $buttonTitleHoverColor;
        }
    }

    &.active {
        color: $buttonTitleActiveColor;
        background-color: $backgroundButtonActiveColor;

        svg {
            fill: $buttonTitleActiveColor;
        }
    }
}

@mixin holiday-menu($name, $image-path) {
    .menu_list.#{$name} {
        .submenu_list_button.active,
        .menu_list_button.active {
            position: relative;
        }

        .submenu_list_item,
        .menu_list_button {
            overflow: hidden;
        }

        .submenu_list_button.active:not(.new_badge):before,
        .menu_list_button.active:not(.new_badge):before {
            content: '';
            display: block;
            height: 100%;
            background-image: url(#{$image-path});
            background-repeat: no-repeat;
            background-size: contain;
            position: absolute;
            pointer-events: none;
            top: 0;
            right: 0;
        }

        .menu_list_button.active:before {
            width: 54px;
            @media (max-width: $BP_middle), (max-height: $BP_middle_height) {
                width: 40px;
            }
        }

        .submenu_list_button.active:before {
            width: 56px;
            @media (max-width: $BP_middle), (max-height: $BP_middle_height) {
                width: 42px;
            }
        }
    }
}

@include holiday-menu('halloween', '../../../../img/holiday/halloween_active_menu_item.svg');
@include holiday-menu('lunar_new_year', '../../../../img/holiday/lunar_new_year_active_menu_item.svg');
@include holiday-menu('thanksgiving_day', '../../../../img/holiday/thanksgiving_day_active_menu_item.svg');
@include holiday-menu('christmas', '../../../../img/holiday/christmas_active_menu_item.svg');
@include holiday-menu('woman_day', '../../../../img/holiday/woman_day_active_menu_item.svg');
@include holiday-menu('valentine_day', '../../../../img/holiday/valentine_day_active_menu_item.svg');
@include holiday-menu('independence_day', '../../../../img/holiday/independence_day_active_menu_item.svg');
@include holiday-menu('easter', '../../../../img/holiday/easter_active_menu_item.svg');

.bottom_wrap {
    margin-top: 20px;
    padding: 10px;

    @media (max-width: $BP_middle), (max-height: $BP_middle_height) {
        margin-top: 10px;
    }
}

.policy {
    width: 100%;
    border: 1px solid $buttonBorderPolicyColor;
    background: transparent;
    border-radius: 5px;
    font-weight: 500;
    font-size: 20px;
    color: $buttonBorderPolicyColor;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 9px;
    transition: 0.3s;
    cursor: pointer;
    height: 52px;

    @media (max-width: $BP_middle), (max-height: $BP_middle_height) {
        height: 40px;
        font-size: 14px;
    }

    svg {
        transition: 0.3s;
        fill: $buttonTitlePolicyMenu;
    }

    &:hover,
    &:focus,
    &.active {
        color: $policyHoverColor;
        background-color: $buttonBorderPolicyColor;

        svg {
            fill: $policyHoverColor;
        }
    }
}

.new_badge:after {
    content: 'New';
    width: 50px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $newBadgeBg;
    color: $newBadgeColor;
    border-radius: 4px;
    font-weight: 500;
    font-size: 12px;
    letter-spacing: -0.02em;
    line-height: 1;
    position: absolute;
    top: 50%;
    right: 8px;
    transform: translateY(-50%);
    z-index: 3;
    transition-duration: 0.3s;

    @media (max-width: $BP_middle), (max-height: $BP_middle_height) {
        width: 34px;
        height: 20px;
        font-size: 10px;
    }
}

.menu_list_button:hover.new_badge:after,
.submenu_list_button:hover.new_badge:after,
.menu_list_button:focus.new_badge:after,
.submenu_list_button:focus.new_badge:after {
    background-color: $newBadgeHoverBg;
    color: $newBadgeHoverColor;
}

.menu_list_button.active.new_badge:after,
.submenu_list_button.active.new_badge:after {
    background-color: $newBadgeActiveBg;
    color: $newBadgeActiveColor;
}

//Halloween
//.menu_list_button{
//  position: relative;
//}
//
//.submenu_list_button.active:before,
//.menu_list_button.active:before{
//  content: '';
//  display: block;
//  height: 100%;
//  width: 47px;
//  background-image: url(../../../../img/halloween/halloween_active_menu_item.svg);
//  background-repeat: no-repeat;
//  background-size: contain;
//  position: absolute;
//  top: 0;
//  right: 0;
//
//  @media (max-width: $BP_middle), (max-height: $BP_middle_height) {
//    width: 36px;
//  }
//}

// New year
//.menu_list_button{
//  position: relative;
//  overflow-x: hidden;
//}
//
//.submenu_list_button{
//  overflow-x: hidden;
//}
//
//.submenu_list_button.active:before,
//.menu_list_button.active:before{
//  content: '';
//  display: block;
//  height: 100%;
//  width: 100%;
//  background-position: center;
//  background-image: url(../../../../img/new-year/menu-snowflake.svg);
//  background-repeat: no-repeat;
//  background-size: contain;
//  position: absolute;
//  top: 0;
//  right: -2px;

//@media (max-width: $BP_middle), (max-height: $BP_middle_height) {
//  width: 20px;
//}
//}

//8 march
//.menu_list_button {
//  position: relative;
//  overflow-x: hidden;
//}
//
//.submenu_list_button {
//  overflow-x: hidden;
//}
//
//.submenu_list_button.active:before,
//.menu_list_button.active:before {
//  content: '';
//  display: block;
//  height: 100%;
//  width: 40px;
//  background-position: center;
//  background-image: url(../../../../img/8march/menu-active-decoration.svg);
//  background-repeat: no-repeat;
//  background-size: contain;
//  position: absolute;
//  bottom: 0;
//  right: 0;
//
//  @media (max-width: $BP_middle), (max-height: $BP_middle_height) {
//    right: -6px;
//  }
//}
