@import "@sitestyles";

.mobile_chat {
  display: flex;
  flex-direction: column;
  height: calc(var(--vh, 1vh) * 100);
  min-height: calc(var(--vh, 1vh) * 100);
  width: 100%;
  z-index: 4;
  position: relative;
  touch-action: manipulation;
}

.mobile_chat_header {
  height: 50px;
  max-height: 50px;
  background: #FFFFFF;
  border-bottom: 1px solid $mobileChatButtonNotificationSelectedBackground;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9;
}

.mobile_chat_container {
  flex-grow: 1;
  height: 1px;
}

.mobile_chat_body_wrap {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-top: 50px;
  position: fixed;
  width: 100%;
  bottom: 0;
}

.mobile_chat_body {
  padding: 0;;
  height: 0;
  flex-grow: 1;
}

.mobile_letters_content {
  padding: 0;;
  height: 0;
  flex-grow: 1;
}

.mobile_chat_footer {
  background: #FFFFFF;
  border-top: 1px solid #E9EBEE;
  min-height: 62px;
  height: fit-content;
  padding: 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 9px;

  &.selected {
    @media screen and (max-height: 620px) {
      margin-bottom: 24px;
    }
  }
}

.mobile_c3_write_msg_textareas_files {
  margin: 0 !important;
  padding: 9px 0 0 !important;
  max-width: 100%;
  overflow: auto;
  box-sizing: border-box;
}

.letters_attach_files_list {
  padding: 0;
}

.mobile_chat_btn_back {
  width: 36px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  outline: 0;
  background: $mobileChatIconColor;
  mask: url('../../../img/mh-goback.svg') right no-repeat;
  mask-size: 24px 24px;
  -webkit-mask: url('../../../img/mh-goback.svg') right no-repeat;
  -webkit-mask-size: 24px 24px;
  font-size: 0;
  margin-right: 8px;
  cursor: pointer;
}

.mobile_chat_btn_favourite {
  width: 24px;
  height: 100%;
  background: $mobileChatIconColor;
  mask: url('../../../img/c3-top-favs.svg') center no-repeat;
  mask-size: 24px 24px;
  -webkit-mask: url('../../../img/c3-top-favs.svg') center no-repeat;
  -webkit-mask-size: 24px 24px;
  font-size: 0;
  margin-right: 12px;
  cursor: pointer;
}

.mobile_chat_btn_favourite.selected {
  background: #F2C94C;
  mask: url('../../../img/c3-top-favs2.svg') center no-repeat;
  mask-size: 24px 24px;
  -webkit-mask: url('../../../img/c3-top-favs2.svg') center no-repeat;
  -webkit-mask-size: 24px 24px;
}

.mobile_chat_profile {
  display: flex;
  align-items: center;
  margin-right: auto;
  justify-content: flex-start;
  cursor: pointer;
  width: fit-content;
  height: 100%;
}

.mobile_chat_profile_photo_wrapper {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  position: relative;
  margin-right: 8px;
  max-width: 32px;
  max-height: 32px;
}

.mobile_chat_profile_photo {
  width: 32px;
  height: 32px;
  display: block;
  object-fit: cover;
  object-position: top center;
  border-radius: 50%;
  background: #E0E0E0;
}

.mobile_chat_profile_name {
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.01em;
  color: $mobileChatProfileNameColor;
  text-align: left;
}

.mobile_chat_profile_status {
  font-size: 0;
  background: #E0E0E0;
  border: 2px solid #FFFFFF;
  box-sizing: border-box;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.mobile_chat_profile_status.active {
  background: #27AE60;
}

.mobile_chat_navigation {
  position: relative;
  margin-right: 14px;
}

.mobile_chat_navigation_list {
  display: none;
  position: absolute;
  top: 44px;
  right: -2px;
  background: #FFFFFF;
  border: 1px solid #E9EBEE;
  border-radius: 5px;
  padding: 8px;
  margin: 0;
  width: 168px;
  filter: drop-shadow(0px 4px 8px $mobileChatMenuBoxShadow);
  z-index: 0;

  &:before {
    position: absolute;
    background: #FFFFFF;
    top: -8px;
    right: 10px;
    content: '';
    display: block;
    border-width: 1px 0 0 1px;
    border-style: solid;
    border-color: #E9EBEE;
    border-radius: 1px 0 2px;
    transform: rotate(45deg);
    width: 14px;
    height: 14px;
    z-index: 1;
  }

  &.active {
    display: block;
  }

}

.mobile_chat_navigation_list_item {
  list-style-type: unset;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.mobile_chat_navigation_btn {
  padding: 8px;
  cursor: pointer;
  border: 0;
  outline: 0;
  font-family: $mainFont;
  font-weight: 600;
  font-size: 11px;
  line-height: 15px;
  letter-spacing: 0.01em;
  color: $mobileChatMenuLinkColor;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.btn_icon:before {
  content: '';
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  background: $mobileChatMenuLinkColor;
  margin-right: 8px;
}

.icon_letter:before {
  mask: url('../../../img/mb-chat.svg') center no-repeat;
  mask-size: contain;
  -webkit-mask: url('../../../img/mb-chat.svg') center no-repeat;
  -webkit-mask-size: contain;
}

.icon_gift:before {
  mask: url('../../../img/c3-opt-gift.svg') center no-repeat;
  mask-size: contain;
  -webkit-mask: url('../../../img/c3-opt-gift.svg') center no-repeat;
  -webkit-mask-size: contain;
}

.icon_complaine:before {
  mask: url('../../../img/c3-top-attention.svg') center no-repeat;
  mask-size: contain;
  -webkit-mask: url('../../../img/c3-top-attention.svg') center no-repeat;
  -webkit-mask-size: contain;
}

.icon_blacklist:before {
  mask: url('../../../img/c3-top-block.svg') center no-repeat;
  mask-size: contain;
  -webkit-mask: url('../../../img/c3-top-block.svg') center no-repeat;
  -webkit-mask-size: contain;
}

.mobile_chat_navigation_burger {
  width: 32px;
  height: 32px;
  border-radius: 5px;
  font-size: 0;
  display: flex;
  border: 0;
  outline: 0;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  cursor: pointer;
  background: #FFFFFF;

  &:before {
    content: '';
    display: block;
    background: $mobileChatIconColor;
    width: 24px;
    height: 24px;
    min-width: 24px;
    mask: url('../../../img/c3-top-options-btn-landscape.svg') center no-repeat;
    mask-size: contain;
  }

  &.active {
    background: #F7F7FE;
  }
}

.mobile_chat_btn_notification {
  width: 46.5px;
  height: 36px;
  background: #FFFFFF;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  border-radius: 5px 0 0 5px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:before {
    content: '';
    display: none;
    background: white;
    box-sizing: border-box;
    width: 13px;
    height: 13px;
    left: -12px;
    bottom: -7px;
    position: absolute;
    z-index: -1;
    background: $mobileChatButtonNotificationSelectedBackground;
    mask: url('../../../img/mh-bg-shape.svg') center no-repeat;
    mask-size: contain;
    transform: scale(-1, 1);
  }

  &:after {
    content: '';
    display: none;
    background: $mobileChatButtonNotificationSelectedBackground;
    width: 100%;
    height: 50px;
    position: absolute;
    z-index: -1;
    right: 0;
    top: 0;
    border-radius: 5px 0 0 0;
  }
}

.mobile_chat_btn_notification_icon {
  background: $mobileChatIconColor;
  width: 28px;
  height: 28px;
  min-width: 28px;
  mask: url('../../../img/alarm.svg') center no-repeat;
  mask-size: contain;
}

.mobile_chat_btn_notification.selected {
  background: $mobileChatButtonNotificationSelectedBackground;
  box-shadow: unset;

  &:after,
  &:before {
    display: block;
  }
}

.mobile_chat_btn_notification_counter {
  background: #EB5757;
  border-radius: 4px;
  min-width: 17px;
  padding: 0 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 17px;
  position: absolute;
  top: -6px;
  left: 3px;
  letter-spacing: -0.02em;
  color: #FFFFFF;
  font-weight: 600;
  font-size: 10px;
  line-height: 100%;
  font-family: $mainFont;
}

.mobile_chat_message_btn_attachment {
  width: 48px;
  height: 100%;
  min-height: 32px;
  padding: 6px 0 0;
  min-width: 48px;
  font-size: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 4;
  position: relative;
  touch-action: manipulation;

  &:before {
    content: '';
    display: block;
    width: 24px;
    height: 24px;
    min-width: 24px;
    background: $mobileChatFormMessageFileUploadIconColor;
    mask: url('../../../img/file-attachment.svg') center no-repeat;
    mask-size: contain;
    -webkit-mask: url('../../../img/file-attachment.svg') center no-repeat;
    -webkit-mask-size: contain;
  }
}

.mobile_chat_message {
  flex: 1;
  display: flex;
  width: 100%;
  align-items: center;
  position: relative;
}

.mobile_user_message {
  border: 1px solid $mobileChatFormMessageTextareaBorderColor;
  border-radius: 8px;
  min-height: 32px;
  width: 100%;
  height: 32px;
  max-height: 160px;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.01em;
  color: $mobileChatFormMessageTextColor;
  font-family: $mainFont;
  padding: 5.5px 8px 6.5px;
  box-sizing: border-box;
  display: flex;
  margin: 0;
  margin-top: 8px;

  &:active,
  &:focus {
    border-color: $mobileChatIconColor;
  }

  &::placeholder {
    font-family: $mainFont;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.01em;
    color: $mobileChatFormMessageTextColor;
    padding: 0;
  }


  &::-webkit-scrollbar {
    width: 3px;
    background: $mobileChatFormMessageTextareaBorderColor;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(2, 49, 124, 0.6);
    border-radius: 5px;
  }
}


.mobile_user_message_counter {
  display: block;
  font-size: 10px;
  text-align: center;
  color: #ABA8A8;
  line-height: 130%;
  width: 100%;
  margin-top: 7px;
}

.mobile_chat_message_btn_submit {
  grid-area: submit;
  width: 48px;
  padding: 4px 0 0;
  min-height: 32px;
  height: 100%;
  min-width: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0;
  cursor: pointer;

  &:before {
    content: '';
    display: block;
    width: 24px;
    height: 24px;
    min-width: 24px;
    background: $mobileChatFormMessageButtonBackground;
    mask: url('../../../img/btn-message-submit.svg') top center no-repeat;
    mask-size: 24px 24px;
    -webkit-mask: url('../../../img/btn-message-submit.svg') top center no-repeat;
    -webkit-mask-size: 24px 24px;
  }
}

.mobile_letter {
  padding: 12px;
  display: block;
  height: 100%;
  overflow-y: scroll;
  box-sizing: border-box;
}

.mobile_letter_item {
  width: 100%;
  list-style-type: none;
  border-radius: 8px;
  padding: 12px 12px 0;
  box-sizing: border-box;
  display: block;
  overflow: hidden;

  &:not(:last-child) {
    margin-bottom: 8px;
  }
}

.mobile_letter_item.bookmarked {
  background: $mobileLetterMessageBookmarkedBackground;
}

.mobile_letter_item.bookmarked .mobile_letter_btn_bookmarks:before {
  background: #feb930;
  mask: url('../../../img/c3l-bookmark-full.svg') center no-repeat;
  mask-size: contain;
  -webkit-mask: url('../../../img/c3l-bookmark-full.svg') center no-repeat;
  -webkit-mask-size: contain;
}

.mobile_letter_man {
  background: $mobileLetterMessageBackgroundPrimary;
}

.mobile_letter_woman {
  background: $mobileLetterMessageBackgroundSecondary;
}

.mobile_letter_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

.mobile_letter_profile {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.mobile_letter_profile_photo {
  width: 32px;
  height: 32px;
  display: block;
  object-fit: cover;
  object-position: top center;
  border-radius: 50%;
  margin-right: 8px;
}

.mobile_letter_profile_name {
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.01em;
  color: $mobileChatProfileNameColor;
}

.mobile_letter_btn_bookmarks {
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.01em;
  color: $mobileChatFormMessageTextColor;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  &:before {
    content: '';
    display: block;
    width: 24px;
    height: 24px;
    min-width: 24px;
    margin-right: 4px;
    background: $mobileChatFormMessageTextColor;
    mask: url('../../../img/c3l-bookmark-empty.svg') center no-repeat;
    mask-size: contain;
    -webkit-mask: url('../../../img/c3l-bookmark-empty.svg') center no-repeat;
    -webkit-mask-size: contain;
  }
}

.mobile_letter_body {
  padding-bottom: 12px;
}

.mobile_letter_text {
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.01em;
  color: $mobileChatFormMessageTextColor;
}

.mobile_letter_date {
  margin-top: 4px;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: -0.03em;
  color: $mobileChatMessageDateColor;
  font-family: $mainFont;
}

.mobile_letter_attach_photo {
  display: flex;
}

.mobile_letter_attach_photo_image {
  max-width: 60px;
  max-height: 38px;
  border-radius: 5px;
  display: block;
  object-fit: cover;
  object-position: top center;
  margin-right: 8px;
}

.mobile_attach_audio {
  background: #FFFFFF;
  box-shadow: 0 3px 3px rgba(14, 4, 143, 0.05);
  border-radius: 5px;
  width: 189px;
  padding: 5px 12px 2px 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.mobile_attach_audio_btn {
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background: $mobileAudioButtonBackground;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0;
  margin-right: 8px;
  cursor: pointer;
  border: 0;
  outline: 0;

  &:before {
    content: '';
    display: block;
    width: 16px;
    height: 24px;
    min-width: 16px;
    background: #FFFFFF;
    mask: url('../../../img/audio-play.svg') center no-repeat;
    mask-size: cover;
    -webkit-mask: url('../../../img/audio-play.svg') center no-repeat;
    -webkit-mask-size: cover;
  }
}

.mobile_attach_audio_btn.playing:before {
  mask: url('../../../img/audio-pause.svg') center no-repeat;
  mask-size: cover;
  -webkit-mask: url('../../../img/audio-pause.svg') center no-repeat;
  -webkit-mask-size: cover;
}

.mobile_attach_audio_body {
  display: flex;
  flex-direction: column;
}

.mobile_attach_audio_name {
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 10px;
  line-height: 12px;
  font-family: $mainFont;
  letter-spacing: -0.03em;
  color: $mobileAudioNameTextColor;
  margin-bottom: 2px;
  white-space: nowrap;
  width: 136px;
  max-width: 136px;
  text-overflow: ellipsis;
  overflow: hidden;

  &:before {
    content: '';
    display: block;
    width: 9px;
    height: 9px;
    min-width: 9px;
    margin-right: 5px;
    background: $mobileAudioNameTextColor;
    mask: url('../../../img/letter-audio-notes.svg') center no-repeat;
    mask-size: contain;
    -webkit-mask: url('../../../img/letter-audio-notes.svg') center no-repeat;
    -webkit-mask-size: contain;
  }
}

.mobile_attach_audio_progress {
  background: $mobileAudioProgressBackgroundDefault;
  height: 1px;
  width: 100%;
  position: relative;
  border-radius: 1px;
  margin-bottom: 3px;
}

.mobile_attach_audio_progress_current {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  background: $mobileAudioButtonBackground;
  border-radius: 1px;
}

.mobile_attach_audio_timetrack {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.mobile_attach_audio_timetrack_item {
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  font-family: $mainFont;
  letter-spacing: -0.03em;
  color: $mobileAudioTimeColor;
}