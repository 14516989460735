@import "@sitestyles";

.video_thumbnail_container {
  position: relative;
}

.video_thumbnail_lock_screen {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  flex-direction: column;
}

.mobile_chat {
  display: flex;
  flex-direction: column;
  height: calc(var(--vh, 1vh) * 100);
  min-height: calc(var(--vh, 1vh) * 100);
  width: 100%;
  z-index: 4;
  position: relative;
  touch-action: manipulation;
}

.mobile_chat_header {
  height: 50px;
  max-height: 50px;
  background: #FFFFFF;
  border-bottom: 1px solid $mobileChatButtonNotificationSelectedBackground;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9;
  //position: relative;
}

.mobile_chat_container {
  flex-grow: 1;
  height: 1px;
}

.mobile_chat_body_wrap {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-top: 50px;
  position: fixed;
  width: 100%;
  bottom: 0;
}

.mobile_chat_body {
  position: relative;
  padding: 0;;
  height: 0;
  flex-grow: 1;
}

.mobile_chat_empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 8px;
  flex: 1;
}

.mobile_chat_empty_wrap {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  height: 100%;
}

.mobile_chat_empty_image {
  margin-bottom: 21px;
}

.mobile_chat_empty_title {
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  color: $mobileChatEmptyTitleColor;
  font-family: $mainFont;
}

.mobile_chat_empty_text {
  font-weight: 500;
  font-size: 12px;
  line-height: 27px;
  text-align: center;
  color: $mobileChatEmptyTextColor;
  margin-bottom: 12px;
  font-family: $mainFont;
}

.mobile_chat_empty_button {
  min-width: 153px;
  height: 37px;
  background: #FFFFFF;
  border: 1px solid $mobileChatIconColor;
  border-radius: 4px;
  font-weight: 600;
  font-size: 12px;
  line-height: 140%;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.03em;
  color: $mobileChatIconColor;
  font-family: $mainFont;
  cursor: pointer;

  &:before {
    content: '';
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    background: $mobileChatIconColor;
    margin-right: 8px;
    mask: url('../../../img/chat-button-hello.svg') center no-repeat;
    mask-size: contain;
    -webkit-mask: url('../../../img/chat-button-hello.svg') center no-repeat;
    -webkit-mask-size: contain;
  }
}

.mobile_chat_footer {
  background: #FFFFFF;
  border-top: 1px solid #E9EBEE;
  min-height: 62px;
  height: fit-content;
  padding: 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 9px;

  &.selected {
    @media screen and (max-height: 620px) {
      margin-bottom: 24px;
    }
  }
}

.mobile_c3_touch_stickers_wrap {
  height: 284px;
  padding: 0;
}

.mobile_chat_btn_back {
  width: 36px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  outline: 0;
  background: $mobileChatIconColor;
  mask: url('../../../img/mh-goback.svg') right no-repeat;
  mask-size: 24px 24px;
  -webkit-mask: url('../../../img/mh-goback.svg') right no-repeat;
  -webkit-mask-size: 24px 24px;
  font-size: 0;
  margin-right: 8px;
  cursor: pointer;
}

.mobile_chat_btn_favourite {
  width: 32px;
  height: 100%;
  background: $mobileChatIconColor;
  mask: url('../../../img/c3-top-favs.svg') center no-repeat;
  mask-size: 24px 24px;
  -webkit-mask: url('../../../img/c3-top-favs.svg') center no-repeat;
  -webkit-mask-size: 24px 24px;
  font-size: 0;
  margin-right: 12px;
  cursor: pointer;
}

.mobile_chat_btn_favourite.selected {
  background: #F2C94C;
  mask: url('../../../img/c3-top-favs2.svg') center no-repeat;
  mask-size: 24px 24px;
  -webkit-mask: url('../../../img/c3-top-favs2.svg') center no-repeat;
  -webkit-mask-size: 24px 24px;
}

.mobile_chat_profile {
  display: flex;
  align-items: center;
  margin-right: auto;
  justify-content: flex-start;
  cursor: pointer;
  width: fit-content;
  height: 100%;
}

.mobile_chat_profile_photo_wrapper {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  position: relative;
  margin-right: 8px;
  max-width: 32px;
  max-height: 32px;
}

.mobile_chat_profile_photo {
  width: 32px;
  height: 32px;
  display: block;
  object-fit: cover;
  object-position: top center;
  border-radius: 50%;
  background: #E0E0E0;
}

.mobile_chat_profile_name {
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.01em;
  color: $mobileChatProfileNameColor;
  text-align: left;
}

.mobile_chat_profile_status {
  font-size: 0;
  background: #E0E0E0;
  border: 2px solid #FFFFFF;
  box-sizing: border-box;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.mobile_chat_profile_status.active {
  background: #27AE60;
}

.mobile_chat_navigation {
  position: relative;
  margin-right: 14px;
}

.mobile_chat_navigation_list {
  display: none;
  position: absolute;
  top: 44px;
  right: -2px;
  background: #FFFFFF;
  border: 1px solid #E9EBEE;
  border-radius: 5px;
  padding: 8px;
  margin: 0;
  width: 168px;
  filter: drop-shadow(0px 4px 8px $mobileChatMenuBoxShadow);
  //z-index: 0;

  &:before {
    position: absolute;
    background: #FFFFFF;
    top: -8px;
    right: 10px;
    content: '';
    display: block;
    border-width: 1px 0 0 1px;
    border-style: solid;
    border-color: #E9EBEE;
    border-radius: 1px 0 2px;
    transform: rotate(45deg);
    width: 14px;
    height: 14px;
    //z-index: 1;
  }

  &.active {
    display: block;
  }
}

.mobile_chat_navigation_list_item {
  list-style-type: unset;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.mobile_chat_navigation_btn {
  padding: 8px;
  cursor: pointer;
  border: 0;
  outline: 0;
  font-family: $mainFont;
  font-weight: 600;
  font-size: 11px;
  line-height: 15px;
  letter-spacing: 0.01em;
  color: $mobileChatMenuLinkColor;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.btn_icon:before {
  content: '';
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  background: $mobileChatMenuLinkColor;
  margin-right: 8px;
}

.icon_letter:before {
  mask: url('../../../img/mob-letter.svg') center no-repeat;
  mask-size: contain;
  -webkit-mask: url('../../../img/mob-letter.svg') center no-repeat;
  -webkit-mask-size: contain;
}

.icon_gift:before {
  mask: url('../../../img/c3-opt-gift.svg') center no-repeat;
  mask-size: contain;
  -webkit-mask: url('../../../img/c3-opt-gift.svg') center no-repeat;
  -webkit-mask-size: contain;
}

.icon_complaine:before {
  mask: url('../../../img/c3-top-attention.svg') center no-repeat;
  mask-size: contain;
  -webkit-mask: url('../../../img/c3-top-attention.svg') center no-repeat;
  -webkit-mask-size: contain;
}

.icon_blacklist:before {
  mask: url('../../../img/c3-top-block.svg') center no-repeat;
  mask-size: contain;
  -webkit-mask: url('../../../img/c3-top-block.svg') center no-repeat;
  -webkit-mask-size: contain;
}

.mobile_chat_navigation_burger {
  width: 32px;
  height: 32px;
  border-radius: 5px;
  font-size: 0;
  display: flex;
  border: 0;
  outline: 0;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  cursor: pointer;
  background: #FFFFFF;

  &:before {
    content: '';
    display: block;
    background: $mobileChatIconColor;
    width: 24px;
    height: 24px;
    min-width: 24px;
    mask: url('../../../img/c3-top-options-btn-landscape.svg') center no-repeat;
    mask-size: contain;
  }

  &.active {
    background: #F7F7FE;
  }
}

.mobile_chat_btn_notification {
  width: 46.5px;
  height: 36px;
  background: #FFFFFF;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  border-radius: 5px 0 0 5px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:before {
    content: '';
    display: none;
    background: white;
    box-sizing: border-box;
    width: 13px;
    height: 13px;
    left: -12px;
    bottom: -7px;
    position: absolute;
    z-index: -1;
    background: $mobileChatButtonNotificationSelectedBackground;
    mask: url('../../../img/mh-bg-shape.svg') center no-repeat;
    mask-size: contain;
    transform: scale(-1, 1);
  }

  &:after {
    content: '';
    display: none;
    background: $mobileChatButtonNotificationSelectedBackground;
    width: 100%;
    height: 50px;
    position: absolute;
    z-index: -1;
    right: 0;
    top: 0;
    border-radius: 5px 0 0 0;
  }
}

.mobile_chat_btn_notification_icon {
  background: $mobileChatIconColor;
  width: 28px;
  height: 28px;
  min-width: 28px;
  mask: url('../../../img/alarm.svg') center no-repeat;
  mask-size: contain;
}

.mobile_chat_btn_notification.selected {
  background: $mobileChatButtonNotificationSelectedBackground;
  box-shadow: unset;

  &:after,
  &:before {
    display: block;
  }
}

.mobile_chat_btn_notification_counter {
  background: #EB5757;
  border-radius: 4px;
  min-width: 17px;
  padding: 0 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 17px;
  position: absolute;
  top: -6px;
  left: 3px;
  letter-spacing: -0.02em;
  color: #FFFFFF;
  font-weight: 600;
  font-size: 10px;
  line-height: 100%;
  font-family: $mainFont;
}

.mobile_chat_message_btn_attachment {
  width: 48px;
  height: 100%;
  min-height: 32px;
  padding: 6px 0 0;
  min-width: 48px;
  font-size: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 4;
  position: relative;
  touch-action: manipulation;

  &:before {
    content: '';
    display: block;
    width: 24px;
    height: 24px;
    min-width: 24px;
    background: $mobileChatFormMessageFileUploadIconColor;
    mask: url('../../../img/file-attachment.svg') center no-repeat;
    mask-size: contain;
    -webkit-mask: url('../../../img/file-attachment.svg') center no-repeat;
    -webkit-mask-size: contain;
  }
}

.mobile_chat_message {
  flex: 1;
  display: flex;
  width: 100%;
  align-items: center;
  position: relative;
}

.mobile_user_message_wrap {
  position: relative;
  width: 100%;
  overflow: hidden;
  flex: 1 1;
  margin-top: 8px;
}

.mobile_user_message {
  border: 1px solid $mobileChatFormMessageTextareaBorderColor;
  border-radius: 8px;
  min-height: 32px;
  width: 100%;
  height: 32px;
  max-height: 160px;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.01em;
  color: $mobileChatFormMessageTextColor;
  font-family: $mainFont;
  padding: 5.5px 34px 6.5px 8px;
  box-sizing: border-box;
  display: flex;
  margin: 0;

  &:active,
  &:focus {
    border-color: $mobileChatIconColor;
  }

  &::placeholder {
    font-family: $mainFont;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.01em;
    color: $mobileChatFormMessageTextColor;
    padding: 0;
  }


  &::-webkit-scrollbar {
    width: 3px;
    background: $mobileChatFormMessageTextareaBorderColor;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(2, 49, 124, 0.6);
    border-radius: 5px;
  }
}

.mobile_user_message_btn_sticker {
  width: 32px;
  min-height: 32px;
  height: 100%;
  background: url('../../../img/btn-message-sticker.svg') center no-repeat;
  background-size: 24px;
  font-size: 0;
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 6px;
  transition: all .3s ease-in-out;
  opacity: 1;
}

.mobile_chat_message_btn_submit {
  grid-area: submit;
  width: 48px;
  padding: 4px 0 0;
  min-height: 32px;
  height: 100%;
  min-width: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0;
  cursor: pointer;

  &:before {
    content: '';
    display: block;
    width: 24px;
    height: 24px;
    min-width: 24px;
    background: $mobileChatFormMessageButtonBackground;
    mask: url('../../../img/btn-message-submit.svg') top center no-repeat;
    mask-size: 24px 24px;
    -webkit-mask: url('../../../img/btn-message-submit.svg') top center no-repeat;
    -webkit-mask-size: 24px 24px;
  }
}

.mobile_chat_letters {
  padding: 12px;
  height: 100%;
  overflow-y: scroll;
  box-sizing: border-box;
}

.mobile_chat_letters_item {
  max-width: 265px;
  width: fit-content;
  list-style-type: none;

  &:not(:last-child) {
    margin-bottom: 8px;
  }

  &:first-child {
    margin-top: auto;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.mobile_chat_letters_item.right {
  margin-left: auto;
}

.mobile_chat_letters_item.left {
  margin-right: auto;
}

.message_text_left,
.message_text_right {
  font-size: 14px;
  line-height: 140%;
  padding: 10px;
  letter-spacing: 0.01em;
  color: $mobileChatMessageTextColor;
}

.message_text_right {
  background: $mobileChatMessageBackgroundPrimary;
  border-radius: 12px 12px 2px 12px;
}

.message_text_left {
  background: $mobileChatMessageBackgroundSecondary;
  border-radius: 12px 12px 12px 2px;
}

.message_text_left.photo,
.message_text_right.photo,
.message_text_left.video,
.message_text_right.video {
  padding: 0;
  border-radius: 0;
  background: none !important;
  margin-bottom: 6px;
}

.message_text_left.photo img,
.message_text_left.video img {
  border-radius: 12px 12px 12px 2px;
}

.message_text_right.photo img,
.message_text_right.video img {
  border-radius: 12px 12px 2px 12px;
}

.message_text_left.sticker,
.message_text_right.sticker,
.message_text_right.wink,
.message_text_left.wink {
  background: none !important;
  padding: 0;
}

.message_text_left.sticker img,
.message_text_right.sticker img {
  width: 120px;
  height: auto;
}

.message_text_left.like,
.message_text_right.like {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.message_text_left.like img,
.message_text_right.like img {
  width: 16px;
  height: 16px;
  margin-right: 8px;
}

.mobile_chat_letters_date {
  margin-top: 6px;
  font-size: 10px;
  line-height: 14px;
  letter-spacing: -0.03em;
  color: $mobileChatMessageDateColor;
}

.mobile_chat_greetings_wrap {
  position: relative;
  bottom: 0;
  overflow: hidden;
}

.mobile_chat_greetings {
  display: flex;
  align-items: baseline;
  justify-content: flex-start;
  padding: 0 8px 10px;
  margin: 0;
  width: 100%;
  overflow-x: scroll;
}

.mobile_chat_greetings_item {
  list-style-type: none;
  margin: 0 8px 0 0;
  padding: 0;
}

.mobile_chat_greetings_btn {
  padding: 8px 12px;
  background: $mobileChatMessageGreetingBackground;
  border-radius: 8px;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: $mobileChatMessageGreetingColor;
  border: 0;
  outline: 0;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  box-sizing: border-box;
  cursor: pointer;
  width: 100%;
}

.mobile_chat_greetings_btn_like {
  background: #E03868;
  color: #FFFFFF;

  &:after {
    content: '';
    display: block;
    width: 24px;
    height: 24px;
    min-width: 24px;
    margin-left: 8px;
    background: #FFFFFF;
    mask: url('../../../img/mobile-chat-like.svg') center no-repeat;
    mask-size: contain;
    -webkit-mask: url('../../../img/mobile-chat-like.svg') center no-repeat;
    -webkit-mask-size: contain;
  }
}

.mobile_chat_greetings_wink {
  color: $mobileChatMenuLinkColor;

  &:after {
    content: '';
    display: block;
    width: 24px;
    height: 24px;
    min-width: 24px;
    margin-left: 8px;
    background: url('../../../img/mobile-chat-wink.svg') center no-repeat;
    background-size: contain;
  }
}

.mobile_chat_greetings_checkbox {
  display: none;
}

.mobile_chat_greetings_checkbox:checked + .mobile_chat_greetings_checkbox_label {
  display: none;
}

.mobile_chat_greetings_checkbox:checked ~ .mobile_chat_greetings {
  flex-wrap: wrap;
  overflow: hidden;
  padding-right: 8px;
  justify-content: space-between;
  margin-top: 24px;

  .mobile_chat_greetings_item:not(:last-child) {
    margin-bottom: 8px;
  }

  .mobile_chat_greetings_item {
    width: 100%;
    margin-right: 0;
  }

  .mobile_chat_greetings_item_like,
  .mobile_chat_greetings_item_wink {
    width: 49%;
  }

  &.liked {
    .mobile_chat_greetings_item_like {
      display: none;
    }

    .mobile_chat_greetings_item_wink {
      width: 100%;
    }
  }
}

.mobile_chat_greetings_checkbox_label {
  background: transparent;
  width: 100%;
  height: 36px;
  display: block;
  position: absolute;
  top: 0;
  left: 200px;
  border-radius: 8px;
}


.mobile_user_message_counter {
  display: block;
  font-size: 10px;
  text-align: center;
  color: #ABA8A8;
  line-height: 130%;
  width: 100%;
  margin-top: 7px;
}

.message_avatar {
  width: 40px;
  height: 40px;

  img {
    display: block;
    object-position: top center;
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
}

.chat_message {
  width: 90%;
  display: grid;
  grid-template-columns: 40px 1fr;
  grid-column-gap: 8px;
}