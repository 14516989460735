@import "@sitestyles";

.google_btn {
  width: 234px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #FFFFFF;
  border: 0;
  border-radius: 5px;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: $greyColor;
  cursor: pointer;
  font-family: $mainFont;
  position: relative;
  box-sizing: border-box;
  transition: $ButtonTransitional;

  @media screen and (max-width: 1140px) {
    height: 40px;
    font-size: 14px;
    line-height: 19px;
  }

  @media screen and (max-width: 600px) {
    width: 106px;
  }

  &:before {
    content: '';
    display: block;
    width: 24px;
    height: 24px;
    margin-right: 12px;
    background: url('../../../../img/google_icon.svg') center no-repeat;
    background-size: contain;

  }

  &:hover {
    background: $mainButtonHoverBg;
    color: #FFFFFF;

    &:before {
      background: #FFFFFF;
      mask: url('../../../../img/google_icon.svg') center no-repeat;
      mask-size: contain;
      -webkit-mask: url('../../../../img/google_icon.svg') center no-repeat;
      -webkit-mask-size: contain;
    }
  }
}

.google_btn_text_mobile {
  display: none;
  @media screen and (max-width: 600px) {
    display: block;
  }
}

.google_btn_text_desktop {
  @media screen and (max-width: 600px) {
    display: none;
  }
}

//.new_google_btn_wrap {
//  position: relative;
//}
//
//.new_google_btn_cover {
//  position: absolute;
//  background-color: rgba(0, 0, 0, 0.1);
//  top: 0;
//  bottom: 0;
//  right: 0;
//  left: 0;
//  z-index: 10;
//  margin-right: 10px;
//  cursor: pointer;
//
//  @media screen and (max-width: 600px) {
//    margin-right: 12px;
//  }
//}

.new_google_btn {
  position: relative;
  z-index: 9;
  max-width: 266px;
  //width: 210px;

  iframe {
    margin-right: 0 !important;
  }

  @media screen and (min-width: 4536px) {
    transform: scale(2);
  }

  @media screen and (max-width: 600px) {
    width: 52px;
  }
}