@import "@sitestyles";

@import "@sitestyles";

.popup_big_meeting_request {
  width: 1040px;
  background-color: $whiteColor;
  padding: 16px 27px 19px 10px;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  position: relative;
  max-width: 100%;
}

.popup_big_meeting_request_wrap {
  display: grid;
  grid-template-areas: "pop_req_left pop_req_top"
  "pop_req_left pop_req_middle"
  "pop_req_left pop_req_bottom";
  grid-template-columns: 330px 1fr;
  grid-column-gap: 18px;
  grid-template-rows: max-content;
}

.popup_big_meeting_request_left {
  grid-area: pop_req_left;
}

.popup_big_meeting_request_top {
  grid-area: pop_req_top;
}

.popup_big_meeting_request_middle {
  grid-area: pop_req_middle;
}

.popup_big_meeting_request_bottom {
  grid-area: pop_req_bottom;
}

.popup_big_meeting_request_photo {
  margin-bottom: 14px;
}

.popup_big_meeting_request_photo img {
  width: 330px;
  height: 529px;
  border-radius: 8px;
}

.popup_big_meeting_request_profile_info {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -o-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: center;
  -ms-align-items: center;
  align-items: center;
}

.popup_big_meeting_request_name {
  width: 100%;
  font-weight: 600;
  font-size: 24px;
  color: $stormGreyColor;
  text-align: center;
  margin-bottom: 8px;
}

.popup_big_meeting_request_profile_info_bot {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: center;
  width: 100%;
}

.popup_big_meeting_request_id {
  color: $defaultTextColorAlpha6;
  margin-right: 18px;
}

.popup_big_meeting_request_place {
  letter-spacing: -0.03em;
  color: $defaultTextColorAlpha6;
}

.popup_big_meeting_request_place img {
  height: 12px;
  border-radius: 3px;
  vertical-align: middle;
  margin-right: 5px;
  margin-bottom: 2px;
}

.popup_big_meeting_request_title {
  font-weight: 600;
  font-size: 28px;
  text-align: center;
  color: $accentLeftColor;
  margin-top: 16px;
  margin-bottom: 16px;
}

.popup_big_meeting_request_subtitle {
  font-weight: 500;
  font-size: 16px;
  line-height: 1.5;
  text-align: center;
  color: $defaultTextColor;
  padding-bottom: 13px;
}

.popup_big_meeting_request_subtitle span {
  color: $accentLeftColor;
}

.popup_big_meeting_request_first_text {
  border: 1px solid $defaultTextColorAlpha3;
  border-radius: 10px;
  padding: 19px;
  padding-right: 13px;
  margin-bottom: 20px;
}

.popup_big_meeting_request_first_text p {
  font-weight: 500;
  line-height: 1.5;
  color: $defaultTextColor;
  margin-bottom: 16px;
}

.popup_big_meeting_request_first_text p span {
  color: $accentLeftColor;
}

.popup_big_meeting_request_first_subtext {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  color: $defaultTextColorAlpha8;
  line-height: 1.5;
  align-items: center;
}

.popup_big_meeting_request_first_subtext img {
  width: 34px;
  flex: none;
  margin-right: 12px;
}

.popup_big_meeting_request_first_subtext svg {
  width: 34px;
  height: 34px;
  flex: none;
  margin-right: 12px;
  fill: #25b061;
}

.popup_big_meeting_request_second_text {
  color: $defaultTextColor;
  line-height: 1.5;
  margin-bottom: 40px;
  position: relative;
  padding-left: 20px;
  font-weight: 500;
}

.popup_big_meeting_request_second_text p:not(:last-child) {
  margin-bottom: 9px;
}

.popup_big_meeting_request_third_text {
  font-size: 13px;
  line-height: 1.62;
  text-align: center;
  color: $defaultTextColorAlpha6;
  padding: 15px 10px 0;
  position: relative;
}

.popup_big_meeting_request_third_text:after {
  content: '';
  display: block;
  width: calc(100% - 140px);
  height: 1px;
  background-color: $defaultTextColorAlpha1;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.popup_big_meeting_request_bottom {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: space-between;
  -ms-align-items: center;
  align-items: center;
  margin-top: auto;
}

.popup_big_meeting_request_price {
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0.01em;
  color: $defaultTextColor;
}

.popup_big_meeting_request_price span {
  font-weight: 600;
  color: $accentRightColor;
  margin-left: 18px;
}

.popup_big_meeting_request_send_btn {
  width: 220px;
  height: 45px;
  line-height: 42px;
  text-align: center;
  color: $whiteColor;
  background: $buttonBackgroundColor;
  border-radius: 4px;
  font-weight: 600;
  cursor: pointer;
}

.popup_big_meeting_request_send_btn:hover {
  box-shadow: $searchItemBtnShadowHover;
}

.popup_big_meeting_request_send_btn img {
  vertical-align: middle;
  margin-bottom: 2px;
  margin-right: 12px;
}

.popup_big_meeting_request_send_btn svg {
  fill: #FFFFFF;
  vertical-align: middle;
  margin-bottom: 2px;
  margin-right: 12px;
  width: 26px;
  height: 26px;
}

.popup_big_meeting_request_send_btn.popup_big_meeting_request_send_btn_dis svg {
  fill: #828282;
}

.popup_big_meeting_request_send_btn_dis {
  border: 2px solid #828282;
  box-sizing: border-box;
  color: #828282;
  background: $whiteColor;
  cursor: default;
  //cursor: not-allowed;

  &:hover {
    box-shadow: none;
  }
}

.popup_big_meeting_request_close {
  position: absolute;
  right: 9px;
  top: 9px;
  padding: 5px;
  cursor: pointer;
}

.popup_big_meeting_request_close img {
  display: block;
  width: 21px;
}

.popup_big_meeting_request_close svg {
  width: 28px;
  height: 28px;
  fill: #ccd2d9;
  stroke: #ccd2d9;
  stroke-width: 0.5;
}

/*************************
**************************
media styles
**************************
*************************/

@media screen and (max-width: 1249px) {
  .popup_big_meeting_request {
    width: 683px;
    padding: 26px 16px 16px 10px;
  }

  .popup_big_meeting_request_wrap {
    grid-template-areas:
            "pop_req_left pop_req_top"
            "pop_req_left pop_req_middle"
            "pop_req_bottom pop_req_bottom";
    grid-template-columns: 247px 1fr;
    grid-column-gap: 12px;
  }

  .popup_big_meeting_request_photo {
    padding-top: 12px;
  }

  .popup_big_meeting_request_photo img {
    width: 247px;
    height: 395px;
  }

  .popup_big_meeting_request_title {
    font-size: 24px;
    margin-top: 0;
  }

  .popup_big_meeting_request_subtitle {
    font-size: 12px;
    padding-bottom: 12px;
  }

  .popup_big_meeting_request_first_text {
    padding: 16px 12px 22px 18px;
  }

  .popup_big_meeting_request_first_text p {
    font-size: 12px;
  }

  .popup_big_meeting_request_first_subtext {
    font-size: 12px;
  }

  .popup_big_meeting_request_second_text {
    font-size: 12px;
    padding-left: 18px;
    padding-bottom: 16px;
    margin-bottom: 11px;
  }

  .popup_big_meeting_request_third_text {
    font-size: 11px;
    padding: 0;
    padding-top: 15px;
  }

  .popup_big_meeting_request_bottom {
    padding-top: 28px;
  }
}

@media screen and (max-width: 720px) {
  .popup_big_meeting_request_close img {
    width: 17px;
  }

  .popup_big_meeting_request_close svg {
    width: 22px;
    height: 22px;
  }

  .popup_big_meeting_request {
    width: calc(100% - 20px);
    height: 85vh;
    max-height: 85vh;
    max-width: 100%;
    padding: 9px 10px 20px;
    overflow-y: scroll;
    margin: 10px auto;
  }

  .popup_big_meeting_request_wrap {
    grid-template-areas:
            "pop_req_left pop_req_top"
            "pop_req_middle pop_req_middle"
            "pop_req_bottom pop_req_bottom";
    grid-template-columns: 160px 1fr;
    grid-column-gap: 14px;
    height: 100%;
  }

  .popup_big_meeting_request_photo {
    padding-top: 0;
    margin-bottom: 10px;
  }

  .popup_big_meeting_request_photo img {
    width: 100%;
    height: auto;
  }

  .popup_big_meeting_request_title {
    font-size: 18px;
    text-align: left;
    margin-bottom: 8px;
  }

  .popup_big_meeting_request_subtitle {
    text-align: left;
  }

  .popup_big_meeting_request_profile_info {
    justify-content: flex-start;
  }

  .popup_big_meeting_request_name {
    font-size: 14px;
    text-align: center;
  }

  .popup_big_meeting_request_profile_info_bot {
    width: 200%;
  }

  .popup_big_meeting_request_id {
    font-size: 12px;
    flex: none;
    white-space: nowrap;
  }

  .popup_big_meeting_request_place {
    font-size: 12px;
    flex: none;
    white-space: nowrap;
  }

  .popup_big_meeting_request_bottom {
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    -o-flex-wrap: wrap;
    flex-wrap: wrap;
    justify-content: center;
    padding-top: 18px;
  }

  .popup_big_meeting_request_price {
    margin-bottom: 12px;
  }

  .popup_big_meeting_request_first_text {
    padding: 0;
    border: none;
  }

  .popup_big_meeting_request_second_text {
    padding-left: 0;
  }

  .popup_big_meeting_request_middle {
    padding: 16px 7px 13px 17px;
    border: 1px solid $defaultTextColorAlpha3;
    border-radius: 10px;
    overflow: hidden;
    margin-top: 16px;
  }

  .popup_big_meeting_request_middle_wrap {
    overflow-y: auto;
    height: 100%;
    padding-right: 6px;
  }

  .popup_big_meeting_request_third_text {
    padding-bottom: 16px;
  }

  .popup_big_meeting_request_middle_wrap::-webkit-scrollbar {
    width: 2px;
    background: $bleachColor;
    border-radius: 5px;
  }

  .popup_big_meeting_request_middle_wrap::-webkit-scrollbar-thumb {
    background: $scrollbarThumbColor;
    border-radius: 5px;
  }

  .popup_big_meeting_request_top {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 51px;
  }

  .popup_big_meeting_request_price {
    margin: 0;
  }

  .popup_big_meeting_request_send_btn {
    margin: 12px;
    margin-bottom: 0;
  }
}

@media screen and (max-height: 719px) {
  .popup_big_meeting_request {
    height: auto;
  }
}

@media screen and (max-width: 350px) {
  .popup_big_meeting_request_wrap {
    grid-template-columns: 121px 1fr;
  }

  .popup_big_meeting_request_profile_info_bot {
    flex-wrap: wrap;
  }

  .popup_big_meeting_request_id {
    margin-right: 0;
    margin-bottom: 2px;
  }
}
