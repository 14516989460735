@import "@sitestyles";

.notify_girls_wrap {
  height: 50%;
  border-top: 3px solid $notifyListBorder;
  padding-top: 32px;
  display: flex;
  flex-direction: column;
  flex: 1;
  z-index: 1;
}

@mixin holiday-notify-bottom($name, $image-path) {
  .notify_girls_wrap.#{$name} {
    position: relative;

    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      background-image: url(#{$image-path});
      background-size: contain;
      background-repeat: no-repeat;
      background-position: bottom;
      pointer-events: none;
      bottom: 0;
      left: 0;
      z-index: -1;
    }
  }
}

@include holiday-notify-bottom('halloween', '../../../../img/holiday/halloween_notify_bottom.svg');
@include holiday-notify-bottom('lunar_new_year', '../../../../img/holiday/lunar_new_year_notify_bottom.svg');
@include holiday-notify-bottom('thanksgiving_day', '../../../../img/holiday/thanksgiving_day_notify_bottom.svg');
@include holiday-notify-bottom('christmas', '../../../../img/holiday/christmas_notify_bottom.svg');
@include holiday-notify-bottom('woman_day', '../../../../img/holiday/woman_day_notify_bottom.svg');
@include holiday-notify-bottom('valentine_day', '../../../../img/holiday/valentine_day_notify_bottom.svg');
@include holiday-notify-bottom('independence_day', '../../../../img/holiday/independence_day_notify_bottom.svg');
@include holiday-notify-bottom('easter', '../../../../img/holiday/easter_notify_bottom.svg');

.notify_girls_list_wrap {
  flex: 1;
  overflow: hidden;
  position: relative;
}

.notify_girls_title {
  font-weight: 600;
  font-size: 16px;
  text-align: center;
  text-transform: uppercase;
  color: $profileButtonActiveColor;
  margin-bottom: 27px;
  cursor: pointer;

  @media screen and (max-width: 1499px) {
    margin-bottom: 17px;
    font-size: 15px;
  }

  @media screen and (max-width: 1139px) {
    font-size: 14px;
    border-bottom: 2px solid $notifyBlockBorder;
    margin: 0 16px;
    height: 55px;
    display: flex;
    align-items: center;
    padding-top: 3px;
    position: relative;
  }
}

.notify_girls_title_wrap {
  display: block;
}

@media screen and (max-width: 1139px) {
  .notify_girls_title:after {
    content: '';
    display: block;
    width: 8px;
    height: 14px;
    position: absolute;
    right: 3px;
    top: 50%;
    transform: translateY(-50%);
    transition: transform .3s;
    mask: url(../../../../img/notif-arr-blue.svg);
    mask-size: contain;
    mask-position: center;
    mask-repeat: no-repeat;
    background: $profileButtonActiveColor;
  }

  .active .notify_girls_title {
    border-color: transparent;
  }

  .active .notify_girls_title:after {
    transform: translateY(-50%) rotate(90deg);
  }
}

.notify_girls_list {
  margin-right: 7px;
  padding: 0 6px 60px 8px;
  overflow-y: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  list-style: none;

  @media screen and (min-width: 1200px) and (max-width: 1649px), screen and (max-width: 599px) {
    gap: 5px;
  }
}

.notify_girls_list::-webkit-scrollbar {
  width: 3px;
  background: $notificationScrollbarBg;
  border-radius: 3px;
}

.notify_girls_list::-webkit-scrollbar-thumb {
  background: $scrollbarThumbColor;
  border-radius: 3px;
}

@media screen and (max-width: 1649px) {
  .notify_girls_list {
    padding-left: 10px;
  }
}

@media screen and (max-width: 1499px) {
  .notify_girls_wrap {
    padding-top: 22px;
  }
}


@media screen and (max-width: 1139px) {
  .notify_girls_wrap {
    border: none;
    padding-top: 0;
  }

  .notify_girls_list_wrap {
    max-height: 0;
    transition: max-height .5s;
  }

  .notify_girls_wrap.active .notify_girls_list_wrap {
    max-height: 100%;
  }

  .notify_girls_list {
    padding-bottom: 0;
  }
}

.notify_item {
  background-color: $notifyGirlItemBg;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(95, 95, 95, 0.24);
  display: flex;
  position: relative;
  align-items: center;
  padding: 10px 10px 16px;
  cursor: pointer;
  gap: 17px;

  @media screen and (min-width: 1200px) and (max-width: 1649px), screen and (max-width: 599px) {
    gap: 10px;
  }
}

.notify_item_clear_mask {
  position: absolute;
  top: 7px;
  right: 7px;
  cursor: pointer;
  width: 16px;
  height: 16px;
  border: none;

  svg {
    fill: $notifyItemClose;
    width: 100%;
    height: 100%;
  }
}

.notify_item_text {
  font-weight: 500;
  line-height: 150%;
  color: $notificationDefaultText;
  margin-top: 10px;
  font-size: 14px;

  @media screen and (max-width: 1649px) {
    margin-top: 5px;
    font-size: 12px;
  }
}

.notify_item_time {
  position: absolute;
  right: 10px;
  bottom: 10px;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: -0.03em;
  color: rgba(51, 51, 51, 0.6);

  @media screen and (max-width: 1649px) {
    right: 8px;
    bottom: 2px;
    font-size: 10px;
  }

  @media screen and (max-width: 599px) {
    bottom: 3px;
  }
}

.notify_item_photo_wrap {
  position: relative;
}

.notify_item_photo {
  width: 100px;
  height: 100px;
  border-radius: 100%;
  object-fit: cover;
  display: block;
  transition-duration: .3s;

  @media screen and (max-width: 599px) {
    width: 72px;
    height: 72px;
  }
}

.notify_item_name {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: $notifyGirlItemTitle;
  margin-bottom: 10px;

  @media screen and (max-width: 1649px) {
    font-size: 14px;
    margin-bottom: 0;
  }
}

.notify_item.unknown_like .notify_item_photo_wrap {
  width: 100px;
  height: 100px;
  background-color: $notificationOfflineColor;
  border-radius: 100%;
  flex: none;
  background-image: url(../../../../img/unknown-like-question.svg);
  background-position: calc(50% - 0px) calc(50% + 5px);
  background-repeat: no-repeat;

  @media screen and (max-width: 599px) {
    width: 72px;
    height: 72px;
    background-size: 29px;
    background-position: calc(50% - 0px) calc(50% + 4px);
  }
}


.notify_item.is_online .notify_item_text {
  padding-left: 25px;
}

.notify_item.is_online .notify_item_subtext {
  padding-left: 25px;
}

.notify_item.is_online .notify_item_text:before {
  content: '';
  display: block;
  width: 17px;
  height: 11px;
  background-image: url(../../../../img/c4-online-ico.svg);
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  left: 0;
  top: 3px;
}


.notify_item.searching .notify_item_text {
  padding-left: 22px;
  @media screen and (min-width: 1200px) and (max-width: 1649px), screen and (max-width: 599px) {
    padding-left: 18px;
    padding-right: 10px;
  }
}

.notify_item.searching .notify_item_text:before {
  content: '';
  display: block;
  width: 14px;
  height: 14px;
  background-image: url(../../../../img/c4-searching-ico.svg);
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  left: 0;
  top: 2px;
}

.notify_item.appeal .notify_item_text {
  padding-left: 22px;
}

.notify_item.appeal .notify_item_text span {
  font-weight: bold;
  color: $notifyGirlItemAppealText;
}

.notify_item.appeal .notify_item_text:before {
  content: '';
  display: block;
  width: 15px;
  height: 15px;
  background-image: url(../../../../img/c4-appeal-ico.svg);
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  left: 0;
  top: 2px;
}

.notify_item.no_chats .notify_item_text {
  padding-left: 22px;
}

.notify_item.no_chats .notify_item_text span {
  font-weight: bold;
  color: $notifyGirlItemNoChatText;
}

.notify_item.no_chats .notify_item_text:before {
  content: '';
  display: block;
  width: 16px;
  height: 16px;
  background-image: url(../../../../img/c4-no-chats-ico.svg);
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  left: 0;
  top: 2px;
}

.notify_item.want_chat .notify_item_text {
  padding-left: 22px;
}

.notify_item.want_chat .notify_item_text span {
  font-weight: bold;
  color: $notifyGirlItemWantChatText;
}

.notify_item.want_chat .notify_item_text:before {
  content: '';
  display: block;
  width: 16px;
  height: 16px;
  background-image: url(../../../../img/c4-want-chat-ico.svg);
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  left: 0;
  top: 2px;
}

.notify_item.unknown_like .notify_item_text {
  padding-left: 22px;
  font-size: 14px;

  @media screen and (min-width: 1200px) and (max-width: 1649px), screen and (max-width: 599px) {
    font-size: 12px;
  }
}

.notify_item.unknown_like .notify_item_text:before {
  content: '';
  display: block;
  width: 15px;
  height: 13px;
  background-image: url(../../../../img/c4-viwed.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  position: absolute;
  left: 0;
  top: 3px;
}

.notify_item.updated .notify_item_text {
  padding-left: 23px;
}

.notify_item.updated .notify_item_text:before {
  content: '';
  display: block;
  width: 16px;
  height: 16px;
  background-image: url(../../../../img/profile-ico-new.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  position: absolute;
  left: 0;
  top: 2px;
}

.notify_item.updated .notify_item_text span {
  @media screen and (min-width: 1200px) and (max-width: 1649px), screen and (max-width: 599px) {
    display: none;
  }
}

.notify_item.joined .notify_item_text {
  padding-left: 24px;

  @media screen and (min-width: 1200px) and (max-width: 1649px), screen and (max-width: 599px) {
    padding-left: 19px;
  }
}

.notify_item.joined .notify_item_text:before {
  content: '';
  display: block;
  width: 14px;
  height: 16px;
  background-image: url(../../../../img/pin-lightgreen.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  position: absolute;
  left: 0;
  top: 2px;
}

.viewed .notify_item_text {
  padding-left: 20px;
  position: relative;
}

.viewed .notify_item_text:before {
  content: '';
  display: block;
  width: 14px;
  height: 10px;
  background-image: url(../../../../img/c4-viwed.svg);
  background-repeat: no-repeat;
  position: absolute;
  left: 0;
  top: 4px;
}

.notify_item.viewed_photos .notify_item_text {
  padding-left: 23px;
}

.notify_item.viewed_photos .notify_item_text:before {
  content: '';
  display: block;
  width: 15px;
  height: 15px;
  background-image: url(../../../../img/pic-orange.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  position: absolute;
  left: 0;
  top: 2px;
}

.notify_item.viewed {
  @media screen and (max-width: 599px) {
    padding: 10px;
  }
}

.liked .notify_item_text {
  padding-left: 18px;
  position: relative;
}

.liked .notify_item_text:before {
  content: '';
  display: block;
  width: 12px;
  height: 11px;
  background-image: url(../../../../img/c4-like.svg);
  background-repeat: no-repeat;
  position: absolute;
  left: 0;
  top: 3px;
}

.liked .notify_item_activity_btn svg {
  stroke: $whiteColor;
}

.notify_item.add_favs .notify_item_text {
  padding-left: 23px;
}

.notify_item.add_favs .notify_item_text:before {
  content: '';
  display: block;
  width: 17px;
  height: 16px;
  background-image: url(../../../../img/star-yellow.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  position: absolute;
  left: 0;
  top: 1px;
}

.notify_item.unknown_like .notify_item_text {
  margin-top: 0;
}

.notify_item_activity_btn {
  width: 174px;
  height: 36px;
  background: $buttonBackgroundColor;
  border-radius: 4px;
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 12px;
  letter-spacing: 0.01em;
  color: $notifyGirlItemButtonText;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  cursor: pointer;
  opacity: 0;
  pointer-events: none;
  transition: opacity .5s, box-shadow .3s;

  @media screen and (min-width: 1200px) and (max-width: 1649px), screen and (max-width: 599px) {
    width: 107px;
    right: 8px;
  }

  @media screen and (max-width: 599px) {
    width: 140px;
  }
}

.notify_item_match .notify_item_activity_btn {
  width: 100px;
  top: calc(50% + 14px);
  right: 10px;
  transition: opacity .7s, box-shadow .3s, width .5s, right .5s;
  white-space: nowrap;

  @media screen and (min-width: 1200px) and (max-width: 1649px), screen and (max-width: 599px) {
    width: 10px;
    top: calc(50% + 10px);
  }
}

.notify_item_activity_btn .big {
  display: block;

  @media screen and (min-width: 1200px) and (max-width: 1649px), screen and (max-width: 599px) {
    display: none;
  }
}

.notify_item_activity_btn .small {
  display: none;
  @media screen and (min-width: 1200px) and (max-width: 1649px), screen and (max-width: 599px) {
    display: block;
  }
}


.notify_item_activity_btn svg {
  width: 16px;
  height: 16px;
  fill: $whiteColor;

  @media screen and (min-width: 1200px) and (max-width: 1649px), screen and (max-width: 599px) {
    display: none;
  }
}

.notify_item.unknown_like .notify_item_activity_btn svg {
  width: 10px;
  height: 10px;
}

.notify_item_activity_btn:hover {
  box-shadow: 0 6px 12px rgba(84, 0, 114, 0.24);
}

.notify_item.activity:hover .notify_item_activity_btn,
.notify_item_match:hover .notify_item_activity_btn {
  opacity: 1;
  pointer-events: unset;
}

.notify_item_match:hover .notify_item_activity_btn {
  width: 174px;
  right: 35px;

  @media screen and (min-width: 1200px) and (max-width: 1649px), screen and (max-width: 599px) {
    width: 130px;
    right: 18px;
  }
}


.notify_item.activity .notify_item_right {
  transition: opacity .5s;
}

.notify_item.activity:hover .notify_item_right {
  pointer-events: none;
  opacity: 0;
}

.notify_item.activity .notify_item_text {
  position: relative;
}

.notify_item_match {
  background-color: $notifyGirlItemBg;
  border-radius: 8px;
  box-shadow: 0 3px 5px rgba(14, 4, 143, 0.15);
  position: relative;
  padding: 14px 15px;

  @media screen and (min-width: 1200px) and (max-width: 1649px), screen and (max-width: 599px) {
    padding: 15px;
    overflow: hidden;
    min-height: 120px;
  }
}

.notify_item_match:hover {
  z-index: 99;
}

.notify_item_match_title {
  font-weight: 500;
  color: $notifyGirlItemTitle;
  margin-bottom: 12px;
  transition: .3s;
  width: max-content;

  @media screen and (min-width: 1200px) and (max-width: 1649px), screen and (max-width: 599px) {
    font-size: 11px;
    text-align: center;
    margin: 6px 0 10px 0;
    width: 100%;
  }
}

.notify_item_match_row {
  display: flex;
  align-items: center;

  @media screen and (min-width: 1200px) and (max-width: 1649px), screen and (max-width: 599px) {
    flex-wrap: wrap;
    justify-content: center;
  }
}

.notify_item_match_man_img,
.notify_item_match_woman_img {
  width: 62px;
  height: 62px;
  border-radius: 100%;
  display: block;
  object-fit: cover;
  object-position: top;
  transition: width .5s, height .5s;

  @media screen and (min-width: 1200px) and (max-width: 1649px), screen and (max-width: 599px) {
    width: 52px;
    height: 52px;
  }
}

.notify_item_match_image {
  position: relative;
  transition: all .5s;
  display: flex;
  flex-direction: row;
  align-items: center;

  @media screen and (min-width: 1200px) and (max-width: 1649px), screen and (max-width: 599px) {
    transition: .5s;
  }
}

.notify_item_match_woman_img_wrap {
  position: relative;
  transition: margin-left .5s;
  display: flex;
  flex-direction: row;
  align-items: center;

  @media screen and (min-width: 1200px) and (max-width: 1649px), screen and (max-width: 599px) {
    transition: .5s;
  }
}

.notify_item_match_woman_img_wrap:after {
  content: '';
  display: block;
  width: 9px;
  height: 9px;
  background-color: $notificationOfflineColor;
  border-radius: 100%;
  border: 1px solid $notifyGirlItemBg;
  position: absolute;
  right: 3px;
  bottom: 3px;
}

.notify_item_match_woman_img_wrap:before {
  content: '';
  display: block;
  width: 16px;
  height: 16px;
  background-image: url(../../../../img/c4-match-ico.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  margin: 0 8px;
  transition: width .5s;
}

.online .notify_item_match_woman_img_wrap:after {
  background-color: $onlineColor;
}

.notify_item_match_woman {
  display: flex;
  align-items: center;
  cursor: pointer;

  @media screen and (min-width: 1200px) and (max-width: 1649px), screen and (max-width: 599px) {
    display: block;
  }
}

.notify_item_match_woman_name {
  font-weight: 600;
  color: $notificationMatchColor;
  margin-left: 12px;
  transition: opacity .5s;

  @media screen and (min-width: 1200px) and (max-width: 1649px), screen and (max-width: 599px) {
    display: none;
  }
}

.notify_item_match:hover .notify_item_match_woman_img_wrap {
  @media screen and (min-width: 1200px) and (max-width: 1649px), screen and (max-width: 599px) {
    transform: translateX(-70px);
  }
}

.notify_item_match:hover .notify_item_match_man_img,
.notify_item_match:hover .notify_item_match_image,
.notify_item_match:hover .notify_item_match_woman_img_wrap:before {
  width: 0;
  height: 0;
  margin: 0;
  opacity: 0;
  visibility: hidden;
}

.notify_item_match:hover .notify_item_match_woman_name {
  opacity: 0;
}

.notify_item_right {
  cursor: pointer;
  flex: 1;
  max-width: 100%;
}

.notify_item_each_like {
  padding: 20px 30px 10px;
  background-color: $notificationMatchBg;
  box-shadow: 0 3px 5px rgba(14, 4, 143, 0.15);
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  min-height: 185px;

  @media screen and (min-width: 1200px) and (max-width: 1649px), screen and (max-width: 599px) {
    padding: 13px 8px 9px;
    min-height: 128px;
  }
}

.notify_item_each_like:hover {
  z-index: 99;
}

.notify_item_each_like .notify_item_clear_mask svg {
  fill: $notifyGirlItemMatchDetails;
}

.notify_item_each_like_title {
  font-weight: bold;
  font-size: 16px;
  text-align: center;
  color: $notificationMatchColor;
  margin-bottom: 11px;

  @media screen and (min-width: 1200px) and (max-width: 1649px), screen and (max-width: 599px) {
    font-size: 14px;
  }
}


.notify_item_each_like_row {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.notify_item_each_like:hover .notify_item_each_like_row {
  justify-content: left;
}

.notify_item_each_like_man_photo,
.notify_item_each_like_girl_photo {
  width: 100px;
  height: 100px;
  border-radius: 100%;
  object-fit: cover;
}

.notify_item_each_like_man_photo.notify_item_empty_avatar,
.notify_item_match_image.notify_item_empty_avatar {
  width: 62px;
  height: 62px;
  border-radius: 100%;
  object-fit: cover;
  background-color: #fafaff;
  position: relative;

  svg {
    position: absolute;
    top: 25%;
    left: 11%;
    width: 24px;
    height: 24px;
    fill: #c3ccd6;
    margin: 0 10px;

    @media screen and (min-width: 1200px) and (max-width: 1649px), screen and (max-width: 599px) {
      margin: 0 9px;
    }
  }
}

.notify_item_each_like_man_photo {
  transition: width .5s, height .5s;
}

.notify_item_each_like:hover .notify_item_each_like_man_photo {
  width: 0;
  height: 0;
}

.notify_item_each_like_man_photo, .notify_item_each_like_girl_photo {
  @media screen and (min-width: 1200px) and (max-width: 1649px), screen and (max-width: 599px) {
    width: 56px;
    height: 56px;
  }
}

.notify_item_each_like_man_photo.notify_item_empty_avatar,
.notify_item_match_image.notify_item_empty_avatar {
  @media screen and (min-width: 1200px) and (max-width: 1649px), screen and (max-width: 599px) {
    width: 56px;
    height: 56px;
  }
}

.notify_item_each_like_row svg {
  width: 35px;
  height: 31px;
  margin: 0 10px;
  transition: .5s;
  fill: $notifyItemEachLikeHeart;

  @media screen and (min-width: 1200px) and (max-width: 1649px), screen and (max-width: 599px) {
    width: 29px;
    height: 26px;
    margin: 0 9px;
  }

  @media screen and (max-width: 599px) {
    margin: 0 13px;
  }
}

.notify_item_each_like:hover .notify_item_each_like_row > svg {
  width: 0;
  height: 0;
  margin: 0;
}

.notify_item_each_like_girl_photo_wrap {
  position: relative;
  transition: margin-left .5s;
}

.notify_item_each_like:hover .notify_item_each_like_girl_photo_wrap {
  margin-left: -10px;

  @media screen and (min-width: 1200px) and (max-width: 1649px), screen and (max-width: 599px) {
    margin-left: 0;
  }
}

.notify_item_each_like_girl_photo_wrap:after {
  content: '';
  display: block;
  width: 11px;
  height: 11px;
  background-color: $onlineColor;
  border-radius: 100%;
  border: 3px solid $notificationMatchBg;
  position: absolute;
  right: 6px;
  bottom: 8px;

  @media screen and (min-width: 1200px) and (max-width: 1649px), screen and (max-width: 599px) {
    width: 10px;
    height: 10px;
    right: -1px;
    bottom: 6px;
  }
}

.notify_item_each_like_hi_btn {
  width: 20px;
  height: 44px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: $notifyItemSayHiButtonBackground;
  box-shadow: 0 4px 5px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.01em;
  color: $notifyGirlItemButtonHiText;
  right: -50px;
  overflow: hidden;
  white-space: nowrap;
  opacity: 0;
  transition: width .5s, right .5s, opacity .5s, box-shadow .3s;
  cursor: pointer;
}

.notify_item_each_like_hi_btn:hover {
  box-shadow: 0 6px 12px rgba(84, 0, 114, 0.24);
}

.notify_item_each_like:hover .notify_item_each_like_hi_btn {
  width: 174px;
  right: -16px;
  opacity: 1;

  @media screen and (min-width: 1200px) and (max-width: 1649px), screen and (max-width: 599px) {
    width: 145px;
    right: 0;
  }
}

.notify_item_each_like_hi_btn svg {
  width: 26px;
  height: 26px;
  margin-right: 12px;
  fill: $notifyItemEachLikeHeart;

  @media screen and (min-width: 1200px) and (max-width: 1649px), screen and (max-width: 599px) {
    display: none;
  }
}

.notify_item_each_like_time {
  margin-top: 2px;
  font-size: 12px;
  letter-spacing: -0.03em;
  color: $notifyGirlItemMatchDetails;
  text-align: center;

  @media screen and (min-width: 1200px) and (max-width: 1649px), screen and (max-width: 599px) {
    text-align: right;
  }
}

.green {
  color: $notifyGirlItemOnlineText;
  font-weight: 500;
}

.notify_item_subtext {
  color: $notificationDefaultText;
  font-weight: 500;
  font-size: 12px;
}

.notify_item.is_online .notify_item_subtext {
  padding-left: 25px;
}

.is_online_hi .notify_item_text {
  margin-top: 10px;
}


@media screen and (max-width: 1649px) and (min-width: 1140px) {
  .notify_item.appeal .notify_item_text br,
  .notify_item.no_chats .notify_item_text br,
  .notify_item.want_chat .notify_item_text br,
  .notify_item.is_online_hi .notify_item_subtext br {
    display: none;
  }
}

.notify_item.activity .notify_item_text br {
  @media screen and (min-width: 1200px) and (max-width: 1649px), screen and (max-width: 599px) {
    display: none;
  }
}
