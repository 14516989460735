@import "@sitestyles";

.letters_attach_files_item {
  position: relative;
}

.letters_attach_files_item .rhap_container {
  box-shadow: none;
  background-color: transparent;
  height: 53px;
  width: 210px;
}

.letters_attach_files_item .rhap_main-controls {
  top: -8px;
}

.letters_attach_files_item .rhap_progress-section {
  width: calc(100% - 40px);
  position: relative;
  top: 22px;
  left: 40px;
  justify-content: space-between;
}

.letters_attach_files_item .rhap_volume-controls {
  width: 0;
}

.letters_attach_files_item .rhap_volume-controls {
  display: none;
}

.letters_attach_files_item .rhap_progress-container {
  display: inline-block;
  margin: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.letters_attach_files_item .rhap_current-time {
  position: relative;
  top: 3px;
  font-size: 12px;
  left: 0;
  color: $defaultTextColorAlpha6;
  letter-spacing: -0.03em;
}

.letters_attach_files_item .rhap_play-pause-button {
  position: relative;
  top: 0px;
  width: 31px;
  height: 31px;
}

.letters_attach_files_item .rhap_play-pause-button img {
  margin: 0;
  top: 0;
  vertical-align: top;
  width: 31px;
}

.letters_attach_files_item .rhap_total-time {
  position: relative;
  top: 3px;
  font-size: 12px;
  right: 0;
  color: $defaultTextColorAlpha6;
  letter-spacing: -0.03em;
}


.letters_attach_files_item .letters_attach_files_item_play_title {
  position: absolute;
  top: 6px;
  left: 50px;
  color: $accentLeftColor;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  width: 170px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  z-index: 1;
}

.letters_attach_files_item .rhap_progress-indicator {
  width: 15px;
  height: 15px;
  top: -6px;
  display: none;
}

.letters_attach_files_item img {
  display: inline-block !important;
  position: relative;
  top: 2px;
  margin-right: 5px;
}

.popup_write_letter .letters_attach_files_item .rhap_progress-section {
  left: 40px;
  width: calc(100% - 40px);
}

.popup_write_letter .letters_attach_files_item .letters_attach_files_item_play_title {
  top: 8px;
}

.popup_write_letter .letters_attach_files_item .letters_attach_files_item_play_title {
  left: 52px;
}

.popup_write_letter .letters_attach_files_item .rhap_current-time {
  right: -22px;
}

.popup_write_letter .letters_attach_files_item .rhap_total-time {
  right: 0px;
}

.c3_write_msg_textareas_files .letters_attach_files_item.file_audio .rhap_container {
  background-color: $bleachColor;
  height: 65px;
}

.c3_write_msg_textareas_files .letters_attach_files_item .rhap_main-controls {
  top: -18px;
}

.c3_write_msg_textareas_files .letters_attach_files_item .rhap_progress-container {
  top: 2px;
}

@media screen and (max-width: 599px) {

  .c3_write_msg_textareas_files .letters_attach_files_item.file_audio .rhap_container {
    width: 150px;
  }

  .c3_write_msg_textareas_files .letters_attach_files_item .letters_attach_files_item_play_title {
    width: 105px;
    font-size: 10px;
    top: 4px;
  }
  .popup_write_letter .c3_write_msg_textareas_files .letters_attach_files_item .letters_attach_files_item_play_title {
    top: 3px;
  }

  .c3_write_msg_textareas_files .letters_attach_files_item.file_audio .rhap_container {
    /*background-color: $whiteColor;
*/
    height: 43px;
  }

  .c3_write_msg_textareas_files .letters_attach_files_item.file_audio {
    /*background-color: $whiteColor;
*/
    height: 40px;
    flex-direction: row;

  }

  .c3_write_msg_textareas_files .letters_attach_files_item .rhap_progress-section {
    top: 20px;
  }

  .c3_write_msg_textareas_files .letters_attach_files_item .rhap_current-time {
    top: 5px;
  }

  .c3_write_msg_textareas_files .letters_attach_files_item .rhap_total-time {
    top: 5px;
  }

  .c3_write_msg_textareas_files .letters_attach_files_item .rhap_main-controls {
    top: -11px;
  }

  .popup_write_letter .c3_write_msg_textareas_files .letters_attach_files_item.file_audio .rhap_container {
    background-color: transparent;
  }

  .c3_write_msg_textareas_files .letters_attach_files_item .rhap_play-pause-button {
    top: 3px;
  }
}

.audioplayer_play {
  //background-color: $primaryLiteColor;
  background: url('../../img/play-audio-attach.svg') center no-repeat;
  background-size:  contain;
  width: 40px;
  height: 40px;
}

.audioplayer_pause {
  //background-color: $primaryLiteColor;
  background: url('../../img/stop_in_attach_file.svg') center no-repeat;
  background-size: contain;
  width: 40px;
  height: 40px;
}
