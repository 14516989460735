@import "@sitestyles";

.tutorial_step_illustration {
  width: 100%;
  height: 262px;
  margin-bottom: 56px;
  background: $tutorialPrimaryIllustrationBackground;
  border: 1px solid $tutorialPrimaryBorderColor;
  border-radius: 5px;
  overflow: hidden;
  position: relative;

  @media screen and (max-width: 540px) {
    height: 190px;
    border-radius: 10px;
    background: $tutorialPrimaryBackground;
  }

  &:before {
    content: '';
    display: block;
    background: $tutorialPrimaryIllustrationBackground;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 5px;
    z-index: 10;

    @media screen and (max-width: 540px) {
      display: none;
    }
  }
}

.tutorial_body {
  display: grid;
  grid-row-gap: 16px;
  margin-bottom: 48px;
}

.tutorial_step_title {
  color: $accentDarkColor;
  text-align: center;
  font-size: 20px;
  font-weight: 700;
}

.tutorial_step_text {
  color: $tutorialStepTextColor;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  font-family: $mainFont;
}

.step_notify {
  display: grid;
  grid-template-columns: 1fr 254px;
}

.step_notify_body {
  background: $tutorialSecondaryBackground;
  position: relative;

  @media screen and (max-width: 540px) {
    overflow: hidden;

    &:after {
      display: block;
      content: '';
      width: 100%;
      height: 100%;
      background: $tutorialPrimaryIllustrationBackground;
      backdrop-filter: blur(2px);
      position: absolute;
      top: 0;
      left: 0;
      z-index: 0;
    }
  }
}

.step_notify_sidebar {
  background: $tutorialNotifySidebarBackground;
  width: 100%;
  display: flex;
  flex-direction: column;
  box-shadow: 0 6px 12px 0 rgba(41, 40, 48, 0.16);
  box-sizing: border-box;
  position: relative;
  z-index: 10;

  @media screen and (max-width: 540px) {
    padding: 0 14px;
  }
}

.step_notify_logo_wrap {
  height: 72px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.step_notify_logo {
  max-height: 37px;
}

.step_notify_card {
  border-radius: 5px;
  background: $tutorialPopupBackground;
  box-shadow: 0 3px 5px 0 rgba(36, 0, 34, 0.10);
  display: grid;
  grid-column-gap: 8px;
  grid-template-columns: 52px 1fr;
  padding: 10px 14px;
  align-items: center;
  margin-bottom: 8px;
  position: relative;
  z-index: 11;

  @media screen and (max-width: 540px) {
    margin: 0 auto;
    padding: 0;
    background: transparent;
    box-shadow: unset;
    grid-template-columns: 125px 1fr;
    grid-column-gap: 12px;
  }

  @media screen and (max-width: 350px) {
    grid-template-columns: 100px 1fr;
  }
}

.step_notify_avatar {
  width: 52px;
  min-width: 52px;
  height: 52px;
  border-radius: 50%;
  border: 1px solid $accentDarkColor;
  background: $tutorialPopupBackground;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 540px) {
    border-radius: 5px;
    width: 100%;
    height: 92px;
  }

  svg {
    fill: $accentDarkColor;
    width: 20px;
    height: 20px;

    @media screen and (max-width: 540px) {
      width: 28px;
      height: 28px;
    }
  }
}

.step_notify_btns {
  display: flex;
  flex-direction: column;
  gap: 4px;

  @media screen and (max-width: 540px) {
    gap: 12px;
  }
}

.step_btn_credits {
  border-radius: 5px;
  background: $tutorialButtonBackground;
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.05);
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $tutorialButtonLightColor;
  text-align: center;
  font-size: 12px;
  font-weight: 600;
  line-height: normal;

  @media screen and (max-width: 540px) {
    height: 40px;
    font-size: 13px;
    padding: 0 16px;
  }

  svg {
    fill: $tutorialLightTextColor;
    width: 16px;
    min-width: 16px;
    height: 16px;
    margin-right: 8px;

    @media screen and (max-width: 540px) {
      width: 20px;
      min-width: 20px;
      height: 20px;
    }
  }
}

.step_btn_profile {
  border-radius: 5px;
  border: 1px solid $accentDarkColor;
  background: $tutorialPopupBackground;
  height: 24px;
  color: $accentDarkColor;
  font-size: 12px;
  font-weight: 600;
  line-height: normal;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 540px) {
    height: 40px;
    font-size: 13px;
    padding: 0 16px;
  }

  svg {
    fill: $accentDarkColor;
    width: 16px;
    min-width: 16px;
    height: 16px;
    margin-right: 8px;

    @media screen and (max-width: 540px) {
      width: 20px;
      min-width: 20px;
      height: 20px;
    }
  }
}

.step_menu_btn_credits {
  border-radius: 5px;
  background: $tutorialButtonBackground;
  box-shadow: 0 3px 5px 0 rgba(36, 0, 34, 0.30);
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $tutorialButtonLightColor;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 8px;

  svg {
    fill: $tutorialButtonLightColor;
    width: 24px;
    min-width: 24px;
    height: 24px;
    margin-right: 12px;
  }
}

.step_menu {
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  background: $tutorialPopupBackground;
}

.step_menu_item {
  padding: 0 8px 0 15px;
  height: 44px;
  margin: 0;
  list-style-type: none;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: $tutorialStepSecondaryColor;
  font-size: 16px;
  font-weight: 400;
  line-height: 140%;

  svg {
    width: 24px;
    min-width: 24px;
    height: 24px;
    fill: $tutorialStepSecondaryColor;
    margin-right: 10px;
  }
}

.step_notify_girls_slider {
  display: grid;
  grid-template-columns: 134px 1fr;
  grid-column-gap: 18px;
  padding-right: 20px;
  margin-right: 12px;
  align-items: center;
  height: 132px;
  margin-top: 60px;
  position: relative;
}

.girls_slider_text_wrap {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.girls_slider_title {
  color: $accentDarkColor;
  font-size: 16px;
  font-weight: 600;
  line-height: 140%;
}

.girls_slider_text {
  color: $tutorialStepTextColor;
  font-size: 12px;
  font-weight: 400;
  line-height: 140%;
}

.girls_slider_list {
  padding: 0;
  margin: 0 0 0 -20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 7px;
}

.girls_slider_list_item {
  padding: 0;
  margin: 0;
  width: 86px;
  min-width: 86px;
  height: 86px;
  border-radius: 10px;
  position: relative;
  list-style-type: none;
  display: flex;
  align-items: center;
  justify-content: center;

  &:last-child:after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background: $tutorialSliderListGradient;
    position: absolute;
    top: 0;
    right: -3px;
    z-index: 2;
  }
}

.girls_slider_status {
  position: absolute;
  top: 6px;
  right: -3px;
  border-radius: 3px;
  background: $tutorialOnlineBackground;
  height: 18px;
  padding: 0 8px;
  color: $tutorialOnlineTextColor;
  font-size: 10px;
  font-weight: 600;
  line-height: normal;
  z-index: 1;
}

.girls_slider_img {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
  border-radius: 10px;
}

.matchbox_user_avatar_img {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
  border-radius: 50%;
}

.girls_slider_btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: $tutorialButtonLightBackground;
  right: 30px;
  width: 32px;
  min-width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 4;

  svg {
    fill: $tutorialStepTextSecondaryColor;
    opacity: 0.8;
    width: 16px;
    height: 16px;
    min-width: 20px;
  }
}

.step_notify_box {
  border-radius: 10px;
  background: $tutorialPopupBackground;
  box-shadow: 0 3px 9px 0 rgba(41, 40, 48, 0.20);
  padding: 26px 24px 0 0;
  margin-right: 40px;
  margin-top: 8px;
  width: 200px;
  margin-left: -26px;
  height: 200px;
  display: flex;
  align-items: flex-start;
  justify-content: center;

  &:before {
    content: '';
    display: block;
    width: 300px;
    height: 100px;
    background: $tutorialNotifySidebarBackground;
    border-radius: 8px;
    margin-top: 24px;
  }
}

.desktop {
  display: grid;

  @media screen and (max-width: 540px) {
    display: none;
  }
}

.mobile {
  display: none;

  @media screen and (max-width: 540px) {
    display: grid;
    grid-template-columns: 1fr 230px;
  }
}

.step_notify_sidebar_title {
  color: $tutorialNotifySidebarTextColor;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
  padding-bottom: 24px;
  padding-top: 6px;

  &:after {
    display: block;
    content: '';
    width: 249px;
    height: 3px;
    background: $tutorialSidebarDividerColor;
    margin-top: 24px;

  }
}

.step_notify_sidebar_activity_title {
  color: $tutorialNotifySidebarTextColor;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
  margin-bottom: 16px;

  @media screen and (max-width: 540px) {
    text-align: left;
    font-size: 14px;
    border-top: 2px solid $tutorialSidebarDividerColor;
    margin-top: 6px;
    padding-top: 16px;
    position: relative;

    &:after {
      display: block;
      content: '';
      background: $accentDarkColor;
      width: 10px;
      min-width: 10px;
      height: 10px;
      mask: url('../../../../../../img/notif-arr-blue.svg') center no-repeat;
      mask-size: contain;
      -webkit-mask: url('../../../../../../img/notif-arr-blue.svg') center no-repeat;
      -webkit-mask-size: contain;
      position: absolute;
      top: 20px;
      right: 0;
      transform: rotate(90deg);
    }
  }
}

.matchbox {
  padding: 12px 6px 6px;
  border-radius: 8px;
  background: $tutorialMatchBoxBackground;
  height: 127px;
  margin: 0 6px;
  position: relative;

  @media screen and (max-width: 540px) {
    margin: 0;
    height: auto;
    //padding: 6px;
  }
}

.matchbox_title {
  color: $tutorialMatchBoxTitleColor;
  text-align: center;
  font-size: 12px;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 14px;

  @media screen and (max-width: 540px) {
    font-size: 10px;
    margin-bottom: 12px;
  }
}

.matchbox_time {
  color: $tutorialMatchBoxTimeColor;
  text-align: center;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;

  @media screen and (max-width: 540px) {
    font-size: 10px;
  }
}

.matchbox_btn_close {
  width: 20px;
  min-width: 20px;
  height: 20px;
  background: $tutorialMatchBoxTimeColor;
  position: absolute;
  top: 6px;
  right: 6px;
  mask: url('../../../../../../img/c3-media-close-tab.svg') center no-repeat;
  mask-size: 16px;
  -webkit-mask: url('../../../../../../img/c3-media-close-tab.svg') center no-repeat;
  -webkit-mask-size: 16px;
  outline: 0;
  border: 0;
  transition: all .6s ease-in-out;
  display: flex;

  @media screen and (max-width: 540px) {
    width: 16px;
    min-width: 16px;
    height: 16px;
    mask: url('../../../../../../img/c3-media-close-tab.svg') center no-repeat;
    mask-size: 12px;
    -webkit-mask: url('../../../../../../img/c3-media-close-tab.svg') center no-repeat;
    -webkit-mask-size: 12px;
  }
}

.matchbox_users_info {
  display: grid;
  grid-template-columns: 60px 33px 60px;
  grid-column-gap: 16px;
  width: fit-content;
  margin: 0 auto;
  align-items: center;

  @media screen and (max-width: 540px) {
    grid-template-columns: 52px 28px 52px;
    grid-column-gap: 14px;
  }
}

.matchbox_icon {
  width: 33px;
  height: 33px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  @media screen and (max-width: 540px) {
    width: 28px;
    height: 28px;
  }
}

.match_heart_icon svg {
  width: 32px;
  height: 32px;
  fill: $tutorialHeartColor;
}

.match_heart_link_icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  fill: $tutorialLightTextColor;
  width: 12px;
  height: 12px;
}

.matchbox_user_avatar {
  display: flex;
  width: 60px;
  height: 60px;
  justify-content: center;
  align-items: center;
  background: $tutorialPopupBackground;
  border-radius: 50%;
  position: relative;

  svg {
    width: 60px;
    height: 60px;

    @media screen and (max-width: 540px) {
      width: 52px;
      height: 52px;
    }
  }

  @media screen and (max-width: 540px) {
    width: 52px;
    height: 52px;
  }

  svg {
    width: 28px;
    height: 28px;
    fill: $tutorialMatchBoxUserColor;

    @media screen and (max-width: 540px) {
      width: 24px;
      height: 24px;
    }
  }
}


.matchbox_user_status {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background: $tutorialMatchBoxBackground;
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 2;

  &:before {
    content: '';
    display: block;
    position: absolute;
    bottom: 1.5px;
    right: 1.5px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: $tutorialOnlineBackground;
    z-index: 1;
  }
}

.card_candidates_card {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  width: calc(100% - 20px);
  min-width: 171px;
  height: 211px;
  gap: 5px;
  border-radius: 5px;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
  box-shadow: 1.4px 1.4px 2.3px rgba(197, 196, 208, .64);
}

.card_candidates_profile {
  width: 100%;
  height: 211px;
  background-color: $tutorialPopupBackground;
  border-radius: 5px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 1.5px;
  display: flex;
  position: relative;
  overflow: hidden;
  box-shadow: 1.4px 1.4px 2.3px rgba(197, 196, 208, .64);

  &:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 25%;
    background: $tutorialLightGradient;
  }
}

.card_candidates_photo {
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-image: $tutorialImageBackground;
  border-radius: 2.3px;
}

.card_candidates_more_photos {
  height: 13px;
  gap: 4px;
  background-color: $tutorialMorePhotoBackground;
  border-radius: 2px;
  justify-content: center;
  align-items: center;
  padding-left: 2px;
  padding-right: 2px;
  display: flex;
  position: absolute;
  top: 4px;
  right: 4px
}

.card_candidates_more_photos_icon {
  width: 9px;
  height: 9px;
  object-fit: cover;
  justify-content: center;
  align-items: center;
  display: flex;
}

.card_candidates_more_photos_text {
  color: $tutorialStepSecondaryColor;
  letter-spacing: -.01em;
  font-size: 7px;
  font-weight: 400;
}

.card_candidates_online {
  width: 76px;
  height: 13px;
  gap: 5px;
  background-image: $tutorialOnlineBackground;
  border-radius: 2px;
  justify-content: center;
  align-items: center;
  padding: 2px 5px;
  display: flex;
  position: absolute;
  top: 4px;
  left: -4px;

  @media screen and (max-width: 350px) {
    width: 65px;
  }
}

.card_candidates_online_text {
  color: $tutorialOnlineTextColor;
  font-size: 7px;
  font-weight: 600;
}

.card_candidates_info {
  width: 100%;
  height: 51px;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 6px 2px;
  display: flex;
  position: absolute;
  top: 159px;
  left: 1px;
}

.card_candidates_info_wrap {
  width: 100%;
  gap: 4px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.card_candidates_info_text {
  color: $tutorialStepTextSecondaryColor;
  text-align: center;
  font-size: 12px;
  font-weight: 600;
}

.card_candidates_info_buttons {
  width: 100%;
  gap: 4px;
  justify-content: flex-start;
  align-items: flex-start;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.card_candidates_info_button_chat {
  width: 100%;
  height: 32px;
  grid-column-gap: 4px;
  grid-row-gap: 4px;
  background-image: $tutorialButtonBackground;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  padding: 4px 10px;
  text-decoration: none;
  display: flex;
}

.card_candidates_info_button_icon {
  width: 12px;
  height: 12px;
  object-fit: cover;
  justify-content: center;
  align-items: center;
  display: flex;

  svg {
    width: 12px;
    height: 12px;
    fill: #FFFFFF;
  }
}

.card_candidates_info_button_like .card_candidates_info_button_icon svg {
  fill: $accentLeftColor;
}

.card_candidates_info_button_chat_text {
  color: $tutorialButtonLightColor;
  text-align: center;
  letter-spacing: .01em;
  font-size: 7px;
  font-weight: 600;
}

.card_candidates_info_button_like {
  width: 100%;
  height: 32px;
  gap: 5px;
  background-color: $tutorialButtonLightColor;
  border: 0.5px solid $tutorialStepSecondaryColor;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  padding: 4px 10px;
  text-decoration: none;
  display: flex;
}

.card_candidates_info_button_like_text {
  color: $tutorialStepSecondaryColor;
  text-align: center;
  letter-spacing: .01em;
  font-size: 6px;
  font-weight: 600;
}