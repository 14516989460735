@import "@sitestyles";

.email_used_modal_wrap {
  width: 100%;
  max-width: 738px;
  padding: 48px 78px;
  background-color: #fff;
  border-radius: 10px;
  text-align: center;
  position: relative;

  @media screen and (max-width: 1099px) {
    padding: 36px 50px;
  }

  @media screen and (max-width: 599px) {
    padding: 36px 20px 30px;
  }

  @media screen and (max-width: 399px) {
    padding-left: 10px;
    padding-right: 10px;
  }

  .modal_text {
    padding: 0 30px;
    font-weight: 600;
    font-size: 18px;
    line-height: 170%;
    color: #446077;

    p {
      & + p {
        margin-top: 32px;

        @media screen and (max-width: 1099px) {
          margin-top: 20px;
        }
      }

      @media screen and (max-width: 1099px) {
        font-size: 16px;
      }

      @media screen and (max-width: 599px) {
        font-size: 14px;
      }
    }

    @media screen and (max-width: 599px) {
      padding: 0;
    }
  }

  .modal_buttons {
    margin-top: 48px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 1099px) {
      margin-top: 30px;
    }
  }

  .modal_btn {
    width: 256px;
    height: 63px;
    display: flex;
    align-items: center;
    justify-content: center;

    font-weight: 600;
    font-size: 24px;
    letter-spacing: 0.01em;
    border-radius: 10px;
    cursor: pointer;

    @media screen and (max-width: 1099px) {
      width: 200px;
      height: 52px;
      font-size: 20px;
    }

    @media screen and (max-width: 599px) {
      font-size: 16px;
      height: 46px;
    }

    &.continue {
      width: max-content;
      padding: 0 15px;
    }
  }

  .color_btn {
    width: 316px;
    background: $buttonBackgroundColor;
    color: #fff;

    @media screen and (max-width: 1099px) {
      width: 200px;
    }
  }

  .grey_btn {
    border: 2px solid #A5A5A5;
    color: #A5A5A5;

    & + .color_btn {
      width: 256px;
      margin-left: 15px;

      @media screen and (max-width: 1099px) {
        width: 200px;
      }
    }
  }

  .close_modal {
    position: absolute;
    right: 5px;
    top: 5px;
    cursor: pointer;
    padding: 10px;

    svg {
      width: 24px;
      height: 24px;
      fill: #C4C4C4;
      display: block;

      @media screen and (max-width: 599px) {
        width: 16px;
      }
    }

    @media screen and (max-width: 599px) {
      right: 0;
      top: 0;
    }
  }
}