@import "@sitestyles";

.term_popup_wrap{
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    -o-flex-direction: column;
    flex-direction: column;
    padding: 76px 0 80px;
    margin: 15px;
    height: 100%;
    position: relative;
    background: #fff;
    border-radius: 8px;
}

.term_popup_wrap *{
    font-family: "Open Sans", sans-serif !important;
}

.term_popup_title{
    font-weight: 600;
    font-size: 40px;
    //color: $accentLeftColor;
    text-align: center;
    margin-bottom: 43px;
    background: $buttonBackgroundColor;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    clip-path: inset(1px);
}

.term_popup_content p{
    font-size: 18px;
    line-height: 1.5;
}

.term_popup_content b{
    font-size: 18px;
    line-height: 1.5;
    font-weight: 600;
}

.term_popup_content p + b{
    margin-top: 60px;
}

.term_popup_content {
    flex: 1;
    overflow-y: auto;
    padding-left: 80px;
    padding-right: 21px;
    margin-right: 55px;

    a {
        color: $primary2Color;
    }
}

.term_popup_content::-webkit-scrollbar {
    width: 3px;
    background: $bleachColor;
    border-radius: 3px;
}

.term_popup_content::-webkit-scrollbar-thumb {
    background: $primaryColor;
    border-radius: 3px;
}

@media screen and (max-width: 999px){
    .term_popup_wrap{
        padding: 40px 0 30px;
    }

    .term_popup_content{
        margin-right: 30px;
        padding-left: 40px;
    }
}

@media screen and (max-width: 899px) and (max-height: 450px) and (min-width: 420px){
    .term_popup_wrap{
        padding: 0;
    }

    .term_popup_title{
        margin-bottom: 20px;
    }

    .term_popup_content{
        margin: 0;
        padding: 0;
        padding-right: 16px;
    }

    .main_registration_form_input_text:first-child{
        width: calc(40% - 5px);
        margin-right: 5px;
    }

    .main_registration_form_input_text:nth-child(2){
        width: calc(60% - 5px);
        margin-left: 5px;
    }

    .main_registration_form{
        display: flex;
        flex-wrap: wrap;
    }

    .main_registration_form_dob_title{
        width: 60px;
        font-size: 12px;
        margin-bottom: 0;
        align-self: center;
        margin-right: 8px;
    }

    .main_registration_form_dob_row{
        flex: 1;
        margin-bottom: 0;
    }

    .main_registration_form_looking_row{
        margin-top: 24px;
        width: 100%;
    }
}

@media screen and (max-width: 599px){
    .term_popup_wrap{
        padding: 10px 0;
    }

    .term_popup_title{
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 16px;
    }

    .term_popup_content{
        padding-left: 15px;
        padding-right: 10px;
        margin-right: 5px;
    }

    .term_popup_content p{
        font-size: 14px;
    }

    .term_popup_content b{
        font-size: 14px;
        margin-bottom: 20px;
    }

    .term_popup_content p + b{
        margin-top: 40px;
    }
}

.term_popup_close{
    cursor: pointer;
    position: absolute;
    right: 16px;
    top: 16px;
    padding: 5px;
    background: #C2C2C2;
    width: 24px;
    height: 24px;
    -webkit-mask: url("../../../../../img/cross_white.svg") center no-repeat;
    mask: url("../../../../../img/cross_white.svg") center no-repeat;
    mask-size: contain;
    -webkit-mask-size: contain;

    &:hover {
        background: $attachTouchText;
    }
}
//.term_popup_close img{
//    width: 20px;
//}

@media screen and (max-width: 599px){
    .term_popup_close{
        position: absolute;
        width: 16px;
        height: 16px;
    }
    //.term_popup_close img{
    //    width: 14px;
    //}

    .term_popup_wrap *{
        word-break: break-word;
        white-space: unset !important;
    }
}
