@import "@sitestyles";

.page_header {
  background: $MainHeaderBg;
  box-shadow: 0 4px 25px rgba(0, 0, 0, 0.15);
  height: 80px;

  @media screen and (max-width: 599px) {
    height: 60px;
  }
}

.page_header_container {
  width: 100%;
  padding: 0 8%;
  height: 100%;
  display: flex;
  align-items: center;
}

.main_header_logo {
  max-width: max-content;
  cursor: pointer;

  img {
    max-height: 60px;
    min-height: 40px;

    @media screen and (max-width: 599px) {
      max-height: 40px;
      min-height: 35px;
    }
  }
}

.page_content {
  padding: 30px 20px 30px 30px;

  @media screen and (max-width: 599px) {
    padding: 15px 10px 20px 15px;
  }
}

.page_content_title {
  text-align: center;
  font-weight: 600;
  font-size: 40px;
  margin-bottom: 20px;

  @media screen and (max-width: 599px) {
    font-size: 27px;
  }
}