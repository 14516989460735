@import "@sitestyles";

.credits_page_scroll{
  flex: 1;
  overflow-y: auto;
  padding-bottom: 30px;
  margin-right: 10px;
  padding-right: 12px;
  padding-left: 25px;

  @media screen and (max-width: 599px){
    margin-right: 4px;
    padding-right: 7px;
    padding-left: 10px;
    padding-bottom: 0;
  }
}

.credits_page_scroll::-webkit-scrollbar {
  width: 3px;
  background: #fff;
  border-radius: 3px;
}

.credits_page_scroll::-webkit-scrollbar-thumb {
  background: $scrollbarThumbColor;
  border-radius: 3px;
}

.auto_top_up_wrap{
  padding: 24px;
  margin-top: 15px;
  background: #fff;
  box-shadow: 0 6px 12px rgba(47, 43, 99, 0.16);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 699px){
    flex-direction: column;
  }

  @media screen and (max-width: 599px){
    padding: 22px 10px;
  }
}

.auto_top_up_left{

  @media screen and (max-width: 599px){
    width: 100%;
  }
}

.top_up_title{
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  font-weight: 600;
  font-size: 18px;
  color: $accentLeftRightColor;

  @media screen and (max-width: 1399px){
    font-size: 16px;
  }

  @media screen and (max-width: 599px){
    font-size: 14px;
    justify-content: center;
  }

  svg {
    width: 32px;
    height: 32px;
    margin-right: 16px;
    fill: #01327a;

    @media screen and (max-width: 1399px){
      width: 30px;
      height: 30px;
      margin-right: 12px;
    }

    @media screen and (max-width: 599px){
      width: 28px;
      height: 28px;
      margin-right: 8px;
    }
  }
}

.top_up_button{
  width: 297px;
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background: $buttonBackgroundColor;
  font-weight: 600;
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;

  @media screen and (max-width: 1399px){
    font-size: 14px;
    height: 42px;
    width: 270px;
  }

  @media screen and (max-width: 699px){
    margin-left: auto;
    margin-right: auto;
  }

  @media screen and (max-width: 599px){
    height: 40px;
    max-width: 100%;
    font-size: 12px;
  }
}

.auto_top_up_subscribe{
  display: flex;
  align-items: center;

  @media screen and (max-width: 699px){
    margin-top: 24px;
  }
}

.top_up_subscribe_title{
  font-weight: 600;
  font-size: 18px;
  color: #8F9CAD;
  margin-right: 16px;

  @media screen and (max-width: 1399px){
    font-size: 16px;
  }

  @media screen and (max-width: 599px){
    font-size: 14px;
  }
}

.top_up_subscribe_switch{

  input{
    display: none;

    &:checked + label:before{
      left: 16px;
      background-color: $accentLeftRightColor;
    }
  }

  label{
    display: block;
    width: 31px;
    height: 18px;
    background-color: #E7F0FA;
    border-radius: 19px;
    position: relative;
    cursor: pointer;

    &:before{
      content: '';
      display: block;
      width: 12px;
      height: 12px;
      position: absolute;
      top: 50%;
      left: 3px;
      transform: translateY(-50%);
      background-color: #a9bed3;
      border-radius: 50%;
      transition: .3s;
    }
  }
}

.credits_table_wrap{
  flex: 1;
  //overflow-y: hidden;
  //padding: 0 24px;
  margin-top: 24px;
  display: flex;
  flex-direction: column;
}

.credits_table_title{
  font-weight: 600;
  font-size: 24px;
  color: #446077;
  margin-bottom: 28px;
}

.credits_table_mob_hor{
  margin-right: -10px;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
}

.credits_table_head{
  height: 65px;
  background-color: $background1Color;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-right: 10px;
}

.credits_table_head_item{
  text-align: center;
  min-width: max-content;
  font-weight: 600;
  font-size: 18px;
  color: #474F5C;
}

.credits_table_head_item.date{
  width: 12%;
}

.credits_table_head_item.credits{
  width: 28%;
}

.credits_table_head_item.card{
  width: 30%;
}

.credits_table_head_item.price{
  width: 30%;
}

.credits_table_list_wrap{
  flex: 1;
  overflow-y: auto;
  padding-right: 7px;
}

.credits_table_list_wrap::-webkit-scrollbar {
  width: 3px;
  background: #fff;
  border-radius: 3px;
}

.credits_table_list_wrap::-webkit-scrollbar-thumb {
  background: $scrollbarThumbColor;
  border-radius: 3px;
}

.credits_table_list{
  height: max-content;
}

.credits_table_date_row{
  height: 55px;
  display: flex;
  align-items: center;
  padding-left: 19px;
  font-weight: 600;
  font-size: 16px;
  color: #446077;
}

.row_color_blue{
  background-color: #F7F8FD;
}

.row_color_white{
  background-color: #fff;
}

.credits_table_row{
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.credits_table_row:first-child{
  border-radius: 5px 5px 0 0;
}

.credits_table_row:first-child:last-child{
  border-radius: 5px;
}

.credits_table_row:last-child{
  border-radius: 0 0 5px 5px;
}

.credits_table_list_col{
  text-align: center;
  min-width: max-content;
  font-size: 18px;
}

.credits_table_list_col:nth-child(2n - 1){
  font-weight: 500;
  color: #474F5C;
}

.credits_table_list_col:nth-child(2n){
  font-weight: 600;
  color: $accentRightColor;
}

.credits_table_list_col.date{
  width: 12%;
}

.credits_table_list_col.credits{
  width: 28%;
}

.credits_table_list_col.card{
  width: 30%;
}

.credits_table_list_col.price{
  width: 30%;
}

@media screen and (max-width: 1299px){
  .credits_table_head_item,
  .credits_table_list_col{
    font-size: 16px;
  }

  .credits_table_head{
    height: 45px;
  }

  .credits_table_row,
  .credits_table_date_row{
    height: 45px;
  }
}

@media screen and (max-width: 999px){
  .credits_table_wrap{
    margin-top: 30px;
  }
  .credits_table_title{
    margin-bottom: 16px;
    font-size: 20px;
  }
}

@media screen and (max-width: 799px){
  .credits_table_head_item,
  .credits_table_list_col{
    font-size: 14px;
  }
}

@media screen and (max-width: 599px){
  .credits_table_wrap{
    margin-top: 20px;
  }
  .credits_table_title{
    margin-bottom: 8px;
    font-size: 18px;
  }

  .credits_table_head_item,
  .credits_table_list_col{
    font-size: 12px;
  }

  .credits_table_mob_hor{
    overflow-x: auto;
    margin-right: 0;
  }

  .credits_table_head,
  .credits_table_list_wrap{
    min-width: 450px;
  }

  .credits_table_head{
    margin-right: 0;
  }

  .credits_table_list_wrap{
    margin-right: 0;
    padding-right: 0;
  }

  .credits_table_head_item.date{
    width: 17%;
  }

  .credits_table_head_item.price{
    width: 25%;
  }

  .credits_table_list_col.date{
    width: 17%;
  }

  .credits_table_list_col.price{
    width: 25%;
  }

  .credits_table_date_row{
    font-size: 14px;
    padding-left: 10px;
  }
}

@media screen and (max-width: 580px){
  .credits_table_wrap{
    padding: 0 0 20px;
    flex: none;
  }
}

@media screen and (max-height: 750px){
  .credits_table_wrap{
    overflow: visible;
  }
}