@import "@sitestyles";

.tutorial_step_illustration {
  width: 100%;
  height: 230px;
  margin-bottom: 56px;
  background: $tutorialPrimaryIllustrationBackground;
  border: 1px solid $tutorialPrimaryBorderColor;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  overflow: hidden;

  @media screen and (max-width: 540px) {
    height: fit-content;
    background-color: $tutorialPopupBackground;
    border: none;
  }
}

.tutorial_step_illustration.desktop{
  @media screen and (max-width: 540px) {
    display: none;
  }
}

.tutorial_step_illustration.mobile{
  display: none;

  @media screen and (max-width: 540px) {
    display: flex;
  }
}

.step_chat{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;

  &:before{
    content: '';
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;
    background: $tutorialPrimaryIllustrationBackground;
  }

  @media screen and (max-width: 540px) {
    flex-direction: column;

    &:before{
      background-color: transparent;
    }
  }
}

.step_chat_left{
  width: 40%;
  height: 100%;
  background-color: $tutorialPopupBackground;
  box-shadow: 0 3px 9px 0 rgba(41, 40, 48, 0.20);
  position: relative;
}

.step_chat_credit{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 12px;
  height: 44px;
  padding: 10px 14px;
  border-radius: 5px;
  background: $tutorialButtonBackground;
  box-shadow: 0 3px 5px 0 rgba(36, 0, 34, 0.30);
  margin: 8px 0;
  color: $tutorialLightTextColor;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
}

.step_chat_credit_icon {
  height: 28px;

  svg {
    width: 28px;
    height: 28px;
  }
}

.step_chat_menu{
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.step_chat_menu_item{
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding: 10px 8px 10px 15px;
  align-items: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  height: 40px;
  color: $tutorialMenuItemColor;
  border-radius: 10px;
  background-color: $tutorialPopupBackground;
}

.step_chat_menu_item.active{
  color: $accentDarkColor;
  position: relative;
  z-index: 3;
  font-weight: 600;

  svg {
    fill: $accentDarkColor;

    path{
      fill: $accentDarkColor;
    }
  }
}

.step_chat_menu_icon  {
  height: 24px;

  @media screen and (max-width: 540px) {
    height: 20px;
  }

  svg {
    width: 24px;
    height: 24px;
    fill: $tutorialMenuItemColor;

    @media screen and (max-width: 540px) {
      width: 20px;
      height: 20px;
      fill: $tutorialDefaultIconColor;
    }
  }
}

.step_chat_right{
  width: 50%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.step_chat_girl_image{
  width: 300px;
  height: 497px;
  position: relative;
  top: -50px;
  left: -20px;
  object-fit: cover;
}

.tutorial_body {
  display: grid;
  grid-row-gap: 16px;
  margin-bottom: 32px;
}

.step_chat_mob_top{
  flex: 1;
}

.step_chat_mob_bottom{
  flex: 1;
  background: $tutorialPopupBackground;
}

.step_chat_mob_menu{
  list-style: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: relative;
  padding: 12.5px 6px;
}

.step_chat_mob_menu_item{
  width: 64px;
  height: 64px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: $tutorialCategoriesColor;
  text-align: center;
  font-size: 8.693px;
  font-weight: 400;
  line-height: 12.171px;

  @media screen and (max-width: 380px) {
    width: 52px;
    height: 52px;
  }
}

.step_chat_mob_menu_item.active{
  border: 1px solid $accentDarkColor;
  border-radius: 10px;
  text-align: center;
  font-size: 11.429px;
  font-weight: 600;
  line-height: 16px;
  color: $accentDarkColor;

  svg {
    fill: $accentDarkColor;

    path{
      fill: $accentDarkColor;
    }
  }
}

.tutorial_step_title {
  color: $accentDarkColor;
  text-align: center;
  font-size: 20px;
  font-weight: 700;
}

.tutorial_step_text {
  color: $tutorialStepTextColor;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  font-family: $mainFont;
}


// FOR 5 step

.tutorial_hello_box {
  border-radius: 5px;
  border: 1px solid $tutorialStepSecondaryColor;
  background: $tutorialHelloBoxBackground;
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  color: $accentDarkColor;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
  gap: 16px;
  margin-bottom: 24px;

  @media screen and (max-width: 540px) {
    padding: 16px;
  }
}

.tutorial_hello_box_btns {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 16px;
  width: 100%;
}

.tutorial_btn_hello {
  border-radius: 5px;
  background: $tutorialButtonBackground;
  display: flex;
  height: 40px;
  padding: 0;
  justify-content: center;
  align-items: center;
  border: 0;
  outline: 0;
  color: $tutorialButtonLightColor;
  font-size: 14px;
  font-weight: 600;
  line-height: 140%;
  letter-spacing: 0.42px;
  box-sizing: border-box;

  @media screen and (max-width: 350px) {
    font-size: 12px;
  }

  &:before {
    content: '';
    display: block;
    margin-right: 8px;
    width: 24px;
    min-width: 24px;
    height: 24px;
    background: url('../../../../../../img/say-hello-white-emoji.svg') center no-repeat;
    background-size: contain;

    @media screen and (max-width: 540px) {
      width: 18px;
      min-width: 18px;
      height: 18px;
    }
    @media screen and (max-width: 350px) {
      width: 16px;
      min-width: 16px;
      height: 16px;
    }
  }
}

.tutorial_btn_like {
  border-radius: 5px;
  background: $tutorialButtonLightBackground;
  border: 1px solid $tutorialStepSecondaryColor;
  display: flex;
  height: 40px;
  padding: 0;
  justify-content: center;
  align-items: center;
  outline: 0;
  color: $tutorialStepSecondaryColor;
  box-sizing: border-box;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  letter-spacing: 0.42px;

  @media screen and (max-width: 350px) {
    font-size: 12px;
  }

  &:before {
    content: '';
    display: block;
    margin-right: 8px;
    width: 24px;
    min-width: 24px;
    height: 24px;
    background: $tutorialStepSecondaryColor;
    mask: url('../../../../../../img/c3lh-item-like.svg') center no-repeat;
    mask-size: contain;

    @media screen and (max-width: 540px) {
      width: 18px;
      min-width: 18px;
      height: 18px;
    }
    @media screen and (max-width: 350px) {
      width: 16px;
      min-width: 16px;
      height: 16px;
    }
  }
}
