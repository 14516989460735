@import "@sitestyles";

.unsubscribe_form {
  width: 616px;
  margin: 0 auto 100px;
  display: grid;

  @media screen and (max-width: 700px) {
    width: 100%;
    margin-bottom: 40px;
  }
}


.unsubscribe_form_fields_item {
  list-style-type: none;
  box-sizing: border-box;
  display: grid;


  &:last-child:before {
    display: none;
  }
}

.unsubscribe_form_fieldgroup {
  display: grid;
  border: 0;
  outline: 0;
  padding: 0 0 64px;
  margin: 0 auto;
  grid-row-gap: 40px;
  position: relative;
  max-width: 521px;

  @media screen and (max-width: 700px) {
    padding: 0 0 40px 48px;
    grid-row-gap: 24px;
  }

  //&:before {
  //  content: '';
  //  display: block;
  //  width: 3px;
  //  height: calc(100% + 12px);
  //  position: absolute;
  //  top: 24px;
  //  left: 24px;
  //  background: rgba(71, 79, 92, 0.3);
  //  box-sizing: border-box;
  //
  //  @media screen and (max-width: 700px) {
  //    width: 1.5px;
  //    top: 16px;
  //    left: 16px;
  //    height: calc(100% + 8px);
  //  }
  //}

  &:last-child {
    padding-bottom: 0;

    @media screen and (max-width: 700px) {
      padding-left: 0;
      margin: 0 auto;

      //.unsubscribe_form_fieldgroup_header {
      //  padding-left: 48px;
      //}
      //.unsubscribe_form_fieldgroup_header:before {
      //  left: 2px;
      //}
      //.unsubscribe_form_fieldgroup_header:after {
      //  left: 10px;
      //}

      .unsubscribe_form_fieldgroup_body {
        padding-left: 0;
      }
    }

  }

  //&:last-child:before {
  //  display: none;
  //}

  //&.selected:before {
  //  background: $unsubscribeTitleColor;
  //}
  //
  //&.selected .unsubscribe_form_fieldgroup_header:before {
  //  background: $unsubscribeTitleColor;
  //}
}

//.unsubscribe_form_fieldgroup_header {
//  position: relative;
//
//  &:before {
//    content: '';
//    display: block;
//    width: 48px;
//    height: 48px;
//    min-width: 48px;
//    border-radius: 50%;
//    background: #FFFFFF;
//    border: 2px solid $unsubscribeTitleColor;
//    position: absolute;
//    left: -80px;
//    top: 50%;
//    transform: translateY(-50%);
//    box-sizing: border-box;
//
//    @media screen and (max-width: 700px) {
//      width: 32px;
//      height: 32px;
//      min-width: 32px;
//      left: -48px;
//    }
//  }
//
//  &:after {
//    content: '';
//    display: block;
//    background: #FFFFFF;
//    mask: url('../../../img/arrow-checked-bold.svg') center no-repeat;
//    mask-size: contain;
//    -webkit-mask: url('../../../img/arrow-checked-bold.svg') center no-repeat;
//    -webkit-mask-size: contain;
//    width: 24px;
//    height: 24px;
//    min-width: 24px;
//    left: -68px;
//    top: 50%;
//    transform: translateY(-50%);
//    position: absolute;
//    box-sizing: border-box;
//    @media screen and (max-width: 700px) {
//      width: 16px;
//      height: 16px;
//      min-width: 16px;
//      left: -40px;
//    }
//  }
//}

.unsubscribe_form_fieldgroup_body {
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 700px) {
    padding-left: 12px;
  }
}

.unsubscribe_form_fieldgroup:last-child .unsubscribe_form_fieldgroup_body {
  grid-row-gap: 16px;
  @media screen and (max-width: 700px) {
    grid-row-gap: 8px;
  }
}

.unsubscribe_form_number {
  font-weight: 600;
  font-size: 18px;
  line-height: 150%;
  color: $unsubscribeTextColor;
  @media screen and (max-width: 700px) {
    font-size: 12px;
  }
}

.unsubscribe_form_title {
  font-weight: 700;
  font-size: 24px;
  line-height: 33px;
  color: $unsubscribeTitleColor;
  text-align: center;
  @media screen and (max-width: 700px) {
    font-size: 14px;
    line-height: 19px;
  }
}


.unsubscribe_form_radio_input {
  display: none;
}

.unsubscribe_form_radio_input:checked + .unsubscribe_form_radio_label {
  font-weight: 700;
  color: $unsubscribeTitleColor;

  &:before {
    background: $unsubscribeTitleColor;
  }

  &:after {
    opacity: 1;
    transform: scale(1);
  }
}

.unsubscribe_form_radio_label {
  font-weight: 600;
  font-size: 18px;
  line-height: 150%;
  padding: 12px 0 12px 40px;
  color: #474F5C;
  cursor: pointer;
  position: relative;
  transition: all .3s ease-out;

  @media screen and (max-width: 700px) {
    font-size: 14px;
    padding: 16px 0 16px 24px;
  }

  &:before {
    content: '';
    display: block;
    background: $unsubscribeInputBackground;
    border: 1px solid $unsubscribeInputPlaceholderColor;
    border-radius: 3px;
    width: 24px;
    height: 24px;
    min-width: 24px;
    box-sizing: border-box;
    transition: all .3s ease-out;
    position: absolute;
    left: 0;
    top: 12px;
    @media screen and (max-width: 700px) {
      width: 16px;
      height: 16px;
      min-width: 16px;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &:after {
    content: '';
    display: block;
    background: #FFFFFF;
    mask: url('../../../img/arrow-checked-bold.svg') center no-repeat;
    mask-size: contain;
    -webkit-mask: url('../../../img/arrow-checked-bold.svg') center no-repeat;
    -webkit-mask-size: contain;
    width: 12px;
    height: 12px;
    min-width: 12px;
    left: 6px;
    top: 18px;
    transform: scale(0);
    position: absolute;
    opacity: 0;
    transition: all .3s ease-out;
    @media screen and (max-width: 700px) {
      width: 8px;
      height: 8px;
      min-width: 8px;
      left: 4px;
      top: 44%;
      transform: scale(0) translateY(-50%);
    }
  }
}

.unsubscribe_form_radio_label:hover {
  &:before {
    border: 1px solid $unsubscribeTitleColor;
  }
}

.unsubscribe_form_textarea {
  resize: none;
  display: none;
  max-height: 104px;
  height: 104px;
  max-width: 521px;
  background: $unsubscribeInputBackground;
  border: 1px solid $unsubscribeInputPlaceholderColor;
  border-radius: 3px;
  padding: 16px;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: $unsubscribeTitleColor;
  font-family: $mainFont;
  margin-top: 16px;
  transition: all .3s ease-out;

  @media screen and (max-width: 700px) {
    max-height: 86px;
    max-width: 100%;
    font-size: 12px;
  }

  &:focus,
  &:active {
    font-weight: 400;
  }
}

.error_text {
  margin-top: 5px;
  font-size: 14px;
  color: #BF2600;
  font-style: italic;
  line-height: 1;

  @media screen and (max-width: 700px) {
    font-size: 11px;
  }
}

.unsubscribe_form_text_input {
  height: 48px;
  max-width: 521px;
  background: $unsubscribeInputBackground;
  border: 1px solid $unsubscribeInputPlaceholderColor;
  border-radius: 5px;
  padding: 0 24px;
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: $unsubscribeTitleColor;
  font-family: $mainFont;
  text-align: center;
  box-sizing: border-box;
  width: 100%;

  @media screen and (max-width: 700px) {
    height: 40px;
    font-size: 14px;
  }

  &::placeholder {
    font-weight: 600;
    color: $unsubscribeInputPlaceholderColor;
    font-size: 16px;
    line-height: 150%;
    font-family: $mainFont;
    @media screen and (max-width: 700px) {
      font-size: 14px;
    }
  }

}

.unsubscribe_form_submit_btn {
  border-radius: 5px;
  height: 48px;
  max-width: 521px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  justify-content: center;
  cursor: pointer;
  font-weight: 600;
  font-size: 18px;
  line-height: 150%;
  font-family: $mainFont;
  background-clip: padding-box, border-box;
  background-origin: border-box;
  border: double 1px transparent;
  background-image: $buttonBackgroundColor;

  @media screen and (max-width: 700px) {
    height: 40px;
    font-size: 14px;
    width: 100%;
  }
}

.unsubscribe_form_submit_btn_text {
  background: #FFFFFF;
  -webkit-background-clip: text;
  -webkit-text-fill-color: #FFFFFF;
  background-clip: text;
  text-fill-color: #FFFFFF;
  font-weight: 600;
  font-family: $mainFont;
}

.unsubscribe_form_fieldgroup:not(:disabled) .unsubscribe_form_text_input:focus,
.unsubscribe_form_fieldgroup:not(:disabled) .unsubscribe_form_text_input:active {
  border: 1px solid $unsubscribeTitleColor;
}

.unsubscribe_form_fieldgroup:not(:disabled) .unsubscribe_form_submit_btn:hover {
  background-image: linear-gradient(white, white), $buttonBackgroundColor;

  .unsubscribe_form_submit_btn_text {
    background: $buttonBackgroundColor;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    font-weight: 600;

  }
}


.unsubscribe_form_radio_textarea:checked ~ .unsubscribe_form_textarea {
  display: block;
}


.unsubscribe_form_fieldgroup:disabled .unsubscribe_form_number {
  color: #474F5C;
  opacity: 0.3;
  cursor: default;

  &::selection {
    background-color: transparent;
  }
}

.unsubscribe_form_fieldgroup:disabled .unsubscribe_form_title {
  color: #474F5C;
  opacity: 0.3;
  cursor: default;

  &::selection {
    background-color: transparent;
  }
}

.unsubscribe_form_fieldgroup:disabled .unsubscribe_form_fieldgroup_header:before {
  border: 2px solid rgba(71, 79, 92, 0.5);
}

.unsubscribe_form_fieldgroup:disabled .unsubscribe_form_radio_label {
  opacity: 0.3;
  cursor: default;

  &::selection {
    background-color: transparent;
  }
}


.unsubscribe_form_fieldgroup:disabled .unsubscribe_form_submit_btn {
  opacity: 0.5;
  cursor: unset;
}

.unsubscribe_form_fieldgroup:disabled .unsubscribe_form_text_input {
  opacity: 0.5;
  cursor: unset;
}

.unsubscribe_form_fieldgroup_selected:before {
  background: $unsubscribeTitleColor;
}

.unsubscribe_form_fieldgroup_selected .unsubscribe_form_fieldgroup_header:before {
  background: $unsubscribeTitleColor;
}
