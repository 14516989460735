@import "@sitestyles";

.empty_opened_chat_wrap{
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.empty_opened_chat{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: auto;
  margin-bottom: auto;

}

.empty_opened_chat_img{
  margin-bottom: 44px;
  max-width: 100%;
  max-height: calc(100% - 220px);
}

.empty_opened_chat_content{
  text-align: center;
  margin-bottom: 28px;
}

.empty_opened_chat_title{
  font-weight: 600;
  font-size: 24px;
  color: #426076;
  margin-bottom: 11px;
}

.empty_opened_chat_text{
  font-weight: 500;
  font-size: 18px;
  color: rgba(51, 51, 51, 0.8);
}

.empty_opened_chat_hello_btn{
  width: 226px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid $bluePinkColor;
  border-radius: 4px;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 0.03em;
  color: $bluePinkColor;
  transition: .3s;
  cursor: pointer;
}

.empty_opened_chat_hello_btn:hover{
  box-shadow: $smallBtnShadowHover;
}

.empty_opened_chat_hello_btn img{
  margin-right: 15px;
}

.empty_chat_say_hello_wrap{
  position: absolute;
  bottom: 5px;
  left: 0;
  width: 100%;
  padding: 0 10px;
  display: flex;
  align-items: flex-end;
}

.empty_chat_say_hello_list_wrap{
  flex: 1;
  overflow: hidden;
}

.empty_chat_say_hello_list_wrap.active{
  background-color: #ebedfa;
  padding: 10px;
}

.empty_chat_say_hello_list{
  max-height: 40px;
  display: flex;
  flex-wrap: wrap;
}

.empty_chat_say_hello_list::-webkit-scrollbar{
  width: 3px;
  height: 3px;
  background: #F7F8FD;
  border-radius: 5px;
}

.empty_chat_say_hello_list::-webkit-scrollbar-thumb {
  background: $scrollbarThumbColor;
  border-radius: 7px;
}

.empty_chat_say_hello_list_wrap.active .empty_chat_say_hello_list{
  max-height: 90px;
  overflow-y: auto;
}

.empty_chat_say_hello_list_item{
  width: max-content;
  height: 40px;
  padding: 0 15px;
  display: flex;
  align-items: center;
  background-color: $background1Color;
  color: $accentLeftColor;
  font-weight: 500;
  white-space: nowrap;
  margin-right: 15px;
  margin-bottom: 10px;
  border-radius: 5px;
  cursor: pointer;
  transition: box-shadow .3s;
}

.empty_chat_say_hello_list_item:hover{
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.05);
}

.empty_chat_say_hello_list_wrap.active .empty_chat_say_hello_list_item{
  background-color: #fff;
}

.empty_chat_say_hello_more_btn{
  width: 96px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-weight: 600;
  font-size: 13px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #fff;
  background: $buttonBackgroundColor;
  border-radius: 4px;
  position: relative;
  cursor: pointer;
  padding-bottom: 5px;
}

.empty_chat_say_hello_more_btn.active{
  background: #EBEDFA;
  color: #333;
  border-radius: 0 5px 5px 0;
  padding-bottom: 0;
}

.empty_chat_say_hello_more_btn svg{
  position: absolute;
  right: 13px;
  top: 3px;
  width: 14px;
  height: 14px;
  fill: #b4c2db;
}

.empty_chat_say_hello_more_btn img{
  position: absolute;
  right: 15px;
  top: 5px;
  width: 10px;
}

@media screen and (max-width: 1399px), screen and (max-height: 900px){
  .empty_opened_chat_title{
    font-size: 18px;
    margin-bottom: 8px;
  }

  .empty_opened_chat_text{
    font-size: 14px;
  }

  .empty_opened_chat_hello_btn{
    width: 193px;
    height: 42px;
    font-size: 14px;
  }

  .empty_opened_chat_hello_btn img{
    width: 21px;
  }
}

@media screen and (max-width: 599px){
  .empty_opened_chat_img{
    max-width: 149px;
    margin-bottom: 22px;
    max-height: 100%;
  }

  .empty_opened_chat_content{
    margin-bottom: 22px;
  }

  .empty_opened_chat_title{
    font-size: 15px;
  }

  .empty_opened_chat_text{
    font-size: 12px;
  }

  .empty_opened_chat_hello_btn{
    width: 153px;
    height: 37px;
    font-size: 12px;
  }

  .empty_opened_chat_hello_btn img{
    width: 16px;
    margin-right: 11px;
  }

  .empty_chat_say_hello_more_btn{
    display: none;
  }

  .empty_chat_say_hello_list{
    flex-wrap: nowrap;
    overflow: auto;
    max-height: unset;
  }

  .empty_chat_say_hello_list_wrap.active{
    padding: 5px;
    background-color: #fff;
  }

  .empty_chat_say_hello_list_item{
    margin-bottom: 2px;
    background-color: #EBEDFA;

    padding: 0 10px;
    height: 30px;
    font-size: 12px;
    margin-right: 8px;
  }

  .empty_chat_say_hello_list_wrap{
    position: relative;
  }

  .empty_chat_say_hello_list_wrap:after{
    content: '';
    display: block;
    width: 50px;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    background: linear-gradient(to right, rgba(255,255,255,.05), rgba(255,255,255,1));
    pointer-events: none;
  }
}

@media screen and (max-width: 399px){
  .empty_opened_chat_img{
    max-height: 100px;
    margin-bottom: 10px;
  }

  .empty_opened_chat_title{
    font-size: 14px;
    margin-bottom: 5px;
  }

  .empty_opened_chat_text{
    font-size: 11px;
  }
}

@media screen and (max-height: 799px) and (min-width: 600px){
  .empty_opened_chat{
    overflow: hidden;
    flex-direction: row;
  }

  .empty_opened_chat_img{
    max-height: 50%;
  }
}