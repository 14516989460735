@import "@sitestyles";

.profile_main{
  padding: 26px 26px 20px 26px;
}

.profile_main_top{
  display: flex;
}

.profile_main_left{
  position: relative;
  margin-right: 38px;
}

.profile_main_photo{
  width: 313px;
  height: 246px;
  object-position: top;
  object-fit: cover;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fafaff;

  svg {
    width: 100px;
    height: 120px;
    fill: #c3ccd6;
    margin-bottom: 28px;
  }

  &.user_vip {
    background: linear-gradient(90.32deg, #FEB930 0.31%, #FE8730 115.03%);

    svg {
      width: 72px;
      height: 72px;
      fill: #FFFFFF;
      margin-bottom: 0;
    }
  }
}

.profile_main_delete_photo{
  width: 80px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 5px;
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
  transition: .3s;
  font-size: 12px;
  color: #00317B;
}

.profile_main_delete_photo:hover{
  background-color: #fff;
}

.profile_main_delete_photo svg{
  margin-right: 8px;
}

.profile_main_crown_vip {
  background: #fff;
  padding: 1px 3px;
  border-radius: 50%;
  position: absolute;
  left: calc(50% - 18px);
  top: -18px;
}

.profile_main_crown_vip svg {
  width: 29px;
  height: 29px;
  fill: #ffba30;
}

.profile_main_new_avatar{
  width: 172px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 27px;
  background-color: #fff;
  position: absolute;
  left: 50%;
  bottom: 15px;
  transform: translateX(-50%);
  border: 1px solid #00317B;
  cursor: pointer;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.06em;
}

.profile_main_new_avatar_icon {
  width: 28px;
  height: 28px;
  fill: $iconAddPlusFillColor;
  margin-right: 10px;
}

.profile_main_middle{
  flex: 1;
  margin-right: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.profile_main_info_row{
  display: block;
}

.profile_main_info_row.name_row{
  display: flex;
  align-items: center;
}

.profile_main_info_row.place_row{
  display: flex;
  align-items: center;
}

.profile_main_name_click_edit{
  margin-top: 27px;
  margin-left: 12px;
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 0.06em;
  color: #446077;
  opacity: 0;
  transition: .3s;
  flex: none;
}

.profile_main_name_input_wrap:hover + .profile_main_name_click_edit,
.profile_main_info_dob_wrap:hover + .profile_main_name_click_edit{
  opacity: 1;
}

.profile_main_name_input_wrap{
  flex: 1;
}

.profile_main_info_dob_wrap_row{
  display: flex;
  align-items: center;
  width: 100%;
}

.profile_main_info_dob_wrap{
  display: flex;
  align-items: center;
}

.profile_main_info_row.dob_row{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.profile_main_info_row.dob_row span{
  width: auto;
}

.profile_main_info_dob_wrap{
  flex: 1;
}

.profile_main_info_dob_wrap > div:nth-child(2){
  margin: 0 9px;
}

.profile_main_name_city_wrap{
  flex: 1;
}

.profile_main_name_city_wrap + .profile_main_name_city_wrap{
  margin-left: 10px;
}

.profile_main_right{
  display: flex;
  flex-direction: column;
}

.profile_main_credits{
  width: 356px;
  height: 154px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: $background1Color;
  border-radius: 8px;
}

.profile_main_credits_info{
  display: flex;
  flex-direction: column;
}

.profile_main_credits_title{
  font-weight: 600;
  font-size: 18px;
  line-height: 140%;
  color: $accentLeftRightColor;

  @media screen and (max-width: 699px){
    font-size: 14px;
  }
}

.profile_main_credits_count{
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  gap: 16px;
}

.profile_main_credits_count_icon {
  width: 36px;
  height: 36px;
  background: $iconCoinsBalanceGradientColor;
  mask: url('../../../../../img/coins.svg') no-repeat center;
  mask-size: contain;
}

.profile_main_new_avatar_icon {
  width: 28px;
  height: 28px;
  background: $iconAddPlusFillColor;
  mask: url('../../../../../img/profile_new_avatar.svg') no-repeat center;
  mask-size: contain;
  -webkit-mask: url('../../../../../img/profile_new_avatar.svg') no-repeat center;
  -webkit-mask-size: contain;
}

.profile_main_credits_count .count{
  font-weight: 600;
  font-size: 28px;
  letter-spacing: 0.06em;
  color: $attachTouchText;
}

.profile_main_credits_count .credits{
  font-weight: 500;
  font-size: 20px;
  color: #4F4F4F;
  align-self: flex-end;
  margin-left: 4px;
}

.profile_main_credits_get_more{
  font-weight: 500;
  font-size: 16px;
  text-decoration-line: underline;
  color: $accentLeftRightColor;
  cursor: pointer;
}

.profile_main_change_email_pass{
  margin-top: auto;
}

.profile_main_bottom{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 27px;
  border-top: 1px solid rgba(68, 96, 119, 0.4);
  padding-top: 20px;
  gap: 24px;

  @media screen and (max-width: 699px) {
    gap: 0;
  }
}

.profile_main_bottom_left{
  display: flex;
  align-items: center;
  gap: 18px;
}

.profile_main_id{
  font-size: 14px;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  color: #333333;
}

.profile_main_verified_btn{
  width: 200px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 14px;
  color: #00317B;
  border: 1px solid #00317B;
  border-radius: 8px;
  letter-spacing: 0.06em;
  cursor: pointer;
}

.profile_main_verified_btn span{
  margin-left: 10px;
}

.profile_main_registered_email{
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.06em;
  color: #00317B;
  margin-left: auto;
  text-align: right;

  @media screen and (max-width: 699px) {
    margin: 0;
  }
}

.profile_main_registered_email span{
  color: #333;
}

.profile_main_sign_up_btn {
  padding: 8px 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;
  border-radius: 5px;
  border: 1px solid $accentLeftColor;
  transition-duration: .3s;
  color: $accentLeftColor;
  background: $whiteColor;
  cursor: pointer;
  font-size: 14px;
  text-wrap: nowrap;

  svg {
    fill: $accentLeftColor;
    width: 24px;
    height: 24px;
    transition-duration: .3s;
  }

  &:hover {
    background: $accentLeftColor;
    color: $whiteColor;

    svg {
      fill: $whiteColor;
    }
  }

  @media screen and (max-width: 699px) {
    margin: 12px 0 0 0;
  }
}

.hidden_upload{
  opacity: 0;
  display: none;
  position: absolute;
  top:0;
  bottom: 0;
  left:0;
  right: 0;
}

.profile_main_credits_get_more.mob{
  display: none;
}

@media screen and (max-width: 1749px){
  .profile_main_credits{
    width: 250px;
  }

  .profile_main_credits_count .count{
    font-size: 24px;
  }

  .profile_main_credits_count .credits{
    font-size: 12px;
  }

  .profile_main_name_click_edit{
    font-size: 10px;
    margin-left: 11px;
  }
}

@media screen and (max-width: 1599px){
  .profile_main_left{
    margin-right: 25px;
  }

  .profile_main_photo{
    width: 225px;
    height: 225px;
  }

  .profile_main_middle{
    margin-right: 20px;
  }

  .profile_main_new_avatar{
    width: 147px;
    border-radius: 8px;
  }

  .profile_main_credits{
    height: 119px;
    margin-top: auto;
  }

  .profile_main_info_dob_wrap > div:nth-child(2){
    flex: .7;
  }

  .profile_main_credits_get_more{
    font-size: 12px;
  }

  .profile_main_verified_btn{
    height: 38px;
    width: 170px;
    font-size: 12px;
  }

  .profile_main_verified_btn svg{
    width: 22px;
    height: 22px;
  }
}

@media screen and (max-width: 1299px){
  .profile_main_name_click_edit{
    position: absolute;
    right: 0;
    top: calc(100% + 2px);
    margin-left: 0;
    margin-top: 0;
  }

  .profile_main_info_row.name_row{
    position: relative;
  }

  .profile_main_info_dob_wrap_row{
    position: relative;
  }

  .profile_main_info_row.place_row{
    position: relative;
  }

  .profile_main_credits{
    width: 200px;
  }
}

@media screen and (max-width: 899px){
  .profile_main_top{
    flex-wrap: wrap;
  }

  .profile_main_middle{
    margin-right: 0;
    max-width: 325px;
  }

  .profile_main_right{
    width: 100%;
    flex-direction: row;
    align-items: center;
    margin-top: 18px;
    flex-wrap: wrap;
  }

  .profile_main_change_email_pass{
    width: calc(50% - 15px);
    margin-top: 0;
  }

  .profile_main_credits{
    width: calc(50% - 15px);
    flex-direction: row;
    height: 84px;
    margin-right: 30px;
    justify-content: space-between;
    padding: 0 10px;
  }

  .profile_main_credits_count{
    margin-bottom: 0;
  }

  .profile_main_credits_count .credits{
    align-self: revert;
  }

  .profile_main_credits_count_icon {
    width: 28px;
    height: 28px;
  }

  .profile_main_credits_get_more.pc{
    display: none;
  }

  .profile_main_credits_get_more.mob{
    width: 88px;
    height: 42px;
    background: $buttonBackgroundColor;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
  }

  .profile_main_credits_get_more svg{
    margin-right: 7px;
    width: 24px;
    height: 24px;
    fill: #FFFFFF;
  }
}

@media screen and (max-width: 699px){
  .profile_main_right{
    order: 1;
    margin-top: 0;
    margin-bottom: 20px;
    flex-wrap: wrap;
  }

  .profile_main_left,
  .profile_main_middle{
    order: 2;
  }

  .profile_main_credits{
    width: 100%;
    margin-right: 0;
    margin-bottom: 10px;
  }

  .profile_main_change_email_pass{
    width: 100%;
  }

  .profile_main_bottom{
    flex-direction: column;
  }

  .profile_main_bottom_left{
    margin-bottom: 10px;
    justify-content: space-between;
  }
}

@media screen and (max-width: 599px){
  .profile_main{
    padding: 16px;
  }

  .profile_main_left{
    width: 268px;
    margin: 0 auto 21px;
  }

  .profile_main_photo{
    width: 100%;
    height: 268px;
    object-position: top;
    margin-left: auto;
    margin-right: auto;
  }

  .profile_main_middle{
    width: 100%;
    flex: none;
    max-width: unset;
  }

  .profile_main_info_row + .profile_main_info_row{
    margin-top: 16px;
  }

  .profile_main_id{
    white-space: nowrap;
    font-size: 10px;
  }

  .profile_main_registered_email{
    font-size: 10px;
    // text-align: right;
    flex: 1;
  }

  .profile_main_registered_email span{
    max-width: 250px;
    // display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 12px;
    // margin-left: auto;
  }

  .profile_main_new_avatar{
    width: 138px;
    height: 36px;
  }

  .profile_main_new_avatar_icon{
    width: 20px;
    height: 20px;
  }

  .profile_main_credits_get_more.mob{
    width: 100px;
    height: 36px;
    font-size: 12px;
  }

  .profile_main_credits_get_more svg{
    margin-right: 10px;
  }

  .profile_main_credits_count{
    display: grid;
    grid-template-areas: "icon credits"
                         "icon count";
    grid-column-gap: 10px;
  }

  .profile_main_credits_count .count{
    grid-area: count;
    margin-left: 0;
    line-height: 1;
  }

  .profile_main_credits_count .credits{
    grid-area: credits;
    margin-top: auto;
    margin-bottom: 0;
    text-transform: capitalize;
  }

  .profile_main_credits_count_icon {
    grid-area: icon;
    align-self: center;
  }

  .profile_main_credits{
    padding: 0 16px;
  }
}