@import "@sitestyles";

.wrapper {
  max-width: 400px;
  width: 100%;
}

.image {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 300px;
  height: 300px;
  margin: 50px auto 0;

  @media (max-height: 700px) {
    width: 250px;
    height: 250px;
    margin: 0 auto 0;
  }

  @media (max-width: 600px) {
    margin: 60px auto 0;
    width: 250px;
  }

  @media (max-width: 400px) {
    margin: 24px auto 0;
  }

  @media (max-width: 360px) {
    margin: 0 auto 0;
    width: 200px;
    height: 200px;
  }

  img {
    width: 100%;
    height: auto;
  }
}

.title {
  color: #333;
  text-align: center;
  font-family: $mainFont;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 48px auto 16px;

  @media (max-width: 600px) {
    font-size: 18px;
    margin: 32px auto 12px;
  }
}

.text {
  color: #404752;
  text-align: center;
  font-family: $mainFont;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  margin: 0 auto 48px;

  @media (max-width: 600px) {
    font-size: 14px;
    margin: 0 auto 97px;
  }

  @media (max-width: 360px) {
    margin: 0 auto 48px;
  }
}

.button {
  height: 48px;
  width: 100%;
  box-sizing: border-box;
  padding: 12px 16px;
  border-radius: 8px;
  background: #212125;
  color: #FFF;
  text-align: center;
  font-family: $mainFont;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  cursor: pointer;
  transition: all .3s ease;

  &:hover {
    background: #363644;
    transition: all .3s ease;
  }
}