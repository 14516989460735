@import "@sitestyles";

.popup_confirm {
  position: relative;
  width: 700px;
  color: #446077;
  font-weight: 500;
  background-color: $whiteColor;
  padding: 50px 25px;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.popup_confirm_form {
  width: 100%;
}

.popup_confirm_title {
  font-weight: bold;
  font-size: 26px;
  color: $accentLeftColor;
  margin-bottom: 16px;
}

.popup_confirm_text {
  margin-bottom: 16px;
  text-align: center;
}

.popup_confirm_diff_text {
  margin-top: 16px;
  margin-left: 20px;
  margin-right: 20px;
  text-align: center;
  a {
    color: #354b5e;
    font-weight: bold;
  }
}

.popup_confirm_important_text {
  color: $accentLeftColor;
  font-weight: bold;
  margin-bottom: 16px;
  text-align: center;
}

.popup_confirm_input_wrap {
  width: 500px;
  margin: 0 auto;
}

.popup_confirm_input {
  width: 100%;
  background: #EFF5FE;
  padding: 12px 10px;
  border-radius: 4px;
  text-align: center;
  color: #00317B;
  font-size: 14px;
  font-weight: 600;
}

.popup_confirm_btn_row {
  display: flex;
  gap: 10px;

  @media screen and (max-width: 1249px) {
    margin-bottom: 16px;

    .popup_confirm_btn {
      width: 240px;
    }
  }

  @media screen and (max-width: 599px) {
    gap: 0;
    .popup_confirm_btn {
      width: calc(50% - 20px);
      font-size: 12px;
      padding: 10px 5px;
      margin: 0 auto;
    }
  }
}

.popup_confirm_btn {
  width: 290px;
  padding: 12px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $buttonBackgroundColor;
  font-weight: 500;
  font-size: 18px;
  border-radius: 4px;
  color: #FFFFFF;
  cursor: pointer;
  margin: 24px auto 0;

  img {
    width: 24px;
    margin-right: 12px;

    @media screen and (max-width: 1599px){
      width: 20px;
    }
  }

  svg {
    width: 26px;
    height: 26px;
    margin-right: 12px;
    fill: #FFFFFF;

    @media screen and (max-width: 1599px){
      width: 22px;
      height: 22px;
    }
  }

  @media screen and (max-width: 1599px){
    height: auto;
    padding: 12px;
    font-size: 14px;
  }

  @media screen and (max-width: 599px){
    text-align: center;
  }
}

.popup_confirm_close {
  position: absolute;
  right: 9px;
  top: 9px;
  padding: 5px;
  cursor: pointer;

  img {
    display: block;
    width: 21px;
  }

  svg {
    display: block;
    width: 28px;
    height: 28px;
    fill: #ccd2d9;
    stroke: #ccd2d9;
    stroke-width: 0.8;
  }
}

//Media styles ******************************

@media screen and (max-width: 1249px) {
  .popup_confirm {
    width: 600px;
    padding: 12px;
  }
}

@media screen and (max-width: 720px) {
  .popup_confirm {
    width: calc(100% - 20px);
    padding: 10px;
  }

  .popup_confirm_title {
    font-size: 18px;
    color: $accentLeftColor;
    margin-bottom: 10px;
  }

  .popup_confirm_important_text,
  .popup_confirm_text {
    font-size: 12px;
    margin-bottom: 10px;
  }
  .popup_confirm_diff_text {
    font-size: 12px;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .popup_confirm_input_wrap {
    max-width: 500px;
    width: 100%;
  }

  .popup_confirm_btn {
    margin: 16px auto 0;
    width: 100%;
  }

  .popup_confirm_close {
    top: 6px;
    right: 6px;

    img {
      width: 17px;
    }
    svg {
      width: 24px;
      height: 24px;
    }
  }
}