.header {
  height: 68px;
  width: 100vw;

  @media (max-width: 600px) {
    height: 59px;
  }
}

.wrapper {
  height: 64px;
  background: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  padding: 12px;
  box-sizing: border-box;

  @media (max-width: 600px) {
    height: 56px;
    padding: 8px;
    gap: 5px;
  }
}

.back {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  padding: 12px 16px;
  cursor: pointer;
  min-width: 100px;

  @media (max-width: 600px) {
    padding: 12px;
  }
}

.skip {
  padding: 12px 16px;
  cursor: pointer;

  @media (max-width: 600px) {
    padding: 12px;
  }
}

.logo {
  display: flex;
  align-items: center;
  justify-content: center;
  
  @media (max-width: 600px) {
    img{
      width: 110px;
      height: auto;
    }
  }
}

.progress_wrapper {
  background: #EFEFEF;
  height: 4px;
  transition: all .3s ease;
}

.progress_line {
  height: 4px;
  background: #6D6AE5;
  transition: all .3s ease;

  @media (max-width: 600px) {
    height: 3px;
  }
}