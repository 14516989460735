.image {
  height: auto;
  width: auto;
  max-width: 100%;
  max-height: 85vh;
  border-radius: 10px;
  object-fit: contain;
}

.image_gallery_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: auto;
  max-width: calc(100vw - 20px);
  min-width: 200px;
  max-height: 85vh;
}