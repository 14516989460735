@import "@sitestyles";

.message_gift_wrap {
  margin-top: 140px;
  padding-bottom: 50px;

  @media screen and (max-width: 1549px) {
    margin-top: 100px;
  }

  @media screen and (max-width: 1359px) and (min-width: 1140px) {
    margin-top: 100px;
  }

  @media screen and (max-width: 820px) {
    margin-top: 70px;
  }
}

.message_gift {
  position: relative;
  border-radius: 10px;
  background: $messageRightBackground;
  width: 100%;
  max-width: 100%;
  height: 180px;
  padding: 0 48px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;

  &.recipient {
    align-items: flex-end;
    background-color: $messageLeftBackground;
  }

  @media screen and (max-width: 1549px) {
    height: 150px;
  }

  @media screen and (max-width: 1359px) and (min-width: 1140px) {
    padding: 90px 24px 24px;
    height: fit-content;
    align-items: center;

    &.recipient {
      align-items: center;
    }
  }

  @media screen and (max-width: 820px) {
    padding: 80px 20px 20px;
    height: fit-content;
    align-items: center;

    &.recipient {
      align-items: center;
    }
  }
}

.message_gift_content {
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 1359px) {
    align-items: center;
  }
}

.message_gift_name {
  color: $profileButtonActiveColor;
  font-size: 32px;
  font-weight: 700;
  line-height: 150%;
  display: flex;
  align-items: center;
  margin-bottom: 16px;

  @media screen and (max-width: 1359px) and (min-width: 1140px) {
    font-size: 30px;
    margin-bottom: 10px;
  }

  @media screen and (max-width: 820px) {
    font-size: 20px;
    margin-bottom: 12px;
  }

  &:before {
    content: '';
    display: block;
    width: 48px;
    min-width: 48px;
    height: 48px;
    background: url('../../../../img/icon_virtual_gift.svg') center no-repeat;
    background-size: contain;
    margin-right: 16px;

    @media screen and (max-width: 820px) {
      width: 32px;
      min-width: 32px;
      height: 32px;
      margin-right: 12px;
    }
  }
}

.message_gift_text {
  margin: 0 0 0 46px;
  font-size: 16px;
  color: #474F5C;
  font-weight: 600;
  line-height: normal;

  @media screen and (max-width: 820px) {
    font-size: 14px;
    margin-left: 38px;
  }
}

.message_gift_image {
  position: absolute;
  right: 10px;
  bottom: -10px;
  width: 350px;
  height: auto;
  max-width: 350px;

  &.recipient {
    right: unset;
    left: 10px;
  }

  @media screen and (max-width: 1549px) {
    width: 270px;
    max-width: 270px;
    bottom: 0;
  }

  @media screen and (max-width: 1359px) and (min-width: 1140px) {
    width: 240px;
    max-width: 240px;
    bottom: unset;
    right: 50%;
    transform: translateX(50%);
    top: -110px;

    &.recipient {
      right: 50%;
      left: unset;
    }
  }

  @media screen and (max-width: 820px) {
    width: 180px;
    max-width: 180px;
    bottom: unset;
    right: 50%;
    transform: translateX(50%);
    top: -90px;

    &.recipient {
      right: 50%;
      left: unset;
    }
  }
}

.message_gift_info {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
}

.message_gift_image_wrapper {
  width: 40px;
  min-width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid #EEF1FE;
  z-index: 1;
  overflow: hidden;

  @media screen and (max-width: 820px) {
    width: 32px;
    min-width: 32px;
    height: 32px;
  }

  img {
    display: block;
    object-fit: cover;
    width: 100%;
    height: 100%;
    object-position: top center;
    border-radius: 50%;
  }

  div {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 16px;
  }

  &:nth-child(2) {
    position: absolute;
    left: 30px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 0;

    @media screen and (max-width: 820px) {
      left: 22px;
    }
  }
}

.message_bottom {
  display: flex;
  justify-content: space-between;
  position: absolute;
  bottom: -20px;
  right: 2px;

  &.recipient {
    right: unset;
    left: 2px;
  }
}

.message_date {
  font-size: 12px;
  line-height: 14px;
  letter-spacing: -0.03em;
  color: #BDBDBD;
}

.message_status {
  width: 19px;
  height: 12px;
  background-size: contain;
  background-repeat: no-repeat;
}

.message_status.send {
  margin-left: 10px;
  background-image: url(../../../../img/send.svg);
}

.message_status.read {
  margin-left: 10px;
  background-image: url(../../../../img/readed.svg);
}