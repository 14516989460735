@import "@sitestyles";

.payment_failed_wrapper {
  max-width: 477px;
  width: 100%;
  margin: 0 auto;
}

.payment_failed_title {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  color: #00317B;
  font-family: Montserrat, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  margin-bottom: 16px;
}

.payment_failed_title svg {
  width: 32px;
  height: 32px;
  fill: #01327a;
}

.payment_failed_text {
  color: #446077;
  text-align: center;
  font-family: Montserrat, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 170%;
  margin: 0 0 24px;
}

.payment_failed_error {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #CA2615;
  text-align: center;
  font-family: Montserrat, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 170%; /* 40.8px */
  text-transform: uppercase;
  margin-bottom: 24px;
}

.payment_failed_error svg {
  width: 64px;
  height: 58px;
  fill: #c92614;
}

.payment_failed_tryAgain {
  border-radius: 9px;
  background: var(--Linear, linear-gradient(90deg, #00317B 0%, #720066 100%));
  max-width: 316px;
  width: 100%;
  height: 63px;
  color: var(--white, #FFF);
  text-align: center;
  font-family: Montserrat, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.24px;
  margin: 0 auto 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.payment_failed_info {
  color: #8D8D8D;
  text-align: center;
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.payment_failed_recomendation {
  border-radius: 5px;
  border: 1px solid #8D8D8D;
  background: #F1F3FF;
  max-width: 433px;
  width: 100%;
  margin: 0 auto 32px;
  padding: 24px 16px;
  box-sizing: border-box;
}

.payment_failed_recomendation_title {
  color: #8D8D8D;
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.payment_failed_recomendation_content {
  color: #446077;
  font-family: Montserrat, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 27px */
}

.technical_msg {
  text-align: center;
  position: absolute;
  top: -110px;
  left: 50%;
  right: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 940px;
  margin: 0 auto;
  background: rgb(234, 237, 250);
  padding: 20px;
  box-sizing: border-box;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(47, 43, 99, 0.1);

  @media screen and (max-width: 599px) {
    top: -115px;
  }

  @media screen and (max-width: 459px) {
    top: -150px;
  }
}

.new_payment_method_technical_works {
  text-align: center;
  background: brown;
  color: white;
  width: 100%;
  padding: 2px;
  box-sizing: border-box;
  margin-top: 20px;
  margin-bottom: 20px;
}

.google_pay_wrap {
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 10px;

  div {
    display: flex;
    width: 100%;
    justify-content: center;
    gap: 10px;
  }
}

.technical_msg {
  text-align: center;
  position: absolute;
  top: -110px;
  left: 50%;
  right: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 940px;
  margin: 0 auto;
  background: rgb(234, 237, 250);
  padding: 20px;
  box-sizing: border-box;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(47, 43, 99, 0.1);

  @media screen and (max-width: 599px) {
    top: -115px;
  }

  @media screen and (max-width: 459px) {
    top: -150px;
  }
}

.payment_error_wrapper {
  background: #FDE5E5;
  padding: 20px 0;
  color: #DE2323;
  display: flex;

  @media screen and (max-width: 1140px) {
    display: block;
  }
}

.payment_error_right {
  width: 290px;

  @media screen and (max-width: 1140px) {
    width: 100%;
    text-align: center;
  }
}

.payment_error_left {
  flex: 1 1;
  padding-left: 30px;

  @media screen and (max-width: 1140px) {
    float: none;
    text-align: center;
  }
}

.payment_error_right img {
  float: left;
  position: relative;
  top: -2px;
  margin-right: 10px;

  @media screen and (max-width: 1140px) {
    float: none;
    top: 6px;
  }
}

.payment_error_support_btn {
  color: #fff;
  font-weight: 600;
  font-size: 15px;
  background: $accentLeftColor;
  width: calc(100% - 290px);
  border-radius: 8px;
  padding: 8px;
  margin: 8px 0 16px 0;
  text-align: center;
  cursor: pointer;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.7);
  transition: .3s;

  &:hover {
    box-shadow: none;
  }

  @media screen and (max-width: 1140px) {
    width: calc(100% - 100px);
  }

  @media screen and (max-width: 599px) {
    width: 100%;
  }
}


.error_message {
  padding: 10px;
  background: #ff7e71;
  width: 100%;
  min-height: 40px;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  border-radius: 8px;
}

.payment_modal_fixed {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #f4f4f4;
  overflow-y: auto;
  z-index: 1000;
}

.payment_modal_wrap {
  padding-top: 20px;
  padding-bottom: 160px;
  width: 1471px;
  margin: 0 auto;
}

.new_payment_balance {
  font-weight: bold;
  font-size: 18px;
  color: $accentLeftColor;
}

.new_payment_balance img {
  margin: 0 16px;
  display: inline-block;
}

.new_payment_balance_icon {
  width: 24px;
  height: 24px;
  background: $iconCoinsBalanceGradientColor;
  mask: url('../../img/coins.svg') no-repeat center;
  mask-size: contain;
  margin: 0 11px;
  vertical-align: text-bottom;
  display: inline-block;
}

.new_payment_balance span {
  font-size: 36px;
  color: $accentRightColor;
}

.new_payment_row {
  display: flex;
}

.new_payment_row_with_modal {
  margin-bottom: 120px;
}

.new_payment_method_name_paymentwall {
}

.new_payment_title {
  font-weight: 500;
  font-size: 16px;
  color: #446077;
  text-align: center;
  margin-bottom: 17px;
}

.new_payment_right_title.mob {
  display: none;
}

.new_payment_right {
  width: 310px;
  margin-left: -20px;
  padding-left: 20px;
}

.new_payment_methods_list {
  width: 100%;
  background-color: #EBEDFA;
  box-shadow: 0 4px 6px rgba(47, 43, 99, 0.1);
  border-radius: 0 10px 10px 0;
  list-style: none;
}

.new_payment_method_stripe_extra {
  width: 100%;
}

.new_payment_method_item_stripe_extra {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  border-radius: 0 10px 10px 0;
  position: relative;
  cursor: pointer;
}

.new_payment_method_item {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 30px 20px 30px 31px;
  border-radius: 0 10px 10px 0;
  position: relative;
  cursor: pointer;
}

.new_payment_method_item.dis_padding {
  padding-left: 0;
  padding-top: 0;
  padding-bottom: 0;
}

.new_payment_method_item:first-child {
  padding-bottom: 18px;
}

.new_payment_method_item:not(:last-child):after {
  content: '';
  display: block;
  width: calc(100% - 16px);
  height: 1px;
  background-color: #fff;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

//.new_payment_method_item:nth-child(2):after{
//  display: none;
//  bottom: 0;
//}

//.new_payment_method_item.active ~ .new_payment_method_item:nth-child(2):before{
//  display: none;
//}
//.new_payment_method_item.active ~ .new_payment_method_item:nth-child(2):after{
//  display: block;
//}

.new_payment_method_item.active {
  background-color: #fff;
  box-shadow: 3px 4px 6px rgba(47, 43, 99, 0.1);
}

.new_payment_method_item.subscribe_stripe_extra {
  background-color: #0E0C0C;
  color: #eeeeee;
}

.new_payment_method_item.subscribe_stripe_extra .new_payment_method_name img {
  width: 24px;
}

.new_payment_method_name {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 18px;
  color: #446077;

}

.new_payment_method_name img {
  margin-right: 14px;
}

.new_payment_method_name_paypal {
  width: 105px;
}

.new_payment_method_name_lpb {
  width: 76px;
}

.new_payment_method_name_uni {
  width: 150px;
}

.new_payment_method_name_googlepay {
  width: 160px;
}

.new_payment_method_name_securionpay {
  width: 140px;
}

.new_payment_method_name_stripe {
  width: 110px;
}

.new_payment_method_name .new_payment_method_name_paymentwall {
  width: 175px;
}

.subscribe_stripe_extra .new_payment_method_name {
  color: #fff;
}

.subscribe_stripe_extra .new_payment_method_name img.new_payment_method_name_apple_pay {
  width: 60px;
}

.new_payment_method_services_list {
  margin-left: auto;
}

.new_payment_method_technical_works {
  text-align: center;
  background: brown;
  color: white;
  width: 100%;
  padding: 2px;
  box-sizing: border-box;
  margin-top: 20px;
}

.new_payment_method_services_list img {
  display: block;
  width: 33px;
  object-fit: contain;
  margin: 4px auto;
}

.new_payment_method_services_list img.main {
  display: block;
  width: 40px;
}

.new_payment_method_services_list img:nth-child(2) {
  width: 38px;
}

.new_payment_method_services_list img + img {
  margin-left: 7px;
}

.new_payment_method_chosen_card {
  font-weight: 500;
  font-size: 12px;
  color: rgba(51, 51, 51, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 25px;
}

.new_payment_method_chosen_card.active {
  font-size: 24px;
  justify-content: end;
}

.new_payment_method_chosen_card p {
  font-weight: 600;
  color: #00317B;
  text-decoration: underline;
  margin-left: 8px;
  cursor: pointer;
}

.new_payment_method_chosen_card p:hover {
  text-decoration: none;
}

.new_payment_method_services_row {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 5px;
  margin-left: auto;
}

.new_payment_method_services_row.paypal {
  flex-wrap: wrap;
}

.new_payment_method_services_row.paypal img {
  height: 22px;
  width: 33px;
  object-fit: contain;
}

.new_payment_method_services_row.sub {
  justify-content: flex-end;
}

.new_payment_method_services_row img {

}

.new_payment_method_services_row img + img {

}

.new_payment_method_services_row span {
  font-size: 11px;
  color: #43484A;
}

.new_payment_left {
  position: relative;
  z-index: 2;
  flex: 1;
}

.new_payment_body {
  background-color: #fff;
  box-shadow: 0 4px 6px rgba(47, 43, 99, 0.1);
  border-radius: 10px 0 10px 10px;
  position: relative;
  padding: 48px 47px 55px 59px;
}

.new_payment_row.frame_open .new_payment_body {
  min-height: 200px;
  height: 100%;
}

.new_payment_body:before {
  content: '';
  display: block;
  width: 88px;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  transform: translateX(-50%);
  background-image: url(../../img/payments/note-label.svg);
  background-repeat: repeat-y;
  background-size: 100%;
}

.new_payment_grid {
  display: grid;

  grid-gap: 30px;
}

.new_payment_grid.grid_2 {
  grid-template-columns: repeat(4, 1fr);
  grid-template-areas: "i5 i5 i4 i4"
                       "i3 i3 i2 i2"
                       "i1 i1 i6 i6";
}

.new_payment_grid.grid_1 {
  grid-template-columns: repeat(3, 1fr);
  grid-template-areas: "i1 i1 i1"
                       "i2 i2 i3"
                       "i4 i5 i6";

  &.user_vip {
    grid-template-areas: "i1 i1 i1"
                         "i2 i2 i3"
                         "i4 i5 i6";
  }
}

.new_payment_grid.grid_4 {
  grid-template-columns: 1fr;
  grid-template-areas: "i1";
  height: 100%;
}

.new_payment_item_wrap {
  position: relative;
  padding: 12px 16px;
  cursor: pointer;
  height: 249px;
}

.new_payment_item_wrap.item_1 {
  grid-area: i1;
}

.new_payment_grid.grid_4 .new_payment_item_wrap.item_1 {
  grid-area: i1;
  grid-column-start: 1;
  grid-row-start: 1;
  height: 730px;
  padding: 30px;

}

.new_payment_item_wrap.item_2 {
  grid-area: i2;
}

.new_payment_item_wrap.item_3 {
  grid-area: i3;
}

.new_payment_item_wrap.item_4 {
  grid-area: i4;
}

.new_payment_item_wrap.item_5 {
  grid-area: i5;
}

.new_payment_item_wrap.item_6 {
  grid-area: i6;
}

.new_payment_item_wrap.item_7 {
  grid-area: i7;
}

.new_payment_grid:not(.grid_2) .new_payment_item_wrap:not(.item_1):before,
.new_payment_grid.grid_2 .new_payment_item_wrap:not(.item_6):before {
  content: '';
  display: block;
  width: 94px;
  height: 94px;
  position: absolute;
  left: -12px;
  top: -12px;
  z-index: 2;
  background-image: url(../../img/payments/item_lt.svg);
}

.new_payment_grid:not(.grid_2) .new_payment_item_wrap:not(.item_1):after,
.new_payment_grid.grid_2 .new_payment_item_wrap:not(.item_6):after {
  content: '';
  display: block;
  width: 94px;
  height: 94px;
  position: absolute;
  right: -12px;
  bottom: -12px;
  z-index: 2;
  background-image: url(../../img/payments/item_rb.svg);
}

.new_payment_item_bg {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-size: 100% 100%;
  opacity: .5;
  filter: grayscale(.5);

}

.new_payment_item_wrap.item_1 .new_payment_item_bg,
.new_payment_item_wrap.item_6 .new_payment_item_bg {
  background-image: url(../../img/payments/p1_1_bg.svg);
}

.new_payment_grid.grid_2 .new_payment_item_wrap.item_1 .new_payment_item_bg,
.new_payment_grid.grid_2 .new_payment_item_wrap.item_2 .new_payment_item_bg {
  background-image: url(../../img/payments/p1_3_bg.svg);
}

.new_payment_item_wrap.item_2 .new_payment_item_bg {
  background-image: url(../../img/payments/p1_2_bg.svg);
}

.new_payment_item_wrap.active .new_payment_item_bg {
  filter: unset;
  opacity: 1;
}

.new_payment_item_ribbon {
  position: absolute;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.new_payment_item_wrap.item_3 .new_payment_item_bg,
.new_payment_item_wrap.item_4 .new_payment_item_bg,
.new_payment_item_wrap.item_5 .new_payment_item_bg {
  background-image: url(../../img/payments/p1_3_bg.svg);
}

.new_payment_item_wrap.item_1.vip .new_payment_item_ribbon {
  width: 62px;
  height: 62px;
  top: -4px;
  left: -6px;
  z-index: 2;
  background-image: url(../../img/payments/vip_price.svg);
}

.new_payment_item_wrap.item_1 .new_payment_item_ribbon {
  width: 116px;
  height: 116px;
  top: -23px;
  left: -13px;
  z-index: 2;
  background-image: url(../../img/payments/ribbon_price.svg);
}

.new_payment_grid.grid_2 .new_payment_item_wrap.item_1 .new_payment_item_ribbon {
  width: 96px;
  height: 96px;
  left: 0;
  top: 0;
}

.new_payment_grid.grid_4 .new_payment_item_wrap.item_1 .new_payment_item_ribbon {
  width: 209px;
  height: 209px;
  left: -12px;
  top: -12px;
  background-image: url(../../img/payments/ribbon_time.svg);
}

.new_payment_item_wrap.item_2 .new_payment_item_ribbon {
  width: 96px;
  height: 96px;
  top: 0;
  left: 0;
  z-index: 2;
  background-image: url(../../img/payments/ribbon_seller.svg);
}

.new_payment_item_wrap.item_6 .new_payment_item_ribbon {
  width: 118px;
  height: 118px;
  top: -20px;
  left: -20px;
  z-index: 2;
  background-image: url(../../img/payments/ribbon_time.svg);
}

.new_payment_item {
  position: relative;
  padding: 15px 30px;
  height: 100%;
  background-repeat: no-repeat;
}

.new_payment_item_wrap.active .new_payment_item:after {
  content: '';
  display: block;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  position: absolute;
  right: 0;
  top: 0;
  transform: translate(55%, -55%);
  background-image: url(../../img/payments/package_check.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  box-shadow: 0 2px 2px rgba(50, 50, 71, 0.06), 0 2px 4px rgba(50, 50, 71, 0.06);
}

.new_payment_grid.grid_4 .new_payment_item_wrap.active .new_payment_item:after {
  width: 85px;
  height: 85px;
}

.new_payment_item_content {
  margin-left: auto;
  width: max-content;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
}

.new_payment_item_wrap.item_1 .new_payment_item {
  background-image: url(../../img/payments/p_1_1.jpg);
  background-size: 110%;
  background-position: left -50px top 0px;
  background-color: #B3B08A;
}

.new_payment_grid.grid_2 .new_payment_item_wrap.item_1 .new_payment_item {
  background-size: 100%;
  background-position: left top 0px;
}

.new_payment_grid.grid_4 .new_payment_item_wrap.item_1 .new_payment_item {
  background-image: url(../../img/payments/p_1_6.jpg);
  background-size: auto 80%;
  background-position: center;
  background-color: #fff;
}

.new_payment_grid.grid_4 .new_payment_item_wrap.item_1 .new_payment_item:before {
  display: none;
}

.new_payment_grid.grid_3 .new_payment_item_wrap.item_1 .new_payment_item {
  background-size: 100%;
  background-position: left top 0px;
}

.new_payment_item_wrap.item_2 .new_payment_item {
  background-image: url(../../img/payments/p_1_2.jpg);
  background-size: 100%;
  background-position: left top -40px;
}

.new_payment_grid.grid_2 .new_payment_item_wrap.item_2 .new_payment_item {
  background-size: 140%;
  background-position: left -30px top -40px;
}

.new_payment_grid.grid_3 .new_payment_item_wrap.item_2 .new_payment_item {
  background-size: 120%;
  background-position: left -30px top -20px;
}


.new_payment_grid.grid_5 .new_payment_item_wrap.item_2 .new_payment_item {
  background-size: 115%;
  background-position: left -30px top -20px;
}

.new_payment_item_wrap.item_3 .new_payment_item {
  background-image: url(../../img/payments/p_1_3.jpg);
  background-size: 115%;
  background-position: left -45px top 0px;
}

.new_payment_grid.grid_3 .new_payment_item_wrap.item_3 .new_payment_item {
  background-size: auto 195%;
  background-position: left 0 top -10px;
  background-color: #F7F5E9;
}

.new_payment_item_wrap.item_4 .new_payment_item {
  background-image: url(../../img/payments/p_1_4.jpg);
  background-size: 120%;
  background-position: left -115px top -15px;
}

.new_payment_grid.grid_3 .new_payment_item_wrap.item_4 .new_payment_item {
  background-size: auto 190%;
  background-position: left -60px top -20px;
  background-color: #FDF6EE;
}

.new_payment_item_wrap.item_5 .new_payment_item {
  background-image: url(../../img/payments/p_1_5.png);
  background-size: 50%;
  background-position: left 20px top -5px;
  background-color: #d6c9a6;
}

.new_payment_grid.grid_3 .new_payment_item_wrap.item_5 .new_payment_item {
  background-size: auto 160%;
  background-position: left 40px top 0px;
  background-color: #d6c9a6;
}

.new_payment_item_wrap.item_6 .new_payment_item {
  background-image: url(../../img/payments/p_1_6.jpg);
  background-size: 100%;
  background-position: center top 0px;
  background-color: #fdfcf8;
}

//.new_payment_item_wrap.item_6.vip .new_payment_item {
//  background-image: url(../../img/payments/p_1_7.jpg);
//  background-size: 100%;
//  background-position: center top 0px;
//  background-color: #fdfcf8;
//}

.new_payment_item_wrap.item_1 .new_payment_item:before {
  content: '';
  display: block;
  width: 50%;
  height: 100%;
  background: linear-gradient(269.91deg, #DECDB1 0.08%, rgba(222, 205, 177, 0) 99.35%);
  position: absolute;
  right: 0;
  top: 0;
}

.new_payment_item_wrap.item_2 .new_payment_item:before {
  content: '';
  display: block;
  width: 50%;
  height: 100%;
  background: linear-gradient(269.91deg, #D6C8A6 0.08%, rgba(214, 200, 166, 0) 99.35%);
  position: absolute;
  right: 0;
  top: 0;
}

.new_payment_item_wrap.item_3 .new_payment_item:before {
  content: '';
  display: block;
  width: 75%;
  height: 100%;
  background: linear-gradient(269.91deg, #FDF0CD 0.08%, rgba(228, 229, 227, 0) 99.35%, rgba(253, 240, 205, 0) 99.35%);
  position: absolute;
  right: 0;
  top: 0;
}

.new_payment_item_wrap.item_4 .new_payment_item:before {
  content: '';
  display: block;
  width: 66%;
  height: 100%;
  background: linear-gradient(269.91deg, #FEF8F0 50.08%, rgba(254, 248, 240, 0) 99.35%);
  position: absolute;
  right: 0;
  top: 0;
}

.new_payment_item_wrap.item_5 .new_payment_item:before {
  content: '';
  display: block;
  width: 75%;
  height: 100%;
  background: linear-gradient(269.91deg, #FEF8F0 0.08%, rgba(255, 255, 255, 0) 99.35%, rgba(255, 255, 255, 0) 99.35%);
  position: absolute;
  right: 0;
  top: 0;
}

.new_payment_item_wrap.item_6 .new_payment_item:before {
  content: '';
  display: block;
  width: 40%;
  height: 100%;
  background: linear-gradient(269.91deg, #FDFEF8 0.08%, rgba(252, 253, 247, 0) 99.35%);
  position: absolute;
  right: 0;
  top: 0;
}

.new_payment_item_credits_count {
  font-weight: bold;
  font-size: 40px;
  color: #474F5C;
  text-align: center;
}

.new_payment_item_credits_count span {
  display: block;
  font-weight: 600;
  font-size: 16px;
  text-align: center;
}

.new_payment_grid.grid_1 .new_payment_item_wrap.item_1 .new_payment_item_credits_count,
.new_payment_item_wrap.item_6 .new_payment_item_credits_count {
  font-weight: bold;
  font-size: 48px;
  color: #00317B;
}

.new_payment_grid.grid_4 .new_payment_item_wrap.item_1 .new_payment_item_credits_count {
  font-size: 96px;
  color: #00317b;
}

.new_payment_grid.grid_1 .new_payment_item_wrap.item_1 .new_payment_item_credits_count span,
.new_payment_item_wrap.item_6 .new_payment_item_credits_count span {
  font-size: 24px;
}

.new_payment_grid.grid_4 .new_payment_item_wrap.item_1 .new_payment_item_credits_count span {
  font-size: 38px;
}

.topup_bottom_link {
  font-weight: bold;
  font-size: 18px;
  color: #00317B;
  cursor: pointer;

  @media screen and (max-width: 1140px) {
    font-size: 15px;
  }

  @media screen and (max-width: 899px) {
    font-size: 13px;
  }
}

.new_payment_item_wrap.item_5 .new_payment_item_bottom {
  height: 58px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.new_payment_item_price {
  font-size: 10px;
  color: white;
  margin-bottom: 5px;
  background: red;
  border-radius: 20px;
  max-width: 35px;
  padding: 2px 4px;
  text-align: center;
}

.new_payment_grid.grid_4 .new_payment_item_wrap.item_1 {
  font-size: 30px;
}

.new_payment_item_save_wrap {
  font-weight: 900;
  font-size: 14px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #474F5C;
}

.new_payment_grid.grid_1 .new_payment_item_wrap.item_1 .new_payment_item_save_wrap {
  font-size: 16px;
  display: flex;
  align-items: center;
}

.new_payment_grid.grid_4 .new_payment_item_wrap.item_1 .new_payment_item_save_wrap {
  font-size: 30px;
}

.new_payment_item_save_count {
  font-size: 18px;
}

.new_payment_item_save_percent {
  font-weight: normal;
  text-decoration: line-through;
}

.new_payment_grid.grid_1 .new_payment_item_wrap.item_1 .new_payment_item_save_count {
  margin-right: 5px;
}

.new_payment_taxes {
  margin-top: 10px
}

.new_payment_get_credit_btns {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 55;
  background-color: #FFFFFF;
  padding: 16px 20px 26px 20px;

  @media screen and (max-width: 899px) {
    padding: 8px 20px 40px;
  }
}

.new_payment_get_credit_btns_top_row {
  display: flex;
  align-items: flex-start;
  margin-bottom: 16px;

  @media screen and (max-width: 899px) {
    display: block;
    margin-bottom: 10px;
  }
}

.new_payment_get_credit_btns_top_auto_switch_wrap {
}

.new_payment_get_credit_btns_top_auto_switch {
  display: flex;
  align-items: center;
  margin-right: 43px;
  flex: none;
  margin-bottom: 5px;

  @media screen and (max-width: 899px) {
    margin-bottom: 5px;
  }

  @media screen and (max-width: 599px) {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: 320px;
  }

  svg {
    width: 30px;
    height: 35px;
    fill: #01327a;
  }

  .auto_switch_text {
    font-weight: bold;
    font-size: 16px;
    color: $accentLeftRightColor;
    margin: 0 16px;
    min-width: 160px;

    @media screen and (max-width: 899px) {
      font-size: 16px;
      margin: 0 16px 0 8px;
    }
  }

  .switch_wrap {

    input {
      display: none;

      &:checked + label:before {
        left: 16px;
        background-color: $accentLeftRightColor;
      }
    }

    label {
      display: block;
      width: 31px;
      height: 18px;
      background-color: #E7F0FA;
      border-radius: 19px;
      position: relative;
      cursor: pointer;

      &:before {
        content: "";
        display: block;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background-color: #A9BED3;
        position: absolute;
        top: 50%;
        left: 3px;
        transform: translateY(-50%);
        transition: .3s;
      }
    }
  }

  img {
    @media screen and (max-width: 899px) {
      width: 20px;
    }
  }

  svg {
    @media screen and (max-width: 899px) {
      width: 26px;
      height: 30px;
    }
  }

  .auto_switch_info_btn {
    margin-left: auto;
    display: none;

    img {
      width: 24px;
      display: block;
    }

    svg {
      width: 24px;
      height: 24px;
      fill: #446178;
      vertical-align: middle;
    }

    @media screen and (max-width: 599px) {
      display: block;
    }
  }
}

.new_payment_get_credit_btns_top_text {
  font-weight: 600;
  font-size: 17px;
  color: #717171;
  padding-top: 5px;

  @media screen and (max-width: 1140px) {
    font-size: 14px;
  }

  @media screen and (max-width: 899px) {
    font-size: 12px;
    line-height: 1.5;
    padding-top: 0;
  }

  @media screen and (max-width: 599px) {
    display: none;
  }
}

.auto_payment_popup_wrap {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .25);
  z-index: 99;
  display: none;
  align-items: flex-end;
  backdrop-filter: blur(2px);

  &.active {
    display: flex;
  }
}

.auto_payment_popup {
  background-color: #fff;
  padding: 8px 20px 40px;

  .head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
    font-weight: bold;
    font-size: 16px;
    color: $accentLeftRightColor;

    img {
      display: block;
    }

    svg {
      width: 24px;
      height: 24px;
      fill: #446178;
      vertical-align: middle;
    }
  }

  .text {
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    color: #717171;

    margin-bottom: 15px;
  }
}

.new_payment_get_credit_btn_wrap_extra > div {
  height: 80px;
}

.new_payment_get_credit_btn_wrap {
  //height: 80px;
  //background-image: linear-gradient(to top, rgb(244,244,244), rgba(244,244,244,0));
}

.new_payment_get_credit_btn_wrap_extra {
  margin-right: 20px;
  width: 220px;
  height: 40px;
}

.new_payment_get_credit_btn_wrap_extra > div {
  width: 100%;
}

.new_payment_get_credit_btn_wrap_extra {
  width: 316px;
  height: 63px;
}

.new_payment_get_credit_btn {
  width: 316px;
  height: 63px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $buttonBackgroundColor;
  border-radius: 9px;
  font-weight: 600;
  font-size: 24px;
  letter-spacing: 0.01em;
  color: #FFFFFF;
  transition: box-shadow .3s;
  cursor: pointer;
}

.new_payment_get_credit_btn_disabled {
  opacity: .5;
  cursor: not-allowed;
}

.new_payment_grid.grid_2 .new_payment_get_credit_btn {
  //margin: 35px auto 0;
}

.new_payment_get_credit_btn:hover {
  box-shadow: $smallBtnShadowHover;
}

.payment_modal_close {
  width: 30px;
  height: 30px;
  position: absolute;
  right: 30px;
  top: 30px;
  background-image: url(../../img/c3lh-item-big-close-blue.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
}

.payment_modal_close svg {
  width: 14px;
  height: 14px;
  margin-right: 10px;
  fill: #01327a;
}

.payment_modal_close.back {
  background-image: url(../../img/left-arrow.svg);
}

.payment_modal_close > * {
  display: none;
}

.new_payment_get_credit_mob_btn {
  width: 290px;
  max-width: 100%;
  height: 41px;
  display: none;
  align-items: center;
  justify-content: center;
  color: #fff;
  background: $buttonBackgroundColor;
  border-radius: 8px;
  margin: 20px auto 0;

  font-weight: 600;
  font-size: 16px;
  letter-spacing: 0.01em;
}

.new_payment_get_credit_mob_btn_extra {
  width: 290px;
  max-width: 100%;
  height: 41px;
  display: none;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  margin: 20px auto 0;

  font-weight: 600;
  font-size: 16px;
  letter-spacing: 0.01em;
}

.new_payment_get_credit_mob_btn_extra > div {
  width: 100%;
}

.new_payment_buying_package_info {
  display: none;
  align-items: center;
  margin-bottom: 10px;
}

.new_payment_buying_package_info > span {
  margin: 0 7px;
}

.new_payment_buying_package_info div {
  font-weight: 600;
  font-size: 12px;
  color: rgba(51, 51, 51, 0.8);
}

.new_payment_buying_package_info div span {
  font-weight: bold;
  color: #333;
}

@media screen and (min-width: 1140px) and (max-height: 1000px) {
  .new_payment_grid.grid_4 .new_payment_item_wrap.item_1 {
    max-height: 60vh;
  }

  .new_payment_method_item {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .new_payment_method_services_list {
    display: flex;
    align-items: center;
  }

  .new_payment_method_services_list img + img {
    margin-top: 0;
    margin-left: 8px;
  }

  .new_payment_grid.grid_4 .new_payment_item_wrap.item_1 .new_payment_item_ribbon {
    width: 160px;
    height: 160px;
    left: -20px;
    top: -20px;
  }

  .new_payment_grid.grid_4 .new_payment_item_wrap.active .new_payment_item:after {
    width: 70px;
    height: 70px;
  }
}

@media screen and (max-width: 1599px), screen and (max-height: 1000px) and (min-width: 1600px) {
  .payment_modal_wrap {
    width: 924px;
    //padding-bottom: 60px;
  }

  .new_payment_balance {
    font-size: 14px;
    margin-bottom: 10px;
  }

  .new_payment_balance span {
    font-size: 24px;
  }

  .new_payment_balance img {
    width: 24px;
    margin: 0 11px;
    vertical-align: text-bottom;
  }

  .new_payment_title {
    font-size: 14px;
  }

  .new_payment_body {
    padding: 30px 30px 30px 50px;
    min-height: calc(100% - 33px);
  }

  .new_payment_body:before {
    width: 54px;
  }

  .new_payment_grid {
    grid-gap: 20px;
  }

  .new_payment_item_wrap {
    height: 153px;
    padding: 7px;
  }

  .new_payment_grid.grid_4 .new_payment_item_wrap.item_1 {
    height: 500px;
    padding: 18px;
  }

  .new_payment_grid:not(.grid_2) .new_payment_item_wrap:not(.item_1):before,
  .new_payment_grid.grid_2 .new_payment_item_wrap:not(.item_6):before {
    width: 45px;
    height: 45px;
    left: -4px;
    top: -4px;
    background-size: contain;
  }
  .new_payment_grid:not(.grid_2) .new_payment_item_wrap:not(.item_1):after,
  .new_payment_grid.grid_2 .new_payment_item_wrap:not(.item_6):after {
    width: 45px;
    height: 45px;
    right: -4px;
    bottom: -4px;
    background-size: contain;
  }

  .new_payment_item {
    padding: 8px 10px;
  }

  .new_payment_item_wrap.item_1 .new_payment_item_ribbon {
    width: 78px;
    height: 78px;
    top: -11px;
    left: -11px;
  }
  .new_payment_grid.grid_2 .new_payment_item_wrap.item_1 .new_payment_item_ribbon {
    width: 59px;
    height: 59px;
    top: -4px;
    left: -4px;
  }
  .new_payment_grid.grid_4 .new_payment_item_wrap.item_1 .new_payment_item_ribbon {
    width: 100px;
    height: 100px;
    top: -8px;
    left: -8px;
  }
  .new_payment_item_wrap.item_2 .new_payment_item_ribbon {
    width: 59px;
    height: 59px;
    top: -4px;
    left: -4px;
  }
  .new_payment_item_wrap.item_6 .new_payment_item_ribbon {
    width: 78px;
    height: 78px;
    top: -10px;
    left: -10px;
  }

  .new_payment_item_wrap.active .new_payment_item:after {
    width: 36px;
    height: 36px;
  }

  .new_payment_grid.grid_4 .new_payment_item_wrap.active .new_payment_item:after {
    width: 50px;
    height: 50px;
  }

  .new_payment_item_wrap.item_1 .new_payment_item {
    //background-size: 80%;
    //background-position: left top -40px;
  }
  .new_payment_grid.grid_2 .new_payment_item_wrap.item_1 .new_payment_item {
    background-size: auto 150%;
    background-position: left top;
  }
  .new_payment_grid.grid_3 .new_payment_item_wrap.item_1 .new_payment_item {
    background-size: auto 160%;
    background-position: left top;
  }
  .new_payment_grid.grid_4 .new_payment_item_wrap.item_1 .new_payment_item {
    background-size: auto 70%;
    background-position: center;
  }
  .new_payment_item_wrap.item_2 .new_payment_item {
    background-size: 110%;
    background-position: left top -10px;
  }
  .new_payment_grid.grid_2 .new_payment_item_wrap.item_2 .new_payment_item {
    background-size: auto 110%;
    background-position: left -10px top -10px;
  }
  .new_payment_grid.grid_3 .new_payment_item_wrap.item_2 .new_payment_item {
    background-size: auto 110%;
    background-position: left -20px top -10px;
  }
  .new_payment_grid.grid_5 .new_payment_item_wrap.item_2 .new_payment_item {
    background-size: auto 115%;
    background-position: left -20px top -10px;
  }
  .new_payment_item_wrap.item_3 .new_payment_item {
    background-size: 115%;
    background-position: left -25px top 0px;
  }
  .new_payment_grid.grid_3 .new_payment_item_wrap.item_3 .new_payment_item {
    background-size: 87%;
    background-position: left -5px top -5px;
  }
  .new_payment_item_wrap.item_4 .new_payment_item {
    background-size: 120%;
    background-position: left -60px top -5px;
  }
  .new_payment_grid.grid_3 .new_payment_item_wrap.item_4 .new_payment_item {
    background-size: auto 190%;
    background-position: left -50px top -10px;
  }
  .new_payment_item_wrap.item_5 .new_payment_item {
    background-size: 50%;
    background-position: left -0px top 0px;
    background-color: #d6c9a6;
  }
  .new_payment_grid.grid_3 .new_payment_item_wrap.item_5 .new_payment_item {
    background-size: auto 160%;
    background-position: left 10px top -5px;
    background-color: #d6c9a6;
  }
  .new_payment_item_wrap.item_6 .new_payment_item {
    background-position: center top 0px;
  }

  .new_payment_item_credits_count {
    font-size: 26px;
    text-align: center;
  }

  .new_payment_grid.grid_1 .new_payment_item_wrap.item_1 .new_payment_item_credits_count,
  .new_payment_item_wrap.item_6 .new_payment_item_credits_count {
    font-size: 28px;
  }

  .new_payment_grid.grid_4 .new_payment_item_wrap.item_1 .new_payment_item_credits_count {
    font-size: 60px;
  }

  .new_payment_item_credits_count span {
    font-size: 12px;
    margin-top: -2px;
  }

  .new_payment_grid.grid_1 .new_payment_item_wrap.item_1 .new_payment_item_credits_count span,
  .new_payment_item_wrap.item_6 .new_payment_item_credits_count span {
    font-size: 12px;
  }

  .new_payment_grid.grid_4 .new_payment_item_wrap.item_1 .new_payment_item_credits_count span {
    font-size: 20px;
  }

  .new_payment_item_price {
    margin-bottom: 4px;
  }

  .new_payment_grid.grid_4 .new_payment_item_wrap.item_1 {
    font-size: 20px;
  }

  .new_payment_item_save_wrap {
    font-size: 12px;
  }

  .new_payment_grid.grid_1 .new_payment_item_wrap.item_1 .new_payment_item_save_wrap {
    font-size: 12px;
  }

  .new_payment_grid.grid_4 .new_payment_item_wrap.item_1 .new_payment_item_save_wrap {
    font-size: 20px;
  }

  .new_payment_get_credit_btn_wrap_extra {
    width: 152px;
    height: 43px;
  }

  .new_payment_get_credit_btn {
    width: 152px;
    height: 43px;
    font-size: 14px;
    border-radius: 6px;
    font-weight: 500;
  }

  .new_payment_grid.grid_2 .new_payment_get_credit_btn {
    margin-top: 20px;
  }
}

@media screen and (max-width: 1139px) {
  .new_payment_body {
    //min-height: 100%;
  }

  .payment_modal_wrap {
    width: 600px;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .new_payment_balance {
    font-size: 14px;
    margin-bottom: 10px;
  }

  .new_payment_balance span {
    font-size: 24px;
  }

  .new_payment_balance img {
    width: 24px;
    margin: 0 11px;
    vertical-align: text-bottom;
  }

  .new_payment_balance_icon {
    width: 24px;
    height: 24px;
    margin: 0 11px;
    vertical-align: text-bottom;
  }

  .new_payment_title {
    font-size: 11px;

    margin: -17px auto 8px;
  }

  .new_payment_right_title.pc {
    display: none;
  }

  .new_payment_right_title.mob {
    display: block;
  }

  .new_payment_left_title {
    display: none;
  }

  .new_payment_right {
    width: 120px;
  }

  .new_payment_body {
    border-radius: 10px;
    padding: 22px 15px 30px 18px;
  }

  .new_payment_body:before {
    width: 24px;
    background-image: url(../../img/payments/note-label-mob.svg);
  }

  .new_payment_method_item {
    padding-left: 9px;
    padding-right: 9px;
    padding-top: 17px;
  }

  .new_payment_method_item.dis_padding {
    padding-right: 0 !important;
    padding-left: 0 !important;
    padding-top: 0 !important;
    padding-bottom: 30px;

    @media screen and (max-width: 599px) {
      padding-bottom: 17px;
    }
  }

  .new_payment_method_name {
    font-size: 12px;
    margin: auto;
  }

  .new_payment_method_name .new_payment_method_name_paypal {
    width: 72px;
  }
  .new_payment_method_name .new_payment_method_name_googlepay {
    width: 100px;
    margin: auto;
  }
  .new_payment_method_name .new_payment_method_name_lpb {
    width: 53px;
    margin: auto;
  }
  .new_payment_method_name .new_payment_method_name_uni {
    width: 70px;
    margin: auto;
  }
  .new_payment_method_name .new_payment_method_name_paymentwall {
    width: 78px;
    margin: auto;
  }

  .new_payment_method_services_row {
    flex-wrap: wrap;
    justify-content: space-around;
    margin: 10px auto 0;
  }

  .new_payment_method_services_row img {
    width: 40%;
    margin: 0;
  }

  .new_payment_method_services_row.sub {
    justify-content: center;
  }

  .new_payment_method_services_row.sub img + img {
    margin-left: 10px;
  }

  .new_payment_method_services_row img + img {
    margin: 0;
  }

  .new_payment_method_services_row span {
    display: none;
  }

  .new_payment_method_item:nth-child(2):after,
  .new_payment_method_item:nth-child(2):before {
    width: calc(100% - 16px);
  }

  .new_payment_method_services_list,
  .new_payment_method_services_row {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 5px;
    margin-top: 5px;
    padding: 5px;
    margin-left: 0;
  }

  .new_payment_method_services_list img,
  .new_payment_method_services_row img {
    margin: auto;
    display: block;
    width: 100%;
    height: 20px;
    object-fit: contain;
  }
  .new_payment_method_services_list img {
    width: 23px;
  }

  .new_payment_method_services_list img:nth-child(2) {
    width: 26px;
    margin-top: 0;
    margin-left: 7px;
  }

  .new_payment_method_chosen_card {
    margin-top: 10px;
  }

  .new_payment_method_chosen_card span {
    text-align: right;
    overflow: hidden;
    width: 32px;
    direction: rtl;
    font-size: 10px;
    flex: 1;
    white-space: nowrap;
  }

  .new_payment_method_chosen_card p {
    font-size: 10px;
    margin-left: 4px;
  }

  .new_payment_method_chosen_card.active p,
  .new_payment_method_chosen_card.active span {
    font-size: 20px;
  }

  .new_payment_method_name img {
    width: 21px;
    margin-right: 5px;
  }

  .new_payment_grid {
    grid-gap: 20px;
  }

  .new_payment_grid.grid_2 {
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas: "i6 i6"
                         "i5 i4"
                         "i3 i2"
                         "i1 i1";
  }

  .new_payment_item_wrap {
    height: 140px;
    padding: 7px;
  }

  .new_payment_grid.grid_4 .new_payment_item_wrap.item_1 {
    //height: 400px;
  }

  .new_payment_grid:not(.grid_2) .new_payment_item_wrap:not(.item_1):before,
  .new_payment_grid.grid_2 .new_payment_item_wrap:not(.item_6):before {
    width: 35px;
    height: 35px;
    left: -4px;
    top: -4px;
    background-size: contain;
  }
  .new_payment_grid:not(.grid_2) .new_payment_item_wrap:not(.item_1):after,
  .new_payment_grid.grid_2 .new_payment_item_wrap:not(.item_6):after {
    width: 35px;
    height: 35px;
    right: -4px;
    bottom: -4px;
    background-size: contain;
  }

  .new_payment_item {
    padding: 9px 14px;
  }

  .new_payment_item_wrap.item_1 .new_payment_item_ribbon {
    width: 59px;
    height: 59px;
    top: -5px;
    left: -5px;
  }
  .new_payment_grid.grid_2 .new_payment_item_wrap.item_1 .new_payment_item_ribbon {
    width: 50px;
    height: 50px;
  }
  .new_payment_item_wrap.item_2 .new_payment_item_ribbon {
    width: 50px;
    height: 50px;
    top: -4px;
    left: -4px;
  }
  .new_payment_item_wrap.item_6 .new_payment_item_ribbon {
    width: 60px;
    height: 60px;
    top: -6px;
    left: -6px;
  }

  .new_payment_item_wrap.active .new_payment_item:after {
    width: 28px;
    height: 28px;
  }

  .new_payment_item_wrap.item_1 .new_payment_item {
    //background-size: 110%;
    //background-position: left top -20px;
  }
  .new_payment_grid.grid_2 .new_payment_item_wrap.item_1 .new_payment_item {
    background-size: 100%;
    background-position: left 0 top 0px;
  }
  .new_payment_grid.grid_4 .new_payment_item_wrap.item_1 .new_payment_item {
    background-size: 80%;
    background-position: center;
  }
  .new_payment_grid.grid_3 .new_payment_item_wrap.item_1 .new_payment_item {
    background-size: 100%;
    background-position: left 0 top 0px;
  }
  .new_payment_item_wrap.item_2 .new_payment_item {
    background-size: cover;
    background-position: left -4px top 0px;
  }
  .new_payment_grid.grid_2 .new_payment_item_wrap.item_2 .new_payment_item {
    background-size: auto 110%;
    background-position: left -20px top -10px;
  }
  .new_payment_grid.grid_5 .new_payment_item_wrap.item_2 .new_payment_item {
    background-size: auto 115%;
    background-position: left -20px top -10px;
  }
  .new_payment_item_wrap.item_3 .new_payment_item {
    background-size: 115%;
    background-position: left -25px top 0px;
  }
  .new_payment_item_wrap.item_4 .new_payment_item {
    background-size: 120%;
    background-position: left -60px top -5px;
  }
  .new_payment_item_wrap.item_5 .new_payment_item {
    background-size: 50%;
    background-position: left -0px top 0px;
    background-color: #d6c9a6;
  }

  .new_payment_item_credits_count {
    font-size: 24px;
    text-align: center;
  }

  .payment_modal_wrap.frame_open {
    width: calc(100% - 50px);
  }

  .new_payment_row.frame_open .new_payment_body {
    //overflow: hidden;
  }

  .new_payment_row.frame_open iframe {
    width: 100%;
    overflow: hidden;

  }

  .new_payment_grid.grid_1 .new_payment_item_wrap.item_1 .new_payment_item_credits_count,
  .new_payment_item_wrap.item_6 .new_payment_item_credits_count {
    font-size: 24px;
  }

  .new_payment_item_credits_count span {
    font-size: 12px;
    margin-top: -2px;
  }

  .new_payment_grid.grid_1 .new_payment_item_wrap.item_1 .new_payment_item_credits_count span,
  .new_payment_item_wrap.item_6 .new_payment_item_credits_count span {
    font-size: 14px;
  }

  .new_payment_item_save_wrap {
    font-size: 11px;
  }

  .new_payment_grid.grid_1 .new_payment_item_wrap.item_1 .new_payment_item_save_wrap {
    font-size: 11px;
  }

  .new_payment_item_wrap.item_5 .new_payment_item_bottom {
    height: 51px;
  }

  .new_payment_get_credit_btn {
    width: 220px;
    height: 40px;
    margin-left: auto;
    margin-right: auto;
  }

  .payment_modal_wrap.frame_open ~ .payment_modal_close.back {
    right: 15px;
    top: 10px;
  }
}

@media screen and (max-width: 599px) {
  .payment_modal_fixed {
    //overflow: visible;
  }
  .payment_modal_wrap.frame_open {
    width: calc(100% - 20px);
    max-width: unset;
  }
  .payment_modal_wrap {
    width: calc(100% - 40px);
    max-width: 335px;
    padding-top: 10px;
    //padding-bottom: 0;
  }

  .new_payment_left {
    //max-height: calc(100vh - 130px);
    //max-height: calc(calc(var(--vh, 1vh) * 100) - 178px);
    //min-height: 385px;
    //overflow-y: auto;
    padding: 5px 5px 10px 20px;
    margin: 0 -5px 0 -20px;
  }

  .new_payment_body {
    padding-bottom: 20px;
  }

  .new_payment_body:before {
    background-position: left -10px;
  }

  .new_payment_balance {
    font-size: 12px;
    margin-bottom: 10px;
  }

  .payment_modal_wrap.frame_open .new_payment_balance {
    margin-top: 10px;
  }

  .new_payment_balance span {
    font-size: 20px;
  }

  .new_payment_balance img {
    width: 19px;
    margin: 0 9px;
  }

  .new_payment_balance_icon {
    width: 19px;
    height: 19px;
    margin: 0 9px;
  }

  .new_payment_title {
    font-size: 11px;

    margin: 0px auto 8px;
  }

  .new_payment_row.frame_open .new_payment_title br {
    display: none;
  }

  .new_payment_row.frame_open .new_payment_method_chosen_card {
    display: block;
    text-align: center;
  }

  .new_payment_row.frame_open .new_payment_method_chosen_card span {
    display: inline-block;
  }

  .new_payment_row.frame_open .new_payment_method_chosen_card {
    margin-top: 3px;
    margin-left: 0;
  }

  .new_payment_method_chosen_card.active {
    width: 100%;
  }
  .new_payment_method_chosen_card.active p,
  .new_payment_method_chosen_card.active span {
    display: block;
    text-align: center;
    font-size: 18px;
    width: 100%;
  }

  .new_payment_row.frame_open {
    flex-direction: column-reverse;
    margin-top: 10px;
    margin-bottom: auto;
  }

  .new_payment_row.frame_open .new_payment_left {
    width: calc(100% + 25px);
    max-height: calc(100vh - 180px);
    max-height: calc(calc(var(--vh, 1vh) * 100) - 180px);
    padding-top: 5px;
    margin-top: -5px;
  }

  .new_payment_row.frame_open .new_payment_body {
    border-radius: 0 0 10px 10px;
    box-shadow: 0px -3px 6px rgba(47, 43, 99, .1);
    min-height: 300px;
  }

  .new_payment_row.frame_open .new_payment_right {
    width: 100%;
    margin-left: 0;
    padding-left: 0;
    min-height: unset;
    height: unset;
  }

  .new_payment_row.frame_open .new_payment_methods_list {
    display: flex;
    border-radius: 6px 6px 0 0;
    overflow-y: auto;
    align-items: flex-end;
    background-color: transparent;
  }

  .new_payment_row.frame_open .new_payment_method_item {
    background-color: #EBEDFA;
    min-height: 53px;
    flex-direction: column;
    padding: 10px;
    border-radius: 0;
  }

  .new_payment_row.frame_open .new_payment_method_item.active {
    border-radius: 6px 6px 0 0;
    background-color: #fff;
  }

  .new_payment_row.frame_open .new_payment_method_services_row img {
    //width: auto;
    //height: 15px;
  }

  .new_payment_row.frame_open .new_payment_method_item:not(:last-child):after {
    content: '';
    display: block;
    width: 1px;
    height: calc(100% - 10px);
    background-color: #fff;
    position: absolute;
    top: 50%;
    left: unset;
    right: 0;
    transform: translateY(-50%);
  }

  .new_payment_right_title.pc {
    display: none;
  }

  .new_payment_right_title.mob {
    display: block;
  }

  .new_payment_left_title {
    display: none;
  }

  .new_payment_right {
    width: 117px;
    //max-height: calc(100vh - 145px);
    //max-height: calc(calc(var(--vh, 1vh) * 100) - 178px);
    //min-height: 380px;
    //overflow-y: auto;
  }

  .new_payment_method_item {
    padding-bottom: 17px;
  }

  //{
  //  display: grid;
  //  grid-template-columns: repeat(2, 1fr);
  //  grid-gap: 10px;
  //  margin-top: 20px;
  //  padding: 5px;
  //}
  //
  //{
  //  width: 100%;
  //  height: 25px;
  //  object-fit: contain;
  //  margin: auto;
  //}

  .new_payment_grid.grid_2 {
    grid-template-columns: 1fr;
    grid-template-areas: "i6" "i5" "i4" "i3" "i2" "i1";
  }

  .new_payment_grid.grid_3 {
    grid-template-columns: 1fr;
    grid-template-areas: "i6" "i5" "i4" "i3" "i2" "i1";
  }

  .new_payment_grid.grid_5 {
    grid-template-columns: 1fr;
    grid-template-areas: "i6" "i5" "i4" "i3" "i2" "i1";
  }

  .new_payment_grid.grid_1 {
    grid-template-columns: 1fr;
    grid-template-areas: "i1" "i2" "i3" "i4" "i5" "i6";

    &.user_vip {
      grid-template-areas: "i1" "i2" "i3" "i4" "i5" "i6";
    }
  }

  .new_payment_item_wrap {
    height: 134px;
    padding: 7px;
  }

  .new_payment_grid.grid_4 .new_payment_item_wrap.item_1 {
    height: 300px;
    padding: 10px;
  }

  .new_payment_item_wrap.item_1 .new_payment_item_bg,
  .new_payment_item_wrap.item_2 .new_payment_item_bg,
  .new_payment_item_wrap.item_6 .new_payment_item_bg {
    background-image: url(../../img/payments/p1_3_bg.svg);
  }

  .new_payment_grid:not(.grid_2) .new_payment_item_wrap:not(.item_1):before,
  .new_payment_grid.grid_2 .new_payment_item_wrap:not(.item_6):before {
    width: 35px;
    height: 35px;
    left: -4px;
    top: -4px;
    background-size: contain;
  }
  .new_payment_grid:not(.grid_2) .new_payment_item_wrap:not(.item_1):after,
  .new_payment_grid.grid_2 .new_payment_item_wrap:not(.item_6):after {
    width: 35px;
    height: 35px;
    right: -4px;
    bottom: -4px;
    background-size: contain;
  }

  .new_payment_item {
    padding: 7px 10px;
  }

  .new_payment_item_wrap.item_1 .new_payment_item_ribbon {
    width: 51px;
    height: 51px;
    top: -7px;
    left: -9px;
  }
  .new_payment_item_wrap.item_2 .new_payment_item_ribbon {
    width: 52px;
    height: 52px;
    top: -4px;
    left: -6px;
  }
  .new_payment_item_wrap.item_6 .new_payment_item_ribbon {
    width: 54px;
    height: 54px;
    top: -9px;
    left: -9px;
  }

  .new_payment_item_wrap.active .new_payment_item:after {
    width: 28px;
    height: 28px;
  }

  .new_payment_grid.grid_4 .new_payment_item_wrap.active .new_payment_item:after {
    width: 28px;
    height: 28px;
    right: -5px;
    top: -5px;
  }

  .new_payment_item_wrap.item_1 .new_payment_item {
    background-size: cover;
    background-position: left -5px top 0;
  }
  .new_payment_grid.grid_2 .new_payment_item_wrap.item_1 .new_payment_item {
    background-size: cover;
    background-position: left top;
  }
  .new_payment_grid.grid_4 .new_payment_item_wrap.item_1 .new_payment_item {
    background-size: 100%;
    background-position: center;
  }
  .new_payment_item_wrap.item_2 .new_payment_item {
    background-size: cover;
    background-position: left -20px top 0px;
  }
  .new_payment_item_wrap.item_3 .new_payment_item {
    background-size: 90%;
    background-position: left -15px top 0px;
    background-color: #FCFDEF;
  }
  .new_payment_item_wrap.item_4 .new_payment_item {
    background-size: 120%;
    background-position: left -80px top -5px;
  }
  .new_payment_item_wrap.item_5 .new_payment_item {
    background-size: 40%;
    background-position: left top;
  }
  .new_payment_item_wrap.item_6 .new_payment_item {
    background-size: auto 110%;
    background-position: right 60px top 0px;
    padding-right: 9px;
  }

  .new_payment_grid.grid_1 .new_payment_item_wrap.item_1 .new_payment_item_save_wrap {
    display: block;
  }

  .new_payment_grid.grid_4 .new_payment_item_wrap.item_1 .new_payment_item_save_wrap {
    font-size: 14px;
  }

  .new_payment_item_wrap.item_5 .new_payment_item_bottom {
    height: 51px;
  }

  .new_payment_item_wrap.item_1 .new_payment_item_credits_count span {
    font-size: 10px;
  }

  .new_payment_grid.grid_4 .new_payment_item_wrap.item_1 .new_payment_item_credits_count {
    font-size: 40px;
  }

  .new_payment_grid.grid_4 .new_payment_item_wrap.item_1 .new_payment_item_credits_count span {
    font-size: 10px;
  }

  .new_payment_grid.grid_1 .new_payment_item_wrap.item_1 .new_payment_item_credits_count,
  .new_payment_item_wrap.item_6 .new_payment_item_credits_count {
    font-size: 22px;
  }

  .new_payment_item_credits_count {
    font-size: 22px;
  }

  .new_payment_item_credits_count span {
    font-size: 10px;
  }

  .new_payment_grid.grid_1 .new_payment_item_wrap.item_1 .new_payment_item_credits_count span,
  .new_payment_item_wrap.item_6 .new_payment_item_credits_count span {
    font-size: 10px;
  }

  .new_payment_item_save_wrap {
    font-size: 11px;
  }

  .new_payment_grid.grid_1 .new_payment_item_wrap.item_1 .new_payment_item_save_wrap {
    font-size: 11px;
  }

  .new_payment_get_credit_btn_wrap {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .new_payment_get_credit_btn_wrap > div + div {
    margin-left: 10px;
    width: calc(50% - 5px);
  }

  .new_payment_get_credit_btn {
    height: 43px;
  }

  .new_payment_get_credit_btn_wrap_extra {
    display: none;
  }

  .payment_modal_close {
    right: 5px;
    top: 5px;
    background-size: 20px;
  }

  .payment_modal_close.back > * {
    display: block;
  }

  .payment_modal_close.back {
    position: static;
    display: flex;
    align-items: center;
    background-image: none;
    width: auto;
    height: auto;
    font-weight: 600;
    font-size: 12px;
    color: $accentLeftRightColor;
    margin-bottom: auto;
  }

  .payment_modal_close.back img {
    margin-right: 13px;
  }

  .new_payment_get_credit_mob_btn {
    //display: flex;
  }

  .new_payment_get_credit_mob_btn_extra {
    display: flex;
  }

  .new_payment_buying_package_info {
    display: flex;
  }

  .new_payment_method_services_list,
  .new_payment_method_services_row {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 5px;
    margin-top: 5px;
    padding: 5px;
    margin-left: 0;
  }

  .new_payment_method_services_list img,
  .new_payment_method_services_row img {
    margin: auto;
    display: block;
    width: 100%;
    height: 20px;
    object-fit: contain;
  }

  .new_payment_method_services_list img:nth-child(2) {
    margin: auto;
    width: 100%;
  }

  .new_payment_method_services_list img + img {
    margin: auto;
  }

  .new_payment_row.frame_open .new_payment_method_services_row,
  .new_payment_row.frame_open .new_payment_method_services_list {
    margin-top: 9px;
    display: none;
    width: 80px;
    padding: 0;
  }

  .new_payment_row.frame_open .new_payment_method_item.active .new_payment_method_services_row,
  .new_payment_row.frame_open .new_payment_method_item.active .new_payment_method_services_list {
    display: grid;
  }

  .new_payment_get_credit_btn {
    width: 100%;
    max-width: 320px;
  }

  .new_payment_get_credit_btns {
    display: block;
  }
}

@media screen and (max-width: 340px) {
  .new_payment_grid.grid_3 .new_payment_item_wrap.item_1 .new_payment_item {
    background-size: auto 110%;
  }
}

/* mob apple pay */

.mob_apple_pay_btn {
  width: calc(50% - 5px);
  margin-top: 0;

  @media screen and (min-width: 600px) {
    display: none;
  }
}

.mob_apple_pay_btn > * {
  width: 100%;
  max-width: 320px;
  margin: 0 auto;
}

.legal_entity_info {
  position: fixed;
  bottom: 8px;
  padding: 0 14px;
  font-size: 10px;
  color: rgba(51, 51, 51, 0.7);
  z-index: 999;

  @media screen and (max-width: 1700px) {
    font-size: 9px;
  }

  @media screen and (max-width: 600px) {
    font-size: 8px;
    padding: 0 10px;
  }

  @media screen and (max-width: 1530px) {
    //max-width: 230px;
  }
}

// First credit payment
//Added class .payment_first_credit_wrap for this element .payment_modal_wrap
//.payment_first_credit_wrap {
//  width: 90%;
//  max-width: 1471px;
//
//  @media screen and (max-width: 1139px){
//    width: 600px;
//  }
//
//  @media screen and (max-width: 599px){
//    width: calc(100% - 40px);
//  }
//}

.payment_first_credit {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 20px;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @media screen and (max-width: 1139px) {
    flex-direction: column;
  }
}

.payment_first_credit_image {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 400px;
  width: 50%;

  @media screen and (max-width: 1139px) {
    width: 100%;
    height: 250px;
  }

  @media screen and (max-width: 599px) {
    height: 120px;
  }
}

.payment_first_credit_image img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.payment_first_credit_info {
  width: 50%;
  max-width: 391px;
  display: flex;
  flex-direction: column;
  gap: 40px;
  justify-content: center;
  height: 100%;
  min-height: 500px;

  @media screen and (max-width: 1139px) {
    width: 100%;
    min-height: fit-content;
    max-width: fit-content;
    gap: 24px;
  }

  @media screen and (max-width: 599px) {
    gap: 16px;
  }
}

.payment_first_credit_banner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;

  @media screen and (max-width: 599px) {
    gap: 8px;
  }
}

.payment_first_credit_banner_title {
  color: $paymentBannerTitle;
  text-align: center;
  font-size: 24px;
  font-weight: 600;

  @media screen and (max-width: 599px) {
    font-size: 14px;
  }
}

.payment_first_credit_banner_offer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  color: $paymentBannerOffer;
  font-size: 36px;
  font-weight: 600;

  @media screen and (max-width: 599px) {
    font-size: 14px;
  }
}

.payment_first_credit_banner_offer span {
  display: block;
  font-size: 48px;
  font-weight: 700;

  @media screen and (max-width: 599px) {
    font-size: 24px;
  }
}

.payment_first_credit_pros {
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media screen and (max-width: 599px) {
    gap: 16px;
  }
}

.payment_first_credit_pros_title {
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  color: $paymentCorsTitle;

  @media screen and (max-width: 599px) {
    font-size: 14px;
  }
}

.payment_first_credit_pros_list {
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;

  @media screen and (max-width: 599px) {
    gap: 12px;
  }
}

.payment_first_credit_pros_item {
  display: flex;
  align-items: center;
  height: fit-content;
  gap: 12px;
  position: relative;
}

.first_credit_icon {
  width: 24px;
  height: 24px;
  margin: 12px;

  path {
    fill: $paymentProsIconColor;
  }

  @media screen and (max-width: 599px) {
    width: 18px;
    height: 18px;
  }
}

.payment_first_credit_pros_item:before {
  content: '';
  display: block;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background: $paymentProsIconBackground;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);

  @media screen and (max-width: 599px) {
    width: 40px;
    height: 40px;
  }
}

.payment_first_credit_pros_item_title {
  color: $paymentCorsItemTitle;
  font-size: 18px;
  font-weight: 400;
  width: 85%;
  line-height: 150%;

  @media screen and (max-width: 599px) {
    font-size: 12px;
  }
}

.payment_first_credit_btns {
  background: transparent;
}

.payment_first_credit_btn {
  background: transparent;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 68px;
  color: $paymentButtonColor;
  border-radius: 8px;
  margin: 20px auto 0;
  padding: 20px;
  cursor: pointer;
  transition-duration: .3s;
  position: relative;

  font-weight: 700;
  font-size: 24px;
  letter-spacing: 0.01em;

  @media screen and (max-width: 599px) {
    padding: 12px 24px;
    font-size: 14px;
    width: 100%;
    gap: 20px;
  }
}

.payment_first_credit_btn span {
  display: block;
}


.payment_first_credit_btn:hover .payment_first_credit_btn_bg {
  background: $paymentButtonBackgroundHover;
  animation: unset;
  transform: scale(1);
  box-shadow: 0 0 0 10px $paymentButtonOutline;
}

.payment_first_credit_btn .payment_first_credit_btn_price_wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.payment_first_credit_btn_old_price {
  text-decoration: line-through;
  opacity: 0.7;
  font-size: 20px;
  font-weight: 600;
  display: block;

  @media screen and (max-width: 599px) {
    font-size: 14px;
  }
}

.payment_first_credit_btn_price {
  @media screen and (max-width: 599px) {
    font-size: 16px;
  }
}


.payment_first_credit_btn_bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  animation-name: button-zoom;
  animation-duration: 1s;
  animation-delay: unset;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-direction: alternate-reverse;
  background: $paymentButtonBackground;
  border-radius: 5px;
  z-index: -1;
  transition: all 1s ease-in-out;
}

//START STYLES FOR NEW VIEW BUY FIRST BONUS PACKAGE =============================================
.new_payment_grid.grid_4.new_view .new_payment_item_wrap.item_1 {
  & .new_payment_item {
    background-image: url(../../img/payments/first_package_image.png);
    background-size: cover;
    background-position: center;
    background-color: #fff;

    @media screen and (max-width: 599px) {
      background-image: url(../../img/payments/first_package_image_mob.png);
    }
  }

  & .new_payment_item_bg {
    background-image: url(../../img/payments/first_package_bg.png);
  }

  & .new_payment_item_content {
    width: max-content;
    padding: 10px;

    @media screen and (max-width: 599px) {
      justify-content: initial;
      width: 100%;
      padding: 0;
    }
  }

  & .new_payment_item_credits_count {
    font-size: 40px;
    color: $accentLeftColor;

    @media screen and (max-width: 599px) {
      font-size: 38px;
      width: max-content;
      align-self: end;
      margin-bottom: 16px;
    }
  }

  & .new_payment_item_bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 599px) {
      flex-direction: row;
    }
  }

  & .new_payment_item_price {
    background: #FC5656;
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    padding: 6px 12px;
    border-radius: 48px;
    max-width: max-content;
    letter-spacing: 0.02em;

    @media screen and (max-width: 599px) {
      font-size: 16px;
      margin-right: 10px;
    }
  }

  & .new_payment_item_save_wrap {
    text-align: center;
  }

  & .new_payment_item_save_count {
    color: #333;
    font-weight: 700;
    font-size: 24px;

    @media screen and (max-width: 599px) {
      font-size: 22px;
    }
  }

  & .new_payment_item_save_percent {
    color: #464851;
    font-size: 18px;
    font-weight: 400;
    text-decoration-line: line-through;

    @media screen and (max-width: 599px) {
      font-size: 16px;
    }
  }

  & .new_payment_item_ribbon {
    @media screen and (max-width: 599px) {
      top: -30px;
      left: -20px;
      width: 80px;
      height: 80px;
    }
  }
}

//END STYLES FOR NEW VIEW BUY FIRST BONUS PACKAGE =============================================

@keyframes button-zoom {
  from {
    transform: scale(1);
    background: $paymentButtonBackgroundHover;
    box-shadow: 0 0 0 10px $paymentButtonOutline;
  }
  to {
    transform: scale(0.97);
    box-shadow: unset;
    background: $paymentButtonBackground;
  }
}

.new_payment_item_wrap.diamonds.item_1 .new_payment_item,
.new_payment_item_wrap.diamonds.item_4 .new_payment_item,
.new_payment_item_wrap.diamonds.item_6 .new_payment_item {
  background-image: url(../../img/payments/bg_1_payment_diamond.svg);
  background-size: cover !important;
  background-position: center !important;
  &:before{
    background: transparent;
  }
}

.new_payment_item_wrap.diamonds.item_2 .new_payment_item,
.new_payment_item_wrap.diamonds.item_3 .new_payment_item,
.new_payment_item_wrap.diamonds.item_5 .new_payment_item {
  background-image: url(../../img/payments/bg_2_payment_diamond.svg);
  background-size: cover !important;
  background-position: center !important;

  &:before{
    background: transparent;
  }
}

.new_payment_item_wrap.diamonds .new_payment_item:before {
  content: '';
  position: absolute;
  height: 100%;
  width: 50%;
  max-width: 195px;
  top: 0;
  left: 0;
}

.new_payment_item_wrap.diamonds.item_1 .new_payment_item:before {
  background: url(../../img/payments/diamond_p_1.svg) no-repeat center 71%;
  background-size: contain;
}

.new_payment_item_wrap.diamonds.item_2 .new_payment_item:before {
  background: url(../../img/payments/diamond_p_2.svg) no-repeat center 71%;
  background-size: contain;
}

.new_payment_item_wrap.diamonds.item_3 .new_payment_item:before {
  background: url(../../img/payments/diamond_p_3.svg) no-repeat center 71%;
  background-size: contain;
}

.new_payment_item_wrap.diamonds.item_4 .new_payment_item:before {
  background: url(../../img/payments/diamond_p_4.svg) no-repeat center 71%;
  background-size: contain;
}

.new_payment_item_wrap.diamonds.item_5 .new_payment_item:before {
  background: url(../../img/payments/diamond_p_5.svg) no-repeat center 71%;
  background-size: contain;
}

.new_payment_item_wrap.diamonds.item_6 .new_payment_item:before {
  background: url(../../img/payments/diamond_p_6.svg) no-repeat center 71%;
  background-size: contain;
}


.new_payment_grid.grid_4 .new_payment_item_wrap.diamonds.item_1 .new_payment_item {
  background-image: url(../../img/payments/bg_first_credit.svg);
  background-size: cover !important;
  background-position: center !important;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    display: block;
    height: 70%;
    width: 70%;
    max-width: none;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    background: url(../../img/payments/diamond_p_5.svg) center no-repeat;
    background-size: contain;
  }
}

.new_payment_grid.grid_4 .new_payment_item_wrap.diamonds.item_1{
  @media screen and (max-width: 599px) {
    height: 200px;

    .new_payment_item:before{
      transform: unset;
      top: 37%;
    }
  }
}

.new_payment_grid.grid_4 .new_payment_item_wrap.diamonds.item_1 .new_payment_item_content {
  width: 102px;
  padding: 30px 0;

  @media screen and (max-width: 599px) {
    width: max-content;
    padding: 0;
  }

  .new_payment_item_credits_count {
    text-align: left;

    span {
      text-align: left;
    }
  }
}

.payment_modal_diamond .new_payment_body{
  @media screen and (max-width: 599px) {
   min-height: 400px;
  }
}

.payment_modal_diamond .new_payment_right{
  @media screen and (max-width: 599px) {
    margin-top: 12px;
  }
}
