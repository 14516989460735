@import "@sitestyles";

.popup_support_wrap {
  max-width: 476px;
  width: 476px;
  border-radius: 8px;
  background-color: $whiteColor;
  padding: 32px 40px;
  position: relative;

  @media screen and (max-width: 599px) {
    max-width: calc(100% - 20px);
    margin: 0 auto;
  }

  @media screen and (max-width: 536px) {
    padding: 24px 16px;
  }
}

.popup_support_head {
  font-weight: 500;
  font-size: 20px;
  line-height: 140%;
  text-align: center;
  color: transparent;
  clip-path: inset(1px);
  outline: 0;
  background: $buttonBackgroundColor;
  -webkit-background-clip: text;
  -webkit-box-decoration-break: clone;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  border: 0;
  margin-bottom: 8px;
  letter-spacing: -0.2px;

  @media screen and (max-width: 600px) {
    font-size: 16px;
    margin-bottom: 12px;
  }
}

//.popup_support_body{
//	padding: 20px 22px;
//}

.popup_support_title_id_wrap {
  display: flex;
  align-items: center;
  margin-bottom: 19px;
}

.popup_support_title_id {
  color: $accentLeftColor;
  margin-left: 15px;
}


.popup_support_title {
  /*margin-bottom: 10px;
*/
  font-weight: bold;
  font-size: 14px;
  color: $accentLeftColor;
}

.popup_support_desc {
  color: $accentLeftColor;
}


.popup_support_input_text {
  display: block;
  width: 100%;
  border: 1px solid transparent;
  border-radius: 5px;
  margin-bottom: 12px;
  padding: 13px 16px;
  outline: none;
  background: #F8F8F8;
  height: 48px;
  font-family: $mainFont;
  font-size: 16px;
  line-height: 22px;
  font-weight: 600;
  color: $InputSelectedTextColor;

  @media screen and (max-width: 600px) {
    font-size: 14px;
    line-height: 20px;
  }

  &::placeholder {
    font-family: $mainFont;
    font-size: 16px;
    line-height: 22px;
    color: #9F9F9F;
    font-weight: 400;

    @media screen and (max-width: 600px) {
      font-size: 14px;
      line-height: 20px;
    }
  }

  &:focus {
    color: #4F4F4F;
    border: 1px solid #9F9F9F;
  }
}

.popup_support_email_input {
  display: block;
  width: 100%;
  height: 48px;
  resize: none;
  outline: none;
  border: 1px solid transparent;
  border-radius: 5px;
  margin-bottom: 12px;
  padding: 13px 16px;
  box-sizing: border-box;
  background: #F8F8F8;
  font-family: $mainFont;
  font-size: 16px;
  line-height: 22px;
  font-weight: 600;
  color: $InputSelectedTextColor;
  @media screen and (max-width: 600px) {
    font-size: 14px;
    line-height: 20px;
  }

  &::placeholder {
    font-family: $mainFont;
    font-size: 16px;
    line-height: 22px;
    color: #9F9F9F;
    font-weight: 400;
    @media screen and (max-width: 600px) {
      font-size: 14px;
      line-height: 20px;
    }
  }

  &:focus {
    color: #4F4F4F;
    border: 1px solid #9F9F9F;
  }
}

.popup_support_email_input.invalid {
  background-color: $springSunsetFlowerColor;
}

.popup_support_textarea {
  display: block;
  width: 100%;
  resize: none;
  outline: none;
  border: 1px solid transparent;
  border-radius: 5px;
  margin-bottom: 16px;
  padding: 13px 16px;
  background: #F8F8F8;
  height: 200px;
  font-family: $mainFont;
  font-size: 16px;
  line-height: 22px;
  font-weight: 600;
  color: $InputSelectedTextColor;

  @media screen and (max-width: 600px) {
    font-size: 14px;
    line-height: 20px;
  }

  &::placeholder {
    font-family: $mainFont;
    font-size: 16px;
    line-height: 22px;
    color: #9F9F9F;
    font-weight: 400;
    @media screen and (max-width: 600px) {
      font-size: 14px;
      line-height: 20px;
    }
  }

  &:focus {
    color: #4F4F4F;
    border: 1px solid #9F9F9F;
  }
}

.popup_support_textarea.invalid {
  background-color: #FADDDD;
}

.popup_support_input {
  display: none;
}

.popup_support_label {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  font-size: 16px;
  line-height: 22px;
  border: 1px solid #9F9F9F;
  color: #4F4F4F;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;

  @media screen and (max-width: 600px) {
    font-size: 14px;
    line-height: 20px;
  }

  &:before {
    display: block;
    content: '';
    width: 24px;
    height: 24px;
    margin-right: 8px;
    background: #4F4F4F;
    mask: url('../../../../../img/support-file-ico.svg') center no-repeat;
    mask-size: contain;
    -webkit-mask: url('../../../../../img/support-file-ico.svg') center no-repeat;
    -webkit-mask-size: contain;

  }

  &:hover {
    background: $attachTouchText;
    color: #FFFFFF;
    border: 1px solid #FFFFFF;

    &:before {
      background: #FFFFFF;
    }
  }
}

.popup_support_label img {
  vertical-align: middle;
  margin-right: 9px;
}

.popup_support_uploaded {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  margin-top: 14px;
  height: 19px;
}


.popup_support_desc {
  color: #9F9F9F;
  text-align: center;
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 16px;

  @media screen and (max-width: 600px) {
    font-size: 14px;
    line-height: 19px;
  }
}

.popup_support_textarea {
  display: block;
  width: 100%;
  resize: none;
  outline: none;
  border: 1px solid transparent;
  border-radius: 5px;
  margin-bottom: 16px;
  padding: 13px 16px;
  background: #F8F8F8;
  height: 200px;
  font-family: $mainFont;
  font-size: 16px;
  line-height: 22px;
  font-weight: 600;
  color: $InputSelectedTextColor;

  @media screen and (max-width: 600px) {
    font-size: 14px;
    line-height: 20px;
  }

  &::placeholder {
    font-family: $mainFont;
    font-size: 16px;
    line-height: 22px;
    color: #9F9F9F;
    font-weight: 400;
    @media screen and (max-width: 600px) {
      font-size: 14px;
      line-height: 20px;
    }
  }

  &:focus {
    color: #4F4F4F;
    border: 1px solid #9F9F9F;
  }
}

.popup_support_input {
  display: none;
}

.popup_support_uploaded_del {
  cursor: pointer;
}

.popup_support_label {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  font-size: 16px;
  line-height: 22px;
  border: 1px solid #9F9F9F;
  color: #4F4F4F;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;

  @media screen and (max-width: 600px) {
    font-size: 14px;
    line-height: 20px;
  }

}

.popup_support_label img {
  vertical-align: middle;
  margin-right: 9px;
}

.popup_support_uploaded {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  margin-top: 14px;
  height: 19px;
}

.popup_support_uploaded_text {
  color: #4F4F4F;
  font-weight: bold;
  margin-right: 8px;
}

.popup_complaint_close {
  position: absolute;
  right: 16px;
  top: 16px;
  //padding: 5px;
  cursor: pointer;
  background: #c2c2c2;
  width: 16px;
  height: 16px;
  -webkit-mask: url('../../../../../img/cross_white.svg') center no-repeat;
  mask: url('../../../../../img/cross_white.svg') center no-repeat;
  mask-size: contain;
  -webkit-mask-size: contain;

  @media screen and (max-width: 599px) {
    right: 13px;
    top: 13px;
  }

  &:hover {
    background: $attachTouchText;
  }
}

.popup_complaint_close img {
  width: 16px;
  height: 16px;
}

.popup_complaint_close svg {
  width: 16px;
  height: 16px;
}


.popup_support_uploaded_name {
  color: $InputSelectedTextColor;
  font-weight: 600;
  margin-right: 8px;
}

.popup_support_uploaded_size {
  font-size: 12px;
  color: $defaultTextColorAlpha6;
  margin-right: 15px;
}

.popup_support_uploaded_text {
  color: #4F4F4F;
  font-weight: bold;
  margin-right: 8px;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  &:before {
    content: '';
    display: block;
    background: url('../../../../../img/file_uploaded.svg') center no-repeat;
    background-size: contain;
    margin-right: 12px;
    width: 16px;
    height: 16px;
  }
}

.popup_support_uploaded_del {
  background: url('../../../../../img/file_uploaded_delete.svg') center no-repeat;
  background-size: contain;
  width: 16px;
  height: 16px;
  min-width: 16px;
  cursor: pointer;
  margin-left: auto;
}

.popup_support_uploaded_name {
  color: $attachTouchText;
  font-weight: 600;
  margin-right: 8px;
}

.popup_support_uploaded_size {
  font-size: 14px;
  line-height: 19px;
  color: $defaultTextColorAlpha6;
  margin-right: 15px;
  font-weight: 600;
}

.popup_support_send_info_hr {
  height: 1px;
  width: 200px;
  background-color: $defaultTextColorAlpha1;
  margin: 22px auto 11px;
}

.popup_support_send_info_text {
  font-size: 12px;
  line-height: 1.5;
  text-align: center;
  color: $defaultTextColorAlpha6;
}

.popup_support_send_info_text a {
  color: $accentRightColor;
}

.popup_support_send_btn {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 48px;
  margin: 16px auto 0;
  background-clip: padding-box, border-box;
  background-origin: border-box;
  font-family: $mainFont;
  border: double 1px transparent;
  border-radius: 5px;
  color: $whiteColor;
  text-align: center;
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  background-image: $buttonBackgroundColor, linear-gradient(to right, $accentLeftColor, $accentRightColor);

  @media screen and (max-width: 600px) {
    font-size: 16px;
    line-height: 22px;
  }

  &:hover {
    background-image: linear-gradient(white, white), linear-gradient(to right, $accentLeftColor, $accentRightColor);


    .popup_support_send_btn_text {
      background: $buttonBackgroundColor;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }
  }
}

.popup_support_send_btn_text {
  background: #FFFFFF;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  clip-path: inset(1px);
}