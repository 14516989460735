@import "@sitestyles";

.top_up_modal_wrap{
  width: 100%;
  max-width: 738px;
  padding: 48px 78px;
  background-color: #fff;
  border-radius: 10px;
  text-align: center;
  position: relative;

  @media screen and (max-width: 1099px){
    padding: 36px 50px;
  }

  @media screen and (max-width: 599px){
    padding: 36px 20px 30px;
  }

  @media screen and (max-width: 399px){
    padding-left: 10px;
    padding-right: 10px;
  }
}

.modal_title{
  font-weight: bold;
  font-size: 24px;
  color: $accentLeftRightColor;

  & + .modal_text{
    margin-top: 32px;
    padding: 0;

    @media screen and (max-width: 1099px){
      margin-top: 20px;
    }
  }

  @media screen and (max-width: 1099px){
    font-size: 20px;
  }

  @media screen and (max-width: 599px){
    font-size: 18px;
  }
}

.modal_text {
  padding: 0 30px;
  font-weight: 600;
  font-size: 18px;
  line-height: 170%;
  color: #446077;

  p {
    & + p{
      margin-top: 32px;

      @media screen and (max-width: 1099px){
        margin-top: 20px;
      }
    }

    @media screen and (max-width: 1099px){
      font-size: 16px;
    }

    @media screen and (max-width: 599px){
      font-size: 14px;
    }
  }

  @media screen and (max-width: 599px){
    padding: 0;
  }
}

.modal_buttons{
  margin-top: 48px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 1099px){
    margin-top: 30px;
  }
}

.modal_btn{
  width: 256px;
  height: 63px;
  display: flex;
  align-items: center;
  justify-content: center;

  font-weight: 600;
  font-size: 24px;
  letter-spacing: 0.01em;
  border-radius: 10px;
  cursor: pointer;

  @media screen and (max-width: 1099px){
    width: 200px;
    height: 52px;
    font-size: 20px;
  }

  @media screen and (max-width: 599px){
    font-size: 16px;
    height: 46px;
  }

  &.continue {
    width: max-content;
    padding: 0 15px;
  }
}

.color_btn{
  width: 316px;
  background: $buttonBackgroundColor;
  color: #fff;

  @media screen and (max-width: 1099px){
    width: 200px;
  }
}

.grey_btn{
  border: 2px solid #A5A5A5;
  color: #A5A5A5;

  & + .color_btn{
    width: 256px;
    margin-left: 15px;

    @media screen and (max-width: 1099px){
      width: 200px;
    }
  }
}

.modal_choose_form{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 16px;
  grid-row-gap: 35px;
  flex-wrap: wrap;
  margin-top: 48px;
  //width: 439px;
  width: 525px;
  max-width: 100%;

  @media screen and (max-width: 1099px){
    margin-top: 30px;
    grid-row-gap: 28px;
  }

  @media screen and (max-width: 599px){
    grid-template-columns: 1fr;
    grid-column-gap: 8px;
    grid-row-gap: 18px;
  }

}

.modal_choose_item{
  input{
    display: none;
    
    &:checked + label:before{
      background-image: url(../../../img/payments/bold_check.svg);
    }
  }

  label{
    font-weight: bold;
    font-size: 18px;
    color: #446077;
    display: flex;
    align-items: center;
    flex: none;
    cursor: pointer;

    @media screen and (max-width: 1099px){
      font-size: 16px;
    }

    @media screen and (max-width: 599px){
      font-size: 12px;
    }

    &:before{
      content: '';
      display: block;
      width: 18px;
      height: 18px;
      margin-right: 16px;
      margin-bottom: 2px;
      border: 2px solid #446077;
      border-radius: 5px;
      position: relative;
      flex: none;
      box-sizing: border-box;

      background-repeat: no-repeat;
      background-position: center;

      @media screen and (max-width: 599px){
        margin-right: 8px;
      }
    }
  }

  &.default{
    label {
      cursor: no-drop;
      color: #948989;
      &:before {
        border: 2px solid #948989;
        background-image: url(../../../img/payments/bold_check.svg);
      }
    }
  }
}

.close_modal {
  position: absolute;
  right: 5px;
  top: 5px;
  cursor: pointer;
  padding: 10px;

  svg {
    width: 24px;
    height: 24px;
    fill: #C4C4C4;
    display: block;

    @media screen and (max-width: 599px) {
      width: 16px;
    }
  }

  @media screen and (max-width: 599px) {
    right: 0;
    top: 0;
  }
}