@import "@sitestyles";

.new_payment_method_chosen_card {
  font-weight: 500;
  font-size: 12px;
  color: rgba(51, 51, 51, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 25px;

  .active {
    font-size: 24px;
    justify-content: end;
  }

  div {
    font-weight: 600;
    color: #00317B;
    text-decoration: underline;
    margin-left: 8px;
    cursor: pointer;
  }

  div:hover {
    text-decoration: none;
  }
}

.error_message {
  padding: 10px;
  background: #ff7e71;
  width: 100%;
  min-height: 40px;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  border-radius: 8px;
}

.form_container {
  .form_row {
    @media screen and (max-width: 470px) {
      flex-direction: column;
      label {
        margin-bottom: 10px;
      }
    }
    & {
      display: flex;
      align-items: center;
      margin: 20px;
    }

    .input_card_num {
      width: 100%;
    }

    .input_card_label {
      width: 100%;
    }

    .input_card_exp {
      width: 40px;
    }

    .input_card_cvc {
      width: 70px;
    }

    input {
      font-size: 16px;
      border: 1px solid slategray;
      border-radius: 5px;
      margin-right: 10px;
      margin-left: 10px;
      padding: 5px;
    }

    .form_submit_btn {
      width: 100%;
      height: 54px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: $buttonBackgroundColor;
      border-radius: 4px;
      font-size: 18px;
      line-height: 140%;
      color: #FFFFFF;
      margin-top: 14px;
      cursor: pointer;
    }
  }
}

.form_submit_btn {
  width: 100%;
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $buttonBackgroundColor;
  border-radius: 4px;
  font-size: 18px;
  line-height: 140%;
  color: #FFFFFF;
  margin-top: 14px;
  cursor: pointer;
}







.cardForm_Field50 {
  float: left;
  width: 50%;
}



/* Makes the default card text easier to read */
.jp-card .jp-card-front .jp-card-display, .jp-card .jp-card-back .jp-card-display {
  text-shadow: 0 1px 2px #1D1F20;
}
/* Changes the default styles */
.jp-card .jp-card-front, .jp-card .jp-card-back {
  background: #27ae60!important;
}
.card_container form {
  max-width: 350px;
  text-align: center;
}
.form_container form {
  margin: 10px auto;
}
.form_container input {
  margin: 0 0 10px 0;
  /* padding: 0; */
  font-size: 16px;
  float: left;
  padding: 5px 0;
  box-sizing: border-box;
  webkit-box-shadow: none;
  box-shadow: none;
  /* width: 50%; */
  display: block;
}
input.secondRow {
  float: left;
  display: block;
  width: 100%;
}

.form_container label {
  text-align: left;
  float: left;
  padding: 5px 0 0px 5px;
  width: 100%;
  font-family: arial;
  text-transform: uppercase;
  font-size: 14px;
  /* font-weight: bold; */
}

.form_container input.button {
  width: 100%;
  padding: 11px;
  border: none;
  box-shadow: none;
  border-radius: 0;
  /* background: #CCCCCC; */
}
/*input.button:hover {
  background: #27ae60;
  color: #fff;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
}*/
.form_container input:not([type=submit]):not([type=file]) {
  /* omg so much cleaner */
  border: 1px solid #DDD;
  -webkit-box-shadow:
          inset 0 0 0px  rgba(0,0,0,0.1),
          0 0 0px rgba(0,0,0,0.1);
  -moz-box-shadow:
          inset 0 0 0px  rgba(0,0,0,0.1),
          0 0 0px rgba(0,0,0,0.1);
  box-shadow:
          inset 0 0 0px  rgba(0,0,0,0.1),
          0 0 0px rgba(0,0,0,0.1);
  padding: 10px;
}
.form_container input[name="name"]{
  width: 100%;
}
.form_container input[name="number"]{
  width: 100%;
}
.form_container input[name="cvc"]{
  //border-left: 0px;
}

.CardDefault, .CardDefault:hover {
  background: gray;
  color: #fff;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
}
.CardWarning,.CardWarning:hover {
  background: red;
  color: #fff;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
}
.CardGood, .CardGood:hover {
  background: #27ae60;
  color: #fff;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
}

.form_container input.incorrectInfo {
  border: red solid 1px;
}







