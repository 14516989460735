.ls_banner_wrap {
}

.ls_banner_bg {
  background-image: url(../../img/love-stories-page/love_stories_bg.jpg);
  background-size: cover;
  height: 100vh;
  padding: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.back_to_site_btn {
  appearance: none;
  background-color: transparent;
  border: 2px solid #1A1A1A;
  border-radius: 15px;
  box-sizing: border-box;
  color: #3B3B3B;
  cursor: pointer;
  display: inline-block;
  font-family: Roobert,-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  margin: 0;
  min-height: 60px;
  min-width: 0;
  outline: none;
  padding: 16px 24px;
  text-align: center;
  text-decoration: none;
  transition: all 300ms cubic-bezier(.23, 1, 0.32, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: max-content;
  will-change: transform;

  position: absolute;
  top: 15px;
  left: 15px;

  &:hover {
    color: #fff;
    background-color: #1A1A1A;
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);
  }

  &:active {
    box-shadow: none;
    transform: translateY(0);
  }
}

.ls_banner_title {
  color: #fff;
  font-size: 14.5vw;
  font-weight: 900;
  line-height: 90%;
  font-family: Roboto, sans-serif;
  text-align: center;
  width: 100%;
  max-width: max-content;

  @media screen and (max-width: 771px) {
    font-size: 13.5vw;
  }
}

.ls_banner_subtitle {
  color: #fff;
  font-style: italic;
  font-size: 48px;
  font-weight: initial;
  line-height: 72px;
  margin-top: 15px;
  text-align: center;
}

.ls_inner_container {
  padding-top: 200px;
  padding-left: 200px;
}

.ls_title {
  color: #383B5A;
  font-size: 48px;
  font-weight: 700;
  margin-bottom: 24px;
  display: block;

  &.center {
    padding-right: 300px;
    padding-left: 100px;
    max-width: 1520px;
    margin: 0 auto 64px;
    text-align: center;
  }

  &.name {
    font-size: 36px;
  }
}

.ls_text {
  color: #76798F;
  font-size: 18px;
  line-height: 180%;
  margin-bottom: 15px;
}

.ls_photo_slider_wrap {
}

.ls_photo_slider_row {
  display: flex;

  & .ls_title.center {
    display: none;
  }
}

.ls_photo_slider_block {
    overflow: hidden;
    width: 100%;
    max-width: 1050px;
}

.ls_photo_slider_text_block {
  max-width: 620px;
  margin-right: 50px;
}

.ls_photo_slider_block_item_wrap {
  position: relative;
  width: 520px;
  margin-right: 80px;
}

.ls_photo_slider_block_item {
  max-width: 450px;

  & img {
    width: 100%;
    border-radius: 5px;
  }
}

.ls_photo_slider_arrow_prev,
.ls_photo_slider_arrow_next {
  position: absolute;
  top: 50%;
  cursor: pointer;
  z-index: 9;

  svg {
    width: 13px;
    height: 23px;
    fill: #383a59;
    stroke: #383a59;
  }
}

.ls_photo_slider_arrow_next {
  right: 35px;
}

.ls_photo_slider_arrow_prev {
  left: -45px;
}

.ls_video_slider_wrap {
}

.ls_video_slider_row {
  position: relative;
}

.ls_video_slider_block_content_wrap {
  position: relative;
}

.ls_video_slider_arrow_next,
.ls_video_slider_arrow_prev {
  position: absolute;
  top: 180px;
  z-index: 10;
  cursor: pointer;
  width: 64px;

  &:after {
    content: '';
    width: 64px;
    height: 64px;
    background-color: #FFFFFF;
    border-radius: 50%;
    position: absolute;
    z-index: -1;
  }

 svg {
   width: 32px;
   height: 32px;
   fill: #383a59;
   fill-opacity: 1;
   position: absolute;
   top: 50%;
   left: calc(50% - 32px);
   transform: translate(50%, 50%);
 }
}

.ls_video_slider_arrow_next {
  right: 50px;
}

.ls_video_slider_arrow_prev {
  left: -100px;
}

.ls_video_slider_block_content {
  display: flex;
  margin-right: 150px;
}

.ls_video_slider_block_item {
  position: relative;
  width: 500px;
  display: flex;
  align-items: center;

  & img {
    width: 100%;
    border-radius: 5px;
  }

  & span {
    background-image: url(../../img/love-stories-page/video_slider_play.svg);
    background-size: cover;
    height: 64px;
    width: 64px;
    position: absolute;
    top: calc(50% - 32px);
    left: calc(50% - 32px);
    z-index: 9;
    cursor: pointer;
    opacity: 0.8;
  }
}

.ls_video_slider_block_text {
  max-width: 765px;
  width: fit-content;
  margin-left: 30px;
}

.ls_video_slider_dots_wrap {
  margin-top: 35px;
  overflow-x: hidden;
}

.ls_video_slider_dots {
  margin: 0;
  list-style-type: none;
  display: flex;
  justify-content: center;
  margin-right: -24px;
}

.ls_video_slider_paging {
  width: 360px;
  height: 4px;
  background: rgba(56, 59, 90, 0.1);
  margin-right: 24px;
  border-radius: 10px;
  cursor: pointer;

  &.active {
    background: #383B5A;
  }
}

.ls_comment_wrap {
}

.ls_comment_row {
  display: flex;
  align-items: center;
  margin-bottom: 80px;
  padding-right: 200px;

  &.reverse {
    flex-direction: row-reverse;
    margin-bottom: 0;
  }
}

.ls_comment_img_wrap {
  width: 230px;

  & img {
    width: 100%;
  }
}

.ls_comment_content {
  position: relative;
  max-width: 930px;
  margin-left: 60px;
  margin-right: 28px;
}

.ls_comment_quot {
  position: absolute;
  width: 30px;
  height: 18px;

  & svg {
    width: 30px;
    height: 18px;
  }

  &.open {
    top: -30px;
    left: -35px;
  }

  &.close {
    bottom: -30px;
    right: -35px;
  }
}

.ls_comment_content_data {
  color: #9DA0B8;
  font-size: 18px;
  line-height: 180%;
  text-align: right;
  display: block;
}

.ls_profiles_wrap {
}

.ls_profiles_row {
  padding-bottom: 24px;
}

.ls_profiles_col {
  margin-right: 24px;
}

.ls_profiles_img_wrap {
  width: 360px;
  margin-bottom: 16px;

  & img {
    width: 100%;
  }
}

.ls_profiles_name {
  font-weight: 600;
  font-size: 24px;
  color: #383B5A;
  margin-bottom: 10px;
}

.ls_profiles_country {
  font-weight: 500;
  font-size: 18px;
  color: #9DA0B8;
  margin-bottom: 32px;
}

.ls_profiles_btn {
  display: inline-block;
  font-size: 18px;
  font-weight: 700;
  color: #fff;
  background: #383B5A;
  border: 2px solid transparent;
  border-radius: 5px;
  padding: 18px 56px;
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    background: #fff;
    color: #383B5A;
    border: 2px solid #383B5A;
  }
}

.ls_video_player_wrapper {
  height: 100%;
}

.react_player video {
  pointer-events: auto;
  max-height: 95vh;
  max-height: calc(var(--vh, 1vh) * 95);
}

.close_modal {
  position: absolute;
  right: 5px;
  top: 5px;
  cursor: pointer;
  padding: 8px;

  svg {
   width: 28px;
    height: 28px;
    fill: #FFFFFF;
  }
}

@media screen and (max-width: 1599px) {
  .ls_inner_container {
    padding-top: 130px;
    padding-left: 130px;
  }

  .ls_photo_slider_text_block {
    margin-right: 20px;
  }

  .ls_title {
    &.center {
      padding-left: 0;
      padding-right: 130px;
    }
  }

  .ls_video_slider_block_content {
    margin-right: 140px;
  }

  .ls_comment_row {
    padding-right: 130px;
    justify-content: space-around;
  }
}

@media screen and (max-width: 1499px) {
  .ls_inner_container {
    padding-top: 100px;
    padding-left: 50px;
  }

  .ls_title {
    &.center {
      padding-right: 50px;
    }
  }

  .ls_video_slider_block_content_wrap.margin_left {
    margin-left: 50px;
  }

  .ls_video_slider_arrow_prev {
    left: -32px;
  }

  .ls_comment_row {
    padding-right: 50px;
  }
}

@media screen and (max-width: 1399px) {
  .ls_banner_subtitle {
    font-size: 40px;
  }

  .ls_photo_slider_block_item_wrap {
    width: 400px;
    margin-right: 60px;
  }

  .ls_photo_slider_block_item {
    max-width: 400px;
  }

  .ls_photo_slider_arrow_next {
    right: 25px;
  }

  .ls_photo_slider_arrow_prev {
    left: -35px;
  }

  .ls_video_slider_block_text {
    max-width: 650px;
  }
}

@media screen and (max-width: 1299px) {
  .ls_inner_container {
    padding: 80px 20px;
  }

  .ls_title {
    font-size: 40px;

    &.center {
      padding-right: 0;
    }

    &.name {
      font-size: 30px;
    }
  }

  .ls_text {
    font-size: 16px;
  }

  .ls_photo_slider_row {
    flex-direction: column-reverse;
    padding: 0 30px;

    & .ls_title {
      display: none;
    }

    & .ls_title.center {
      display: block;
    }
  }

  .ls_photo_slider_text_block {
    max-width: 100%;
    margin-right: 0;
  }

  .ls_photo_slider_block {
    max-width: max-content;
    overflow: visible;
  }

  .ls_photo_slider_block_item_wrap {
    margin-bottom: 24px;
  }

  .ls_video_slider_block_content_wrap.margin_left {
    margin-left: 80px;
  }

  .ls_video_slider_arrow_prev {
    left: -5px;
  }

  .ls_video_slider_block_text {
    max-width: 550px;
  }

  .ls_comment_row {
    padding-right: 0;
  }

  .ls_comment_content {
    max-width: 900px;
    margin-left: 28px;
  }

  .ls_comment_quot {
    width: 22px;

    & svg {
      width: 22px;
    }

    &.open {
      top: -25px;
      left: -30px;
    }

    &.close {
      bottom: -25px;
      right: -30px;
    }
  }

  .ls_profiles_img_wrap {
    width: 280px;
  }
}

@media screen and (max-width: 1199px) {
  .ls_banner_subtitle {
    line-height: 60px;
    font-size: 36px;
  }

  .ls_video_slider_arrow_prev {
    left: 110px;
  }

  .ls_video_slider_arrow_next {
    right: 110px;
  }

  .ls_video_slider_block_content_wrap {
    margin-right: 24px;
  }

  .ls_video_slider_block_content_wrap.margin_left {
    margin-left: 0;
  }

  .ls_video_slider_block_content {
    flex-direction: column;
    align-items: center;
    width: calc(100% - 12px);
    margin: 0 auto;
  }

  .ls_video_slider_block_item {
    margin-bottom: 24px;
  }

  .ls_video_slider_paging {
    width: 200px;
  }

  .ls_comment_row {
    flex-direction: column;

    &.reverse {
      flex-direction: column;
    }
  }

  .ls_comment_img_wrap {
    width: 280px;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 999px) {
  .ls_video_slider_arrow_prev {
    left: 60px;
  }

  .ls_video_slider_arrow_next {
    right: 60px;
  }

  .ls_photo_slider_block_item_wrap {
    min-width: 100%;
  }

  .ls_profiles_img_wrap {
    width: 240px;
  }
}

@media screen and (max-width: 899px) {
  .ls_banner_bg {
    background-position-x: -300px;
  }

  .ls_video_slider_arrow_prev {
    left: 10px;
  }

  .ls_video_slider_arrow_next {
    right: 10px;
  }
}

@media screen and (max-width: 799px) {
  .ls_banner_subtitle {
    line-height: 50px;
    font-size: 30px;
  }

  .ls_photo_slider_block_item {
    margin: 0 auto;
  }

  .ls_photo_slider_arrow_prev {
    left: 0;
  }

  .ls_photo_slider_arrow_next {
    right: 0;
  }
}

@media screen  and (max-width: 767px){
  .ls_inner_container {
    padding: 50px 15px;
  }

  .react_player video {
    max-height: 80vh;
    max-height: calc(var(--vh, 1vh) * 80);
  }
}

@media screen and (max-width: 699px) {
  .ls_photo_slider_arrow_prev {
    left: 20px;
  }

  .ls_photo_slider_arrow_next {
    right: 20px;
  }
}

@media screen and (max-width: 599px) {
  .ls_banner_bg {
    padding: 20px;
  }

  .back_to_site_btn {
    font-size: 14px;
    min-height: 40px;
    padding: 14px 20px;
  }

  .ls_banner_subtitle {
    line-height: 21px;
    font-size: 16px;
  }

  .ls_photo_slider_row {
    padding: 0;
  }

  .ls_photo_slider_block_item {
    margin: 0 auto;
  }

  .ls_video_slider_block_text {
    margin-left: 0;
  }

  .ls_title {
    font-size: 24px;
    line-height: 120%;

    &.name {
      font-size: 16px;
    }

    &.center {
      margin: 0 auto 24px;
    }
  }

  .ls_text {
    font-size: 15px;
    line-height: 150%;
  }

  .ls_video_slider_block_item {
    width: 100%;
  }

  .ls_video_slider_arrow_next,
  .ls_video_slider_arrow_prev {
    width: 40px;
    top: 200px;

    &:after {
      width: 40px;
      height: 40px;
    }

    svg {
      width: 22px;
      height: 22px;
      top: calc(50% - 2px);
      left: calc(50% - 22px);
    }
  }


  .ls_comment_quot {
    width: 16px;

    & svg {
      width: 16px;
    }

    &.open {
      top: -20px;
      left: -25px;
    }

    &.close {
      bottom: -20px;
      right: -25px;
    }
  }

  .ls_comment_content {
    margin: 0 20px;
  }

  .ls_comment_content_data {
    font-size: 15px;
  }

  .ls_profiles_name {
    font-size: 16px;
  }

  .ls_profiles_country {
    font-size: 14px;
    margin-bottom: 24px;
  }

  .ls_profiles_btn {
    font-size: 14px;
    padding: 12px 40px;
  }
}

@media screen and (max-width: 549px) {
  .ls_photo_slider_arrow_prev {
    left: 5px;
  }

  .ls_photo_slider_arrow_next {
    right: 5px;
  }
}

@media screen and (max-width: 499px) {
  .ls_video_slider_arrow_next,
  .ls_video_slider_arrow_prev {
    top: 140px;
  }

  .ls_profiles_img_wrap {
    width: 200px;
  }
}

@media screen and (max-width: 480px) {
  .ls_photo_slider_arrow_prev,
  .ls_photo_slider_arrow_next {

    svg {
      fill: #FFFFFF;
      stroke: #FFFFFF;
    }
  }
}