@import "@sitestyles";

.profile_wrap{
  flex: 1;
  overflow-y: auto;
  padding: 0 15px 15px 30px;
  margin-right: 12px;
}

.profile_wrap *{
  font-family: $mainFont;
}

.profile_wrap::-webkit-scrollbar{
  width: 3px;
  background: #fff;
  border-radius: 3px;
}

.profile_wrap::-webkit-scrollbar-thumb{
  background: $scrollbarThumbColor;
  border-radius: 3px;
}

.profile_middle_row{
  display: flex;
  justify-content: space-between;
  margin: 24px 0;
}

.profile_middle_row > div{
  width: calc(50% - 12px);
}

.delete_profile_btn{
  width: 296px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border: 1px solid $primaryColor;
  border-radius: 4px;
  margin: 23px auto 0;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0.06em;
  color: $primaryColor;
  cursor: pointer;

  svg {
    margin-right: 23px;
    width: 36px;
    height: 26px;
    stroke: $primaryColor;;
  }
}

@media screen and (max-width: 1139px){
  .delete_profile_btn{
    font-size: 14px;
    width: 230px;
    height: 40px;

    svg {
      width: 26px;
      height: 26px;
      margin-right: 12px;
    }
  }
}

@media screen and (max-width: 799px){
  .profile_wrap{
    padding: 0 7px 15px 10px;
    margin-right: 0;
  }

  .profile_middle_row > div{
    width: calc(50% - 8px);
  }

  .profile_middle_row{
    margin: 16px 0;
  }
}

@media screen and (max-width: 699px){
  .profile_middle_row{
    display: block;
  }

  .profile_middle_row > div{
    width: 100%;
  }

  .profile_middle_row > div + div{
    margin-top: 16px;
  }
}