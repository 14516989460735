@import "@sitestyles";

.popup_credits_out {
  max-width: 100%;
  width: 1040px;
  background-color: $whiteColor;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 40px;
  position: relative;
  margin-left: auto;
  margin-right: auto;
}

.popup_credits_out_title {
  font-weight: 600;
  font-size: 28px;
  text-align: center;
  color: $accentLeftColor;
  margin-bottom: 8px;
}

.popup_credits_out_desc {
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  color: $defaultTextColor;
  margin-bottom: 28px;
}

.popup_credits_out_grid {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  justify-content: space-between;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -o-flex-wrap: wrap;
  flex-wrap: wrap;
}

.popup_credits_out_item_big {
  width: 100%;
  height: 89px;
  background-color: $littleGreyLittleBlueColor;
  border-radius: 10px;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: space-between;
  -ms-align-items: center;
  align-items: center;
  padding: 0 30px;
  margin-bottom: 20px;
  cursor: pointer;
}

input:checked + .popup_credits_out_item_big {
  background-color: $background3Color;
}

.popup_credits_out_item_big_left {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
}

.popup_credits_out_grid input {
  display: none;
}

.popup_credits_out_item_big_input {
  display: block;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  border: 2px solid $primaryColor;
  position: relative;
  cursor: pointer;
  margin-right: 24px;
}

input:checked + .popup_credits_out_item_big .popup_credits_out_item_big_input:after {
  content: "";
  display: block;
  width: 18px;
  height: 18px;
  background-color: $primaryColor;
  border-radius: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.popup_credits_out_item_big_title {
  font-weight: 600;
  font-size: 28px;
  color: $defaultTextColor;
}

.popup_credits_out_item_big_right {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
}

.popup_credits_out_item_big_credits_count {
  font-size: 20px;
  color: $defaultTextColor;
  margin-right: 24px;
}

.popup_credits_out_item_big_price {
  font-weight: 600;
  font-size: 30px;
  text-align: center;
  color: $accentRightColor;
}

.popup_credits_out_item {
  width: calc(50% - 8px);
  height: 59px;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  justify-content: space-between;
  background-color: $littleGreyLittleBlueColor;
  border-radius: 10px;
  padding: 0 30px;
  margin-bottom: 20px;
  cursor: pointer;
}

input:checked + .popup_credits_out_item {
  background-color: $background3Color;
}

.popup_credits_out_item_left {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
}

.popup_credits_out_item_input {
  display: block;
  width: 24px;
  height: 24px;
  border-radius: 100%;
  border: 2px solid $primaryColor;
  position: relative;
  margin-right: 18px;
}

input:checked + .popup_credits_out_item .popup_credits_out_item_input:after {
  content: "";
  display: block;
  width: 14px;
  height: 14px;
  background-color: $primaryColor;
  border-radius: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.popup_credits_out_item_text {
  font-size: 18px;
  color: $defaultTextColor;
}

.popup_credits_out_item_text span {
  font-weight: 600;
}

.popup_credits_out_item_price {
  font-weight: 500;
  font-size: 24px;
  color: $accentRightColor;
}

.popup_credits_out_current_card {
  height: 62px;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  justify-content: space-between;
  border: 1px solid $defaultTextColorAlpha3;
  border-radius: 10px;
  padding: 0 29px;
}

.popup_credits_out_current_card_left {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
}

.popup_credits_out_current_card_img {
  width: 50px;
  margin-right: 19px;
}

.popup_credits_out_current_card_number {
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.03em;
  color: $defaultTextColor;
}

.popup_credits_out_current_card_text {
  font-weight: 500;
  letter-spacing: 0.01em;
  color: $onlineColor;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
}

.popup_credits_out_current_card_text img {
  width: 20px;
  margin-right: 14px;
}

.popup_credits_out_buttons {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.popup_credits_out_btn_credit {
  width: 374px;
  height: 50px;
  line-height: 50px;
	background: $buttonBackgroundColor;
  border-radius: 8px;
  text-align: center;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.01em;
  color: $whiteColor;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.popup_credits_out_btn_credit span {
  margin: 0 6px;
}

.popup_credits_out_btn_credit img {
  margin-right: 12px;
  margin-bottom: 2px;
}

.popup_credits_out_btn_credit svg {
  margin-right: 12px;
  margin-bottom: 1px;
  width: 22px;
  height: 22px;
  fill: #FFFFFF;
}

.popup_credits_out_btn_credit:hover {
  box-shadow: $searchItemBtnShadowHover;
}

.popup_credits_out_btn_paypal {
  width: 374px;
  height: 50px;
  line-height: 48px;
  background-color: $whiteColor;
  border: 1px solid $primaryColor;
  border-radius: 8px;
  text-align: center;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.01em;
  color: $primaryColor;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  justify-content: center;
  margin-left: 27px;
  cursor: pointer;
}

.popup_credits_out_btn_paypal:hover {
  box-shadow: $searchItemLikeBtnShadowHover;
}

.popup_credits_out_btn_paypal img {
  margin-left: 11px;
}

.popup_credits_out_close {
  position: absolute;
  right: 15px;
  top: 15px;
  padding: 5px;
  cursor: pointer;
}

.popup_credits_out_close img {
  width: 20px;
  display: block;
}

.popup_credits_out_close svg {
  width: 28px;
  height: 28px;
  fill: $iconCrossCloseFillColor;
  stroke: $iconCrossCloseFillColor;
  stroke-width: 0.5;
}


@media screen and (max-width: 999px) {
  .popup_credits_out {
    padding: 24px;
  }

  .popup_credits_out_title {
    font-size: 24px;
  }

  .popup_credits_out_desc {
    font-size: 14px;
    margin-bottom: 14px;
  }

  .popup_credits_out_item_big {
    padding: 0 24px;
  }

  .popup_credits_out_item_big_input {
    margin-right: 20px;
  }

  .popup_credits_out_item_big_title {
    font-size: 22px;
  }

  .popup_credits_out_item_big_credits_count {
    font-size: 16px;
  }

  .popup_credits_out_item_big_price {
    font-size: 24px;
  }

  .popup_credits_out_item_input {
    width: 20px;
    height: 20px;
    margin-right: 15px;
  }

  input:checked + .popup_credits_out_item .popup_credits_out_item_input:after {
    width: 10px;
    height: 10px;
  }

  .popup_credits_out_item_text {
    font-size: 14px;
  }

  .popup_credits_out_item_price {
    font-size: 20px;
  }

  .popup_credits_out_item {
    padding: 0 16px;
  }

  .popup_credits_out_current_card {
    padding: 0 16px;
    height: 56px;
  }

  .popup_credits_out_current_card_img {
    width: 39px;
    margin-right: 19px;
  }

  .popup_credits_out_current_card_number {
    font-size: 14px;
  }

  .popup_credits_out_current_card_text img {
    width: 16px;
    margin-right: 10px;
  }

  .popup_credits_out_current_card_text {
    font-size: 12px;
  }

  .popup_credits_out_btn_credit {
    width: 344px;
    font-size: 16px;
  }

  .popup_credits_out_btn_paypal {
    width: 344px;
    font-size: 16px;
    margin-left: 15px;
  }

  .popup_credits_out_btn_paypal img {
    width: 100px;
    margin-left: 14px;
  }

  .popup_credits_out_close {
    right: 10px;
    top: 10px;
  }

  .popup_credits_out_close img {
    width: 18px;
  }

  .popup_credits_out_close svg {
    width: 24px;
    height: 24px;
  }
}

@media screen and (max-width: 699px) {
  .popup_credits_out {
    padding: 16px;
  }

  .popup_credits_out_title {
    font-size: 16px;
  }

  .popup_credits_out_desc {
    font-size: 12px;
  }

  .popup_credits_out_item_big {
    height: 58px;
    padding: 0 15px;
    margin-bottom: 0;
  }

  .popup_credits_out_item_big_input {
    width: 16px;
    height: 16px;
    margin-right: 10px;
  }

  input:checked + .popup_credits_out_item_big .popup_credits_out_item_big_input:after {
    width: 8px;
    height: 8px;
  }

  .popup_credits_out_item_big_title {
    font-size: 14px;
  }

  .popup_credits_out_item_big_credits_count {
    font-size: 12px;
    margin-right: 12px;
  }

  .popup_credits_out_item_big_price {
    font-size: 18px;
  }

  .popup_credits_out_item {
    width: 100%;
    margin-bottom: 0;
    height: 41px;
    background-color: $whiteColor;
  }

  .popup_credits_out_item_input {
    width: 16px;
    height: 16px;
    margin-right: 10px;
  }

  input:checked + .popup_credits_out_item .popup_credits_out_item_input:after {
    width: 8px;
    height: 8px;
  }

  .popup_credits_out_item_text {
    font-size: 12px;
  }

  .popup_credits_out_item_price {
    font-size: 16px;
  }

  .popup_credits_out_current_card {
    margin-top: 12px;
  }

  .popup_credits_out_current_card {
    height: 48px;
  }

  .popup_credits_out_current_card_img {
    width: 33px;
    margin-right: 14px;
  }

  .popup_credits_out_current_card_number {
    font-size: 12px;
  }

  .popup_credits_out_close {
    right: 5px;
    top: 5px;
  }

  .popup_credits_out_close img {
    width: 14px;
  }

  .popup_credits_out_close svg {
    width: 20px;
    height: 20px;
  }
}

@media screen and (max-width: 599px) {
  .popup_credits_out_item_big_credits_count {
    display: none;
  }

  .popup_credits_out_current_card_text span {
    display: none;
  }

  .popup_credits_out_current_card_text img {
    margin-right: 0;
  }

  .popup_credits_out_buttons {
    display: block;
  }

  .popup_credits_out_btn_credit {
    width: 100%;
    max-width: 344px;
    margin: 0 auto;
  }

  .popup_credits_out_btn_credit span {
    display: none;
  }

  .popup_credits_out_btn_paypal {
    width: 100%;
    max-width: 344px;
    margin: 8px auto 0;
    font-size: 14px;
  }

  .popup_credits_out_btn_paypal img {
    margin-left: 8px;
  }

  .popup_credits_out_item_big_title {
    max-width: 130px;
  }
}

