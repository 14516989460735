@import "@sitestyles";

.popup {
  width: 550px;
  height: 97vh;
  min-height: 500px;
  max-height: 695px;
  max-width: 100%;
  background-color: #fff;
  border-radius: 5px;
  position: relative;

  @media screen and (max-width: 600px) {
    width: 96vw;
    height: 92vh;
  }
}

.popup_success {
  padding: 45px;
  box-sizing: border-box;
  height: fit-content;
  max-height: unset;
  min-height: unset;

  @media screen and (max-width: 600px) {
    padding: 24px;
  }
}

.logout_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  position: relative;
  padding: 0 12px;
  margin-bottom: 15px;
}

.logout_header_static {
  position: static;
}

.popup_header {
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 4px solid #EFF3F8;
  box-sizing: border-box;
  position: relative;
  padding: 0 12px;

  @media screen and (max-width: 600px) {
    height: 48px;
    border-bottom: 2px solid #EFF3F8;
  }
}


.popup_header_center {
  justify-content: center;
}

.popup_title {
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  width: fit-content;
  color: $mainTitleColor;

  @media screen and (max-width: 600px) {
    font-size: 16px;
  }
}

.close_btn {
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 12px;
  right: 16px;
  cursor: pointer;

  @media screen and (max-width: 600px) {
    top: 10px;
  }

  svg {
    fill: #818181;
    transition-duration: .3s;
  }

  &:hover,
  &:focus {
    outline: none;

    svg {
      fill: #333;
    }
  }
}

.close_btn_photo {
  svg {
    fill: #FFFFFF;
  }

  &:hover,
  &:focus {
    svg {
      fill: #FFFFFF;
      opacity: 0.7;
    }
  }
}

.popup_body {
  padding: 45px;
  height: calc(100% - 56px);
  box-sizing: border-box;

  @media screen and (max-width: 600px) {
    padding: 24px;
    height: calc(100% - 48px);
  }
}

.popup_body_faq {
  padding: 28px 23px 48px 48px;

  @media screen and (max-width: 600px) {
    padding: 24px 11px 24px 24px;
  }
}

.block_title {
  font-size: 20px;
  font-weight: 700;
  line-height: 140%;
  color: $mainTitleColor;
  text-align: left;
  margin-bottom: 8px;

  @media screen and (max-width: 600px) {
    font-size: 16px;
  }
}

.block_title_success {
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;

  &:before {
    content: '';
    width: 64px;
    height: 64px;
    mask: url("../../../../../img/identify-user-modal/icon_success.svg");
    mask-repeat: no-repeat;
    mask-position: center;
    mask-size: contain;
    background: $mainTitleColor;
  }

  @media screen and (max-width: 600px) {
    margin-bottom: 8px;
    gap: 16px;

    &:before {
      width: 48px;
      height: 48px;
    }
  }
}

.block_text {
  font-size: 16px;
  font-weight: 400;
  color: #4F4F4F;
  text-align: left;

  @media screen and (max-width: 600px) {
    font-size: 14px;
  }
}

.block_text_success {
  text-align: center;
  margin-bottom: 32px;

  @media screen and (max-width: 600px) {
    margin-bottom: 24px;
  }
}

.faq_btn {
  font-size: 16px;
  font-weight: 500;
  color: #4F4F4F;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  cursor: pointer;
  transition-duration: .3s;
  margin-top: 16px;

  svg {
    transition-duration: .3s;
    fill: #4F4F4F;
    width: 20px;
    height: 20px;
  }

  &:hover,
  &:focus {
    color: #333333;

    svg {
      fill: #333333;
    }
  }

  @media screen and (max-width: 600px) {
    font-size: 14px;
  }
}

.block_buttons {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 24px;
  height: calc(100% - 330px);
}

.block_button {
  background: #F4F4F4;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2px;
  height: 72px;
  padding: 8px 50px;
  border-radius: 5px;
  cursor: pointer;
  transition-duration: .3s;
  position: relative;

  &:hover,
  &:focus {
    background: #EEEEEE;
  }

  &:before,
  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 24px;
    height: 24px;
  }

  &:before {
    left: 16px;
    background: #333333;
  }

  &:after {
    right: 16px;
    mask: url("../../../../../img/identify-user-modal/icon_arrow.svg");
    mask-position: center;
    mask-repeat: no-repeat;
    mask-size: contain;
    background: #212121;
  }

  @media screen and (max-width: 600px) {
    height: 98px;
    padding: 8px 48px;
  }
}

.block_button.step_completed {
  border: 1px solid #DFDFDF;
  background: #FFF;

  &:before {
    mask: url("../../../../../img/identify-user-modal/icon_check.svg");
    mask-position: center;
    mask-repeat: no-repeat;
    mask-size: contain;
    background: #27AE60;
  }
}

.block_button_title {
  font-size: 16px;
  font-weight: 600;
  color: #333;
  text-align: start;

  @media screen and (max-width: 600px) {
    font-size: 14px;
  }
}

.block_button_text {
  font-size: 14px;
  font-weight: 400;
  color: #4F4F4F;
  text-align: start;

  @media screen and (max-width: 600px) {
    font-size: 12px;
  }
}

.button_user_id:before {
  mask: url("../../../../../img/identify-user-modal/icon_user_id.svg");
  mask-position: center;
  mask-repeat: no-repeat;
  mask-size: contain;
}

.button_user_selfie:before {
  mask: url("../../../../../img/identify-user-modal/icon_user_selfie.svg");
  mask-position: center;
  mask-repeat: no-repeat;
  mask-size: contain;
}

.block_footer {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 24px;
}

.block_button_main {
  background-image: $buttonBackgroundColor, $buttonBackgroundColor;
  border: 1px solid transparent;
  color: $whiteColor;
  height: 48px;
  padding: 10px 16px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  border-radius: 5px;

  &:hover,
  &:focus {
    border-color: $accentLeftColor;
    color: $accentLeftColor;
    background: $whiteColor;

    &:before {
      background: $accentLeftColor;
    }
  }

  &:disabled {
    background: #DFDFDF;
    color: #4F4F4F;
    border-color: transparent;
    cursor: unset;
  }
}

.block_button_success {
  display: flex;
  flex-direction: row;
  gap: 11px;

  &:before {
    content: '';
    width: 20px;
    height: 20px;
    mask: url("../../../../../img/identify-user-modal/icon_success.svg");
    mask-repeat: no-repeat;
    mask-position: center;
    mask-size: contain;
    background: $whiteColor;
  }
}

.block_info {
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  color: #4F4F4F;
  display: flex;
  flex-direction: row;
  gap: 8px;
  position: relative;
  align-items: flex-start;

  &:before {
    content: '';
    display: block;
    position: relative;
    min-width: 24px;
    min-height: 24px;
    mask: url("../../../../../img/identify-user-modal/icon_policy.svg");
    mask-repeat: no-repeat;
    mask-position: center;
    mask-size: contain;
    background: #4F4F4F;
  }

  @media screen and (max-width: 600px) {
    font-size: 12px;
  }
}

.faq_block {
  height: 100%;
  overflow-y: auto;
  padding-right: 23px;


  @media screen and (max-width: 600px) {
    padding-right: 11px;
  }
}

.faq_list {
  list-style: none;
  padding: 0;
  margin: 8px 0 24px;
  height: calc(100% - 108px);
  //min-height: calc(100% - 108px);
  overflow-y: auto;
  padding-right: 10px;

  &::-webkit-scrollbar {
    width: 3px;
    background: #F8F8F8;
    border-radius: 5px;
    margin-right: 3px;
  }

  &::-webkit-scrollbar-thumb {
    background: $scrollbarThumbColor;
    border-radius: 7px;
  }

  @media screen and (max-width: 600px) {
    margin: 0 0 24px;
  }
}

.faq_item:not(:last-child) {
  border-bottom: 1px solid #DFDFDF;
}

.faq_item {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.faq_item_title {
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  color: #333;
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  cursor: pointer;

  &:after {
    content: '';
    display: block;
    width: 24px;
    height: 24px;
    mask: url("../../../../../img/identify-user-modal/icon_arrow.svg");
    mask-position: center;
    mask-repeat: no-repeat;
    mask-size: contain;
    background: #212121;
    transform: rotate(90deg);
    transition-duration: .3s;
  }

  @media screen and (max-width: 600px) {
    font-size: 14px;
  }
}

.faq_item_title.active_faq:after {
  transform: rotate(-90deg);
}

.faq_item_text {
  margin-bottom: 16px;
  text-align: left;
  color: #4F4F4F;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;

  @media screen and (max-width: 600px) {
    font-size: 14px;
  }
}

.user_id_title {
  margin-bottom: 24px;
}

.requirements_list {
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 7px;
}

.requirements_item {
  text-align: left;
  color: #4F4F4F;
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
  display: flex;
  flex-direction: row;
  gap: 8px;

  &:before {
    content: '';
    width: 24px;
    height: 24px;
  }
}

.requirements_item_correct:before {
  mask: url("../../../../../img/identify-user-modal/icon_check--small.svg");
  background: #55A026;
  mask-size: contain;
  mask-position: center;
  mask-repeat: no-repeat;
}

.requirements_item_incorrect:before {
  mask: url("../../../../../img/identify-user-modal/icon_close--small.svg");
  background: #DC3333;
  mask-size: contain;
  mask-position: center;
  mask-repeat: no-repeat;
}

.examples_list {
  list-style: none;
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;

  @media screen and (max-width: 600px) {
    //gap: 17px;
  }
}

.examples_item {
  display: flex;
  flex-direction: column;
  gap: 4px;
  color: #4F4F4F;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
  width: 25%;
  white-space: nowrap;

  &:before {
    content: '';
    width: 100%;
    height: 68px;
  }

  @media screen and (max-width: 600px) {
    font-size: 10px;

    &:before {
      height: 50px;
    }
  }
}

.examples_list_selfie {
  justify-content: space-around;

  .examples_item:before {
    height: 56px;
  }
}

.examples_item_good:before {
  background: url("../../../../../img/identify-user-modal/user_id_good.svg") center no-repeat;
  background-size: contain;
}

.examples_item_cut:before {
  background: url("../../../../../img/identify-user-modal/user_id_cut.svg") center no-repeat;
  background-size: contain;
}

.examples_item_blur:before {
  background: url("../../../../../img/identify-user-modal/user_id_blur.svg") center no-repeat;
  background-size: contain;
}

.examples_item_reflective:before {
  background: url("../../../../../img/identify-user-modal/user_id_reflective.svg") center no-repeat;
  background-size: contain;
}

.examples_item_glasses:before {
  background: url("../../../../../img/identify-user-modal/no_glasses_icon.svg") center no-repeat;
  background-size: contain;
}

.examples_item_hat:before {
  background: url("../../../../../img/identify-user-modal/no_hat_icon.svg") center no-repeat;
  background-size: contain;
}

.examples_item_mask:before {
  background: url("../../../../../img/identify-user-modal/no_mask_icon.svg") center no-repeat;
  background-size: contain;
}

.examples_item_good_selfie:before {
  background: url("../../../../../img/identify-user-modal/good_selfie.svg") center no-repeat;
  background-size: contain;
}

.examples_item_selfie_glasses:before {
  background: url("../../../../../img/identify-user-modal/selfie_glasses.svg") center no-repeat;
  background-size: contain;
}

.examples_item_selfie_hat:before {
  background: url("../../../../../img/identify-user-modal/selfie_hat.svg") center no-repeat;
  background-size: contain;
}

.examples_item_selfie_background:before {
  background: url("../../../../../img/identify-user-modal/selfie_background.svg") center no-repeat;
  background-size: contain;
}

.form {
  margin: 24px 0 32px;

  @media screen and (max-width: 600px) {
    margin: 16px 0 24px;
  }
}

.form_input {
  display: none;
}

.form_label {
  border-radius: 5px;
  background: #F4F4F4;
  display: flex;
  height: 56px;
  padding: 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: #333;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
  transition-duration: .3s;

  &:before {
    content: '';
    width: 24px;
    height: 24px;
    background: url("../../../../../img/identify-user-modal/icon_camera.svg") center no-repeat;
    background-size: contain;
  }

  &:hover,
  &:focus {
    background: #EEE;
  }

  @media screen and (max-width: 600px) {
    height: 48px;
  }
}

.form_info {
  color: #4F4F4F;
  font-size: 14px;
  font-weight: 400;
  line-height: 130%;
  margin-bottom: 16px;
  text-align: left;
  height: 40px;
  display: flex;
  align-items: flex-end;

  @media screen and (max-width: 600px) {
    font-size: 12px;
    height: 40px;
  }
}

.form_info.form_info_success {
  color: #55A026;
}

.form_file {
  border-radius: 5px;
  border: 1px solid #DFDFDF;
  display: flex;
  height: 56px;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  padding: 12px;
  position: relative;

  &:before {
    position: absolute;
    content: '';
    width: 32px;
    height: 32px;
    background: url("../../../../../img/identify-user-modal/file_icon.svg") no-repeat center;
    background-size: contain;
    top: 50%;
    transform: translateY(-50%);
    left: 12px;
  }

  @media screen and (max-width: 600px) {
    height: 48px;
    padding: 8px;

    &:before {
      left: 8px;
    }
  }
}

.form_file_info {
  padding: 5px 5px 5px 52px;
  background: transparent;
  transition-duration: .3s;
  cursor: pointer;
  border-radius: 4px;
  min-width: 150px;

  &:hover:not(:disabled),
  &:focus:not(:disabled) {
    background: #EEE;
  }

  @media screen and (max-width: 600px) {
    padding-left: 48px;
    min-width: 130px;
  }
}

.form_file_name,
.form_file_size {
  color: #4F4F4F;
  text-align: left;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.form_file_name {
  font-size: 12px;
}

.form_file_size {
  font-size: 10px;
}

.form_file_delete {
  width: 24px;
  height: 24px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    fill: #818181;
    transition-duration: .3s;
  }

  &:hover,
  &:focus {
    svg {
      fill: #333333;
    }
  }
}

.requirements_details {
  height: calc(100% - 262px);
  overflow-y: auto;
  padding-right: 10px;

  &::-webkit-scrollbar {
    width: 3px;
    background: #F8F8F8;
    border-radius: 5px;
    margin-right: 3px;
  }

  &::-webkit-scrollbar-thumb {
    background: $scrollbarThumbColor;
    border-radius: 7px;
  }

  @media screen and (max-width: 600px) {
    height: calc(100% - 230px);
    margin-bottom: auto;
  }
}

.form_error .form_info {
  color: #EF4B61;
}

.form_error .form_label {
  border: 1px solid #EF4B61;
}

.requirements_list_selfie {
  padding-left: 110px;
  position: relative;
  margin: 24px 0;

  &:before {
    content: '';
    width: 95px;
    height: 95px;
    background: #F2F2F2 url("../../../../../img/identify-user-modal/user_selfie_image.svg") no-repeat bottom;
    background-size: contain;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    border-radius: 5px;
  }

  @media screen and (max-width: 600px) {
    padding-left: 72px;
    margin: 16px 0 24px;

    &:before {
      width: 56px;
      height: 56px;
      top: 0;
      transform: unset;
    }
  }
}

.uploaded_photo_wrap {
  width: 100vw;
  height: 100vh;
  background: #000;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  padding: 15px;
}

.uploaded_photo {
  width: 100%;
  height: auto;
  object-fit: contain;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: 90vh;
}

.help_button:hover {
    cursor: pointer;
}

.help_button_with_close {
  margin-right: 35px;
  &:hover {
    cursor: pointer;
  }
}

.help_button_right {
  position: absolute;
  left: 20px;
  top: 16px;
}

.requirements_selfie {
  display: flex;
  flex-direction: column;
  gap: 16px;
  list-style: none;
}

.requirements_selfie_item {
  display: flex;
  flex-direction: column;
}

.requirements_selfie_item_title {
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
  text-align: left;
  color: #333333;
  margin-bottom: 2px;
}

.requirements_selfie_item_text {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  color: #4F4F4F;
}