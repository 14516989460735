@import "@sitestyles";

@import "@sitestyles";

.ReactModal__Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $fadeColor;
  z-index: 9999 !important;
  backdrop-filter: blur(4px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.ReactModal__Content{
  width: 1040px;
  //background-color: $whiteColor;
  border-radius: 10px;
  position: relative;
}

@media screen and (max-width: 1249px) {
  .ReactModal__Content{
    width: 683px;
  }
}

@media screen and (max-width: 720px){
  .ReactModal__Content{
    width: 100%;
    height: 100%;
    overflow-y: auto;
  }
}

@media screen and (max-height: 719px){
  .ReactModal__Content{
    height: 100%;
    overflow-y: auto;
  }
}
