@import "@sitestyles";

.vip_onb_wrapper {
  display: flex;
  justify-content: center;
}

.vip_onb_item {
  padding: 30px 3.42%;
  margin-bottom: 16px;
  box-shadow: 3px 3px 5px rgba(197, 196, 208, 0.64);
  border-radius: 10px;
  background-color: #fff;
  position: relative;
  width: 100%;
}

.vip_onb_title {
  margin-top: 10px;
  margin-bottom: 24px;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  text-transform: uppercase;

  color: #00317B;

  span {
    color: #FEB930;
  }
}

.vip_onb_container {
  display: flex;
}

.vip_onb_container_txt {
  display: flex;
  align-items: center;
  margin-top: 12px;
}

.vip_onb_dot {
  width: 16px;
  height: 16px;
}

.vip_onb_container_txt svg {
  width: 18px;
  height: 18px;
  min-height: 18px;
  min-width: 18px;
  fill: #ffa530;
}

.vip_onb_txt {
  margin-left: 8px;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #00317B;
}

.vip_onb_pict_wrap {
  display: block;

  &_mob {
    display: none;
  }
}

.vip_onb_pict {
  width: 435px;
  height: 301px;
}

.vip_onb_btn {
  margin-top: 40px;
  cursor: pointer;
  background: linear-gradient(90.32deg, #FEB930 0.31%, #FE8730 115.03%), #0965B1;
  width: 381px;
  height: 54px;
  border-radius: 4px;
  color: $whiteColor;
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
}

.vip_onb_cross {
  position: absolute;
  right: 20px;
  top: 30px;
  transform: translateY(-50%);
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.vip_onb_cross svg {
  width: 24px;
  height: 24px;
  fill: #bfbfbf;
  stroke: #bfbfbf;
  stroke-width: 0.5;
}

@media screen and (max-width: 1449px) {
  .vip_onb_item {
    padding: 20px 25px;
  }

  .vip_onb_pict {
    width: 360px;
  }
}

@media screen and (max-width: 1299px) {
  .vip_onb_btn {
    margin: 25px auto 0;
  }
  .vip_onb_pict_wrap {
    display: none;

    &_mob {
      display: flex;
      justify-content: center;
      margin-top: 10px;

      img {
        width: 280px;
      }
    }
  }
}

@media screen and (max-width: 999px) {
  .vip_onb_title {
    font-size: 20px;
  }

  .vip_onb_txt {
    font-size: 14px;
  }

  .vip_onb_btn {
    font-size: 16px;
    width: 360px;
    height: 50px;
  }
}

@media screen and (max-width: 699px) {
  .vip_onb_item {
    display: block;
    padding: 16px;
  }
}

@media screen and (max-width: 650px) {
  .vip_onb_item__video_show {
    max-width: 350px;
  }

  .vip_onb_btn {
    margin-top: 20px;
    font-size: 14px;
    width: 100%;
    height: 40px;
    background: $buttonBackgroundColor;
  }
}

@media screen and (max-width: 599px) {
  .vip_onb_title {
    font-size: 18px;
  }

  .vip_onb_cross {
    right: 10px;
    top: 20px;
    width: 18px;
    height: 18px;
  }

  .vip_onb_cross svg {
    width: 22px;
    height: 22px;
  }
}

@media screen and (max-width: 1349px) and (min-width: 1200px) {
  .vip_onb_item__video_show {
    max-width: 780px;
  }
}

@media screen and (max-width: 2496px) and (min-width: 1900px) {
  .vip_onb_item__video_show {
    max-width: 1270px;
  }
}

@media screen and (max-width: 3138px) and (min-width: 2497px) {
  .vip_onb_item__video_show {
    max-width: 1911px;
  }
}

@media screen and (max-width: 3199px) and (min-width: 3139px) {
  .vip_onb_item__video_show {
    max-width: 2554px;
  }
}

@media screen and (max-width: 3666px)  and (min-width: 3200px) {
  .vip_onb_item__video_show {
    max-width: 2050px;
  }
}

@media screen and (min-width: 3667px) {
  .vip_onb_item__video_show {
    max-width: 3080px;
  }
}



