@import "@sitestyles";

/*MAIN PAGE --> MAIN-SELECT*/


.cl2h_tab_wide_filter_item_wrap {
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	justify-content: space-between;
}

.main_registration_form_dob_row .cl2h_tab_wide_filter_item{
	width: 120px;
}

.main_registration_form_dob_row .cl2h_tab_wide_filter_item:nth-child(2) {
	width: 144px;
}

.cl2h_tab_wide_filter_item:not(.open) .like_select_head:hover {
	box-shadow: 0px 3px 6px rgba(28, 38, 119, 0.08);
	border-color: $selectBorderHoverColor;
	/*font-weight: 500;*/
	color: #333;
}

.cl2h_tab_wide_filter_item:not(.open) .like_select_head.selected:hover{
	font-weight: 600;
	color: #00317b;
}

.like_select_list.open {
	display: block;
}
