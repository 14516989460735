@import "@sitestyles";

.onboardingInput {
  width: 100%;
  position: relative;

  &:last-child {
    margin-top: 16px;
  }

  p {
    color: #333;
    font-family: $mainFont;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    text-align: left;
    margin: 0 0 8px;
  }
}

.inputWrapper {
  width: 100%;
  position: relative;
}

.arrow {
  position: absolute;
  right: 16px;
  top: 13px;
}

.input {
  border-radius: 8px;
  border: 1px solid #E4E4E4;
  background: #F8F8F8;
  width: 100%;
  padding: 12px 16px;
  color: #333333;
  font-family: $mainFont;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  transition: all .3s ease;
  cursor: pointer;

  &::placeholder {
    color: #404752;
    font-family: $mainFont;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }

  &:hover {
    border: 1px solid #D8D8D8;
    background: #EFEFEF;
    transition: all .3s ease;
  }

  //&:focus {
  //  border: 1px solid #6D6AE5;
  //  background: #FFF;
  //  transition: all .3s ease;
  //}
}

.input_noCaret{
  caret-color: transparent;
}

.input_filled {
  border: 1px solid #D8D8D8;
  background: #FFF;
  color: #333333;
  transition: all .3s ease;
}

.input_opened {
  border: 1px solid #6D6AE5;
  border-bottom: 1px solid #EFEFEF;
  border-radius: 8px 8px 0 0;
  background: #FFF;
  transition: all .3s ease;

  &:hover {
    border: 1px solid #6D6AE5;
    border-bottom: 1px solid #EFEFEF;
    background: #FFF;
    transition: all .3s ease;
    border-radius: 8px 8px 0 0;
  }
}

.list {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  max-height: 220px;
  overflow-y: auto;
  position: absolute;
  top: 75px;
  width: 100%;
  padding: 10px 20px 10px;
  box-sizing: border-box;
  margin-right: -15px;
  z-index: 10;

  border: 1px solid #6D6AE5;
  border-top: 1px solid #EFEFEF;
  background: #FFF;
  border-radius: 0 0 8px 8px;

  &::-webkit-scrollbar-track {
    border-radius: 0;
    margin-left: 15px;
  }

  &::-webkit-scrollbar {
    width: 2px;
    height: 2px;
    background: #EFEFEF;
    margin-right: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: #757575;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
  }
}

.option {
  width: 100%;
  padding: 16px;
  text-align: start;
  cursor: pointer;

  &:hover {
    background: #F8F8F8;
    border-radius: 8px;
    transition: all .3s ease;
  }
}