.player_wrapper {
    /*position: relative;*/
    /*padding-top: 56.25%; !* Player ratio: 100 / (1280 / 720) *!*/
    width: 100%;
    display: flex;
    justify-content: center;
}

.react_player {
    /*position: absolute;*/
    /*top: 0;*/
    /*left: 0;*/
}

.react_player video {
    pointer-events: auto;
    max-height: 95vh;
    max-height: calc(var(--vh, 1vh) * 95);
}

@media screen  and (max-width: 767px){
    .react_player video {
        max-height: 80vh;
        max-height: calc(var(--vh, 1vh) * 80);
    }
}