@import "@sitestyles";

.new_mprofile_wrap{
    flex: 1;
    overflow-y: auto;
    padding-left: 21px;
    margin-right: 10px;
    padding-right: 11px;
}

.new_mprofile_wrap::-webkit-scrollbar {
    width: 3px;
    background: $whiteColor;
    border-radius: 3px;
}

.new_mprofile_wrap::-webkit-scrollbar-thumb {
    background: $scrollbarThumbColor;
    border-radius: 3px;
}

.new_mprofile_main{
    display: flex;
    background-color: $whiteColor;
    box-shadow: 0px 6px 12px rgba(47, 43, 99, 0.16);
    border-radius: 10px;
    padding: 26px;
    position: relative;
    margin-bottom: 15px;
}

.new_mprofile_main_avatar{
    width: auto;
    height: 246px;
    object-fit: cover;
    border-radius: 15px;
    margin-right: 26px;
    max-width: 450px;
}

.new_mprofile_main_info{
    align-self: center;
}

.new_mprofile_main_info_name{
    font-weight: 600;
    font-size: 30px;
    color: $accentLeftColor;
    margin-bottom: 20px;
    display: flex;
    align-items: flex-start;
}

.new_mprofile_main_info_name_verified{
    font-size: 12px;
    display: flex;
    align-items: center;
    margin-left: 10px;
    margin-top: 7px;
    color: #27AE60;
    text-transform: uppercase;
}

.new_mprofile_main_info_name_verified img{
    width: 18px;
    margin-right: 4px;
}

.new_mprofile_main_info_id{
    letter-spacing: 0.06em;
    text-transform: uppercase;
    color: $defaultTextColor;
    margin-bottom: 13px;
}

.new_mprofile_main_info_dob{
    font-weight: 600;
    font-size: 16px;
    letter-spacing: 0.06em;
    color: $primaryColor;
    margin-bottom: 13px;
}

.new_mprofile_main_info_dob span{
    font-weight: normal;
}

.new_mprofile_main_info_place{
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 0.06em;
    text-transform: capitalize;
    color: $primaryColor;
    display: flex;
    align-items: center;
    margin-bottom: 25px;
}

.new_mprofile_main_info_place img{
    height: 20px;
    margin-right: 9px;
}

.new_mprofile_main_info_email{
    font-weight: 600;
    font-size: 18px;
    letter-spacing: 0.06em;
    color: $accentLeftColor;
}

.new_mprofile_main_info_email span{
    font-weight: normal;
}

.new_mprofile_main_credits{
    width: 252px;
    height: 109px;
    background-color: $background1Color;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: auto;
}

.new_mprofile_main_credits_top{
    display: flex;
    align-items: center;
}

.new_mprofile_main_credits_img{
    width: 32px;
    margin-right: 18px;
}

.new_mprofile_main_credits_right{
    text-align: center;
}

.new_mprofile_main_credits_text{
    font-weight: 500;
    font-size: 20px;
    line-height: 1.4;
    color: $stormGreyColor;
}

.new_mprofile_main_credits_value{
    font-weight: 600;
    font-size: 24px;
    letter-spacing: 0.06em;
    color: $accentRightColor;
}

.new_mprofile_main_credits_getmore_link{
    margin-top: 7px;
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    text-decoration-line: underline;
    color: $accentLeftColor;
    cursor: pointer;
}

.new_mprofile_main_credits_getmore_btn{
    display: none;
    width: 125px;
    height: 34px;
    align-items: center;
    justify-content: center;
    background: linear-gradient(90.46deg, $accentLeftColor 0%, $accentRightColor 100%);
    border-radius: 4px;
    font-weight: 600;
    font-size: 12px;
    color: $whiteColor;
}

.new_mprofile_main_credits_getmore_btn img{
    margin-right: 9px;
}

.new_mprofile_edit_btn{
    font-weight: 600;
    font-size: 16px;
    letter-spacing: 0.06em;
    color: $accentRightColor;
    background-image: url(../../img/edit-profile-btn.svg);
    background-repeat: no-repeat;
    background-position: left center;
    background-size: contain;
    padding-left: 31px;
    cursor: pointer;
    position: absolute;
    right: 0;
    bottom: 26px;
}

.new_mprofile_edit_btn.new_mprofile_main_edit_btn{
    right: 26px;
}

.new_mprofile_block{
    background-color: $whiteColor;
    box-shadow: 0px 6px 12px rgba(47, 43, 99, 0.16);
    border-radius: 10px;
    margin-bottom: 15px;
}

.new_mprofile_block_photo_edit{
    padding-bottom: 26px;
    display: block;
}

.new_mprofile_block_edit{

}

.new_mprofile_block_head{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;
    padding-left: 32px;
    cursor: pointer;
}

.new_mprofile_block_head_title{
    height: 100%;
    padding-left: 58px;
    padding-right: 40px;
    font-weight: 600;
    font-size: 20px;
    letter-spacing: 0.06em;
    text-transform: uppercase;
    color: $primaryColor;
    background-position: left center;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    position: relative;
}

.new_mprofile_block_head_title:after{
    content: "";
    display: block;
    width: 22px;
    height: 11px;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    background-image: url(../../img/np-arrow.svg);
    background-repeat: no-repeat;
    display: none;
}

.new_mprofile_block.opened .new_mprofile_block_head_title:after{
    transform: translateY(-50%) rotate(180deg);
}

.new_mprofile_block_head_title.social{
    background-image: url(../../img/np-social.svg);
}
.new_mprofile_block_head_title.about{
    background-image: url(../../img/np-about.svg);
}
.new_mprofile_block_head_title.appear{
    background-image: url(../../img/np-appearance.svg);
}
.new_mprofile_block_head_title.prefer{
    background-image: url(../../img/np-preferences.svg);
}
.new_mprofile_block_head_title.hobby{
    background-image: url(../../img/np-hobby.svg);
}
.new_mprofile_block_head_title.photo{
    background-image: url(../../img/np-photo.svg);
}

.new_mprofile_block_head_info{
    height: 42px;
    line-height: 42px;
    font-weight: 500;
    font-size: 18px;
    color: $defaultTextColorAlpha8;
    padding: 0 32px 0 26px;
    margin-right: -6px;
    background-color: $freeContentColor;
    border-radius: 5px 2px 2px 5px;
}

.new_mprofile_block_content{
    padding: 26px 0;
    margin: 0 26px;
    border-top: 1px solid $defaultTextColorAlpha1;
    position: relative;
    max-height: 0;
    padding: 0;
    transition: max-height 1.0s, padding .3s;
}

.new_mprofile_block.opened .new_mprofile_block_content{
    max-height: 99999vh;
    padding: 26px 0;
    transition: max-height 1.0s, padding .6s;
}

.new_mprofile_block_content_title{
    font-weight: 600;
    font-size: 18px;
    letter-spacing: 0.06em;
    color: $accentLeftColor;
    margin-bottom: 12px;
}

.new_mprofile_block_jobs_list{
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 40px;
}

.new_mprofile_block_hobbies_list{
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 22px;
}

.new_mprofile_block_jobs_list_item_not_spec{
    width: 255px;
    height: 53px;
    border: 1px solid $defaultTextColorAlpha1;
    border-radius: 26px;
    padding-left: 73px;
    background-repeat: no-repeat;
    background-position: 6px center;
    background-image: url(../../img/np-not-spec.svg);
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.06em;
    color: $counterAndErrorColor;
}

.new_mprofile_block_jobs_list_item{
    display: flex;
    align-items: center;
    padding: 0 20px;
    height: 52px;
    border-radius: 26px;
    border: 1px solid $defaultTextColorAlpha1;
    font-weight: 500;
    letter-spacing: 0.06em;
    color: $stormGreyColor;
    margin-bottom: 16px;
    margin-right: 16px;
}

.new_mprofile_block_jobs_list_item.not_spec{
    color: $counterAndErrorColor;
    font-weight: 600;
}

.new_mprofile_block_jobs_list_item:last-child{
    margin-right: 0;
}

.new_mprofile_block_edit .new_mprofile_block_jobs_list_item:hover{
    cursor: pointer;
}

.new_mprofile_block_jobs_list_item.active{
    background-color: $background5Color;
    border-color: $background5Color;
}

.new_mprofile_block_jobs_list_item img{
    width: 39px;
    height: 39px;
    object-fit: contain;
    margin-right: 10px;
}

.new_mprofile_block_jobs_list_item_edit_type_in{
    position: relative;
    align-self: flex-start;
}

.new_mprofile_block_jobs_list_item_edit_type_in input{
    width: 276px;
    height: 52px;
    background-color: $bleachColor;
    border-radius: 26px;
    padding-left: 24px;
    padding-right: 48px;
    font-size: 16px;
    color: $defaultTextColor;
}

.new_mprofile_block_jobs_list_item_edit_type_in input:hover{
    box-shadow: 0px 3px 3px rgba(28, 38, 119, 0.13);
}

.new_mprofile_block_jobs_list_item_edit_type_in input::placeholder{
    color: $defaultTextColorAlpha6;
    font-weight: 400;
}

.new_mprofile_block_jobs_list_item_edit_type_in_btn{
    width: 27px;
    height: 27px;
    background-image: url(../../img/check-circle-violet.svg);
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
}

.new_mprofile_block_content_list .cl2h_tab_wide_filter_item{
    height: 42px;
}

.new_mprofile_block_content_list{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 22px;
    grid-column-gap: 28px;
}

.new_mprofile_block_content_list + .new_mprofile_block_content_list{
    margin-top: 22px;
}

.new_mprofile_block_content_item_title{
    letter-spacing: 0.06em;
    text-transform: uppercase;
    color: $stormGreyColor;
    margin-bottom: 9px;
}

.new_mprofile_block_content_item_row_half{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
}

.new_mprofile_block_content_item_text{
    height: 42px;
    display: flex;
    align-items: center;
    padding: 0 18px;
    font-weight: 600;
    letter-spacing: 0.06em;
    color: $accentLeftColor;
    border: 1px solid $defaultTextColorAlpha2;
    border-radius: 4px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
}

.new_mprofile_block_content_item_text span{
    color: $accentLeftColor;
    font-weight: normal;
    margin-right: 4px;
}

.new_mprofile_block_content_item_text.not_spec{
    color: $counterAndErrorColor;
}

.new_mprofile_block_summary_text{
    margin-bottom: 33px;
}

.new_mprofile_block_summary_text_empty{
    font-size: 16px;
    line-height: 145%;
    color: $defaultTextColorAlpha4;
}

.new_mprofile_block_summary_text p{
    font-size: 16px;
    line-height: 150%;
    text-align: justify;
    color: $stormGreyColor;
}

.new_mprofile_block_summary_text_edit{
    padding: 11px 16px;
    background: #eff3f8;
    border: 1px solid #9aafd0;
    border-radius: 4px;
    margin-bottom: 36px;
}

.new_mprofile_block_summary_text_edit:hover{
    border-color: #5577ad;
}

.new_mprofile_block_summary_text_edit textarea{
    display: block;
    width: 100%;
    height: 100px;
    background-color: #eff3f8;
    resize: none;
    border: none;
    font-size: 16px;
    line-height: 1.45;
    font-weight: 500;
}

.new_mprofile_block_summary_text_edit textarea::placeholder{
    color: $defaultTextColor;
    font-weight: 400;
}

.new_mprofile_block_girls_colors_row{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 22px;
    margin-bottom: 40px;
}

.new_mprofile_block_girls_colors_edit_row{
    display: block;
}

.new_mprofile_block_girls_colors_col_edit + .new_mprofile_block_girls_colors_col_edit{
    margin-top: 36px;
}

.new_mprofile_block_girls_colors_list{
    display: flex;
    flex-wrap: wrap;
}

.new_mprofile_block_girls_colors_item{
    height: 52px;
    border: 1px solid $defaultTextColorAlpha1;
    border-radius: 26px;
    display: flex;
    align-items: center;
    padding: 0 24px 0 6px;
    margin-right: 16px;
    margin-bottom: 8px;
}

.new_mprofile_block_girls_colors_item:last-child{
    margin-right: 0;
}

.new_mprofile_block_girls_colors_item.active{
    background-color: $background5Color;
    border-color: $background5Color;
}

.new_mprofile_block_girls_colors_col_edit .new_mprofile_block_girls_colors_item{
    cursor: pointer;
}

.new_mprofile_block_girls_colors_item_color{
    width: 40px;
    height: 40px;
    border-radius: 100%;
    margin-right: 10px;
}

.new_mprofile_block_girls_colors_item_color.not_spec{
    background: #c4c4c4;
}
.new_mprofile_block_girls_colors_item_color.black{
    background: linear-gradient(218.66deg, #554849 13.83%, #0E0C0C 54.12%, #4F2B2B 91.96%);
}
.new_mprofile_block_girls_colors_item_color.blond{
    background: linear-gradient(218.66deg, #F6F7E6 13.83%, #EDE8CE 54.12%, #F6F7F0 91.96%);
}
.new_mprofile_block_girls_colors_item_color.brunette{
    background: linear-gradient(218.66deg, #554849 13.83%, #0E0C0C 54.12%, #4F2B2B 91.96%);
}
.new_mprofile_block_girls_colors_item_color.brown{
    background: linear-gradient(218.66deg, #8F6857 13.83%, #532816 54.12%, #683417 91.96%);
}
.new_mprofile_block_girls_colors_item_color.golden{
    background: linear-gradient(218.66deg, #E6BD8E 13.83%, #DDA77F 50.86%, #E2D4B0 91.96%);
}
.new_mprofile_block_girls_colors_item_color.ginger{
    background: linear-gradient(218.66deg, #E27C50 13.83%, #D56527 56.56%, #CC7815 91.96%);
}

.new_mprofile_block_girls_colors_item_color.green{
    background: linear-gradient(218.66deg, #2E734E 13.83%, #62C96C 54.12%, #95D544 91.96%);
}
.new_mprofile_block_girls_colors_item_color.blue{
    background: linear-gradient(218.66deg, #6CB9DB 13.83%, #5290BC 55.74%, #63CAD8 91.96%);
}
.new_mprofile_block_girls_colors_item_color.brown{
    background: linear-gradient(218.66deg, #8F6857 13.83%, #532816 54.12%, #683417 91.96%);
}
.new_mprofile_block_girls_colors_item_color.gray{
    background: linear-gradient(218.66deg, #BFD2DB 13.83%, #ACBDC9 55.74%, #B4C7C0 91.96%);
}
.new_mprofile_block_girls_colors_item_color.hazel{
    background: linear-gradient(218.66deg, #6F6514 13.83%, #BA6618 55.74%, #4E6709 91.96%);
}
.new_mprofile_block_girls_colors_item_color.amber{
    background: linear-gradient(218.66deg, #EFE165 13.83%, #BA6618 55.74%, #EACB29 91.96%);
}

.new_mprofile_block_girls_colors_item_text{
    letter-spacing: 0.06em;
    font-weight: 500;
    color: $stormGreyColor;
}

.new_mprofile_block_head_photos{
    height: 74px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 26px;
}

.new_mprofile_block_head_photos .new_mprofile_block_head_title:after{
    display: none;
}

.new_mprofile_block_head_photos .new_mprofile_edit_btn{
    position: static;
}

.new_mprofile_block_photos_wrap{
    padding: 0 26px 26px;
}

.new_mprofile_block_photos_wrap .content-slider{
    margin-top: 0;
}

.new_mprofile_block_photos_edit_wrap{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 12px;
    padding: 0 26px;
    justify-content: center;
}

.new_mprofile_block_photos_edit_img_wrap{
    width: 100%;
    padding-top: 100%;
    position: relative;
    cursor: pointer;
}

.new_mprofile_block_photos_edit_img_wrap img{
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    object-fit: cover;
    display: block;
    border-radius: 15px;
}

.new_mprofile_block_photos_edit_btn_wrap{
    position: relative;
    padding-top: 100%;
    width: 100%;
}

.new_mprofile_block_photos_edit_btn{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: $familySizeCondensedSoupColor;
    border-radius: 15px;
    font-weight: 500;
    font-size: 24px;
    color: $defaultTextColorAlpha6;
    cursor: pointer;
}

.new_mprofile_block_photos_edit_btn img{
    margin-bottom: 20px;
}



/************************
edit profile
************************/

.new_mprofile_main_edit{
    background-color: $whiteColor;
    box-shadow: 0px 6px 12px rgba(47, 43, 99, 0.16);
    border-radius: 10px;
    padding: 26px;
    position: relative;
    margin-bottom: 15px;
}

.new_mprofile_main_edit_wrap{
    display: flex;
}

.new_mprofile_main_edit_photo{
    position: relative;
    margin-right: 26px;
}

.new_mprofile_main_edit_photo img{
    width: 357px;
    height: 100%;
    object-fit: cover;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    border-radius: 15px;
}

.new_mprofile_main_edit_photo_btn{
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 94px;
    background: linear-gradient(0deg, $whiteColor 0%, $transparentColor 100%);
    border-radius: 0 0 15px 15px;
    text-align: center;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    padding-bottom: 24px;
    font-weight: 500;
    font-size: 18px;
    color: $defaultTextColor;
    cursor: pointer;
}

.new_mprofile_main_delete_photo_btn,
.new_mprofile_block_photos_delete_img_wrap{
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
    width: 85px;
    height: 26px;
    padding: 5px 14px;
    background: rgba(255, 255, 255, 0.8);
    border-radius: 5px;
    font-size: 16px;
    font-weight: 400;
    color: #00317B;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 10px;
    cursor: pointer;

    img.photo_delete_icon{
        width: 20px;
        height: 20px;
        margin-right: 5px;
        position: relative;
    }
}

.new_mprofile_main_edit_info{
    flex: 1;
}

.new_mprofile_main_edit_info_title_row{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.new_mprofile_main_edit_info_title{
    font-weight: 600;
    font-size: 24px;
    letter-spacing: 0.06em;
    text-transform: uppercase;
    color: $primaryColor;
}

.new_mprofile_main_edit_info_id{
    font-size: 18px;
    letter-spacing: 0.06em;
    text-transform: uppercase;
    color: $defaultTextColor;
}

.new_mprofile_main_edit_info_top{
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    margin-bottom: 22px;
}

.new_mprofile_main_edit_info_top_left{
    width: calc(100% - 370px);
}

.new_mprofile_main_edit_info_item_input{
    width: 100%;
    height: 50px;
    background-color: $selectBackgroundColor;
    border-radius: 4px;
    font-weight: 600;
    font-size: 20px;
    color: $accentLeftColor;
    padding: 0 21px;
    border: 1px solid $selectBorderColor;
}

.new_mprofile_main_edit_info_item_input:hover{
    border-color: $selectBorderHoverColor;
    box-shadow: 0px 3px 6px rgba(28, 38, 119, 0.08);
}

.new_mprofile_main_edit_info_item_input::placeholder {
    font-weight: 400;
    color: #C2C2C2;
}

.new_mprofile_main_edit_info_item_input:focus{
    border-color: $accentLeftColor;
    box-shadow: 0px 3px 6px rgba(28, 38, 119, 0.08);
}

.new_mprofile_main_edit_info_item_dob{
    margin-top: 22px;
}

.new_mprofile_main_edit_info_item_dob_row{
    display: grid;
    grid-template-columns: 1fr 26% 1fr;
    grid-gap: 15px;
    width: 100%;
}

.new_mprofile_main_edit_info_item_dob_mm,
.new_mprofile_main_edit_info_item_dob_dd,
.new_mprofile_main_edit_info_item_dob_yy{
    height: 50px;
}

.new_mprofile_main_edit_info_item_dob_row .like_select_head{
    height: 50px;
    line-height: 50px;
    font-size: 18px;
}

.c3_profile_edit_manage_password_btn{
    width: 270px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 16px;
    color: $primaryColor;
    border: 2px solid $primaryColor;
    border-radius: 5px;
    cursor: pointer;
}

.c3_profile_edit_manage_password_btn.verify.in_progress{
    border-color: #3664A1;
    color: #3664A1;
    cursor: default;
    pointer-events: none;
}

.c3_profile_edit_manage_password_btn.verify.in_progress:hover{
    box-shadow: none;
}

.c3_profile_edit_manage_password_btn.hidden{
    visibility: hidden;
}

.c3_profile_edit_manage_password_btn span.mob{
    display: none;
}

//.c3_profile_edit_manage_password_btn + .c3_profile_edit_manage_password_btn{
//    margin-top: 22px;
//}

.new_mprofile_main_edit_info_top_right{
    padding-top: 27px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.new_mprofile_main_edit_info_list_sel .fill_data_form_select_item_wrap{
    margin-bottom: 0;
    width: 100%;
}

.new_mprofile_main_edit_info_list_sel .fill_data_form_select_item{
    height: 50px;
}

.new_mprofile_main_edit_info_list_sel{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 15px;
    width: 100%;
    margin-top: 22px;
}

.new_mprofile_main_edit_info_list_sel .like_select_head{
    height: 50px;
    line-height: 50px;
    font-size: 18px;
}

.new_mprofile_main_edit_save_btn{
    width: 229px;
    height: 42px;
    background: linear-gradient(90.68deg, $accentLeftColor 0%, $accentRightColor 100%);
    border-radius: 4px;
    font-weight: 600;
    font-size: 18px;
    letter-spacing: 0.01em;
    color: $whiteColor;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 26px auto 0;
    cursor: pointer;
}

.new_mprofile_main_edit_save_btn:hover{
    box-shadow: $searchItemBtnShadowHover;
}

.new_mprofile_main_edit_save_btn img{
    width: 18px;
    margin-right: 16px;
    margin-bottom: 2px;
}

.new_mprofile_block_edit_save_btn{
    width: 229px;
    height: 42px;
    background: linear-gradient(90.68deg, $accentLeftColor 0%, $accentRightColor 100%);
    border-radius: 4px;
    font-weight: 600;
    font-size: 18px;
    letter-spacing: 0.01em;
    color: $whiteColor;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 26px auto 0;
    cursor: pointer;
    position: absolute;
    right: 0;
    bottom: 26px;
}

.new_mprofile_block_edit_save_btn:hover{
    box-shadow: $searchItemBtnShadowHover;
}

.new_mprofile_block_edit_save_btn img{
    width: 18px;
    margin-right: 16px;
    margin-bottom: 2px;
}

.new_mprofile_block_edit_save_btn.photos{
    position: static;
    margin: 26px auto 0;
}

@media screen and (max-width: 1799px){
    .new_mprofile_main_edit_info_top_left {
        width: calc(100% - 300px);
    }
}

@media screen and (max-width: 1599px){
    .new_mprofile_main_edit_info_top_left {
        width: calc(100% - 250px);
    }

    .new_mprofile_main_edit_info_list_sel .fill_data_form_select_item{
        height: 40px;
    }

    .new_mprofile_block_head{
        height: 60px;
        padding-left: 16px;
    }
    .new_mprofile_block_head_title{
        font-size: 16px;
        background-size: 21px;
        padding-left: 41px;
    }
    .new_mprofile_block_head_info{
        height: 33px;
        line-height: 33px;
        font-size: 14px;
    }

    .new_mprofile_edit_btn{
        font-size: 14px;
        background-size: 14px;
        padding-left: 23px;
    }

    .new_mprofile_block_content_title{
        font-size: 16px;
    }

    .new_mprofile_block_summary_text p{
        font-size: 14px;
    }

    .new_mprofile_block_summary_text_empty{
        font-size: 14px;
    }

    .new_mprofile_block_head_photos{
        height: 60px;
        padding: 0 16px;
    }

    .scrollbar-item{
        height: 160px;
    }

    .new_mprofile_main_credits{
        width: 188px;
        height: 93px;
    }

    .new_mprofile_main_credits_img{
        margin-right: 14px;
    }

    .new_mprofile_main_credits_text{
        font-size: 14px;
    }

    .new_mprofile_main_credits_value{
        font-size: 20px;
    }

    .new_mprofile_main_credits_getmore_link{
        font-size: 12px;
    }

    .new_mprofile_main_avatar{
        height: 230px;
        margin-right: 18px;
    }

    .new_mprofile_main_info_name{
        font-size: 24px;
        margin-bottom: 16px;
    }

    .new_mprofile_main_info_id{
        font-size: 14px;
        margin-bottom: 10px;
    }

    .new_mprofile_main_info_dob{
        font-size: 14px;
        margin-bottom: 10px;
    }

    .new_mprofile_main_info_place{
        font-size: 14px;
        margin-bottom: 16px;
    }

    .new_mprofile_main_info_email{
        font-size: 14px;
    }

    .new_mprofile_main{
        padding: 16px;
    }

    .new_mprofile_main_edit{
        padding: 16px;
    }

    .new_mprofile_main_edit_photo img{
        width: 230px;
        height: auto;
    }

    .new_mprofile_main_edit_info_title{
        font-size: 20px;
    }

    .new_mprofile_main_edit_info_id{
        font-size: 14px;
    }

    .new_mprofile_main_edit_info_item_input{
        height: 40px;
        font-size: 14px;
    }

    .c3_profile_edit_manage_password_btn{
        height: 40px;
        width: 222px;
    }

    .new_mprofile_main_edit_info_item_dob_row .like_select_head{
        height: 40px;
        line-height: 40px;
        padding-left: 10px;
        font-size: 14px;
    }

    .new_mprofile_main_edit_info_item_dob_mm,
    .new_mprofile_main_edit_info_item_dob_dd,
    .new_mprofile_main_edit_info_item_dob_yy{
        height: 40px;
    }

    .new_mprofile_main_edit_info_list_sel .like_select_head{
        height: 40px;
        line-height: 40px;
        padding-left: 10px;
        font-size: 14px;
    }

    .new_mprofile_main_edit_save_btn{
        margin-top: 16px;
    }

    .new_mprofile_edit_btn.new_mprofile_main_edit_btn{
        right: 16px;
    }

    .new_mprofile_edit_btn{
        bottom: 16px;
    }

    .new_mprofile_block_content{
        margin: 0 16px;
    }

    .new_mprofile_block.opened .new_mprofile_block_content{
        padding: 16px 0;
    }

    .new_mprofile_block_photos_wrap{
        padding: 16px;
        padding-top: 0;
    }

    .content-slider{
        padding-bottom: 0;
    }

    .new_mprofile_block_photos_edit_wrap{
        /*grid-template-columns: repeat(auto-fill, 160px);
*/
    }

    .new_mprofile_block_photos_edit_btn{
        font-size: 20px;
    }

    .new_mprofile_block_photos_edit_btn img{
        width: 40px;
        margin-bottom: 14px;
    }
}

@media screen and (max-width: 1549px){
    .new_mprofile_main_edit_photo{
        align-self: flex-start;
        margin-right: 16px;
    }

    .new_mprofile_main_edit_photo img{
        width: 250px;
        height: 250px;
    }
}

@media screen and (max-width: 1299px){
    .new_mprofile_main_info_name_verified{
        margin-top: 4px;
    }

    .new_mprofile_main_avatar{
        max-width: 345px;
    }

    .new_mprofile_main_info_name{
        font-size: 20px;
    }

    .new_mprofile_main_info_id{
        font-size: 12px;
    }

    .new_mprofile_main_info_dob{
        font-size: 12px;
    }

    .new_mprofile_main_info_email{
        font-size: 12px;
    }
}

@media screen and (max-width: 1139px){
    .new_mprofile_wrap{
        padding-top: 16px;
    }

    .new_mprofile_main_avatar{
        height: 200px;
        max-width: 270px;
    }

    .new_mprofile_block_head_info{
        padding: 0 16px;
    }

    .new_mprofile_block_jobs_list{
        margin-bottom: 30px;
    }

    .new_mprofile_block_content_list{
        grid-row-gap: 22px;
        grid-column-gap: 16px;
    }

    .content-slider{
        overflow: hidden;
    }

    .new_mprofile_block_content_item_text{
        font-size: 12px;
    }

    .mp_slider_wrap{
        width: calc(100vw - 77px);
    }
}

@media screen and (max-width: 899px){
    .new_mprofile_main_edit_info_top_left {
        width: calc(100% - 150px);
    }

    .new_mprofile_block_girls_colors_row{
        display: block;
    }

    .new_mprofile_block_girls_colors_col + .new_mprofile_block_girls_colors_col{
        margin-top: 24px;
    }

    .new_mprofile_main_edit .c3_profile_edit_manage_password_btn{
        width: 125px;
        font-size: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-right: 10px;
        padding-left: 10px;
        text-align: center;
    }
}

@media screen and (max-width: 767px){
    .new_mprofile_main{
        display: block;
    }

    .new_mprofile_main_info{
        min-height: 160px;
    }

    .new_mprofile_main_avatar{
        float: left;
    }

    .new_mprofile_main_credits{
        margin-left: 0;
        margin-top: 16px;
    }

    .new_mprofile_main_avatar{
        height: 160px;
        max-width: 200px;
    }

    .new_mprofile_main_delete_photo_btn,
    .new_mprofile_block_photos_delete_img_wrap{
        width: auto;
        left: 0;
        font-size: 13px;
    
        & img.photo_delete_icon{
            width: 17px;
            height: 17px;
            margin-right: 5px;
        }
    }

    .new_mprofile_edit_btn{
        width: 14px;
        font-size: 0;
        height: 17px;
        bottom: unset;
        top: 16px;
        right: 0;
        padding-left: 16px;
    }

    .new_mprofile_block_jobs_list_item{
        font-size: 12px;
        padding: 0 15px;
        height: unset;
        min-height: 40px;
        margin-right: 10px;
        margin-bottom: 10px;
    }

    .new_mprofile_block_jobs_list_item img{
        width: 32px;
        height: 32px;
    }

    .new_mprofile_block_content_title{
        font-size: 14px;
    }

    .new_mprofile_block_head_title{
        font-size: 14px;
        padding-left: 34px;
    }

    .new_mprofile_block_head{
        height: 46px;
    }

    .new_mprofile_block_head_info{
        height: 28px;
        line-height: 28px;
        font-size: 11px;
    }

    .new_mprofile_block_jobs_list_item_not_spec{
        font-size: 12px;
        height: 36px;
        background-size: 21px;
        padding-left: 38px;
        width: auto;
        padding-right: 15px;
    }

    .new_mprofile_block_content_item_text{
        font-size: 12px;
        height: 36px;
    }

    .new_mprofile_block_content_item_title{
        font-size: 12px;
    }

    .scrollbar-item{
        height: 120px;
        display: block;
    }

    .slider-btns .arrow-btn{
        width: 20px;
    }

    .new_mprofile_block_jobs_list_item_edit_type_in input{
        width: 180px;
        height: 36px;
        font-size: 12px;
    }

    .new_mprofile_block_jobs_list_item_edit_type_in_btn{
        width: 21px;
        height: 21px;
        background-size: contain;
    }

    .new_mprofile_block_content_list .like_select_head{
        height: 36px;
        line-height: 36px;
        font-size: 12px;
        padding-left: 10px;
    }

    .new_mprofile_block_edit_save_btn{
        bottom: 16px;
        height: 36px;
        font-size: 14px;
    }

    .new_mprofile_block_hobbies_list{
        align-items: flex-start;
    }

    .new_mprofile_block_girls_colors_item{
        height: 36px;
        line-height: 36px;
        font-size: 12px;
        padding-right: 10px;
        margin-right: 10px;
    }

    .new_mprofile_block_girls_colors_item_color{
        width: 21px;
        height: 21px;
    }

    .new_mprofile_main_credits_getmore_link{
        display: none;
    }

    .new_mprofile_main_credits{
        flex-direction: row;
        justify-content: space-between;
        padding: 0 15px;
    }

    .new_mprofile_main_credits_getmore_btn{
        display: flex;
    }

    .new_mprofile_main_credits{
        width: 100%;
        height: 67px;
        margin-left: auto;
        margin-right: auto;
    }

    .new_mprofile_main_credits_text{
        font-size: 12px;
    }

    .new_mprofile_main_credits_value{
        font-size: 18px;
    }

    .new_mprofile_main_credits_img{
        width: 25px;
        margin-right: 9px;
    }

    .new_mprofile_wrap{
        padding: 8px 5px 8px 8px;
        margin-right: 3px;
    }

    .new_mprofile_block_photos_edit_wrap{
        padding: 0 16px;
        /*grid-template-columns: repeat(auto-fill, 124px);
*/
    }

    .new_mprofile_block_head_info{
        flex: none;
        margin-right: -2px;
    }

    .new_mprofile_main_edit_photo img{
        width: 160px;
        height: 160px;
    }

    .new_mprofile_main_edit_photo_btn{
        font-size: 14px;
    }

    .new_mprofile_main_edit_info_item_input{
        height: 36px;
        padding: 0 8px;
        font-size: 12px;
    }

    .new_mprofile_main_edit_info_item_dob_mm,
    .new_mprofile_main_edit_info_item_dob_dd,
    .new_mprofile_main_edit_info_item_dob_yy{
        height: 36px;
    }

    .new_mprofile_main_edit_info_item_dob_row .like_select_head{
        height: 36px;
        line-height: 36px;
        font-size: 12px;
        padding: 0 8px;
    }

    .new_mprofile_main_edit_info_list_sel .like_select_head{
        height: 36px;
        line-height: 36px;
        font-size: 12px;
        padding: 0 8px;
    }

    .new_mprofile_main_edit_save_btn{
        height: 36px;
        font-size: 14px;
    }

    .new_mprofile_block_head_photos{
        height: 46px;
        padding: 0 9px;
    }

    .mp_slider_wrap {
        width: calc(100vw - 52px);
    }
}

@media screen and (max-width: 699px){
    //.new_mprofile_main_edit_info_top_left {
    //    width: calc(50% + 20px);
    //}
}

@media screen and (max-width: 599px){
    .new_mprofile_block_content_list{
        grid-template-columns: 1fr;
        grid-gap: 20px;
    }

    .c3_profile_edit_manage_password_btn{
        width: max-content;
    }

    .new_mprofile_main_edit .c3_profile_edit_manage_password_btn.verify{
        width: 100%;
    }

    .c3_profile_edit_manage_password_btn.hidden{
        display: none;
    }

    .c3_profile_edit_manage_password_btn span.mob{
        display: block;
    }

    .c3_profile_edit_manage_password_btn span.pc{
        display: none;
    }

    .new_mprofile_block_head_title{
        font-size: 13px;
        background-size: 18px;
        padding-right: 0;
    }

    .new_mprofile_block_content_title{
        font-size: 13px;
    }

    .new_mprofile_main_avatar{
        width: 125px;
        height: 103px;
    }

    .new_mprofile_main_info_name{
        font-size: 18px;
        margin-bottom: 10px;
        display: block;
    }

    .new_mprofile_main_info_name_verified{
        font-size: 10px;
        margin-left: 0;
        margin-top: 2px;
    }

    .new_mprofile_main_info_name_verified img{
        width: 12px;
        margin-bottom: 0;
    }

    .new_mprofile_main_info_id{
        font-size: 11px;
        margin-bottom: 7px;
    }

    .new_mprofile_main_info_dob{
        font-size: 11px;
        margin-bottom: 7px;
    }

    .new_mprofile_main_info_dob span{
        font-size: 9px;
        display: inline-block;
    }

    .new_mprofile_main_info_place{
        font-size: 11px;
    }

    .new_mprofile_main_info{
        min-height: unset;
    }

    .new_mprofile_main_credits{
        margin-top: 13px;
    }

    .new_mprofile_main{
        /*padding: 9px;*/
        margin-bottom: 5px;
        box-shadow: 0px 3px 8px rgba(47, 43, 99, 0.16);
    }

    .new_mprofile_block{
        margin-bottom: 5px;
    }

    .new_mprofile_block_content_item_text{
        padding: 0 8px;
    }

    .new_mprofile_edit_btn.new_mprofile_main_edit_btn{
        right: 9px;
        top: 13px;
    }

    .new_mprofile_edit_btn{
        bottom: unset;
        top: -37px;
        width: 30px;
        height: 30px;
        padding: 0;
        background-position: center;
    }

    .new_mprofile_block_head_info{
        display: none;
    }

    .new_mprofile_block_edit .new_mprofile_block_head_info{
        display: block;
    }

    .new_mprofile_main_edit_info_title_row{
        display: none;
    }

    .new_mprofile_main_edit_wrap{
        display: block;
    }

    .new_mprofile_main_edit_photo{
        float: left;
        margin-right: 7px;
    }

    .new_mprofile_main_edit_info_top{
        display: block;
    }

    .new_mprofile_main_edit_info_top_right{
        max-width: 170px;
        height: 123px;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        padding-top: 0;
        margin-bottom: 16px;
    }

    .c3_profile_edit_manage_password_btn + .c3_profile_edit_manage_password_btn{
        margin-top: 7px;
    }

    .new_mprofile_main_edit_photo img{
        width: 112px;
        height: 123px;
    }

    .new_mprofile_main_edit{
        padding: 7px;
    }

    .new_mprofile_main_edit_info_top_left{
        width: 100%;
    }

    .new_mprofile_main_edit_info_list_sel{
        grid-template-columns: 1fr;
        grid-gap: 12px;
    }

    .new_mprofile_main_edit .c3_profile_edit_manage_password_btn img{
        margin-bottom: 0;
        margin-right: 6px;
        height: 15px;
    }

    .new_mprofile_block_edit_save_btn{
        width: 100%;
        margin-top: 16px;
        position: static;
    }

    .new_mprofile_main_edit .c3_profile_edit_manage_password_btn{
        width: max-content;
        margin: 0;
        padding-left: 5px;
        padding-right: 5px;
    }

    .new_mprofile_main_edit .c3_profile_edit_manage_password_btn span{
        width: auto;
    }

    .new_mprofile_block_head_info{
        padding: 0 8px;
    }

    .new_mprofile_block_edit_save_btn.photos{
        width: calc(100% - 18px);
        margin-left: 9px;
        margin-right: 9px;
    }

    .new_mprofile_block_jobs_list_item{
        /*font-size: 10px;
*/
        padding: 5px 15px;
    }

    .new_mprofile_block_summary_text_edit textarea{
        font-size: 12px;
    }

    .new_mprofile_block_photos_edit_wrap{
        grid-template-columns: repeat(3, 1fr);
    }

    .new_mprofile_block_photos_edit_btn{
        font-size: 14px;
    }

    .new_mprofile_block_photos_edit_btn img{
        width: 30px;
        margin-bottom: 10px;
    }
}

@media screen and (max-width: 360px){

    .new_mprofile_block_head_info span{
        display: none;
    }

    .new_mprofile_block_head_title{
        padding-left: 30px;
    }

    .new_mprofile_block_head_info,
    .new_mprofile_block_edit .new_mprofile_block_head_info{
        display: none;
    }

    .new_mprofile_edit_btn{
        background-size: 14px;
        top: -38px;
    }

    .new_mprofile_block_girls_colors_item{
        margin-bottom: 6px;
        margin-right: 6px;
    }

    .new_mprofile_block_jobs_list_item{
        margin-bottom: 6px;
        margin-right: 6px;
    }

    .new_mprofile_block_photos_edit_wrap{
        grid-template-columns: repeat(2, 1fr);
    }
}





































