@import "@sitestyles";

.mob_menu_page {
  display: flex;
  flex-direction: column;
  height: 100%;

  @media screen and (max-width: 899px) and (max-height: 450px) and (min-width: 420px) {
    flex-direction: row;
  }

  @media (hover: none) and (pointer: coarse) and (orientation: landscape) and (min-width: 900px) {
    flex-direction: row;
    align-items: flex-start;
  }
}

.user_info_avatar {
  display: inline-block;
  width: 56px;
  height: 56px;
  padding-left: 2px;
  padding-top: 2px;
  line-height: 50px;
  border-radius: 60px;
  text-align: center;
  color: white;
  font-size: 20px;
}

.mob_menu_page_head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 0;
  margin: 0 auto;
  border-bottom: 1px solid $defaultTextColorAlpha2;
  width: 100%;
  max-width: calc(100% - 30px);

  @media screen and (max-width: 899px) and (max-height: 450px) and (min-width: 420px) {
    display: block;
    margin: 22px 0;
    padding: 0 11px;
    border-bottom: none;
    border-right: 1px solid $defaultTextColorAlpha2;
    width: auto;
  }

  @media (hover: none) and (pointer: coarse) and (min-width: 768px) {
    width: auto;
  }

  @media (hover: none) and (pointer: coarse) and (orientation: portrait) and (min-width: 768px) {
    margin: 0 24px;
    padding: 26px 0;
    width: auto;
  }

  @media (hover: none) and (pointer: coarse) and (orientation: landscape) and (min-width: 900px) {
    display: block;
    margin: 24px 0 0;
    padding: 0 22px;
    border-bottom: none;
    border-right: 1px solid $defaultTextColorAlpha2;
  }
}

.mob_menu_page_head_photo {
  width: 125px;
  height: 125px;
  object-fit: cover;
  border-radius: 9px;
  flex: none;

  @media screen and (max-width: 899px) and (max-height: 450px) and (min-width: 420px) {
    width: 226px;
    height: 186px;
  }

  @media (hover: none) and (pointer: coarse) and (orientation: portrait) and (min-width: 768px) {
    width: 300px;
    height: 300px;
  }

  @media (hover: none) and (pointer: coarse) and (orientation: portrait) and (min-width: 900px) {
    width: 412px;
    height: 339px;
  }

  @media (hover: none) and (pointer: coarse) and (orientation: portrait) and (min-width: 600px) {
    width: 300px;
    height: 300px;
  }

  @media (hover: none) and (pointer: coarse) and (orientation: landscape) and (min-width: 900px) {
    width: 299px;
    height: 246px;
  }
}

.mob_menu_page_head_right {
  margin-left: 15px;
  flex: 1;

  @media screen and (max-width: 899px) and (max-height: 450px) and (min-width: 420px) {
    margin-left: 0;
    margin-top: 12px;
  }

  @media (hover: none) and (pointer: coarse) and (min-width: 768px) {
    display: flex;
    flex-direction: column;
  }

  @media (hover: none) and (pointer: coarse) and (orientation: portrait) and (min-width: 768px) {
    margin-left: 34px;
    width: 100%;
    overflow: hidden;
  }

  @media (hover: none) and (pointer: coarse) and (orientation: landscape) and (min-width: 900px) {
    margin-left: 0;
    margin-top: 30px;
  }
}

.mob_menu_page_head_profile_btn {
  width: 100%;
  line-height: 30px;
  background-color: $whiteColor;
  border: 1px solid $primaryColor;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 12px;
  gap: 8px;
  letter-spacing: -0.03em;
  color: $primaryColor;

  svg {
    width: 20px;
    height: 20px;
    fill: $primaryColor;
    transition: .3s;
  }

  &:hover,
  &:focus {
    color: $whiteColor;
    background-color: $primaryColor;

    svg{
      fill: $whiteColor;
    }
  }

  @media (hover: none) and (pointer: coarse) and (min-width: 768px) {
    height: 44px;
    font-size: 18px;
  }
}


.mob_menu_page_head_add_credits_btn {
  width: 100%;
  height: 36px;
  background: $backgroundButtonColor;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  color: $whiteColor;
  letter-spacing: -0.03em;
  font-weight: 60;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  transition-duration: .3s;

  svg {
    width: 20px;
    height: 20px;
    fill: $whiteColor;
    transition: .3s;
  }

  &:hover,
  &:focus {
    color: $addCreditsHoverColor;
    background-image: none;
    background-color: $addCreditsBackgroundColor;
    border: 1px solid $addCreditsHoverColor;

    svg{
      fill: $addCreditsHoverColor;
    }
  }

  @media (hover: none) and (pointer: coarse) and (min-width: 768px) {
    height: 58px;
    font-size: 24px;
  }

  @media (hover: none) and (pointer: coarse) and (orientation: landscape) and (min-width: 900px) {
    height: 52px;
    font-size: 18px;
  }
}


.mob_menu_page_list_wrap {
  flex: 1;
  overflow-y: auto;
}

.mob_menu_page_list {
  padding: 12px 13px;
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 6px;

  @media screen and (max-width: 899px) and (max-height: 450px) and (min-width: 420px) {
    padding: 12px 11px;
  }

  @media (hover: none) and (pointer: coarse) and (orientation: portrait) and (min-width: 768px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 15px;
    grid-column-gap: 30px;
    padding: 26px 24px;
  }

  @media (hover: none) and (pointer: coarse) and (orientation: landscape) and (min-width: 900px) {
    padding: 24px 26px;
  }
}

.mob_menu_page_list_item_button {
  border: none;
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 8px;
  font-weight: 600;
  color: $smallActiveButtonColor;
  font-size: 14px;
  font-family: inherit;
  width: 100%;
  height: 54px;
  max-width: 320px;
  margin: 0 auto;
  background-color: $whiteColor;
  box-shadow: 0 3px 5px rgba(47, 43, 99, 0.16);
  border-radius: 10px;
  padding-left: 22px;
  position: relative;
  transition: .3s;

  svg {
    width: 24px;
    height: 24px;
    fill: $smallActiveButtonColor;
    transition: .3s;
  }

  &:hover,
  &:focus {
    color: $whiteColor;
    background-color: $smallActiveButtonColor;

    span{
      color: $whiteColor;
    }

    svg{
      fill: $whiteColor;
    }
  }

  @media (hover: none) and (pointer: coarse) and (min-width: 768px) {
    max-width: unset;
    height: 74px;
    font-size: 18px;
  }

  @media (hover: none) and (pointer: coarse) and (orientation: landscape) and (min-width: 900px) {
    margin-bottom: 15px;
  }
}

.mob_menu_page_list_item:first-child {
  @media (hover: none) and (pointer: coarse) and (min-width: 768px) {
    grid-column-start: 1;
    grid-column-end: 3;
    justify-content: space-between;
  }
}

.mob_menu_page_head_profile_info_name_wrap {
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 28px;

  @media (hover: none) and (pointer: coarse) and (orientation: landscape) and (min-width: 900px) {
    margin-bottom: 10px;
  }
}

.mob_menu_page_head_profile_info_name {
  font-weight: 600;
  font-size: 30px;
  color: $accentLeftColor;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.mob_menu_page_head_profile_info_id {
  font-size: 14px;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  color: $defaultTextColor;
}

.mob_menu_page_head_profile_info_dob {
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 0.06em;
  color: $primaryColor;
  margin-bottom: 15px;

  @media (hover: none) and (pointer: coarse) and (orientation: landscape) and (min-width: 900px) {
    font-size: 12px;
    margin-bottom: 12px;
  }
}

.mob_menu_page_head_profile_info_dob span {
  font-weight: 400;
}

.mob_menu_page_head_profile_info_place {
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0.06em;
  text-transform: capitalize;
  color: $primaryColor;
  display: flex;
  align-items: center;
  margin-bottom: 18px;
}

.mob_menu_page_head_profile_info_place img {
  height: 18px;
  margin-right: 9px;
}

.mob_menu_page_head_profile_info {
  display: none;

  @media (hover: none) and (pointer: coarse) and (min-width: 768px) {
    display: block;
  }

  @media (hover: none) and (pointer: coarse) and (orientation: portrait) and (min-width: 768px) {
    margin: auto 0;
  }

  @media screen and (max-width: 899px) and (max-height: 450px) and (min-width: 420px) {
    display: none;
  }
}

.mob_menu_page_list_item_text {
  display: none;

  @media (hover: none) and (pointer: coarse) and (min-width: 768px) {
    display: block;
    font-weight: 500;
    font-size: 12px;
    line-height: 140%;
    text-align: right;
    color: rgba(51, 51, 51, 0.8);
    position: absolute;
    right: 17px;
    top: 50%;
    transform: translateY(-50%);
  }

  @media (hover: none) and (pointer: coarse) and (orientation: landscape) and (min-width: 900px) {
    display: none;
  }
}

.mob_menu_page_head_buttons {
  display: flex;
  flex-direction: column;
  gap: 12px;

  @media (hover: none) and (pointer: coarse) and (orientation: portrait) and (min-width: 768px) {
    margin-top: auto;
  }
}
