@import "@sitestyles";

.rhap_container {
	background-color: white;
	border-radius: 40px;
	display: flex;
	width: 100%;
	font-size: 15px;
	box-shadow: 0px 6px 12px rgba(47, 43, 99, 0.16);
	position: relative;
	height: 62px;
}

.message_audio_wrap{
	width: 300px;
}

.chat_message_wrap.right .message_audio_wrap{
}

.rhap_container,
.rhap_container *{
	outline: none;
}
.rhap_container:focus:not(:focus-visible) {
	outline: 0;
}

.rhap_header {
	margin-bottom: 10px;
}

.rhap_footer {
	margin-top: 5px;
}

.rhap_main {
	display: flex;
	flex-direction: column;
	flex: 1 1 auto;
}

.message_audio_wrap .rhap_controls-section{
	padding-bottom: 2px;
}

.rhap_stacked .rhap_controls-section {
}

.rhap_horizontal {
	flex-direction: row;
}
.rhap_horizontal .rhap_controls-section {
	margin-left: 8px;
}

.rhap_horizontal-reverse {
	flex-direction: row-reverse;
}
.rhap_horizontal-reverse .rhap_controls-section {
	margin-right: 8px;
}

.rhap_stacked-reverse {
	flex-direction: column-reverse;
}
.rhap_stacked-reverse .rhap_controls-section {
	margin-bottom: 8px;
}

.rhap_progress-section {
	display: flex;
	width: 210px;
	height: 62px;
	align-items: center;
	left: 39px;
	position: absolute;
}

.rhap_progress-container {
	display: flex;
	align-items: center;
	height: 20px;
	flex: 1 0 auto;
	align-self: center;
	margin: 0 calc(10px + 1%);
	cursor: pointer;
	-webkit-user-select: none;
}
.rhap_progress-container:focus:not(:focus-visible) {
	outline: 0;
}

.rhap_time {
	color: $defaultTextColor;
	font-size: 16px;
	user-select: none;
	-webkit-user-select: none;
}

.rhap_progress-bar {
	box-sizing: border-box;
	position: relative;
	z-index: 0;
	width: 100%;
	height: 5px;
	background-color: $familySizeCondensedSoupColor;
	border-radius: 2px;
}

.rhap_progress-filled {
	height: 100%;
	position: absolute;
	z-index: 2;
	background-color:  $primaryLiteColor;
	border-radius: 2px;
}

.rhap_progress-bar-show-download {
	background-color: $defaultTextColorAlpha2;
}

.rhap_download-progress {
	height: 100%;
	position: absolute;
	z-index: 1;
	background-color: $familySizeCondensedSoupColor;
	border-radius: 2px;
	max-width: 100%;
}

.rhap_progress-indicator {
	box-sizing: border-box;
	position: absolute;
	z-index: 3;
	width: 20px;
	height: 20px;
	margin-left: -10px;
	top: -8px;
	background:  $primaryLiteColor;
	border-radius: 50px;
	box-shadow: rgba(134, 134, 134, 0.5) 0 0 5px;
	display: none;
}

.rhap_controls-section {
	display: flex;
	flex: 1 1 auto;
	justify-content: space-between;
	align-items: center;

}

.rhap_additional-controls {
	display: none;
}

.rhap_repeat-button {
	font-size: 26px;
	width: 26px;
	height: 26px;
	color: $arsenicColor;
	margin-right: 6px;
}

.rhap_main-controls {
	display: flex;
	position: relative;
}

.rhap_main-controls-button {
	margin: 0 3px;
	color: $arsenicColor;
	font-size: 35px;
	width: 35px;
	height: 35px;
}

.rhap_play-pause-button {
	font-size: 40px;
	width: 40px;
	height: 40px;
	line-height: 40px;
	margin: 0;
	display: flex;
	justify-content: center;
	align-items: center;
}

.rhap_play-pause-button img{
	vertical-align: middle;
}

.upload_popup_wrap .rhap_play-pause-button img{
	margin-bottom: 0;
	vertical-align: top;
}

.rhap_volume-controls {
	display: flex;
	width: 26px;
	position: relative;
	right: 13px;
}

.rhap_volume-button {
	flex: 0 0 26px;
	font-size: 26px;
	width: 26px;
	height: 26px;
	color: $arsenicColor;
	margin-right: 6px;
	line-height: 26px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.rhap_volume-button img{
	vertical-align: middle;
	width: 26px;
}

.rhap_volume-container {
	display: flex;
	align-items: center;
	flex: 0 1 100px;
	-webkit-user-select: none;
}

.rhap_volume-container:hover .rhap_volume-bar-area{
	/*display: flex;
*/
}

.rhap_progress-indicator{
	color: $primaryLiteColor;
}

.rhap_volume-bar-area {
	display: none;
	align-items: center;
	width: 100%;
	height: 14px;
	cursor: pointer;
}
.rhap_volume-bar-area:focus:not(:focus-visible) {
	outline: 0;
}

.rhap_volume-bar {
	box-sizing: border-box;
	position: relative;
	width: 100%;
	height: 4px;
	background:  $primaryLiteColor;
	border-radius: 2px;
}


.rhap_volume-indicator {
	box-sizing: border-box;
	position: absolute;
	width: 12px;
	height: 12px;
	margin-left: -6px;
	left: 0;
	top: -4px;
	background: $primaryLiteColor;
	opacity: 0.9;
	border-radius: 50px;
	box-shadow: rgba(134, 134, 134, 0.5) 0 0 3px;
	cursor: pointer;
}
.rhap_volume-indicator:hover {
	opacity: 0.9;
}

/* Utils */
.rhap_button-clear {
	background-color: transparent;
	border: none;
	padding: 0;
	overflow: hidden;
	cursor: pointer;
}
.rhap_button-clear:hover {
}
.rhap_button-clear:active {
}
.rhap_button-clear:focus:not(:focus-visible) {
	outline: 0;
}
.rhap_rewind-button{
	display: none;
}
.rhap_forward-button{
	display: none;
}

.hide_volume_btn .rhap_volume-container {
	display: none;
}

.hide_volume_btn .rhap_progress-section {
	width: calc(100% - 55px);
}



