@import "@sitestyles";

.girls_slider_wrap {
  //display: flex;
  align-items: center;
  width: 100%;
  flex: none;
  overflow: hidden;
  padding-left: 48px;
  min-height: 170px;


  display: grid;
  grid-template-columns: 160px 1fr;
  grid-column-gap: 16px;
  box-sizing: border-box;
  max-width: 100%;
  position: relative;


  @media screen and (max-width: 700px) {
    padding-left: 12px;
    min-height: 64px;
    grid-template-columns: 110px 1fr;
  }
}

.girls_slider_info {
  width: 160px;
  //margin-right: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  @media screen and (max-width: 700px) {
    width: 110px;
  }
}

.girls_slider_title {
  font-weight: 600;
  font-size: 18px;
  letter-spacing: 0.06em;
  color: $unsubscribeTitleColor;

  @media screen and (max-width: 700px) {
    font-size: 12px;
    line-height: 128.9%;
  }
}

.girls_slider_text {
  font-size: 12px;
  line-height: 140.4%;
  letter-spacing: 0.06em;
  color: $unsubscribeTextColor;
  padding-right: 10px;
  margin-top: 4px;

  @media screen and (max-width: 700px) {
    font-size: 10px;
    line-height: 140.4%;
  }
}

.girls_slider_like_prev {
  width: 153px;
  height: 34px;
  border: 1px solid $primaryOrangeColor;
  border-radius: 4px;
  background-color: #fff;
  font-weight: 600;
  font-size: 12px;
  letter-spacing: 0.06em;
  color: $primaryOrangeColor;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-top: 19px;
  transition: .3s;
}

.girls_slider_like_prev:hover {
  box-shadow: $searchItemLikeBtnShadowHover;
}

.girls_slider_like_prev img {
  margin-right: 8px;
}

.girls_slider_list_wrap {
  flex: 1;
  overflow: hidden;
  position: relative;

  @media screen and (max-width: 700px) {
    z-index: 0;
  }
}

.girls_slider_list_wrap:after {
  content: '';
  display: block;
  width: 5px;
  height: 100%;
  position: absolute;
  right: -5px;
  top: 0;
  //background: linear-gradient(to left, #F4F4F4 0%, rgba(255, 255, 255, 0) 60%);
  z-index: 2;
  pointer-events: none;

  box-shadow: 2px 2px 50px 50px #FFFFFF;

}

.girls_slider_list_wrap:before {
  content: '';
  display: block;
  //width: 135px;
  width: 5px;
  height: 100%;
  position: absolute;
  //left: 0;
  left: -5px;
  top: 0;
  //background: linear-gradient(to right, #F4F4F4 0%, rgba(255, 255, 255, 0) 60%);
  z-index: 2;
  pointer-events: none;

  box-shadow: 2px 2px 50px 50px #FFFFFF;

  @media screen and (max-width: 599px) {
    display: none;
  }
}

.girls_slider_list {
  width: 100%;
}

.girls_slider_item_wrap {
  height: 200px;
  display: flex;
  align-items: center;
  padding: 0 5px 0 10px;

  @media screen and (max-width: 700px) {
    height: 64px;
    padding: 0 8px 0 0;
  }
}


.girls_slider_item {
  position: relative;
}

.girls_slider_item_status {
  width: 49px;
  height: 18px;
  background: $likeSliderOnlineBg;
  border-radius: 3px;
  position: absolute;
  top: 3px;
  right: -3px;
  color: $likeSliderOnlineColor;
  font-size: 10px;
  line-height: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: $mainFont;

  @media screen and (max-width: 700px) {
    bottom: 2px;
    right: -2px;
    top: unset;
    width: 36px;
    height: 15px;
    font-size: 9px;
    line-height: 11px;
  }
}

.girls_slider_item_photo {
  width: 120px;
  height: 120px;
  object-fit: cover;
  object-position: top;
  border-radius: 10px;
  display: block;
  transition: .3s;
  cursor: pointer;

  @media screen and (max-width: 700px) {
    width: 64px;
    height: 64px;
  }
}

@media screen and (min-width: 700px) {
  .girls_slider_item:hover {
    box-shadow: 0 6px 12px rgba(47, 43, 99, 0.4);
  }

  .girls_slider_item:hover .girls_slider_item_photo {
    width: 170px;
    height: 170px;
  }
}

.girls_slider_item_bg {
  background: linear-gradient(0deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
  width: 100%;
  height: 170px;
  position: absolute;
  left: 0;
  bottom: 0;
  border-radius: 0 0 9px 9px;
  opacity: 0;
  transition: opacity .3s;
  pointer-events: none;
  @media screen and (max-width: 700px) {
    height: 64px;
  }
}

@media screen and (min-width: 700px) {
  .girls_slider_item:hover .girls_slider_item_bg {
    opacity: 1;
  }
}

.girls_slider_item_buttons {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 10px;
  padding: 0 5px;
  opacity: 0;
  transition: opacity .3s;
  @media screen and (max-width: 700px) {
    display: none;
  }
}

.girls_slider_item:hover .girls_slider_item_buttons {
  opacity: 1;
}

.girls_slider_item_btn {
  width: 100%;
  height: 33px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.03em;
  transition: .3s;
  cursor: pointer;
  font-family: $mainFont;
  background-clip: padding-box, border-box;
  background-origin: border-box;
  border: double 1px transparent;
  background-image: $buttonBackgroundColor;

  &:hover {
    background-image: linear-gradient(white, white), $buttonBackgroundColor;

    .girls_slider_item_btn_text {
      background: $buttonBackgroundColor;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
      font-weight: 600;

    }
  }
}

.girls_slider_item_btn_text {
  background: #FFFFFF;
  -webkit-background-clip: text;
  -webkit-text-fill-color: #FFFFFF;
  background-clip: text;
  text-fill-color: #FFFFFF;
  font-weight: 600;
  font-family: $mainFont;
}

.girls_slider_item_like_btn {
  background-color: #fff;
  border: 1px solid $primaryOrangeColor;
  color: $primaryOrangeColor;
}

.girls_slider_item_like_btn:hover {
  box-shadow: $searchItemLikeBtnShadowHover;
}

.girls_slider_item_like_btn.active {
  background-color: $bluePinkColor;
  border: 1px solid $bluePinkColor;
  color: #fff;
  cursor: default;
}

.girls_slider_item_like_btn.active:hover {
  box-shadow: none;
}

.girls_slider_btn_buttons {
  position: absolute;
  right: 24px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  z-index: 3;

  @media screen and (max-width: 700px) {
    display: none;
  }
}

.girls_slider_btn {
  z-index: 3;
  width: 40px;
  height: 40px;
  background-color: rgba(255, 255, 255, 1);
  border: 1px solid #ECECEC;
  border-radius: 100%;
  background-repeat: no-repeat;
  cursor: pointer;
  transition: background-color .3s;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);

  //@media screen and (max-width: 1139px) {
  //  top: 57.5%;
  //}
  @media screen and (max-width: 700px) {
    display: none;
  }
}

.girls_slider_btn:hover {
  background-color: #fff;
}

.girls_slider_prev_btn {
  background-image: url(../../../img/girls-slider-prev.svg);
  background-position: calc(55% - 2px) center;
  left: 210px;
}

.girls_slider_next_btn {
  background-image: url(../../../img/girls-slider-next.svg);
  background-position: calc(50% + 1px) center;
  right: 0;
}

.girls_slider_item_mob_click {
  display: none;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.girls_slider_item_placeholder {
  animation-duration: 1.8s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: linear-gradient(90deg, rgba(189, 195, 199, .5) 0%, rgba(44, 62, 80, .3) 51.04%, rgba(189, 195, 199, .5) 100%);
  background-size: 1000px 640px;
  position: relative;
  border-radius: 10px;
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0
  }
  100% {
    background-position: 468px 0;
  }
}
