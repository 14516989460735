@import "@sitestyles";

/*HISTORY-MEDIA*/

@media screen and (max-width: 1920px){
	.column-3 .head-links{
		padding-right: 340px;
		max-width: 100%;
		padding-left: 225px;
	}
}
@media screen and (max-width: 1800px){
	.column-3 .head-links{
		max-width: 930px;
		padding-left: 0;
		padding-right: 100px;
		left: 0;
		right: 0;
		margin: auto;
	}
	.column-3 .head-links a{
		flex-direction: column;
		justify-content: center;
		padding-top: 7px;
		padding-bottom: 7px;
		padding:  7px 2px;
		max-width: 93px;
		text-align: center;
		padding-left: 0;
		min-height: 89px;
		/* align-content: center;
 */
	}
	.column-3 .head-links h5{
		font-size: 14px;
	}
	.column-3 .head-links img{
		margin-right: 0;
		margin-bottom: 5px;
	}
}

@media screen and (max-width: 1399px){
	.c4_item_photo{
	}
	.c4_item_name{
		font-size: 14px;
	}
	.c4_item_photo_wrap{
		margin-right: 10px;
	}
	.message .c4_item_text{
		width: 105px;
	}
	.column-3 .head-links{
		max-width: 760px;
		padding-right: 30px;
	}
}

@media screen and (max-width: 1199px){
	.column-3 .pages{
		position: fixed;
		left: 10px;
		top: 100px;
		z-index: 80;
		background: $whiteColor;
		box-shadow: 0px 6px 12px rgba(47, 43, 99, 0.16);
		border-radius: 10px;
		width: 60px;
		max-width: 100%;
		min-height: 36px;
	}
	.column-3 .head-links{
		top: 0;
		width: 198px;
		max-width: 100%;
		left: auto;
		right: 0;
		position: fixed;
		display: flex;
		flex-direction: column;
		padding: 0;
		transition: 0.5s;
		transform: translate(100%, 0);
		padding-top: 10px;
		margin: 0;
		z-index: 100;
	}
	.column-3 .head-links .c1_close{
		display: none;
		width: 30px;
		height: 30px;
		background-image: url(/img/cross_white.svg);
		-webkit-background-size: 26px 26px;
		background-size: 20px 20px;
		background-position: center;
		position: absolute;
		left: -40px;
		top: 10px;
		z-index: 100;
		background-repeat: no-repeat;
		cursor: pointer;
		visibility: hidden;
	}
	.column-3 .head-links a{
		flex-direction: row;
		padding-left: 10px;
		min-height: auto;
		max-width: 170px;
		justify-content: flex-start;
	}
	.column-3 .head-links img{
		margin-right: 7px;
		margin-bottom: 0;
	}
	.column-3 .head-links h5{
		text-align: left;
	}
	.column-3 .head-links-open {
		transform: translate(0, 0);
	}
	.column-3 .pagesOpenActive {
		left: 200px;
	}
	.pagesOpen img{
		position: relative;
		z-index: 100;
	}
	.scrollActive {
		overflow-y: hidden;
	}
}

@media screen and (max-width: 789px){
	.column-3 .head-links{
		margin: 0 auto;
	}
}

@media screen and (max-width: 599px){
	.column-3 .head-links {
		justify-content: space-around;
	}
	.column-3 .head-links a{
		max-width: 140px;
	}
	.column-3 .head-links h5{
		font-size: 14px;
	}
}
