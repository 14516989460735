@import "@sitestyles";

.tutorial_step_illustration {
  width: 100%;
  height: 262px;
  margin-bottom: 56px;
  background: $tutorialPrimaryIllustrationBackground;
  border: 1px solid $tutorialPrimaryBorderColor;
  border-radius: 5px;
  overflow: hidden;
  position: relative;

  @media screen and (max-width: 540px) {
    height: 190px;
    border-radius: 10px;
    background: $tutorialPrimaryBackground;
  }

  &:before {
    content: '';
    display: block;
    background: $tutorialPrimaryIllustrationBackground;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 5px;
    z-index: 10;

    @media screen and (max-width: 540px) {
      display: none;
    }
  }
}

.tutorial_body {
  display: grid;
  grid-row-gap: 16px;
  margin-bottom: 48px;
}

.tutorial_step_title {
  color: $accentDarkColor;
  text-align: center;
  font-size: 20px;
  font-weight: 700;
}

.tutorial_step_text {
  color: $tutorialStepTextColor;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  font-family: $mainFont;
}

.step_profile {
  display: grid;
  grid-template-columns: 190px 1fr;
}

.step_profile_body {
  background: $tutorialSecondaryBackground;
}

.step_profile_menu {
  background: $tutorialPrimaryBackground;
  width: 190px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 6px 12px 0 rgba(41, 40, 48, 0.16);
  box-sizing: border-box;
  position: relative;
}

.step_profile_logo_wrap {
  height: 72px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.step_profile_logo {
  max-height: 46px;
  width: auto;
}

.step_profile_logo_large{
  max-height: 50px;
  max-width: 110px;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.step_profile_card {
  border-radius: 5px;
  background: $tutorialPopupBackground;
  box-shadow: 0 3px 5px 0 rgba(36, 0, 34, 0.10);
  display: grid;
  grid-column-gap: 8px;
  grid-template-columns: 52px 1fr;
  padding: 10px 14px;
  align-items: center;
  margin-bottom: 8px;
  position: relative;
  z-index: 11;

  @media screen and (max-width: 540px) {
    margin: 0 auto;
    padding: 0;
    background: transparent;
    box-shadow: unset;
    grid-template-columns: 125px 1fr;
    grid-column-gap: 12px;
  }

  @media screen and (max-width: 350px) {
    grid-template-columns: 100px 1fr;
  }
}

.step_profile_avatar {
  width: 52px;
  min-width: 52px;
  height: 52px;
  border-radius: 50%;
  border: 1px solid $accentDarkColor;
  background: $tutorialPopupBackground;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 540px) {
    border-radius: 5px;
    width: 100%;
    height: 92px;
  }

  svg {
    fill: $accentDarkColor;
    width: 18px;
    height: 18px;

    @media screen and (max-width: 540px) {
      width: 28px;
      height: 28px;
    }
  }
}

.step_profile_btns {
  display: flex;
  flex-direction: column;
  gap: 4px;

  @media screen and (max-width: 540px) {
    gap: 12px;
  }
}

.step_btn_credits {
  border-radius: 5px;
  background: $tutorialButtonBackground;
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.05);
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $tutorialButtonLightBackground;
  text-align: center;
  font-size: 12px;
  font-weight: 600;
  line-height: normal;

  @media screen and (max-width: 540px) {
    height: 40px;
    font-size: 13px;
    padding: 0 16px;
  }

  svg {
    fill: $tutorialPopupBackground;
    width: 16px;
    min-width: 16px;
    height: 16px;
    margin-right: 8px;

    @media screen and (max-width: 540px) {
      width: 20px;
      min-width: 20px;
      height: 20px;
    }
  }
}

.step_btn_profile {
  border-radius: 5px;
  border: 1px solid $accentDarkColor;
  background: $tutorialButtonLightBackground;
  height: 24px;
  color: $accentDarkColor;
  font-size: 12px;
  font-weight: 600;
  line-height: normal;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 540px) {
    height: 40px;
    font-size: 13px;
    padding: 0 16px;
  }

  svg {
    fill: $accentDarkColor;
    width: 14px;
    min-width: 14px;
    height: 14px;
    margin-right: 8px;

    @media screen and (max-width: 540px) {
      width: 20px;
      min-width: 20px;
      height: 20px;
    }
  }
}

.step_menu_btn_credits {
  border-radius: 5px;
  background: $tutorialButtonBackground;
  box-shadow: 0 3px 5px 0 rgba(36, 0, 34, 0.30);
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $tutorialButtonLightColor;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 8px;

  svg {
    fill: $tutorialPopupBackground;
    width: 24px;
    min-width: 24px;
    height: 24px;
    margin-right: 12px;
  }
}

.step_menu {
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  background: $tutorialPopupBackground;
}

.step_menu_item {
  padding: 0 8px 0 15px;
  height: 44px;
  margin: 0;
  list-style-type: none;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: $tutorialMenuItemColor;
  font-size: 16px;
  font-weight: 400;
  line-height: 140%;

  svg {
    width: 24px;
    min-width: 24px;
    height: 24px;
    fill: $tutorialMenuItemColor;
    margin-right: 10px;
  }
}

.step_profile_girls_slider {
  display: grid;
  grid-template-columns: 134px 1fr;
  grid-column-gap: 18px;
  padding-left: 24px;
  margin-left: 30px;
  align-items: center;
  height: 132px;
  margin-top: 80px;
  position: relative;
}

.girls_slider_text_wrap {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.girls_slider_title {
  color: $accentDarkColor;
  font-size: 16px;
  font-weight: 600;
  line-height: 140%;
}

.girls_slider_text {
  color: $tutorialStepTextColor;
  font-size: 12px;
  font-weight: 400;
  line-height: 140%;
}

.girls_slider_list {
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.girls_slider_list_item {
  padding: 0;
  margin: 0;
  width: 98px;
  height: 86px;
  border-radius: 10px;
  position: relative;
  list-style-type: none;
  display: flex;
  align-items: center;
  justify-content: center;

  &:before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, $tutorialSecondaryBackground 0%, rgba(244, 244, 244, 0.00) 100%);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }
}

.girls_slider_status {
  position: absolute;
  top: 6px;
  right: 3px;
  border-radius: 3px;
  background: $tutorialOnlineBackground;
  height: 18px;
  padding: 0 8px;
  color: $tutorialButtonLightColor;
  font-size: 10px;
  font-weight: 600;
  line-height: normal;
  z-index: 1;
}

.girls_slider_img {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
  border-radius: 10px;
}

.girls_slider_btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: $tutorialButtonLightBackground;
  right: 80px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 4;

  svg {
    fill: $tutorialStepTextSecondaryColor;
    opacity: 0.8;
    width: 16px;
    height: 16px;
    min-width: 20px;
  }
}

.step_profile_box {
  border-radius: 10px;
  background: $tutorialPopupBackground;
  box-shadow: 0 3px 5px 0 rgba(41, 40, 48, 0.30);
  padding: 26px 26px 20px 26px;
  margin-left: 30px;
  margin-top: 8px;
}

.desktop {
  display: grid;

  @media screen and (max-width: 540px) {
    display: none;
  }
}

.mobile {
  display: none;

  @media screen and (max-width: 540px) {
    display: flex;
  }
}
