@import "@sitestyles";

.term_popup {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  max-width: 1200px;
  margin: auto;
  height: 100%;
  max-height: 900px;
  position: relative;
  background: #fff;
  border-radius: 10px;
  padding: 32px;
  overflow: hidden;

  @media screen and (max-height: 1080px) {
    max-height: calc(100svh - 108px);
  }

  @media screen and (max-width: 1440px) {
    max-width: calc(100vw - 280px);
  }

  @media screen and (max-width: 1040px) {
    max-width: calc(100vw - 80px);
  }

  @media screen and (max-width: 599px) {
    padding: 16px;
    gap: 16px;
    max-width: calc(100vw - 32px);
  }
}

.term_popup_title {
  font-weight: 700;
  font-size: 20px;
  color: $policyTitleColor;

  &.custom_color {
    font-size: 24px;
    clip-path: inset(1px);
    color: $policyTitleColor;
    background: transparent;
    background-clip: unset;
    text-fill-color: unset;
    -webkit-background-clip: unset;
    -webkit-text-fill-color: unset;
    letter-spacing: normal;
    font-weight: 600;
  }
}

.term_popup_content {
  display: block;
  height: 100%;
  overflow-y: auto;
  word-break: break-word;
  padding-right: 24px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: $policyTextColor;

  & * {
    font-size: 14px;
    font-weight: 500;
  }

  h2 {
    margin-top: 24px;
    margin-bottom: 16px;
    font-size: 16px;
    font-weight: 700;
    color: $policyTitleColor;
  }

  h3 {
    margin-top: 20px;
    margin-bottom: 12px;
    font-size: 14px;
    font-weight: 700;
    color: $policyTitleColor;
  }

  h4 {
    margin-top: 16px;
    margin-bottom: 8px;
    font-size: 14px;
    font-weight: 700;
    color: $policyTitleColor;
  }

  p, span {
    font-size: 14px;
    color: $policyTextColor;

    & + * {
      margin: 12px 0;

      @media screen and (max-width: 1499px){
        margin: 8px 0;
      }
    }
  }

  b, strong {
    font-size: 14px;
    font-weight: 700;
  }

  a {
    transition: all .3s ease-in-out;
    text-decoration: underline;
    color: $policyLinksColor;

    &:hover,
    &:focus {
      color: $policyLinksHoverColor;
    }
  }

  ul, ol {
    padding-left: 20px;
    list-style-position: outside;

    li {
      & + * {
        margin-top: 8px;
      }
      p {
        display: inline;
      }
    }

    ul, ol {
      padding-top: 8px;
    }

    @media screen and (max-width: 1040px) {
      padding-left: 16px;
    }
  }

  &.custom_color {
    font-size: 16px;
    font-weight: 400;
    line-height: 150%;
    color: $policyTextColor;

    & * {
      font-size: 16px;
      font-weight: 400;
    }

    h2 {
      margin-top: 12px;
      margin-bottom: 12px;
      font-size: 20px;
      font-weight: 600;
      color: $policyTitleColor;
    }

    h3 {
      margin-top: 10px;
      margin-bottom: 10px;
      font-size: 18px;
      font-weight: 600;
      color: $policyTitleColor;
    }

    h4 {
      margin-top: 8px;
      margin-bottom: 8px;
      font-size: 16px;
      font-weight: 600;
      color: $policyTitleColor;
    }

    p, span {
      font-size: 16px;
      color: $policyTextColor;

      & + * {
        margin: 12px 0;

        @media screen and (max-width: 1499px){
          margin: 8px 0;
        }
      }
    }

    b, strong {
      font-size: 16px;
      font-weight: 700;
    }

    a {
      transition: all .3s ease-in-out;
      text-decoration: underline;
      color: $policyLinksColor;
      display: inline-block;
      word-break: break-word;
      overflow-wrap: break-word;
      white-space: normal;
      max-width: 100%;

      &:hover,
      &:focus {
        color: $policyLinksHoverColor;
      }
    }

    ul, ol {
      display: flex;
      flex-direction: column;
      gap: 8px;
      padding-left: 20px;
      list-style-position: outside;

      li {
        & + * {
          margin-top: 8px;
        }
        p {
          display: inline;
        }
      }

      ul, ol {
        padding-top: 8px;
      }

      @media screen and (max-width: 1040px) {
        padding-left: 16px;
      }
    }
  }

  &::-webkit-scrollbar {
    width: 3px;
    background: #FFFFFF;
    border-radius: 9px;
  }

  &::-webkit-scrollbar-thumb {
    background: $policyScrollColor;
    border-radius: 9px;
  }
}

.button_close {
  display: flex;
  width: 40px;
  min-width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 10px;
  padding: 0;
  border: 0;
  outline: 0;
  transition: all .3s ease-in-out;
  background: transparent;

  @media screen and (max-width: 599px) {
    top: 5px;
    right: 5px;
  }


  svg {
    fill: #C2C2C2;
    width: 20px;
    height: 20px;

    @media screen and (max-width: 599px) {
      width: 18px;
      height: 18px;
    }
  }

  &.custom_color svg {
    width: 16px;
    height: 16px;
  }

  &:hover svg {
    fill: #858585;
  }
}
