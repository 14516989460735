@import "@sitestyles";

.modal_wrap {
  position: relative;
  width: 490px;
  max-width: 100%;
  padding: 40px;
  border-radius: 8px;
  background-color: $whiteColor;
  text-align: center;
  height: max-content;

  @media screen and (max-width: 599px) {
    width: 340px;
    margin: 0 auto;
    padding: 30px 20px 20px 20px;
    box-sizing: border-box;
  }

  @media screen and (max-width: 360px) {
    width: 300px;
  }
}

.modal_title {
  font-size: 20px;
  font-weight: 600;
  color: $accentLeftColor;

  a {
    color: $menuIconColor;
    font-size: 21px;
  }

  @media screen and (max-width: 599px) {
    font-size: 18px;
  }
}

.modal_text {
  margin: 16px auto;
  font-size: 16px;
  font-weight: 400;
  color: $defaultTextColorAlpha8;

  @media screen and (max-width: 599px) {
    font-size: 14px;
  }
}

.modal_button {
  width: 100%;
  font-size: 15px;
  padding: 12px 10px;
  background: $backgroundButtonColor;
  border: 1px solid transparent;
  border-radius: 4px;
  color: $whiteColor;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;

  svg {
    width: 20px;
    height: 20px;
    fill: $whiteColor;
  }

  &:hover,
  &:focus {
    background: $accentDarkColor;
  }

  @media screen and (max-width: 599px) {
    padding: 10px;
    font-size: 14px;
  }
}

.modal_close {
  position: absolute;
  right: 7px;
  top: 7px;
  padding: 5px;
  cursor: pointer;
  outline: none;
  background: transparent;

  &:hover {
    svg {
      fill: rgb(46, 46, 46);
    }
  }
}

.modal_close svg {
  width: 20px;
  height: 20px;
  fill: rgb(205, 211, 218);
  transition-duration: .3s;
}
