@import "@sitestyles";

@import "@sitestyles";

.first_block_bg {
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url(/img/auth/first_bg.jpg);
  background-size: cover;
  background-position: center;
}

.first_block_bg:after {
  content: '';
  display: block;
  width: 100%;
  height: 380px;
  position: absolute;
  left: 0;
  bottom: 0;
  background: linear-gradient(0deg, $unknownlWOqwKKMlVColor 0%, $transparentColor 100%);
}

.main_header_wrap {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 99;
}

.main_header {
  display: flex;
  align-items: center;
  height: 90px;
  border-bottom: 2px solid $whiteColor;
}

.main_header_left {
  flex: 1;
  display: flex;
  align-items: center;
}

.main_header_left_item {
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: $primaryColor;
  cursor: pointer;
}

.main_header_left_item:first-child {
  margin-right: 70px;
}

.main_header_logo {
  flex: 1;
}

.main_header_logo img {
  display: block;
  margin: 0 auto;
}

.main_header_login {
  flex: 1;
  display: flex;
}

.main_header_login_btn {
  margin-left: auto;
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: $unknownSenSmBLPaAColor;
  cursor: pointer;
  padding: 5px 0;
}

.main_header_login_btn img {
  margin-right: 15px;
}

.first_block_content_wrap {
  position: relative;
}

.main_first_block {
  margin-bottom: 150px;
}

.first_block_content {
  height: 100vh;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding-bottom: 90px;
  padding-top: 90px;
  flex-direction: row-reverse;
  text-transform: none;
}

.first_block_text_wrap {
  text-align: center;
}

.first_block_text_title {
  font-weight: 600;
  font-size: 40px;
  color: $unknownaLHRtdBfNfColor;
  margin-bottom: 15px;
}

.first_block_text_content {
  font-weight: 500;
  font-size: 28px;
  color: $greenSwampTemplateColor;
}

.first_block_registr_form_row {
  position: relative;
}

.first_block_registr_form_text_row {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
}

.first_block_registr_form_text_fieldset {
  display: grid;
  grid-row-gap: 12px;
}


.first_block_registr_form_text_row.with_email_help {
  position: relative;
}

.first_block_registr_form_emails_list_wrap {
  // width: 100%;
  position: absolute;
  ////top: calc(100% - 3px);
  //top: 100%;
  //left: 0;
  background-color: #fff;
  border-radius: 0 0 5px 5px;
  border: 1px solid #9F9F9F;
  border-top: none;
  z-index: 3;
  display: none;
  width: 100.5%;
  top: 97%;
  left: -1px;
}

.first_block_registr_form_text_row.with_email_help.opened {
  background: #F8F8F8;
  border: 1px solid #9F9F9F;
  border-radius: 5px;

  .first_block_registr_form_emails_list_wrap {
    display: block;
    background: #F8F8F8;
    //border: 1px solid #9F9F9F;
    border-radius: 0 0 5px 5px;
  }

  .first_block_registr_form_input:active,
  .first_block_registr_form_input:focus {
    border: 0;
  }
}

.first_block_registr_form_emails_list {
  max-height: 200px;
  overflow-y: auto;
  margin-right: 5px;
  padding-right: 2px;
  border-radius: 0 0 5px 5px;
}

.first_block_registr_form_emails_list::-webkit-scrollbar {
  width: 3px;
  background: $littleGreyLittleBlueColor;
  border-radius: 5px;
  margin-right: 3px;
}

.first_block_registr_form_emails_list::-webkit-scrollbar-thumb {
  background: $scrollbarThumbColor;
  border-radius: 7px;
}

.first_block_registr_form_emails_list_item {
  padding: 13px 16px;
  cursor: pointer;
  transition: .3s;
  margin: 0;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #4F4F4F;
  font-family: $mainFont;
}

.first_block_registr_form_emails_list_item:hover {
  background: #EFEFEF;
}

.first_block_registr_form_input {
  display: block;
  width: 100%;
  height: 48px;
  padding: 13px 16px;
  font-weight: 600;
  line-height: 22px;
  font-size: 16px;
  color: $attachTouchText;
  background: #F8F8F8;
  border-radius: 5px;
  box-sizing: border-box;
  font-family: $mainFont;

  &::placeholder {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #9F9F9F;
    font-family: $mainFont;
  }

  &:focus,
  &:active {
    border: 1px solid #9F9F9F;
    color: #4F4F4F;
    font-family: $mainFont;
  }

  &:-webkit-autofill {
    font-weight: 600 !important;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active,
  &:-webkit-autofill::first-line {
    color: $attachTouchText;
    -webkit-text-fill-color: $attachTouchText;
    -webkit-box-shadow: 0 0 0 1000px #F8F8F8 inset;
    font-weight: 600 !important;
    font-family: $mainFont;
    font-size: 16px;

    line-height: 22px;
  }
}
.first_block_registr_form_password {
  padding-right: 50px;
}

.first_block_registr_form_row_line {
  height: 1px;
  width: 100%;
  background-color: $defaultTextColorAlpha3;
  border-radius: 4px;
}

.first_block_registr_form_row_line.error {
  background-color: #EB5757;
}

.first_block_registr_form_row_line + .error_text {
  display: none;
}

.first_block_registr_form_row_line.error + .error_text {
  display: block;
  position: absolute;
  right: 0;
  top: 67px;
  font-size: 12px;
  color: #EB5757;
}

.first_block_registr_form_label {
  position: absolute;
  left: 16px;
  bottom: 13px;
  font-size: 16px;
  color: #9F9F9F;
  line-height: 22px;
  pointer-events: none;
  transition: .3s;
}

.error_text {
  display: none;
}

//.first_block_registr_form_input:focus ~ .first_block_registr_form_label,
//.first_block_registr_form_input:not(:placeholder-shown) ~ .first_block_registr_form_label,
//.first_block_registr_form_input:-webkit-autofill ~ .first_block_registr_form_label {
//  bottom: 41px;
//  left: 0;
//  font-size: 11px;
//  color: $primaryColor;
//}

.first_block_registr_form_dob_row {
  margin-top: 24px;
}

.first_block_registr_dob_title {
  font-weight: 600;
  color: $stormGreyColor;
  margin-bottom: 17px;
}

.first_block_registr_dob_row_wrap {
  position: relative;
}

.first_block_registr_dob_row_wrap .error_text {
  position: absolute;
  right: 0;
  top: 45px;
  font-size: 12px;
  color: #EB5757;
}

.first_block_registr_dob_row {
  display: grid;
  grid-template-columns: 124px 124px 124px;
  grid-column-gap: 12px;
}

.main_select_item_wrap {
  position: relative;
  height: 48px;
  background: #F8F8F8;
  border-radius: 5px;
  box-sizing: border-box;
}

.main_select_item {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 2;
}

.main_select_item_head {
  height: 48px;
  font-size: 16px;
  line-height: 22px;
  cursor: pointer;
  position: relative;
  color: #9F9F9F;
  padding: 16px;
  width: 100%;
  box-sizing: border-box
}

.main_select_item_head:after {
  content: '';
  display: block;
  width: 16px;
  height: 16px;
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
  background-image: url(../../img/main_select_arrow.svg);
  background-repeat: no-repeat;
  background-position: center;
  transition: .3s;
}

.main_select_item.open .main_select_item_head:after {
  transform: translateY(-50%) rotate(180deg);
}

.main_select_item_head_line {
  width: calc(100% + 18px);
  height: 1px;
  background-color: $defaultTextColorAlpha3;
  box-shadow: 0px 2px 10px rgba(84, 0, 114, 0.05);
  border-radius: 4px;
  margin-left: -18px;
}

.main_select_item_head_line.error {
  background-color: #EB5757;
}

.main_select_item_list {
  background: #F8F8F8;
  display: none;
  //  border: 1px solid $silverColor;
  border-radius: 0 0 5px 5px;
  border-top: none;
  margin-top: -1px;
  padding: 0;
}

.main_select_item_list_inner {
  margin-right: 3px;
  max-height: 172px;
  overflow-y: auto;
  border-radius: 5px;
}

.main_select_item_list_inner::-webkit-scrollbar {
  width: 3px;
  background: $whiteColor;
  border-radius: 3px;
}

.main_select_item_list_inner::-webkit-scrollbar-thumb {
  background: $michiganSkyColor;
  border-radius: 3px;
}

.main_select_item_list input {
  display: none;
}

.main_select_item_list label {
  display: block;
  margin: 0;
  padding: 13px 16px;
  font-family: $mainFont;
  cursor: pointer;
  font-size: 16px;
  color: #4F4F4F;
  font-weight: 600;
  line-height: 22px;
}

.main_select_item_list label:hover {
  background: #EFEFEF;
}

.main_select_item.open {
  z-index: 3;
  background: #F8F8F8;
  border: 1px solid #9F9F9F;
  border-radius: 5px;

  .main_select_item_head {
    color: $attachTouchText;
    font-weight: bold;
  }
}

.main_select_item.open .main_select_item_list {
  display: block;
}


.main_select_item.selected .main_select_item_head {
  font-weight: 600;
  color: $attachTouchText;
  padding: 0 16px;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  &:after {
    right: 16px;
  }
}

.first_block_registr_form_looking_row .main_select_item {
  .main_select_item_head {
    padding: 0;
    display: flex;
    align-items: center;

    &:after {
      right: 0;
    }
  }
}

.first_block_registr_form_looking_row {
  position: relative;

  &.selected {
    .main_select_item_head {
      font-weight: 600;
      color: $attachTouchText;
    }
  }
}

.first_block_registr_form_looking_row.open {
  border: 1px solid #9F9F9F;

  .main_select_item_wrap {
    position: relative;
  }

  .main_select_item_head {
    display: none;
  }

  .first_block_registr_form_looking_title {
    font-weight: 600;
    color: $attachTouchText;
    position: relative;
    z-index: 10;
  }

  .main_select_item_wrap {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 101%;
    margin-left: -1px;
    top: 88%
  }

  .main_select_item_list_inner {
    margin-right: 0;
  }

  .main_select_item_head:after {
    transform: translateY(-50%) rotate(180deg);
  }

  .main_select_item {
    z-index: 3;
    background: #F8F8F8;
    border: 1px solid #9F9F9F;
    border-radius: 5px;

    .main_select_item_head {
      color: $attachTouchText;
      font-weight: bold;
    }
  }

  .main_select_item .main_select_item_list {
    display: block;
  }
}

.first_block_registr_form_looking_row {
  display: flex;
  align-items: center;
  margin-top: 24px;
  background: #F8F8F8;
  border-radius: 5px;
  padding: 16px;
  height: 48px;
  box-sizing: border-box;
  cursor: pointer;
}

.first_block_registr_form_looking_title {
  color: #9F9F9F;
  font-size: 16px;
  line-height: 22px;
}

.first_block_registr_form_looking_row .main_select_item_wrap {
  flex: 1;
  margin-left: 8px;
}

.first_block_registr_form_policy_row {
  display: flex;
  align-items: center;
  margin-top: 22px;
}

.first_block_registr_form_policy_row + .error_text {
  display: none;
}

.first_block_registr_form_policy_row.error + .error_text {
  display: block;
  font-size: 12px;
  color: #EB5757;
  text-align: end;
  width: 100%;
  margin-top: 2px;
}

.first_block_registr_form_policy_row input {
  display: none;
}

.first_block_registr_form_policy_row label {
  display: block;
  width: 24px;
  height: 24px;
  border: 1.5px solid $attachTouchText;
  border-radius: 4px;
  flex: none;
  margin-right: 16px;
  position: relative;
  cursor: pointer;
}

.first_block_registr_form_policy_row.error label {
  border: 1px solid #EB5757;
}

.first_block_registr_form_policy_row input:checked + label:after {
  content: '';
  display: block;
  width: 12px;
  min-width: 12px;
  height: 12px;
  border-radius: 100%;
  background: url('../../img/arrow-checked.svg') center no-repeat;
  background-size: 12px 8px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.first_block_registr_form_policy_text {
  font-size: 12px;
  line-height: 150%;
  font-weight: 400;
  color: #4F4F4F;
}

.first_block_registr_form_policy_text div {
  display: inline;
  cursor: pointer;
  background: $buttonBackgroundColor;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.first_block_registr_form_policy_text span {
  color: #4F4F4F;
}

.first_block_registr_form_find_btn {
  width: 100%;
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $buttonBackgroundColor;
  border-radius: 5px;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  color: $whiteColor;
  margin-top: 25px;
  cursor: pointer;
  font-family: $mainFont;
  position: relative;
  transition: all .6s ease-in-out;

  &:after {
    display: block;
    content: '';
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    position: absolute;
    top: -1px;
    left: -1px;
    background: $buttonBackgroundColor;
    border-radius: 5px;
    z-index: 0;
    transition: all .6s ease-in-out;
    opacity: 1;
  }

  &:hover {
    background: #FFFFFF;

    .first_block_registr_form_find_btn_text {
      background: $buttonBackgroundColor;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }

    &:after {
      z-index: -1;
    }
  }

}

.first_block_registr_form_find_btn_text {
  background: #FFFFFF;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  transition: all .4s ease-in-out;
  position: relative;
  z-index: 1;
}

.first_block_registr_signup_hr {
  display: flex;
  align-items: center;
  margin-top: 29px;
}

.first_block_registr_signup_line {
  height: 1px;
  flex: 1;
  background-color: $greyColorBorderAlpha2;
}

.first_block_registr_signup_text {
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 0.01em;
  color: $stormGreyColor;
  margin: 0 20px;
}

.first_block_registr_signup_socials {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.first_block_registr_signup_socials_item {
  margin: 0 7px;
}

.first_block_registr_form_tablet_social {
  display: none;
  align-items: center;
  align-self: center;
  font-weight: 500;
  font-size: 10px;
  letter-spacing: 0.01em;
  color: #474F5C;
}

.first_block_registr_form_tablet_social_item {
  margin-left: 10px;
  cursor: pointer;
}

.first_block_registr_form_tablet_social_item img {
  display: block;
}

.main_registration_form_bottom_btn {
  width: 230px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: .01em;
  color: #426076;
  border: 1px solid #426076;
  border-radius: 5px;
  cursor: pointer;
}

.main_registration_form_bottom_btn_img {
  width: 30px;
  height: 30px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50%;
  margin-right: 12px;
}


.registr_form_footer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
}

.registr_form_footer_text {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #4F4F4F;
  margin-right: 16px;
}

.registr_form_footer_btn {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.05em;
  background: $buttonBackgroundColor;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  font-family: $mainFont;
  cursor: pointer;

  &:hover {
    background: #4F4F4F;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }
}

.first_block_registr_form {
  position: relative;
  z-index: 0;
  overflow: scroll;
}

.first_block_registr_form_text_column {
  display: grid;
  grid-template-columns: 192px 192px;
  grid-column-gap: 12px;
}

.first_block_registr_password_show {
  width: 24px;
  height: 24px;
  border: 0;
  outline: 0;
  background: url('../../img/password-show.svg') center no-repeat;
  background-size: contain;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 16px;
  cursor: pointer;

  &:hover {
    filter: brightness(0.4);
  }
}

.main_form_captcha_wrap {
  margin: 24px 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 536px) {
    margin-top: 16px;
  }
}


// Popup modal registration
.popup_main_reg {
  width: 100vw;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $MainHeaderBg;

  @media screen and (max-height: 700px) {
    align-items: flex-start;
    padding: 5% 0;
    overflow: auto;
  }
}

.popup_main_reg_wrap {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: center;
  -ms-align-items: center;
  align-items: center;
  background-color: $whiteColor;
  border-radius: 15px;
  position: relative;
}


.popup_main_reg .main_registration_form_wrap {
  position: static;
  margin: 0;
  max-width: 476px;
  width: 476px;
  padding: 32px 40px;
  height: 100%;
  background-color: $whiteColor;
  box-sizing: border-box;
  border-radius: 15px;
  @media screen and (max-width: 900px) {
    margin: 0 auto;
  }
  @media screen and (max-width: 536px) {
    width: 343px;
    max-width: 343px;
    padding: 24px 16px;
  }
  @media screen and (max-width: 370px) {
    width: 304px;
    max-width: 304px;
  }
}

.popup_main_reg_close {
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
}


.popup_main_reg_close img {
  display: block;
  width: 100%;
  height: auto;
}

.popup_main_reg_close svg {
  width: 20px;
  height: 20px;
  fill: #c4c4c4;
}

.main_registration_form_title {
  font-weight: 600;
  font-size: 24px;
  line-height: 140%;
  text-align: center;
  background: $buttonBackgroundColor;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 20px;

  @media screen and (max-width: 536px) {
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 16px;
  }
}