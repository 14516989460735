@import "@sitestyles";

.search_cross_banner_item_wrap {
  position: relative;
  padding: 3px;
  background-color: $whiteColor;
  box-shadow: 3px 3px 5px $backgroundSearchItemShadowColor;
  border-radius: 10px;
}

.search_cross_banner_item_photo_wrap {
  position: relative;
  width: 100%;
  min-height: 274px;
  padding-top: 166%;
  cursor: pointer;

  &:before {
    content: '';
    display: none;
    width: 100%;
    bottom: 0;
    left: 0;
    right: 0;
    height: 38px;
    position: absolute;
    z-index: 1;
    background: $whiteColor;

    @media screen and (max-width: 599px) {
      z-index: 2;
    }                                     
  }
}

.search_cross_banner_item_photo {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
  border-radius: 8px;
}

.search_cross_banner_item_bottom {
  position: absolute;
  bottom: 3px;
  left: 2px;
  right: 2px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:before {
    content: '';
    width: 100%;
    height: 200%;
    position: absolute;
    bottom: 0;
    left: 0;
    background: $backgroundSearchColor;
    border-radius: 0 0 8px 8px;
    pointer-events: none;
    transition: .3s;

    @media screen and (max-width: 599px) {
      z-index: 2;
    }
  }

  @media screen and (max-width: 599px) {
    bottom: 2px;
  }
}

.search_cross_banner_item_chat_btn {
  background: $searchItemBtnBg;
  border: 1px solid $primarySearchButtonBorder;
  color: $primarySearchButton;
  border-radius: 4px;
  transition: .3s;
  position: relative;
  z-index: 2;
  width: 100%;
  height: 47px;
  margin: 0 1px 3px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  text-transform: capitalize;

  svg {
    fill: $primarySearchButton;
    width: 24px;
    height: 24px;
  }

  &:hover,
  &:focus {
    box-shadow: $searchItemBtnShadowHover;
  }

  @media screen and (max-width: 599px) {
    height: 32px;
    font-size: 12px;

    svg {
      width: 18px;
      height: 18px;
    }
  }
}