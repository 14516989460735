@import '@sitestyles';

.connection_lost_container {
  position: relative;
  width: 600px;
  height: 646px;
  border-radius: 10px;
  padding: 40px;
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 24px;
  font-family: $mainFont;

  @media screen and (max-width: 650px){
    width: 343px;
    height: 424px;
    padding: 24px;
  }
}

.connection_lost_image {
  width: 500px;
  height: 350px;

  @media screen and (max-width: 650px)  {
    width: 260px;
    height: 182px;
  }
}

.connection_lost_title {
  background: $buttonBackgroundColor;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
  font-size: 28px;
  font-weight: 700;
  line-height: 130%;

  @media screen and (max-width: 650px){
    font-size: 18px;
  }
}

.connection_lost_subtitle {
  font-size: 16px;
  font-weight: 400;
  color: #4F4F4F;
  text-align: center;
  max-width: 380px;
  line-height: normal;

  @media screen and (max-width: 650px){
    font-size: 14px;
    line-height: 150%;
  }
}

.connection_lost_button {
  width: 100%;
  height: 56px;
  padding: 0 24px;
  background-image: $buttonModalBackgroundColor,
  linear-gradient(to right, $accentLeftColor, $accentRightColor);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin-top: 8px;
  cursor: pointer;

  .connection_lost_button_text {
    font-weight: 400;
    font-size: 18px;
    color: #FFFFFF;

    @media screen and (max-width: 650px) {
      font-size: 16px;
    }
  }

  svg {
    width: 22px;
    height: 22px;
    fill: #FFFFFF;
  }

  &:hover,
  &:focus {
    border: 1px solid $accentLeftColor;
    background-image: linear-gradient($buttonBackgroundColorHover, $buttonBackgroundColorHover),
    linear-gradient(to right, $accentLeftColor, $accentRightColor);

    .connection_lost_button_text {
      background: $accentLeftColor;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
      position: relative;
      z-index: 1;
    }

    svg {
      fill: $accentLeftColor;
    }
  }

  @media screen and (max-width: 650px) {
    height: 48px;
    min-height: 48px;
    margin-top: 0;
    font-size: 16px;
  }
}

.connection_lost_button_close {
  position: absolute;
  z-index: 1;
  width: 99%;
  text-align: end;
  margin-top: 8px;

  svg {
    fill: #9E9E9E;
    width: 36px;
    height: 36px;
    padding: 6px;
    cursor: pointer;
    transition: 0.3s;

    @media screen and (max-width: 650px) {
      width: 28px;
      height: 28px;
    }
  }

  &:hover,
  &:focus {
    svg {
      fill: $accentLeftColor;
    }
  }

  @media screen and (max-width: 650px) {
    width: 98%;
    margin-top: 6px;
  }
}

.connection_lost_wrapper {
  position: relative;
}