@import "@sitestyles";

.profile_div{
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 6px 12px rgba(47, 43, 99, 0.16);
  padding: 30px;
}

.profile_input{
  height: 42px;
  border-radius: 8px;
}

.text_input{
  width: 100%;
  border: 1px solid $inputBorderNormal;
  padding: 0 15px;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.06em;
  color: $inputTextColor;
  display: block;
  cursor: pointer;

  &.error {
    border: 1px solid #FF3030 !important;
  }
}

.text_input:focus{
  cursor: text;
}

.text_input:focus,
.text_input:hover{
  border-color: #677F92;
}

.profile_label{
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  color: #474F5C;
  margin-bottom: 7px;
  display: block;
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.profile_select_wrap{
  flex: 1;
  position: relative;
  max-width: 100%;
}

.profile_info_row .profile_select_wrap + .profile_select_wrap{
  margin-left: 17px;
}

.profile_select_wrap.open{
  z-index: 6;
}

.profile_select_head{
  background-color: #fff;
  border: 1px solid $inputBorderNormal;
  border-radius: 8px;
  height: 42px;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.06em;
  color: #474F5C;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
  cursor: pointer;
}

.profile_select_head span{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.profile_main_top .profile_select_head{
  color: $inputTextColor;
}

.profile_select_wrap.selected .profile_select_head{
  color: $inputTextColor;
}

.profile_select_head:hover{
  border-color: #677F92;
}

.profile_select_wrap.pass_mail .profile_select_head{
  border-color: #00317B;
}

.profile_select_wrap.pass_mail .profile_select_head svg{
  opacity: 1;
  margin-left: 5px;
}

.profile_select_wrap.open .profile_select_head{
  border-radius: 8px 8px 0 0;
  border-bottom: none;
  border-color: $inputTextColor;
}

.profile_select_head_arrow svg{
  opacity: 0;
  transition: .3s;
}

.profile_select_head:hover .profile_select_head_arrow svg,
.profile_select_wrap.open .profile_select_head_arrow svg{
  opacity: 1;
}

.profile_select_wrap.open .profile_select_head_arrow svg{
  transform: rotate(180deg);
}

.profile_select_list_wrap{
  display: none;
  position: absolute;
  left: 0;
  top: 100%;
  width: 100%;
  border: 1px solid #00317B;
  border-top: none;
  background-color: #fff;
  z-index: 2;
  border-radius: 0 0 8px 8px;
  padding-bottom: 7px;
}

.profile_select_list_wrap:before{
  content: '';
  display: block;
  margin: 0 20px 4px;
  height: 1px;
  background-color: rgba(163, 199, 229, 0.4);
}

.profile_select_wrap.open .profile_select_list_wrap{
  display: block;
}

.profile_select_list{
  margin-left: 7px;
  margin-right: 7px;
  padding-right: 4px;
  max-height: 150px;
  overflow-y: auto;
  list-style: none;
}

.profile_select_list::-webkit-scrollbar{
  width: 3px;
  background: #fff;
  border-radius: 3px;
}

.profile_select_list::-webkit-scrollbar-thumb{
  background: $scrollbarThumbColor;
  border-radius: 3px;
}

.profile_select_color{
  width: 29px;
  height: 29px;
  border-radius: 100%;
  margin-right: 10px;
  flex: none;
}

.profile_select_color.not_spec{
  background: #c4c4c4;
}
.profile_select_color.black{
  background: linear-gradient(218.66deg, #554849 13.83%, #0E0C0C 54.12%, #4F2B2B 91.96%);
}
.profile_select_color.blond{
  background: linear-gradient(218.66deg, #F6F7E6 13.83%, #EDE8CE 54.12%, #F6F7F0 91.96%);
}
.profile_select_color.brunette{
  background: linear-gradient(218.66deg, #554849 13.83%, #0E0C0C 54.12%, #4F2B2B 91.96%);
}
.profile_select_color.brown{
  background: linear-gradient(218.66deg, #8F6857 13.83%, #532816 54.12%, #683417 91.96%);
}
.profile_select_color.golden{
  background: linear-gradient(218.66deg, #E6BD8E 13.83%, #DDA77F 50.86%, #E2D4B0 91.96%);
}
.profile_select_color.ginger{
  background: linear-gradient(218.66deg, #E27C50 13.83%, #D56527 56.56%, #CC7815 91.96%);
}

.profile_select_color.green{
  background: linear-gradient(218.66deg, #2E734E 13.83%, #62C96C 54.12%, #95D544 91.96%);
}
.profile_select_color.blue{
  background: linear-gradient(218.66deg, #6CB9DB 13.83%, #5290BC 55.74%, #63CAD8 91.96%);
}
.profile_select_color.brown{
  background: linear-gradient(218.66deg, #8F6857 13.83%, #532816 54.12%, #683417 91.96%);
}
.profile_select_color.gray{
  background: linear-gradient(218.66deg, #BFD2DB 13.83%, #ACBDC9 55.74%, #B4C7C0 91.96%);
}
.profile_select_color.hazel{
  background: linear-gradient(218.66deg, #6F6514 13.83%, #BA6618 55.74%, #4E6709 91.96%);
}
.profile_select_color.amber{
  background: linear-gradient(218.66deg, #EFE165 13.83%, #BA6618 55.74%, #EACB29 91.96%);
}


.profile_select_list_item{
  padding: 8px 13px;
  display: flex;
  align-items: center;
  border-radius: 5px;
  transition: background-color .3s;
  font-weight: 600;
  font-size: 12px;
  letter-spacing: 0.06em;
  color: #00317B;
  cursor: pointer;

  img {
    width: 15px;
  }
}

.profile_select_list_item + .profile_select_list_item{
  margin-top: 3px;
}

.profile_select_list_item:hover,
.profile_select_list_item.active{
  background-color: #E7EFFB;
  font-weight: 700;
}

.profile_select_list_item.disabled {
  opacity: 0.7;
  pointer-events: none;
}

.profile_select_list_item svg {
  margin-right: 10px;
  width: 15px;
  height: 15px;
  fill: #01327a;
}

.profile_click_edit{
  margin-left: 12px;
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 0.06em;
  color: #446077;
  opacity: 0;
  transition: opacity .3s;
}

.dob_wrap:hover ~ .profile_click_edit,
.city_wrap:hover ~ .profile_click_edit,
.profile_select_wrap:hover ~ .profile_click_edit,
.profile_info_row_half:hover ~ .profile_click_edit{
  opacity: 1;
}

.city_wrap ~ .profile_click_edit,
.profile_info_row_half ~ .profile_click_edit{
  margin-top: 27px;
}

.profile_div_head{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 21px;
  border-bottom: 1px solid rgba(68, 96, 119, 0.4);
  margin-bottom: 24px;
}

.profile_div_head_title{
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 20px;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  color: $primaryColor;
}

.profile_div_head_title svg {
  fill: $primaryColor;
  margin-right: 20px;
  vertical-align: middle;
}

.profile_div_head_click_info{
  display: flex;
  align-items: center;
  font-size: 16px;
  letter-spacing: 0.01em;
  color: rgba(51, 51, 51, 0.6);
  position: relative;
}

.profile_div_head_click_info:after{
  content: 'Click a field to edit';
  width: 146px;
  height: 37px;
  display: none;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: -13px;
  top: calc(100% + 11px);
  font-size: 12px;
  color: rgba(51, 51, 51, 0.8);
  background: rgba(255, 255, 255, 0.9);
  border: 1px solid #5C5C5C;
  border-radius: 5px;
  z-index: 1;
}

.profile_div_head_click_info:before{
  content: '';
  display: none;
  width: 14px;
  height: 14px;
  background: #fff;
  border: 1px solid #5C5C5C;
  border-bottom: none;
  border-right: none;
  border-radius: 2px;
  position: absolute;
  left: 50%;
  top: calc(100% + 4px);
  transform: translateX(-50%) rotate(45deg);
  z-index: 2;
}

.profile_div_head_click_info svg{
  width: 12px;
  height: 12px;
  fill: #858585;
  margin-right: 11px;
}

.profile_info_row{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.profile_info_row + .profile_info_row{
  margin-top: 20px;
}

.profile_info_row_half{
  flex: 1;
  position: relative;
}

.profile_info_colors_row{
  position: relative;
  padding-right: 86px;
}

.profile_info_row_half + .profile_info_row_half{
  margin-left: 20px;
}

.profile_info_hobby_row{
  display: flex;
  align-items: center;
  width: 100%;
  padding-right: 86px;
  position: relative;
}

.profile_info_hobby_list_wrap{
  display: none;
  position: absolute;
  left: 0;
  top: 0;
  width: calc(100% - 86px);
  background-color: #fff;
  padding: 14px 22px 9px;
  border: 1px solid #00317B;
  border-radius: 8px;
  z-index: 6;
}

.profile_info_colors_row .profile_info_hobby_list_wrap{
  top: 24px;
}

.profile_info_hobby_list_wrap.active{
  display: block;
}

.profile_info_hobby_list_head{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 11px;
  margin-bottom: 17px;
  border-bottom: 1px solid rgba(163, 199, 229, 0.4);
}

.profile_info_hobby_list_head_title{
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 0.06em;
  color: #446077;
}

.profile_info_hobby_list_head_count{
  font-weight: 600;
  font-size: 10px;
  letter-spacing: 0.06em;
  color: #00317B;
}

.profile_info_hobby_list_scroll{
  max-height: 197px;
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  list-style: none;
}

.profile_info_hobby_list_scroll::-webkit-scrollbar{
  width: 3px;
  background: #fff;
  border-radius: 3px;
}

.profile_info_hobby_list_scroll::-webkit-scrollbar-thumb{
  background: $scrollbarThumbColor;
  border-radius: 3px;
}

.profile_info_hobby_list_item{
  display: flex;
  align-items: center;
  padding: 0 15px;
  border: 1px solid #9AAFD0;
  border-radius: 8px;
  height: 42px;
  margin-right: 10px;
  margin-bottom: 11px;
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 0.06em;
  cursor: pointer;
}

.profile_info_hobby_list_item.chosen{
  width: 153px;
  margin-bottom: 0;
}

.profile_info_hobby_list_item.chosen span{
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.profile_info_hobby_list_item.active,
.profile_info_hobby_list_item:hover{
  background-color: #e7effb;
  color: #00317b;
}

.profile_info_hobby_list_item img{
  width: 26px;
  height: 26px;
  object-fit: contain;
  margin-right: 12px;
}

.profile_info_hobby_selected{
  margin-right: 16px;
  width: calc(100% - 157px);
  cursor: default;
  height: 42px;
  display: flex;
  align-items: center;
  border: 1px solid $inputBorderNormal;
  border-radius: 8px;
  padding: 0 15px;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.06em;
  color: #474F5C;
}

.profile_info_hobby_selected.selected{
  color: #00317B;
}

.profile_info_hobby_add_btn{
  width: max-content;
  padding: 0 16px;
  height: 42px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid $inputBorderNormal;
  color: $searchFilterSelectColor;
  font-weight: 600;
  font-size: 12px;
  letter-spacing: 0.06em;
  cursor: pointer;
  flex: none;
}

.profile_info_hobby_add_btn_icon{
  width: 28px;
  height: 28px;
  margin-right: 10px;
  background: $iconAddPlusFillColor;
  mask: url('../../../../img/profile_new_avatar.svg') no-repeat center;
  mask-size: contain;
  -webkit-mask: url('../../../../img/profile_new_avatar.svg') no-repeat center;
  -webkit-mask-size: contain;
}

.profile_info_hobby_selected_images{
  display: flex;
  align-items: center;
}

.profile_info_hobby_selected_images img{
  display: block;
  width: 21px;
  height: 21px;
  object-fit: contain;
}

.profile_info_hobby_selected_img_item + .profile_info_hobby_selected_img_item{
  margin-left: -10px;
}

.profile_info_hobby_selected_img_item{
  background-color: #fff;
  width: 33px;
  height: 33px;
  border-radius: 100%;
  border: 1px solid #ADBAFF;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.profile_info_hobby_selected_names{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
}

.profile_info_hobby_selected_more{
  flex: none;
  margin: 0 15px 0 8px;
}

.profile_info_hobby_selected_img_item:nth-child(1){
  z-index: 5;
}
.profile_info_hobby_selected_img_item:nth-child(2){
  z-index: 4;
}
.profile_info_hobby_selected_img_item:nth-child(3){
  z-index: 3;
}
.profile_info_hobby_selected_img_item:nth-child(4){
  z-index: 2;
}
.profile_info_hobby_selected_img_item:nth-child(5){
  z-index: 1;
}

.profile_info_about_text_wrap {
  position: relative;
  width: 100%;
  display: flex;
  flex: 1 1;

  span {
    position: absolute;
    bottom: -16px;
    right: 5px;
    font-size: 11px;
    font-weight: 500;
    letter-spacing: 0.01em;
    color: rgba(51, 51, 51, 0.6);
    z-index: 3;

    @media screen and (max-width: 599px) {
      font-size: 10px;
    }
  }
}

.profile_info_about_text{
  display: block;
  flex: 1;
  height: 131px;
  background-color: #fff;
  border: 1px solid $inputBorderNormal;
  border-radius: 8px;
  resize: none;
  padding: 10px 15px;
  color: #333;
  font-size: 12px;
}

.profile_info_about_text::-webkit-scrollbar{
  width: 3px;
  background: #fff;
  border-radius: 3px;
}

.profile_info_about_text::-webkit-scrollbar-thumb{
  background: $scrollbarThumbColor;
  border-radius: 3px;
}

.profile_info_expect_row{
  display: flex;
  align-items: center;
  width: 100%;
  padding-right: 86px;
}

.profile_info_expect_input{
  width: 55%;
  height: 42px;
  background: #fff;
  border: 1px solid #9AAFD0;
  border-radius: 8px;
  padding: 0 15px;
  margin-right: 17px;
  color: #333;
  font-size: 12px;
}

.profile_info_expect_input:hover,
.profile_info_expect_input:focus{
  border-color: #677F92;
}

.profile_info_expect_row .profile_select_wrap .profile_select_head{
  justify-content: center;
  color: #00317B;
  border-color: #00317B;
}

.profile_block_title {
  font-weight: 600;
  font-size: 20px;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  color: $primaryColor;

  span {
    margin-left: 4px;
  }
}

@media screen and (max-width: 1799px){
  .profile_div_head_title,
  .profile_block_title {
    font-size: 15px;
  }

  .profile_div_head_title svg{
    margin-right: 14px;
  }

  .profile_div_head_click_info{
    font-size: 12px;
  }

  .profile_click_edit{
    font-size: 10px;
    margin-left: 11px;
  }
}

@media screen and (max-width: 1599px){
  .text_input{
    height: 38px;
  }

  .profile_label{
    font-size: 12px;
  }

  .profile_select_head{
    height: 38px;
    font-size: 12px;
  }

  .profile_select_head_arrow svg{
    width: 13px;
    height: 7px;
    margin-bottom: 1px;
  }

  .profile_info_hobby_row,
  .profile_info_expect_row{
    padding-right: 0;
  }

  .profile_info_colors_row{
    padding-right: 60px;
  }

  .profile_info_hobby_list_wrap{
    width: 100%;
  }

  .profile_info_hobby_add_btn{
    height: 38px;
  }

  .profile_info_hobby_add_btn_icon{
    width: 22px;
    height: 22px;
  }

  .profile_info_hobby_selected{
    width: calc(100% - 151px);
    height: 38px;
    font-size: 12px;
  }

  .profile_info_hobby_list_item{
    height: 38px;
  }

  .profile_select_color{
    width: 24px;
    height: 24px;
  }
}

@media screen and (max-width: 1449px) and (min-width: 1300px){
  .profile_info_row.alc_smoke .profile_info_row_half{
    max-width: calc(50% - 49px);
  }
}

@media screen and (max-width: 1399px){
  .profile_info_colors_row{
    padding-right: 0;
  }
}

@media screen and (max-width: 1299px){
  .profile_info_row{
    position: relative;
  }

  .profile_click_edit{
    position: absolute;
    right: 0;
    top: calc(100% + 2px);
    margin-left: 0;
  }

  .city_wrap ~ .profile_click_edit,
  .profile_info_row_half ~ .profile_click_edit{
    margin-top: 0;
  }

  .profile_div_head_title,
  .profile_block_title {
    font-size: 12px;
  }

  .profile_div_head_title svg{
    margin-right: 10px;
  }

  .profile_info_row_half{
    width: calc(50% - 12px);
  }

  .profile_info_row_half + .profile_info_row_half{
    margin-left: 24px;
  }

  .profile_info_hobby_add_btn{
    padding: 0 8px;
  }

  .profile_info_hobby_selected{
    width: calc(100% - 135px);
  }

  .profile_info_hobby_list_item.chosen{
    padding: 0 8px;
    width: 136px;
  }

  .profile_select_head span{
    max-width: 165px;
  }
}

@media screen and (max-width: 899px){
  .profile_select_head span{
    max-width: 215px;
  }
}

@media screen and (max-width: 799px){
  .profile_click_edit{
    display: none;
  }

  .profile_div_head_click_info span{
    display: none;
  }

  .profile_div_head_click_info svg{
    margin: 0;
    width: 16px;
    height: 16px;
  }

  .profile_div{
    padding: 20px;
  }

  .profile_div_head_click_info:hover:after,
  .profile_div_head_click_info:hover:before{
    display: flex;
  }
}

@media screen and (max-width: 699px){
  .profile_info_hobby_list_item.chosen{
    width: max-content;
  }
}

@media screen and (max-width: 599px){
  .profile_info_hobby_add_btn span span{
    display: none;
  }

  .profile_info_row_half{
    width: calc(50% - 6px);
  }

  .profile_info_row_half + .profile_info_row_half{
    margin-left: 12px;
  }

  .profile_info_hobby_add_btn{
    width: 100%;
  }

  .profile_info_hobby_row{
    flex-wrap: wrap;
  }

  .profile_info_hobby_selected{
    margin-bottom: 12px;
    width: 100%;
    font-size: 12px;
    margin-right: 0;
  }

  .profile_div_head{
    padding-bottom: 10px;
    margin-bottom: 20px;
  }

  .profile_info_hobby_list_item.chosen{
    width: 100%;
  }

  .profile_info_row .profile_select_wrap + .profile_select_wrap{
    margin-left: 12px;
  }

  .profile_select_wrap.age_range_item{
    width: calc(50% - 6px);
  }
}

@media screen and (max-width: 360px){
  .profile_label {
    font-size: 11px;
  }
}