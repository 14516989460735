@import "@sitestyles";

.online_now_cross_banner_item {
  height: 384px;
  background-color: $accentLeftColor;
  margin-bottom: 16px;
  box-shadow: 3px 3px 5px rgba(197, 196, 208, 0.64);
  border-radius: 10px;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 24px;

  @media screen and (max-width: 1449px){
    height: 282px;
  }

  @media screen and (max-width: 699px){
    height: 186px;
    gap: 16px;
  }
}

.online_now_cross_banner_gallery {
  width: 640px;
  height: 100%;
  overflow: hidden;
  position: relative;
  border-radius: 10px 0 0 10px;
  background: linear-gradient(261deg, $accentLeftColor 0%, $accentRightColor 100%), $whiteColor;

  img {
    width: 112%;
    height: auto;
    transform: rotate(16deg);
    position: absolute;
    bottom: -43%;
    right: 8%;

    @media screen and (max-width: 799px){
      bottom: -16%;
      width: 118%;
    }

    @media screen and (max-width: 699px){
      width: 100%;
      bottom: -50%;
    }

    @media screen and (max-width: 499px){
      bottom: -25%;
      right: 0;
      width: 150%;
    }
  }

  @media screen and (max-width: 799px){
    width: 440px;
  }
}

.online_now_cross_banner_bottom {
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 16px;
  width: 260px;
  height: 100%;
  position: relative;
  z-index: 1;
  margin-right: 24px;

  &::before {
    content: "";
    width: 580px;
    height: 100%;
    background: linear-gradient(269deg, $accentLeftColor 45.95%, rgba(1, 49, 123, 0) 99.56%);
    position: absolute;
    z-index: -1;
    right: 0;
    border-radius: 0 10px 10px 0;

    @media screen and (max-width: 699px){
      width: 330px;
    }

    @media screen and (max-width: 499px){
      background: linear-gradient(270deg, $accentLeftColor 65.66%, rgba(1, 49, 123, 0.00) 100%);
      width: 235px;
    }
  }

  @media screen and (max-width: 699px){
    width: 138px;
    gap: 4px;
  }
}

.online_now_cross_banner_bottom_title {
  font-size: 20px;
  font-weight: 600;
  color: $whiteColor;
  line-height: 120%;

  span {
    text-transform: capitalize;
  }

  @media screen and (max-width: 699px){
    font-size: 14px;
  }
}

.online_now_cross_banner_bottom_text {
  color: $whiteColor;
  font-size: 16px;
  font-weight: 400;
  line-height: 140%;

  @media screen and (max-width: 699px){
    font-size: 12px;
  }
}

.online_now_cross_banner_button {
  padding: 0 24px;
  margin-top: 16px;
  width: 260px;
  height: 40px;
  color: $accentLeftColor;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  text-align: center;
  background-color: $whiteColor;
  //background-image: $buttonBackgroundColor, linear-gradient(to right, $accentLeftColor, $accentRightColor);
  background-clip: padding-box, border-box;
  background-origin: border-box;
  border: double 1px transparent;
  border-radius: 4px;
  cursor: pointer;

  &:hover,
  &:focus {
    box-shadow: $searchItemBtnShadowHover;
  }

  @media screen and (max-width: 699px){
    width: 138px;
    height: 32px;
    margin-top: 12px;
  }
}

