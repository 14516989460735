@import "@sitestyles";

.online_now_cross_banner_item {
  padding: 30px 3.42%;
  margin-bottom: 16px;
  box-shadow: 3px 3px 5px rgba(197, 196, 208, 0.64);
  border-radius: 10px;
  background-color: #fff;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;

  @media screen and (max-width: 1449px){
    padding: 20px 25px;
  }
  @media screen and (max-width: 699px){
    gap: 16px;
    padding: 16px;
  }
}

.online_now_cross_banner_gallery {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  gap: 0;

  img {
    width: 8vw;
    transition: transform 0.3s ease;
    border-radius: 8px;
    border: 4px solid #FFFFFF;
    box-shadow: 0 1.024px 2.048px 0 rgba(9, 53, 28, 0.12);
    position: relative;
    z-index: 0;

    @media screen and (max-width: 1139px){
      width: 10vw;
    }

    @media screen and (max-width: 699px){
      width: 12vw;
    }

    @media screen and (max-width: 499px){
      width: 12vw;
    }
  }

  img:nth-child(3) {
    width: 18vw;
    z-index: 2;

    @media screen and (max-width: 1139px){
      width: 22vw;
    }

    @media screen and (max-width: 699px){
      width: 26vw;
    }

    @media screen and (max-width: 499px){
      width: 28vw;
    }
  }

  img:nth-child(2),
  img:nth-child(4) {
    width: 12vw;
    margin-left: -25px;
    margin-right: -25px;
    z-index: 1;

    @media screen and (max-width: 1139px){
      width: 16vw;
      margin-left: -15px;
      margin-right: -15px;
    }

    @media screen and (max-width: 699px){
      width: 18vw;
      margin-left: -10px;
      margin-right: -10px;
    }

    @media screen and (max-width: 499px){
      width: 20vw;
      margin-left: -8px;
      margin-right: -8px;
    }
  }
}

.online_now_cross_banner_bottom {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.online_now_cross_banner_button {
  padding: 0 24px;
  width: 380px;
  height: 48px;
  background-image: $buttonBackgroundColor, linear-gradient(to right, $accentLeftColor, $accentRightColor);
  background-clip: padding-box, border-box;
  background-origin: border-box;
  border: double 1px transparent;
  border-radius: 4px;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  color: $whiteColor;
  cursor: pointer;

  &:hover {
    box-shadow: $smallBtnShadowHover;
  }

  @media screen and (max-width: 1139px){
    width: 320px;
  }

  @media screen and (max-width: 699px){
    width: 280px;
  }

  @media screen and (max-width: 600px) {
    font-size: 12px;
    line-height: 22px;
    height: 36px;
    width: 100%;
  }
}

.online_now_cross_banner_button_text {
  text-transform: capitalize;
  background: $whiteColor;
  -webkit-background-clip: text;
  -webkit-text-fill-color: $whiteColor;
  background-clip: text;
  text-fill-color: $whiteColor;
}

