@import "@sitestyles";

.category_col{
  flex: 1;
  background-color: #fff;
  box-shadow: 0 6px 12px rgba(47, 43, 99, 0.16);
  border-radius: 10px;
  padding: 8px 24px;

  @media screen and (max-width: 1139px){
    padding: 8px 15px;
  }

  @media screen and (max-width: 767px){
    display: none;

    &.mob_opened{
      display: block;
    }
  }

  .topic_text {
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    color: $accentLeftRightColor;
    margin-bottom: 4px;
    position: relative;
    padding-top: 10px;
    padding-bottom: 15px;
    border-bottom: 1px solid #dedede;

    p {
      font-weight: 500;
      font-size: 14px;
      line-height: 170%;
      color: $primaryColor;

      & + .subcategory_title {
        margin-top: 16px;
      }

      img {
        max-width: 100%;
        display: block;
        margin: 5px auto;
        cursor: zoom-in;
      }

      ul li:nth-of-type(1) {
        margin-top: 18px;
      }

      li {
        margin-left: 18px;
      }

      b {
        color: #00317B;
      }

      @media screen and (max-width: 767px) {
        line-height: 150%;
      }
    }
  }
}

.category_col_mob_head{
  display: flex;
  align-items: center;

  & > *{
    //flex: 1;
  }

  .head_back_btn{
    width: max-content;
    display: flex;
    align-items: center;
    color: #8F9CAC;
    font-size: 12px;
    padding: 5px 5px 5px 0;
    cursor: pointer;


    svg{
      fill: #8F9CAC;
      width: 6px;
      margin-right: 8px;
    }
  }

  .head_title{
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.01em;
    color: $accentLeftRightColor;
    text-align: center;
  }

  @media screen and (min-width: 768px){
    display: none;
  }
}

.category_item{

  &:not(:last-child){
    border-bottom: 1px solid #dedede;
  }

  &.opened{

    .category_title svg{
      transform: rotate(90deg);
    }

    .subcategory{
      display: block;
    }
  }
}

.category_title{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 0;

  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: $accentLeftRightColor;
  cursor: pointer;
  user-select: none;

  @media screen and (max-width: 1499px), screen and (max-height: 699px){
    font-size: 14px;
    padding: 12px 0;
  }

  span{
    margin-right: 40px;
  }

  svg{
    transition: .3s;
    flex: none;

    @media screen and (max-width: 1499px), screen and (max-height: 699px){
      width: 7px;
    }
  }
}

.subcategory{
  display: none;
  margin-bottom: 15px;
  padding-left: 35px;
  word-break: break-word;

  @media screen and (max-width: 767px){
    padding-left: 30px;
  }

  .subcategory_title{
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    color: $accentLeftRightColor;
    margin-bottom: 4px;
    position: relative;

    //&:before{
    //  content: '';
    //  display: block;
    //  width: 7px;
    //  height: 7px;
    //  border-radius: 50%;
    //  background-color: $accentLeftRightColor;
    //  position: absolute;
    //  left: -18px;
    //  top: 6px;
    //}
  }

  p{
    font-weight: 500;
    font-size: 14px;
    line-height: 170%;
    color: $primaryColor;

    & + .subcategory_title{
      margin-top: 16px;
    }

    img{
      max-width: 100%;
      //display: block;
      margin: 5px auto;
      cursor: zoom-in;
    }

    span[data-link] {
      display: block;
      position: relative;

      img {
        cursor: pointer;
      }

      &:after{
        z-index: 2;
        content: '';
        display: block;
        width: 51px;
        height: 52px;
        position: absolute;
        top: calc(50% - 26px);
        left: calc(50% - 26px);
        background-image: url(../../../../src/img/play-btn.svg);
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        cursor: pointer;
      }
    }

    ul li:nth-of-type(1) {
      margin-top: 18px;
    }

    li{
      margin-left: 18px;
    }

    b{
      color: #00317B;
    }

    @media screen and (max-width: 767px){
      line-height: 150%;
    }
  }
}