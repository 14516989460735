@import "@sitestyles";

.reset_pass_modal {
  width: 476px;
  max-width: 100%;
  background-color: #fff;
  padding: 32px 40px;
  position: relative;
  border-radius: 8px;

  @media screen and (max-width: 600px) {
    padding: 24px 16px;
    width: 343px;
  }
  @media screen and (max-width: 350px) {
    width: 304px;
  }
}

.reset_pass_title {
  font-weight: 600;
  font-size: 18px;
  background: $buttonBackgroundColor;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  font-family: $mainFont;
  text-align: center;
  margin-bottom: 16px;

  &:not(:first-child) {
    padding-top: 16px;
    border-top: 1px solid #ccc;
  }

  @media screen and (max-width: 599px) {
    font-size: 16px;
  }
}

.reset_pass_form {
  max-width: 400px;
  margin: 0 auto 24px;

  @media screen and (max-width: 599px) {
    margin-bottom: 16px;
    max-width: 304px;
  }
}

.reset_pass_form_input_wrap {
  margin-bottom: 16px;
  position: relative;
  border: 1px solid transparent;
  border-bottom: none;
}

.reset_pass_form_input_wrap.opened {
  border: 1px solid #9F9F9F;
  border-bottom: none;
  border-radius: 5px 5px 0 0;
}

.reset_pass_form_input_wrap.opened .registration_form_emails_list_wrap {
  display: block;
}


.reset_pass_form_input_wrap input {
  display: block;
  width: 100%;
  flex: 1 1;
  height: 48px;
  padding: 13px 16px;
  font-weight: 600;
  line-height: 22px;
  font-size: 16px;
  color: $InputSelectedTextColor;
  background: #F8F8F8;
  border-radius: 5px;
  box-sizing: border-box;
  font-family: $mainFont;

  &::placeholder {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #9F9F9F;
    font-family: $mainFont;
  }

  &:focus,
  &:active {
    //border: 1px solid #9F9F9F;
    color: $greyColor;
    font-weight: 600;
    //font-family: $mainFont;
  }

  &:-webkit-autofill {
    font-weight: 600 !important;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active,
  &:-webkit-autofill::first-line {
    color: $attachTouchText;
    -webkit-text-fill-color: $attachTouchText;
    -webkit-box-shadow: 0 0 0 1000px #F8F8F8 inset;
    font-weight: 600 !important;
    font-family: $mainFont;
    font-size: 16px;

    line-height: 22px;
  }


  @media screen and (max-width: 536px) {
    font-size: 14px;
    line-height: 19px;

    &::placeholder,
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active,
    &:-webkit-autofill::first-line {
      font-size: 14px;
      line-height: 19px;
    }
  }

  &.error {
    border: 1px solid #9B3B3B;
    background: #FFEEEE;

    &::placeholder {
      color: #9B3B3B;
    }
  }
}

.registration_form_emails_list_wrap {
  display: none;
  position: absolute;
  border: 1px solid #9F9F9F;
  border-top: none;
  z-index: 3;
  background: #F8F8F8;
  border-radius: 0 0 5px 5px;
  width: 396px;
  top: 45px;
  left: -1px;

  @media screen and (max-width: 600px) {
    width: 304px;
  }
  @media screen and (max-width: 350px) {
    width: 255px;
  }
}

.registration_form_emails_list {
  max-height: 192px;
  overflow-y: auto;
  margin-right: 5px;
  padding-right: 2px;
  border-radius: 0 0 5px 5px;
}


.registration_form_emails_list::-webkit-scrollbar {
  width: 3px;
  background: $littleGreyLittleBlueColor;
  border-radius: 5px;
  margin-right: 3px;
}

.registration_form_emails_list::-webkit-scrollbar-thumb {
  background: $scrollbarThumbColor;
  border-radius: 7px;
}


.registration_form_emails_list_item {
  background: #F8F8F8;
  height: 48px;
  padding: 13px 16px;
  color: #4F4F4F;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  cursor: pointer;

  &:hover {
    background: #EFEFEF;
  }

  @media screen and (max-width: 600px) {
    font-size: 14px;
    line-height: 19px;
  }
}


.reset_pass_form_btn {
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  color: $whiteColor;
  cursor: pointer;
  font-family: $mainFont;
  position: relative;
  background-clip: padding-box, border-box;
  background-origin: border-box;
  border: double 1px transparent;
  border-radius: 5px;
  transition: $ButtonTransitional;
  background-image: $buttonBackgroundColor, linear-gradient(to right, $accentLeftColor, $accentRightColor);

  &:hover {
    background-image: linear-gradient(white, white), linear-gradient(to right, $accentLeftColor, $accentRightColor);

    .reset_pass_form_btn_text {
      background: $buttonBackgroundColor;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }
  }

  @media screen and (max-width: 600px) {
    font-size: 14px;
    line-height: 19px;
  }

}

.reset_pass_form_btn_text {
  background: #FFFFFF;
  -webkit-background-clip: text;
  -webkit-text-fill-color: #FFFFFF;
  background-clip: text;
  text-fill-color: #FFFFFF;
  font-weight: 700;
}

.reset_pass_instruction_item {
  display: flex;
  align-items: center;

  &:not(:last-child) {
    padding-bottom: 16px;
    border-bottom: 1px solid #ccc;
  }

  &:not(:first-child) {
    padding-top: 16px;
  }

  .item_img {
    width: 90px;
    min-width: 90px;
    height: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 17px;
    position: relative;
    z-index: 1;

    &::before {
      content: '';
      width: 90px;
      height: 90px;
      border-radius: 50%;
      background-color: $iconResetPasswordBackgroundColor;
      position: absolute;
      z-index: -1;
    }

    svg {
      width: 50px;
      height: 50px;
      fill: $iconResetPasswordFillColor;
    }
  }

  .item_content {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
  }

  .item_content_title {
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
    color: $attachTouchText;
  }

  .item_content_text {
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
    color: $greyColor;
  }

  @media screen and (max-width: 1139px), screen and (max-height: 800px) {
    &:not(:last-child) {
      padding-bottom: 10px;
    }

    &:not(:first-child) {
      padding-top: 10px;
    }

    .item_img {
      width: 70px;
      height: 70px;
      min-width: 70px;

      &::before {
        width: 70px;
        height: 70px;
      }

      svg {
        width: 45px;
        height: 45px;
      }
    }

    .item_content {
      row-gap: 8px;
    }

    .item_content_text {
      font-size: 14px;
    }
  }

  @media screen and (max-width: 599px) {
    .item_img {
      width: 50px;
      height: 50px;
      min-width: 50px;
      margin-right: 10px;

      &::before {
        width: 50px;
        height: 50px;
      }

      svg {
        width: 30px;
        height: 30px;
      }
    }

    .item_content_title {
      font-size: 14px;
    }

    .item_content_text {
      font-size: 12px;
    }
  }
}

.reset_pass_close {
  position: absolute;
  right: 10px;
  top: 10px;
  padding: 5px;
  cursor: pointer;

  @media screen and (max-width: 599px) {
    right: 5px;
    top: 5px;
  }

  svg {
    display: block;
    width: 24px;
    height: 24px;
    fill: #C4C4C4;

    @media screen and (max-width: 599px) {
      width: 17px;
      height: 17px;
    }
  }
}
