.wrapper {
  border-radius: 8px;
  border: 1px solid #E4E4E4;
  background: #F8F8F8;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
  padding: 12px;
  box-sizing: border-box;
  cursor: pointer;
  transition: all .3s ease;

  &:hover {
    border: 1px solid #D8D8D8;
    background: #EFEFEF;
    transition: all .3s ease;
  }

  @media (max-width: 600px) {
    padding: 12px 8px;
  }
}

.wrapper_checked {
  border: 1px solid #6D6AE5;
  background: #F2F2FF;
  transition: all .3s ease;

  span{
    color: #6D6AE5;
    transition: all .3s ease;
  }

  &:hover {
    border: 1px solid #6D6AE5;
    background: #F2F2FF;
  }
}

.custom_radio {
  position: absolute;
  display: inline-block;
  opacity: 0;

  & + label {
    cursor: pointer;
    display: inline-block;
    position: relative;
    white-space: break-spaces;
    transition: all 0.5s ease-in-out;
    padding-left: 36px;
    text-align: left;

    &::before,
    &::after {
      content: url("../../../../img/onboarding/icon_radio.svg");
      position: absolute;
      text-align: center;
      border-radius: 0;
      top: -2px;
      left: 0;
      width: 24px;
      height: 24px;
      transition: all 0.5s ease-in-out;
    }

    &::before {
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      line-height: 1rem;
      border-radius: 0;
    }

    &:hover,
    &:focus,
    &:active {
      transition: all 0.3s ease;
      outline: none;

      &::before {
        transition: all .3s ease;
      }
    }
  }

  &:focus,
  &:hover,
  &:checked {
    & + label {
      color: #220000 !important;
    }

    & + label::before {
      transition: all .3s ease;
    }
  }

  &:checked {
    & + label::before {
      content: url("../../../../img/onboarding/icon_radio_checked.svg");
    }
  }

  & + label {
    &::before {
      border-radius: 50%;
    }
  }

  &[type=checkbox] {
    & + label {
      &::before {
        border-radius: 0;
      }
    }
  }
}