.search_hover {
  transition: background-color .3s ease;

  &:hover {
    background-color: #EBEDFA;
    transition: background-color .3s ease;
  }
}

.filters_wrap {
  max-width: 320px;
  border-radius: 10px 0 10px 10px;
  right: 0;
  left: auto;
  padding: 16px;

  @media (max-width: 1140px) {
    display: none;
  }
}

.filters_mobile {
  //max-height: 400px !important;
  border-radius: 0 8px 8px 0;
  padding-right: 12px !important;
  padding-left: 12px;
  box-sizing: border-box;

  @media (min-width: 1140px) {
    display: none;
  }
}

.submit {
  width: 100%;
}