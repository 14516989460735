@import "@sitestyles";

.profile_page_main_div {
  /*display: none;
*/
}

.c3_profile_head {
  height: 100px;
  width: 100%;
}

.edit-profile-column {
  background-color: $bleachColor;
  position: relative;
}

/* profile support*/
.popup_manprof_change_email_item .popup_manprof_change_password_input {
  padding-right: 17px;
}

.c3_profile_support_wrap {
  padding: 40px 10px 0 0;
}

.c3_profile_support_wrap2 {
  padding-left: 24px;
  padding-right: 14px;
  height: calc(100vh - 140px);
  overflow-y: auto;
}

.c3_profile_support_wrap2::-webkit-scrollbar {
  width: 3px;
  background: $whiteColor;
  border-radius: 22px;
}

.c3_profile_support_wrap2::-webkit-scrollbar-thumb {
  background: $scrollbarThumbColor;
  border-radius: 9px;
}

.c3_profile_support_list_date {
  font-weight: 600;
  font-size: 24px;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  color: $primaryColor;
  margin-bottom: 21px;
}

.c3_profile_support_item_wrap + .c3_profile_support_list_date {
  margin-top: 34px;
}

.c3_profile_support_item_wrap {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
}

.c3_profile_support_item_wrap.item_left {
  justify-content: flex-start;
}

.c3_profile_support_item_wrap.item_right {
  justify-content: flex-end;
}

.c3_profile_support_item {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  box-shadow: 0px 6px 12px rgba(47, 43, 99, 0.16);
  border-radius: 10px;
  padding: 20px 23px 21px 21px;
  margin-bottom: 16px;
  width: 90%;
}

.item_left .c3_profile_support_item {
  background: $background5Color;
}

.item_right .c3_profile_support_item {
  background-color: $whiteColor;
}

.c3_profile_support_item_from {
  text-align: center;
  width: 80px;
  margin-right: 31px;
  flex: none;
}

.c3_profile_support_item_from img {
  width: 56px;
  height: 56px;
  border-radius: 100%;
  object-fit: cover;
  margin-bottom: 11px;
}

.c3_profile_support_item_name {
  font-size: 16px;
  color: $stormGreyColor;
}

.c3_profile_support_item_name_status {
  font-size: 10px;
  color: $defaultTextColorAlpha5;
}

.c3_profile_support_item_content {
  flex: 1;
}

.c3_profile_support_item_content_head {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: space-between;
  -ms-align-items: center;
  align-items: center;
}

.c3_profile_support_item_subject {
  font-weight: 600;
  font-size: 18px;
  letter-spacing: 0.01em;
  color: $stormGreyColor;
}

.c3_profile_support_item_req_id {
  font-size: 12px;
  letter-spacing: -0.03em;
  color: $defaultTextColorAlpha7;
}

.c3_profile_support_item_text {
  font-size: 16px;
  line-height: 24px;
  color: $defaultTextColorAlpha9;
  margin-top: 5px;
}

.c3_profile_support_item_time {
  text-align: right;
  font-size: 12px;
  letter-spacing: -0.03em;
  color: $defaultTextColorAlpha7;
  margin-top: 2px;
}

.c3_profile_support_item_media {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  margin-top: 5px;
}

.c3_profile_support_item_media img {
  width: 130px;
  height: 73px;
  object-fit: cover;
  border-radius: 5px;
  margin-right: 10px;
}

/* profile support popup */

.popup_new_appeal {
  display: none;
  justify-content: center;
  -ms-align-items: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  background: $fadeColor;
  z-index: 9999;
}

.popup_new_appeal.active {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
}

.popup_new_appeal_wrap {
  width: 941px;
  max-width: calc(100% - 30px);
  background: $whiteColor;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.16);
  border-radius: 10px;
  padding: 32px 36px 36px;
  position: relative;
}

.popup_new_appeal_title {
  font-weight: 600;
  font-size: 24px;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  color: $primaryColor;
  text-align: center;
}

.popup_new_appeal_row {
  margin-bottom: 24px;
}

.popup_new_appeal_topic {
  letter-spacing: 0.06em;
  text-transform: uppercase;
  color: $stormGreyColor;
  margin-bottom: 8px;
}

.popup_new_appeal_textarea {
  display: block;
  width: 100%;
  height: 243px;
  border: none;
  background-color: $bleachColor;
  border-radius: 4px;
  outline: none;
  resize: none;
  padding: 5px 12px;
}

.popup_new_appeal_send_btn {
  width: 174px;
  height: 42px;
  line-height: 42px;
  text-align: center;
  background: linear-gradient(90.52deg, $accentLeftColor 0%, $accentRightColor 100%);
  border-radius: 4px;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 0.01em;
  color: $whiteColor;
  margin-left: auto;
  cursor: pointer;
}

.popup_new_appeal_close {
  position: absolute;
  right: 14px;
  top: 14px;
  cursor: pointer;
  padding: 5px;
}

.popup_new_appeal_close img {
  display: block;
  width: 20px;
  opacity: .5;
}

/* profile support popup select */


.popup_new_appeal_select .choices__list--single .choices__item {
  width: 100%;
  height: 42px;
  line-height: 44px;
  background-color: $bleachColor;
  border-radius: 5px;
  font-weight: 500;
  font-size: 14px;
  color: $defaultTextColorAlpha5;
  padding: 0px 12px;
  position: relative;
  outline: none;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.05);
}

.popup_new_appeal_select .choices__list--dropdown .choices__item {
  width: 100%;
  height: 42px;
  line-height: 42px;
  background-color: $bleachColor;
  font-weight: 500;
  font-size: 14px;
  color: $defaultTextColorAlpha8;
  padding: 0 12px;
  outline: none;
  margin-bottom: 2px;
}

.popup_new_appeal_select .choices__list--dropdown {
  background-color: $bleachColor;
  border-radius: 0 0 5px 5px;
  box-shadow: 0px 6px 12px rgba(47, 43, 99, 0.16);
  padding: 0 0 12px;
  max-height: 360px;
}

.popup_new_appeal_select .choices__list--dropdown .is-highlighted {
  background-color: $background5Color;
}

.popup_new_appeal_select .is-open .choices__list--single .choices__item {
  border-radius: 5px 5px 0 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  position: relative;
  z-index: 3;
}

.popup_new_appeal_select .is-open .choices__list--dropdown {
  z-index: 2;
}

.popup_new_appeal_select .choices__list--single.has-selected .choices__item {
  color: $accentLeftColor;
}

.popup_new_appeal_select .choices__item--disabled {
  display: none;
}

.popup_new_appeal_select .choices__list::-webkit-scrollbar {
  width: 3px;
  background: $whiteColor;
  border-radius: 5px;
}

.popup_new_appeal_select .choices__list::-webkit-scrollbar-thumb {
  background: $scrollbarThumbColor;
  border-radius: 7px;
}

/* profile support end*/
/* profile gifts*/

.c3_profile_gifts_wrap {
  padding: 16px 16px 0 0;
  height: 100%;
}

.c3_profile_gifts_wrap2 {
  height: 100%;
  overflow-y: auto;
  padding: 0 14px 16px 24px;
}

.c3_profile_gifts_wrap2::-webkit-scrollbar {
  width: 3px;
  background: $whiteColor;
  border-radius: 22px;
}

.c3_profile_gifts_wrap2::-webkit-scrollbar-thumb {
  background: $scrollbarThumbColor;
  border-radius: 9px;
}

.c3_profile_filters {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  margin-bottom: 25px;
}

.c3_profile_filters_title {
  font-weight: 600;
  font-size: 18px;
  line-height: 140%;
  color: $accentLeftColor;
  margin-right: 17px;
}

.c3_profile_filters_item {
  padding: 5px 10px 3px;
  font-size: 18px;
  line-height: 140%;
  color: $primaryColor;
  border: 1px solid transparent;
  border-radius: 4px;
  cursor: pointer;
}

.c3_profile_filters_item.active {
  border-color: $primaryColor;
}

/* profile gifts item*/

.c3_profile_gifts_item {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -o-flex-wrap: wrap;
  flex-wrap: wrap;
  background-color: $whiteColor;
  padding: 24px;
  box-shadow: 0px 6px 12px rgba(47, 43, 99, 0.16);
  border-radius: 10px;
  margin-bottom: 16px;
}

.c3_profile_gifts_item:last-child {
  margin-bottom: 0;
}

.c3_profile_gifts_item_gift_photo {
  width: 193px;
  height: 193px;
  object-fit: cover;
  margin-right: 24px;
  border-radius: 5px;
  grid-area: pr_gift_item_photo;
}

.c3_profile_gifts_item_right {
  flex: 1;
  -ms-align-self: flex-start;
  align-self: flex-start;
  padding-top: 20px;
}

.c3_profile_gifts_item_top {
  display: grid;
  grid-template-areas: "pr_gift_item_photo pr_gift_item_id pr_gift_item_id pr_gift_item_id"
						 "pr_gift_item_photo pr_gift_item_info pr_gift_item_to pr_gift_item_photos";
  grid-template-columns: 217px minmax(200px, 383px) 1fr 346px;
  grid-template-rows: 24px 169px;
  width: 100%;
  justify-content: space-between;
}

.c3_profile_gifts_item_id {
  font-weight: 500;
  font-size: 12px;
  color: $stormGreyColor;
  margin-bottom: 9px;
  width: 100%;
  grid-area: pr_gift_item_id;
}

.c3_profile_gifts_item_main_info {
  grid-area: pr_gift_item_info;
}

.c3_profile_gifts_item_content {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: space-between;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -o-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-bottom: 24px;
  border-bottom: 1px solid $defaultTextColorAlpha1;
}

.c3_profile_gifts_item_gift_name {
  font-weight: 600;
  font-size: 20px;
  color: $accentRightColor;
  width: 280px;
  margin-bottom: 9px;
}

.c3_profile_gifts_item_gift_price {
  font-weight: 500;
  font-size: 16px;
  color: $stormGreyColor;
}

.c3_profile_gifts_item_gift_price span {
  font-weight: 600;
  margin-left: 16px;
}

.c3_profile_gifts_item_to {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  grid-area: pr_gift_item_to;
  padding-bottom: 50px;
}

.c3_profile_gifts_item_to_text {
  font-weight: 500;
  font-size: 18px;
  color: $defaultTextColorAlpha8;
  margin-right: 18px;
}

.c3_profile_gifts_item_to_photo {
  margin-right: 14px;
}

.c3_profile_gifts_item_to_photo img {
  width: 72px;
  height: 72px;
  border-radius: 100%;
  object-fit: cover;
  display: block;
}

.c3_profile_gifts_item_to_id {
  font-size: 12px;
  color: $arsenicColor;
}

.c3_profile_gifts_item_to_name {
  font-weight: bold;
  font-size: 18px;
  line-height: 34px;
  color: $primaryColor;
}

.c3_profile_gifts_item_to_place {
  font-weight: 500;
  font-size: 12px;
  color: $arsenicColor;
}

.c3_profile_gifts_item_to_place img {
  width: 16px;
}

.c3_profile_gifts_item_girls_photos {
  grid-area: pr_gift_item_photos;
}

.c3_profile_gifts_item_girls_photos_text {
  font-weight: 600;
  font-size: 18px;
  color: $primaryColor;
  margin-bottom: 11px;
}

.c3_profile_gifts_item_girls_photos_list {
  display: flex;
  padding-right: 26px;
  overflow-x: auto;
  padding-bottom: 5px;
  list-style: none;
}

.c3_profile_gifts_item_girls_photos_list::-webkit-scrollbar {
  height: 5px;
  background: #fff;
  border-radius: 3px;
}

.c3_profile_gifts_item_girls_photos_list::-webkit-scrollbar-thumb {
  background: $scrollbarThumbColor;
  border-radius: 3px;
}

.c3_profile_gifts_item_girls_photos_list img,
.c3_profile_gifts_item_girls_photos_list li {
  width: auto;
  height: 72px;
  border-radius: 5px;
  margin-right: 7px;
  cursor: pointer;
}

.c3_profile_gifts_item_girls_photos_list img:last-child,
.c3_profile_gifts_item_girls_photos_list li:last-child {
  margin-right: 0;
}

.c3_profile_gifts_item_bottom {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  width: 100%;
  margin-left: 217px;
  margin-top: -43px;
  border-top: 1px solid $defaultTextColorAlpha1;
  padding-top: 21px;
  justify-content: space-between;
}

.c3_profile_gifts_item_gift_date {
  font-weight: 500;
  font-size: 16px;
  color: $defaultTextColorAlpha8;
}

.c3_profile_gifts_item_gift_date span {
  margin-left: 18px;
  font-weight: 600;
  color: $stormGreyColor;
}

.c3_profile_gifts_item_gift_date.date_sent {
  /*width: 383px;
*/
}

.c3_profile_gifts_item_gift_date.date_delivered {
  width: 346px;
}

.c3_profile_gifts_item_gift_status {
  font-weight: 600;
  font-size: 18px;
  color: $primaryColor;
  text-transform: uppercase;
  width: auto;
}

.c3_profile_gifts_item_gift_status span {
  margin-left: 18px;
  text-transform: none;
}

.c3_profile_gifts_item_gift_status.approved span {
  color: $onlineColor;
}

.c3_profile_gifts_item_gift_status.decline span {
  color: $counterAndErrorColor;
}

.c3_profile_gifts_item_gift_status.wip span {
  color: $accentLeftColor;
}

.c3_profile_gifts_item_decline_reason {
  width: 346px;
  margin-top: -30px;
  grid-area: pr_gift_item_photos;
}

.c3_profile_gifts_item_decline_reason_title {
  font-weight: 600;
  font-size: 18px;
  color: $primaryColor;
  margin-bottom: 5px;
}

.c3_profile_gifts_item_decline_reason_text {
  font-size: 14px;
  line-height: 19px;
  color: $defaultTextColorAlpha8;
}

.c3_profile_gifts_item_empty_third {
  width: 346px;
  grid-area: pr_gift_item_photos;
}

/* profile gifts end*/
/* profile webcam*/

.c3_profile_webcam_wrap {
  padding: 28px 10px 0 0;
}

.c3_profile_webcam_wrap2 {
  height: calc(100vh - 128px);
  overflow-y: auto;
  padding: 0 14px 0 24px;
}

.c3_profile_webcam_wrap2::-webkit-scrollbar {
  width: 3px;
  background: $whiteColor;
  border-radius: 22px;
}

.c3_profile_webcam_wrap2::-webkit-scrollbar-thumb {
  background: $scrollbarThumbColor;
  border-radius: 9px;
}

.c3_profile_webcam_list_item {
  padding: 24px;
  background-color: $whiteColor;
  box-shadow: 0px 6px 12px rgba(47, 43, 99, 0.16);
  border-radius: 10px;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  margin-bottom: 16px;
}

.c3_profile_webcam_list_item_girl {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  width: 277px;
}

.c3_profile_webcam_list_item_rigth {
  flex: 1;
}

.c3_profile_webcam_list_item_top {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  justify-content: space-between;
}

.c3_profile_webcam_list_item_span_text {
  font-weight: 500;
  font-size: 16px;
  color: $defaultTextColorAlpha8;
}

.c3_profile_webcam_list_item_span_text br {
  display: none;
}

.c3_profile_webcam_list_item_span_text.c3_profile_webcam_list_item_request_date {
  width: 290px;
}

.c3_profile_webcam_list_item_span_text.c3_profile_webcam_list_item_call_dur {
  width: 290px;
}

.c3_profile_webcam_list_item_span_text span {
  font-weight: 600;
  color: $stormGreyColor;
  margin-left: 18px;
}

.c3_profile_webcam_list_item_preferable {
  width: 413px;
}

.c3_profile_webcam_list_item_pref_date {
  margin-bottom: 13px;
}

.c3_profile_webcam_list_item_bottom {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: space-between;
  margin-top: 18px;
  padding-top: 12px;
  border-top: 1px solid $defaultTextColorAlpha1;
}

.c3_profile_webcam_list_item_appr_date {
  width: 413px;
}

.c3_profile_webcam_list_item_top .c3_profile_gifts_item_gift_status {
  width: 197px;
}

.c3_profile_webcam_list_item_decline_reason_title {
  font-weight: 600;
  font-size: 18px;
  color: $primaryColor;
  flex: none;
  margin-right: 14px;
}

.c3_profile_webcam_list_item_decline_reason_text {
  line-height: 24px;
  color: $defaultTextColorAlpha8;
}

/* profile webcam end*/
/* profile edit*/

.c3_profile_edit_wrap {
  padding: 0 10px 0 0;
  padding-right: 10px;
}

.c3_profile_edit_wrap2 {
  height: calc(100vh - 100px);
  height: calc(calc(var(--vh, 1vh) * 100) - 100px);
  overflow-y: auto;
  padding: 0 14px 30px 24px;
}

.need_confirm_email .c3_profile_edit_wrap2 {
  height: calc(100vh - 180px);
  height: calc(calc(var(--vh, 1vh) * 100) - 180px);
}

.c3_profile_edit_wrap2::-webkit-scrollbar {
  width: 3px;
  background: $whiteColor;
  border-radius: 22px;
}

.c3_profile_edit_wrap2::-webkit-scrollbar-thumb {
  background: $scrollbarThumbColor;
  border-radius: 9px;
}

.c3_profile_edit_main_information_wrap {
  background-color: $whiteColor;
  box-shadow: 0px 6px 12px rgba(47, 43, 99, 0.16);
  border-radius: 10px;
  padding: 32px 24px 24px;
  margin-bottom: 24px;
}

.c3_profile_edit_block {
  margin-top: 50px;
}


.c3_profile_edit_block_item .cl2h_tab_wide_filter_row2 {
  height: 42px;
}

.c3_profile_edit_block_title {
  font-weight: 600;
  font-size: 24px;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  color: $primaryColor;
  margin-bottom: 23px;
}

.c3_profile_edit_block_list {
  display: grid;
  grid-template-columns: repeat(3, calc((100% - 50px) / 3));
  grid-gap: 25px;
}

.c3_profile_edit_big_block_right .c3_profile_edit_block {
  grid-template-columns: repeat(2, 1fr);
  margin-top: 79px;
}

.c3_profile_edit_block_item {
  position: relative;
  width: 100%;
}

.c3_profile_edit_block_list .fill_data_form_select_item_wrap {
  width: 100%;
  margin: 0;
}

.c3_profile_edit_block_item > label {
  letter-spacing: 0.06em;
  text-transform: uppercase;
  color: $stormGreyColor;
  margin-bottom: 10px;
  display: block;
  width: 100%;
}

.c3_profile_edit_textarea_wrap {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: space-between;
}

.c3_profile_edit_textarea_item {
  width: calc(50% - 19px);
}

.c3_profile_edit_textarea_wrap2 {
  background-color: $bleachColor;
  padding: 22px 9px 22px 22px;

}

.c3_profile_edit_textarea {
  display: block;
  border: none;
  width: 100%;
  height: 428px;
  background-color: $bleachColor;
  border-radius: 4px;
  resize: none;
  outline: none;
  font-size: 16px;
  line-height: 150%;
  color: $stormGreyColor;
  padding-right: 10px;
}

.c3_profile_edit_textarea::-webkit-scrollbar {
  width: 3px;
  background: $whiteColor;
  border-radius: 22px;
}

.c3_profile_edit_textarea::-webkit-scrollbar-thumb {
  background: $accentLeftColor;
  border-radius: 9px;
}

.c3_profile_edit_block_subtitle {
  font-weight: 600;
  font-size: 22px;
  letter-spacing: 0.06em;
  color: $accentRightColor;
  margin-bottom: 23px;
}

.c3_profile_edit_block_photo_list {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -o-flex-wrap: wrap;
  flex-wrap: wrap;
}

.c3_profile_edit_block_photo_item {
  position: relative;
  margin-right: 15px;
  margin-bottom: 16px;
}

.c3_profile_edit_block_photo_item:after {
  content: '';
  display: block;
  width: 167px;
  max-width: 50%;
  height: 100%;
  position: absolute;
  right: -1px;
  top: 0;
  background: linear-gradient(-90deg, $whiteColor 0%, $transparentColor 100%);
}

.c3_profile_edit_block_photo_item_img {
  height: 300px;
  width: auto;
  border-radius: 10px;
}

.c3_profile_edit_block_photo_item.add_photo {
  width: 300px;
  height: 300px;
  background-color: $trueGreyColor;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  justify-content: center;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  -o-flex-direction: column;
  flex-direction: column;
  cursor: pointer;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
}

.c3_profile_edit_block_photo_item.add_photo:after {
  display: none;
}

.c3_profile_edit_block_photo_item.add_photo span {
  font-weight: 500;
  font-size: 20px;
  line-height: 140%;
  color: $defaultTextColorAlpha6;
  margin-top: 16px;
}

.c3_profile_edit_block_photo_item_close {
  position: absolute;
  right: 5px;
  top: 10px;
  padding: 5px;
  z-index: 2;
  cursor: pointer;
}

.c3_profile_edit_block_photo_item_close img {
  display: block;
}

.c3_profile_save_edit_btn {
  width: 363px;
  height: 57px;
  line-height: 57px;
  text-align: center;
  background: linear-gradient(90.8deg, $accentLeftColor 0%, $accentRightColor 100%);
  border-radius: 4px;
  font-weight: 600;
  font-size: 24px;
  letter-spacing: 0.01em;
  color: $whiteColor;
  margin: 34px auto 0;
  cursor: pointer;
}

.c3_profile_save_edit_btn:hover {
  box-shadow: $searchItemBtnShadowHover;
}

.c3_profile_save_edit_btn img {
  vertical-align: middle;
  margin-bottom: 4px;
  margin-right: 17px;
}

.c3_profile_edit_big_block_head {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: space-between;
}

.c3_profile_edit_big_block_id {
  font-size: 18px;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  color: $defaultTextColor;
}

.c3_profile_edit_big_photo {
  width: calc((100% - 50px) / 3);
  position: relative;
  margin-right: 25px;
  align-self: center;
}

.c3_profile_edit_big_photo_wrap {
  position: relative;
  height: 100%;
}

.c3_profile_edit_big_photo_wrap img {
  width: 100%;
  height: auto;
  max-height: 375px;
  object-fit: contain;
}

.c3_profile_edit_big_photo_edit {
  position: absolute;
  left: 0;
  bottom: -1px;
  width: 100%;
  height: 113px;
  background: linear-gradient(0deg, $whiteColor 0%, $transparentColor 100%);
  border-radius: 5px 5px 0px 0px;
  text-align: center;
  font-weight: 500;
  font-size: 18px;
  color: $defaultTextColor;
  padding-top: 63px;
  cursor: pointer;
}

.c3_profile_edit_big_block {
}

.c3_profile_edit_big_block_top {
  display: flex;
}

.c3_profile_edit_big_block_row {
  position: relative;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  margin-bottom: 26px;
}

.c3_profile_edit_big_block_right {
  position: relative;
  flex: 1;
}

.c3_profile_edit_big_block_item {
  width: 410px;
}

.c3_profile_edit_big_block_item_town {
  margin-left: 22px;
}

.c3_profile_edit_big_block_item > label {
  letter-spacing: 0.06em;
  text-transform: uppercase;
  color: $stormGreyColor;
  margin-bottom: 7px;
  display: block;
  width: 100%;
}

.c3_profile_edit_big_block_item input[type=text] {
  display: block;
  width: 100%;
  height: 60px;
  background: $bleachColor;
  border-radius: 4px;
  padding: 0 10px 0 22px;
  font-weight: 600;
  font-size: 24px;
  line-height: 140%;
  color: $accentLeftColor;
  outline: none;
}

.c3_profile_edit_big_block_item input:not(:placeholder-shown) {
  background-color: $background5Color;
}

.c3_profile_edit_big_block_item.c3_profile_edit_big_block_email {
  margin-left: 22px;
  align-self: flex-end;
}

.c3_profile_edit_big_block_item.c3_profile_edit_big_block_email input {
  font-size: 18px;
}

/* profile edit select custom */

.c3_profile_edit_block_item.half {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: space-between;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -o-flex-wrap: wrap;
  flex-wrap: wrap;
}

.c3_profile_edit_block_item.half .cl2h_tab_wide_filter_item_wrap {
  width: 48%;
}

.c3_profile_edit_block_item.half .cl2h_tab_wide_filter_item {
  /*width: 48%;
*/
}

.c3_profile_edit_block_item .choices__list--single .choices__item {
  width: 100%;
  height: 42px;
  line-height: 44px;
  background-color: $bleachColor;
  border-radius: 5px;
  font-weight: 500;
  font-size: 14px;
  color: $defaultTextColorAlpha5;
  padding: 0px 12px;
  position: relative;
  outline: none;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.05);
}

.c3_profile_edit_block_item .choices__list--dropdown .choices__item {
  width: 100%;
  height: 42px;
  line-height: 42px;
  background-color: $bleachColor;
  font-weight: 500;
  font-size: 14px;
  color: $defaultTextColorAlpha8;
  padding: 0 12px;
  outline: none;
  margin-bottom: 2px;
}

.c3_profile_edit_block_item .choices__list--dropdown {
  background-color: $bleachColor;
  border-radius: 0 0 5px 5px;
  box-shadow: 0px 6px 12px rgba(47, 43, 99, 0.16);
  padding: 0 0 12px;
  max-height: 360px;
}

.c3_profile_edit_block_item .choices__list--dropdown .is-highlighted {
  background-color: $background5Color;
}

.c3_profile_edit_block_item .is-open .choices__list--single .choices__item {
  border-radius: 5px 5px 0 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  position: relative;
  z-index: 3;
}

.c3_profile_edit_block_item .is-open .choices__list--dropdown {
  z-index: 2;
}

.c3_profile_edit_block_item .choices__list--single.has-selected .choices__item {
  color: $accentLeftColor;
}

.c3_profile_edit_block_item .choices__item--disabled {
  display: none;
}

.c3_profile_edit_block_item .choices__list::-webkit-scrollbar {
  width: 3px;
  background: $whiteColor;
  border-radius: 5px;
}

.c3_profile_edit_block_item .choices__list::-webkit-scrollbar-thumb {
  background: $scrollbarThumbColor;
  border-radius: 7px;
}

/* profile edit select custom  big*/

.c3_profile_edit_big_block_item.date_of_birth {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: space-between;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -o-flex-wrap: wrap;
  flex-wrap: wrap;
}

.c3_profile_edit_big_block_item.date_of_birth .choices:nth-child(2) {
  width: 100px;
}

.c3_profile_edit_big_block_item.date_of_birth .choices:nth-child(3) {
  width: 145px;
}

.c3_profile_edit_big_block_item.date_of_birth .choices:nth-child(4) {
  width: 145px;
}

.c3_profile_edit_big_block_item .choices__list--single .choices__item {
  width: 100%;
  height: 60px;
  line-height: 64px;
  background-color: $bleachColor;
  border-radius: 5px;
  font-weight: 500;
  font-size: 18px;
  color: $defaultTextColorAlpha5;
  padding: 0px 12px;
  position: relative;
  outline: none;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.05);
}

.c3_profile_edit_big_block_item .choices__list--dropdown .choices__item {
  width: 100%;
  height: 62px;
  line-height: 64px;
  background-color: $bleachColor;
  font-weight: 500;
  font-size: 18px;
  color: $defaultTextColorAlpha8;
  padding: 0 12px;
  outline: none;
  margin-bottom: 2px;
}

.c3_profile_edit_big_block_item .choices__list--dropdown {
  background-color: $bleachColor;
  border-radius: 0 0 5px 5px;
  box-shadow: 0px 6px 12px rgba(47, 43, 99, 0.16);
  padding: 0 0 12px;
  max-height: 360px;
}

.c3_profile_edit_big_block_item .choices__list--dropdown .is-highlighted {
  background-color: $background5Color;
}

.c3_profile_edit_big_block_item .is-open .choices__list--single .choices__item {
  border-radius: 5px 5px 0 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  position: relative;
  z-index: 3;
}

.c3_profile_edit_big_block_item .is-open .choices__list--dropdown {
  z-index: 2;
}

.c3_profile_edit_big_block_item .choices__list--single.has-selected .choices__item {
  color: $accentLeftColor;
}

.c3_profile_edit_big_block_item .choices__item--disabled {
  display: none;
}

.c3_profile_edit_big_block_item .choices__list::-webkit-scrollbar {
  width: 3px;
  background: $whiteColor;
  border-radius: 5px;
}

.c3_profile_edit_big_block_item .choices__list::-webkit-scrollbar-thumb {
  background: $scrollbarThumbColor;
  border-radius: 7px;
}

/* choose new profile photo popup */

.popup_choose_new_photo_wrap {
  display: none;
  width: 100%;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  background: $fadeColor;
  justify-content: center;
  -ms-align-items: center;
  align-items: center;
  z-index: 999;
}

.popup_choose_new_photo_wrap.active {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
}

.popup_choose_new_photo {
  position: relative;
  width: 791px;
  max-width: calc(100% - 20px);
  background-color: $whiteColor;
  padding: 37px 19px 43px 40px;
  border-radius: 10px;

}

.popup_choose_new_photo_title {
  font-weight: 600;
  font-size: 24px;
  letter-spacing: 0.06em;
  color: $accentRightColor;
  margin-bottom: 28px;
}

.popup_choose_new_photo_list {
  height: 627px;
  overflow-y: auto;
}

.popup_choose_new_photo_list::-webkit-scrollbar {
  width: 3px;
  background: $bleachColor;
  border-radius: 22px;
}

.popup_choose_new_photo_list::-webkit-scrollbar-thumb {
  background: $scrollbarThumbColor;
  border-radius: 9px;
}

.popup_choose_new_photo_list img {
  height: 195px;
  width: auto;
  max-width: 100%;
  object-fit: cover;
  margin-right: 16px;
  border-radius: 5px;
  margin-bottom: 10px;
  cursor: pointer;
}

.popup_choose_new_photo_set_btn {
  width: 185px;
  height: 45px;
  line-height: 45px;
  background: linear-gradient(90.51deg, $accentLeftColor 0%, $accentRightColor 100%);
  border-radius: 4px;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 0.01em;
  color: $whiteColor;
  text-align: center;
  margin: 30px auto 0;
  cursor: pointer;
}

.header-menu-item-notif {
  background-color: $counterAndErrorColor;
  box-shadow: 0px 6px 12px rgba(47, 43, 99, 0.16);
  border-radius: 10px;
  color: $whiteColor;
  text-align: center;
  padding-top: 10px;
  margin-top: -10px;
}

.c1_profile_right div.with_notif {
  background-image: none;
  padding-left: 8px;
}

.c1_profile_right div.with_notif span {
  display: inline-block;
}

.with_notif b {
  display: inline-block;
  width: 16px;
  height: 16px;
  background-color: $counterAndErrorColor;
  font-weight: 500;
  font-size: 12px;
  text-align: center;
  letter-spacing: -0.03em;
  color: $whiteColor;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
}

.c3_profile_edit_manage_password_btn_wrap {
  position: absolute;
  right: 10px;
  top: 54px;
  z-index: 2;
}

.mob_profile_pass {
  display: none;
}


.c3_profile_edit_manage_password_btn:hover {
  box-shadow: $searchItemLikeBtnShadowHover;
}

.c3_profile_edit_manage_password_btn img {
  margin-right: 10px;
}

.c3_profile_edit_big_block_row.with_pass,
.c3_profile_edit_big_block_row.dob_with_email {
  justify-content: space-between;
}

.c3_profile_edit_big_photo_mob_buttons {
  display: none;
}

.c3_profile_edit_big_photo_mob_edit {
  width: 100%;
  height: 32px;
  line-height: 32px;
  border: 1px solid $primaryColor;
  border-radius: 5px;
  font-weight: 500;
  font-size: 12px;
  color: $primaryColor;
  text-align: center;
  margin-bottom: 11px;
  cursor: pointer;
}

.c3_profile_edit_big_photo_mob_edit img {
  vertical-align: middle;
  margin-bottom: 4px;
  margin-right: 8px;
}

.c3_profile_edit_big_photo_mob_pass {
  width: 100%;
  height: 32px;
  line-height: 32px;
  border: 1px solid $primaryColor;
  border-radius: 5px;
  font-weight: 500;
  font-size: 12px;
  color: $primaryColor;
  text-align: center;
  cursor: pointer;
}

.c3_profile_edit_big_photo_mob_pass img {
  vertical-align: middle;
  margin-bottom: 4px;
  margin-right: 8px;
}

.mob_edit_page_save {
  position: absolute;
  right: 26px;
  bottom: 20px;
  z-index: 15;
  width: 363px;
  height: 57px;
  line-height: 57px;
  background: linear-gradient(90.31deg, $accentLeftColor 0%, $accentRightColor 100%);
  border-radius: 4px;
  color: $whiteColor;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0.01em;
  text-align: center;
  cursor: pointer;
}

.mob_edit_page_save:hover {
  box-shadow: $searchItemBtnShadowHover;
}

.mob_edit_page_save img {
  width: 27px;
  vertical-align: middle;
  margin-bottom: 4px;
  margin-right: 12px;
}

.full-width-content > div > div > div:nth-child(3)::-webkit-scrollbar {
  width: 3px;
  background: $littleGreyLittleBlueColor;
  border-radius: 5px;
  margin-right: 3px;
}

.full-width-content > div > div > div:nth-child(3)::-webkit-scrollbar-thumb {
  background: $scrollbarThumbColor;
  border-radius: 7px;
}

.country-select__menu-list::-webkit-scrollbar {
  width: 3px;
  background: $littleGreyLittleBlueColor;
  border-radius: 5px;
  margin-right: 3px;
}

.country-select__menu-list::-webkit-scrollbar-thumb {
  background: $scrollbarThumbColor;
  border-radius: 7px;
}

.country-select__input {
  height: 100%;
}

.country-select__control .country-select__single-value {
  line-height: 54px;
  font-size: 18px;
}

.country-select__control .country-select__placeholder {
  font-size: 18px;
}

.country-select__menu .country-select__option {
  padding-top: 20px;
  padding-bottom: 18px;
  font-size: 18px;
}

.country-select__input input {
  font-size: 18px;
}

.c3_profile_edit_big_block_item .country-select__input input[type=text] {
  height: 100%;
  font-size: 18px;
  width: 100% !important;
}

.country-select__indicator svg {
  color: $primaryColor;
  width: 17px;
  opacity: .56;
}

.all-humens .c3_video_empty_img {
  max-width: 25%;
  margin-top: 50px;
}

@media screen and (max-width: 1899px) {
  .c3_profile_edit_wrap2 {
    height: calc(100vh - 113px);
    height: calc(calc(var(--vh, 1vh) * 100) - 113px);
  }

  .need_confirm_email .c3_profile_edit_wrap2 {
    height: calc(100vh - 193px);
    height: calc(calc(var(--vh, 1vh) * 100) - 193px);
  }
}

@media screen and (max-width: 1799px) {
  .c3_profile_edit_big_block_item {
    width: 340px;
  }
}

@media screen and (max-width: 1749px) {
  .c3_profile_gifts_item_right {
    padding-top: 0;
  }

  .c3_profile_gifts_item_content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "pr_gift_item_id pr_gift_item_id"
		"pr_gift_item_info pr_gift_item_photos"
		"pr_gift_item_to pr_gift_item_photos";
    -ms-align-items: center;
    align-items: center;
    border: none;
    padding-bottom: 0;
  }

  .c3_profile_gifts_item_top {
    grid-template-areas: "pr_gift_item_id pr_gift_item_id pr_gift_item_id"
		"pr_gift_item_photo pr_gift_item_info pr_gift_item_photos"
		"pr_gift_item_photo pr_gift_item_to pr_gift_item_photos";
    grid-template-columns: 217px 1fr 346px;
    grid-template-rows: 24px 128px 65px;
  }

  .c3_profile_gifts_item_girls_photos {
    -ms-align-self: center;
    align-self: center;
    margin-top: -33px;
  }

  .c3_profile_gifts_item_to {
    padding-bottom: 0;
  }

  .c3_profile_gifts_item_bottom {
    margin-left: 0;
    margin-top: 27px;
    padding-top: 22px;
    border-top: 1px solid $defaultTextColorAlpha1;
    justify-content: space-between;
  }

  .c3_profile_gifts_item_id {
    margin-left: 0;
    margin-bottom: 11px;
  }

  .c3_profile_gifts_item_main_info {
    margin-bottom: 27px;
  }

  .c3_profile_gifts_item_decline_reason {
    margin-top: 0;
  }

  .c3_profile_webcam_list_item_appr_date {
    width: auto;
  }

  .c3_profile_webcam_list_item_request_date {
    margin-bottom: 16px;
  }

  .c3_profile_gifts_item_gift_status {
    margin: 0 10px;
    width: auto;
    flex: none;
  }

  .c3_profile_gifts_item_gift_date.date_sent {
    width: auto;
  }

  .c3_profile_gifts_item_gift_date.date_delivered {
    width: auto;
  }
}

@media screen and (max-width: 1699px) {
  .c3_profile_edit_big_block_item {
    width: 300px;
  }
}

@media screen and (max-width: 1599px) {
  .c3_profile_edit_big_photo {
    /*width: 315px;
*/
    float: left;
  }

  .c3_profile_edit_big_block {
    display: block;
  }

  .c3_profile_edit_big_photo_wrap img {
    max-height: 400px;
    object-fit: contain;
  }

  .c3_profile_edit_manage_password_btn {
    border: 1px solid $primaryColor;
  }
}

@media screen and (max-width: 1499px) {
  .c3_profile_edit_big_block {
    align-items: flex-start;
    display: block;
    position: relative;
  }

  .c3_profile_edit_big_photo {
    /*width: 250px;
*/
  }

  .cl2h_tab_wide_filter_row2_big .like_select_label {
    padding-top: 11px;
    padding-bottom: 9px;
    margin: 0 5px;
  }

  .c3_profile_edit_block_title {
    font-size: 18px;
  }

  .c3_profile_edit_big_block_item {
    width: 250px;
  }

  .c3_profile_edit_big_block_item input[type=text] {
    height: 42px;
    font-size: 18px;
  }

  .c3_profile_edit_textarea_wrap {
    display: block;
  }

  .c3_profile_edit_textarea_item {
    width: 100%;
    margin-bottom: 24px;
  }

  .c3_profile_edit_textarea {
    height: 169px;
    font-size: 14px;
  }

  .c3_profile_edit_block_photo_item_img {
    height: 167px;
  }

  .c3_profile_edit_block_photo_item.add_photo {
    width: 167px;
    height: 167px;
  }

  .c3_profile_edit_manage_password_btn img {
    height: 20px;
    width: auto;
  }

  .c3_profile_edit_big_block_item .country-select__input input[type=text] {
    font-size: 16px !important;
  }

  .country-select__control .country-select__single-value {
    line-height: 38px;
    font-size: 16px;
  }

  .cl2h_tab_wide_filter_row2_big .cl2h_tab_wide_filter_item_wrap {
    height: 42px;
  }

  .country-select__menu .country-select__option {
    padding-top: 11px;
    padding-bottom: 9px;
    font-size: 14px;
  }

  .country-select__control .country-select__placeholder {
    font-size: 16px;
  }
}

@media screen and (max-width: 1399px) {
  .c3_profile_gifts_item_top {
    grid-template-areas: "pr_gift_item_id pr_gift_item_id"
		"pr_gift_item_photo pr_gift_item_info"
		"pr_gift_item_photo pr_gift_item_to"
		"pr_gift_item_photos pr_gift_item_photos";
    grid-template-columns: 217px 1fr;
    grid-template-rows: 24px 128px 65px auto;
    justify-content: center;
    width: auto;
    margin: auto;
  }

  .c3_profile_gifts_item_decline_reason {
    width: 450px;
    margin: auto;
    text-align: center;
    padding: 20px 0;
  }

  .c3_profile_gifts_item_girls_photos {
    margin-top: 0;
    padding-top: 20px;
  }

  .c3_profile_gifts_item_girls_photos_text {
    text-align: center;
  }

  .c3_profile_gifts_item_gift_date.date_delivered {
    width: auto;
  }

  .c3_profile_gifts_item_gift_date.date_sent {
    width: auto;
  }

  .mob_edit_page_save {
    width: 250px;
    height: 54px;
    line-height: 54px;
    font-size: 20px;
  }

  .mob_edit_page_save img {
    width: 24px;
  }

  .c3_profile_edit_block_list {
    grid-template-columns: repeat(2, calc((100% - 50px) / 2));
  }
}

@media screen and (max-width: 1299px) {

  .c3_profile_edit_manage_password_btn.mob_profile_pass {
    display: block;
  }

  .c3_profile_edit_big_block_row.place_row {
    display: block;
  }

  .c3_profile_edit_big_block_row.place_row .c3_profile_edit_big_block_item_town {
    margin-left: 0;
    margin-top: 26px;
  }

  .c3_profile_edit_big_block_head {
    display: block;
    margin-bottom: 26px;
  }

  .c3_profile_edit_block_title {
    margin-bottom: 16px;
  }

  .content-info-about {
    flex-wrap: wrap;
  }

  .info-about-parts {
    width: 50%;
  }

  .info-about-block.block-2 {
    width: 50%;
  }

  .social-info {
    width: 100%;
    margin-top: 24px;
  }

  .c3_profile_edit_big_photo {
    width: calc((100% - 50px) / 2);
  }

  .c3_profile_edit_big_block_top_right {
    width: calc((100% - 50px) / 2);
  }

  .c3_profile_edit_big_block_item {
    width: 100%;
  }
}

@media screen and (max-width: 1199px) {
  .c3_profile_edit_block_item > label span {
    display: none;
  }
}

@media screen and (max-width: 1139px) {
  .c3_profile_edit_wrap2 {
    height: 100%;
    padding-top: 16px;
  }

  .need_confirm_email .c3_profile_edit_wrap2 {
    height: 100%;
  }

  .edit-profile-column {
    height: 100%;
  }

  .edit-profile-column main {
    height: 100%;
  }

  .c3_profile_edit_wrap {
    height: 100%;
  }
}

@media screen and (max-width: 999px) {

  .c3_profile_edit_manage_password_btn.mob_profile_pass {
    display: block;
  }

  .all-contact-re {
    //max-height: calc(100vh - 193px);
  }

  .c3_profile_support_item {
    position: relative;
    display: block;
  }

  .c3_profile_support_item_from {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    -o-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-align-items: center;
    align-items: center;
    width: 100%;
    text-align: left;
    margin-bottom: 16px;
  }

  .c3_profile_support_item_req_id {
    position: absolute;
    right: 15px;
    top: 43px;
  }

  .c3_profile_support_item_from img {
    margin-bottom: 0;
    margin-right: 10px;
  }

  .block-block-list .c2_list_wrap {
    height: 100%;
  }

  .need_confirm_email .block-block-list .c2_list_wrap {
    height: 100%;
  }

  .block-block-list .c2_list_wrap_2 {
    flex: 1;
    overflow-x: hidden;
  }

  .block-block-list .c3_top {
    flex: none;
  }

  .block-block-list .c3_chat_wrap {
    flex: 1;
    height: unset;
    overflow: hidden;
  }

  .need_confirm_email .block-block-list .c3_chat_wrap {
    flex: 1;
    height: unset;
    overflow: hidden;
  }

  .c3_profile_edit_block_photo_list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 16px;
  }

  .c3_profile_edit_block_photo_item {
    position: relative;
    padding-top: 100%;
    margin: 0;
  }

  .c3_profile_edit_block_photo_item_img {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    object-fit: cover;
  }

  .c3_profile_edit_block_photo_item.add_photo {
    padding-top: 0;
    width: 100%;
    height: 100%;
  }
}

@media screen and (max-width: 899px) {
  .all-contact-re {
    //max-height: calc(100vh - 100px);
  }

  .cl2h_tab_wide_filter_row2_dob {
    width: 100%;
  }

  .column-3 main {
    padding-top: 0;
  }

  .c3_profile_gifts_wrap {
    //padding-top: 0;
  }
}

@media screen and (max-width: 820px) {
  .popup_manprof_change_email_item .popup_manprof_change_password_input {
    font-size: 15px;
  }
  .c3_profile_edit_manage_password_btn_wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    margin-bottom: 70px;
    margin-top: -79px;

  }
  .c3_profile_edit_manage_password_btn {
    position: relative;
    /*display: none;
*/
  }

  .c3_profile_edit_big_photo_mob_buttons {
    display: block;
    margin-top: 15px;
  }

  .c3_profile_edit_block_list {
    grid-gap: 15px;
    grid-template-columns: repeat(2, 50%);
  }
}

@media screen and (max-width: 767px) {

  .c3_profile_edit_big_photo {
    float: left;
  }

  .c3_profile_edit_big_block_right .c3_profile_edit_block {
    margin-top: 80px;
  }
}

@media screen and (max-width: 749px) {
  .c3_profile_edit_block_item .like_select_label {
    height: auto;
    line-height: 1.3;
    padding: 7px 12px 5px;
  }
}

@media screen and (max-width: 699px) {

  .all-contact-re .humen .emailInfo .email {
    flex-wrap: wrap;
  }

  .all-contact-re .humen .emailInfo .someEmail {
    word-break: break-word;
  }

  .all-contact-re .humen .emailInfo .copy {
    bottom: 0px;
  }

  .c3_profile_filters {
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    -o-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-bottom: 10px;
  }

  .c3_profile_edit_block_list {
    grid-template-columns: repeat(2, calc((100% - 30px) / 2));
  }

  .c3_profile_gifts_item_bottom {
    display: block;
  }

  .c3_profile_gifts_item_gift_status {
    margin: 15px auto;
    width: auto;
    text-align: center;
  }

  .c3_profile_gifts_item_gift_date {
    text-align: center;
  }
}

@media screen and (max-width: 599px) {
  .c3_profile_edit_big_block_top_right {
    width: 100%;
  }

  .main-content-header-row-flex {
    display: block;
    padding-left: 16px;
  }

  .content-info-about {
    margin: 0 16px;
    width: calc(100% - 32px);
  }

  .info-about-parts {
    width: 100%;
  }

  .info-about-block.block-2 {
    width: 100%;
  }

  .c3_profile_edit_big_block_top {
    display: block;
  }

  .c3_profile_edit_manage_password_btn {
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .all-contact-re .all-humens {
    padding-left: 0;
  }

  .all-contact-re {
    padding-left: 20px;
    margin-left: -20px;
    //max-height: calc(100vh - 82px);
    margin-right: 0;
  }

  .c3_profile_gifts_wrap {
    //padding-top: 0;
  }

  .c3_profile_gifts_item_top {
    grid-template-areas: "pr_gift_item_photo pr_gift_item_id"
		"pr_gift_item_photo pr_gift_item_info"
		"pr_gift_item_to pr_gift_item_to"
		"pr_gift_item_photos pr_gift_item_photos";
    grid-template-columns: 107px 1fr;
    grid-template-rows: 25px 75px 85px auto;
  }

  .c3_profile_gifts_item_gift_photo {
    width: 100px;
    height: 100px;
    margin-right: 0;
  }

  .c3_profile_gifts_item_gift_name {
    font-size: 14px;
    width: auto;
  }

  .c3_profile_gifts_item_id {
    font-size: 10px;
  }

  .c3_profile_gifts_item_gift_price {
    font-size: 12px;
  }

  .c3_profile_gifts_item_main_info {
    margin-bottom: 0;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    -o-flex-direction: column;
    flex-direction: column;
    justify-content: space-between;
  }

  .c3_profile_gifts_item_to_photo img {
    width: 50px;
    height: 50px;
  }

  .c3_profile_gifts_item_to_id {
    font-size: 10px;
  }

  .c3_profile_gifts_item_to_name {
    line-height: 22px;
    font-size: 14px;
  }

  .c3_profile_gifts_item_to_place {
    font-size: 10px;
  }

  .c3_profile_gifts_item_girls_photos_list img {
    //width: 76px;
    height: 54px;
    object-fit: contain;
  }

  .c3_profile_gifts_item_girls_photos_text {
    font-size: 14px;
  }

  .c3_profile_gifts_item_girls_photos_list {
    padding-right: 0;
  }

  .c3_profile_gifts_item {
    padding: 16px;
  }

  .c3_profile_gifts_item_decline_reason {
    width: auto;
    padding: 0;
  }

  .c3_profile_gifts_wrap {
    padding-right: 4px;
  }

  .c3_profile_gifts_wrap2 {
    padding-right: 5px;
    padding-left: 5px;
    padding-bottom: 0;
  }

  .c3_profile_gifts_item_gift_date {
    font-size: 14px;
  }

  .c3_profile_gifts_item_gift_status {
    font-size: 14px;
    width: auto;
    text-align: center;
    margin: 12px auto;
  }

  .c3_profile_gifts_item_bottom {
    margin-top: 16px;
    padding-top: 16px;
  }

  .c3_profile_gifts_item_girls_photos {
    padding-top: 0;
  }

  .c3_profile_edit_big_block_item input[type=text] {
    min-width: 153px;
    width: 100%;
    height: 30px;
    font-size: 14px;
    padding-left: 10px;
  }

  .c3_profile_edit_big_block_item > label {
    font-size: 12px;
  }

  .c3_profile_edit_big_block_item {
    width: 100%;
  }

  .c3_profile_edit_big_block_item.c3_profile_edit_big_block_email {
    margin-left: 0;
    margin-top: 10px;
  }

  .c3_profile_edit_big_photo {
    width: 125px;
  }

  .c3_profile_edit_wrap {
    padding: 0 5px 0 10px;
  }

  .c3_profile_edit_wrap2 {
    padding: 0 5px 20px;
    height: calc(100vh - 100px);
    height: calc(calc(var(--vh, 1vh) * 100) - 100px);
  }

  .need_confirm_email .c3_profile_edit_wrap2 {
    height: calc(100vh - 100px);
    height: calc(calc(var(--vh, 1vh) * 100) - 100px);
  }

  .c3_profile_edit_block_photo_list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
  }

  .c3_profile_edit_block_photo_item {
    margin: 0;
    position: relative;
    padding-top: 100%;
  }

  .c3_profile_edit_block_photo_item_img {
  }

  .c3_profile_edit_block_photo_item.add_photo {
    padding: 0;
  }

  .c3_profile_edit_block_photo_item.add_photo img {
    width: 30px;
    height: 30px;
  }

  .c3_profile_edit_block_photo_item.add_photo span {
    font-size: 14px;
    margin-top: 6px;
  }

  .c3_profile_edit_main_information_wrap {
    padding: 10px;
    box-shadow: none;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    margin-bottom: 0;
  }

  .c3_profile_edit_main_information_wrap:nth-child(2) {
    padding-top: 34px;
  }

  .c3_profile_save_edit_btn {
    height: 44px;
    line-height: 44px;
    width: 290px;
    font-size: 20px;
  }

  .c3_profile_edit_big_photo {
    margin-right: 12px;
    float: none;
    width: 100%;
  }

  .c3_profile_edit_big_photo_edit {
    /*display: none;
*/
  }

  .c3_profile_edit_big_block {
    padding-top: 0;
  }

  .c3_profile_edit_big_block_head .c3_profile_edit_block_title {
    position: static;
  }

  .c3_profile_save_edit_btn img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }

  .c3_profile_edit_block_photo_item_close img {
    width: 10px;
  }

  .c3_profile_edit_block_photo_item_close {
    top: 5px;
  }

  .c3_profile_edit_big_photo_mob_pass {
    height: 41px;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
    text-align: left;
  }

  .c3_profile_edit_big_photo_mob_pass span {
    flex: 0;
  }

  .c3_profile_edit_big_photo_mob_pass img {
    margin-bottom: 0;
  }

  .c3_profile_edit_big_block_row {
    margin-bottom: 13px;
    flex-wrap: wrap;
  }

  .c3_profile_edit_big_block_right .c3_profile_edit_block {
    margin-top: 30px;
  }

  .mob_edit_page_save {
    position: absolute;
    right: 20px;
    bottom: 20px;
    z-index: 10;
    width: 80px;
    height: 32px;
    line-height: 32px;
    background: linear-gradient(90.31deg, $accentLeftColor 0%, $accentRightColor 100%);
    border-radius: 4px;
    color: $whiteColor;
    font-weight: 600;
    letter-spacing: 0.01em;
    text-align: center;
    font-size: 14px;
  }

  .mob_edit_page_save span span {
    display: none;
  }

  .mob_edit_page_save img {
    width: 15px;
    margin-right: 8px;
  }

  .c3_profile_edit_big_block_item_town {
    margin-left: 0;
    margin-top: 16px;
  }

  .c3_profile_edit_block_list {
    display: block;
  }

  .c3_profile_edit_textarea_wrap2 {
    padding: 10px 11px 10px 15px;
  }

  .country-select__control .country-select__single-value {
    font-size: 14px;
    line-height: 22px;
  }

  .country-select__control .country-select__indicators {
    height: 100%;
    margin-right: 5px;
  }

  .country-select__control .country-select__value-container {
    padding-left: 10px;
  }

  .c3_profile_edit_big_block_item .country-select__input input[type=text] {
    font-size: 14px !important;
    min-width: 100%;
    font-weight: 500;
  }

  .country-select__menu .country-select__option {
    padding: 6px 5px;
    margin: 0 5px;
    font-size: 14px;
  }

  .c3_profile_support_item {
    width: 97%;
  }

  .c3_profile_support_item_req_id {
    width: 70px;
    text-align: right;
    top: 26px;
    right: 23px;
  }

  .c3_profile_support_item_from img {
    width: 40px;
    height: 40px;
  }

  .c3_profile_support_item_media img {
    width: 100px;
    height: 56px;
  }

  .c3_profile_support_item_time {
    margin-top: 5px;
  }

  .c3_profile_support_wrap {
    padding-top: 0;
  }

  .cl2h_tab_wide_filter_row2_big .cl2h_tab_wide_filter_item_wrap {
    height: 30px;
  }

  .country-select__control .country-select__placeholder {
    font-size: 14px;
  }
  main.c3_profile_meeting_wrap {
    padding-top: 16px;
  }
}

@media screen and (max-width: 329px) {
  .c3_profile_edit_block_photo_list {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 899px) and (max-height: 450px) and (min-width: 420px) {
  main.c3_profile_meeting_wrap {
    padding-top: 16px;
    padding-left: 16px;
  }
  .mob_edit_page_save {
    position: absolute;
    right: 20px;
    bottom: 20px;
    z-index: 10;
    width: 80px;
    height: 32px;
    line-height: 32px;
    background: linear-gradient(90.31deg, $accentLeftColor 0%, $accentRightColor 100%);
    border-radius: 4px;
    color: $whiteColor;
    font-weight: 600;
    letter-spacing: 0.01em;
    text-align: center;
    font-size: 14px;
  }

  .mob_edit_page_save img {
    width: 15px;
    margin-right: 8px;
  }

  .need_confirm_email .c3_profile_edit_wrap2 {
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
  }

  .c3_profile_edit_wrap2 {
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
  }

  .c3_profile_gifts_wrap2 {
    //height: 100vh ;
    //height: calc(var(--vh, 1vh) * 100);
  }

  .all-contact-re {
    //max-height: 100vh ;
    //max-height: calc(var(--vh, 1vh) * 100);
  }
}

/* TODO temporary */
.popup_manprof_edit_photo_list_item img {
  max-width: 100px;
}

/*popups */
.popup_manprof_edit_photo {
  height: 834px;
  max-height: calc(100vh - 30px);
  max-height: calc(calc(var(--vh, 1vh) * 100) - 30px);
  width: 791px;
  max-width: calc(100vw - 30px);
  padding: 37px 19px 22px 40px;
  background-color: $whiteColor;
  border-radius: 10px;
  position: relative;
}

.popup_manprof_edit_photo_wrap {
  height: 100%;
}

.popup_manprof_edit_photo_title {
  font-weight: 600;
  font-size: 24px;
  letter-spacing: 0.06em;
  color: $accentRightColor;
  margin-bottom: 20px;
}

.popup_manprof_edit_photo_list {
  padding-right: 18px;
  height: calc(100% - 141px);
  overflow-y: auto;
}

.popup_manprof_edit_photo_list::-webkit-scrollbar {
  width: 3px;
  background: $bleachColor;
  border-radius: 22px;
}

.popup_manprof_edit_photo_list::-webkit-scrollbar-thumb {
  background: $scrollbarThumbColor;
  border-radius: 9px;
}

.popup_manprof_edit_photo_list_wrap {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -o-flex-wrap: wrap;
  flex-wrap: wrap;

}

.popup_manprof_edit_photo_list_item {
  margin-right: 16px;
  margin-bottom: 16px;
}

.popup_manprof_edit_photo_list_item img {
  height: 195px;
  width: auto;
  max-width: calc(100%);
  border-radius: 5px;
  object-fit: cover;
}

.popup_manprof_edit_photo_btn_wrap {
  margin-top: 14px;
}

.popup_manprof_edit_photo_btn {
  width: 184px;
  height: 45px;
  line-height: 45px;
  text-align: center;
  border-radius: 5px;
  background: linear-gradient(90.8deg, $accentLeftColor 0%, $accentRightColor 100%);
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 0.01em;
  color: $whiteColor;
  cursor: pointer;
  margin: auto;
}

.popup_manprof_edit_photo_btn.disable {
  background: linear-gradient(90.51deg, rgba(0, 49, 123, 0.6) 0%, rgba(114, 0, 102, 0.6) 100%);
  cursor: default;
}

.popup_manprof_edit_photo_btn_text {
  text-align: center;
  letter-spacing: 0.01em;
  color: $defaultTextColorAlpha9;
  font-size: 16px;
  margin-top: 14px;
}

.popup_manprof_edit_photo_list_add_btn {
  width: 195px;
  height: 195px;
  background-color: $trueGreyColor;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  justify-content: center;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  -o-flex-direction: column;
  flex-direction: column;
  cursor: pointer;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  margin-right: 16px;
  margin-bottom: 16px;
}

.popup_manprof_edit_photo_list_add_btn span {
  font-weight: 500;
  font-size: 20px;
  line-height: 140%;
  color: $defaultTextColorAlpha6;
  margin-top: 16px;
}

.popup_manprof_edit_photo_close {
  position: absolute;
  right: 15px;
  top: 15px;
  padding: 5px;
  cursor: pointer;
}

@media screen and (max-width: 599px) {
  .popup_manprof_edit_photo_title {
    font-size: 18px;
    text-align: left;
  }
}

.popup_manprof_save_changes {
  width: 385px;
  max-width: calc(100vw - 30px);
  height: 239px;
  max-height: calc(100vh - 30px);
  position: relative;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  padding: 36px 40px 31px;
  background-color: $whiteColor;
}

.popup_manprof_save_changes_title {
  font-weight: 600;
  font-size: 20px;
  text-align: center;
  color: $accentLeftColor;
  margin-bottom: 18px;
}

.popup_manprof_save_changes_text {
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: $defaultTextColorAlpha8;
  margin-bottom: 24px;
}

.popup_manprof_save_changes_btn_save {
  display: block;
  width: 219px;
  height: 42px;
  line-height: 42px;
  background: linear-gradient(90.66deg, $accentLeftColor 0%, $accentRightColor 100%);
  border-radius: 4px;
  text-align: center;
  font-weight: 600;
  font-size: 14px;
  color: $whiteColor;
  margin: 0 auto 11px;
  cursor: pointer;
}

.popup_manprof_save_changes_btn_save img {
  vertical-align: middle;
  margin-bottom: 2px;
  margin-right: 10px;
}

.popup_manprof_save_changes_btn_discard {
  color: $primaryColor;
  font-weight: 600;
  text-align: center;
  cursor: pointer;
  line-height: 30px;
  width: max-content;
  margin: 0 auto;
  padding: 3px;
}

.popup_manprof_save_changes_btn_discard.close {
  margin-top: 15px;
}

.popup_manprof_save_changes_close {
  position: absolute;
  right: 15px;
  top: 15px;
  padding: 5px;
  cursor: pointer;
}

.popup_manprof_save_changes_close img {
  width: 16px;
}

@media screen and (max-width: 599px) {
  .popup_manprof_save_changes {
    padding: 46px 10px 20px;
  }

  .popup_manprof_save_changes_title {
    font-size: 16px;
    margin-top: 32px;
  }

  .popup_manprof_edit_photo_list {
    height: calc(100% - 90px);
  }

  .popup_manprof_edit_photo {
    padding: 37px 10px 22px 20px;
  }

  .popup_manprof_edit_photo_list_add_btn {
    width: 120px;
    height: 120px;
    margin-bottom: 9px;
    margin-right: 5px;
  }

  .popup_manprof_edit_photo_list_add_btn img {
    width: 30px;
    height: 30px;
  }

  .popup_manprof_edit_photo_list_add_btn span {
    font-size: 14px;
    margin-top: 12px;
  }

  .popup_manprof_edit_photo_list_item img {
    height: 120px;
  }

  .popup_manprof_edit_photo_list_item {
    margin-bottom: 5px;
    margin-right: 5px;
  }
}

@media screen and (max-width: 326px) {
  .popup_manprof_edit_photo_list {
    height: calc(100% - 110px);
  }
}


.full-width-content {
  width: 100%;
}

/* manage password */

.popup_manprof_edit_photo_list_item img.active {
  border: 4px solid $primaryLiteColor;
}

/* edit saved modal*/

.popup_manprof_edits_saved {
  width: 436px;
  max-width: calc(100vw - 30px);
  position: relative;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  background-color: $whiteColor;
}

.popup_manprof_edits_saved_wrap {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  -o-flex-direction: column;
  flex-direction: column;
  justify-content: center;
  height: 263px;
}

.popup_manprof_edits_saved_title {
  font-weight: 600;
  font-size: 30px;
  text-align: center;
  color: $accentLeftColor;
  margin-bottom: 16px;
}

.popup_manprof_edits_saved_title img {
  vertical-align: middle;
  margin-bottom: 4px;
  margin-right: 22px;
}

.popup_manprof_edits_saved_text {
  font-size: 18px;
  line-height: 1.4;
  text-align: center;
  color: $defaultTextColorAlpha8;
}

.popup_manprof_save_changes_close {
  position: absolute;
  right: 15px;
  top: 15px;
  padding: 5px;
  cursor: pointer;
}

.popup_manprof_save_changes_close img {
  width: 16px;
}

@media screen and (max-width: 450px) {
  .popup_manprof_edits_saved_title {
    font-size: 24px;
  }

  .popup_manprof_edits_saved_title img {
    width: 28px;
    margin-right: 12px;
  }

  .popup_manprof_edits_saved_text {
    font-size: 14px;
  }
}
