@import "@sitestyles";

.onboardingInput {
  width: 100%;

  p {
    color: #333;
    font-family: $mainFont;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    text-align: left;
    margin: 0 0 8px;
  }
}

.input {
  border-radius: 8px;
  border: 1px solid #E4E4E4;
  background: #F8F8F8;
  width: 100%;
  padding: 12px 16px;
  color: #333333;
  font-family: $mainFont;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  transition: all .3s ease;
  cursor: pointer;

  &::placeholder {
    color: #404752;
    font-family: $mainFont;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }

  &:hover {
    border: 1px solid #D8D8D8;
    background: #EFEFEF;
    transition: all .3s ease;
  }

  &:focus {
    border: 1px solid #6D6AE5;
    background: #FFF;
    transition: all .3s ease;
  }
}

.input_filled {
  border: 1px solid #D8D8D8;
  background: #FFF;
  color: #333333;
  transition: all .3s ease;
}