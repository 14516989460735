@import "@sitestyles";

/*LETTER MAIN*/

.c3_write_msg_textareas textarea {
  background-color: $littleGreyLittleBlueColor;
  border: none;
  width: 100%;
  height: 70px;
  resize: none;
  outline: none;
  padding: 10px 17px;
  font-size: 16px;
  color: $stormGreyColor;
  border-radius: 8px;
}

.c3_write_msg_textareas textarea.large {
  height: 115px;
}

.c3_write_msg_textareas textarea::-webkit-scrollbar {
  width: 3px;
  background: $littleGreyLittleBlueColor;
  border-radius: 5px;
  margin-right: 3px;
}

.c3_write_msg_textareas textarea::-webkit-scrollbar-thumb {
  background: $scrollbarThumbColor;
  border-radius: 7px;
}

.c3_write_msg_textareas {
  height: 100%;
}

.c3_write_msg_textareas.with_files {
  display: flex;
  flex-direction: column;
  background-color: $littleGreyLittleBlueColor;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
}

.c3_write_msg_textareas_files {
  padding: 10px 0;
  border-top: 1px solid $oldGrayColor;
  margin: 10px 16px 0;
}

.c3_write_msg_textareas_files .letters_attach_files_item.file_audio {
  background-color: $bleachColor;
  padding-left: 10px;
  padding-right: 0;
}

.letters_attach_files_item_audio_control {
  display: flex;
  align-items: center;
}

img.letters_attach_files_item_audio_play {
  margin-right: 16px;
}

.letters_attach_files_item_audio_info {
  font-size: 12px;
  letter-spacing: -0.03em;
  color: $defaultTextColorAlpha6;
}

.letters_attach_files_item_audio_info span:first-child {
  margin-right: 10px;
}

.c3l_bookmark_ico {
  width: 15px;
  height: 21px;
  background-image: url(/img/c3l-bookmark-empty.svg);
  -webkit-background-size: contain;
  background-size: contain;
  background-repeat: no-repeat;
}

.c3l_men_list_item_btn_wrap:hover .c3l_bookmark_ico {
  margin-left: 10px;
}

.c3l_bookm_btn.active .c3l_bookmark_ico {
  background-image: url(/img/c3l-bookmark-full.svg);
}

.c3_head_info {
  display: grid;
  grid-template-areas: "c2_bottom_name c2_bottom_name"
						 "c2_bottom_id c2_bottom_place";
}

.c3_head_name {
  width: 100%;
}

.close_one_popup {
  position: absolute;
  right: 14px;
  top: 14px;
  padding: 5px;
  cursor: pointer;
}

.close_one_popup svg {
  fill: #c4c4c4;
  width: 22px;
  height: 22px;
  stroke: #c4c4c4;
  stroke-width: 0.5;
}

.popup_compose_letter .close_one_popup,
.popup_write_letter .close_one_popup {
  right: 2px;
  top: 2px;
}

.popup_compose_letter .close_one_popup svg,
.popup_write_letter .close_one_popup svg {
  fill: #01327a;
  width: 26px;
  height: 26px;
  stroke: #01327a;
  stroke-width: 0.5;

  @media screen and (max-width: 599px) {
    width: 22px;
    height: 22px;
  }
}

.c4_minichat .c3_write_msg {
  display: grid;
  grid-template-areas: "c4_text c4_text c4_text"
	"c4_attach c4_smile c4_send";
  grid-template-columns: 56px 1fr 111px;
}

.c4_minichat .c3_attach {
  grid-area: c4_attach;
  margin-right: 0;
  padding-top: 0;
}

.c4_minichat .c3_textaera_wrap {
  grid-area: c4_text;
}

.c4_minichat .c3_smile_btn_wrap {
  grid-area: c4_smile;
  margin-left: 0;
  height: 32px;
}

.c4_minichat .c3_smile_btn {
  padding: 0;
}

.c3l_letters_list_write_bottom .c3_smile_btn {
  height: 40px;
  cursor: pointer;
  padding: 0 16px;
  //width: 46px;
  display: flex;
  align-items: center;
  width: fit-content;
  //flex-direction: column;
  justify-content: center;
  position: relative;

  z-index: 3;
  background-repeat: no-repeat;
  font-size: 12px;
  //letter-spacing: -.01em;
  border-radius: 4px;
  //color: $backgroundButtonColor;
  color: $chatItemBtnColor;
}

.c4_minichat .c3_send {
  grid-area: c4_send;
}

.popup_audio_item_check {
  margin-left: 32px;
}

.popup_audio_item_delete {
  cursor: pointer;
}

.c3_message_audio_title {
  font-size: 15px;
  letter-spacing: -0.03em;
  color: $defaultTextColorAlpha9;
  margin-bottom: 13px;
}


.c4_minichat .rhap_progress-section {
  width: calc(100% - 90px);
}

.c4_minichat .message_audio_wrap {
  width: 216px;
}

.c4_minichat .chat_message_wrap.right .message_audio_wrap {
  width: 266px;
}

.c4_minichat .rhap_volume-controls {
  width: 36px;
  right: 0;
}

.c4_minichat .rhap_volume-bar-area {
  width: 100px;
  position: relative;
  top: 5px;
}

.c4_minichat .rhap_volume-container:hover .rhap_volume-bar-area {
  display: none;
}


.media_file_status {
  color: $defaultTextColorAlpha8;
  left: 0;
  top: 100%;
  width: 100%;
  background: $whiteColor;
  text-align: center;
  font-size: 10px;
  height: 29px;
  line-height: 24px;
  padding-top: 10px;
  margin-top: -10px;
  box-shadow: 0px 3px 3px rgba(14, 4, 143, 0.05);
  -webkit-border-radius: 0 0 4px 4px;
  -moz-border-radius: 0 0 4px 4px;
  border-radius: 0 0 5px 5px;
}

.letters_attach_files_item.file_audio .media_file_status {
  width: calc(100% + 20px);
  margin-left: -20px;
  margin-right: -20px;
}

.media_file_status.st_viewed span {
  position: relative;
}

.media_file_status.st_viewed span:before {
  content: "";
  display: inline-block;
  width: 14px;
  height: 9px;
  //background-image: url(/img/st-viewed.svg);
  margin-right: 5px;
}

.c3_send_btn.disabled {
  cursor: unset;
}

.c3_send_btn_cost_text {
  position: absolute;
  right: 0;
  top: calc(100% + 3px);
  font-size: 11px;
  color: $defaultTextColorAlpha5;
  white-space: nowrap;
  font-weight: 400;
  pointer-events: none;
}

.popup_write_letter .c3_send_btn_cost_text {
  top: calc(100% + 8px);
}

.slick-arrow.slick-next {
  position: absolute;
  right: -16px;
  top: 20px;
}

.slick-arrow.slick-prev {
  position: absolute;
  left: -16px;
  top: 20px;
}

.slick-track {
  position: relative;
}

.slick-list {
  padding-bottom: 5px;
}

#slick_letter_attach {
  .slick-slider {
    display: flex;
  }

  .slick-list {
    width: 100%;
    max-width: calc(100% - 74px);

    @media screen and (max-width: 768px) {
      max-width: calc(100% - 30px);
      margin: 0 auto;
    }
  }

  .slick-track {
    display: flex;
    flex-direction: row;
  }

  .slick-slide {
    width: max-content;
  }

  .c3l_men_list_item_main_btn {
    position: initial;
    cursor: pointer;
    z-index: 4;

    @media screen and (max-width: 768px) {
      position: absolute;
    }
  }

  .btn-prev {
    margin-right: 10px;

    @media screen and (max-width: 768px) {
      left: -10px;
    }
  }

  .btn-next {
    margin-left: 10px;

    @media screen and (max-width: 768px) {
      right: -10px;
    }
  }
}


.letters_attach_files_item.file_audio {
  display: flex;
  /*flex-direction: column;
*/
}

.c3l_letters_list_write_wrap .slick-arrow.slick-next {
  top: 50%;
  transform: translateY(-50%);
}

.c3l_letters_list_write_wrap .slick-arrow.slick-prev {
  top: 50%;
  transform: translateY(-50%);
  left: -21px;
}

.c3l_letters_list_write_wrap .letters_attach_files_item.file_video:after {
  top: 50%;
}

.c3l_letters_list_write_wrap .letters_attach_files_item.file_audio {
  display: flex;
  flex-direction: row;
}

.c3l_letters_list_write_wrap .slick-track {
  display: flex;
  align-items: center;
}

.c3l_letters_list_item.right .slick-slide {
  margin-right: 10px;
}

.c3l_letters_list_item.right .letters_attach_files_list {
  padding-bottom: 0;
}

.c3l_letters_list_write_wrap {
  position: relative;
}

.new_message_btn {
  display: none;
  min-width: 200px;
  height: 40px;
  padding: 0 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 100px;
  border-radius: 5px;
  border: 1px solid $accentRightColor;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  color: $accentRightColor;
  background: $whiteColor;
  box-shadow: $searchItemBtnShadowHover;
  cursor: pointer;
  z-index: 2;
  transition: all .3s ease-in-out;

  svg {
    fill: $accentRightColor;
    width: 24px;
    min-width: 24px;
    height: 24px;
    transition: all .3s ease-in-out;
  }

  &:hover,
  &:focus {
    color: $whiteColor;
    background: $accentRightColor;

    svg {
      fill: $whiteColor;
    }
  }
}

.is_form_open.new_message_btn {
  bottom: 240px;
}

.new_message_btn.active {
  display: flex;
}


.pagination {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  width: 100%;
  justify-content: center;
  -ms-align-items: center;
  align-items: center;
}

.pagination li {
  width: 41px;
  height: 41px;
  line-height: 41px;
  color: $accentLeftColor;
  text-align: center;
  list-style: none;
}

.pagination li.active {
  border-radius: 100%;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  background-color: $whiteColor;
  color: $accentRightColor;
  outline: none;
  font-size: 18px;
  padding-top: 2px;
  margin-bottom: 2px;
}

.pagination li a {
  display: inline-block;
  width: 100%;
  height: 100%;
  cursor: pointer;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  outline: none;
}

.pagination li.previous,
.pagination li.next {
  width: 39px;
  height: 19px;
  border-radius: 5px;
  background-image: url(/img/c3wp-pagin-disable.svg);
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  font-size: 0;
}

.pagination li.next {
  transform: rotate(180deg);
  margin-left: 5px;
}

.pagination li.previous:not(.disabled),
.pagination li.next:not(.disabled) {
  background-color: $accentRightColor;
  background-image: url(/img/c3wp-pagin-active.svg);
}


.pagination li.previous:not(.disabled):hover,
.pagination li.next:not(.disabled):hover {
  box-shadow: 0px 2px 6px rgba(14, 4, 143, 0.24);
}

.pagination li.previous:not(.disabled) {
  transform: rotate(180deg);
}

.pagination li.next:not(.disabled) {
  transform: unset;
}

.arrow_down_btn {
  display: none;
  position: absolute;
  right: 30px;
  bottom: 72px;
  width: 46px;
  height: 36px;
  background: linear-gradient(0deg, $whiteColor, $whiteColor);
  border: 1px solid $primaryColor;
  box-sizing: border-box;
  border-radius: 5px;
  cursor: pointer;
}

.arrow_down_btn > img {
  width: 15px;
  height: 14px;
}

.arrow_down_btn.active {
  display: flex;
  justify-content: center;
  align-items: center;
}

.arrow_down_btn.open_letter_form {
  bottom: 340px;
}
