@import "@sitestyles";

.popup_contact_request {
  position: relative;
  width: 1040px;
  color: #446077;
  font-weight: 500;
  background-color: $whiteColor;
  padding: 16px 27px 19px 16px;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  text-align: left;
}

.popup_contact_request_title {
  font-weight: bold;
  font-size: 28px;
  text-align: center;
  color: $accentLeftColor;
  margin-top: 16px;
  margin-bottom: 16px;

  @media screen and (max-width: 599px) {
    font-size: 22px;
  }
}

.popup_contact_request_top {
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
}

.popup_contact_request_profile_info_wrap {
  width: 270px;
  display: flex;
  align-items: center;
  margin-right: 15px;
}

.popup_contact_request_profile_photo {
  margin-right: 10px;

  img {
    width: 92px;
    height: 92px;
    border-radius: 100%;
    object-fit: cover;
    display: block;
  }
}

.popup_contact_request_profile_info {
  font-size: 12px;
  color: $defaultTextColorAlpha6;
  width: max-content;
  max-width: 170px;
}

.popup_contact_request_profile_name {
  width: 100%;
  font-weight: bold;
  font-size: 16px;
  color: $accentLeftColor;
  margin-bottom: 5px;
}

.popup_contact_request_profile_id {
  margin-bottom: 5px;
}

.popup_contact_request_profile_place {
  img{
    height: 12px;
    border-radius: 3px;
    vertical-align: middle;
    margin-right: 5px;
    margin-bottom: 2px;
  }
}

.popup_contact_request_top_text_wrap {
  p{
    margin-bottom: 10px;
  }
}

.popup_contact_request_conditions {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  &.done {
    flex-direction: row;
    margin-left: -20px;

    & > div {
      flex: 1;
      margin-left: 20px;

      .popup_contact_request_chat_days_subtitle {
        display: block;
      }

      .popup_contact_request_chat_days_item {
        height: 50px;
        max-width: 30px;
        border-radius: 5px;
        margin: 0 2px;

        span {
          display: none;
        }
      }
    }

    @media screen and (max-width: 1249px) {
      flex-direction: column;
    }
  }
}

.popup_contact_request_credit_counter,
.popup_contact_request_chat_days,
.popup_contact_request_verification,
.popup_contact_request_imbra{
  border: 2px solid #EBEDFA;
  border-radius: 15px;
  padding: 19px 13px 24px 19px;
  margin-bottom: 16px;
}

.popup_contact_request_credit_counter_title,
.popup_contact_request_chat_days_title,
.popup_contact_request_verification_title,
.popup_contact_request_imbra_title {
  font-weight: bold;
  font-size: 16px;
  color: $accentLeftColor;
  margin-bottom: 10px;
}

.popup_contact_request_credit_counter_text,
.popup_contact_request_chat_days_text,
.popup_contact_request_verification_text,
.popup_contact_request_imbra_text {
  margin-bottom: 10px;
}

.popup_contact_request_credit_counter_row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  color: $accentLeftColor;
}

.popup_contact_request_spent_credits {
  font-weight: bold;
  font-size: 15px;
}

.popup_contact_request_limit_credits {
  font-weight: 500;
  font-size: 14px;
}

.popup_contact_request_progressbar_line {
  height: 6px;
  background-color: $background5Color;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  position: relative;
}

.popup_contact_request_progressbar_line_indicator {
  position: absolute;
  top: 1px;
  left: 1px;
  background-color: $accentLeftColor;
  border-radius: 3px;
  height: 5px;
  max-width: 100%;
}

.popup_contact_request_chat_days_subtitle {
  display: none;
  font-weight: bold;
  font-size: 14px;
  color: $accentLeftColor;
  margin-bottom: 10px;
}

.popup_contact_request_chat_days_row {
  display: flex;
  justify-content: space-between;
  margin: 0 -5px;
}

.popup_contact_request_chat_days_item {
  flex: 1;
  height: 85px;
  color: $accentLeftColor;
  font-size: 18px;
  font-weight: bold;
  border: 2px solid #EBEDFA;
  box-sizing: border-box;
  border-radius: 15px;
  margin: 0 5px;
  display: flex;
  justify-content: center;
  align-items: center;

  &.fill {
    color: $whiteColor;
    background-color: #7A87DC;
    border: 2px solid #7A87DC;
  }
}

.popup_contact_request_verification {
  display: flex;
  justify-content: space-between;
}

.popup_contact_request_verification_left {
  width: calc(40% - 10px);
}

.popup_contact_request_verification_right{
  width: calc(60% - 10px);
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.popup_contact_request_verification_right_col{
}

.popup_contact_request_verification_icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #EBEDFA;
  font-weight: bold;

  svg {
    width: 38px;
    height: 38px;
    margin-bottom: 5px;
  }

  &.active {
    color: $accentLeftColor;

    svg path {
      fill: $accentLeftColor;
      stroke: $accentLeftColor;
    }
  }
}

.popup_contact_request_imbra {
  display: flex;
  justify-content: space-between;
}

.popup_contact_request_imbra_left {
  width: calc(65% - 10px);
}
.popup_contact_request_imbra_right {
  width: calc(35% - 10px);
  display: flex;
  justify-content: center;
}

.popup_contact_request_imbra_right_col {
  width: 140px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: #AFAFAF;
}

.popup_contact_request_imbra_subtitle {
  font-weight: bold;
  font-size: 15px;
  margin-bottom: 10px;
}

.popup_contact_request_imbra_icon {
  margin-bottom: 10px;

  img {
    height: 60px;
  }
}

.request_icon_search svg{
  width: 50px;
  height: 50px;
}

.popup_contact_request_imbra_status {
  font-weight: 600;

  &.verified {
    color: #62C065;
  }

  &.canceled {
    color: #D02745;
  }
}

.popup_contact_request_bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.popup_contact_request_price {
}

.popup_contact_request_price_title {
  margin-bottom: 5px;
}

.popup_contact_request_price_value {
  font-weight: bold;
  font-size: 18px;
  color: $accentLeftColor;
  margin-bottom: 5px;
}

.popup_contact_request_price_text {
  width: 300px;
  font-size: 12px;
  color: #ABABAB;
}

.popup_contact_request_send_btn {
  width: 220px;
  height: 45px;
  border: 2px solid #828282;
  box-sizing: border-box;
  border-radius: 5px;
  color: #828282;
  font-size: 16px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: default;
  //cursor: not-allowed;

  svg {
    width: 20px;
    height: 20px;
    fill: #828282;
    margin-right: 8px;
  }

  &.mob {
    display: none;
    margin-top: 16px;
  }

  &.active {
    color: $whiteColor;
    background: $backgroundButtonColor;
    border: none;
    cursor: pointer;

    &:hover {
      box-shadow: $smallBtnShadowHover;
    }

    svg {
      fill: #FFFFFF;
    }
  }

  &.canceled {
    width: 270px;
    background: #fff;
    color: #D02745;
    border: 2px solid #D02745;
  }

  &.verified {
    width: 270px;
    background: #fff;
    color: #62C065;
    border: 2px solid #62C065;
  }
}

.popup_contact_request_text_reason {
  margin-top: 10px;
  color: #D02745;
  font-weight: 600;

  &.mob {
    display: none;
  }
}

.popup_contact_request_close {
  position: absolute;
  right: 9px;
  top: 9px;
  padding: 5px;
  cursor: pointer;

  svg {
    width: 32px;
    height: 32px;
    fill: #ccd2d9;
  }
}

//Media styles ******************************

@media screen and (max-width: 1249px) {
  .popup_contact_request {
    width: 683px;
    padding: 12px;
  }

  .popup_contact_request_title {
    margin-top: 0;
  }

  .popup_contact_request_top {
    flex-direction: column;
  }

  .popup_contact_request_profile_info_wrap {
    margin-bottom: 10px;
  }

  .popup_contact_request_verification,
  .popup_contact_request_imbra {
    flex-direction: column;
  }

  .popup_contact_request_verification_left,
  .popup_contact_request_verification_right,
  .popup_contact_request_imbra_left,
  .popup_contact_request_imbra_right {
    width: 100%;
  }

  .popup_contact_request_send_btn {
    &:not(.main) {
      display: none;
    }

    &.mob {
      display: flex;
      margin: 16px auto 0;
    }
  }

  .popup_contact_request_text_reason {
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 720px) {
  .popup_contact_request {
    width: calc(100% - 20px);
    height: 85vh;
    max-height: 85vh;
    padding: 10px;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
  }

  .popup_contact_request_chat_days_subtitle {
    display: block;
  }

  .popup_contact_request_chat_days_item {
    height: 65px;
    max-width: 30px;
    border-radius: 5px;

    span {
      display: none;
    }
  }

  .popup_contact_request_close {
    svg {
      width: 25px;
      height: 25px;
    }
  }
}

@media screen and (max-height: 719px){
  //.popup_contact_request{
  //  height: 100%;
  //  overflow-y: auto;
  //}
}

@media screen  and (max-width: 599px){
  .popup_contact_request_top {
    margin-bottom: 10px;
  }

  .popup_contact_request_credit_counter,
  .popup_contact_request_chat_days,
  .popup_contact_request_verification,
  .popup_contact_request_imbra {
    padding: 15px 10px 15px 10px;
    margin-bottom: 10px;
    border-radius: 5px;
  }

  .popup_contact_request_chat_days_item {
    height: 50px;
    margin: 0 2px;
  }

  .popup_contact_request_bottom {
    flex-direction: column;
  }

  .popup_contact_request_price {
    align-self: start;
    margin-bottom: 16px;
  }

  .popup_contact_request_send_btn {
    width: 100%;
    max-width: 300px;
    align-self: center;

    &.canceled {
      width: 100%;
    }
  }
}

@media screen  and (max-width: 899px) and (max-height: 450px){
  .popup_contact_request{
    height: 100%;
    overflow-y: auto;
  }
}

@media screen  and (max-width: 1249px) and (min-height: 720px){
  //.popup_contact_request{
  //  height: 100%;
  //  overflow-y: auto;
  //}
}