@import '@sitestyles';

.error_page_wrap{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.error_image{
  position: relative;
  margin-bottom: 50px;
  display: flex;
}

.error_image_img{
  display: block;
  height: 650px;
  margin: auto;
}

.error_404_text{
  color: #6F0267;
  text-align: center;
  position: absolute;
  left: 48.5%;
  bottom: 21.5%;
  transform: translateX(-50%);
  width: 47%;
  height: 44.5%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.error_404_text .small{
  font-weight: 500;
  font-size: 43px;
  letter-spacing: 0.05em;
  line-height: 1;
}

.error_404_text .big{
  font-weight: 600;
  font-size: 180px;
  letter-spacing: 0.05em;
  line-height: 1;
}

.error_404_text .medium{
  font-weight: bold;
  font-size: 80px;
  letter-spacing: 0.05em;
}

.error_subtitle{
  font-weight: 600;
  font-size: 24px;
  color: $accentLeftRightColor;
  margin-bottom: 11px;
}

.error_title{
  font-weight: 600;
  font-size: 36px;
  color: $accentLeftRightColor;
  margin-bottom: 11px;
}

.error_bottom_text{
  font-size: 18px;
  line-height: 1.4;
  color: rgba(71, 79, 92, 0.7);
  margin-bottom: 32px;
  max-width: 550px;
}

.error_back_btn{
  text-align: center;
  width: 227px;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $buttonBackgroundColor;
  border-radius: 4px;
  font-weight: 600;
  font-size: 18px;
  letter-spacing: 0.01em;
  color: #FFFFFF;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
}

@media screen and (max-height: 1000px){
  .error_image_img{
    height: 500px;
  }

  .error_404_text .small{
    font-size: 34px;
  }

  .error_404_text .big{
    font-size: 140px;
  }

  .error_404_text .medium{
    font-size: 60px;
  }
}

@media screen and (max-height: 900px), screen and (max-width: 1399px){

  .error_image_img{
    height: 400px;
  }

  .error_subtitle{
    font-size: 18px;
  }

  .error_title{
    font-size: 24px;
  }

  .error_bottom_text{
    font-size: 14px;
  }

  .error_404_text .small{
    font-size: 30px;
  }

  .error_404_text .big{
    font-size: 110px;
  }

  .error_404_text .medium{
    font-size: 50px;
  }
}

@media screen and (max-width: 1139px){
  .error_page_wrap{
    height: calc(100% - 127px);
  }
}

@media screen and (max-height: 800px){
  .error_image_img{
    height: 300px;
  }

  .error_404_text .small{
    font-size: 26px;
  }

  .error_404_text .big{
    font-size: 80px;
  }

  .error_404_text .medium{
    font-size: 40px;
  }
}

@media screen and (max-width: 767px){
  .error_page_wrap{
    height: calc(100% - 114px);
  }
}

@media screen and (max-height: 700px), screen and (max-width: 699px){
  .error_image{
    margin-bottom: 30px;
  }

  .error_image_img{
    height: 250px;
  }

  .error_back_btn{
    width: 190px;
    height: 40px;
    font-size: 14px;
  }

  .error_subtitle{
    font-size: 14px;
  }

  .error_title{
    font-size: 20px;
  }

  .error_bottom_text{
    font-size: 12px;
  }

  .error_404_text .big{
    font-size: 60px;
  }

  .error_404_text .medium{
    font-size: 30px;
  }

}

@media screen and (min-width: 600px) and (max-height: 600px){
  .error_image_img{
    height: 150px;
  }

  .error_404_text .small{
    font-size: 20px;
  }

  .error_404_text .big{
    font-size: 40px;
  }

  .error_404_text .medium{
    font-size: 20px;
  }
}

@media screen and (max-width: 599px){
  .error_page_wrap{
    height: calc(100% - 100px);
  }

  .error_image_img{
    height: 162px;
  }

  .error_title{
    font-size: 18px;
  }

  .error_bottom_text{
    margin-bottom: 27px;
  }

  .error_back_btn{
    width: 100%;
    max-width: 275px;
  }

  .error_page_bottom{
    padding: 0 22px;
  }

  .error_404_text .small{
    font-size: 20px;
  }

  .error_404_text .big{
    font-size: 40px;
  }

  .error_404_text .medium{
    font-size: 20px;
  }
}

@media screen and (max-height: 499px){
  .error_image_img{
    height: 120px;
  }

  .error_back_btn{
    height: 30px;
    font-size: 12px;
  }

  .error_404_text .small{
    font-size: 16px;
  }

  .error_404_text .big{
    font-size: 30px;
  }

  .error_404_text .medium{
    font-size: 16px;
  }
}

@media screen and (max-height: 500px) and (max-width: 899px) and (min-width: 420px){
  .error_page_wrap{
    height: 100%;
  }
}