@import "@sitestyles";

.main_footer {
  background: $FooterBg;
}

.main_footer_top {
  display: flex;
  gap: 25px;
  align-items: flex-start;
  padding-top: 56px;
  padding-bottom: 32px;
  border-bottom: 1px solid $whiteColor;
  position: relative;

  @media screen and (max-width: 641px) {
    padding-top: 16px;
    padding-bottom: 24px;
    flex-direction: column;
    gap: 0;
  }
}

.main_footer_col_1 {
  width: calc(100% / 3);
  @media screen and (max-width: 641px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 25px;
    align-items: center;
    justify-content: center;
  }
}

.main_footer_logo {
  height: 54px;
  width: auto;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 28px;
  @media screen and (max-width: 641px) {
    margin-bottom: 0;
    height: 40px;
  }
}

.main_footer_logo img {
  object-fit: contain;
  width: auto;
  height: 100%;
  max-height: 100%;
  max-width: 100%;
}
.main_footer_c1_images {
  display: flex;
  align-items: center;
  margin-bottom: 60px;
  flex-wrap: wrap;
  justify-content: flex-end;
  row-gap: 16px;
  column-gap: 30px;

  @media screen and (max-width: 641px) {
    justify-content: center;
    column-gap: 32px;
    row-gap: 16px;
    margin-bottom: 30px;
  }
}

.main_footer_c1_images img {
  @media screen and (max-width: 641px) {
    max-width: 72px;
    width: 72px;
    height: auto;
    margin-right: 0;
    max-height: 48px;
  }
}

.main_footer_c1_images img:last-child {
  margin-right: 0;
}

.main_footer_col_2 {
  width: calc((100% / 3) * 2);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;

  @media screen and (max-width: 641px) {
    width: 100%;
    flex-direction: column;
  }
}
.main_footer_social {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  column-gap: 40px;

  @media screen and (max-width: 641px) {
    width: 100%;
    justify-content: center;
  }
}

.main_footer_social_item {
  display: flex;
  align-items: center;
  justify-content: center;
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.main_footer_social_link {
  display: block;
  font-size: 0;
  height: 48px;
  width: 48px;
  background: white;

  &:hover {
    opacity: 0.7;
  }
}

.icon_facebook {
  mask: url('../../../../img/facebook_icon.svg') center no-repeat;
  mask-size: contain;
}

.icon_instagram {
  mask: url('../../../../img/instagram_icon.svg') center no-repeat;
  mask-size: contain;
}

.icon_twitter {
  mask: url('../../../../img/twitter_icon.svg') center no-repeat;
  mask-size: contain;
}


.main_footer_bottom {
  min-height: 65px;
  display: flex;
  justify-content: space-between;
  padding-top: 16px;
  padding-bottom: 34px;
  //margin-bottom: 16px;
  flex-direction: column-reverse;

}

.main_footer_copyright {
  max-width: 340px;
  color: $whiteColor;
  font-weight: 700;
  line-height: 12px;
  letter-spacing: 0.05em;
  text-transform: uppercase;

  .age_restriction {
    margin-top: 10px;
    font-size: 8px;
  }

  small {
    display: block;
    font-size: 9px;
  }

  @media screen and (max-width: 641px) {
    max-width: 100%;
    text-align: center;
  }
}

.main_footer_bottom_links {
  column-gap: 36px;
  row-gap: 24px;
  display: flex;
  flex-wrap: wrap;

  @media screen and (max-width: 641px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;
  }
}

.main_footer_bottom_links div {
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  text-transform: uppercase;
  color: $whiteColor;
  margin-right: 10px;
  display: inline-block;
  transition: $ButtonTransitional;

  @media screen and (max-width: 1600px) {
    margin-right: 0;
    text-align: left;
  }

  @media screen and (max-width: 641px) {
    font-weight: 600;
    font-size: 12px;
    line-height: 17px;
    text-align: center;
  }

}

.main_footer_bottom_links button {
  cursor: pointer;
  font-weight: 600;
  text-transform: uppercase;
  color: $whiteColor;
  margin: 0;
  display: flex;
  font-family: $mainFont;
  align-items: center;
  font-size: 14px;
  line-height: 19px;
  height: 19px;

  @media screen and (max-width: 1300px) {
    height: auto;
    margin: 0;
    text-align: left;
  }
  @media screen and (max-width: 600px) {
    font-size: 12px;
    line-height: 17px;
  }

  &:hover {
    text-decoration: underline;
    text-underline-offset: 4px;
    text-decoration-thickness: 2px;
  }
}
