@import "@sitestyles";

.poll_modal{
  width: 800px;
  max-width: 100%;
  background-color: #fff;
  box-shadow: 0 3px 3px rgba(0,0,0,0.05);
  border-radius: 10px;
  padding: 25px 40px;
  position: relative;
}

.poll_modal_wrap{
  display: block;
}

.poll_modal_title{
  font-weight: bold;
  font-size: 28px;
  text-align: center;
  color: $accentLeftColor;
  margin-top: 16px;
  margin-bottom: 12px;
}

.poll_modal_subtitle{
  display: block;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 30px;
  color: #333;
}

.poll_modal_rating{
  display: flex;
  justify-content: center;
  margin-bottom: 47px;
}

.poll_modal_rating label ~ label{
  margin-left: 10px;
}

.poll_modal_rating input{
  display: none;
}

.poll_modal_rating label{
  display: block;
  cursor: pointer;
}

.poll_modal_question_answer .poll_modal_rating input[type=radio] + label:before{
  display: none;
}

.poll_modal_rating svg{
  display: block;
  width: 30px;
  height: 30px;
  fill: #00317B;
  stroke: #00317B;
  stroke-width: 10;
}

.poll_modal_rating input:checked ~ label svg{
  fill: #fff;
}

.poll_modal_rating input:checked + label svg{
  fill: #fff;
}

.poll_modal_text{
  font-weight: 600;
  text-align: center;
  font-size: 18px;
  color: #333;
  margin-bottom: 24px;
}

.poll_modal_questions_list ~ .poll_modal_text{
  margin-bottom: 0;
  margin-top: 24px;
}

.poll_modal_questions_list{
  display: block;
}

.poll_modal_wrap.delete_modal .poll_modal_question_answer{
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  .poll_modal_question_answer_inp{
    width: 50%;
    flex: none;
    margin: auto 0;
    padding: 0 20px 10px 0;
    display: flex;
    align-items: center;

    @media screen and (max-width: 599px){
      width: 100%;

      & + .poll_modal_question_answer_inp{
        margin-top: 5px;
      }
    }
  }
}

.poll_modal_question_item{
  display: block;
}

.poll_modal_question_item + .poll_modal_question_item{
  margin-top: 30px;
}

.poll_modal_question_title{
  font-weight: 500;
  font-size: 14px;
  color: #333;
  margin-bottom: 10px;
  //word-break: break-all;
}

.poll_modal_wrap.delete_modal .poll_modal_question_title{
  font-weight: 500;
  font-size: 18px;
  line-height: 1.5;
  color: #333333;
}

.poll_modal_question_answer{
  display: block;
}

.poll_modal_question_answer textarea{
  width: 100%;
  height: 60px;
  display: block;
  border: 1px solid #9aafd0;
  border-radius: 8px;
  padding: 5px 10px;
  resize: none;
  color: #333;
  font-size: 14px;
}

.poll_modal_question_answer textarea::-webkit-scrollbar {
  width: 3px;
  background: #fff;
  border-radius: 3px;
}

.poll_modal_question_answer textarea::-webkit-scrollbar-thumb {
  background: $scrollbarThumbColor;
  border-radius: 3px;
}

.poll_modal_question_answer_inp{
  width: max-content;
  max-width: 100%;
}

.poll_modal_question_answer_inp + .poll_modal_question_answer_inp{
  margin-top: 10px;
}

.poll_modal_question_answer input[type=radio],
.poll_modal_question_answer input[type=checkbox]{
  display: none;
}

.poll_modal_question_answer label{
  display: flex;
  align-items: flex-start;
  cursor: pointer;
}

.poll_modal_question_answer input[type=radio] + label:before,
.poll_modal_question_answer input[type=checkbox] + label:before{
  content: "";
  display: block;
  width: 16px;
  height: 16px;
  background-color: #fff;
  border: 1px solid #ced6e0;
  margin-right: 10px;
  flex: none;
}

.poll_modal_question_answer input[type=radio] + label:before{
  border-radius: 50%;
}

.poll_modal_question_answer input[type=checkbox] + label:before{
  border-radius: 4px;
}

.poll_modal_question_answer input:checked + label:before{
  background-image: url(../../../../../img/popup-checked.svg);
  background-repeat: no-repeat;
  background-position: 50%;
  background-color: #9c6ef7;
  border-color: #9c6ef7;
}

.poll_modal_question_answer input:checked ~ input[type=text]{
  pointer-events: auto;
}

.poll_modal_question_answer input[type=text]{
  height: 36px;
  flex: 1;
  margin-left: 20px;
  border: 1px solid $selectBorderColor;
  border-radius: 6px;
  padding: 0 11px;
  font-weight: 500;
  pointer-events: none;

  color: $inputTextColor;

  &::placeholder{
    font-weight: normal;
  }

  &:focus{
    border-color: $selectBorderHoverColor;
  }

  &:hover{
    border-color: $selectBorderHoverColor;
  }
}

.poll_modal_close{
  display: block;
  width: 30px;
  height: 30px;
  position: absolute;
  right: 10px;
  top: 10px;
  background-image: url(../../../../../img/cross-darkgrey.svg);
  background-size: 20px;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
}

.poll_modal_send_btn{
  width: 219px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $buttonBackgroundColor;
  font-weight: 600;
  font-size: 14px;
  color: #FFFFFF;
  border-radius: 4px;
  margin: 40px auto 0;
  cursor: pointer;
}

.poll_modal_wrap.delete_modal .poll_modal_send_btn{
  width: 260px;
}

.poll_modal_send_btn img{
  width: 20px;
  margin-right: 12px;
}

.poll_modal_send_btn svg {
  width: 22px;
  height: 22px;
  fill: #FFFFFF;
  margin-right: 12px;
}

@media screen and (max-width: 899px){
  .poll_modal_title{
    font-size: 24px;
  }

  .poll_modal_subtitle{
    margin-bottom: 20px;
  }

  .poll_modal_rating{
    margin-bottom: 20px;
  }

  .poll_modal_rating svg{
    width: 24px;
    height: 24px;
  }

  .poll_modal_text{
    font-size: 16px;
  }
}

@media screen and (max-width: 599px){
  .poll_modal{
    padding: 25px 20px;
  }

  .poll_modal_title{
    font-size: 20px;
  }

  .poll_modal_subtitle{
    margin-bottom: 20px;
    font-size: 14px;
  }

  .poll_modal_rating{
    margin-bottom: 20px;
  }

  .poll_modal_rating svg{
    width: 18px;
    height: 18px;
  }

  .poll_modal_text{
    font-size: 16px;
  }

  .poll_modal_question_title{
    font-size: 12px;
    font-weight: 600;
  }

  .poll_modal_close{
    width: 25px;
    height: 25px;
    background-size: 16px;
  }
}