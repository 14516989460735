@import "@sitestyles";

.image {
  //width: -webkit-fill-available;
  max-width: calc(100vw - 20px);
  height: 100%;
  width: 100%;
  max-height: 85vh;
  border-radius: 10px;
}

.image_gallery_container {
  min-width: 200px;
  display: flex;
  flex-direction: column;
}

.gallery {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.gallery_header {
  display: flex;
  width: 100%;
  height: 96px;
  align-items: center;
  justify-content: space-between;
  gap: 40px;
  padding: 24px;
  max-width: 1920px;
  margin: auto;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;

  @media screen and (max-width: 789px) {
    height: 72px;
    padding: 16px;
    top: unset;
    bottom: 0;
    z-index: 100;
  }
}

.user_profile {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  @media screen and (max-width: 789px) {
    gap: 12px;
    z-index: 100;
  }
}

.user_name {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  color: $whiteColor;

  @media screen and (max-width: 789px) {
    font-size: 14px;
  }
}

.user_avatar {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  position: relative;

  img {
    max-height: 100%;
    max-width: 100%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }

  @media screen and (max-width: 789px) {
    width: 40px;
    height: 40px;
  }
}

.user_online:after {
  content: '';
  position: absolute;
  background: $onlineColor;
  width: 10px;
  height: 10px;
  display: block;
  border-radius: 50%;
  border: 2px solid $whiteColor;
  bottom: 0;
  right: 0;

  @media screen and (max-width: 789px) {
    width: 8px;
    height: 8px;
    border: 2px solid $whiteColor;
  }
}


.button_close {
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  @media screen and (max-width: 789px) {
    width: 40px;
    height: 40px;
    position: fixed;
    top: 12px;
    right: 12px;
    z-index: 100;
  }

  svg {
    width: 24px;
    height: 24px;
    fill: $whiteColor;
    transition-duration: .3s;
  }

  &:hover,
  &:focus {
    svg {
      transform: scale(1.5);
    }
  }
}

.button_chat {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: center;
  height: 48px;
  padding: 0 16px;
  cursor: pointer;
  border: 1px solid $whiteColor;
  border-radius: 6px;
  transition-duration: .3s;
  background: transparent;
  margin-left: auto;

  svg {
    fill: $whiteColor;
    width: 24px;
    height: 24px;
    transition-duration: .3s;
  }

  span {
    color: $whiteColor;
    font-size: 16px;
    font-weight: 600;
    transition-duration: .3s;
  }

  &:hover,
  &:focus {
    background: $whiteColor;

    svg {
      fill: #2F2D48;
    }

    span {
      color: #2F2D48;
    }
  }

  @media screen and (max-width: 789px) {
    height: 40px;
    z-index: 100;
    span {
      font-size: 14px;
    }
  }
}

.gallery_photos {
  height: 100%;
  z-index: 99;
  display: flex;
  max-height: 80vh;
  position: relative;
  align-items: center;

  @media screen and (max-height: 789px) and (min-width: 789px)   {
    max-height: 70vh;
  }

  @media screen and (max-width: 789px) {
    flex-direction: column;
    justify-content: center;
    align-items: unset;
    max-height: calc(100vh - 88px - 40px);
  }
}

.main_photo_wrap {
  position: relative;
  height: fit-content;
  min-width: 200px;
}

.image_counter {
  border-radius: 61px;
  background: rgba(25, 25, 25, 0.15);
  padding: 6px 16px;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  color: #fff;
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 999;

  @media screen and (max-height: 789px) and (min-width: 789px)   {
    padding: 4px 8px;
    font-size: 12px;
    top: 12px;
    right: 16px;
  }

  @media screen and (max-width: 789px) {
    padding: 4px 8px;
    font-size: 12px;
    top: 12px;
    right: 16px;
  }
}

.gallery_photos_list_wrap {
  max-height: 80vh;
  height: 100%;
  margin-left: 24px;

  @media screen and (max-height: 789px) and (min-width: 789px)   {
    max-height: 70vh;
  }

  @media screen and (max-width: 789px) {
    max-height: unset;
    max-width: calc(100vw - 38px);
    margin: 16px 0 0;
    height: fit-content;
  }
}

.gallery_photos_list {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 0 12px 0 0;
  max-height: 80vh;

  @media screen and (max-height: 789px) and (min-width: 789px)   {
    max-height: 70vh;
  }

  @media screen and (max-width: 789px) {
    flex-direction: row;
    overflow-y: hidden;
    overflow-x: auto;
    max-width: 100%;
    padding: 0 0 5px 0;
    max-height: 76px;
  }
}

.gallery_photos_list::-webkit-scrollbar {
  width: 3px;
  background: rgba(255, 255, 255, 0.15);
  border-radius: 5px;

  @media screen and (max-width: 789px) {
    height: 3px;
    width: unset;
  }
}

.gallery_photos_list::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.50);
  border-radius: 7px;
}

.gallery_photos_list_item {
  width: 120px;
  height: 200px;
  cursor: pointer;
  border-radius: 10px;
  position: relative;

  &:before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.30);
    opacity: 0;
    transition: all .6s ease-in-out;
    border-radius: 10px;
  }

  &:hover:before {
    opacity: 1;
  }

  @media screen and (max-height: 789px) {
    width: 100px;
    height: 150px;
  }

  @media screen and (max-width: 789px) {
    width: 56px;
    height: 64px;
  }
}

.gallery_photos_list_item img {
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  min-width: 100px;
  min-height: 150px;
  object-fit: cover;
  border-radius: 10px;

  @media screen and (max-width: 789px) {
    min-width: 56px;
    min-height: 64px;
  }
}




