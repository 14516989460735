@import "@sitestyles";

.alert_modal_wrap {
  position: relative;
  width: 490px;
  max-width: 100%;
  padding: 40px;
  border-radius: 8px;
  background-color: $whiteColor;
  text-align: center;
  height: max-content;

  @media screen and (max-width: 599px) {
    width: 340px;
    margin: 0 auto;
    padding: 30px 20px 20px 20px;
    box-sizing: border-box;
  }

  @media screen and (max-width: 360px) {
    width: 300px;
  }
}

.alert_modal {
  width: 100%;
}

.alert_modal_title {
  font-size: 20px;
  font-weight: 600;
  color: $accentLeftColor;
  margin-bottom: 18px;

  a {
    color: $menuIconColor;
    font-size: 21px;
  }

  @media screen and (max-width: 599px) {
    font-size: 18px;
    margin-bottom: 16px;
  }
}

.alert_modal_title_image {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 6px;
}

.alert_modal_title_image.warning_icon {
  gap: 16px;
}

.alert_modal_title_image svg {
  width: 25px;
  height: 25px;
  fill: $accentLeftColor;
}

.warning_icon:before {
  content: '';
  width: 64px;
  height: 64px;
  background: url("../../../../../img/icon_warning.svg") center no-repeat;
  background-size: contain;
}

.alert_modal_text {
  margin: 16px auto;
  font-size: 16px;
  font-weight: 400;
  color: $defaultTextColorAlpha8;

  a {
    color: $menuIconColor;
  }

  @media screen and (max-width: 599px) {
    font-size: 14px;
  }
}

.alert_modal_sub_text {
  margin: 16px auto;
  font-size: 14px;
  font-weight: 400;
  color: $defaultTextColorAlpha6;

  &:last-child {
    margin-bottom: 0;
  }

  @media screen and (max-width: 599px) {
    font-size: 13px;
  }
}

.alert_modal_text_email {
  font-size: 16px;
  font-weight: 600;
  color: #00317B;
  background: #EBEDFA;
  border: 1px solid #A3A9D2;
  border-radius: 5px;
  padding: 10px 12px;
  min-width: 320px;
  width: max-content;
  max-width: 100%;
  overflow-x: auto;
  margin: 16px auto;

  &::-webkit-scrollbar {
    height: 6px;
    background: $whiteColor;
    border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb {
    height: 6px;
    background: $scrollbarThumbColor;
    border-radius: 3px;
  }

  @media screen and (max-width: 599px) {
    font-size: 14px;
    min-width: 270px;
  }

  @media screen and (max-width: 360px) {
    min-width: 100%;
  }
}

.alert_modal_btn_row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 24px;

  .alert_modal_btn,
  .alert_modal_cancel_btn {
    margin: 0;
    width: calc(50% - 8px);
    min-width: auto;
  }
}

.alert_modal_btn_col {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.alert_modal_btn {
  width: max-content;
  min-width: 220px;
  font-size: 15px;
  padding: 12px 10px;
  margin: 24px auto;
  background: $backgroundButtonColor;
  border: 1px solid transparent;
  border-radius: 4px;
  color: $whiteColor;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;

  svg {
    width: 20px;
    height: 20px;
    fill: $whiteColor;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &:hover,
  &:focus {
    background: $accentDarkColor;
  }

  @media screen and (max-width: 599px) {
    margin: 16px auto;
    padding: 10px;
    font-size: 14px;
  }
}

.sendberly .alert_modal_btn {
  &:hover,
  &:focus {
    background: transparent;
    border-color: $accentLeftColor;
    color: $accentLeftColor;

    svg {
      fill: $accentLeftColor;
    }
  }
}

.sendberly .alert_modal_title {
  color: #2F2D48;

  a {
    color: $accentLeftColor;
  }
}

.sendberly .alert_modal_text {
  color: #7E7D8B;

  a {
    color: $accentLeftColor;
  }
}

.alert_modal_cancel_btn {
  font-size: 15px;
  color: $primaryColor;
  font-weight: 600;
  text-align: center;
  cursor: pointer;
  line-height: 30px;
  width: max-content;
  margin: 0 auto;
  padding: 3px;
  transition-duration: .3s;

  &.outline {
    border: 1px solid $primaryColor;
    padding: 12px 10px;
    border-radius: 4px;
    line-height: normal;

    &:hover,
    &:focus {
      background: #F4F4F4;
    }
  }

  &:hover,
  &:focus {
    color: rgb(46, 46, 46);
  }

  @media screen and (max-width: 599px) {
    font-size: 14px;
  }
}

.alert_modal_close_icon {
  position: absolute;
  right: 7px;
  top: 7px;
  padding: 5px;
  cursor: pointer;
  outline: none;
  background: transparent;

  &:hover {
    svg {
      fill: rgb(46, 46, 46);
    }
  }
}

.icon_warning {
  width: 64px;
  margin-bottom: 16px;
}

.alert_modal_close_icon svg {
  width: 20px;
  height: 20px;
  fill: rgb(205, 211, 218);
  transition-duration: .3s;
}
