@import "@sitestyles";

.policy_page_wrap {
  flex: 1;
  background-color: #F8F8F8;
  height: calc(100% - 60px);
  display: flex;

  @media screen and (max-width: 767px) {
    height: 100%;

    &.opened{
      .policy_links_list_wrap {
        display: none;
      }

      .policy_content_column {
        display: flex;
        flex-direction: column;
      }
    }
  }
}

.policy_header_wrap {
  height: 60px;
  min-height: 60px;
  display: flex;
  gap: 8px;
  justify-content: center;
  align-items: center;
  background-color: #FFFFFF;
  box-shadow: 0 6px 12px 0 rgba(47, 43, 99, 0.16);
  font-size: 14px;
  font-weight: 700;
  color: $policyTitleColor;
  z-index: 1;

  svg {
    width: 20px;
    height: 20px;
    fill: $policyTitleColor;
  }

  @media screen and (max-width: 767px) {
    display: none;
  }
}

.policy_content_block_wrap {
  background-color: #F8F8F8;
  height: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;

  @media screen and (max-width: 767px) {
    height: 100%;
  }
}

.policy_links_list_wrap {
  width: 340px;
  background-color: #F8F8F8;
  padding: 16px 24px;
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 1499px) {
    width: 282px;
  }

  @media screen and (max-width: 767px) {
    width: 100%;
    padding: 16px;
  }

  .policy_links_list {
    margin-bottom: 20px;
  }

  .policy_link_item {
    width: 100%;
    height: 52px;
    display: flex;
    gap: 8px;
    align-items: center;
    padding: 8px 16px;
    background-color: #FFFFFF;
    box-shadow: 0 3px 5px rgba(14, 4, 143, 0.15);
    border-radius: 10px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: $policyTitleColor;
    transition: .3s;
    cursor: pointer;
    text-align: start;

    @media screen and (max-width: 767px) {
      padding: 8px 16px;
    }

    &.active {
      background-color: $policyButtonBg;
    }

    & + .policy_link_item {
      margin-top: 10px;
    }

    svg {
      width: 24px;
      height: 24px;
      min-width: 24px;
      min-height: 24px;
      fill: $policyTitleColor;
    }
  }

  .policy_link_bottom {
    margin-top: auto;
    text-align: center;
    color: #000000;
    font-size: 14px;

    .copy {
      font-size: 10px;
      color: #4F4F4F;
      margin-top: 4px;
    }
  }
}

.policy_links_list_wrap::-webkit-scrollbar {
  width: 3px;
  background: #FFFFFF;
  border-radius: 9px;
}

.policy_links_list_wrap::-webkit-scrollbar-thumb {
  background: $scrollbarThumbColor;
  border-radius: 9px;
}

.policy_content_column {
  flex: 1;
  position: relative;
  padding: 0 24px 24px 24px;
  margin-top: 16px;
  margin-right: 24px;
  background-color: #FFFFFF;
  border-radius: 10px 10px 0 0;

  @media screen and (max-width: 767px){
    display: none;
    padding: 40px 16px 16px 16px;
    margin: 16px 16px 0 16px;
  }
}

.policy_content_wrap {
  height: calc(100% - 60px);
  overflow-y: auto;
  margin-right: -38px;
  padding-right: 40px;
  position: relative;

  @media screen and (max-width: 1499px) {
    padding-right: 35px;
  }

  @media screen and (max-width: 767px) {
    padding-right: 22px;
    margin-right: -26px;
    height: 100%;
  }
}

.policy_content_wrap::-webkit-scrollbar {
  width: 3px;
  background: #FFFFFF;
  border-radius: 9px;
}

.policy_content_wrap::-webkit-scrollbar-thumb {
  background: $policyScrollColor;
  border-radius: 9px;
}

.policy_content_wrap {
  display: block;
}

.policy_content_title {
  font-weight: 700;
  font-size: 20px;
  color: $policyTitleColor;
  margin-top: 24px;
  margin-bottom: 16px;

  @media screen and (max-width: 767px) {
    margin-top: 0;
  }
}

.policy_content {
  display: block;
  font-size: 14px;
  font-weight: 500;
  color: $policyTextColor;

  & * {
    font-size: 14px;
    font-weight: 500;
  }

  h2 {
    margin: 12px 0;
    font-size: 16px;
    font-weight: 700;
    color: $policyTitleColor;
  }

  h3 {
    margin: 8px 0;
    font-size: 14px;
    font-weight: 700;
    color: $policyTitleColor;
  }

  p, span {
    word-break: break-word;
    & + * {
      margin: 12px 0;

      @media screen and (max-width: 767px) {
        margin-top: 8px;
      }
    }
  }

  b {
    font-size: 14px;
    font-weight: 700;
  }

  a {
    text-decoration: underline;
    color: $policyLinksColor;
    transition: all .3s ease-in-out;
    display: inline-block;
    word-break: break-word;
    overflow-wrap: break-word;
    white-space: normal;
    max-width: 100%;

    &:hover {
      color: $policyLinksHoverColor;
    }
  }

  ul, ol {
    list-style-position: outside;
    padding-left: 20px;

    li {
      & + * {
        margin-top: 8px;
      }

      p {
        display: inline;
      }
    }
  }
}

.mobile_close {
  display: flex;
  align-items: center;
  position: absolute;
  left: 16px;
  top: 16px;
  cursor: pointer;
  color: #8F9CAC;
  font-size: 12px;
  z-index: 2;

  @media screen and (min-width: 768px) {
    display: none;
  }

  svg {
    width: 6px;
    margin-right: 5px;
    fill: #8F9CAC;
  }
}