@import "@sitestyles";

.tutorial_step_illustration {
  width: 100%;
  height: 262px;
  margin-bottom: 56px;
  background: $tutorialPrimaryIllustrationBackground;
  border: 1px solid $tutorialPrimaryBorderColor;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  overflow: hidden;

  @media screen and (max-width: 540px) {
    height: 190px;
  }
}

.tutorial_step_illustration.desktop {
  @media screen and (max-width: 540px) {
    display: none;
  }
}

.tutorial_step_illustration.mobile {
  display: none;

  @media screen and (max-width: 540px) {
    display: flex;
  }
}

.step_search {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;

  @media screen and (max-width: 540px) {
    flex-direction: column;
  }

  &:before {
    content: '';
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;
    background: $tutorialPrimaryIllustrationBackground;
  }
}

.step_search_left {
  width: 40%;
  height: 104%;
  background-color: $tutorialPopupBackground;
  box-shadow: 0 3px 9px 0 rgba(41, 40, 48, 0.20);
  position: relative;
  top: -5px;
  bottom: 0;
}

.step_search_categories {
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.step_search_categories_item {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  letter-spacing: 0.14px;
  color: $tutorialCategoriesColor;
  padding: 0 8px 0 15px;
}

.step_search_categories_icon {
  height: 16px;

  svg {
    width: 16px;
    height: 16px;
    fill: #C1CEDE;
  }
}

.step_search_categories_icon.step_search_categories_icon_heart {
  height: 14px;

  svg {
    width: 16px;
    height: 14px;
    fill: #C1CEDE;
  }
}

.step_search_menu {
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 7px;
}

.step_search_menu_item {
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding: 10px 8px 10px 15px;
  align-items: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 140%;
  color: $tutorialMenuItemColor;
  border-radius: 10px;
  background-color: $tutorialPopupBackground;
}

.step_search_menu_item.active {
  color: $accentDarkColor;
  position: relative;
  z-index: 3;
  font-weight: 600;

  svg {
    fill: $accentDarkColor;

    path {
      fill: $accentDarkColor;
    }
  }
}

.step_search_menu_icon svg {
  width: 24px;
  height: 24px;
  fill: $tutorialMenuItemColor;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 540px) {
    width: 20px;
    height: 20px;
    fill: $tutorialDefaultIconColor;
  }
}

.step_search_menu_icon svg {
  width: 24px;
  height: 24px;
  fill: $tutorialMenuItemColor;

  @media screen and (max-width: 540px) {
    width: 20px;
    height: 20px;
    fill: $tutorialDefaultIconColor;
  }
}

.step_search_menu_icon_faq{
  display: flex;
  justify-content: center;
  align-items: center;

  circle{
    stroke: $tutorialMenuItemColor;
  }

  path{
    fill: $tutorialMenuItemColor;
  }
}

.step_search_right {
  width: 50%;
  height: 106%;
  background-color: $tutorialPopupBackground;
  box-shadow: 0 3px 9px 0 rgba(41, 40, 48, 0.20);
  position: relative;
  border-bottom-left-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
  padding: 0 0 20px 26px;
  top: -44px;
}

.step_search_avatar_wrap {
  display: flex;
  flex-direction: column;
  gap: 14px;
  align-items: center;
}

.step_search_avatar_icon svg {
  width: 140px;
  height: 140px;
  fill: #d2d7de;
}

.step_search_avatar_button {
  padding: 6px 16px;
  display: flex;
  flex-direction: row;
  gap: 9px;
  align-items: center;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.72px;
  color: $tutorialStepTextSecondaryColor;
  border: 1px solid $accentDarkColor;
  border-radius: 8px;
}

.step_search_plus_button {
  background: $tutorialButtonBackground;
  width: 29px;
  height: 29px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.step_search_plus_icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;

  svg {
    width: 12px;
    height: 12px;
    fill: #FFFFFF;
  }
}

.step_search_user_id {
  padding-top: 19px;
  border-top: 1px solid $tutorialUserIdColor;
  color: $tutorialStepTextSecondaryColor;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.84px;
  text-transform: uppercase;
}

.tutorial_body {
  display: grid;
  grid-row-gap: 16px;
  margin-bottom: 48px;
}

.step_search_mob_top {
  flex: 1;
}

.step_search_mob_bottom {
  flex: 1;
  background: $tutorialPopupBackground;
}

.step_search_mob_menu {
  list-style: none;
  display: flex;
  flex-direction: row;
  gap: 21px;
  align-items: center;
  justify-content: space-between;
  padding: 0 12px;
  position: relative;
}

.step_search_mob_menu_item {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2px;
  padding: 6px 7px;
  color: $tutorialCategoriesColor;
  text-align: center;
  font-size: 8.693px;
  font-weight: 400;
  line-height: 12.171px;
}

.step_search_mob_menu_item.search,
.step_search_mob_menu_item.like {
  position: absolute;
  background-color: $tutorialPopupBackground;
  border-radius: 10px;
  padding: 8px 12px;
  z-index: 3;
  color: $accentDarkColor;
  text-align: center;
  font-size: 12px;
  font-weight: 600;
  line-height: 16.333px;
  width: 64px;
  height: 64px;
  top: -15px;

  @media screen and (max-width: 350px) {
    width: 55px;
    height: 55px;
    padding: 5px;
    font-size: 10px;
  }

  svg {
    fill: $accentDarkColor;

    path {
      fill: $accentDarkColor;
    }
  }
}

.step_search_mob_menu_item.search {
  left: 20%;
}

.step_search_mob_menu_item.like {
  right: 20%;
}

.step_search_mob_menu_item.like .step_search_menu_icon svg,
.step_search_mob_menu_item.search .step_search_menu_icon svg {
  width: 28px;
  height: 28px;
}

.step_search_mob_site_bar {
  margin: 17px auto 4px;
  width: 90%;
  padding: 6px 8px;
  box-shadow: 0 3px 14px 0 rgba(0, 0, 0, 0.10);
  background-color: $tutorialPopupBackground;
  border-radius: 7px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.step_search_mob_text_icon svg {
  width: 13.5px;
  height: 8.5px;
  fill: #2f3136;
}

.step_search_mob_update_icon svg{
  width: 10.7px;
  height: 13px;
  fill: #2f3136;
}

.step_search_mob_lock_icon {
  z-index: 10;

  svg {
    width: 5.6px;
    height: 8.2px;
    fill: #747680;
  }
}

.step_search_mob_site_bar_text {
  display: flex;
  gap: 4px;
  color: $tutorialStepTextSecondaryColor;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: -0.3px;
  align-items: center;
  text-transform: lowercase;
}

.step_search_mob_chat_item {
  width: 90%;
  margin: 5px auto 0;
  border-radius: 5px;
  background: $tutorialPopupBackground;
  box-shadow: 0 3px 4px 0 rgba(14, 4, 143, 0.15);
  padding: 10.5px;
  display: flex;
  flex-direction: row;
  gap: 10.5px;
  align-items: center;
}

.step_search_mob_cross_icon {
  position: absolute;
  top: 5px;
  left: 20px;
  width: 8px;
  height: 8px;

  svg {
    width: 8px;
    height: 8px;
    fill: #9da0a6;
  }
}

.step_search_mob_chat_image {
  width: 48.5px;
  height: 48.5px;
  border-radius: 50%;
  background-color: $tutorialPaginationItemColor;
  position: relative;

  &:after {
    content: '';
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: $tutorialPrimaryGreenColor;
    border: 2px solid $tutorialPopupBackground;
    display: block;
    position: absolute;
    bottom: 0;
    right: 0;
  }
}

.step_search_mob_chat_info {
  display: flex;
  flex-direction: column;
  width: 79%;
  position: relative;
}

.step_search_mob_chat_user_name {
  color: $tutorialChatProfileNameColor;
  font-size: 13.04px;
  font-weight: 600;
}

.step_search_mob_chat_message {
  color: $tutorialChatMsgColor;
  font-size: 10px;
  font-weight: 400;
  line-height: 140%;
}

.step_search_mob_chat_time {
  color: $tutorialChatMsgColor;
  font-size: 8.693px;
  font-weight: 400;
  letter-spacing: -0.261px;
  position: absolute;
  bottom: -10%;
  right: -1%;
}

.tutorial_step_title {
  color: $accentDarkColor;
  text-align: center;
  font-size: 20px;
  font-weight: 700;
}

.tutorial_step_text {
  color: $tutorialStepTextColor;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  font-family: $mainFont;
}