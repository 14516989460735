.image_container {
  width: 200px;
  height: 360px;
}

.image_container.loaded {
  width: 100%;
  height: 100%;
}

.image_container.loading {
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite linear;
}

.image_container.loaded .image {
  display: block;
  border-radius: 8px;
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
  max-width: 320px;
  max-height: 360px;
  width: 100%;
}

.image_container .image {
  object-fit: cover;
  opacity: 0;
}

@keyframes shimmer {
  0% {
    background-position: -200% 0;
  }
  100% {
    background-position: 200% 0;
  }
}