.wrapper {
  max-width: 600px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
}

.step {
  color: #757575;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0 0 16px;

  @media (max-width: 600px) {
    font-size: 12px;
  }
}

.title {
  color: #333;
  font-family: "Open Sans", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  margin: 0 0 8px;

  @media (max-width: 600px) {
    font-size: 18px;
    text-align: left;
  }
}

.subtitle {
  color: #757575;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  margin: 0 0 48px;

  @media (max-height: 700px) {
    margin: 0 0 24px;
  }

  @media (max-width: 600px) {
    font-size: 14px;
    margin: 0 0 24px;
  }

  @media (max-width: 360px) {
    margin: 0 0 16px;
  }
}

.title_list {
  color: #333;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  margin: 0 0 16px;

  @media (max-width: 600px) {
    font-size: 14px;
  }
}

.text {
  color: #333;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  margin: 0 0 72px;

  @media (max-width: 600px) {
    font-size: 14px;
  }
}

.button_row {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 72px;


  @media (max-height: 700px) {
    margin-top: 24px;
  }

  @media (max-width: 600px) {
    flex-direction: column;
    margin-top: 24px;
  }

  @media (max-width: 360px) {
    margin-top: 16px;
  }
}

.button {
  height: 48px;
  width: 100%;
  max-width: 200px;
  box-sizing: border-box;
  padding: 12px 16px;
  border-radius: 8px;
  background: #212125;
  color: #FFF;
  text-align: center;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  cursor: pointer;
  transition: all .3s ease;

  &:hover {
    background: #363644;
    transition: all .3s ease;
  }

  &:disabled{
    background: #9A9A9A;
    transition: all .3s ease;
    cursor: not-allowed;
  }

  &.skip {
    border: 1px solid #BFBFBF;
    color: #BFBFBF;
    background: transparent;

    &:hover {
      border: 1px solid #363644;
      color: #363644;
      background: transparent;
      transition: all .3s ease;
    }
  }

  @media (max-width: 600px) {
    max-width: 100%;
    margin: 0 auto;

    &:last-child {
      margin-top: 16px;
    }
  }
}

.list {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
  flex-wrap: wrap;
  max-height: 200px;
  height: 100%;
  overflow-y: auto;
  margin-right: 0;
  position: relative;
  margin-bottom: 32px;

  &::-webkit-scrollbar {
    width: 2px;
    height: 2px;
    background: #EFEFEF;
  }

  &::-webkit-scrollbar-thumb {
    background: #757575;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
  }

  @media (max-width: 600px) {
    margin-bottom: 24px;
  }

  @media (max-width: 360px) {
    margin-bottom: 16px;
  }
}

.option {
  cursor: pointer;
  border-radius: 500px;
  border: 1px solid #E4E4E4;
  background: #F8F8F8;
  color: #333;
  text-align: center;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 12px 16px;
  transition: all .3s ease;

  &:hover {
    background: #EFEFEF;
    border: 1px solid #D8D8D8;
    transition: all .3s ease;
  }

  @media (max-width: 360px) {
    font-size: 12px;
    padding: 12px;
  }
}

.option_active {
  transition: all .3s ease;
  border: 1px solid #6D6AE5;
  background: #F2F2FF;
  color: #6D6AE5;

  &:hover {
    border: 1px solid #6D6AE5;
    background: #F2F2FF;
    color: #6D6AE5;
    transition: all .3s ease;
  }
}