@import "../breakpoints";

.user_info_avatar {
    display: inline-block;
    width: 60px;
    height: 60px;
    min-height: 60px;
    padding-left: 2px;
    padding-top: 2px;
    line-height: 50px;
    border-radius: 60px;
    text-align: center;
    color: white;
    font-size: 20px;

    @media (max-width: $BP_middle), (max-height: $BP_middle_height){
        width: 50px;
        height: 50px;
        min-height: 50px;
        line-height: 44px;
    }
}
.user-info-name {
    display: inline-block;
}