@import "@sitestyles";

.routing_onb_wrapper {
  display: flex;
  justify-content: center;
  max-width: 540px;
  width: 100%;
  align-items: center;

  @media (max-width: 600px) {
    max-width: calc(100vw - 32px);
  }
}

.routing_onb_item {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  margin: 0;
  box-shadow: 3px 3px 5px rgba(197, 196, 208, 0.64);
  border-radius: 10px;
  background-color: #fff;
  position: relative;
  width: 540px;
  height: 100%;
  max-width: 540px;

  @media (max-width: 600px) {
    width: calc(100vw - 32px);
    max-width: calc(100vw - 32px);
  }
}

.routing_onb_item_form {
  width: 540px;
  max-height: 80vh;
  overflow-y: auto;

  //height: 550px;
  //@media (max-width: 850px) {
  //  width: 650px;
  //}
  //@media (max-width: 730px) {
  //  width: 550px;
  //}
  @media (max-width: 600px) {
    width: calc(100vw - 32px);
  }
}

.loader {
  height: 690px;
  width: 750px;
  overflow-x: hidden;
  max-height: 80vh;
  overflow-y: hidden;

  @media (max-width: 850px) {
    width: 650px;
  }
  @media (max-width: 730px) {
    width: 550px;
  }
  @media (max-width: 650px) {
    width: 80vw;
  }
}

.routing_onb_cross {
  position: absolute;
  right: 16px;
  top: 16px;
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.routing_onb_cross svg {
  width: 24px;
  height: 24px;
  fill: #c2c2c2;
}
