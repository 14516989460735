@import "@sitestyles";

.mob_notify_list_fixed {
  display: block;
  position: fixed;
  top: 46px;
  right: 8px;
  padding-top: 6px;
  max-height: 161px;
  z-index: 9999;

  @media screen and (min-width: 1139px){
    display: none;
  }

  @media screen and (min-width: 599px){
    top: 55px;
  }
}

.item:first-child .mob_notify_activity:before {
  content: "";
  display: block;
  width: 14px;
  height: 14px;
  position: absolute;
  top: -2px;
  right: 18px;
  background: $notifyMobDefaultBg;
  transform: rotate(-45deg);
  transition-duration: .3s;
}

.item:first-child .each_like.mob_notify_activity:before {
  background: $notificationMatchBg;
}

.mob_notify_activity{
  width: 94vw;
  height: 56px;
  display: flex;
  align-items: center;
  background-color: $notifyMobDefaultBg;
  padding-right: 11px;
  padding-left: 9px;
  box-shadow: $notifyMainShadow;
  border-radius: 5px;
  margin-left: 5px;
  transition-duration: .3s;
  cursor: pointer;

  &:hover,
  &:focus{
    background: $notifyMobHoverBg;

    &:before{
      background: $notifyMobHoverBg !important;
    }
  }
}

.each_like.mob_notify_activity:hover:before,
.each_like.mob_notify_activity:focus:before {
  background: $notificationMatchBg !important;
}

.mob_notify_activity:first-child{
  margin-left: 3vw;
}

.mob_notify_activity.each_like{
  background-color: $notificationMatchBg;
  backdrop-filter: blur(4px);
}

.mob_notify_activity_each_like_left{
  font-size: 12px;
  font-weight: bold;
  color: $notificationMatchColor;
  max-width: 90px;
}

.mob_notify_activity_each_like_right{
  display: flex;
  align-items: center;
  margin-left: auto;
}

.mob_notify_activity_each_like_right .each_like_man_photo_placeholder {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 100%;
  position: relative;
  z-index: 1;

  &:after {
    content: '';
    width: 40px;
    height: 40px;
    background-color: #fafaff;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }

  svg {
    width: 18px;
    height: 18px;
    fill: #c3ccd6;
    position: absolute;
    top: 0;
    left: -1%;
    transform: translate(50%, 50%);
    margin: 0;
  }
}

.each_like_man_photo{
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 100%;
}

.each_like svg{
  display: block;
  width: 21px;
  height: 21px;
  margin: 0 5px;
  fill: $notifyItemEachLikeHeart;
}

.each_like_woman_photo_wrap{
  width: 40px;
  height: 40px;
}

.each_like_woman_photo{
  object-fit: cover;
  border-radius: 100%;
  width: 100%;
  height: 100%;
}

.mob_notify_each_like_close{
  width: 17px;
  height: 17px;
  margin-left: auto;
  position: relative;
  top: -15px;
  right: 3px;

  svg {
    fill: $notificationMatchColor;
  }
}

.mob_notify_photo_wrap{
  margin-right: 7px;
}

.mob_notify_photo{
  display: block;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  object-fit: cover;
  object-position: top;
}

.mob_notify_name{
  font-weight: 600;
  font-size: 14px;
  color: $notifyGirlItemTitle;
  margin-bottom: 4px;
}

.mob_notify_text{
  font-weight: 500;
  font-size: 12px;
  color: $notificationDefaultText;
  position: relative;
  padding-left: 20px;
}

.mob_notify_text:before{
  content: '';
  display: block;
  width: 14px;
  height: 14px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  left: 0;
  top: 0;
}

.mob_notify_activity.searching .mob_notify_text:before{
  background-image: url(../../../../img/c4-searching-ico.svg);
}
.mob_notify_activity.updated .mob_notify_text:before{
  background-image: url(../../../../img/profile-ico-lightblue.svg);
  top: 3px;
}
.mob_notify_activity.joined .mob_notify_text:before{
  background-image: url(../../../../img/pin-lightgreen.svg);
  top: 3px;
}
.mob_notify_activity.viewed .mob_notify_text:before{
  background-image: url(../../../../img/c4-viwed.svg);
}
.mob_notify_activity.viewed_photos .mob_notify_text:before{
  background-image: url(../../../../img/pic-orange.svg);
}
.mob_notify_activity.liked .mob_notify_text:before{
  background-image: url(../../../../img/c4-like.svg);
  background-size: 12px;
  top: 3px;
}
.mob_notify_activity.add_favs .mob_notify_text:before{
  background-image: url(../../../../img/star-yellow-2.svg);
  top: 3px;
}
.mob_notify_activity.unknown_like .mob_notify_text:before{
  background-image: url(../../../../img/c4-like.svg);
  background-size: 12px;
  top: 3px;
}
.mob_notify_activity.online_hi .mob_notify_text:before{
  background-image: url(../../../../img/c4-online-ico.svg);
  background-size: 12px;
  top: 3px;
}
.mob_notify_activity.appeal .mob_notify_text:before{
  background-image: url(../../../../img/c4-appeal-ico.svg);
  background-size: 13px;
  top: 3px;
}
.mob_notify_activity.no_chat .mob_notify_text:before{
  background-image: url(../../../../img/c4-no-chats-ico.svg);
  background-size: 12px;
  top: 3px;
}
.mob_notify_activity.want_chat .mob_notify_text:before{
  background-image: url(../../../../img/c4-want-chat-ico.svg);
  background-size: 12px;
  top: 3px;
}

.mob_notify_close{
  width: 17px;
  height: 17px;
  margin-left: auto;
  position: relative;
  top: -15px;

  svg {
    fill: $notifyItemClose;
  }
}

.mob_notify_ideal_text{
  font-weight: 500;
  font-size: 12px;
  color: $notifyGirlItemTitle;
  max-width: 110px;
}

.mob_notify_ideal_right{
  display: flex;
  align-items: center;
  margin-left: auto;
}

.mob_notify_ideal_right .mob_notify_ideal_man_photo_placeholder {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  object-fit: cover;
  object-position: top;
  position: relative;
  z-index: 1;

  &:after {
    content: '';
    width: 40px;
    height: 40px;
    background-color: #fafaff;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }

  svg {
    width: 18px;
    height: 18px;
    fill: #c3ccd6;
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(50%, 50%);
    margin: 0;
  }
}

.mob_notify_ideal_man_photo,
.mob_notify_ideal_woman_photo{
  width: 40px;
  height: 40px;
  border-radius: 100%;
  object-fit: cover;
  object-position: top;
}

.mob_notify_ideal_right svg{
  width: 14px;
  height: 14px;
  margin: 0 7px;
  fill: $notifyMatchChain;
}

.ideal_match .mob_notify_close{
  margin-left: 9px;
}

.mob_notify_activity.unknown_like .mob_notify_photo_wrap{
  width: 40px;
  height: 40px;
  border-radius: 100%;
  flex: none;
  background-image: url(../../../../img/unknown-like-question-2.svg);
  background-position: calc(50% - 0px) calc(50% + 0px);
  background-repeat: no-repeat;
  background-size: 16px;
  background-color: $notifyMobDefaultBg;
}

.mob_notify_activity.unknown_like .mob_notify_text{
  max-width: 150px;
}

.mob_notify_activity.online_hi .mob_notify_text span{
  color: $notifyGirlItemOnlineText;
}

.mob_notify_activity.appeal .mob_notify_text span{
  color: $notifyGirlItemAppealText;
}

.mob_notify_activity.no_chat .mob_notify_text span{
  color: $notifyGirlItemNoChatMobText;
}

.mob_notify_activity.want_chat .mob_notify_text span{
  color: $notifyGirlItemWantChatText;
}

@media screen and (max-width: 1139px){
  div.mob_notify_list_fixed_item{
    padding: 0;
  }

  .mob_notify_list_fixed_item .mob_notify_activity{
    width: 100%;
    height: 60px;
    margin-left: 0;
    padding-right: 6px;
  }

  .mob_notify_list_fixed_item .mob_notify_name{
    font-size: 12px;
  }

  .mob_notify_list_fixed_item .mob_notify_ideal_right svg{
    margin: 0 2px;
  }

  .mob_notify_list_fixed_item .mob_notify_close{
    margin-left: 5px;
    flex: none;
  }

  .mob_notify_list_fixed_item .mob_notify_ideal_text{
    font-size: 11px;
    margin-right: 5px;
  }

  .mob_notify_list_fixed_item .mob_notify_text{
    font-size: 11px;
    padding-left: 14px;
  }

  .mob_notify_list_fixed_item .mob_notify_text:before{
    width: 10px;
    height: 10px;
    top: 2px;
  }

  .mob_notify_list_fixed_item .mob_notify_activity.liked .mob_notify_text:before{
    top: 3px;
    background-size: contain;
  }

  .mob_notify_list_fixed_item .mob_notify_activity.unknown_like .mob_notify_text:before{
    top: 3px;
    background-size: contain;
  }

  .mob_notify_list_fixed_item .mob_notify_activity.joined .mob_notify_text:before{
    top: 3px;
    background-size: contain;
  }

  .mob_notify_list_fixed_item .mob_notify_activity.online_hi .mob_notify_text:before{
    top: 3px;
    background-size: contain;
  }

  .mob_notify_list_fixed_item .mob_notify_activity.appeal .mob_notify_text:before{
    top: 3px;
    background-size: contain;
  }

  .mob_notify_list_fixed_item .mob_notify_activity.no_chat .mob_notify_text:before{
    top: 3px;
    background-size: contain;
  }

  .mob_notify_list_fixed_item .mob_notify_activity.want_chat .mob_notify_text:before{
    top: 3px;
    background-size: contain;
  }

  .mob_notify_list_fixed_item .mob_notify_activity.each_like{
    background-image: none;
    padding-left: 9px;
  }

  .mob_notify_list_fixed_item .mob_notify_activity_each_like_left{
    font-size: 12px;
    font-weight: 500;
    margin-right: 3px;
  }

  .mob_notify_list_fixed_item .each_like svg{
    margin: 0 3px;
    width: 18px;
    height: 18px;
  }

  .mob_notify_list_fixed_item,
  .mob_notify_each_like_close{
    flex: none;
    margin-left: 5px;
  }
}

@media (orientation: landscape) and (max-width: 900px) and (max-height: 500px) and (min-width: 420px){
  :global(.mob_notify_bottom.likeher) .mob_notify_activity{
    width: 42vw;
  }
}

