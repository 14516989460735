@import "@sitestyles";

.verified_block {
  position: relative;
  z-index: 3;
}

.verified_status {
  font-size: 14px;
  font-weight: 600;
  text-align: left;
  color: #4F4F4F;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
  cursor: pointer;
  transition-duration: .3s;

  &:before {
    content: '';
    width: 24px;
    height: 24px;
  }

  &:hover,
  &:focus {
    color: #333;
  }

  @media screen and (max-width: 699px) {
    font-size: 12px;
  }
}

.verified_status.approved:before {
  background: url(../../../img/verified_status.svg) center no-repeat;
  background-size: contain;
}

.verified_status.unverified:before {
  background: url(../../../img/unverified_status.svg) center no-repeat;
  background-size: contain;
}

.verified_status.need_approve:before {
  background: url(../../../img/pending_status.svg) center no-repeat;
  background-size: contain;
}

.verified_status.declined:before {
  background: url(../../../img/rejected_status.svg) center no-repeat;
  background-size: contain;
}

.verified_tip {
  position: absolute;
  width: 240px;
  padding: 16px;
  border-radius: 4px;
  background: #333333;
  display: none;
  top: 25px;
  left: -20px;

  &:before {
    content: '';
    width: 8px;
    height: 8px;
    background: #333333;
    transform: rotate(45deg);
    position: absolute;
    top: -2px;
    left: 28px;
  }

  @media screen and (max-width: 699px) {
    padding: 12px;
    width: 190px;
  }
}

.verified_tip.right {
  left: unset;
  right: -35px;

  &:before {
    left: unset;
    right: 44px;
  }

  @media screen and (max-width: 699px) {
    right: -17px;
    width: 160px;

    &:before {
      right: 26px;
    }
  }
}

.verified_tip h3 {
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  text-align: left;
  white-space: initial;
  color: #FFFFFF;
  margin-bottom: 4px;

  @media screen and (max-width: 699px) {
    font-size: 12px;
  }
}

.verified_tip p {
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  white-space: initial;
  color: #FFFFFF;

  @media screen and (max-width: 699px) {
    font-size: 10px;
    line-height: 13px;
  }
}

.verified_tip_buttons_wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
  margin-top: 14px;
}

.verified_tip_button {
  outline: none;
  background: none;
  cursor: pointer;
  font-size: 12px;
  font-weight: 600;
  line-height: 14px;
  text-align: left;
  text-decoration-line: underline;
  white-space: initial;
  color: #FFFFFF;
  transition-duration: .3s;

  &:hover,
  &:focus {
    opacity: 0.7;
  }
}

.open .verified_tip {
  display: block;
}
