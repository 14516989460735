@import "@sitestyles";

.imbra_form_popup {
  position: relative;
  background: #fff;
  //width: calc(100vw - 30px);
  //height: calc(100vh - 30px);
  padding: 20px 40px 40px 40px;
  margin: 15px;
  text-align: left;
  overflow-y: auto;
  border-radius: 8px;
  height: 100%;

  &::-webkit-scrollbar {
    width: 5px;
    background: $bleachColor;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: $accentLeftColor;
    border-radius: 7px;
  }

  @media screen and (max-width: 600px) {
    padding: 15px 10px 30px 10px;
  }
}

.imbra_form_title {
  font-weight: 700;
  font-size: 30px;
  line-height: 44px;
  text-align: center;
  color: $mainTitleColor;
  margin-bottom: 16px;
  border-bottom: 1px solid $mainTitleColor;
  padding-bottom: 10px;

  @media screen and (max-width: 1300px) {
    font-size: 24px;
  }

  @media screen and (max-width: 600px) {
    font-size: 20px;
    line-height: 26px;
  }
}

.imbra_form_text {
  color: $mainTextColor;
  font-weight: 500;
  font-size: 17px;
  margin-top: 10px;

  a {
    color: #0000FF;
    text-decoration: underline;
    cursor: pointer;

    &:hover {
      text-decoration: none;
    }
  }

  @media screen and (max-width: 1300px) {
    font-size: 15px;
  }

  @media screen and (max-width: 600px) {
    font-size: 13px;
  }
}

.imbra_form_table {
  margin-top: 20px;
  border: 1px solid #000;
  display: flex;

  @media screen and (max-width: 900px) {
    flex-direction: column;
  }
}

.imbra_form_table_col {
  position: relative;
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 25%;
  border-right: 1px solid #000;

  &:last-child {
    border-right: 1px solid transparent;
  }

  @media screen and (max-width: 900px) {
    width: 100%;
    flex-direction: row;
    border: 1px solid transparent;
    border-bottom: 1px solid #000;

    &:last-child {
      border-bottom: 1px solid transparent;
    }
  }
}

.imbra_form_table_head {
  border-bottom: 1px solid #000;
  height: 50px;
  padding: 0 10px;
  font-size: 18px;
  color: #000;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 1300px) {
    font-size: 16px;
  }

  @media screen and (max-width: 900px) {
    border: transparent;
    border-right: 1px solid #000;
    height: auto;
    width: 40%;
  }

  @media screen and (max-width: 600px) {
    font-size: 13px;
  }
}

.imbra_form_table_body {
  height: 60px;
  padding: 0 10px;
  font-size: 19px;
  color: #00008B;
  font-weight: 600;
  text-align: center;

  &.error {
    color: #FF3030;
  }

  @media screen and (max-width: 1300px) {
    font-size: 17px;
  }

  @media screen and (max-width: 900px) {
    border: transparent;
    width: 60%;
  }

  @media screen and (max-width: 600px) {
    font-size: 14px;
    height: 50px;
  }
}

.imbra_form_question_block {
  position: relative;
  margin-top: 25px;
  color: $mainTextColor;

  @media screen and (max-width: 600px) {
    margin-top: 20px;
  }
}

.imbra_form_required {
  position: absolute;
  top: -17px;
  font-size: 13px;
  color: #FF3030;
  font-weight: 600;

  &.table {
    top: -1px;
    left: 1px;
  }

  @media screen and (max-width: 600px) {
    top: -15px;
    font-size: 11px;
  }
}

.imbra_form_question {
  font-size: 16px;
  font-weight: 500;

  a {
    color: #0000FF;
    text-decoration: underline;
    cursor: pointer;

    &:hover {
      text-decoration: none;
    }
  }

  @media screen and (max-width: 1300px) {
    font-size: 14px;
  }

  @media screen and (max-width: 600px) {
    font-size: 13px;
  }
}

.imbra_form_row {
  display: flex;
  margin-top: 10px;

  @media screen and (max-width: 600px) {
    flex-direction: column;
  }
}

.imbra_form_checkbox_row {
  display: flex;

  @media screen and (max-width: 600px) {
    margin-bottom: 10px;
  }
}

.imbra_form_checkbox {
  display: none;

  &:checked + .imbra_form_checkbox_label:before {
    content: '\2718';
    font-weight: bold;
    font-size: 16px;
    line-height: 1.1;

    @media screen and (max-width: 1300px) {
      font-size: 15px;
    }


  }
}

.imbra_form_checkbox_label {
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-right: 20px;
  font-size: 17px;
  font-weight: 500;

  &:before {
    content: '';
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 16px;
    border: 1px solid #000;
    border-radius: 4px;
    margin-right: 5px;
  }

  @media screen and (max-width: 1300px) {
    font-size: 15px;

    &:before {
      width: 18px;
      height: 14px;
    }
  }
}

.imbra_form_answer_wrap {
  position: relative;
  margin-top: 15px;
  max-width: 480px;
  height: 120px;

  @media screen and (max-width: 600px) {
    height: 100px;
  }
}

.imbra_form_answer {
  width: 100%;
  height: 100%;
  resize: none;
  padding: 5px 10px;
  background-color: #eee;
  color: #00008B;
  font-size: 15px;

  &.error {
    border: 2px solid #FF3030;
  }

  @media screen and (max-width: 600px) {
    font-size: 14px;
  }
}

.imbra_form_answer_counter {
  position: absolute;
  right: 5px;
  bottom: 5px;
  z-index: 3;
  font-size: 13px;
  color: #000;

  &.error {
    color: #FF3030;
  }

  @media screen and (max-width: 600px) {
    font-size: 12px;
  }
}

.imbra_form_today_wrap {
  margin-top: 50px;

  @media screen and (max-width: 600px) {
    margin-top: 20px;
  }
}

.imbra_form_today_date {
  font-size: 17px;
  font-weight: 600;

  @media screen and (max-width: 1300px) {
    font-size: 15px;
  }

  @media screen and (max-width: 600px) {
    font-size: 14px;
  }
}

.imbra_form_today_input {
  margin-top: 5px;
  padding: 6px 5px;
  border: 1px solid #000;
  width: 100%;
  max-width: 300px;
  font-size: 16px;

  @media screen and (max-width: 1300px) {
    font-size: 15px;
  }

  @media screen and (max-width: 600px) {
    font-size: 14px;
  }

  &.error {
    border: 2px solid #FF3030;
    color: #FF3030;
  }
}

.imbra_form_download_btn {
  margin: 50px auto 0;
  width: 100%;
  max-width: 300px;
  height: 45px;
  border: 2px solid #828282;
  box-sizing: border-box;
  border-radius: 5px;
  color: #828282;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;

  a {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &.active {
    color: $whiteColor;
    background: $backgroundButtonColor;
    border: none;

    &:hover {
      box-shadow: $smallBtnShadowHover;
    }
  }

  @media screen and (max-width: 600px) {
    font-size: 13px;
    margin-top: 30px;
  }
}

.imbra_form_close {
  cursor: pointer;
  position: absolute;
  right: 16px;
  top: 16px;
  padding: 5px;
  background: #C2C2C2;
  width: 24px;
  height: 24px;
  -webkit-mask: url("../../../../../img/cross_white.svg") center no-repeat;
  mask: url("../../../../../img/cross_white.svg") center no-repeat;
  -webkit-mask-size: contain;
  mask-size: contain;

  &:hover {
    background: $attachTouchText;
  }

  @media screen and (max-width: 600px) {
    right: 14px;
    top: 14px;
    width: 20px;
    height: 20px;
  }
}