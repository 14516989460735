@import "@sitestyles";

.column_3_profile_man_manage_credit_card_btn {
  width: 289px;
  height: 42px;
  background: $whiteColor;
  border: 1px solid $primaryColor;
  box-sizing: border-box;
  border-radius: 5px;
  margin-left: auto;
  margin-right: 34px;
  margin-top: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.column_3_profile_man_manage_credit_card_btn:hover {
  box-shadow: $searchItemLikeBtnShadowHover;
}

.column_3_profile_man_manage_credit_card_btn .manage_credit_card_btn_title {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 140%;

  display: flex;
  align-items: center;

  color: $primaryColor;
}

.column_3_profile_man_manage_credit_card_btn > div {
  display: flex;
  align-items: center;
  margin-right: 6px;
}

.popup_your_credit_cards {
  width: 607px;
  max-width: 100%;
  background-color: $whiteColor;
  padding: 45px 50px;
  border-radius: 10px;
  margin: auto;
  position: relative;
  //min-height: 300px;
}

.popup_your_credit_cards_title {
  font-weight: 600;
  font-size: 26px;
  text-align: center;
  letter-spacing: 0.06em;
  color: $accentRightColor;
  margin-bottom: 30px;
}

.popup_your_credit_cards_current_card {
  height: 62px;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  justify-content: space-between;
  border: 1px solid $defaultTextColorAlpha3;
  border-radius: 10px;
  padding: 2px 28px 0 16px;
}

.popup_your_credit_cards_current_card_left {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
}

.popup_your_credit_cards_current_card_input {
  width: 19px;
  height: 19px;
  border: 1px solid $primaryColor;
  border-radius: 100%;
  position: relative;
  margin-right: 15px;
  flex: none;
}

.popup_your_credit_cards_current_card_input:after {
  content: '';
  display: block;
  width: 11px;
  height: 11px;
  position: absolute;
  left: 3px;
  top: 3px;
  background-color: $primaryColor;
  border-radius: 100%;
}

.popup_your_credit_cards_current_card_img {
  margin-right: 19px;
}

.popup_your_credit_cards_current_card_text {
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0.03em;
  color: $defaultTextColor;
}

/*
.popup_your_credit_cards_current_card_info_text{
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	-ms-align-items: center;
	align-items: center;
	width: 140px;
	font-weight: 500;
	font-size: 12px;
	letter-spacing: 0.01em;
	color: $onlineColor;
	width: max-content;
	margin-left: auto;
}

.popup_your_credit_cards_current_card_info_text img{
	margin-right: 8px;
	width: 20px;
}*/

.popup_your_credit_cards_list {
  margin-top: 14px;
  max-height: 300px;
  overflow-y: auto;
  padding-right: 40px;
  margin-right: -40px;
}

.popup_your_credit_cards_list::-webkit-scrollbar {
  width: 3px;
  background: $bleachColor;
  border-radius: 22px;
}

.popup_your_credit_cards_list::-webkit-scrollbar-thumb {
  background: $accentLeftColor;
  border-radius: 9px;
}

.popup_your_credit_cards_list_item {
  height: 62px;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid $defaultTextColorAlpha3;
  padding: 2px 28px 0 16px;
}

.popup_your_credit_cards_list_item_left {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
}

.popup_your_credit_cards_list_item_input {
  width: 19px;
  height: 19px;
  border: 1px solid $primaryColor;
  border-radius: 100%;
  position: relative;
  margin-right: 15px;
  cursor: pointer;
  flex: none;
}

.popup_your_credit_cards_list_item_img {
  margin-right: 19px;
}

.popup_your_credit_cards_list_item_text {
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.03em;
  color: $defaultTextColor;
}

.popup_your_credit_cards_current_card_info_text {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  width: 140px;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.01em;
  color: $onlineColor;
  width: max-content;
  margin-left: auto;
}

.popup_your_credit_cards_current_card_info_text img {
  margin-right: 8px;
  width: 20px;
}

.popup_your_credit_cards_current_card_info_text svg {
  margin-right: 8px;
  width: 20px;
  height: 20px;
  fill: #25b061;
}

.popup_your_credit_cards_list_item_remove {
  cursor: pointer;
  margin-left: 10px;
}

.popup_your_credit_cards_list_item_remove svg {
  width: 28px;
  height: 35px;
  fill: #db325d;
}

.popup_your_credit_cards_buttons {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}

.popup_your_credit_cards_add_card_btn {
  width: 203px;
  height: 44px;
  line-height: 44px;
  background-color: $whiteColor;
  border: 1px solid $primaryColor;
  border-radius: 4px;
  text-align: center;
  font-weight: 600;
  letter-spacing: 0.01em;
  color: $primaryColor;
  cursor: pointer;
}

.popup_your_credit_cards_add_card_btn img {
  vertical-align: middle;
  margin-bottom: 4px;
  margin-right: 12px;
}

.popup_your_credit_cards_add_card_btn svg {
  vertical-align: middle;
  margin-bottom: 4px;
  margin-right: 12px;
  width: 21px;
  height: 21px;
  fill: $primaryColor;
}

.popup_your_credit_cards_save_changes_btn {
  width: 111px;
  height: 44px;
  line-height: 44px;
  background: linear-gradient(90.58deg, $accentLeftColor 0%, $accentRightColor 100%);
  border-radius: 4px;
  text-align: center;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0.01em;
  color: $whiteColor;
  cursor: pointer;
}

.popup_your_credit_cards_save_changes_btn img {
  vertical-align: middle;
  margin-bottom: 2px;
  margin-right: 12px;
}

.popup_your_credit_cards_save_changes_btn svg {
  vertical-align: middle;
  margin-bottom: 2px;
  margin-right: 12px;
  width: 20px;
  height: 21px;
  fill: #FFFFFF;
}

.popup_your_credit_cards_no_added {
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.03em;
  color: $defaultTextColor;
  text-align: center;
}

.popup_your_credit_cards_no_added img {
  vertical-align: middle;
  margin-right: 19px;
}

.popup_your_credit_cards_empty_add_btn {
  width: 203px;
  height: 44px;
  line-height: 44px;
  margin: 30px auto 0;
  background: linear-gradient(90.58deg, $accentLeftColor 0%, $accentRightColor 100%);
  border-radius: 4px;
  text-align: center;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0.01em;
  color: $whiteColor;
  cursor: pointer;
}

.popup_your_credit_cards_empty_add_btn:hover {
  box-shadow: $searchItemBtnShadowHover;
}

.popup_your_credit_cards_empty_add_btn img {
  vertical-align: middle;
  margin-bottom: 2px;
  margin-right: 12px;
}

.popup_your_credit_cards_empty_add_btn svg {
  vertical-align: middle;
  margin-bottom: 2px;
  margin-right: 12px;
  width: 22px;
  height: 22px;
  fill: #FFFFFF;
}

.popup_your_credit_cards_add_card_row {
  margin-bottom: 30px;
}

.popup_your_credit_cards_add_card_row label {
  display: block;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  color: $stormGreyColor;
  margin-bottom: 5px;
}

.popup_your_credit_cards_add_card_row input {
  width: 100%;
  height: 42px;
  background-color: $bleachColor;
  border-radius: 4px;
  border: none;
  padding: 0 15px;
  outline: none;
  font-family: inherit;
  font-weight: 500;
  letter-spacing: 0.03em;
  color: $accentLeftColor;
}

.popup_your_credit_cards_add_card_row input::placeholder {
  color: $defaultTextColorAlpha3;
}

.popup_your_credit_cards_add_card_row_with_2 {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}

.popup_your_credit_cards_add_card_row_with_2 .popup_your_credit_cards_add_card_row {
  width: calc(50% - 16px);
  margin-bottom: 0;
}

.popup_your_credit_cards_add_card_set_default input {
  display: none;
}

.popup_your_credit_cards_add_card_set_default label {
  display: block;
  position: relative;
  letter-spacing: 0.03em;
  color: $defaultTextColorAlpha8;
  font-weight: 500;
  padding-left: 37px;
  cursor: pointer;
}

.popup_your_credit_cards_add_card_set_default label:before {
  content: '';
  display: block;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  border: 1px solid $primaryColor;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.popup_your_credit_cards_add_card_set_default input:checked + label:after {
  content: '';
  display: block;
  width: 14px;
  height: 14px;
  position: absolute;
  left: 4px;
  top: 50%;
  transform: translateY(-50%);
  background-color: $primaryColor;
  border-radius: 100%;
}

.popup_your_credit_cards_add_card_save_btn {
  width: 203px;
  height: 44px;
  line-height: 44px;
  background: linear-gradient(90.58deg, $accentLeftColor 0%, $accentRightColor 100%);
  border-radius: 4px;
  text-align: center;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0.01em;
  color: $whiteColor;
  cursor: pointer;
  margin: 30px auto 0;
}

.popup_your_credit_cards_add_card_save_btn img {
  vertical-align: middle;
  margin-bottom: 2px;
  margin-right: 12px;
}

.popup_your_credit_cards_close {
  position: absolute;
  right: 10px;
  top: 10px;
  padding: 5px;
}

@media screen and (max-width: 599px) {
  .popup_your_credit_cards {
    padding: 16px;
    max-width: calc(100% - 16px);
    margin: auto;
    max-height: 80vh;
    overflow: scroll;
  }

  .popup_your_credit_cards_title {
    font-size: 22px;
  }

  .popup_your_credit_cards_no_added {
    display: flex;
    align-items: center;
  }

  .popup_your_credit_cards_save_changes_btn {
    width: 85px;
  }

  .popup_your_credit_cards_save_changes_btn img {
    margin-right: 8px;
  }

  .popup_your_credit_cards_save_changes_btn svg {
    margin-right: 8px;
  }

  .popup_your_credit_cards_add_card_btn {
    width: 155px;
  }

  .popup_your_credit_cards_add_card_btn img {
    margin-right: 8px;
  }

  .popup_your_credit_cards_add_card_btn svg {
    margin-right: 8px;
  }

  .ui-content {
    padding: 0;
  }

  .popup_your_credit_cards_current_card_img {
    width: 27px;
  }

  .popup_your_credit_cards_current_card_text {
    font-size: 12px;
  }

  .popup_your_credit_cards_current_card_info_text {
    width: auto;
  }

  .popup_your_credit_cards_current_card_info_text span {
    display: none;
  }

  .popup_your_credit_cards_current_card_info_text img {
    margin: 0;
  }

  .popup_your_credit_cards_current_card_info_text svg {
    margin: 0;
  }

  .popup_your_credit_cards_list {
    margin-right: 0;
    padding-right: 0;
  }

  .popup_your_credit_cards_current_card {
    height: 39px;
    padding-right: 10px;
  }

  .popup_your_credit_cards_list_item {
    height: 39px;
    padding-right: 15px;
  }

  .popup_your_credit_cards_current_card_input,
  .popup_your_credit_cards_list_item_input {
    width: 12px;
    height: 12px;
    margin-right: 10px;
  }

  .popup_your_credit_cards_current_card_input:after,
  .popup_your_credit_cards_list_item_input:after {
    width: 6px;
    height: 6px;
    left: 2px;
    top: 2px;
  }

  .popup_your_credit_cards_current_card_img {
    margin-right: 9px;
  }

  .popup_your_credit_cards_list_item_remove img {
    width: 13px;
  }

  .popup_your_credit_cards_list_item:last-child {
    border: none;
  }
}
