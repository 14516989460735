@import "@sitestyles";

$mobileBreakpoint: 599px;

.email_resubscribe_popup {
  background-color: #fff;
  border-radius: 10px;
  width: 600px;
  max-width: 100%;
  padding: 40px;
  gap: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: $mobileBreakpoint) {
    width: 343px;
  }
}

.email_resubscribe_title {
  color: $accentLeftColor;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;

  @media (max-width: $mobileBreakpoint) {
    font-size: 18px;
  }
}

.email_resubscribe_img {
  width: 350px;
  height: 233px;

  @media (max-width: $mobileBreakpoint) {
    width: 180px;
    height: 119px;
  }
}

.email_resubscribe_text {
  color: rgba(51, 51, 51, 0.80);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;

  @media (max-width: $mobileBreakpoint) {
    font-size: 14px;
  }
}

.email_resubscribe_btn_wrap {
  display: flex;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
}

.email_resubscribe_btn {
  height: 48px;

  background-image: $buttonBackgroundColor, linear-gradient(to right, $accentLeftColor, $accentRightColor);
  border-radius: 5px;
  padding: 10px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  box-sizing: border-box;

  font-size: 16px;
  color: #FFFFFF;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  cursor: pointer;
  border: solid 1px $accentLeftColor;

  &:hover {
    background-image: linear-gradient(white, white), linear-gradient(to right, $accentLeftColor, $accentRightColor);
    span {
      background: $buttonBackgroundColor;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }
  }

  &.outline {
    background: #fff;
    border: 1px solid #CDD3DA;
    color: #6F6F6F;
    font-weight: 700;

    &:hover {
      background: #6F6F6F;

      span {
        background: #fff;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
      }
    }
  }
}

.close_modal {
  position: absolute;
  right: 5px;
  top: 5px;
  padding: 5px;
  transition-duration: .3s;
  cursor: pointer;

  svg {
    display: block;
    cursor: pointer;
    fill: $notifyItemClose;
    width: 24px;
    height: 24px;
    transition-duration: .3s;
  }

  &:hover,
  &:focus{
    svg{
      fill: $primaryColor;
    }
  }
}