@import '@sitestyles';

.nps_modal {
    width: 600px;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 10px;
    background: $tutorialPopupBackground;
    position: fixed;
    padding: 40px;
    box-sizing: border-box;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: 0.7s;
    z-index: 999999;

    &.success {
        width: 480px;
        align-items: center;

        &::before {
            content: '';
            background-image: url(../../../../../img/success_icon.svg);
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            width: 64px;
            min-width: 64px;
            min-height: 64px;
            height: 64px;

            @media screen and (max-width: 620px) {
                width: 40px;
                min-width: 40px;
                min-height: 40px;
                height: 40px;
            }
        }

        @media screen and (max-width: 520px) {
            width: calc(100% - 32px);
        }
    }

    @media screen and (max-width: 620px) {
        width: 343px;
        padding: 24px;
    }

    @media screen and (max-width: 350px) {
        width: calc(100% - 32px);
    }
}

.nps_modal_title {
    color: $npsModalTitleColor;
    font-size: 24px;
    font-weight: 700;
    line-height: normal;
    margin: 0 auto 24px;

    @media screen and (max-width: 620px) {
        font-size: 18px;
        margin: 0 auto 16px;
    }
}

.nps_modal.success .nps_modal_title {
    color: $npsModalTitleColor;
    font-size: 24px;
    font-weight: 700;
    line-height: normal;
    margin: 20px auto 24px;

    @media screen and (max-width: 620px) {
        font-size: 18px;
        margin: 12px auto 16px;
    }
}

.mix_nps_content {
    @media screen and (max-width: 620px) {
        max-height: 408px;
        overflow-y: auto;
        padding-right: 8px;

        &::-webkit-scrollbar {
            width: 3px;
            background: #EEE;
            border-radius: 9px;
        }

        &::-webkit-scrollbar-thumb {
            background: #858585;
            border-radius: 9px;
        }
    }
}

.nps_modal_text {
    color: $ratingTextColor;
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
    margin: 0 0 32px;
    display: flex;
    gap: 8px;

    @media screen and (max-width: 620px) {
        font-size: 14px;
        padding-right: 5px;
        margin: 0 0 24px;
    }
}

.nps_modal.success .nps_modal_text {
    text-align: center;
    font-weight: 400;
    color: rgba(51, 51, 51, 0.7);
}

.rating_text_row {
    color: $ratingTextColor;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    margin: 0 0 16px;

    @media screen and (max-width: 620px) {
        display: none;
    }
}

.rating_textarea_wrapper {
    max-height: 96px;
    height: 96px;
    margin-bottom: 32px;
    position: relative;

    @media screen and (max-width: 620px) {
        margin-bottom: 24px;
    }
}

.rating_textarea {
    border-radius: 4px;
    outline: 0;
    border: 1px solid $npsInputBorderColor;
    height: 96px;
    min-height: 96px;
    padding: 12px 12px 32px;
    box-sizing: border-box;
    width: 100%;
    min-width: 100%;
    background-color: $textareaBackgroundColor;
    color: #333333;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    font-family: $mainFont;
    max-height: 300px;
    resize: none;

    &:hover {
        background-color: $npsTextAreaBackgroundActiveColor;
        + .rating_textarea_counter {
            background-color: $npsTextAreaBackgroundActiveColor;
        }
    }

    &:active,
    &:focus {
        background-color: #FFFFFF;
        border: 2px solid $textareaBorderActiveColor;
        outline: 4px solid $textareaOutlineColor;
        + .rating_textarea_counter {
            background-color: #FFFFFF;
            bottom: 2px;
        }
    }

    &::placeholder {
        font-family: $mainFont;
        color: #8a9ba9;
        font-size: 14px;
        font-weight: 400;
        line-height: normal;
    }

    &::-webkit-scrollbar {
        width: 3px;
        background: #f7f8fd;
        border-radius: 20px;
    }

    &::-webkit-scrollbar-thumb {
        background: #adbaff;
        border-radius: 20px;
    }
}

.rating_textarea_counter {
    color: #8a9ba9;
    min-height: 31px;
    max-height: 31px;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    position: absolute;
    bottom: 1px;
    padding: 5px 0 7px 10px;
    left: 2px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 98%;
    border-radius: 0 0 4px 4px;
    z-index: 10;
    background-color: $textareaBackgroundColor;
}

.rating_text {
    color: $ratingTextColor;
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
}

.rating_inputs_row {
    display: flex;
    gap: 13px;
    align-items: center;
    justify-content: center;
    margin: 0 auto 24px;
    flex-wrap: wrap;

    @media screen and (max-width: 620px) {
        width: 250px;
        column-gap: 12px;
        row-gap: 24px;
        position: relative;
        &::after {
            content: '';
            display: block;
            width: 100%;
            height: 1px;
            background-color: rgba(0, 0, 0, 0.1);
            margin: 12px 0;
            position: absolute;
            top: 42%;
            bottom: 0;
            left: 0;
            right: 0;
        }
    }
}

.rating_radio {
    display: none;
}

.rating_radio:checked + .rating_radio_label {
    color: $npsRadioLabelColorChecked;
    .rating_radio_label_circle_background {
        background: $npsRadioLabelColorChecked;
        border: none;
    }
}

.rating_radio_label {
    width: 40px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    row-gap: 4px;
    border-radius: 6px;
    box-sizing: border-box;
    transition: all 0.3s ease-in-out;
    color: #00000080;
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;
    outline: 0;
    margin-bottom: 5px;

    &:hover,
    &:focus {
        color: $npsRadioLabelColorChecked;
        .rating_radio_label_circle_background {
            background: $npsRadioLabelColorHover;
            border: none;
        }
    }

    @media screen and (max-width: 620px) {
        font-size: 14px;
    }
}

.rating_radio_label_circle_background {
    width: 40px;
    height: 40px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    transition: all 0.3s ease-in-out;
}

.rating_radio_label_emoji {
    width: 32px;
    height: 32px;
    background-size: cover;
}

.rating_radio_label_emoji[data-index='rate_1'],
.rating_radio_label_emoji[data-index='rate_2'],
.rating_radio_label_emoji[data-index='rate_3'],
.rating_radio_label_emoji[data-index='rate_4'],
.rating_radio_label_emoji[data-index='rate_5'],
.rating_radio_label_emoji[data-index='rate_6'] {
    background-image: url(../../../../../img/emoji_unsatisfied.svg);
}

.rating_radio_label_emoji[data-index='rate_7'],
.rating_radio_label_emoji[data-index='rate_8'] {
    background-image: url(../../../../../img/emoji_neutral.svg);
}

.rating_radio_label_emoji[data-index='rate_9'],
.rating_radio_label_emoji[data-index='rate_10'] {
    background-image: url(../../../../../img/emoji_satisfied.svg);
}

.nps_button_continue {
    width: 100%;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $whiteColor;
    font-weight: 700;
    font-size: 18px;
    line-height: normal;
    border-radius: 8px;
    position: relative;
    padding: 0 16px;
    box-sizing: border-box;
    cursor: pointer;
    font-family: $mainFont;
    background-clip: padding-box, border-box;
    background-origin: border-box;
    border: $npsButtonBorderHover;
    background-image: $buttonModalBackgroundColor,
        linear-gradient(to right, $accentLeftColor, $accentRightColor);
    transition: all 0.3s ease-in-out;

    @media screen and (max-width: 620px) {
        font-size: 16px;
        margin-top: 6px;
    }

    &:hover {
        background-image: linear-gradient($buttonBackgroundColorHover, $buttonBackgroundColorHover),
            linear-gradient(to right, $accentLeftColor, $accentRightColor);

        .nps_button_continue_text {
            background: $buttonModalBackgroundColor;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;
            position: relative;
            z-index: 1;
        }
    }
}

.nps_button_continue_text {
    background: $buttonColor;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    font-weight: 700;
    font-size: 16px;
}

.nps_button_success {
    width: 100%;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $whiteColor;
    font-weight: 700;
    font-size: 16px;
    line-height: normal;
    border-radius: 5px;
    position: relative;
    margin: 0 auto;
    padding: 0 16px;
    box-sizing: border-box;
    cursor: pointer;
    font-family: $mainFont;
    background-clip: padding-box, border-box;
    background-origin: border-box;
    border: $npsButtonBorderHover;
    background-image: $buttonModalBackgroundColor,
        linear-gradient(to right, $accentLeftColor, $accentRightColor);

    @media screen and (max-width: 620px) {
        font-size: 16px;
        height: 40px;
    }

    &:before {
        background: #fff;
        content: '';
        display: block;
        width: 24px;
        min-width: 24px;
        height: 24px;
        margin-right: 11px;
        mask: url('../../../../../img/arrow-checked-circle.svg') center no-repeat;
        mask-size: contain;
    }

    &:hover {
        background-image: linear-gradient($buttonBackgroundColorHover, $buttonBackgroundColorHover),
            linear-gradient(to right, $accentLeftColor, $accentRightColor);

        .nps_button_success_text {
            background: $buttonModalBackgroundColor;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;
            position: relative;
            z-index: 1;
        }

        &:before {
            background: $buttonModalBackgroundColor;
        }
    }
}

.nps_button_success_text {
    background: $whiteColor;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    font-weight: 700;
}

.nps_modal_btn_close {
    width: 40px;
    min-width: 40px;
    height: 40px;
    background: rgba(51, 51, 51, 0.6);
    position: absolute;
    top: 8px;
    right: 8px;
    mask: url('../../../../../img/c3-media-close-tab.svg') center no-repeat;
    mask-size: 12px;
    -webkit-mask: url('../../../../../img/c3-media-close-tab.svg') center no-repeat;
    -webkit-mask-size: 16px;
    outline: 0;
    border: 0;
    transition: all 0.6s ease-in-out;
    cursor: pointer;
    display: flex;

    @media screen and (max-width: 620px) {
        top: 0;
        right: 0;
        -webkit-mask-size: 12px;
    }

    &:hover {
        background: $accentDarkColor;
    }
}

.textarea_error_message,
.contacts_input_error_message {
    color: #f15656;
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 8px;;
}

.contacts_input_error_message {
    margin-top: 6px;
}

.disabled {
    background-image: $buttonBackgroundColorDisabled;
    color: $whiteColor;
    cursor: default;

    .nps_button_continue_text {
        background: $buttonTextColorDisabled;
        background-clip: text;
    }

    &:hover {
        background-image: $buttonBackgroundColorDisabled;
        color: $buttonColor;
        border: $npsButtonBorderHover;

        .nps_button_continue_text {
            background: $buttonTextColorDisabled;
            background-clip: text;
        }
    }
}

.contacts_checkbox_wrapper {
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 8px;
    margin-bottom: 32px;

    @media screen and (max-width: 620px) {
        margin-bottom: 24px;
    }
}

.contacts_checkbox {
    display: none;

    &:checked + .contacts_checkbox_label {
        background-color: $npsCheckboxColor;
        border: 1px solid $npsCheckboxColor;
        &:after {
            content: '';
            display: block;
            width: 12px;
            min-width: 12px;
            height: 12px;
            border-radius: 100%;
            background: #FFFFFF;
            mask: url('../../../../../img/arrow-checked.svg') center no-repeat;
            mask-size: 12px 8px;
            -webkit-mask: url('../../../../../img/arrow-checked.svg') center no-repeat;
            -webkit-mask-size: 12px 8px;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }
    }
}

.contacts_checkbox_label {
    width: 18px;
    height: 18px;
    min-width: 18px;
    border: 1px solid $npsInputBorderColor;
    border-radius: 4px;
    cursor: pointer;
    position: relative;
}

.contacts_checkbox_info {
    color: rgba(0, 0, 0, 0.8);
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
}

.contacts_input_wrapper {
    display: none;
}

.contacts_input_wrapper.contacts_input_active {
    position: relative;
    display: block;
    height: 48px;
    width: 100%;
    min-width: 100%;
    background-color: #FFFFFF;
    margin-top: 43px;
    margin-bottom: 32px;

    @media screen and (max-width: 620px) {
        margin-bottom: 18px;
    }
}

.contacts_input {
    width: 100%;
    height: 100%;
    padding: 5px 10px;
    border-radius: 4px;
    border: 1px solid $npsInputBorderColor;
    box-sizing: border-box;
    font-size: 14px;
    font-weight: 400;
    color: $npsContactsInputColor;

    &::placeholder {
        color: rgba(51, 51, 51, 0.60);
        font-size: 14px;
        font-weight: 400;
        line-height: normal;
    }

    &:hover {
        background-color: $npsTextAreaBackgroundActiveColor;
    }

    &:active,
    &:focus {
        outline: 4px solid $textareaOutlineColor;
        background-color: #FFFFFF;
        border: 2px solid $textareaBorderActiveColor;
    }
}

.contacts_input_label {
    position: absolute;
    top: -60%;
    left: 0;
}

