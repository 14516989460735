.message_text_hello{
  width: 463px;
  height: 139px;
  max-width: 100%;
  padding-left: 37px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(210, 223, 245, 0.8);
  border-radius: 8px 8px 0 8px;
  position: relative;
  margin-bottom: 5px;
  overflow: hidden;
}

.message_text_hello_content{
  position: relative;
  z-index: 2;
}

.message_text_hello_title{
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 24px;
  color: #00317B;
  margin-bottom: 14px;
}

.message_text_hello_title img{
  margin-left: 9px;
  margin-top: -2px;
}

.message_text_hello_text{
  font-size: 20px;
  color: #333333;
  max-width: 200px;
}

.message_text_hello_img{
  position: absolute;
  right: 0;
  bottom: 0;
}

.message_text_hello_img.img_1{
  bottom: 50%;
  transform: translateY(50%);
}
.message_text_hello_img.img_2{

}
.message_text_hello_img.img_3{
  right: 33px;
  bottom: 50%;
  transform: translateY(50%);
}
.message_text_hello_img.img_4{

}
.message_text_hello_img.img_5{

}

@media screen and (max-width: 1399px){
  .message_text_hello{
    width: 332px;
    height: 104px;
    padding-left: 26px;
  }

  .message_text_hello_title{
    font-size: 18px;
    margin-bottom: 11px;
  }

  .message_text_hello_title img{
    width: 20px;
    margin-left: 7px;
  }

  .message_text_hello_text{
    font-size: 14px;
    max-width: 140px;
  }

  .message_text_hello_img{
    max-height: 90%;
    max-width: 150px;
  }

  .message_text_hello_img.img_3{
    right: 15px;
  }
}

@media screen and (max-width: 599px){
  .message_text_hello{
    width: 260px;
  }

  .message_text_hello_title{
    margin-bottom: 5px;
  }

  .message_text_hello_text{
    max-width: 100px;
  }

  .message_text_hello_img{
    //max-width: 100px;
  }

  .message_text_hello_img.img_1{
    width: 130px;
  }
  .message_text_hello_img.img_2{
    width: 140px;
    bottom: 10px;
    right: -12px;
  }
  .message_text_hello_img.img_3{
    width: 130px;
    right: 10px;
  }
  .message_text_hello_img.img_5{
    max-height: 95%;
  }
}

@media screen and (max-width: 359px){
  .message_text_hello{
    width: 207px;
    padding-left: 13px;
  }
  .message_text_hello_img.img_1{
    width: 107px;
    right: -20px;
  }
  .message_text_hello_img.img_2{
    width: 115px;
  }

  .message_text_hello_img.img_3{
    right: -15px;
  }
}