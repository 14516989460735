@import "@sitestyles";

.active_cursor {
  cursor: pointer;
}

.search_item_wrap {
  position: relative;
  padding: 3px;
  background-color: $whiteColor;
  box-shadow: 3px 3px 5px $backgroundSearchItemShadowColor;
  border-radius: 10px;
}

.search_item {
  position: relative;

  @media screen and (max-width: 599px) {
    padding-bottom: 37px;
  }
}

.search_item_photo_wrap {
  position: relative;
  width: 100%;
  min-height: 274px;
  padding-top: 166%;
  cursor: pointer;

  &:before {
    content: '';
    display: none;
    width: 100%;
    bottom: 0;
    left: 0;
    right: 0;
    height: 38px;
    position: absolute;
    z-index: 1;
    background: $whiteColor;

    @media screen and (max-width: 599px) {
      display: block;
    }
  }
}

.search_item_photo {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
  border-radius: 8px;
}

.search_item_photo_all_btn {
  width: 91px;
  height: 27px;
  position: absolute;
  top: 7px;
  right: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 4px;
  font-weight: 500;
  font-size: 11px;
  letter-spacing: -0.01em;
  color: $accentLeftColor;
  cursor: pointer;
  transition: background-color .3s;
  gap: 3px;

  @media screen and (max-width: 599px) {
    width: 64px;
    height: 24px;
    right: 5px;
    top: 5px;
    text-transform: capitalize;

    span {
      display: none;
    }
  }

  @media screen and (max-width: 370px) {
    height: 20px;
    font-size: 11px;
    width: 44px;

    svg {
      display: none;
    }
  }
}

.search_item_photo_all_btn svg {
  width: 16px;
  height: 16px;
  fill: $accentLeftColor;

  @media screen and (max-width: 599px) {
    width: 13px;
    height: 13px;
  }
}

.search_item_photo_wrap:hover .search_item_photo_all_btn {
  background-color: $whiteColor;
}

.search_item_online_status {
  width: 159px;
  height: 27px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 7px;
  left: -9px;
  background: $backgroundOnlineColor;
  border-radius: 3px;
  font-weight: 600;
  font-size: 14px;
  color: $onlineTitleColor;

  @media screen and (max-width: 599px) {
    width: 96px;
    height: 24px;
    font-size: 10px;
    top: 8px;
    left: -4px;
  }

  @media screen and (max-width: 370px) {
    height: 20px;
    font-size: 11px;
    width: 83px;
  }
}

.search_item_bottom {
  position: absolute;
  bottom: 3px;
  left: 2px;
  right: 2px;

  &:before {
    content: '';
    width: 100%;
    height: 120%;
    position: absolute;
    bottom: 0;
    left: 0;
    background: $backgroundSearchColor;
    border-radius: 0 0 8px 8px;
    pointer-events: none;
    transition: .3s;

    @media screen and (max-width: 599px) {
      bottom: 31px;
      z-index: 2;
      height: 75px;
    }
  }

  @media screen and (max-width: 599px) {
    bottom: 2px;
  }
}

.search_item_name_wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin-bottom: 10px;

  @media screen and (max-width: 599px) {
    margin-bottom: 6px;
  }
}

.search_item_name {
  text-align: center;
  font-weight: 600;
  font-size: 24px;
  color: $searchProfileNameColor;
  text-shadow: 0 1px 1px rgba(255, 255, 255, 0.32);
  word-break: break-word;
  padding-left: 3px;
  padding-right: 3px;
  position: relative;
  z-index: 3;

  @media screen and (max-width: 599px) {
    font-size: 14px;
  }

  @media screen and (max-width: 360px) {
    font-size: 12px;
  }
}

.search_item_big_buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 3px 3px;
  position: relative;
  gap: 8px;

  @media screen and (max-width: 599px) {
    padding: 0 0 2px;
    border-radius: 0 0 6px 6px;
    gap: 4px;
    box-shadow: 3px 3px 5px 0 rgba(197, 196, 208, 0.64);
  }
}

.search_item_big_buttons > button {
  width: calc(50% - 3px);
  height: 47px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  gap: 10px;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.03em;
  z-index: 2;

  @media screen and (max-width: 599px) {
    height: 32px;
    font-size: 12px;
  }

  @media screen and (max-width: 370px) {
    font-size: 11px;
    gap: 5px;
    height: 30px;
  }
}

.search_item_big_buttons > .search_item_chat_btn {
  background: $searchItemBtnBg;
  border: 1px solid $primarySearchButtonBorder;
  color: $primarySearchButton;
  border-radius: 4px;
  transition: .3s;


  svg {
    fill: $primarySearchButton;
    width: 24px;
    height: 24px;
  }

  &:hover,
  &:focus {
    box-shadow: $searchItemBtnShadowHover;
  }

  @media screen and (max-width: 599px) {
    width: 70%;
    box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.05);

    svg {
      display: none;
    }
  }
}

.search_item_big_buttons > .search_item_like_btn {
  background-color: $whiteColor;
  border: 1px solid $defaultLikeButtonColor;
  border-radius: 4px;
  color: $defaultLikeButtonColor;
  transition: .3s;

  &:hover,
  &:focus {
    box-shadow: $searchItemLikeBtnShadowHover;
  }

  @media screen and (max-width: 599px) {
    width: 30%;
  }


}

.search_item_like_btn.active {
  background: $activeLikeButtonColor;
  border-color: $activeLikeButtonColor;
  color: $whiteColor;
  cursor: default;
}

.search_item_like_btn.active:hover {
  box-shadow: none;
}

.search_item_like_btn svg {
  width: 24px;
  height: 24px;
  fill: $defaultLikeButtonColor;

  @media screen and (max-width: 599px) {
    display: none;
  }
}

.search_item_like_btn.active svg {
  fill: $whiteColor;
}

.search_item_small_buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  height: 0;
  overflow: hidden;
  transition: height .3s;
  position: relative;
  z-index: 2;

  @media screen and (max-width: 599px) {
    height: 32px;
    padding-top: 4px;
    background: $whiteColor;
    justify-content: space-around;
    border-radius: 0 0 5px 5px;
  }
}

.search_item_wrap:hover .search_item_small_buttons {
  height: 48px;

  @media screen and (max-width: 599px) {
    height: 32px;
    padding-top: 4px;
  }
}

.search_item_small_buttons.no_video {
  gap: 16px;
}

.search_item_small_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc((100% / 3) - 5px);
  height: 37px;
  border-radius: 4px;
  transition: background-color .3s;
  cursor: pointer;
  font-weight: 600;
  font-size: 12px;
  letter-spacing: 0.03em;
  color: $primarySearchButton3;

  &:hover,
  &:focus {
    background-color: $primarySearchButton3BackgroundHover;
  }

  svg {
    fill: $primarySearchButton3;
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }

  @media screen and (max-width: 599px) {
    width: 40%;
    height: 28px;
    font-size: 11px;
    font-weight: 400;

    svg {
      width: 15px;
      height: 15px;
      margin-right: 7px;
    }
  }

  @media screen and (max-width: 300px) {
    svg {
      display: none;
    }
  }
}

.search_item_small_buttons.no_video .search_item_small_btn {
  width: 43%;
}

.search_item_letter {
  @media screen and (max-width: 599px) {
    display: none;
  }
}
