@import "@sitestyles";


.popup_big_contact_request{
    width: 1040px;
    background-color: $whiteColor;
    padding: 16px 27px 19px 10px;
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    position: relative;
    text-align: left;
}

.popup_big_contact_request_wrap{
    display: grid;
    grid-template-areas: "pop_req_left pop_req_top"
    "pop_req_left pop_req_middle";
    grid-template-columns: 330px 1fr;
    grid-column-gap: 18px;
    grid-template-rows: max-content;
}

.popup_big_contact_request_left{
    grid-area: pop_req_left;
}

.popup_big_contact_request_top{
    grid-area: pop_req_top;
}

.popup_big_contact_request_middle{
    grid-area: pop_req_middle;
}

.popup_big_contact_request_bottom{
    grid-area: pop_req_bottom;
}

.popup_big_contact_request_photo{
    margin-bottom: 14px;
}

.popup_big_contact_request_photo img{
    width: 330px;
    border-radius: 8px;
}

.popup_big_contact_request_profile_info{
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    -o-flex-wrap: wrap;
    flex-wrap: wrap;
    justify-content: center;
    -ms-align-items: center;
    align-items: center;
}

.popup_big_contact_request_name{
    width: 100%;
    font-weight: 600;
    font-size: 24px;
    color: $stormGreyColor;
    text-align: center;
    margin-bottom: 8px;
}

.popup_big_contact_request_profile_info_bot{
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    justify-content: center;
    width: 100%;
}

.popup_big_contact_request_id{
    color: $defaultTextColorAlpha6;
    margin-right: 18px;
}

.popup_big_contact_request_place{
    letter-spacing: -0.03em;
    color: $defaultTextColorAlpha6;
}

.popup_big_contact_request_place img{
    height: 12px;
    border-radius: 3px;
    vertical-align: middle;
    margin-right: 5px;
    margin-bottom: 2px;
}

.popup_big_contact_request_title{
    font-weight: 600;
    font-size: 28px;
    text-align: center;
    color: $accentLeftColor;
    margin-top: 16px;
    margin-bottom: 16px;
}

.popup_big_contact_request_subtitle{
    font-weight: 500;
    font-size: 16px;
    line-height: 1.5;
    text-align: center;
    color: $defaultTextColor;
    padding-bottom: 13px;
}

.popup_big_contact_request_first_text{
    border: 1px solid $defaultTextColorAlpha3;
    border-radius: 10px;
    padding: 19px;
    padding-right: 13px;
    margin-bottom: 14px;
}

.popup_big_contact_request_first_text p{
    font-weight: 500;
    line-height: 1.5;
    color: $defaultTextColor;
    margin-bottom: 16px;
}

.popup_big_contact_request_first_subtext{
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    align-items: center;
    color: $defaultTextColorAlpha8;
    line-height: 1.5;
    font-size: 12px;
    margin-bottom: 12px;
}

.popup_big_contact_request_first_subtext img{
    width: 21px;
    flex: none;
    margin-right: 12px;
}

.popup_big_contact_request_progressbar_title{
    text-align: center;
    font-weight: 600;
    font-size: 13px;
    letter-spacing: 0.01em;
    color: $accentLeftColor;
    margin-bottom: 2px;
}

.popup_big_contact_request_progressbar_row{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.popup_big_contact_request_progressbar_text{
    flex: none;
    font-weight: 500;
    font-size: 12px;
    letter-spacing: 0.01em;
    color: $stormGreyColor;
    line-height: 1;
}

.popup_big_contact_request_progressbar_line{
    height: 9px;
    flex: 1;
    background-color: $background5Color;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    margin: 0 10px;
    position: relative;
}

.popup_big_contact_request_progressbar_line_indicator{
    position: absolute;
    top: 2px;
    left: 2px;
    background-color: $onlineColor;
    border-radius: 3px 0 0 3px;
    height: 5px;
    max-width: calc(100% - 4px);
}

.popup_big_contact_request_second_text{
    color: $defaultTextColor;
    line-height: 1.5;
    padding-bottom: 18px;
    margin-bottom: 15px;
    position: relative;
    padding-left: 20px;
    text-align: left;
}

.popup_big_contact_request_second_text p + p{
    margin-top: 10px;
}

.popup_big_contact_request_second_text:after{
    content: '';
    display: block;
    width: calc(100% - 140px);
    height: 1px;
    background-color: $defaultTextColorAlpha1;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
}

.popup_big_contact_request_third_text{
    font-size: 13px;
    line-height: 1.62;
    text-align: center;
    color: $defaultTextColorAlpha6;
    padding: 0 10px;
}

.popup_big_contact_request_bottom{
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    justify-content: space-between;
    -ms-align-items: center;
    align-items: center;
}

.popup_big_contact_request_price{
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 0.01em;
    color: $defaultTextColor;
}

.popup_big_contact_request_price span{
    font-weight: 600;
    color: $accentRightColor;
    margin-left: 18px;
}

.popup_big_contact_request_send_btn{
    width: 219px;
    height: 42px;
    line-height: 42px;
    text-align: center;
    color: $whiteColor;
    background: linear-gradient(90.66deg, $accentLeftColor 0%, $accentRightColor 100%);
    border-radius: 4px;
    font-weight: 600;
    cursor: pointer;
}

.popup_big_contact_request_send_btn.disabled{
    opacity: .60;
    cursor: default;
}

.popup_big_contact_request_send_btn:hover{
    box-shadow: $searchItemBtnShadowHover;
}

.popup_big_contact_request_send_btn img{
    vertical-align: middle;
    margin-bottom: 2px;
    margin-right: 12px;
}

.popup_big_contact_request_close{
    position: absolute;
    right: 9px;
    top: 9px;
    padding: 5px;
    cursor: pointer;
}

.popup_big_contact_request_close img{
    display: block;
    width: 21px;
}

/*************************
**************************
media styles
**************************
*************************/

@media screen and (max-width: 1249px) {
    .popup_big_contact_request{
        width: 683px;
        padding: 26px 16px 16px 10px;
    }

    .popup_big_contact_request_wrap{
        grid-template-areas:
                "pop_req_left pop_req_top"
                "pop_req_left pop_req_middle";
        grid-template-columns: 247px 1fr;
        grid-column-gap: 12px;
    }

    .popup_big_contact_request_photo{
        padding-top: 12px;
    }

    .popup_big_contact_request_photo img{
        width: 247px;
        height: 395px;
    }

    .popup_big_contact_request_title{
        font-size: 24px;
        margin-top: 0;
    }

    .popup_big_contact_request_subtitle{
        font-size: 12px;
        padding-bottom: 12px;
    }

    .popup_big_contact_request_first_text{
        padding: 16px 12px 22px 18px;
    }

    .popup_big_contact_request_first_text p{
        font-size: 12px;
    }

    .popup_big_contact_request_first_subtext{
        font-size: 12px;
    }

    .popup_big_contact_request_second_text{
        font-size: 12px;
        padding-left: 18px;
        padding-bottom: 16px;
        margin-bottom: 11px;
    }

    .popup_big_contact_request_third_text{
        font-size: 11px;
        padding: 0;
    }

    .popup_big_contact_request_bottom{
        padding-top: 28px;
    }

    .popup_big_contact_request_progressbar_text{
        font-size: 10px;
        max-width: 66px;
    }

    .popup_big_contact_request_progressbar_title{
        font-size: 12px;
    }
}

@media screen and (max-width: 720px){
    .popup_big_contact_request_close img{
        width: 17px;
    }

    .popup_big_contact_request{
        width: 100%;
        height: 100%;
        padding: 9px 0 10px 10px;
        overflow-y: visible;
        display: flex;
        flex-direction: column;
    }

    .popup_big_contact_request_wrap{
        grid-template-areas:
                "pop_req_left pop_req_top"
                "pop_req_middle pop_req_middle"
                "pop_req_bottom pop_req_bottom";
        grid-template-columns: 160px 1fr;
        grid-column-gap: 14px;
        height: 100%;
        padding-top: 30px;
        padding-right: 10px;
        overflow: auto;
    }

    .popup_big_contact_request_photo{
        padding-top: 0;
        margin-bottom: 10px;
    }

    .popup_big_contact_request_photo img{
        width: 100%;
        height: auto;
    }

    .popup_big_contact_request_title{
        font-size: 18px;
        text-align: left;
        margin-bottom: 8px;
    }

    .popup_big_contact_request_subtitle{
        text-align: left;
    }

    .popup_big_contact_request_profile_info{
        justify-content: flex-start;
    }

    .popup_big_contact_request_name{
        font-size: 14px;
        text-align: center;
    }

    .popup_big_contact_request_profile_info_bot{
        width: 200%;
    }

    .popup_big_contact_request_id{
        font-size: 12px;
        flex: none;
        white-space: nowrap;
    }

    .popup_big_contact_request_place{
        font-size: 12px;
        flex: none;
        white-space: nowrap;
    }

    .popup_big_contact_request_bottom{
        -webkit-flex-wrap: wrap;
        -moz-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        -o-flex-wrap: wrap;
        flex-wrap: wrap;
        justify-content: center;
        padding-top: 15px;
        width: 100%;
        background-color: #fff;
    }

    .popup_big_contact_request_price{
        margin-bottom: 12px;
    }

    .popup_big_contact_request_first_text{
        padding: 0;
        border: none;
    }

    .popup_big_contact_request_second_text{
        padding-left: 0;
    }

    .popup_big_contact_request_middle{
        padding: 16px 7px 13px 17px;
        border: 1px solid $defaultTextColorAlpha3;
        border-radius: 10px;
        margin-top: 16px;
    }

    .popup_big_contact_request_middle_wrap{
        overflow-y: auto;
        height: 100%;
        padding-right: 6px;
    }

    .popup_big_contact_request_third_text{
        padding-bottom: 16px;
    }

    .popup_big_contact_request_middle_wrap::-webkit-scrollbar{
        width: 2px;
        background: $bleachColor;
        border-radius: 5px;
    }

    .popup_big_contact_request_middle_wrap::-webkit-scrollbar-thumb {
        background: $accentLeftColor;
        border-radius: 5px;
    }

    .popup_big_contact_request_progressbar_title{
        font-size: 12px;
        margin-bottom: 5px;
    }

    .popup_big_contact_request_progressbar_row{
        position: relative;
        padding-bottom: 16px;
    }

    .popup_big_contact_request_progressbar_text{
        position: absolute;
        bottom: 0;
        font-size: 10px;
        max-width: unset;
    }

    .popup_big_contact_request_progressbar_text:first-child{
        left: 0;
    }

    .popup_big_contact_request_progressbar_text:last-child{
        right: 0;
    }

    .popup_big_contact_request_progressbar_line{
        margin: 0;
    }

    .popup_big_contact_request_top{
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-bottom: 10px;
    }

    .popup_big_contact_request_price{
        margin: 0;
    }

    .popup_big_contact_request_send_btn{
        margin: 12px;
        margin-bottom: 0;
    }
}

@media screen and (max-height: 719px){
    .popup_big_contact_request{
        height: 100%;
        overflow-y: auto;
    }
}

@media screen  and (max-width: 599px){
    .popup_big_contact_request_wrap{
        grid-template-columns: 121px 1fr;
    }

    .popup_big_contact_request_profile_info_bot{
        flex-wrap: wrap;
    }

    .popup_big_contact_request_id{
        margin-right: 0;
        margin-bottom: 2px;
    }
}

@media screen  and (max-width: 899px) and (max-height: 450px){
    .popup_big_contact_request{
        height: 100%;
        overflow-y: auto;
    }

    .popup_big_contact_request_middle{
        overflow: visible;
    }
}
