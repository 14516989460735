@import "@sitestyles";

.mob_bottom_nav {
  display: none;
  height: 63px;
  justify-content: center;
  align-items: center;
  border-top: 1px solid $trueGreyColor;
  background-color: $whiteColor;
  position: relative;
  z-index: 99;
}

.mob_bottom_nav_item {
  width: 50px;
  //height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 4px;
  height: 100%;
  align-items: center;
  cursor: pointer;
  position: relative;
  //margin: 0 30px;
}

.mob_bottom_nav_item_ico {
  width: 32px;
  height: 32px;
  background-repeat: no-repeat;
  background-position: center;
  margin-bottom: 6px;
  background-size: contain;
}

.mob_bottom_nav_item_ico_letters {
  background-image: url(../img/mb-letters.svg);
}

.mob_bottom_nav_item.active .mob_bottom_nav_item_ico_letters {
  background-image: url(../img/mb-letters-a.svg);
}

.mob_bottom_nav_item_ico_search {
  background-image: url(../img/mb-search.svg);
}

.mob_bottom_nav_item.active .mob_bottom_nav_item_ico_search {
  background-image: url(../img/mb-search-a.svg);
}

.mob_bottom_nav_item_ico_chat {
  background-image: url(../img/mb-chat.svg);
}

.mob_bottom_nav_item.active .mob_bottom_nav_item_ico_chat {
  background-image: url(../img/mb-chat-a.svg);
}

.mob_bottom_nav_item_ico_likeher {
  background-image: url(../img/mb-likeher.svg);
}

.mob_bottom_nav_item.active .mob_bottom_nav_item_ico_likeher {
  background-image: url(../img/mb-likeher-a.svg);
}

.mob_bottom_nav_item_ico_menu {
  background-image: url(../img/mb-menu.svg);
}

.mob_bottom_nav_item.active .mob_bottom_nav_item_ico_menu {
  background-image: url(../img/mb-menu-a.svg);
}

.mob_bottom_nav_item_count {
  background-color: $counterAndErrorColor;
  border-radius: 3px;
  padding: 0 3px;
  color: $whiteColor;
  letter-spacing: -0.02em;
  position: absolute;
  top: -2px;
  right: 0px;
  text-align: center;
  min-width: 21px;
  height: 16px;
  line-height: 16px;
  font-size: 11px;
}

// Old mob counter style
//.mob_bottom_nav_item_notify {
//  border-radius: 100%;
//  background-color: #eb5757;
//  box-shadow: 0 1px 2px #9d000029;
//  width: 10px;
//  height: 10px;
//  top: 4px;
//  right: 10px;
//  position: absolute;
//  font-size: 0;
//}

// New mob counter style

.mob_bottom_nav_item_notify {
  background: #EB5757;
  box-shadow: 0 1px 2px rgba(157, 0, 0, 0.16);
  border-radius: 4px;
  font-weight: 600;
  font-size: 10px;
  line-height: 10px;
  letter-spacing: -0.02em;
  color: #FFFFFF;
  padding: 0 4px 4px;
  min-width: 19px;
  height: 17px;
  min-height: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 26px;
  top: 2px;
}

.mob_bottom_nav_item_text {
  font-size: 12px;
  color: $defaultTextColorAlpha6;
}

.mob_bottom_nav_item.active .mob_bottom_nav_item_text {
  color: $accentLeftColor;
  background: $backgroundButtonColor;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 600;
}

.mob_head_nav {
  height: 100%;
  width: 90px;
}

.mob_head_nav_btn {
  height: 100%;
  width: 100%;
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.mob_head_nav_btn.active {
  background-color: $background1Color;
  position: relative;
}

.mob_head_nav_btn.active:after {
  content: "";
  display: block;
  width: 13px;
  height: 13px;
  position: absolute;
  right: -12px;
  bottom: -1px;
  background-image: url(../img/mh-bg-shape.svg);
  background-position: left bottom;
  background-repeat: no-repeat;
}

.mob_head_nav_btn.letters {
  display: none;
}

.mob_head_nav_btn_ico {
  width: 30px;
  height: 25px;
  background-repeat: no-repeat;
  background-position: center;
  margin-bottom: 6px;
}

.mob_head_nav_btn_ico_profile {
  background-image: url(../img/mh-profile.svg);
}

.mob_head_nav_btn.active .mob_head_nav_btn_ico_profile {
  background-image: url(../img/mh-profile-a.svg);
}

.mob_head_nav_btn_ico_filters {
  background-image: url(../img/mh-filters.svg);
}

.mob_head_nav_btn.active .mob_head_nav_btn_ico_filters {
  background-image: url(../img/mh-filters-a.svg);
}

.mob_head_nav_btn_ico_goback {
  background-image: url(../img/mh-goback.svg);
  background-size: contain;
}

.mob_head_nav_btn_text {
  font-size: 12px;
  color: $whiteColor;
}

.mob_head_nav_btn.active .mob_head_nav_btn_text {
  color: $accentRightColor;
  font-weight: 500;
}

@media screen and (max-width: 1139px) {
  .mob_bottom_nav {
    display: flex;
    padding: 0 140px;
  }

  .mob_head_nav_btn {
    display: flex;
  }
}

@media (hover: none) and (pointer: coarse) {

  @media screen and (min-width: 1140px) {
    .mob_head_nav_btn {
      display: none;
    }

    .mob_head_nav_btn.mob_head_nav_btn_search {
      display: flex;
    }
  }

  @media screen and (min-width: 1140px) {
    .mob_bottom_nav {
      display: flex;
      justify-content: center;
    }

    .mob_bottom_nav_item {
      margin: 0 30px;
    }
  }
}

@media screen and (max-width: 999px) {
  .mob_head_nav_btn.letters {
    display: flex;
  }
}

@media screen and (max-width: 899px) {
  .mob_head_nav_btn {
    display: flex;
  }

  .mob_bottom_nav {
    justify-content: space-around;
  }

  .mob_bottom_nav_item {
    margin: 0;
  }
}

@media screen and (max-width: 767px) {
  .mob_head_nav {
    width: 64px;
  }

  .mob_bottom_nav {
    padding: 0;
  }

  .mob_head_nav_btn_ico {
    width: 26px;
    height: 20px;
    margin-bottom: 4px;
  }

  .mob_head_nav_btn_text {
    font-size: 10px;
  }

  .mob_bottom_nav {
    height: 50px;
  }

  //.mob_bottom_nav_item {
  //  width: 48px;
  //  height: 40px;
  //}

  .mob_bottom_nav_item_ico {
    width: 21px;
    height: 21px;
    margin-bottom: 2px;
  }

  .mob_bottom_nav_item_text {
    font-size: 10px;
  }

  .mob_bottom_nav_item_count {
    min-width: 18px;
    right: 2px;
    font-size: 10px;
    height: 14px;
    line-height: 14px;
  }

  //.mob_bottom_nav_item_notify {
  //  width: 10px;
  //  height: 10px;
  //  top: -2px;
  //  right: 7px;
  //}
}

@media screen and (max-width: 899px) and (max-height: 500px) and (min-width: 420px) {
  .mob_bottom_nav {
    position: absolute;
    right: 0;
    top: 0px;
    width: 45px;
    height: 100%;
    flex-direction: column;
    border-top: none;
    border-left: 1px solid $trueGreyColor;
    padding: 0;
  }

  .mob_bottom_nav_item_count {
    right: unset;
    left: 0;
    top: 1px;
    min-width: 18px;
    font-size: 10px;
    height: 14px;
    line-height: 14px;
  }

  .mob_bottom_nav_item_ico {
    width: 21px;
    height: 21px;
    margin-bottom: 2px;
  }

  .mob_bottom_nav_item_text {
    font-size: 10px;
  }

  //.mob_bottom_nav_item {
  //  width: 44px;
  //  height: 48px;
  //}

  .mob_head_nav {
    width: 100%;
    height: 60px;
  }

  .mob_head_nav_btn.active:after {
    right: -1px;
    bottom: calc(100% - 1px);
    transform: scale(-1, 1);
  }

  //.mob_bottom_nav_item_notify {
  //  width: 10px;
  //  height: 10px;
  //  top: 2px;
  //  right: 6px;
  //}
}


/*column.css start*/
@media screen and (max-width: 999px) {

  /*#root{
      position: fixed;
      width: 100vw;
  }*/

  /*.mob_head{
      padding: 0;
      height: 64px;
  }*/

  /*.head_logo img:first-child{
      height: 46px;
  }

  .head_logo img:nth-child(2){
      width: 50px;
  }*/

  /*.need_confirm_email .chat_wrap_confirm_email_wrap{
      display: none;
  }*/

  /*.chat_wrap{
      height: calc(100vh - 109px);
      height: calc(calc(var(--vh, 1vh) * 100) - 109px);
      width: 100vw;
  }*/

  /*.need_confirm_email .column-3.empty{
      padding-top: 0;
  }*/

  /*.block-block-list{
      display: flex;
      height: 100%;
  }*/

  /*.block-block-list .column-2{
      display: flex;
      flex-direction: column;
  }

  .block-block-list .column-3{
      display: flex;
      flex-direction: column;
  }*/

  /*.mob_head_left{
      display: none;
  }*/

  /*.head_empty{
      width: 90px;
  }*/

  /*.column-2.c2_fx{
      display: flex;
      flex-direction: column;
  }*/

  /*.column-2.c2_fx .c2_list_wrap_2{
      flex: 1;
      overflow-x: hidden;
  }*/

  /*.c2_list_wrap{
      height: 100%;
  }*/

  /*.column-4{
      height: calc(100% - 80px);
      top: 80px;
      border-radius: 8px 0 0 0;
  }

  .overlay{
      height: calc(100vh - 109px);
      height: calc(calc(var(--vh, 1vh) * 100) - 109px);
      top: 64px;
  }*/

  /*.mob_notif_fixed.active{
      width: 90px;
      height: 80px;
      top: 0;
      border-radius: 0;
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $background1Color;
  }

  .mob_notif_fixed.active:after{
      content: "";
      display: block;
      width: 13px;
      height: 13px;
      position: absolute;
      left: -12px;
      bottom: 0;
      background-image: url(../img/mh-bg-shape.svg);
      background-position: left bottom;
      background-repeat: no-repeat;
      transform: scale(-1, 1);
  }*/
}

@media screen and (max-width: 899px) {
  /*.column-2{
      min-height: unset;
      height: calc(100% - 80px);
      top: 80px;
      border-radius: 0 8px 0 0;
  }*/
}

@media screen and (max-width: 599px) {
  /*.chat_wrap {
      height: calc(100vh - 95px);
      height: calc(calc(var(--vh, 1vh) * 100) - 95px);
  }*/

  /*.column-2{
      top: 50px;
      height: calc(100% - 95px);
  }*/

  /*.overlay {
      height: calc(100vh - 95px);
      height: calc(calc(var(--vh, 1vh) * 100) - 95px);
      top: 50px;
  }*/

  /*.mob_notif_fixed.active{
      width: 64px;
      height: 50px;
  }*/

  /*.column-4{
      width: 239px;
      height: calc(100% - 50px);
      top: 50px;
  }*/

  /*.column-1{
      top: 50px;
      height: calc(100vh - 95px);
      height: calc(calc(var(--vh, 1vh) * 100) - 95px);
  }*/

  /*.column-1.active{
      top: 50px;
      height: calc(100vh - 95px);
      height: calc(calc(var(--vh, 1vh) * 100) - 95px);
  }*/
}

@media screen and (max-width: 899px) and (max-height: 450px) {
  /*.overlay{
      top: 0;
      height: 100%;
      width: calc(100% - 95px);
      left: 50px;
  }*/

  /*.chat_wrap{
      height: 100vh;
      height: calc(var(--vh, 1vh) * 100);
      width: calc(100% - 95px);
      margin-left: 50px;
  }*/

  /*.column-2{
      top: 0;
      height: 100%;
  }*/

  /*.column-2.active{
      left: 50px;
  }*/

  /*.mob_head{
      position: absolute;
      left: 0;
      top: 0;
      width: 50px;
      height: 100%;
      padding: 0;
      display: flex;
      flex-direction: column-reverse;
  }*/

  /*.mob_head_nav_btn.active:after{
      right: -1px;
      bottom: 100%;
      transform: scale(-1, 1);
  }*/

  /*.head_logo img:nth-child(2){
      display: none;
  }

  .head_logo img:nth-child(1){
      width: 32px;
  }*/

  /*.head_empty{
      width: 100%;
      height: 60px;
  }

  .left_menu_chat_list{
      margin-left: 0;
      margin-top: 10px;
  }*/

  /*.left_menu_chat_list img{
      margin: auto;
  }

  .mob_head_left{
  }

  .mob_notif_fixed{
      width: 45px;
      height: 34px;
      left: 0;
      top: 11px;
      border-radius: 0 5px 5px 0;
  }

  .mob_notif_fixed img{
      width: 24px;
  }

  .column-4{
      right: unset;
      left: -239px;
      top: 0;
      border-radius: 0 8px 0 0;
      height: 100%;
      width: 239px;
      overflow-x: hidden;
      transition: .5s;
  }

  .column-4.active{
      left: 50px;
  }

  .mob_notif_fixed.active{
      width: 50px;
      height: 60px;
  }

  .mob_notif_fixed.active:after{
      bottom: unset;
      top: 100%;
      left: unset;
      right: -1px;
      transform: scale(-1);
  }

  .c3_chat_empty_img{
      max-width: calc(100% - 230px);
  }*/
}

/*column.css end*/

/*chat/style.module.scss start*/

/*.c4_top_mob_pause{
    display: none;
}

.c2_chat_categories{
    display: none;
}*/

@media screen and (max-width: 999px) {
  /*.c3_chat_wrap{
      height: calc(100vh - 235px);
      height: calc(calc(var(--vh, 1vh) * 100) - 235px);
  }

  .need_confirm_email .c3_chat_wrap{
      height: calc(100vh - 235px);
      height: calc(calc(var(--vh, 1vh) * 100) - 235px);
  }*/

  /*.c3_chat_scroll{
      height: unset;
      flex: 1;
  }

  .need_confirm_email .c3_chat_scroll{
      height: unset;
      flex: 1;
  }*/

  /*.c3_write_msg{
      flex: none;
  }*/

  /*.block-block-list .c2_list_wrap{
      height: 100%;
  }

  .need_confirm_email .block-block-list .c2_list_wrap{
      height: 100%;
  }

  .block-block-list .c2_list_wrap_2{
      flex: 1;
      overflow-x: hidden;
  }

  .block-block-list .c3_top{
      flex: none;
  }

  .block-block-list .c3_chat_wrap{
      flex: 1;
      height: unset;
      overflow: hidden;
  }

  .need_confirm_email .block-block-list .c3_chat_wrap{
      flex: 1;
      height: unset;
      overflow: hidden;
  }*/

  /*.c2_chat_categories{
      display: flex;
      justify-content: space-evenly;
      flex-wrap: wrap;
      padding-top: 10px;
  }

  .c2_chat_categories_btn{
      width: 80px;
      height: 30px;
      line-height: 30px;
      font-weight: 600;
      font-size: 12px;
      letter-spacing: -0.02em;
      color: $accentLeftColor;
      text-align: center;
      cursor: pointer;
      position: relative;
  }

  .c2_chat_categories_btn_count{
      height: 18px;
      line-height: 18px;
      padding: 0 3px;
      background-color: $counterAndErrorColor;
      border-radius: 4px;
      position: absolute;
      right: -2px;
      top: -5px;
      font-weight: 600;
      font-size: 12px;
      letter-spacing: -0.02em;
      color: $whiteColor;
  }

  .c2_chat_categories_btn.active{
      background-color: $whiteColor;
      box-shadow: 0px 2px 5px rgba(0, 49, 123, 0.1);
      border-radius: 5px;
  }*/

  /*.c4_top{
      height: 55px;
      line-height: 1;
      display: flex;
      justify-content: space-between;
      align-items: center;
      text-transform: none;
      padding-left: 12px;
      padding-right: 9px;
      font-size: 14px;
  }

  .c4_top_mob_pause{
      display: block;
      width: 90px;
      height: 30px;
      line-height: 30px;
      background-color: $whiteColor;
      border: 1px solid $primaryColor;
      box-shadow: 0px 2px 5px rgba(0, 49, 123, 0.1);
      border-radius: 5px;
      font-weight: 600;
      font-size: 10px;
      letter-spacing: -0.02em;
      color: $primaryColor;
      text-transform: none;
      cursor: pointer;
  }*/
}

@media screen and (max-width: 599px) {
  /*.need_confirm_email .c3_chat_wrap{
      height: calc(100vh - 180px);
      height: calc(calc(var(--vh, 1vh) * 100) - 180px);
  }*/

  /*.c3_send_btn{
      width: 48px;
      font-size: 10px;
  }*/

  /*.c3_send_btn span{
      display: block;
  }

  .c3_send_btn img{
      display: none;
  }*/
}

@media screen and (max-width: 899px) and (max-height: 500px) {
  /*.c3_chat_wrap{
      height: calc(100vh - 75px);
      height: calc(calc(var(--vh, 1vh) * 100) - 75px);
  }

  .need_confirm_email .c3_chat_wrap{
      height: calc(100vh - 75px);
      height: calc(calc(var(--vh, 1vh) * 100) - 75px);
  }*/

  /*.c3_write_msg{
      height: 58px;
      grid-template-areas: "c3_smile c3_attach c3_msg_wink c3_msg_like c3_text c3_send";
      grid-template-columns: 35px 39px 42px 42px 1fr auto;
      padding-top: 10px;
  }*/

  //.c3_chat_send_btn_cost_text {
  //    bottom: calc(100% + 3px);
  //    right: 0;
  //    width: max-content;
  //    text-align: right;
  //    font-size: 9px;
  //    top: unset;
  //}

  /*.c3_write_msg .c3_textaera_wrap{
      margin-bottom: 0;
      align-self: center;
      margin-right: 16px;
  }*/

  /*.c3_send{
      align-self: center;
      margin-top: 0;
      margin-left: 9px;
  }*/
}

/*chat/style.module.scss end*/

/*letters/media.css start*/

@media screen and (max-width: 999px) {
  /*.c3l_men_list_wrap2{
      height: calc(100vh - 109px);
      height: calc(calc(var(--vh, 1vh) * 100) - 109px);
      padding-right: 5px;
  }

  .need_confirm_email .c3l_men_list_wrap2{
      height: calc(100vh - 109px);
      height: calc(calc(var(--vh, 1vh) * 100) - 109px);
  }*/

  /*.c3l_men_list_wrap2::-webkit-scrollbar{
      width: 3px;
  }*/

  /*.c2l_letter_filters_wrap2{
      height: calc(100vh - 500px);
      height: calc(calc(var(--vh, 1vh) * 100) - 500px);
  }*/

  /*.c3l_letters_list_wrap2{
      height: calc(100vh - 235px);
      height: calc(calc(var(--vh, 1vh) * 100) - 235px);
      padding-bottom: 16px;
  }

  .need_confirm_email .c3l_letters_list_wrap2{
      height: calc(100vh - 235px);
      height: calc(calc(var(--vh, 1vh) * 100) - 235px);
  }*/

  /*.c3l_letters_list_box_wrap{
      height: calc(100% - 274px);
      padding-bottom: 0;
  }

  .c3l_letters_list_write_wrap{
      height: 250px;
  }*/
}

@media screen and (max-width: 599px) {
  /*.c3l_men_list_wrap2{
      height: calc(100vh - 95px);
      height: calc(calc(var(--vh, 1vh) * 100) - 95px);
      padding-bottom: 16px;
  }

  .need_confirm_email .c3l_men_list_wrap2{
      height: calc(100vh - 95px);
      height: calc(calc(var(--vh, 1vh) * 100) - 95px);
  }*/

  /*.c3l_men_list_item:last-child{
      margin-bottom: 0;
  }*/

  /*.c3l_letters_list_wrap2{
      height: calc(100vh - 170px);
      height: calc(calc(var(--vh, 1vh) * 100) - 170px);
  }

  .need_confirm_email .c3l_letters_list_wrap2{
      height: calc(100vh - 170px);
      height: calc(calc(var(--vh, 1vh) * 100) - 170px);
  }*/

  /*.c3l_letters_list_box_wrap.close_letter_form{
      height: calc(100% - 60px);
      margin-bottom: 16px;
      padding-bottom: 0;
  }*/

  /*.c3l_letters_list_box_wrap{
      height: calc(100% - 193px);
  }*/

  /*.c2l_letter_filters_wrap2{
      height: calc(100vh - 425px);
      height: calc(calc(var(--vh, 1vh) * 100) - 425px);
  }*/
}

@media screen and (max-width: 899px) and (max-height: 450px) {
  /*.c3l_men_list_wrap2{
      height: 100vh;
      height: calc(var(--vh, 1vh) * 100);
  }

  .need_confirm_email .c3l_men_list_wrap2{
      height: 100vh;
      height: calc(var(--vh, 1vh) * 100);
  }*/

  /*.c3l_letters_list_wrap2 {
      height: calc(100vh - 75px);
      height: calc(calc(var(--vh, 1vh) * 100) - 75px);
      padding-bottom: 0;
  }

  .need_confirm_email .c3l_letters_list_wrap2 {
      height: calc(100vh - 75px);
      height: calc(calc(var(--vh, 1vh) * 100) - 75px);
  }*/

  /*.c3l_letters_list_box_wrap {
      height: calc(100%);
      padding-bottom: 0;
  }*/

}

/*letters/media.css end*/

/*letters.css start*/

@media screen and (max-width: 899px) and (max-height: 450px) {
  /*.c3l_men_list_item_main .carousel_container {
      width: calc(100vw - 195px);
  }*/

  /*.c3l_letters_list_item .carousel_container {
      width: calc(60vw - 95px);
  }*/

  /*.c3l_letters_list_write_wrap{
      height: 100%;
  }*/
}

/*letters.css end*/

/*likeher.css start*/

@media screen and (max-width: 999px) {
  /*.c3lh_your_likes_list_wrap2{
      height: calc(100vh - 179px);
      height: calc(calc(var(--vh, 1vh) * 100) - 179px);
  }

  .need_confirm_email .c3lh_your_likes_list_wrap2{
      height: calc(100vh - 179px);
      height: calc(calc(var(--vh, 1vh) * 100) - 179px);
  }*/
}

@media screen and (max-width: 700px) {
  /*.c3lh_your_likes_list_wrap2{
      height: calc(100vh - 209px);
      height: calc(calc(var(--vh, 1vh) * 100) - 209px);
  }

  .need_confirm_email .c3lh_your_likes_list_wrap2{
      height: calc(100vh - 209px);
      height: calc(calc(var(--vh, 1vh) * 100) - 209px);
  }*/
}

@media screen and (max-width: 599px) {
  /*.c3lh_your_likes_list_wrap2 {
      height: calc(100vh - 130px);
      height: calc(calc(var(--vh, 1vh) * 100) - 130px);
  }

  .need_confirm_email .c3lh_your_likes_list_wrap2 {
      height: calc(100vh - 130px);
      height: calc(calc(var(--vh, 1vh) * 100) - 130px);
  }*/

  /*.c3lh_your_likes_list_wrap{
      margin-top: 0;
  }*/
}

@media screen and (max-width: 899px) and (max-height: 450px) {
  /*.need_confirm_email .c3lh_your_likes_list_wrap2{
      height: 100vh;
      height: calc(var(--vh, 1vh) * 100);
  }

  .c3lh_search_top{
      display: flex;
      flex-direction: column;
      justify-content: center;
  }*/

  /*.c3lh_search_filters_btn_wrap{
      margin: 0 auto;
  }*/

  /*.c3lh_your_likes_list_wrap {
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 10px;
      padding: 16px 0;
  }*/

  /*.c3lh_your_likes_item{
      height: auto;
      padding-bottom: 65px;
  }

  .c3lh_your_likes_item_back{
      width: 100%;
      height: 100%;
      bottom: unset;
      top: 0;
      justify-content: flex-end;
      -webkit-border-radius: 6px;
      -moz-border-radius: 6px;
      border-radius: 6px;
  }

  .c3lh_your_likes_item_main{
      width: 100%;
      padding-top: 168%;
      height: auto;
  }

  .c3lh_your_likes_item_big_photo{
      height: calc(100% - 3px);
      width: calc(100% - 2px);
      position: absolute;
      left: 1px;
      top: 1px;
  }

  .c3lh_your_likes_item_back_bottom{
      flex-wrap: wrap;
      height: 65px;
      padding: 4px;
  }

  .c3lh_your_likes_item_back_b_item.item_picture{
      display: none;
  }

  .c3lh_your_likes_item_back_b_item{
      width: 26px;
      height: 26px;
      -webkit-background-size: 16px auto;
      background-size: 16px auto;
  }

  .c3lh_your_likes_item_back_b_left{
      width: 50%;
      justify-content: space-around;
  }

  .c3lh_your_likes_item_back_top{
      width: calc(50% - 4px);
      position: absolute;
      bottom: 35px;
      right: 4px;
      display: flex;
      justify-content: space-around;
  }

  .c3lh_your_likes_item_back_top_item_wrap:nth-child(2){
      display: none;
  }

  .c3lh_your_likes_item_back_top_item{
      padding: 0;
      position: static;
  }

  .c3lh_your_likes_item_back_top_item_wrap{
      width: 26px;
      height: 26px;
      display: flex;
      justify-content: center;
      align-items: center;
  }

  .c3lh_your_likes_item_back_top_item_wrap:first-child{
      background-image: url(../img/c3lh-item-mail-mob.svg);
      background-size: 16px auto;
      background-position: center;
      background-repeat: no-repeat;
  }

  .c3lh_your_likes_item_back_top_item_wrap:last-child{
      background-image: url(../img/c3lh-item-like-mob.svg);
      background-size: 16px auto;
      background-position: center;
      background-repeat: no-repeat;
  }
  .c3lh_your_likes_item_back_top_item_wrap.liked:last-child{
      background-image: url(../img/c3lh-item-like-mob-liked.svg);
  }

  .c3lh_your_likes_item_back_top_item img{
      width: 16px;
      display: none;
  }

  .c3lh_your_likes_item_back_chat_btn{
      width: 100%;
  }

  .c3lh_your_likes_item_profile{
      background: linear-gradient(to top, $whiteColor 0%, $transparentColor 100%);
      padding-bottom: 6px;
  }

  .c3lh_your_likes_item_profile_name{
      font-size: 12px;
  }

  .c3lh_your_likes_item_profile_online{
      font-size: 10px;
  }*/
}

@media screen and (max-width: 699px) and (max-height: 450px) and (min-width: 420px) {
  .c3lh_search_filters_btn_wrap {
    width: 100%;
  }

  .c3lh_search_filters_btn_wrap img {
    width: 16px;
  }

  .c3lh_search_filters_btn_wrap {
    font-size: 12px;
  }

  .c3lh_search_filters_btn_wrap b {
    right: 5px;
  }
}

/*likeher.css end*/

/*likeher-new.css start*/

@media screen and (max-width: 999px) {
  /*.likeher_page_wrap {
      height: calc(100vh - 125px);
      height: calc(calc(var(--vh, 1vh) * 100) - 125px);
  }

  .need_confirm_email .likeher_page_wrap {
      height: calc(100vh - 125px);
      height: calc(calc(var(--vh, 1vh) * 100) - 125px);
  }*/

  /*.likeher_top {
      max-height: calc(100vh - 300px);
      max-height: calc(calc(var(--vh, 1vh) * 100) - 300px);
  }

  .need_confirm_email .likeher_top {
      max-height: calc(100vh - 300px);
      max-height: calc(calc(var(--vh, 1vh) * 100) - 300px);
  }*/
}

@media screen and (max-width: 599px) {
  /*.need_confirm_email .likeher_page_wrap{
      height: calc(100vh - 95px);
      height: calc(calc(var(--vh, 1vh) * 100) - 95px);
  }*/

  /*.likeher_small_photo{
      z-index: 5;
  }*/

  /*.likeher_small_photo > img{
      display: none;
  }

  .need_confirm_email .likeher_top{
      max-height: unset;
  }*/

  /*.likeher_top{
      max-height: unset;
      width: 100%;
      padding: 0;
      position: absolute;
      left: 0;
      top: 0;
      height: calc(calc(var(--vh, 1vh) * 100) - 95px);
      z-index: 2;
      padding: 5px;
      padding-bottom: 0;
  }

  .likeher_top:after{
      content: "";
      display: block;
      width: calc(100% - 10px);
      height: 175px;
      background: linear-gradient(to top, rgba(0, 49, 123, 0.6) 0%, $transparentColor 100%);
      position: absolute;
      left: 5px;
      bottom: 0;
      z-index: 3;
  }*/

  /*.likeher_photos{
      max-height: unset;
      height: 100%;
  }*/

  /*.need_confirm_email .likeher_big_photo img{
      max-height: unset;
  }

  .likeher_big_photo img{
      max-height: unset;
      width: 100%;
      height: 100%;
      border-radius: 8px 8px 0 0;
  }*/

  /*.likeher_big_photo{
      width: 100%;
  }

  /*.likeher_controls{
      position: absolute;
      bottom: 70px;
      left: 0;
      width: 100%;
      z-index: 5;
  }*/

  /*.likeher_bottom{
      position: absolute;
      bottom: 60px;
      left: 0;
      width: 100%;
      justify-content: flex-end;
      z-index: 8;
  }*/

  /*.c3lh_girls_list_bottom_controls{
      position: absolute;
      bottom: 11px;
      left: 0;
      width: 100%;
      padding-bottom: 0;
      padding: 0 12px;
      justify-content: space-between;
      z-index: 5;
  }*/

  /*.likeher_controls_btn{
      margin: 0;
      width: 141px;
      height: 42px;
      font-size: 14px;
  }

  .likeher_controls_btn img{
      width: 21px;
  }*/

  /*.likeher_bottom_btn{
      display: none;
  }*/

  /*.likeher_bottom_btn.lbb_chat{
      display: flex;
      width: 87px;
      height: 32px;
      border: none;
      background: linear-gradient(90.34deg, $accentLeftColor 0%, $accentRightColor 100%);
      border-radius: 5px;
      position: relative;
      justify-content: flex-start;
      padding-left: 38px;
  }

  .likeher_bottom_btn.lbb_chat:before{
      content: "";
      display: block;
      width: 19px;
      height: 18px;
      position: absolute;
      left: 13px;
      top: 50%;
      transform: translateY(-50%);
      background-image: url(../img/chat_header.svg);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
  }

  .likeher_bottom_btn span{
      display: block;
      color: $whiteColor;
      width: 36px;
      overflow: hidden;
      white-space: nowrap;
      font-size: 14px;
  }

  .likeher_bottom_btn.lbb_chat svg{
      display: none;
  }*/

  /*.c3lh_girls_list_controls_girl_info{
      background-color: transparent;
      box-shadow: none;
      grid-template-areas:
              "c3lh_name"
              "c3lh_status";
      grid-template-columns: 1fr;
      width: 100%;
      height: auto;
  }*/

  /*.c3lh_girls_list_controls_girl_info_id{
      display: none;
  }*/

  /*.c3lh_girls_list_controls_girl_info_place{
      display: none;
  }*/

  /*.c3lh_girls_list_controls_girl_info_online_status{
      text-align: left;
      color: $onlineColor;
      text-shadow: 0px 0px 4px rgba(255, 255, 255, 0.25);
      margin-bottom: 0;
  }

  .c3lh_girls_list_controls_girl_info_online_status:before{
      display: inline-block;
      width: 8px;
      height: 8px;
      margin-right: 3px;
  }*/

  /*.c3lh_girls_list_controls_girl_info_name{
      color: $whiteColor;
      font-size: 18px;
      text-shadow: 0px 1px 5px rgba(0, 29, 73, 0.33);
      margin-bottom: 0;
  }*/

}

/*likeher-new.css end*/

/*mp-video.css start*/

@media screen and (max-width: 999px) {
  /*.c3mp_videos_list_wrap{
      height: calc(100vh - 179px);
      height: calc(calc(var(--vh, 1vh) * 100) - 179px);
  }

  .need_confirm_email .c3mp_videos_list_wrap{
      height: calc(100vh - 179px);
      height: calc(calc(var(--vh, 1vh) * 100) - 179px);
  }*/
}

@media screen and (max-width: 700px) {
  /*.c3mp_videos_list_wrap{
      height: calc(100vh - 209px);
      height: calc(calc(var(--vh, 1vh) * 100) - 209px);
      padding-bottom: 16px;
  }

  .need_confirm_email .c3mp_videos_list_wrap{
      height: calc(100vh - 209px);
      height: calc(calc(var(--vh, 1vh) * 100) - 209px);
  }*/
}

@media screen and (max-width: 599px) {
  /*.c3mp_videos_list_wrap{
      height: calc(100vh - 130px);
      height: calc(calc(var(--vh, 1vh) * 100) - 130px);
  }

  .need_confirm_email .c3mp_videos_list_wrap{
      height: calc(100vh - 130px);
      height: calc(calc(var(--vh, 1vh) * 100) - 130px);
  }*/
}

@media screen and (max-width: 899px) and (max-height: 450px) {
  /*.c3mp_videos_list_wrap{
      height: 100vh;
      height: calc(var(--vh, 1vh) * 100);
      padding: 16px 12px;
  }

  .need_confirm_email .c3mp_videos_list_wrap{
      height: 100vh;
      height: calc(var(--vh, 1vh) * 100);
  }

  .c3mp_video_list{
      grid-template-columns: 340px;
  }*/
}

@media screen and (max-width: 599px) and (max-height: 450px) and (min-width: 420px) {
  /*.c3mp_video_list{
      grid-template-columns: 1fr;
  }*/
}

/*mp-video.css end*/

/*man-profile/media.css.css start*/

@media screen and (max-width: 999px) {
  /*.profile_man main .container{
      height: calc(100vh - 125px);
      height: calc(calc(var(--vh, 1vh) * 100) - 125px);
      padding-bottom: 16px;
      padding-top: 16px;
  }

  .need_confirm_email .profile_man main .container{
      height: calc(100vh - 125px);
      height: calc(calc(var(--vh, 1vh) * 100) - 125px);
  }*/

  /*.c3_header{
      display: none;
  }*/
}

@media screen and (max-width: 899px) {
  /*.profile_man main{
      padding-top: 0;
  }*/
}

@media screen and (max-width: 599px) {
  /*.profile_man main .container{
      height: calc(100vh - 95px);
      height: calc(calc(var(--vh, 1vh) * 100) - 95px);
      padding-bottom: 0;
  }

  .need_confirm_email .profile_man main .container{
      height: calc(100vh - 95px);
      height: calc(calc(var(--vh, 1vh) * 100) - 95px);
  }*/
}

/*man-profile/media.css.css end*/


/*profile.css start*/

@media screen and (max-width: 999px) {
  /*.c3_profile_edit_wrap2{
      height: calc(100vh - 125px);
      height: calc(calc(var(--vh, 1vh) * 100) - 125px);
      padding-top: 16px;
  }

  .need_confirm_email .c3_profile_edit_wrap2{
      height: calc(100vh - 125px);
      height: calc(calc(var(--vh, 1vh) * 100) - 125px);
  }

  .c3_profile_gifts_wrap2{
      height: calc(100vh - 153px);
      height: calc(calc(var(--vh, 1vh) * 100) - 153px);
      padding-top: 16px;
  }*/
}

@media screen and (max-width: 899px) {
  /*.column-3 main{
      padding-top: 0;
  }

  .c3_profile_gifts_wrap2{
      height: calc(100vh - 125px);
      height: calc(calc(var(--vh, 1vh) * 100) - 125px);
      padding-top: 16px;
  }*/
}

@media screen and (max-width: 599px) {
  /*.c3_profile_edit_wrap2{
      height: calc(100vh - 95px);
      height: calc(calc(var(--vh, 1vh) * 100) - 95px);
  }

  .need_confirm_email .c3_profile_edit_wrap2{
      height: calc(100vh - 95px);
      height: calc(calc(var(--vh, 1vh) * 100) - 95px);
  }*/
}


/*profile.css end*/
