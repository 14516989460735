@import "@sitestyles";

.popup_c3l_letters_list_write_area{
  height: 100%;
  display: flex;
  flex-direction: column;
}

.popup_c3l_letters_list_write_top{
  flex: 1;
}

.popup_c3_write_msg_textareas{
  height: 100%;
  display: flex;
  flex-direction: column;
}

.popup_c3_write_msg_textareas textarea{
  display: block;
  width: 100%;
  height: 100%;
  background-color: #fff;
  border: none;
  resize: none;
  outline: none;
  padding: 10px 17px;
  font-size: 16px;
  color: #5c5c5c;
  border-radius: 8px;
}

.popup_c3_write_msg_textareas textarea::-webkit-scrollbar {
  width: 3px;
  background: #fff;
  border-radius: 3px;
}

.popup_c3_write_msg_textareas textarea::-webkit-scrollbar-thumb {
  background: $scrollbarThumbColor;
  border-radius: 3px;
}

.popup_write_letter .c3_write_msg_textareas_files{
  margin-left: 0;
  margin-right: 0;
}

@media screen and (max-width: 599px){
  .popup_write_letter .c3_write_msg_textareas_files{
    margin-left: 10px;
    margin-right: 10px;
  }

  .popup_c3l_letters_list_write_area .c3l_letters_list_write_attach_btn img{
    width: 22px;
  }

  .popup_c3l_letters_list_write_area .c3l_letters_list_write_smile img{
    width: 20px;
  }

  .popup_c3_write_msg_textareas textarea{
    padding: 10px;
    font-size: 14px;
    background-color: #fff;
  }

  .popup_write_letter_write_area .mob-smiles{
    width: 100% !important;
    margin-top: 25px;
    margin-left: 0 !important;
  }
}