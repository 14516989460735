@import "@sitestyles";

.remarket_banner {
  background: #FFFFFF;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: center;
  padding: 8px 24px 8px 8px;
  height: 80px;
  min-height: 80px;
  box-sizing: border-box;
  width: 100%;
  justify-content: space-between;
  z-index: 0;
  overflow: hidden;
  position: relative;
  cursor: pointer;

  @media screen and (max-width: 1500px) {
    padding: 8px 16px 8px 8px;
  }

  @media screen and (max-width: 1200px) and (min-width: 1140px) {
    padding: 8px 80px 8px 8px;
  }

  @media screen and (max-width: 960px) {
    padding: 4px 12px 4px 4px;
  }

  @media screen and (max-width: 699px) {
    height: 50px;
    min-height: 50px;
  }

  &:after {
    display: block;
    content: '';
    width: 400px;
    height: 100%;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    background: linear-gradient(270deg, #FFFFFF 0%, #FFFFFF 57.29%, rgba(244, 244, 244, 0.72) 76.56%, rgba(244, 244, 244, 0.00) 100%, rgba(244, 244, 244, 0.00) 100%);

    @media screen and (max-width: 1600px) {
      width: 360px;
    }

    @media screen and (max-width: 1200px) and (min-width: 1140px) {
      width: 420px;
    }

    @media screen and (max-width: 960px) {
      width: 280px;
    }

    @media screen and (max-width: 699px) {
      width: 220px;
      background: linear-gradient(270deg, #FFFFFF 0%, #FFFFFF 57.29%, rgba(244, 244, 244, 0.72) 76.56%, rgba(244, 244, 244, 0.00) 100%, rgba(244, 244, 244, 0.00) 100%);
    }
  }
}


.remarket_banner_image {
  display: block;
  align-items: center;
  width: 100%;
  flex: 1;
  height: 100%;
  background: url('../../../img/banners/banner_v2_europe.png') left no-repeat;
  background-size: cover;
  position: relative;

  &.latina {
    background: url('../../../img/banners/banner_v2_latina.png') left no-repeat;
    background-size: cover;
  }

  &.asia {
    background: url('../../../img/banners/banner_v2_asia.png') left no-repeat;
    background-size: cover;
  }
}

.remarket_banner_btn {
  background: linear-gradient(135deg, #00317B 0%, #720066 100%), linear-gradient(90deg, #DA5555 0%, #AC384D 100%);
  box-shadow: 0 0 42px rgba(255, 255, 255, 0.25);
  border-radius: 10px;
  height: 48px;
  font-family: $mainFont;
  display: flex;
  align-items: center;
  cursor: pointer;
  box-sizing: border-box;
  color: #FFFFFF;
  letter-spacing: -0.48px;
  text-transform: capitalize;
  margin-left: auto;
  padding: 0 24px;
  font-size: 16px;
  font-weight: 400;
  z-index: 2;
  position: relative;
  min-width: 180px;
  justify-content: center;


  @media screen and (max-width: 1600px) {
    height: 42px;
    padding: 0 16px;
    font-size: 14px;
  }

  @media screen and (max-width: 699px) {
    height: 32px;
    font-size: 10px;
    letter-spacing: -0.3px;
    border-radius: 5px;
    min-width: 120px;
  }


  &.latina {
    background: linear-gradient(90deg, #DA5555 0%, #AC384D 100%);
  }

  &.asia {
    background: linear-gradient(133deg, #8BB0E9 0%, #509ED7 100%), #FFF;
  }

  &.europe {
    background: linear-gradient(135deg, #00317B 0%, #720066 100%), linear-gradient(90deg, #DA5555 0%, #AC384D 100%);
  }

  &:hover {
    filter: drop-shadow(2px 4px 6px rgba(0, 0, 0, 0.15));
  }
}

.remarket_banner_btn_text {
  font-weight: 700;
  padding: 0 4px;

  @media screen and (max-width: 960px) {
    padding: 0 2px;
  }
}

// START Smaller banner =========================

//.remarket_banner.smaller {
//}

// END Smaller banner =========================