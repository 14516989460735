@import "@sitestyles";

/*HISTORY-PURCHASE*/

.all-content-info::-webkit-scrollbar{
	width: 3px;
	background: $littleGreyLittleBlueColor;
	border-radius: 5px;
	margin-right: 3px;
}
.all-content-info::-webkit-scrollbar-thumb {
    background: $scrollbarThumbColor;
    border-radius: 7px;
  }
.purchase-history{
    position: relative;
    max-width: 100%;
    background: $bleachColor;
    display: none;
	margin-top: 24px;
	/*padding-top: 41px;
*/
	padding-right: 10px;
}

.all-content-info{
	padding: 0px 13px 0px 24px;
	height: calc(100% - 161px);
	overflow-y: auto;
	position: relative;
}

.purchase-history .fixed-head{
    left: 0;
    top: 0;
    background: $background1Color;
    border-radius: 8px;
    width: 100%;
	position: sticky;
	width: calc(100%);
}
.purchase-history .fixed-head div p{
    font-weight: 600;
}
.purchase-history .row div{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding:  17px 30px 16px 10px;
}
.purchase-history .fixed-head div{
    padding: 21px 30px 21px 0px;
}
.purchase-history .all-tabel{
    background: $bleachColor;
}
.purchase-history .all-tabel h3{
    padding:  15px 0 15px 21px;
    text-align: left;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    color: $primaryColor;
}
.purchase-history .all-tabel .white{
    background: $whiteColor;
}
.purchase-history .all-tabel .blue{
    background: $littleGreyLittleBlueColor;
}
.purchase-history .all-tabel .firs-mon {
    border-radius: 8px 8px 0px 0px;
}
.purchase-history .all-tabel .row:last-child {
    border-radius: 0px 0px 8px 8px;
}
.purchase-history .row p{
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    text-align: center;
    color: $stormGreyColor;
    width: 100%;
}
.purchase-history .row .date{
    max-width: 120px;
    padding-left: 53px;
}
.purchase-history .row .credit{
    max-width: 175px;
    padding-right: 37px;
    color: $accentRightColor;
    font-weight: 600;
}
.purchase-history .fixed-head div .credit{
    color: $stormGreyColor;
;
}
.purchase-history .row .card{
    max-width: 300px;
    padding-right: 58px;
}
.purchase-history .row .price{
    max-width: 227px;
    padding-right: 107px;
    color: $accentRightColor;
    font-weight: 600;
}
.purchase-history .fixed-head .price{
    color: $stormGreyColor;
}


.c3_profile_credits_wrap{
    padding: 30px 0 0;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.c3_profile_credits_balance{
    background-color: $whiteColor;
    box-shadow: 0 6px 12px rgba(47, 43, 99, 0.16);
    border-radius: 10px;
    display: flex;
    align-items: center;
    padding: 22px 40px;
    order: 1;
    width: 100%;
}

.c3_profile_credits_your_cards + .c3_profile_credits_your_cards + .c3_profile_credits_balance{
    width: 100%;
    margin-bottom: 16px;
    min-height: 175px;
}

.c3_profile_credits_your_cards + .c3_profile_credits_balance{
    width: calc(50% - 8px);
}

.c3_profile_credits_balance_img {
    width: 57px;
    height: 55px;
    margin-right: 35px;
    background: $iconCoinsBalanceDefaultColor;
    mask: url('../../img/coins.svg') no-repeat center;
    mask-size: contain;
}

.c3_profile_credits_balance_middle_text{
    font-weight: 600;
    font-size: 18px;
    line-height: 140%;
    color: $accentLeftRightColor;
    margin-bottom: 3px;
}

.c3_profile_credits_balance_middle_row{
    display: flex;
    align-items: flex-end;
}

.c3_profile_credits_balance_middle_value{
    font-weight: 600;
    font-size: 36px;
    line-height: 1;
    letter-spacing: 0.06em;
    color: $accentRightColor;
    margin-right: 14px;
}

.c3_profile_credits_balance_middle_value_text{
    font-weight: 500;
    font-size: 20px;
    line-height: 140%;
    color: $stormGreyColor;
}

.c3_profile_credits_balance_get_creds_btn{
    width: 246px;
    height: 54px;
    background: $backgroundButtonColor;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: 18px;
    color: $whiteColor;
    margin-left: auto;
    cursor: pointer;
}

.c3_profile_credits_balance_get_creds_btn svg {
    margin-right: 12px;
}




.c3_profile_credits_your_cards{
    background-color: $whiteColor;
    box-shadow: 0px 6px 12px rgba(47, 43, 99, 0.16);
    border-radius: 10px;
    padding: 22px 22px;
    width: calc(50% - 8px);
    min-height: 175px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    order: 2;
}

.c3_profile_credits_your_cards_title_row{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.c3_profile_credits_your_cards_title{
    font-weight: 600;
    font-size: 22px;
    letter-spacing: 0.06em;
    color: $accentRightColor;
}

.c3_profile_credits_your_cards_add_new_btn{
    width: 163px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid $primaryOrangeColor;
    border-radius: 4px;
    font-weight: 600;
    font-size: 12px;
    letter-spacing: 0.01em;
    color: $primaryOrangeColor;
    cursor: pointer;
}

.c3_profile_credits_your_cards_add_new_btn img{
    width: 15px;
    margin-right: 9px;
}

.c3_profile_credits_your_cards_add_new_btn svg{
    width: 15px;
    height: 15px;
    margin-right: 9px;
    fill: $primaryOrangeColor;
}

.c3_profile_credits_your_cards_item{
    height: 54px;
    display: flex;
    align-items: center;
    border: 1px solid $defaultTextColorAlpha3;
    border-radius: 10px;
    padding: 0 13px 0 19px;
    margin: 13px 0;
}

.c3_profile_credits_your_cards_item_img{
    height: 20px;
    margin-right: 17px;
}

.c3_profile_credits_your_cards_item_card_num{
    font-weight: 500;
    letter-spacing: 0.03em;
    color: $defaultTextColor;
}

.c3_profile_credits_your_cards_item_current_card{
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 12px;
    letter-spacing: 0.01em;
    color: $onlineColor;
    margin-left: auto;
}

.c3_profile_credits_your_cards_item_current_card img{
    margin-right: 10px;
}

.c3_profile_credits_your_cards_item_current_card svg {
    margin-right: 10px;
    width: 22px;
    height: 22px;
    fill: #25b061;
}

.c3_profile_credits_your_cards_manage_link{
    text-align: center;
    font-weight: 600;
    font-size: 12px;
    letter-spacing: 0.01em;
    text-decoration-line: underline;
    color: $accentLeftColor;
}

.c3_profile_credits_your_cards_title_row + .c3_profile_credits_your_cards_manage_link{
    margin-top: 20px;
}

.c3_profile_credits_your_cards_manage_link span{
    cursor: pointer;
}

.c3_profile_credits_your_cards_empty_row{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 18px;
}

.c3_profile_credits_your_cards_empty_card{
    display: flex;
    align-items: center;
    font-weight: 500;
    letter-spacing: 0.03em;
    color: $defaultTextColor;
}

.c3_profile_credits_your_cards_empty_card img{
    height: 25px;
    margin-right: 12px;
}

.c3_purchase_history_text{
    font-weight: 600;
    font-size: 24px;
    color: $accentLeftColor;
    padding: 0 24px;
    margin: 60px 0 28px;
}

@media screen and (max-width: 1749px){
    .c3_profile_credits_balance{
        padding: 22px 30px;
    }
    .c3_profile_credits_balance_img{
        width: 50px;
        height: 50px;
        margin-right: 16px;
    }

    .c3_profile_credits_balance_middle_value{
        font-size: 32px;
        margin-right: 10px;
    }

    .c3_profile_credits_balance_middle_value_text{
        font-size: 16px;
    }

    .c3_profile_credits_balance_middle_text{
        font-size: 16px;
    }

    .c3_profile_credits_your_cards + .c3_profile_credits_your_cards + .c3_profile_credits_balance .c3_profile_credits_balance_get_creds_btn{
         width: 246px;
         height: 54px;
         font-size: 16px;
     }

    .c3_profile_credits_your_cards + .c3_profile_credits_your_cards + .c3_profile_credits_balance .c3_profile_credits_balance_get_creds_btn svg {
        width: 24px;
        height: 24px;
        fill: #FFFFFF;
    }

    .c3_profile_credits_your_cards + .c3_profile_credits_balance .c3_profile_credits_balance_get_creds_btn{
        width: 210px;
        height: 42px;
        font-size: 16px;
    }

    .c3_profile_credits_your_cards + .c3_profile_credits_balance .c3_profile_credits_balance_get_creds_btn svg {
        width: 24px;
        height: 24px;
    }
}

@media screen and (max-width: 1560px){
    .purchase-history .row .credit{
        padding-right: 0;
    }
    .purchase-history .row .date{
        padding-right: 0;
    }
    .purchase-history .row .card{
        padding-right: 0;
        max-width: 200px;
    }
    .purchase-history .row .price{
        padding-right: 0;
        max-width: 150px;
    }
}

@media screen and (max-width: 1449px){

    .c3_profile_credits_your_cards + .c3_profile_credits_balance .c3_profile_credits_balance_get_creds_btn{
        width: 160px;
        height: 38px;
        font-size: 14px;
    }

    .c3_profile_credits_your_cards + .c3_profile_credits_balance .c3_profile_credits_balance_get_creds_btn svg{
        margin-right: 8px;
    }
}

@media screen and (max-width: 1399px){
    .c3_profile_credits_balance{
        padding: 22px 22px;
        min-height: unset;
    }

    .c3_profile_credits_your_cards + .c3_profile_credits_your_cards + .c3_profile_credits_balance{
        min-height: 120px;
    }

    .c3_profile_credits_balance_img{
        width: 40px;
        height: 38px;
    }

    .c3_profile_credits_balance_middle_text{
        font-size: 14px;
    }

    .c3_profile_credits_balance_middle_value{
        font-size: 30px;
        margin-right: 6px;
    }

    .c3_profile_credits_your_cards_title{
        font-size: 18px;
    }

    .c3_profile_credits_your_cards_empty_card{
        font-size: 12px;
    }

    .c3_profile_credits_your_cards_empty_card img{
        margin-right: 8px;
    }

    .c3_profile_credits_your_cards_add_new_btn{
        width: 136px;
        height: 30px;
        flex: none;
        margin-left: 10px;
    }

    .c3_profile_credits_balance_get_creds_btn{
        width: 210px;
        height: 42px;
        font-size: 14px;
    }

    .c3_profile_credits_balance_get_creds_btn svg{
        width: 24px;
        height: 24px;
    }

    .c3_profile_credits_your_cards + .c3_profile_credits_your_cards + .c3_profile_credits_balance .c3_profile_credits_balance_get_creds_btn{
        width: 210px;
        height: 42px;
        font-size: 14px;
    }

    .c3_profile_credits_your_cards + .c3_profile_credits_your_cards + .c3_profile_credits_balance .c3_profile_credits_balance_get_creds_btn svg {
        width: 24px;
        height: 24px;
    }
}

@media screen and (max-width: 1280px){
    .purchase-history .row div{
        padding-right: 15px;
    }
    .purchase-history .row p{
        font-size: 16px;
    }
    .purchase-history .row .date{
        max-width: 100px;
    }
    .purchase-history .row .credit{
        max-width: 170px;
    }

    .c3_profile_credits_your_cards_item_img{
        margin-right: 8px;
    }

    .c3_profile_credits_your_cards_item_card_num{
        font-size: 12px;
    }

    .c3_profile_credits_your_cards_item_current_card{
        font-size: 10px;
    }

    .c3_profile_credits_your_cards_item_current_card img {
        width: 16px;
        margin-right: 6px;
    }

    .c3_profile_credits_your_cards_item_current_card svg {
        width: 16px;
        height: 16px;
        margin-right: 6px;
    }

    .c3_profile_credits_your_cards_empty_card{
        font-size: 10px;
    }

    .c3_profile_credits_your_cards_empty_card img{
        height: 20px;
    }

    .c3_profile_credits_your_cards + .c3_profile_credits_balance .c3_profile_credits_balance_get_creds_btn{
        width: 80px;
    }

    .c3_profile_credits_your_cards + .c3_profile_credits_balance .c3_profile_credits_balance_get_creds_btn span span{
        display: none;
    }

    .c3_profile_credits_your_cards + .c3_profile_credits_your_cards + .c3_profile_credits_balance .c3_profile_credits_balance_get_creds_btn span span{
        display: inline;
    }

    .c3_profile_credits_balance_get_creds_btn span span{
        display: inline;
    }

    .c3_profile_credits_balance_get_creds_btn{
        width: 210px;
        height: 42px;
        font-size: 14px;
    }
}

@media screen and (max-width: 1139px){
    .c3_profile_credits_balance_get_creds_btn{
        width: 160px;
        height: 38px;
        font-size: 14px;
    }

    .c3_profile_credits_balance_get_creds_btn svg{
        width: 24px;
        height: 24px;
        margin-right: 8px;
    }
}

@media screen and (max-width: 999px){
    .c3_purchase_history_text{
        margin-top: 30px;
        margin-bottom: 16px;
        font-size: 20px;
    }
}

@media screen and (max-width: 899px){
    .c3_profile_credits_wrap{
        margin-top: 20px;
    }

    .purchase-history{
        margin-top: 0;
        padding-top: 0;
    }

    .c3_profile_credits_your_cards{
        width: 100%;
        margin-left: 0;
    }

    .c3_profile_credits_your_cards + .c3_profile_credits_your_cards{
        margin-top: 16px;
    }

    .c3_profile_credits_your_cards + .c3_profile_credits_balance{
        width: 100%;
        margin-bottom: 16px;
        min-height: 120px;
    }
}

@media screen and (max-width: 768px){
    .purchase-history .row p{
        font-size: 14px;
    }
    .purchase-history .row .date{
        max-width: 70px;
        padding-left: 0;
    }
    .purchase-history .row .credit{
        max-width: 120px;
    }
    .purchase-history .row .card{
        max-width: 120px;
    }
    .purchase-history .row .price{
        max-width: 110px;
    }

    .c3_profile_credits_wrap{
        flex-wrap: wrap;
    }

    .c3_profile_credits_balance{
        width: 100%;
        margin-right: 0;
        margin-bottom: 16px;
    }
}

@media screen and (max-width: 580px){
    .mob_hor_scroll{
        height: calc(100vh - 100px);
        height: calc(calc(var(--vh, 1vh) * 100) - 100px);
        overflow-y: auto;
    }
    .purchase-history .row div {
        justify-content: space-around;
    }
    .purchase-history .row p{
        font-size: 12px;
    }

    .all-blocks.first-link-show{
        padding: 0 15px;
    }

    .purchase-history{
        overflow-x: auto;
    }

    .purchase-history::-webkit-scrollbar{
        width: 3px;
        height: 3px;
        background: $littleGreyLittleBlueColor;
        border-radius: 5px;
        margin-right: 3px;
    }

    .purchase-history::-webkit-scrollbar-thumb {
        background: $scrollbarThumbColor;
        border-radius: 7px;
    }

    .purchase-history .all-content-info{
        min-width: 450px;
        padding: 0;
        margin-bottom: 8px;
    }

    .column_3_profile_man_manage_credit_card_btn{
        margin-left: auto;
        margin-right: auto;
    }

    .c3_profile_credits_balance_img{
        width: 30px;
        height: 29px;
        margin-right: 8px;
    }

    .c3_profile_credits_balance_middle_value{
        font-size: 22px;
    }

    .c3_profile_credits_balance_middle_value_text{
        font-size: 12px;
    }

    .c3_profile_credits_balance_middle_text{
        font-size: 12px;
    }

    .c3_profile_credits_your_cards + .c3_profile_credits_your_cards + .c3_profile_credits_balance{
        min-height: 100px;
    }

    .c3_profile_credits_balance_get_creds_btn{
        width: 65px;
        height: 28px;
    }

    .c3_profile_credits_balance_get_creds_btn span span{
        display: none;
    }

    .c3_profile_credits_balance_get_creds_btn svg {
        width: 14px;
        height: 14px;
    }

    .c3_profile_credits_your_cards_title_row{
        display: block;
    }

    .c3_profile_credits_your_cards_title{
        font-size: 16px;
        margin-bottom: 6px;
        text-align: center;
    }

    .c3_profile_credits_your_cards_add_new_btn{
        margin: 0 auto;
        width: 110px;
    }

    .c3_profile_credits_your_cards_empty_row{
        flex-wrap: wrap;
        justify-content: center;
    }

    .c3_profile_credits_your_cards_empty_card{
        margin-bottom: 0px;
        font-size: 12px;
    }

    .c3_profile_credits_your_cards{
        padding: 22px 10px;
        order: unset;
    }

    .c3_profile_credits_balance{
        order: unset;
        grid-row-start: 1;
        grid-row-end: 2;
    }

    .c3_profile_credits_wrap{
        width: 100%;
    }

    .c3_profile_credits_wrap{
        margin-top: 15px;
        padding: 0 0 15px;
        flex-wrap: nowrap;
        flex-direction: column-reverse;

        display: grid;
        grid-template-columns: 1fr;
    }

    .c3_profile_credits_your_cards_item{
        padding: 0 5px;
    }

    .c3_profile_credits_your_cards_item_img{
        height: 14px;
    }

    .c3_profile_credits_your_cards_item_card_num{
        font-size: 10px;
    }

    .c3_profile_credits_your_cards_item_current_card img {
        width: 14px;
    }

    .c3_profile_credits_your_cards_item_current_card svg {
        width: 14px;
        height: 14px;
    }

    .c3_purchase_history_text{
        margin-top: 20px;
        margin-bottom: 8px;
        padding: 0;
        font-size: 20px;
    }

    .c3_profile_credits_your_cards_add_new_btn span span{
        display: none;
    }

    .purchase-history .all-tabel h3{
        padding-left: 10px;
        font-size: 15px;
    }



    .c3_profile_credits_your_cards + .c3_profile_credits_your_cards + .c3_profile_credits_balance .c3_profile_credits_balance_get_creds_btn{
        width: 80px;
        height: 38px;
    }

    .c3_profile_credits_your_cards + .c3_profile_credits_your_cards + .c3_profile_credits_balance .c3_profile_credits_balance_get_creds_btn span span{
        display: none;
    }


}

@media screen and (max-width: 370px){
    .c3_profile_credits_your_cards_empty_card{
        font-size: 10px;
        width: 100%;
        margin-bottom: 5px;
    }

    .c3_profile_credits_your_cards_add_new_btn{
        margin: 0 auto;
    }
}

@media screen and (max-width: 899px) and (max-height: 450px) and (min-width: 420px){
    .mob_hor_scroll{
        height: 100vh;
        height: calc(var(--vh, 1vh) * 100);
        overflow-y: auto;
    }

    .c3_profile_credits_wrap{
        margin-top: 0;
    }

    .c3_header{
        width: 250px;
        padding-bottom: 0;
        padding-top: 0;
    }

    .header-menu{
        padding-bottom: 16px;
        padding-top: 16px;
    }

    .profile_header_menu_close{
        right: -30px;
    }

    .profile_man main .container{
        /*height: 100vh;
        height: calc(var(--vh, 1vh) * 100);
*/
    }

    .need_confirm_email .profile_man main .container{
        /*height: 100vh;
        height: calc(var(--vh, 1vh) * 100);
*/
    }

    .column-3 main{
        padding-top: 0;
    }

    .mp_slider_wrap {
        width: calc(100vw - 193px);
    }
}
