@import "@sitestyles";

@media screen and (max-width: 1899px){
	.c3_header{
		max-height: none;
	}

	/*.header-menu-btn{
		width: 120px;
		min-width: 100px;
		height: 89px;
	}

	.header-menu-item-notif{
		width: 93px;
		padding-bottom: 3px;
		line-height: 13px;
	}

	.header-menu-item{
		flex-wrap: wrap;
	}

	.header-menu-text{
		text-align: center;
		margin-top: 6px;
		display: block;
		flex: none;
		margin-left: 0;
		font-size: 14px;
	}

	.header-sup{
		margin-left: 0;
		line-height: 36px;
	}

	.header-gif{
		margin-left: 0;
		padding: 0 15px;
	}

	.header-prof{
		width: auto;
		margin-left: 0;
	}

	.header-menu__img{
		max-width: 30px;
		max-height: 30px;
		margin: 0;
	}*/

	.header-menu-btn{
		width: 130px;
	}

	.profile_man main .container{
		/*height: calc(100vh - 114px);
		height: calc(calc(var(--vh, 1vh) * 100) - 114px);
*/
	}

	.profile_man.notif main .container{
		height: calc(100vh - 142px);
		height: calc(calc(var(--vh, 1vh) * 100) - 142px);
	}

	.c3ph_img{
		width: 30px;
		height: 30px;
		background-size: 24px;
		flex: none;
	}

	.info__name{
		font-size: 28px;
	}

	.info__id{
		font-size: 16px;
	}
}

@media screen and (max-width: 1699px){
	.content-photo{
		width: 350px;
		height: 240px;
	}

	.man_profile_photo{
		max-height: 250px;
	}
}

@media screen and (max-width: 1649px){
	.mp_slider_wrap{
		width: calc(100vw - 575px);
	}
}

@media screen and (max-width: 1549px){
	.info__name{
		font-size: 24px;
	}

	.man_profile_email{
		font-size: 16px;
	}

	.info__id{
		font-size: 14px;
		margin-left: 20px;
	}

	.info-date{
		margin-top: 15px;
	}

	.date-birthday{
		font-size: 16px;
	}

	.info-place{
		margin-top: 15px;
		align-items: center;
	}

	.place-info{
		font-size: 16px;
	}

	.place-flag{
		width: 24px;
		padding-right: 0;
		margin-right: 9px;
	}

	.list-header{
		font-size: 18px;
		margin-bottom: 15px;
	}

	.list-item{
		font-size: 14px;
	}

	.header-menu-text{
		font-size: 12px;
	}

	.c3ph_img{
		width: 22px;
		height: 22px;
		background-size: contain;
	}

	.header-menu-btn{
		width: 120px;
		height: 52px;
	}

	.header-menu-element{
		margin-right: 8px;
	}
}

@media screen and (max-width: 1499px){
	.main-content{
		height: auto;
		min-height: unset;
	}

	.info__name{
		font-size: 24px;
	}

	.man_profile_email{
		font-size: 14px;
	}

	.place-info{
		font-size: 14px;
	}

	.info-date{
		margin-top: 6px;
	}

	.info-place{
		margin-top: 10px;
	}

	.date-birthday{
		font-size: 12px;
	}

	.credits-link-item{
		font-size: 12px;
		margin-top: 0;
	}

	.content-info__credits{
		width: 147px;
		height: 77px;
	}

	.credits-count{
		font-size: 20px;
		line-height: 1;
		margin-bottom: 5px;
		margin-top: 5px;
	}

	.credits-link{
		line-height: 1;
	}

	.credits-name{
		font-size: 12px;
		line-height: 1;
		margin-top: 5px;
	}

	.content-info__credits img{
		margin-right: -10px;
		margin-top: -7px;
	}

	.info-about-parts{
		display: block;
	}

	.info-about-block{
	}

	.social-info{
		max-width: unset;
	}

	.main-content-header{
		display: block;
	}

	.content-photo{
		background-position: center;
		background-color: transparent;
		height: 285px;
		float: left;
	}

	.list-of-lists{
		flex-wrap: wrap;
	}

	.text-elem{
		width: 50%;
	}

	.list-item{
		display: block;
	}

	.content-info{
		padding-bottom: 24px;
	}

	.mp_slider_wrap{
		width: calc(100vw - 501px);
	}

	.m_profile_flex_wrap{
		display: flex;
	}

	.block-2{
		flex: none;
	}

	.node-header{
		font-size: 18px;
	}
}

@media screen and (max-width: 1399px){
	.content-photo{
		width: 270px;
		height: 200px;
	}

	.man_profile_photo{
		max-width: 270px;
		max-height: 320px;
	}


}

@media screen and (max-width: 1349px){
	.header-menu-btn{
		width: 104px;
	}
}

@media screen and (max-width: 1299px){
	.node-header{
		font-size: 22px;
	}

	.info__name{
		font-size: 18px;
	}

	.info__id{
		font-size: 12px;
	}
}

@media screen and (max-width: 1199px){
	.mp_slider_wrap{
		width: calc(100vw - 230px);
	}

	.c3_header{
		justify-content: flex-start;
		padding-left: 24px;
	}
}

@media (hover: none) and (pointer: coarse){
	@media screen and (min-width: 1140px){
		.c3_header{
			display: none;
		}

		.new_mprofile_wrap{
			padding-top: 16px;
		}
	}
}


@media screen and (max-width: 1139px){
	.c3_header{
		display: none;
	}
}

@media screen and (max-width: 999px){
	.node-header{
		font-size: 16px;
		height: auto;
	}

	.node-about{
		font-size: 14px;
	}

	.mp_slider_wrap {
		width: calc(100vw - 100px);
	}

	.profile_man main .container{
		/*height: calc(100vh - 125px);
		height: calc(calc(var(--vh, 1vh) * 100) - 125px);
*/
		padding-bottom: 16px;
		padding-top: 16px;
	}

	.need_confirm_email .profile_man main .container{
		/*height: calc(100vh - 125px);
		height: calc(calc(var(--vh, 1vh) * 100) - 125px);
*/
	}
}

@media screen and (max-width: 899px){

	.left_menu_chat_list img{
		display: block;
	}

	.search .left_menu_chat_list{
		display: none;
	}

	.profile_header_menu_close{
		display: block;
		right: 50px;
	}

	.c3_header{
		position: fixed;
		left: -320px;
		top: 0;
		width: 320px;
		height: 100vh;
		height: calc(var(--vh, 1vh) * 100);
		display: block;
		/*background-color: $fadeColor;
*/
		padding: 16px 8px 16px 0;
		z-index: 999;
		transition: .3s;
	}

	.c3_header.active{
		left: 0;
	}

	.header-menu{
		display: block;
		padding-top: 0;
		padding-bottom: 0;
	}

	.header-menu-btn{
		width: 209px;
		height: 54px;
	}

	.column-3 .header-menu-item-notif{
		width: 209px;
		padding-top: 40px;
		margin-top: -40px;
	}

	.header-menu-item{
		justify-content: flex-start;
		padding-left: 20px;
	}

	.header-menu-text{
		width: 100%;
		margin-top: 0;
		margin-left: 12px;
		flex: 1;
		padding: 0;
		text-align: left;
	}

	.c3ph_img{
		width: 25px;
		height: 25px;
		background-size: contain;
	}

	.header-menu-element{
		margin-bottom: 16px;
		margin-right: 0;
	}

	.header-menu-element:last-child{
		margin-bottom: 0;
	}

	.header-menu{
		padding-left: 16px;
		height: 100%;
		overflow: auto;
		padding-right: 8px;
	}

	.header-menu::-webkit-scrollbar{
		width: 3px;
		background: $littleGreyLittleBlueColor;
		border-radius: 5px;
		margin-right: 3px;
	}

	.header-menu::-webkit-scrollbar-thumb {
		background: $accentLeftColor;
		border-radius: 7px;
	}

	.profile_man main .container{
		/*height: calc(100vh - 114px);
		height: calc(calc(var(--vh, 1vh) * 100) - 114px);
*/
	}

	.need_confirm_email .profile_man main .container{
		/*height: calc(100vh - 114px);
		height: calc(calc(var(--vh, 1vh) * 100) - 114px);
*/
	}

	.column-3 main{
		padding-top: 0px;
	}
}

@media screen and (max-width: 789px){
	.upload_popup_tabs_content_middle.photo, .upload_popup_tabs_content_middle.video{
		justify-content: center;
	}

	.popup_likeher_search_photos_big_photo > img{
		max-width: calc(100vw - 40px);
	}

	.content-info-header{
		display: block;
		border: none;
		padding: 16px 0;
	}

	.info-firstline{
		display: block;
		height: auto;
	}

	.info-firstline span{
		display: block;
	}

	.info__id{
		margin-left: 0;
		margin-top: 0;
		font-size: 12px;
	}

	.credits{
		margin-top: 10px;
	}

	.content-photo{
		width: 250px;
	}

	.man_profile_photo{
		max-width: 250px;
		max-height: 320px;
	}

	.man_profile_email{
		margin-top: 16px;
	}

	.content-info{
		padding-left: 15px;
	}

	.main-content-header{
		display: block;
	}

	.place-info{
		font-size: 12px;
	}

	.place-flag{
		width: 19px;
	}

	.info-date{
		margin-top: 0;
	}

	.info-place{
		margin-top: 0;
	}

	.node-header__section{
		font-size: 14px;
		margin-bottom: 5px;
	}

	.list-header{
		font-size: 14px;
		margin-bottom: 5px;
	}

	.list-item{
		font-size: 12px;
		margin-bottom: 6px;
	}

	.info-about-parts .list-item-header{
		margin-left: 20px;
	}

	.block-2{
		width: 295px;
	}
}

@media screen and (max-width: 599px){
	.left_menu_chat_list img{
		height: 25px;
	}

	.m_profile_flex_wrap{
		display: block;
	}

	.info-about-parts .list-item-header{
		margin-left: 0;
	}

	.content-info__credits img{
		margin-right: 12px ;
		display: block;
		margin-top: 0;
	}

	.make-emojis-large{
		font-size: 90px;
	}
	.close_one_popup img{
		width: 16px;
		height: 16px;
	}

	.profile_man main .container{
		/*height: calc(100vh - 100px);
		height: calc(calc(var(--vh, 1vh) * 100) - 100px);
*/
		padding-bottom: 0;
	}

	.need_confirm_email .profile_man main .container{
		/*height: calc(100vh - 100px);
		height: calc(calc(var(--vh, 1vh) * 100) - 100px);
*/
	}

	.rhap_progress-section{
		width: calc(100% - 90px);
	}
	.message_audio_wrap{
		width: 210px;
	}

	.message_audio_wrap .rhap_time{
		font-size: 12px;
	}

	.chat_message_wrap.right .message_audio_wrap{
	} 

	.rhap_volume-controls{
		width: 36px;
		right: 0;
	}

	.rhap_volume-bar-area{
		display: none;
	}

	.rhap_volume-container:hover .rhap_volume-bar-area{
		display: none !important;
	}
	
	.c4_minichat .message_audio_wrap{
		width: 205px;
	}

	.c4_minichat .chat_message_wrap.right .message_audio_wrap{
		width: 232px;
	}

	.upload_popup_tabs{
		padding: 20px 10px;
	}

	.react-tabs{
		padding: 0;
		position: relative;
	}

	.upload_popup_tabs_content_top .cost{
		width: 150px;
	}

	.upload_popup_tabs_content_middle.photo{
		grid-template-columns: repeat(auto-fill, 80px);
	}

	.photo .upload_popup_tabs_content_item_top label img{
		width: 80px;
		height: 80px;
	}

	.upload_popup_tabs_content_middle.photo, .upload_popup_tabs_content_middle.video{
		margin-right: 0;
	}

	.upload_popup_tabs_content_middle.video{
		grid-template-columns: repeat(auto-fill, 180px);
	}

	.upload_popup_tabs_content_middle.audio{
		margin-right: 0;
		height: calc(100% - 64px);
	}

	.popup_audio_item_check{
		margin-left: 12px;
	}

	.popup_audio_item_name{
		max-width: 40vw;
	}

	.text-elem{
		width: 100%;
	}

	.about-profile-text{
		display: block;
		margin-bottom: 40px;
	}

	.node-about{
		margin-bottom: 0;
		margin-top: 8px;
	}

	.text-node{
		margin-right: 0;
		margin-bottom: 36px;
		width: 100%;
	}

	.node-header__section{
		margin-top: 0;
	}

	.profile_man main{
		//padding-right: 0px;
	}

	.profile_man main .container{
		padding-left: 0px;
		padding-right: 0;
		margin-right: 8px;
	}

	.main-content{
		-webkit-border-radius: 0;
		-moz-border-radius: 0;
		border-radius: 0;
		margin-bottom: 0;
		box-shadow: none;
	}

	.main-content-about{
		-webkit-border-radius: 0;
		-moz-border-radius: 0;
		border-radius: 0;
		box-shadow: none;
	}

	.about-content-container{
		padding-top: 0;
		padding-right: 10px;
		padding-left: 15px;
	}

	.content-photo{
		margin: 0;
		width: calc(100% - 15px);
		float: none;
		background-size: contain;
		background-position: center;
		height: 250px;
		max-width: unset;
	}

	.man_profile_photo_wrap{
		padding-right: 10px;
	}

	.man_profile_photo{
		width: 100%;
		height: auto;
		margin: 0 auto;
		max-width: unset;
		max-height: unset;
	}

	.block-2{
		width: 100%;
	}

	.info-place{
		align-items: flex-start;
	}

	.place-info{
		line-height: 1;
	}

	.content-info{
		padding-right: 10px;
		padding-left: 15px;
		padding-top: 0;
	}

	.content-info__credits{
		width: 100%;
	}

	.mp_slider_wrap {
		width: calc(100vw - 77px);
	}

	.scrollbar-item{
		height: 150px;
		object-fit: contain;
	}

	.profile_page_main_div{
		background-color: $whiteColor;
	}

	.info-firstline span{
		margin-bottom: 6px;
	}

	.info-date{
		margin-bottom: 11px;
	}

	.credits{
		margin-top: 15px;
		width: calc(100% - 16px);
	}
}

@media screen and (max-width: 899px) and (max-height: 450px){
	.man_profile_photo{
		max-width: 220px;
	}
}
