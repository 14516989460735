@import "@sitestyles";

@mixin flex_full_center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin button_styles {
  border: 1px solid $supportTextColor;
  border-radius: 5px;
  cursor: pointer;
  transition-duration: .3s;
  color: $supportTextColor;

  &:hover,
  &:focus {
    background: $supportTextColor;
    color: $whiteColor;
    border-color: $supportTextColor;

    svg {
      fill: $whiteColor;
    }
  }

  svg {
    width: 24px;
    height: 24px;
    fill: $supportTextColor;
    margin-right: 4px;
    transition-duration: .3s;
  }
}

$bigButtonWidth: 178px;
$mediumButtonWidth: 145px;
$largeButtonWidth: 200px;
$smallButtonWidth: 100%;

.bottom_buttons_wrap {
  background-color: #fff;
  padding: 10px;
  border-top: 3px solid $notifyListBottomBtn;

  @media screen and (max-width: 599px) {
    background-color: transparent;
    border: none;
  }
}

.buttons_row {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;

  @media screen and (max-width: 599px) {
    display: block;
  }

  & > * {
    font-family: $mainFont;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $primaryColor;
    border-radius: 5px;
    font-weight: 500;
    font-size: 12px;
    color: $primaryColor;
    cursor: pointer;
    transition-duration: .3s;

    &:hover,
    &:focus {
      background: $primaryColor;
      color: $whiteColor;
      border-color: $primaryColor;

      svg {
        fill: $whiteColor;
      }
    }

    @media screen and (max-width: 599px) {
      height: 34px;
    }

    svg {
      transition-duration: .3s;
      margin-right: 4px;
      fill: $primaryColor;
    }

    span {
      line-height: 1;
    }
  }

  .mute_btn {
    width: $bigButtonWidth;
    margin-right: 8px;

    @media screen and (max-width: 1649px) {
      width: $mediumButtonWidth;

      span {
        width: min-content;
      }
    }

    @media screen and (max-width: 1199px) {
      width: $largeButtonWidth;

      span {
        width: auto;
      }
    }

    @media screen and (max-width: 599px) {
      width: $smallButtonWidth;
      margin-top: 8px;
      margin-right: 0;
    }
  }

  .help_btn {
    flex: 1;

    @media screen and (max-width: 599px) {
      width: 100%;
    }
  }
}

.bottom_all_buttons_wrap {
  width: 100%;
  padding: 20px 10px 10px;
  background-color: #fff;
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 100;
  font-size: 12px;
  font-weight: 500;
  color: $supportTextColor;
  display: none;

  &.opened {
    display: block;

    @media screen and (max-width: 599px) {
      display: flex;
    }
  }

  @media screen and (max-width: 599px) {
    height: 100%;
    flex-direction: column;
    background-color: $background1Color;
  }

  .opened_title {
    @include flex_full_center;
    font-weight: bold;
    margin-bottom: 8px;
    color: $supportTextColor;

    svg {
      margin-right: 4px;
      fill: $supportTextColor;
    }
  }

  .opened_text {
    text-align: center;
    max-width: 193px;
    margin: 0 auto 8px;

    .opened_phone_link {
      color: #00317b;
    }
  }

  .opened_buttons_col {
    margin-bottom: 16px;
    list-style: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;

    .opened_btn_wrap {
      display: block;
    }

    .opened_col_btn {
      @include flex_full_center;
      @include button_styles;

      & {
        width: 121px;
        height: 44px;
      }

      svg {
        margin-right: 4px;
      }
    }
  }

  .opened_buttons_row {
    display: flex;
    list-style: none;
    gap: 8px;

    @media screen and (max-width: 599px) {
      flex-direction: column;
      margin-top: auto;
    }

    .opened_btn_start_wrap {
      display: block;
    }

    .opened_btn_help_wrap{
      flex: 1;
    }

    .opened_row_btn {
      @include flex_full_center;
      @include button_styles;

      & {
        height: 44px;
      }

      &.start {
        width: $bigButtonWidth;

        @media screen and (max-width: 1649px) {
          width: $mediumButtonWidth;
        }

        @media screen and (max-width: 1199px) {
          width: $largeButtonWidth;
        }

        @media screen and (max-width: 599px) {
          width: $smallButtonWidth;
        }
      }

      &.help {
        width: 100%;
      }
    }
  }

  .close {
    position: absolute;
    right: 5px;
    top: 5px;
    padding: 5px;
    transition-duration: .3s;
    cursor: pointer;

    svg {
      display: block;
      cursor: pointer;
      fill: $notifyItemClose;
      width: 24px;
      height: 24px;
      transition-duration: .3s;
    }

    &:hover,
    &:focus{
      svg{
        fill: $primaryColor;
      }
    }
  }
}