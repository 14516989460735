@import "@sitestyles";

.loaderDiv{
    z-index: 99;
    width: 100%;
    height: 100%;
}
.loaderImage{
    position: absolute;
    left: calc(50% - 50px);
    top: calc(50% - 50px);
}
.title{
    display: flex;
    justify-content: center;
    padding: 20px;
    background-color: white;
    width: 300px;
    margin: 0 auto;
    box-shadow: #00000019;
    border-radius: 0 0 30px 30px;
    color: $accentRightColor;
    font-weight: 600;
    font-size: 20px;
}

.loaderDiv.static{
    width: max-content;
    margin: auto;
    position: static;
    height: auto;
}

.loaderDiv.static .loaderImage{
    position: static;
}

:global(.popup_your_credit_cards) .loaderImage{
    position: relative;
}

@media screen and (max-width: 499px) {
    .title{
        width: 100%;
        font-weight: 600;
        font-size: 17px;
    }
}