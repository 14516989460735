@import "@sitestyles";


.cl2h_tab_wide_filter_row2 {
  width: 100%;
}

.cl2h_tab_wide_filter_row2_two {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: space-between;
}

.cl2h_tab_wide_filter_row2_dob {
  width: 410px;
  max-width: 100%;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: space-between;
}

.cl2h_tab_wide_filter_item_wrap {
  height: 40px;
  gap: 12px;
  @media screen and (max-width: 796px) {
    height: 30px;
  }
}

.cl2h_tab_wide_filter_row2_two .cl2h_tab_wide_filter_item_wrap {
  width: calc(50% - 4px);
}

.cl2h_tab_wide_filter_row2_big .cl2h_tab_wide_filter_item_wrap {
  height: 60px;
}

.cl2h_tab_wide_filter_row2_dob .cl2h_tab_wide_filter_item_wrap {
  width: 145px;
}

.cl2h_tab_wide_filter_row2_dob .cl2h_tab_wide_filter_item_wrap:nth-child(2) {
  width: 100px;
}

.cl2h_tab_wide_filter_item {
  position: relative;
  width: 100%;
}

.like_select {
  opacity: 0;
  position: fixed;
  left: -999999px;
  top: -99999px;
}

.fill_data_form_select_item {
  position: relative;
}

.like_select_head {
  height: 42px;
  line-height: 42px;
  font-size: 14px;
  background-color: $searchFilterSelectBg;
  border-radius: 4px;
  border: 1px solid $searchFilterSelectBorder;
  padding: 0 30px 0 22px;
  color: $searchFilterSelectColor;
  font-weight: 500;
  position: relative;
  z-index: 2;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media screen and (max-width: 1299px) {
    font-size: 12px;
    height: 36px;
    line-height: 36px;
    padding: 0 12px;
  }
}

.like_select_head.error {
  border: 1px solid #EB5757;
}

.like_select_head.hide_caret {
  caret-color: transparent;
  font-family: $mainFont;
  color: $accentLeftColor;
  font-weight: 600;
  letter-spacing: 0.02em;

  &::placeholder {
    color: #333;
    font-weight: 500;
  }
}

.like_select_head_remove {
  position: absolute;
  width: 10px;
  height: 11px;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 9;
  background-color: $searchFilterSelectBorder;
  -webkit-mask: url(../img/c2-remove.svg) center no-repeat;
  mask: url(../img/c2-remove.svg) center no-repeat;
  -webkit-mask-size: contain;
  mask-size: contain;
  cursor: pointer;

  &:hover {
    background-color: $selectBorderHoverColor;
  }
}

.cl2h_tab_wide_filter_item.open .like_select_head {
  border-bottom: none;
  border-color: $inputBorderNormal;
  color: $searchFilterSelectColor;
  background-color: $searchSelectBg;

  &.hide_caret {
    color: $accentLeftColor;

    &::placeholder {
      color: #333;
      font-weight: 500;
    }
  }


}

.profile_main_info_row .cl2h_tab_wide_filter_item.open .like_select_head {
  border-bottom: none;
  border-color: $inputBorderNormal;
  color: $searchFilterSelectColor;
  background-color: $searchSelectBg;

  &.hide_caret {
    color: $accentLeftColor;

    &::placeholder {
      color: #333;
      font-weight: 500;
    }
  }
}

.c3lh_search_filters_list_wrap .like_select_head {
  /*font-weight: 500;*/
  /*color: rgba(51, 51, 51, 0.6);*/
}

.cl2h_tab_wide_filter_row2_big .like_select_head {
  height: 60px;
  line-height: 60px;
  font-size: 18px;
}

.like_select_head:after {
  content: '';
  display: block;
  width: 10px;
  height: 6px;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  background-image: url(../img/create-profile-page/selecet-arrow.svg);
  transition: .3s;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.open .like_select_head:after {
  transform: translateY(-50%) rotate(180deg);
}

.cl2h_tab_wide_filter_item.open .like_select_head.selected,
.like_select_head.selected {
  color: $accentLeftColor;
  font-weight: 600;
  letter-spacing: 0.02em;
  padding-left: 14px;
}

.like_select_head.selected:hover {
  /*font-weight: 600;*/
}

.cl2h_tab_wide_filter_item.open {
  border-radius: 4px 4px 0 0;
  box-shadow: 0px 6px 12px rgba(47, 43, 99, 0.16);
  z-index: 4;
}

.like_select_list_wrap {
  padding: 10px 6px 10px 10px;
  background-color: $selectBackgroundColor;
  border: 1px solid $inputBorderNormal;
  border-top: none;
  border-radius: 0 0 4px 4px;
  position: relative;
  z-index: 3;
  margin-top: -5px;
  display: none;
}

.like_select_list_wrap.open {
  display: block;
}

.like_select_list {
  display: none;
  /*padding: 20px 0 10px;*/
  position: relative;
  z-index: 6;
  max-height: 225px;
  overflow: auto;
  padding-right: 5px;
}

.like_select_list::-webkit-scrollbar {
  width: 3px;
  background: $whiteColor;
  border-radius: 22px;
}

.like_select_list::-webkit-scrollbar-thumb {
  background: $scrollbarThumbColor;
  border-radius: 9px;
}

.like_select_label {
  display: block;
  padding: 12px 12px 10px;
  color: #818181;
  font-weight: 500;
  border-radius: 4px;
  cursor: pointer;
}

.cl2h_tab_wide_filter_row2_big .like_select_label {
  font-size: 18px;
  padding: 20px 5px 18px;
}

.like_select:checked + .like_select_label {
  background-color: $background5Color;
}

.like_select_label:hover {
  background-color: $buttonHoverBg;
  color: #333;
}

.like_select_head.profile_head {
  background-color: #fff;
  font-size: 14px;
  border-radius: 8px;
}

.like_select_head.profile_head::placeholder {
  color: $inputTextColor;
}

.like_select_popular_title {
  font-weight: bold;
  margin-left: 5px;
  color: #474f5c;
}

.cl2h_tab_wide_filter_item.open .like_select_head.profile_head::placeholder {
  color: #474f5c;
}

.cl2h_tab_wide_filter_item:not(.open) .like_select_head.profile_head.selected:hover {
  border-color: #677f92;
}

.cl2h_tab_wide_filter_item.open .like_select_head.profile_head {
  border-radius: 8px 8px 0 0;
}

input.like_select_head.profile_head:focus {
  cursor: text;
}

.like_select_list_wrap.profile_like_select_list_wrap {
  background-color: #fff;
  position: absolute;
  width: 100%;
  border-radius: 0 0 8px 8px;
}

.like_select_label.like_select_label_profile {
  color: #00317B;
}

.like_select_label.like_select_label_profile:hover {
  font-weight: 600;
}

.cl2h_tab_wide_filter_item.cl2h_tab_wide_filter_item_profile:not(.open) {
  z-index: 1;
}

@media screen and (max-width: 1799px) {

  .cl2h_tab_wide_filter_row2_dob .cl2h_tab_wide_filter_item_wrap {
    width: 125px;
  }

  .cl2h_tab_wide_filter_row2_dob .cl2h_tab_wide_filter_item_wrap:nth-child(2) {
    width: 75px;
  }
}

@media screen and (max-width: 1699px) {
  .cl2h_tab_wide_filter_row2_dob .cl2h_tab_wide_filter_item_wrap {
    width: 110px;
  }

  .cl2h_tab_wide_filter_row2_dob .cl2h_tab_wide_filter_item_wrap:nth-child(2) {
    width: 70px;
  }
}

@media screen and (max-width: 1649px) {
  .cl2h_tab_wide_filter .like_select_head {
    padding-left: 15px;
    font-size: 12px;
  }

  .cl2h_tab_wide_filter .like_select_label {
    margin: 0;
    padding-left: 5px;
    padding-right: 5px;
    font-size: 12px;
  }
}

@media screen and (max-width: 1599px) {
  .like_select_head.profile_head {
    height: 38px;
    font-size: 12px;
  }

  .like_select_label {
    font-size: 12px;
    padding: 11px 12px 10px;
  }
}

@media screen and (max-width: 1499px) {
  .cl2h_tab_wide_filter_row2_dob .cl2h_tab_wide_filter_item_wrap {
    width: 87px;
  }

  .cl2h_tab_wide_filter_row2_dob .cl2h_tab_wide_filter_item_wrap:nth-child(2) {
    width: 60px;
  }

  .cl2h_tab_wide_filter_row2_big .like_select_head {
    height: 42px;
    line-height: 42px;
    font-size: 14px;
    padding-left: 10px;
  }
}

@media screen and (max-width: 999px) {
  .c3_profile_edit_block_item > label {
    font-size: 12px;
  }
}

@media screen and (max-width: 767px) {
  .c3_profile_edit_block_list {
    display: block;
  }

  .c3_profile_edit_block_item {
    margin-bottom: 16px;
  }

  .c3_profile_edit_big_block {
    display: block;
  }

  .c3_profile_edit_block_title {
    text-align: center;
    margin-bottom: 10px;
  }

  .c3_profile_edit_block_subtitle {
    text-align: center;
    font-size: 16px;
    margin-bottom: 10px;
  }

  .c3_profile_edit_big_block_right {
    position: relative;
  }

  .c3_profile_edit_big_block_head .c3_profile_edit_block_title {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    text-align: center;
    top: -35px;
    margin-top: 24px;
  }

  .c3_profile_edit_big_block {
    padding-top: 40px;
  }

  .c3_profile_edit_big_block_id {
    display: none;
  }
}

@media screen and (max-width: 599px) {

  .cl2h_tab_wide_filter_row2_dob .cl2h_tab_wide_filter_item_wrap {
    min-width: 154px;
    width: 100%;
    height: 30px;
  }

  .cl2h_tab_wide_filter_row2_dob .cl2h_tab_wide_filter_item_wrap:nth-child(2) {
    min-width: 74px;
    width: 100%;
    margin-bottom: 10px;
    margin-top: 10px;
  }

  .cl2h_tab_wide_filter_row2_big .like_select_head {
    height: 30px;
    line-height: 30px;
    font-size: 12px;
  }

  .cl2h_tab_wide_filter_row2_dob {
    flex-wrap: wrap;
  }

  .like_select_head {
    height: 30px;
    line-height: 30px;
    font-size: 12px;
  }

  .c3lh_search_filter_item_hobbies_title {
    height: 30px;
    line-height: 30px;
  }

  .c3lh_search_filter_item label {

  }

  .like_select_label,
  .cl2h_tab_wide_filter_row2_big .like_select_label {
    font-size: 12px;
    padding-top: 9px;
    padding-bottom: 7px;
  }

  .cl2h_tab_wide_filter_item_wrap {
    height: 30px;
  }

  .like_select_list {
    max-height: 175px;
  }
}
